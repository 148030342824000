import React, { useState } from "react";
import { Paper, Tooltip } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Print } from "@mui/icons-material";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTextField,
  StyledTypography,
} from "../../css/StyledComponents";

import Payment from "../payment/Payment";
import PdfMaker from "../pdfMaker/PdfMaker";
import { pincodes } from "../../constants/pincodes";

const doPaymentMutation = loader("../../graphqlCalls/payment/DoPayment.gql");
const updatePostPaymentMutation = loader(
  "../../graphqlCalls/payment/UpdatePostPayment.gql"
);
const getSubStatusQuery = loader(
  "../../graphqlCalls/payment/getSubscriptionStatus.gql"
);
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Billing(props) {
  const fUser = props.fUser;
  const [GSTNo, setGSTNo] = useState("");
  const [pincode, setPincode] = useState(0);
  const [state, setState] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [billDate, setBillDate] = useState(0);

  const getStartDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  const getEndDate = (dateIs) => {
    const formattedDate = new Date(dateIs);

    let subscriptionDays = 365;
    let result = formattedDate.setDate(
      formattedDate.getDate() + subscriptionDays
    );
    let endDate = new Date(result);

    return `${endDate.getDate()} ${
      monthNames[endDate.getMonth()]
    } ${endDate.getFullYear()}`;
  };

  const [
    DoPaymentMutation,
    { loading: fetchingPayment, error: errorCreating, data: dataCreating },
  ] = useMutation(doPaymentMutation);
  const [
    UpdatePostPaymentMutation,
    {
      loading: updatingPostPayment,
      error: errorPostUpdate,
      data: dataPostUpdate,
    },
  ] = useMutation(updatePostPaymentMutation);

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getSubStatusQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        sId: fUser ? fUser._id : null,
      },
      skip: !fUser || fUser.role != "Student",
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ getSubscriptionStatus }) => {
        if (getSubscriptionStatus.status == true) {
          setPaymentDone(true);
          setState(getSubscriptionStatus.state);
          setPincode(getSubscriptionStatus.pincode);
          setGSTNo(getSubscriptionStatus.GSTNo);
          setPaymentId(getSubscriptionStatus.paymentId);
          setBillDate(Date.parse(getSubscriptionStatus.billDate));
        } else setPaymentDone(false);
      },
    }
  );

  const getState = (pincodeIp) => {
    const found = pincodes.find((pc) => pc.Pincode == pincodeIp);
    if (found) {
      return found.StateName;
    }
    return "";
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function DisplayRazorpay() {
    let callInfo;
    if (fetchingPayment) return;

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }

    try {
      callInfo = await DoPaymentMutation();
    } catch (error) {
      alert("Something went wrong");
    }

    const dp = callInfo.data.DoPayment;

    const options = {
      key: "rzp_test_UL2w8U1WKAUgx8",
      currency: dp.currency,
      amount: dp.amount?.toString(),
      order_id: dp.id,
      name: "Subscription Fees",
      description: "Annual Fee (email id cannot be changed)",
      handler: async function (response) {
        try {
          const callInfo = await UpdatePostPaymentMutation({
            variables: {
              input: {
                studentId: fUser._id,
                state: state,
                pincode: parseInt(pincode),
                GSTNo: GSTNo,
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                // topicData: [topicDataTypeIp],
              },
            },
            onError: (err) => {
              alert("Something went wrong");
            },
          });
          refetch();
        } catch (error) {
          alert("Something went wrong");
        }
      },
      prefill: {
        email: fUser.mail,
      },
      readonly: { email: true },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div style={{ margin: "5vh 5vw", height: "800px" }}>
      <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
        Billing Details
      </StyledTypography>
      <br />
      {/* <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
        <span style={{ fontWeight: "bold" }}>Yearly subscription plan: </span>
        <strike
          style={{
            color: "#1f5156",
          }}
        >
          INR 1180.00 (rupees five hundred only)
        </strike>
      </StyledTypography>
      <br />
      <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
        Congratulations! You have availed
        {" "}<span style={{ fontWeight: "bold" }}>50%</span>{" "}
        discount! Only for limited subscribers.
      </StyledTypography>
      <br /> */}
      {fUser.role === "Student" ? (
        <>
          {!paymentDone ? (
            <div>
              <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
                <span style={{ fontWeight: "bold" }}>
                  Yearly subscription plan:{" "}
                </span>
                <span
                  style={{
                    color: "#1f5156",
                  }}
                >
                  INR 590.00 (rupees five hundred and ninety only)(including
                  taxes)
                </span>
              </StyledTypography>
              <br />
              <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
                For value added tax invoice, please provide
                <span style={{ fontWeight: "bold" }}> GSTIN:</span>
                <StyledTextField
                  name="GSTNo"
                  value={GSTNo}
                  label="(optional)"
                  onChange={(e) => {
                    setGSTNo(e.target.value);
                  }}
                  variant="filled"
                />
              </StyledTypography>
              <br />
              <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
                Please enter pincode
                <span style={{ fontWeight: "bold" }}> PINCODE:</span>
                <StyledTextField
                  name="pincode"
                  type="number"
                  value={pincode}
                  onChange={(e) => {
                    setPincode(e.target.value);
                    let stateIs = getState(e.target.value);
                    setState(stateIs);
                  }}
                  variant="filled"
                />
                <span style={{ fontWeight: "bold" }}> STATE:</span>
                <StyledTextField
                  name="state"
                  value={state}
                  variant="filled"
                  disabled
                />
              </StyledTypography>
              <p>Please enter valid Indian Pincode to enable Payment Gateway</p>
              {/* <Payment fUser={fUser} studentMail={fUser.mail} Disabled={state == ""} /> */}

              <StyledButton
                variant="contained"
                disabled={fetchingPayment || state == "" || loading}
                size="medium"
                onClick={DisplayRazorpay}
              >
                Pay Rs.590
              </StyledButton>
              <p>Payment is non-refundable</p>
            </div>
          ) : null}
          <br />
          <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
            First five mock tests can be availed by the student without any
            payment.
          </StyledTypography>
          <br />
          <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
            <ul>
              <li>Services provided under this application are chargeable.</li>
              <li>
                The chargeable services include:
                <ol type="i" style={{ fontSize: "25px" }}>
                  <li>the usage of online examination platform</li>
                  <li>the usage of AI based analysis</li>
                </ol>
              </li>
              <li>
                We are not charging for the questions available within the
                application.
              </li>
            </ul>
          </StyledTypography>
          <StyledTypography variant="h5" style={{ textAlign: "justify" }}>
            This is an annual billing process and applicable to each student.
            The student registration either by self, parent/guardian, teacher or
            institute is liable to pay the annual payment. The student whose
            payment is not done maybe unable to access the online examinations.
          </StyledTypography>
          <br />
          <br />
          {paymentDone ? (
            <StyledTableContainer component={Paper}>
              <StyledTable>
                <StyledTableHead>
                  <StyledTableRow>
                    {/* <StyledTableCell >Sr No.</StyledTableCell> */}
                    <StyledTableCell>Date of Payment</StyledTableCell>
                    <StyledTableCell>Download Receipt</StyledTableCell>
                    <StyledTableCell>End of Subscription</StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {/* <StyledTableCell >1</StyledTableCell> */}
                  <StyledTableCell>{getStartDate(billDate)}</StyledTableCell>
                  <StyledTableCell>
                    <Tooltip title="Download invoice">
                      <StyledButton
                        size="large"
                        disabled={loading}
                        startIcon={<Print />}
                        onClick={() =>
                          PdfMaker(
                            fUser,
                            state,
                            pincode,
                            GSTNo,
                            paymentId,
                            getStartDate(billDate),
                            getEndDate(billDate)
                          )
                        }
                      ></StyledButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell>{getEndDate(billDate)}</StyledTableCell>
                </StyledTableBody>
              </StyledTable>
            </StyledTableContainer>
          ) : null}
        </>
      ) : fUser.role === "Teacher" || fUser.role === "Institute" ? (
        <>
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "bold", textAlign: "justify" }}
          >
            {/* Rs.30 per student per exam(1 real exam plus 3 mock tests) for first
            50 students, Afterwards Rs.25 per student per exam(1 real exam plus
            3 mock tests). */}
            For the enterprise use please connect to{" "}
            <a href="mailto:admin@fenlei.co.in">admin@fenlei.co.in</a> or
            +919762938277 (Ms. Tejashree Gaidhani).
          </StyledTypography>
        </>
      ) : null}
    </div>
  );
}
