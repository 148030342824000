import * as Yup from "yup";

const mobileNoFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const SignUpValidationForm = Yup.object().shape({
  username: Yup.string()
    .email("Email is invalid, Check for empty spaces")
    .required("Please enter email address"),
  userRole: Yup.string().required("Please select user type"),
  firstName: Yup.string().when("userRole", (userRole, schema) => {
    if (userRole === "Student" || userRole === "Teacher")
      return schema.required("Please enter first name");
  }),
  lastName: Yup.string().when("userRole", (userRole, schema) => {
    if (userRole === "Student" || userRole === "Teacher")
      return schema.required("Please enter last name");
  }),
  grade: Yup.string().when("userRole", (userRole, schema) => {
    if (userRole === "Student")
      return schema.required("Please select your grade");
  }),
  instituteName: Yup.string().when("userRole", (userRole, schema) => {
    if (userRole === "Institute")
      return schema.required("Please enter Institute name");
  }),
  institutePincode: Yup.number().when("userRole", (userRole, schema) => {
    if (userRole === "Institute")
      return schema
        .typeError("Pin code should contain numbers only")
        .integer("Pin code must be a 6 digit number")
        .max(999999, "Pin code must be a 6 digit number")
        .min(100000, "Pin code must be a 6 digit number")
        .required("Please enter the Pin code");
  }),
  mobileNo: Yup.string()
    .required("Please enter your mobile number")
    .matches(
      mobileNoFormat,
      "Phone number is not valid. Don't add country code"
    ),
});

export default SignUpValidationForm;
