import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { enIN } from "date-fns/locale";
import { FormControl } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import { examsList } from "../../../constants/examsList";
import { quesTestTypes } from "../../../constants/quesTestType";
import { topics } from "../../../constants/topics";
import { gradesList } from "../../../constants/gradesList";
import { subjectList } from "../../../constants/subjects";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";

import {
  StyledTypography,
  StyledTextField,
  StyledInputLabel,
  StyledSelect,
  StyledMenuItem,
  StyledListItemText,
  StyledBox,
  StyledChip,
  StyledAutocomplete,
  StyledCheckbox,
  StyledButton,
  StyledTable,
  StyledTableContainer,
  StyledTableCell,
  StyledTableBody,
  StyledTableHead,
  StyledTableRow,
  HeaderDiv,
  TextAreaDiv,
  DropDownDiv,
  StyledTableTextField,
  TableDiv,
  TableButtonDiv,
  FiftyDiv,
  ThirtyDiv,
} from "../../../css/StyledComponents";

const createTestMutation = loader(
  "../../../graphqlCalls/test/UserSaveNewTest.gql"
);
const getTestsQuery = loader("../../../graphqlCalls/test/userGetAllTests.gql");
const teacherGetNoOfTestQuestionsMutation = loader(
  "../../../graphqlCalls/test/TeacherGetNoOfTestQuestions.gql"
);
const userGetExamsQuery = loader(
  "../../../graphqlCalls/exam/userGetAllExams.gql"
);

const d = new Date();
const initialFormValues = {
  testName: "",
  //   testDate: new Date(),
  testDate: "",
  // testDuration: d,
  // totalMarks: 0,
  Beginner: {
    TNF: 0,
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
    CaseStudy: 0,
  },
  Intermediate: {
    TNF: 0,
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
    CaseStudy: 0,
  },
  Advance: {
    TNF: 0,
    ObjSingleSol: 0,
    ObjMultiSol: 0,
    Subjective: 0,
    Integral: 0,
    CaseStudy: 0,
  },
  grades: [],
  compiExams: [],
  primaryTopic: [],
  secondaryTopic: [],
  testType: "",
  quesRepeated: "",
  subject: "Mathematics",
  testStartTime: d,
  testEndTime: d,
  exam: "",
  isOfExam: false,
  cutOff: 0,
};
const initialNoOfQues = {
  Beginner: {
    TNF: -1,
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
    CaseStudy: -1,
  },
  Intermediate: {
    TNF: -1,
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
    CaseStudy: -1,
  },
  Advance: {
    TNF: -1,
    ObjSingleSol: -1,
    ObjMultiSol: -1,
    Subjective: -1,
    Integral: -1,
    CaseStudy: -1,
  },
};
const initialMarks = {
  Beginner: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Intermediate: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Advance: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
};
function CreateTest(props) {
  const fUser = props.fUser;
  const navigate = useNavigate();

  const [formValues, setValues] = useState(initialFormValues);
  const [noOfQues, setNoOfQues] = useState(initialNoOfQues);
  const [quesMarks, setQuesMarks] = useState(initialMarks);
  const [fetchedExams, setFetchedExams] = useState([]);

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;

  let Exams = examsList;
  Exams.sort((a, b) => a.localeCompare(b));

  let pTopic = Object.keys(topics);
  pTopic.sort((a, b) => a.localeCompare(b));
  let sTopic = [];
  for (let i = 0; i < formValues.primaryTopic.length; i++) {
    let primTopic = formValues.primaryTopic[i];
    let secTopics = Object.keys(topics[primTopic]);
    for (let j = 0; j < secTopics.length; j++) {
      let secTopic = secTopics[j];
      let topicObj = {
        primaryTopic: primTopic,
        secondaryTopic: secTopic,
      };
      let topicObjStr = JSON.stringify(topicObj);
      sTopic.push(topicObjStr);
    }
  }
  for (let k = 0; k < formValues.secondaryTopic.length; k++) {
    let secTopic = formValues.secondaryTopic[k];
    let secTopicObj = JSON.parse(secTopic);
    let sResult = sTopic.some((st) => {
      let stObj = JSON.parse(st);
      return stObj.secondaryTopic === secTopicObj.secondaryTopic;
    });
    if (!sResult) {
      let index = formValues.secondaryTopic.indexOf(secTopic);
      if (index > -1) {
        formValues.secondaryTopic.splice(index, 1);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleBeginInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Beginner: { ...formValues.Beginner, [name]: parseInt(value) },
    });
  };
  const handleInterInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Intermediate: { ...formValues.Intermediate, [name]: parseInt(value) },
    });
  };
  const handleAdvInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      Advance: { ...formValues.Advance, [name]: parseInt(value) },
    });
  };

  const handleMksBegCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Beginner: {
        ...quesMarks.Beginner,
        [name]: { ...quesMarks.Beginner[name], correct: value },
      },
    });
  };
  const handleMksBegWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Beginner: {
        ...quesMarks.Beginner,
        [name]: {
          ...quesMarks.Beginner[name],
          wrong: value,
        },
      },
    });
  };
  const handleMksIntermCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Intermediate: {
        ...quesMarks.Intermediate,
        [name]: { ...quesMarks.Intermediate[name], correct: value },
      },
    });
  };
  const handleMksIntermWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Intermediate: {
        ...quesMarks.Intermediate,
        [name]: {
          ...quesMarks.Intermediate[name],
          wrong: value,
        },
      },
    });
  };
  const handleMksAdvCorrectInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Advance: {
        ...quesMarks.Advance,
        [name]: { ...quesMarks.Advance[name], correct: value },
      },
    });
  };
  const handleMksAdvWrongInputChange = (e) => {
    const { name, value } = e.target;
    setQuesMarks({
      ...quesMarks,
      Advance: {
        ...quesMarks.Advance,
        [name]: {
          ...quesMarks.Advance[name],
          wrong: value,
        },
      },
    });
  };
  const parseIntQuesNos = (quesNosIp) => {
    let parsedQuesNos = quesNosIp;
    for (var quesType in quesNosIp) {
      if (quesNosIp.hasOwnProperty(quesType)) {
        parsedQuesNos[quesType] = quesNosIp[quesType]
          ? parseInt(quesNosIp[quesType])
          : 0;
      }
    }
    return parsedQuesNos;
  };
  const parseIntQuesMarks = (quesMarksIp) => {
    let parsedQuesMarks = initialMarks;
    for (var quesLevel in quesMarksIp) {
      if (quesMarksIp.hasOwnProperty(quesLevel)) {
        for (var quesType in quesMarksIp[quesLevel]) {
          if (quesMarksIp[quesLevel].hasOwnProperty(quesType)) {
            for (var answerType in quesMarksIp[quesLevel][quesType]) {
              if (quesMarksIp[quesLevel][quesType].hasOwnProperty(answerType)) {
                parsedQuesMarks[quesLevel][quesType][answerType] = quesMarksIp[
                  quesLevel
                ][quesType][answerType]
                  ? parseInt(quesMarksIp[quesLevel][quesType][answerType])
                  : 0;
              }
            }
          }
        }
      }
    }
    return parsedQuesMarks;
  };
  const JsonParseTopics = () => {
    const submittedTopics = formValues.secondaryTopic.map((secTopic) => {
      const secTopicObj = JSON.parse(secTopic);
      return {
        primaryTopic: secTopicObj.primaryTopic,
        secondaryTopic: secTopicObj.secondaryTopic,
      };
    });
    for (let i = 0; i < formValues.primaryTopic.length; i++) {
      let found = submittedTopics.some(
        (el) => el.primaryTopic === formValues.primaryTopic[i]
      );
      if (!found)
        submittedTopics.push({
          primaryTopic: formValues.primaryTopic[i],
          secondaryTopic: "",
        });
    }
    return submittedTopics;
  };

  const [
    CreateTestMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createTestMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(err); //need to show this error cuz if no of selected level questions wala error need to be shown
    },
    onCompleted: () => {
      NotificationsSuccess("Submitted!");
    },
  });

  const [
    GetNoOfTestQuestionsMutation,
    { loading: gettingQues, error: errorGettingQues, data: dataGettingQues },
  ] = useMutation(teacherGetNoOfTestQuestionsMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong!");
    },
    onCompleted: ({ TeacherGetNoOfTestQuestions }) => {
      setNoOfQues(TeacherGetNoOfTestQuestions);
    },
  });

  const updateCacheCreating = (client, { data: { UserSaveNewTest } }) => {
    const data = client.readQuery({
      query: getTestsQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
      },
    });
    const newTest = UserSaveNewTest;
    if (data) {
      const newTestList = {
        userGetAllTests: [newTest, ...data.userGetAllTests],
      };
      client.writeQuery({
        query: getTestsQuery,
        variables: {
          userId: fUser._id,
          userT: fUser.role,
        },
        data: newTestList,
      });
    }
  };

  const getNoOfQues = async () => {
    if (gettingQues) return;
    try {
      const callInfo = await GetNoOfTestQuestionsMutation({
        variables: {
          input: {
            teacherId: fUser ? fUser._id : null,
            test: {
              grade: formValues.grades,
              compiExams: formValues.compiExams,
              topics: JsonParseTopics(),
              testType: formValues.testType,
              quesRepeated: formValues.quesRepeated,
              subject: formValues.subject,
            },
          },
        },
      });
    } catch (error) {}
  };
  const getTimeDuration = (duration) => {
    let hours = duration.getHours();
    let mins = duration.getMinutes();
    let hoursString = hours >= 10 ? `${hours}` : `0${hours}`;
    let minsString = mins >= 10 ? `${mins}` : `0${mins}`;
    return `${hoursString}:${minsString}`;
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); //whats this for?
    if (creating) return;
    try {
      const callInfo = await CreateTestMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            test: {
              name: formValues.testName,
              ofDate: formValues.testDate,
              // duration: getTimeDuration(formValues.testDuration),
              testType: formValues.testType,
              quesRepeated: formValues.quesRepeated,
              grade: formValues.grades,
              compiExams: formValues.compiExams,
              topics: JsonParseTopics(),
              quesLevels: {
                Beginner: parseIntQuesNos(formValues.Beginner),
                Intermediate: parseIntQuesNos(formValues.Intermediate),
                Advance: parseIntQuesNos(formValues.Advance),
              },
              quesMarks: parseIntQuesMarks(quesMarks),
              subject: formValues.subject,
              testStartTime: formValues.testStartTime?.toString(),
              testEndTime: formValues.testEndTime?.toString(),
              exam: formValues.exam,
              isOfExam: formValues.isOfExam,
              cutOff: parseInt(formValues.cutOff),
            },
          },
        },
        update: updateCacheCreating,
      });

      navigate("/test", {
        state: {
          id: callInfo.data.UserSaveNewTest._id,
          isOfExam: formValues.isOfExam,
        },
      });
    } catch (error) {}
  };

  const quesTable = [
    {
      handleChange: handleBeginInputChange,
      stateName: "Beginner",
      rowName: "Beginner",
      mksCorrectChange: handleMksBegCorrectInputChange,
      mksWrongChange: handleMksBegWrongInputChange,
    },
    {
      handleChange: handleInterInputChange,
      stateName: "Intermediate",
      rowName: "Intermediary",
      mksCorrectChange: handleMksIntermCorrectInputChange,
      mksWrongChange: handleMksIntermWrongInputChange,
    },
    {
      handleChange: handleAdvInputChange,
      stateName: "Advance",
      rowName: "Advanced",
      mksCorrectChange: handleMksAdvCorrectInputChange,
      mksWrongChange: handleMksAdvWrongInputChange,
    },
  ];
  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <form onSubmit={handleSubmit}>
      {NotificationsContainer()}
      <div>
        <HeaderDiv>
          <StyledTypography variant="h6">Create Test</StyledTypography>
        </HeaderDiv>
        <TextAreaDiv>
          <FiftyDiv>
            <StyledTextField
              name="testName"
              variant="filled"
              label="Test Name"
              value={formValues.testName}
              onChange={handleInputChange}
            />
          </FiftyDiv>
          <FiftyDiv>
            <FormControl>
              <StyledInputLabel>Test Type:</StyledInputLabel>
              <StyledSelect
                name="testType"
                value={formValues.testType}
                onChange={handleInputChange}
                label="Test Type"
              >
                {quesTestTypes.map((qTestType, index) => {
                  return (
                    <StyledMenuItem key={index} value={qTestType}>
                      {qTestType}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </FiftyDiv>
        </TextAreaDiv>
        <TextAreaDiv>
          <FiftyDiv>
            <FormControl>
              <StyledInputLabel>Going to be assigned to Exam?</StyledInputLabel>
              <StyledSelect
                name="isOfExam"
                value={formValues.isOfExam}
                onChange={handleInputChange}
                MenuProps={MenuProps}
                label="Going to be assigned to Exam?"
              >
                <StyledMenuItem value={true}>
                  <StyledListItemText primary={"Yes"} />
                </StyledMenuItem>
                <StyledMenuItem value={false}>
                  <StyledListItemText primary={"No"} />
                </StyledMenuItem>
              </StyledSelect>
            </FormControl>
          </FiftyDiv>
          <FiftyDiv>
            <StyledTextField
              type="number"
              name="cutOff"
              onChange={handleInputChange}
              value={formValues.cutOff}
              label="Cut Off"
            />
          </FiftyDiv>
        </TextAreaDiv>
        {/* Dropdowns start here */}

        <DropDownDiv>
          <FiftyDiv style={{ display: "flex", alignItems: "end" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enIN}
            >
              <TimePicker
                name="testStartTime"
                label="Test Start Time"
                value={formValues.testStartTime}
                sx={{ margin: "10px 7px 0 7px" }}
                onChange={(newValue) => {
                  newValue.setSeconds(0);
                  setValues({
                    ...formValues,
                    testStartTime: newValue,
                  });
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={enIN}
            >
              <TimePicker
                name="testEndTime"
                label="Test End Time"
                value={formValues.testEndTime}
                sx={{ margin: "10px 7px 0 7px" }}
                onChange={(newValue) => {
                  newValue.setSeconds(0);
                  setValues({
                    ...formValues,
                    testEndTime: newValue,
                  });
                }}
              />
            </LocalizationProvider>
          </FiftyDiv>
          <FiftyDiv>
            <StyledTextField
              name="testDate"
              variant="filled"
              label="Test Date"
              value={formValues.testDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
              type="date"
            />
          </FiftyDiv>
        </DropDownDiv>

        <DropDownDiv>
          <ThirtyDiv style={{ display: "flex", alignItems: "end" }}>
            <FormControl>
              <StyledInputLabel>Grade:</StyledInputLabel>
              <StyledSelect
                name="grades"
                id="grade"
                label="Grade:"
                multiple
                value={formValues.grades}
                onChange={handleInputChange}
                renderValue={(selected) => (
                  <StyledBox
                    style={{ display: "flex", flexWrap: "wrap", padding: 0.7 }}
                  >
                    {selected.map((value) => (
                      <StyledChip key={value} label={value} />
                    ))}
                  </StyledBox>
                )}
                MenuProps={MenuProps}
              >
                {gradesList.map((grade, index) => {
                  return (
                    <StyledMenuItem key={index} value={grade}>
                      <StyledCheckbox
                        checked={formValues.grades.indexOf(grade) > -1}
                      />
                      <StyledListItemText primary={grade} />
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </ThirtyDiv>
          <ThirtyDiv>
            <FormControl>
              <StyledInputLabel shrink>Exams:</StyledInputLabel>
              <StyledAutocomplete
                multiple
                options={Exams}
                disableCloseOnSelect
                value={formValues.compiExams}
                onChange={(event, value) => {
                  setValues({ ...formValues, compiExams: value });
                }}
                getOptionLabel={(option) => option}
                // renderOption={(option, { selected }) => (
                //   <React.Fragment>
                //     <StyledCheckbox
                //       icon={icon}
                //       checkedIcon={checkedIcon}
                //       checked={selected}
                //     />
                //     {option}
                //   </React.Fragment>
                // )}
                renderInput={(params) => (
                  <StyledTextField {...params} onChange={handleInputChange} />
                )}
              />
            </FormControl>
          </ThirtyDiv>
          <ThirtyDiv style={{ display: "flex", alignItems: "end" }}>
            <FormControl>
              <StyledInputLabel>Repeat Questions:</StyledInputLabel>
              <StyledSelect
                name="quesRepeated"
                label="Repeat Questions:"
                value={formValues.quesRepeated}
                onChange={handleInputChange}
              >
                <StyledMenuItem value={true}>Yes</StyledMenuItem>
                <StyledMenuItem value={false}>No</StyledMenuItem>
              </StyledSelect>
            </FormControl>
          </ThirtyDiv>
        </DropDownDiv>
        <DropDownDiv>
          <ThirtyDiv style={{ display: "flex", alignItems: "end" }}>
            <FormControl>
              <StyledInputLabel>Subject:</StyledInputLabel>
              <StyledSelect
                label="Subject"
                name="subject"
                disabled={creating || gettingQues}
                value={formValues.subject}
                onChange={handleInputChange}
              >
                {subjectList.map((subject, index) => {
                  return (
                    <StyledMenuItem key={index} value={subject}>
                      {subject}
                    </StyledMenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </ThirtyDiv>
          <ThirtyDiv>
            <FormControl>
              <StyledInputLabel shrink htmlFor="topic1">
                Primary Topic:
              </StyledInputLabel>
              <StyledAutocomplete
                multiple
                options={pTopic}
                disableCloseOnSelect
                value={formValues.primaryTopic}
                getOptionLabel={(option) => {
                  return option;
                }}
                onChange={(event, value) => {
                  setValues({ ...formValues, primaryTopic: value });
                }}
                // renderOption={(option, { selected }) => (
                //   <React.Fragment>
                //     <StyledCheckbox
                //       icon={icon}
                //       checkedIcon={checkedIcon}
                //       checked={selected}
                //     />
                //     {option}
                //   </React.Fragment>
                // )}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </FormControl>
          </ThirtyDiv>
          <ThirtyDiv>
            <FormControl>
              <StyledInputLabel shrink htmlFor="topic2">
                Secondary Topic:
              </StyledInputLabel>
              <StyledAutocomplete
                multiple
                options={sTopic}
                disableCloseOnSelect
                value={formValues.secondaryTopic}
                getOptionLabel={(option) => {
                  let jsonOption = JSON.parse(option);
                  return jsonOption.secondaryTopic;
                }}
                onChange={(event, value) => {
                  setValues({ ...formValues, secondaryTopic: value });
                }}
                // renderOption={(option, { selected }) => {
                //   let jsonOption = JSON.parse(option);
                //   return (
                //     <React.Fragment>
                //       <StyledCheckbox
                //         icon={icon}
                //         checkedIcon={checkedIcon}
                //         checked={selected}
                //       />
                //       {jsonOption.secondaryTopic}
                //     </React.Fragment>
                //   );
                // }}
                renderInput={(params) => <StyledTextField {...params} />}
              />
            </FormControl>
          </ThirtyDiv>
        </DropDownDiv>

        <br />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledButton
            variant="contained"
            onClick={async () => {
              await getNoOfQues();
            }}
            disabled={creating || gettingQues} //make sure disabled
          >
            Get No Of available Questions
          </StyledButton>
        </div>

        {/* StyledTable starts here */}
        <TableButtonDiv>
          <StyledTableContainer component={Paper}>
            <StyledTable aria-label="customized table">
              <StyledTableHead>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="center">
                    TRUE or FALSE Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Single Objective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Multi Objective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Subjective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Integer Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Case Study Question
                  </StyledTableCell>
                  <StyledTableCell align="center">Total</StyledTableCell>
                </StyledTableRow>
              </StyledTableHead>

              <StyledTableBody>
                {quesTable.map((item, index) => {
                  return (
                    <StyledTableRow key={`noq${item.rowName}${index}`}>
                      <StyledTableCell component="th" scope="row">
                        {item.rowName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["TNF"]}
                            name="TNF"
                            onChange={item.handleChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["TNF"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["ObjSingleSol"]}
                            name="ObjSingleSol"
                            onChange={item.handleChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["ObjSingleSol"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["ObjMultiSol"]}
                            name="ObjMultiSol"
                            onChange={item.handleChange}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["ObjMultiSol"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["Subjective"]}
                            name="Subjective"
                            onChange={item.handleChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["Subjective"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["Integral"]}
                            name="Integral"
                            onChange={item.handleChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["Integral"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={formValues[item.stateName]["CaseStudy"]}
                            name="CaseStudy"
                            onChange={item.handleChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={noOfQues[item.stateName]["CaseStudy"]}
                            disabled
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <StyledTableTextField
                          type="number"
                          style={{ width: "60%" }}
                          disabled
                          value={
                            parseInt(formValues[item.stateName]["TNF"]) +
                            parseInt(
                              formValues[item.stateName]["ObjSingleSol"]
                            ) +
                            parseInt(
                              formValues[item.stateName]["ObjMultiSol"]
                            ) +
                            parseInt(formValues[item.stateName]["Subjective"]) +
                            parseInt(formValues[item.stateName]["Integral"]) +
                            parseInt(formValues[item.stateName]["CaseStudy"])
                          }
                          variant="outlined"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>

          <StyledTypography variant="h5">Marks per Question</StyledTypography>
          <StyledTableContainer component={Paper}>
            <StyledTable aria-label="customized table">
              <StyledTableHead>
                <StyledTableRow>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="center">
                    TRUE or FALSE Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Single Objective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Multi Objective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Subjective Question
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Integer Question
                  </StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </StyledTableRow>
              </StyledTableHead>
              <StyledTableBody>
                {quesTable.map((item, index) => {
                  return (
                    <StyledTableRow key={`mks${item.rowName}${index}`}>
                      <StyledTableCell component="th" scope="row">
                        {item.rowName}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={quesMarks[item.stateName]["TNF"]["correct"]}
                            name="TNF"
                            onChange={item.mksCorrectChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={quesMarks[item.stateName]["TNF"]["wrong"]}
                            name="TNF"
                            onChange={item.mksWrongChange}
                            // InputProps={{ inputProps: { max: 0 } }}
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["ObjSingleSol"][
                                "correct"
                              ]
                            }
                            name="ObjSingleSol"
                            onChange={item.mksCorrectChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["ObjSingleSol"]["wrong"]
                            }
                            name="ObjSingleSol"
                            onChange={item.mksWrongChange}
                            // InputProps={{ inputProps: { max: 0 } }}
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["ObjMultiSol"][
                                "correct"
                              ]
                            }
                            name="ObjMultiSol"
                            onChange={item.mksCorrectChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["ObjMultiSol"]["wrong"]
                            }
                            name="ObjMultiSol"
                            onChange={item.mksWrongChange}
                            // InputProps={{ inputProps: { max: 0 } }}
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["Subjective"]["correct"]
                            }
                            name="Subjective"
                            onChange={item.mksCorrectChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["Subjective"]["wrong"]
                            }
                            name="Subjective"
                            onChange={item.mksWrongChange}
                            // InputProps={{ inputProps: { max: 0 } }}
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <TableDiv>
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["Integral"]["correct"]
                            }
                            name="Integral"
                            onChange={item.mksCorrectChange}
                            // InputProps={{ inputProps: { min: 0 } }}
                            variant="outlined"
                          />
                          <StyledTableTextField
                            type="number"
                            value={
                              quesMarks[item.stateName]["Integral"]["wrong"]
                            }
                            name="Integral"
                            onChange={item.mksWrongChange}
                            // InputProps={{ inputProps: { max: 0 } }}
                            variant="outlined"
                          />
                        </TableDiv>
                      </StyledTableCell>

                      <StyledTableCell align="center"></StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
          <div>
            <StyledButton
              type="submit"
              variant="contained"
              value="Submit"
              disabled={creating || gettingQues} //make sure disabled
            >
              submit
            </StyledButton>
          </div>
        </TableButtonDiv>
      </div>
    </form>
  );
}

export default CreateTest;
