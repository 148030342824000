import * as Yup from "yup";
import YupPassword from "yup-password";
YupPassword(Yup);

const SignInValidationForm = Yup.object().shape({
  username: Yup.string().when("$modeNewPassword", (modeNewPassword, schema) => {
    if (!modeNewPassword[0])
      return schema
        .email("Email is invalid, Check for empty spaces")
        .required("Please enter email address");
  }),
  password: Yup.string().when("$modeNewPassword", (modeNewPassword, schema) => {
    if (!modeNewPassword[0])
      return schema.required("Please enter your password");
  }),
  newPassword: Yup.string().when(
    "$modeNewPassword",
    (modeNewPassword, schema) => {
      if (modeNewPassword[0])
        return schema
          .required("Please enter your new password")
          .min(
            8,
            "Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
          )
          .minLowercase(1, "password must contain at least 1 lower case letter")
          .minUppercase(1, "password must contain at least 1 upper case letter")
          .minNumbers(1, "password must contain at least 1 number")
          .minSymbols(1, "password must contain at least 1 special character");
    }
  ),
  confirmNewPassword: Yup.string().when(
    "$modeNewPassword",
    (modeNewPassword, schema) => {
      if (modeNewPassword[0])
        return schema
          .required("Please re-enter your new password")
          .when("newPassword", {
            is: (newPassword) =>
              newPassword && newPassword.length > 0 ? true : false,
            then: () =>
              Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "Password doesn't match"
              ),
          });
    }
  ),
  newName: Yup.string().when("$nameReq", (nameReq, schema) => {
    if (nameReq[0]) return schema.required("Please enter your name");
  }),
});

export default SignInValidationForm;
