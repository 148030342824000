import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import ReactPaginate from "react-paginate";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import "../../../css/content.css";
import QuestionListCourseCard from "./QuestionListCourseCard";

import { LoadingSpinner } from "../../../constants/loadingSpinner";

import { examsList } from "../../../constants/examsList";
import { quesTestTypes } from "../../../constants/quesTestType";
import { topics } from "../../../constants/topics";
import { gradesList } from "../../../constants/gradesList";
import { subjectList } from "../../../constants/subjects";
import { quesLangList } from "../../../constants/quesLangList";
import {
  FilterDiv,
  StyledAutocomplete,
  StyledBox,
  StyledButton,
  StyledCheckbox,
  StyledChip,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
  StyledGrid,
  StyledDialog,
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
  ColumnFlexDiv,
  StyledListItemText,
  ListDiv,
} from "../../../css/StyledComponents";

const getQuestionsQuery = loader(
  "../../../graphqlCalls/question/userGetAllQuestions.gql"
);
const initialFormValues = {
  grade: "",
  level: "",
  compiExams: [],
  quesLangs: [quesLangList[0]],
  bloomTags: [],
  primaryTopic: [],
  secondaryTopic: [],
  tertiaryTopic: [],
  quesType: "",
  quesTestType: "",
  subject: "Mathematics",
};

const QuestionList = (props) => {
  const [formValues, setValues] = useState(initialFormValues);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);

  const filterDialog = props.filterDialog;
  const setFilterDialog = props.setFilterDialog;
  const fUser = props.fUser;

  // CHANGE IN CREATE QUESTION IF ANY CHANGE HERE
  const quesTypes = [
    "TNF",
    "ObjSingleSol",
    "ObjMultiSol",
    "Integral",
    "Subjective",
    "CaseStudy"
  ];

  // CHANGE IN CREATE QUESTION IF ANY CHANGE HERE
  const levelList = ["Beginner", "Intermediate", "Advance"];

  // CHANGE IN CREATE QUESTION IF ANY CHANGE HERE
  const bloomTagsList = [
    "Create",
    "Evaluate",
    "Analyze",
    "Apply",
    "Understand",
    "Remember",
  ];
  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;

  let Exams = examsList;
  Exams.sort((a, b) => a.localeCompare(b));
  let pTopic = Object.keys(topics);
  pTopic.sort((a, b) => a.localeCompare(b));
  let sTopic = [];
  for (let i = 0; i < formValues.primaryTopic.length; i++) {
    let primTopic = formValues.primaryTopic[i];
    let secTopics = Object.keys(topics[primTopic]);
    if (secTopics) {
      for (let j = 0; j < secTopics.length; j++) {
        let secTopic = secTopics[j];
        let topicObj = {
          primaryTopic: primTopic,
          secondaryTopic: secTopic,
        };
        let topicObjStr = JSON.stringify(topicObj);
        sTopic.push(topicObjStr);
      }
    }
  }
  for (let k = 0; k < formValues.secondaryTopic.length; k++) {
    let secTopic = formValues.secondaryTopic[k];
    let secTopicObj = JSON.parse(secTopic);
    let sResult = sTopic.some((st) => {
      let stObj = JSON.parse(st);
      return stObj.secondaryTopic === secTopicObj.secondaryTopic;
    });
    if (!sResult) {
      let index = formValues.secondaryTopic.indexOf(secTopic);
      if (index > -1) {
        formValues.secondaryTopic.splice(index, 1);
      }
    }
  }
  let tTopic = [];
  for (let j = 0; j < formValues.secondaryTopic.length; j++) {
    let secTopic = formValues.secondaryTopic[j];
    let secTopicObj = JSON.parse(secTopic);

    let terTopics =
      topics[secTopicObj.primaryTopic][secTopicObj.secondaryTopic];
    if (terTopics) {
      for (let k = 0; k < terTopics.length; k++) {
        let terTopic = terTopics[k];
        let topicObj = {
          primaryTopic: secTopicObj.primaryTopic,
          secondaryTopic: secTopicObj.secondaryTopic,
          tertiaryTopic: terTopic,
        };
        let topicObjStr = JSON.stringify(topicObj);
        tTopic.push(topicObjStr);
      }
    }
  }
  for (let k = 0; k < formValues.tertiaryTopic.length; k++) {
    let terTopic = formValues.tertiaryTopic[k];
    let terTopicObj = JSON.parse(terTopic);
    let sResult = tTopic.some((st) => {
      let stObj = JSON.parse(st);
      return stObj.tertiaryTopic === terTopicObj.tertiaryTopic;
    });
    if (!sResult) {
      let index = formValues.tertiaryTopic.indexOf(terTopic);
      if (index > -1) {
        formValues.tertiaryTopic.splice(index, 1);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const clearFilter = () => {
    setFilteredQuestions(questions);
  };
  const handleFilter = () => {
    let filtered = questions.filter((ques) => {
      let quesLangList = ques.ques.map((qo) => qo.language);
      let quesPrimaryTopicList = ques.topics.map((qo) => qo.primaryTopic);
      let quesSecondaryTopicList = ques.topics.map((qo) => qo.secondaryTopic);
      let quesTertiaryTopicList = ques.topics.map((qo) => qo.tertiaryTopic);

      return (
        (!formValues.grade || ques.gradeLevel[0].grade == formValues.grade) &&
        (!formValues.level || ques.gradeLevel[0].level == formValues.level) &&
        (!formValues.quesType || ques.quesType == formValues.quesType) &&
        (!formValues.quesTestType ||
          ques.quesTestType == formValues.quesTestType) &&
        (!formValues.subject || ques.subject == formValues.subject) &&
        (formValues.compiExams.length == 0 ||
          formValues.compiExams.some((ce) => ques.compiExam.includes(ce))) &&
        (formValues.bloomTags.length == 0 ||
          formValues.bloomTags.some((bt) => ques.bloomTags.includes(bt))) &&
        (formValues.quesLangs.length == 0 ||
          formValues.quesLangs.some((ql) => quesLangList.includes(ql))) &&
        (formValues.primaryTopic.length == 0 ||
          formValues.primaryTopic.some((pt) =>
            quesPrimaryTopicList.includes(pt)
          )) &&
        (formValues.secondaryTopic.length == 0 ||
          formValues.secondaryTopic.some((st) =>
            quesSecondaryTopicList.includes(st)
          )) &&
        (formValues.tertiaryTopic.length == 0 ||
          formValues.tertiaryTopic.some((tt) =>
            quesTertiaryTopicList.includes(tt)
          ))
      );
    });

    setFilteredQuestions(filtered);
  };

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getQuestionsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onCompleted: ({ userGetAllQuestions }) => {
        setQuestions(userGetAllQuestions);
        setFilteredQuestions(userGetAllQuestions);
      },
      onError: (error) => {
        alert("Something went wrong");
      },
    }
  );

  let quesPerPage = 9;

  const pagesVisited = pageNumber * quesPerPage;

  const displayQuestions = filteredQuestions
    ? filteredQuestions
        .slice(pagesVisited, pagesVisited + quesPerPage)
        .map((ques) => {
          return (
            <StyledGrid key={ques._id} item xs={12} sm={4}>
              <QuestionListCourseCard
                id={ques._id}
                topics={ques.topics}
                question={ques.ques}
                quesLangs={ques.quesLangs}
                quesType={ques.quesType}
                options={ques.quesOptions}
                fUser={fUser}
              />
            </StyledGrid>
          );
        })
    : [];

  const pageCount = filteredQuestions
    ? Math.ceil(filteredQuestions.length / quesPerPage)
    : 0;

  const changepage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <ListDiv>
      <StyledDialog maxWidth="lg" open={filterDialog}>
        <StyledDialogTitle>Please Apply Filters</StyledDialogTitle>
        <StyledDialogContent>
          <div style={{ padding: 20 }}>
            <FilterDiv>
              <ColumnFlexDiv>
                <StyledInputLabel>Grade:</StyledInputLabel>
                <StyledSelect
                  name="grade"
                  id="grade"
                  value={formValues.grade}
                  onChange={handleInputChange}
                >
                  {gradesList.map((grade, index) => {
                    return (
                      <StyledMenuItem key={index} value={grade}>
                        {grade}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel>Level:</StyledInputLabel>
                <StyledSelect
                  name="level"
                  value={formValues.level}
                  onChange={handleInputChange}
                  MenuProps={MenuProps}
                >
                  {levelList.map((level, index) => {
                    return (
                      <StyledMenuItem key={index} value={level}>
                        {level}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
            </FilterDiv>
            <FilterDiv>
              <ColumnFlexDiv>
                <StyledInputLabel>Exams:</StyledInputLabel>
                <StyledAutocomplete
                  multiple
                  options={Exams}
                  disableCloseOnSelect
                  // name="compiExams"
                  value={formValues.compiExams}
                  // onChange={handleInputChange}
                  onChange={(event, value) => {
                    // console.log(value)
                    setValues({ ...formValues, compiExams: value });
                  }}
                  // onInputChange={handleInputChange}
                  getOptionLabel={(option) => option}
                  // renderOption={(option, { selected }) => (
                  //   <React.Fragment>
                  //     <StyledCheckbox
                  //       icon={icon}
                  //       checkedIcon={checkedIcon}
                  //       checked={selected}
                  //     />
                  //     {option}
                  //   </React.Fragment>
                  // )}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      style={{ margin: 0 }}
                      onChange={handleInputChange}
                    />
                  )}
                />
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel>Bloom Taxonomy:</StyledInputLabel>
                <StyledSelect
                  name="bloomTags"
                  multiple
                  value={formValues.bloomTags}
                  onChange={handleInputChange}
                  renderValue={(selected) => (
                    <StyledBox>
                      {selected.map((value) => (
                        <StyledChip key={value} label={value} />
                      ))}
                    </StyledBox>
                  )}
                  MenuProps={MenuProps}
                >
                  {bloomTagsList.map((tag, index) => {
                    return (
                      <StyledMenuItem key={index} value={tag}>
                        <StyledCheckbox
                          checked={formValues.bloomTags.indexOf(tag) > -1}
                        />
                        <StyledListItemText primary={tag} />
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
            </FilterDiv>
            <FilterDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="grade">Subject</StyledInputLabel>
                <StyledSelect
                  label="Subject"
                  name="subject"
                  value={formValues.subject}
                  onChange={handleInputChange}
                  MenuProps={MenuProps}
                >
                  {subjectList.map((subject, index) => {
                    return (
                      <StyledMenuItem key={index} value={subject}>
                        {subject}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="exam">
                  Ques Languages:
                </StyledInputLabel>
                <StyledAutocomplete
                  multiple
                  options={quesLangList}
                  disableCloseOnSelect
                  // name="compiExams"
                  value={formValues.quesLangs}
                  // onChange={handleInputChange}
                  onChange={(event, value) => {
                    setValues({
                      ...formValues,
                      quesLangs: value,
                    });
                  }}
                  // onInputChange={handleInputChange}
                  getOptionLabel={(option) => option}
                  // renderOption={(option, { selected }) => (
                  //   <React.Fragment>
                  //     <StyledCheckbox
                  //       icon={icon}
                  //       checkedIcon={checkedIcon}
                  //       checked={selected}
                  //     />
                  //     {option}
                  //   </React.Fragment>
                  // )}
                  renderInput={(params) => (
                    <StyledTextField style={{ margin: 0 }} {...params} />
                  )}
                />
              </ColumnFlexDiv>
            </FilterDiv>
            <FilterDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="grade">Test Type:</StyledInputLabel>
                <StyledSelect
                  name="quesTestType"
                  value={formValues.quesTestType}
                  onChange={handleInputChange}
                  MenuProps={MenuProps}
                >
                  {quesTestTypes.map((qTestType, index) => {
                    return (
                      <StyledMenuItem key={index} value={qTestType}>
                        {qTestType}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="grade">Ques Type:</StyledInputLabel>
                <StyledSelect
                  name="quesType"
                  value={formValues.quesType}
                  onChange={handleInputChange}
                  MenuProps={MenuProps}
                >
                  {quesTypes.map((qType, index) => {
                    return (
                      // CHANGE IN CREATE QUESTION IF ANY CHANGE HERE
                      <StyledMenuItem key={index} value={qType}>
                        {qType === "Integral"
                          ? "Integer Solution"
                          : qType === "ObjSingleSol"
                          ? "Single Objective"
                          : qType === "ObjMultiSol"
                          ? "Multi Objective"
                          : qType === "TNF"
                          ? "TRUE or FALSE"
                          : qType}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </ColumnFlexDiv>
            </FilterDiv>
            <FilterDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="exam">
                  Primary Topic:
                </StyledInputLabel>
                <StyledAutocomplete
                  multiple
                  options={pTopic}
                  disableCloseOnSelect
                  // name="primaryTopic"
                  value={formValues.primaryTopic}
                  getOptionLabel={(option) => {
                    // console.log("ptopic option", option);
                    return option;
                  }}
                  onChange={(event, value) => {
                    setValues({ ...formValues, primaryTopic: value });
                  }}
                  // onChange={handleInputChange}
                  // renderOption={(option, { selected }) => (
                  //   <React.Fragment>
                  //     <StyledCheckbox
                  //       icon={icon}
                  //       checkedIcon={checkedIcon}
                  //       checked={selected}
                  //     />
                  //     {option}
                  //   </React.Fragment>
                  // )}
                  renderInput={(params) => (
                    <StyledTextField style={{ margin: 0 }} {...params} />
                  )}
                />
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="exam">
                  Secondary Topic:
                </StyledInputLabel>
                <StyledAutocomplete
                  multiple
                  options={sTopic}
                  disableCloseOnSelect
                  value={formValues.secondaryTopic}
                  getOptionLabel={(option) => {
                    let jsonOption = JSON.parse(option);
                    return jsonOption.secondaryTopic;
                  }}
                  onChange={(event, value) => {
                    setValues({ ...formValues, secondaryTopic: value });
                  }}
                  // onChange={handleInputChange}
                  // renderOption={(option, { selected }) => {
                  //   let jsonOption = JSON.parse(option);
                  //   return (
                  //     <React.Fragment>
                  //       <StyledCheckbox
                  //         icon={icon}
                  //         checkedIcon={checkedIcon}
                  //         checked={selected}
                  //       />
                  //       {jsonOption.secondaryTopic}
                  //     </React.Fragment>
                  //   );
                  // }}
                  renderInput={(params) => (
                    <StyledTextField style={{ margin: 0 }} {...params} />
                  )}
                />
              </ColumnFlexDiv>
              <ColumnFlexDiv>
                <StyledInputLabel htmlFor="exam">
                  Tertiary Topic:
                </StyledInputLabel>
                <StyledAutocomplete
                  multiple
                  options={tTopic}
                  disableCloseOnSelect
                  value={formValues.tertiaryTopic}
                  getOptionLabel={(option) => {
                    let jsonOption = JSON.parse(option);
                    return jsonOption.tertiaryTopic;
                  }}
                  onChange={(event, value) => {
                    setValues({ ...formValues, tertiaryTopic: value });
                  }}
                  // onChange={handleInputChange}
                  // renderOption={(option, { selected }) => {
                  //   let jsonOption = JSON.parse(option);
                  //   return (
                  //     <React.Fragment>
                  //       <StyledCheckbox
                  //         icon={icon}
                  //         checkedIcon={checkedIcon}
                  //         checked={selected}
                  //       />
                  //       {jsonOption.tertiaryTopic}
                  //     </React.Fragment>
                  //   );
                  // }}
                  renderInput={(params) => (
                    <StyledTextField style={{ margin: 0 }} {...params} />
                  )}
                />
              </ColumnFlexDiv>
            </FilterDiv>
          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={() => {
              setValues(initialFormValues);
              clearFilter();
              setFilterDialog(false);
            }}
          >
            Clear
          </StyledButton>
          <StyledButton
            onClick={() => {
              handleFilter();
              setFilterDialog(false);
            }}
          >
            Apply
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              setFilterDialog(false);
            }}
          >
            Cancel
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      {filteredQuestions.length != 0 ? (
        <StyledGrid container spacing={2}>
          {displayQuestions}
        </StyledGrid>
      ) : null}
      {filteredQuestions.length != 0 ? (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={changepage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"paginationLink"}
          nextLinkClassName={"paginationLink"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      ) : null}
      <br />
      {LoadingSpinner(loading || !fUser)}
    </ListDiv>
  );
};

export default QuestionList;
