import * as Yup from "yup";

const yesterday = new Date(Date.now() - 86400000);

const CreateExamValidationForm = Yup.object().shape({
  examName: Yup.string().required("Please enter Exam Name"),
  regStart: Yup.date()
    .typeError("Please select registration Start date")
    .min(yesterday, "Date cannot be in the past")
    .required("Please select registration Start date"),
  regEnd: Yup.date()
    .typeError("Please select registration End date")
    .min(
      Yup.ref("regStart"),
      "Registration end date should be after Start date"
    )
    .required("Please select registration Start date"),
  // .when("regStart", (regStart, schema) => {
  //   if (regStart === `${regStart}`) {
  //     return schema.min(
  //       `${regStart}`,
  //       "Registration end date should be after Start date"
  //     );
  //   }
  // })
  // Yup()
  //   .date()
  //   .typeError("Please select registration end date")
  //   .min(
  //     new Date(),
  //     "Registration End date should not be same or before registration Start date"
  //   )
  //   .required("Please select registration End date"),

  // Yup.date().when(
  //   "regStart",
  //   (regStart, schema) => regStart && schema.min(regStart)
  // ),
  // Yup.date().when(
  //   "regStart",
  //   (regStart, schema) =>
  //     regStart &&
  //     schema
  //       .min(regStart, "Registration end date cannot be before start date")
  //       .required("Please enter registration End date")
  // ),
  grades: Yup.array()
    .typeError("Please select grades")
    .required("Please select grades"),
  individualFee: Yup.number()
    .typeError("Please enter fee for Individual registrations")
    .min(0, "Fees cannot be negative")
    .required("Please enter fee for Individual registrations"),
  bulkFee: Yup.number()
    .typeError("Please enter fee for Bulk registrations")
    .min(0, "Fees cannot be negative")
    .required("Please enter fee for Bulk registrations"),
});

export default CreateExamValidationForm;
