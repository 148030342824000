import React from "react";
import { Link } from "react-router-dom";

import {
  StyledCardButton,
  StyledCardSpan,
  StyledCard,
  StyledTypography,
  CardDiv,
  StyledCardActions,
  StyledCardContent,
} from "../../css/StyledComponents";

const CourseCard = (props) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypography>
          Primary Topic: <StyledCardSpan> {props.primaryTopic}</StyledCardSpan>
        </StyledTypography>
        <StyledTypography>
          Secondary Topic:{" "}
          <StyledCardSpan> {props.secondaryTopic}</StyledCardSpan>
        </StyledTypography>
      </StyledCardContent>
      <StyledCardActions>
        <CardDiv>
          <Link
            exact
            to={"/course"}
            state={{
              id: props.id,
              primaryTopic: props.primaryTopic,
              secondaryTopic: props.secondaryTopic,
              gradeLevel: props.gradeLevel,
            }}
            style={{ textDecoration: "none" }}
          >
            <StyledCardButton variant="outlined" size="medium">
              Open
            </StyledCardButton>
          </Link>
        </CardDiv>
      </StyledCardActions>
    </StyledCard>
  );
};

export default CourseCard;
