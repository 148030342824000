import React, { useState, useEffect } from "react";

import Header from "../header/Header";
import TestContent from "./testList/TestContent";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";

const Tests = (props) => {
  const flag = 3;
  const fUser = props.fUser;
  const setUrl = props.setUrl;

  useEffect(() => {
    if (fUser.role === "Student") setUrl(window.location.pathname);
  });
  return (
    <RootDiv>
      <Header flag={flag} fUser={fUser} />

      <StyledGrid item xs={12} sm={12}>
        <TestContent flag={flag} fUser={fUser} />
      </StyledGrid>
    </RootDiv>
  );
};

export default Tests;
