import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";

import { Link, useHistory } from "react-router-dom";

import BPrimLogo from "../../src/bprimLogo.png";
import GyanSopanLogo from "../../src/GyanSopan_new.png";

const HeaderDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  color: "white",
  backgroundColor: "#1f5156",
  width: "100%",
  height: 65,
  // paddingTop: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  zIndex: 10,
}));
const BrandDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "fixed",
  left: 50,
  [theme.breakpoints.down("xs")]: {
    left: 5,
    top: 20,
  },
}));
const GSLogoImg = styled("img")(({ theme }) => ({
  position: "fixed",
  right: 50,
  top: 10,
  [theme.breakpoints.down("xs")]: {
    top: 20,
    right: 5,
    width: "25%",
  },
}));
const BPLogoImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    width: 25,
    height: 25,
  },
}));
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: 10,
  fontWeight: "bold",
  [theme.breakpoints.down("xs")]: {
    fontSize: 13,
    marginLeft: 3,
  },
}));
export default function FrontHeader() {
  const [hostname, setHostname] = React.useState(window.location.hostname);

  return (
    <HeaderDiv>
      {hostname === "bprim.gyansopan.com" ? (
        <Link
          exact
          to="/"
          style={{
            position: "fixed",
            left: 50,
            top: 10,
            textDecoration: "none",
            color: "white",
          }}
        >
          <BrandDiv>
            <BPLogoImg
              src={BPrimLogo}
              alt="B Prim Logo"
              width={45}
              height={45}
            />
            <StyledTypography variant="h6">
              Bhaskaracharya Pratishthana
            </StyledTypography>
          </BrandDiv>
        </Link>
      ) : null}
      <Link exact to="/">
        <GSLogoImg src={GyanSopanLogo} alt="GyanSopan Logo" />
      </Link>
    </HeaderDiv>
  );
}
