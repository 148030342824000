import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import {
  Button,
  createTheme,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
} from "@mui/material";
import {
  ArrowDropDownCircle,
  AccountCircle,
  ExitToApp,
  Payment,
} from "@mui/icons-material";
import Logo from "../GyanSopan_new.png";

import "./NavBar.css";

function NavBar(props) {
  const navigate = useNavigate();

  const fUser = props.fUser;
  const [click, setClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = () => setClick(!click);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log("I have clicked", anchorEl);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const _handleSignOut = async () => {
    signOut()
      .then(() => {
        navigate("/");
        // await client.resetStore()
        window.location.reload(); //to completely signOut user
        // window.location.reload(true);
        // setTimeout(() => window.location.reload(true));
        // console.log("signed out");
      })
      .catch((err) => {
        // console.log(JSON.stringify(err, null, 2));
        alert("Something went wrong");
      });
  };
  const theme = createTheme({
    overrides: {
      MuiButton: {
        label: {
          // don't change
          justifyContent: "left",
        },
      },
    },
  });

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <div
            style={{
              position: "absolute",
              right: 60,
              // top: 8,
            }}
          >
            <IconButton onClick={handleMenuClick}>
              <ArrowDropDownCircle fontSize="large" className="arrowDown" />
            </IconButton>
            <ThemeProvider theme={theme}>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // PaperProps={{
                //   style: {
                //     width: "150px",
                //   },
                // }}
              >
                {fUser.role === "Teacher" ? (
                  <div>
                    <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={"/teacherProfile"}
                        state={{
                          id: fUser._id,
                        }}
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          margin: "0px !important",
                          textAlign: "left",
                        }}
                      >
                        <Button
                          size="large"
                          startIcon={<AccountCircle />}
                          className="nav-button"
                          style={{ margin: "0 !important" }}
                        >
                          My Profile
                        </Button>
                      </Link>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={{
                          pathname: "/billing",
                        }}
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          textAlign: "left",
                          margin: "0px !important",
                        }}
                      >
                        <Button
                          size="large"
                          startIcon={<Payment />}
                          className="nav-button"
                          style={{ margin: "0 !important" }}
                        >
                          Billing
                        </Button>
                      </Link>
                    </MenuItem> */}
                    <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={{
                          pathname: "/bills",
                        }}
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          textAlign: "left",
                          margin: "0px !important",
                        }}
                      >
                        <Button
                          size="large"
                          startIcon={<Payment />}
                          className="nav-button"
                          style={{ margin: "0 !important" }}
                        >
                          Bills
                        </Button>
                      </Link>
                    </MenuItem>
                  </div>
                ) : fUser.role === "Student" ? (
                  <div>
                    <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={"/studentProfile"}
                        state={{
                          id: fUser._id,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size="large"
                          startIcon={<AccountCircle />}
                          className="nav-button"
                        >
                          My Profile
                        </Button>
                      </Link>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={{
                          pathname: "/billing",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size="large"
                          startIcon={<Payment />}
                          className="nav-button"
                          style={{ margin: "0 !important" }}
                        >
                          Billing
                        </Button>
                      </Link>
                    </MenuItem> */}
                  </div>
                ) : fUser.role === "Institute" ? (
                  <div>
                    <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={"/instituteProfile"}
                        state={{
                          id: fUser._id,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size="large"
                          startIcon={<AccountCircle />}
                          className="nav-button"
                        >
                          Profile
                        </Button>
                      </Link>
                    </MenuItem>
                    {/* <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={{
                          pathname: "/billing",
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          size="large"
                          startIcon={<Payment />}
                          className="nav-button"
                        >
                          Billing
                        </Button>
                      </Link>
                    </MenuItem> */}
                    <MenuItem onClick={handleClose} disableGutters>
                      <Link
                        exact
                        to={{
                          pathname: "/bills",
                        }}
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          textAlign: "left",
                          margin: "0px !important",
                        }}
                      >
                        <Button
                          size="large"
                          startIcon={<Payment />}
                          className="nav-button"
                          style={{ margin: "0 !important" }}
                        >
                          Bills
                        </Button>
                      </Link>
                    </MenuItem>
                  </div>
                ) : null}
                <MenuItem onClick={handleClose} disableGutters>
                  <Button
                    size="large"
                    onClick={() => _handleSignOut()}
                    startIcon={<ExitToApp />}
                    className="nav-button"
                    // variant="contained"
                    // color="primary"
                    style={{ margin: "0 !important", textAlign: "left" }}
                  >
                    Sign Out
                  </Button>
                </MenuItem>
              </Menu>
            </ThemeProvider>
          </div>
          <Link exact to="/">
            <img src={Logo} alt="GyanSopan Logo" className="nav-logo" />
          </Link>

          {fUser.role === "Teacher" ? (
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link
                  exact
                  to="/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/examList"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Registration
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/questions"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Questions
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  exact
                  to="/courses"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Courses
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  exact
                  to="/tests"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Tests
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  exact
                  to="/assignments"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Assignments
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  exact
                  to="/students"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Students
                </Link>
              </li>
            </ul>
          ) : fUser.role === "Student" ? (
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link
                  exact
                  to="/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/examList"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Registration
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/tests"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Tests
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  exact
                  to="/assignments"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Assignments
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  exact
                  to="/instituteList"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Institutes
                </Link>
              </li>
            </ul>
          ) : fUser.role === "Institute" ? (
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link
                  exact
                  to="/"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/examList"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Registration
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/questions"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Questions
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  exact
                  to="/courses"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Courses
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  exact
                  to="/tests"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Tests
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  exact
                  to="/assignments"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Assignments
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  exact
                  to="/teachers"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Teachers
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  exact
                  to="/students"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  Students
                </Link>
              </li>
            </ul>
          ) : null}
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
