//any changes here make to backend as well
export const topics = {
  Algebra: {
    "Complex Numbers": [
      "Addition",
      "Subtraction",
      "Multiplication and Division",
      "Complex Modulus and its properties",
      "Principle Argument",
      "Triangle Inequality",
      "Geometric Interpretations",
      "Cube Roots of Unity",
      "De Moivre's Theorem",
      "Nth Root of a Complex Number",
    ],
    "Quadratic Equations and Expressions": [
      "Solutions of Quadratic Equations by Factorization",
      "Method of Completing the Squares",
      "Relations between Roots and Coefficients",
      "Formation of Quadratic Equations with Given Roots",
      "Symmetric Functions of Roots",
      "Behavior of Quadratic Expressions",
    ],
    "Sequence and Series": [
      "Arithmetic Progression",
      "Geometric Progression",
      "Harmonic Progression",
      "Sum of Arithmetic Progression",
      "Sum of Geometric Progression",
      "Arithmetic, Geometric and Harmonic Mean",
      "Infinite Geometric Series",
      "Special Finite Series",
      "Sum of Finite Trigonometric Series",
      "Sum involving Binomial Coefficients",
    ],
    "System of Linear Equations": [
      "Slope",
      "Intercepts",
      "Slope-Intercept Form",
      "Point Slope Form",
      "Standard Form",
      "Parallel Lines",
      "Perpendicular Lines",
      "Horizontal and Vertical Lines",
      "Geometric Interpretations",
    ],
    Polynomials: [
      "Addition and Multiplication of Polynomials",
      "Factorization of Polynomials",
      "Division of Polynomials",
      "Remainder Theorem and Factorization",
      "GCD and LCM of Polynomials",
      "Euclid's Algorithm",
      "Solving Cubic Equations",
    ],
    Inequalities: [
      "Some Basic Inequalities",
      "AM-GM Inequalities",
      "Cauchy-Schwarz Inequalities",
    ],
    "Conic Section": [
      "Equation of a circle in various forms",
      "equations of tangent, normal and chord",
      "Parametric equations of a circle",
      "intersection of a circle with a straight line or a circle",
      "equation of a circle through the points of intersection of two circles and those of a circle and a straight line",
      "Equations of a parabola, its foci, directrices and eccentricity, parametric equations, equations of tangent and normal",
      "Equations of an ellipse, its foci, directrices and eccentricity, parametric equations, equations of tangent and normal",
      "Equations of a hyperbola, its foci, directrices and eccentricity, parametric equations, equations of tangent and normal",
    ],
    Sets: [
      "Sets and their representations",
      "Empty set.Finite and Infinite sets",
      "Equal sets.Subsets",
      "Subsets of a set of real numbers especially intervals (with notations)",
      "Power set",
      "Universal set",
      "Venn diagrams",
      "Union and Intersection of sets.Difference of sets",
      "Complement of a set",
      "Properties of Complement",
    ],
    "Relations and Functions": [
      "Ordered pairs.Cartesian product of sets",
      "Number of elements in the Cartesian product of two finite sets",
      "Cartesian product of the set of reals with itself (upto R x R x R)",
      "Definition of relation, pictorial diagrams, domain, co-domain and range of a relation",
      "Function as a special type of relation",
      "Pictorial representation of a function, domain, co-domain and range of a function",
      "Real valued functions, domain and range of these functions",
      "Constant functions and their graphs",
      "Identity functions and their graphs",
      "polynomial functions and their graphs",
      "Rational functions and their graphs",
      "Modulus functions and their graphs",
      "Signum functions and their graphs",
      "Exponential functions and their graphs",
      "Logarithmic functions and their graphs",
      "Greatest integer functions, with their graphs",
      "Sum, difference, product and quotients of functions",
    ],
    "Principle of Mathematical Induction": [
      "Process of the proof by induction",
      "The principle of mathematical induction and simple applications",
      "Well Ordering Principle",
    ],
    "Mathematical Reasoning": [
      "Mathematically acceptable statements",
      "Connecting words/ phrases - consolidating the understanding",
      "if and only if (necessary and sufficient) condition",
      "Implies Condition",
      "Implied by Condition",
      "And Condition",
      "Or Condition",
      "There exists Condition",
      "Contradiction Condition",
      "Converse Condition",
      "Contrapositive Condition",
    ],
    //"Three Dimension Co-ordinate Geometry",
    //"Two Dimension Co-ordinate Geometry",
    //"Trigonometry",
    //"Vectors",
    //"Matrices",
    "Logarithmic and Exponential Functions": [
      "Logarithmic Functions",
      "Laws of Logarithms",
      "Change of Base of Logarithms",
      "Common Logarithms",
      "Natural Logarithms",
      "Solving Equations using Logarithms and Exponentials",
      "Graphs of Logarithmic Functions",
      "Exponential Functions",
    ],
    "Indices and Surds": ["Indices", "Surds"],
    "Variables and Equations": [
      "Variables",
      "Consecutive Integers",
      "d = st",
      "Hormonic Mean",
      "Moles Digging Holes",
    ],
    "System of Equations": [
      "Substitution",
      "Elimination",
      "Common Word Problems",
      "Cheating the System",
    ],
    "Ratios, Proportions and Percents": [
      "Ratios",
      "Proportions",
      "Percents",
      "Percents of Change",
    ],
    Fractions: ["Decimal Fractions"],
    Measurement: ["Decimal", "Other Units of Measurement"],
    "Use of letters": ["Use of letters"],
    "Unitary Method": ["Unitary Method"],
    Interest: ["Simple Interest", "Compound Interest"],
    Distribution: ["Distribution"],
    "Exponents & Radicals": ["Exponents & Radicals"],
    "Sequence & Series": [
      "Arithmetic sequence",
      "Geometric Sequence",
      "Harmonic  Sequence",
    ],
    Indices: ["Indices"],
    Numbers: ["Types of number", "Operations on numbers"],
    "H.C.F./G.C.D. and L.C.M. of Numbers": [
      "H.C.F./G.C.D. and L.C.M. of Numbers",
    ],
    "Ratio and Proportion": ["Ratio and Proportion"],
    Percentage: ["Percentage"],
    "Profit and Loss": ["Discount", "Sale"],
    Average: ["Average"],
    "Distance, Speed and Time": ["Distance, Speed and Time"],
    "Time and Work": ["Time and Work"],
    "Squares and Square roots & Cubes and Cube roots": [
      "Squares and Square roots & Cubes and Cube roots",
    ],
    "Algebraic Expressions and Factorizations": [
      "Algebraic Expressions and Factorizations",
    ],
    "Equations in One Variable": ["Equations in One Variable"],
    "Identities  Expansion": ["Identities  Expansion"],
    Parity: ["Parity"],
  },
  Calculus: {
    Differentiation: [
      "Gradient of a curve",
      "General Method for the Gradient Function",
      "Meaning of (dy/dx)",
      "The derivative of ax^n",
      "Composite Functions",
      "Second Differential Coefficients",
      "Differentiation of the product of Two Functions",
      "Differentiation of the Quotient of Two Functions",
      "Differentiatino of Trigonometric Functions",
      "Differentiation of Logarithmic Functions",
      "Differentiation of Exponential Functions",
    ],
    "Application of Differentiation": [
      "Tangents and Normals",
      "Stationary Points, Maxima and Minima",
      "Maximum and Minimum Problems",
      "Small Increments: Approximate Changes",
      "Connected Rates of Change",
    ],
    Integration: [
      "Anti-Differentiation",
      "The Arbitrary Constant - Indefinite Integral",
      "Area Under a Curve",
      "Definite Integrals",
      "Further Notes on Areas",
      "Integration of Trigonometric Functions",
      "Integration of Power of the Linear Function",
      "Integration of Exponential Functions",
    ],
    "Application of Calculus": [
      "Motion in a Straight Line",
      "Distance - Time Graphs",
      "Velocity - Time Graphs",
      "Constant Acceleration",
    ],
  },
  Matrices: {
    Matrices: [
      "Matrix",
      "Equal Matrices",
      "Addition and Subtraction of Matrices",
      "Multiplication of a Matrix by a Number (a Scalar)",
      "Multiplication of Two Matrices",
      "The Zero(or Null) Matrix",
      "The Unit(or Identity) Matrix",
      "Determinant of a Matrix",
      "Diagonal Matrix",
      "Symmetric Matrix",
      "Skew-Symmetric Matrix",
      "Inverse of a Matrix",
      "Simple Matrix Equations",
      "Using Matrices to Solve Simultaneous Equations",
    ],
  },
  Vectors: {
    Vectors: [
      "Scalars and Vectors",
      "Representation of Vectors",
      "Notation for Vectors",
      "Equality of Vectors",
      "Magnitude of a Vector",
      "Zero Vector or Null Vector",
      "Scalar Multiple of a Vector",
      "Addition of Vectors",
      "Diagonals of a Parallelogram",
      "Position Vectors",
      "Problem Solving using Vector",
      "Ratio Theorem",
      "Components of a vector",
      "Unit Vectors",
      "Composition of Velocities",
      "Resolution of Velocities",
      "Relative Velocities",
    ],
  },
  Geometry: {
    "Straight lines and triangles": [
      "Straight Lines",
      "Parallel straight lines",
      "Congruence of triangles",
      "Similar triangle and properties",
      "Congruence and Collinarity",
    ],
    Circles: [
      "Preliminaries",
      "Chords",
      "Secants",
      "Tangents",
      "Arc Angles",
      "Sectors",
      "Area of a Sector",
      "Inscribed Angles",
      "Theorems on Chords, Secants and Tangents",
      "Circumference",
      "Arc Length",
      "Cyclic Quadrilaterals",
      "Constructions",
      "Congruences in Circles",
    ],
    "Pythagorean Theorem": [
      "Triples",
      "Special Right Angles",
      "Distance Formula",
      "Algebra and the Pythagorean Theorem",
    ],
    "Geometry Basic": [
      "Lines",
      "Angles",
      "Triangles",
      "Polygons",
      "Trapezoids",
      "Parallelograms",
      "Kites",
    ],
    Area: [
      "Formulas",
      "Heron's Formula for Triangles",
      "Area of Quadrilaterals",
      "Area of Cones, Cylinders and Spheres",
      "Areas of Pyramid and Prisms",
    ],
    "Three Dimensional Geometry": [
      "Polyhedra",
      "Faces",
      "Vertices and Edges",
      "Volume",
      "Direction cosines and direction ratios",
      "equation of a straight line in space",
      "equation of a plane, distance of a point from a plane",
    ],
    Similarity: [
      "Right Angles",
      "Circles",
      "Parallel Lines",
      "Area and Volume Relationships",
    ],
    "Two Dimension Co-ordinate Geometry": [
      "Cartesian coordinates",
      "distance between two points",
      "section formulae",
      "shift of origin",
      "Equation of a straight line in various forms",
      "angle between two lines",
      "distance of a point from a line",
      "Lines through the point of intersection of two given lines",
      "equation of the bisector of the angle between two lines",
      "concurrency of lines",
      "Centroid, orthocentre, incentre and circumcentre of a triangle",
    ],

    "Three Dimension Co-ordinate Geometry": [
      "Direction cosines and direction ratios",
      "equation of a straight line in space",
      "equation of a plane, distance of a point from a plane",
    ],
    "Circular Measure": [
      "Radian Measure",
      "Length of an Arc",
      "Area of a sector of a Circle",
    ],
    //"Conic Section",
    // "Trigonometry",
  },
  Trigonometry: {
    Trigonometry: [
      "Trigonometric Function for a General Angle",
      "Sign of Trigonometric Functions",
      "Note on Special Angles",
      "Negative Angles",
      "Basic Angles",
      "Trigonometric Ratio of a general angle in terms of its Associate Basic Angles",
      "Basic Trigonometric Equations",
      "Other Trigonometric Functions",
      "Graph of Trigonometric Functions",
      "Multiple Angle Functions and their Graphs",
      "Trigonometric Identities",
      "Equations with More than One Function",
      "Heights and Distances",
      "Elimination",
      "Inverse Circular Functions",
    ],
  },
  "Number Theory": {
    "Primes and Divisibility": [
      "Sieve of Eratosthenes",
      "Primes",
      "Divisibility Rules",
    ],
    Factors: ["The Fundamental Theorem of Arithmetics", "Factor Counting"],
    "Factor Tricks": [
      "Product of the Factors",
      "Sum of the Factors",
      "GCD and LCM",
      "Factorials",
    ],
    "Different Bases": ["Notation", "Basic Arithmetic"],
    "The Units Digit": [
      "Addition",
      "Multiplication",
      "Perfect Squares",
      "Exponents",
    ],
    "Fractions and Decimals": [
      "Repeating Decimals",
      "Terminating Decimals",
      "Conversion",
    ],
    "Modular Arithmetics": ["Modular Arithmetics"],
  },
  Counting: {
    Basics: [
      "Simple Counting Techniques",
      "Addition Rule",
      "Multiplication Rule",
    ],
    "Venn Diagrams": ["Two-Set Ven Diagrams", "Three-Set Ven Diagrams"],
    "Bowling Pins and Handshakes": ["Triangular Number Patterns"],
    "Counting Outcomes": ["The Fundamental Counting Principles"],
    "Factorization and Permutation": [
      "Factorials",
      "Permutations",
      "Permutations with Restriction",
    ],
    Combinations: ["Combinations", "Paths on a Grid", "Complementary Counting"],
    "Stick and Stones": [
      "Stick and Stones without Restrictions",
      "Stick and Stones with Restrictions",
    ],
    "Pascal's Triangle": ["Combinations and Pascal's Triangle"],
    "Binomial Expansions": [
      "Pascal's Triangle",
      "Binomial Expansion",
      "Binomial Theorem",
      "Binomial Identities",
    ],
  },
  Probability: {
    "Probability Basics": [
      "Compound Events",
      "Dependent and Independent Events",
      "Replacement Events",
      "Cards",
    ],
    "Compound Events and Counting": [
      "Combining Counting Techniques",
      "Compound Probability",
    ],
    "Geometric Probability": ["Geometric Probability"],
    "Probability and Combination": ["Probability and Combination"],
    "Complementary Counting and Probability": [
      "Complementary Counting and Probability",
    ],
    "Expected Value": ["Mean", "Median", "Mode", "Range"],
  },
  "Linear Algebra": {
    "Vector Spaces and Basis": [
      "Real Vector Spaces",
      "Subspaces",
      "Intersection of Subspaces",
      "Sum of Subspaces",
      "Span and Linear Independence",
      "Basis and Dimensions",
      "The Fundamental Matrix Subspaces",
      "Kernel and Image",
      "Superposition Principle",
      "Adjoint Systems",
      "Cokernel and Coimage",
      "Fundamental theorem of Linear Algebra",
    ],
    "Inner Products and Norms": [
      "Inner Products",
      "Inner Product on Function Spaces",
      "Cauchy Schwarz Inequalities",
      "Orthogonal Vectors",
      "Triangle Inequality",
      "Unit Vectors and Matrix Norms",
      "Gram Matrices Postive Definite Matrix",
      "Cholesky Factorization Completing the Square",
    ],
    Orthogonality: [
      "Concepts of Orthogonal and Orthonormal Basis",
      " Gram Schmidt Process",
      "Orthogonal Projections and Orthogonal Subspaces",
      " Orthogonality of Fundamental Matrix Subspaces and the Fredholm Alternative",
      "Legendre Orthogonal Polynomials",
    ],
    Linearity: [
      "Linear Operators",
      "Space of Linear Functions",
      "Dual Spaces",
      "Composition and Inverses",
      "Linear Transformations",
      "Change of Basis",
      "Introduction to Affine Transformations and Isometry",
      "Adjoints",
      "Positive Definite Linear Functions and Minimization",
    ],
    "Eigen values and Eigenvectors": [
      "Introduction",
      "Basic Properties and Gerschgorin Circle Theorem Eigenbasis",
      "Diagonalization",
      "Invariant Subspaces",
      "Eigenvalues of Symmetric Matrices",
      "Spectral theorem (Statement only)",
      "Introduction to Schur’s Decomposition and Jordan Canonical Form Introduction to Singular values",
      "The Pseudo Inverse",
      "Euclidean Matrix Norm",
      "Conditional number and rank",
      "Variance Covariance and Introduction to Principal Components",
    ],
    Applications: [
      "Minimization of Quadratic Forms",
      "Concept of the Closest Point Least Squares",
      "Introduction to Discrete Fourier Transform",
      "Compression and Denoising",
      "Introduction to Haar Wavelets",
      "Modern Wavelets",
      "Haar Scaling Function",
    ],
  },
};
