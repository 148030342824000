import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import ReactPaginate from "react-paginate";

import CourseCard from "./CourseCard";
import "../../css/content.css";
import { StyledGrid } from "../../css/StyledComponents";
import { LoadingSpinner } from "../../constants/loadingSpinner";
import PdfRound1Certificate from "../pdfMaker/PdfRound1Certificate";

const getCoursesQuery = loader(
  "../../graphqlCalls/course/userGetAllCourses.gql"
);
const studentGetRound1Certificatequery = loader(
  "../../graphqlCalls/test/studentGetRound1Certificate.gql"
);

const Content = (props) => {
  const [courses, setCourses] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [round1Data, setRound1Data] = useState({ grade: -1 });
  const flag = props.flag;
  const fUser = props.fUser;

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getCoursesQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onCompleted: ({ userGetAllCourses }) => {
        setCourses(userGetAllCourses);
      },
      onError: (err) => {
        // console.log(JSON.stringify(err, null, 2));
      },
    }
  );

  // ROUND 2
  // const { loading: loadingRound1Data } = useQuery(
  //   studentGetRound1Certificatequery,
  //   {
  //     fetchPolicy: "network-only",
  //     notifyOnNetworkStatusChange: true,
  //     variables: {
  //       userId: fUser ? fUser._id : null,
  //     },
  //     skip: !fUser || fUser.role !== "Student",
  //     onCompleted: ({ studentGetRound1Certificate }) => {
  //       setRound1Data(studentGetRound1Certificate);
  //     },
  //     onError: (err) => {
  //       // alert("Something went wrong");
  //       // console.log(JSON.stringify(err, null, 2));
  //       // alert(
  //       //   err.message == "Error: You have not submitted the Test yet! :(" ||
  //       //     err.message == "Error: All questions not yet checked"
  //       //     ? err.message
  //       //     : "Something went wrong"
  //       // );
  //       // refetch()
  //     },
  //   }
  // );
  // console.log("in Main Flow networkStat is-->", networkStatus);
  // useEffect(() => {
  //   if (networkStatus === NetworkStatus.ready) {
  //     if (data) {
  //       console.log(data);
  //       const fCourses = data.teacherGetAllCourses;
  //       setCourses(fCourses);
  //       console.log("courses", courses);
  //     }
  //   }
  // }, [fUser]);
  // if (networkStatus === NetworkStatus.ready) {
  //   if (data) {
  //     console.log(data);
  //     const fCourses = data.teacherGetAllCourses;
  //     // setCourses(fCourses);
  //     console.log("courses", courses);
  //   }
  // }

  let coursesPerPage;
  let smValue;

  if (flag == 1) {
    coursesPerPage = 12;
    smValue = 4;
  } else {
    coursesPerPage = 8;
    smValue = 6;
  }

  const pagesVisited = pageNumber * coursesPerPage;

  // if (loading || !fUser) return (
  //  );
  const displayCourses =
    courses && courses.length != 0
      ? courses
          .slice(pagesVisited, pagesVisited + coursesPerPage)
          .map((course) => {
            return (
              <StyledGrid key={course._id} item xs={12} sm={smValue}>
                <CourseCard
                  id={course._id}
                  primaryTopic={course.primaryTopic}
                  secondaryTopic={course.secondaryTopic}
                  gradeLevel={course.gradeLevel}
                  // Status={course.cStatus}
                />
              </StyledGrid>
            );
          })
      : [];

  const pageCount =
    courses && courses.length != 0
      ? Math.ceil(courses.length / coursesPerPage)
      : 0;

  const changepage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div /*className="content"*/>
      {/* Round 2 */}
      {/* {fUser && fUser.role === "Student" && round1Data.grade != -1 ? (
        round1Data.studPortrait?.academic?.schoolName &&
        round1Data.studPortrait?.academic?.schoolName != "" ? (
          <div className={classes.div1Style}>
            <Button
              variant="outlined"
              size="medium"
              className={classes.buttonStyles}
              disabled={loadingRound1Data}
              onClick={() =>
                PdfRound1Certificate(round1Data.studPortrait, round1Data.grade)
              }
            >
              Download BMTSC Round 1 Certificate
            </Button>
          </div>
        ) : (
          <div className={classes.div1Style}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold", margin: "5", color: "red" }}
            >
              Please Update Your Name & School Name in Profile to view your
              BMTSC 2023 -24 Round 1 Ceritifcate
            </Typography>
          </div>
        )
      ) : null} */}
      {/* {courses && courses.length != 0 ? (
        <StyledGrid container spacing={1}>
          {displayCourses}
        </StyledGrid>
      ) : null}
      {courses && courses.length != 0 ? (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={changepage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"paginationLink"}
          nextLinkClassName={"paginationLink"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      ) : null} */}
      <br />
      {LoadingSpinner(loading || !fUser)}
    </div>
  );
};

export default Content;
