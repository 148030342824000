import React from "react";
import BPrim from "../../constants/contactUs/bPrim";
import Footer from "../Footer";
import Header from "../FrontHeader";
import { Button, Typography } from "@mui/material";

const ContactUs = () => {
  const [hostname, setHostname] = React.useState(window.location.hostname);

  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      {hostname === "bprim.gyansopan.com" ? <BPrim /> : null}
      <br />
      <div style={{ textAlign: "justify", margin: 20 }}>
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontWeight: "bold" }}
        >
          Technical Support - Fenlei Technology Private Limited
        </Typography>
        <br />
        <Typography>
          <b>Address:</b>
          <br />
          14, Krushnai Apartment,
          <br /> Lane 17 A, Garmala,
          <br /> Dhayari, Pune - 411041,
          <br /> Maharashtra
          <br />
          <b>Mobile Number:</b> +91 9359022506
          <br />
          <b>Email:</b> <a href="mailto: admin@fenlei.in">admin@fenlei.in</a>
        </Typography>
        <br />
        <br />
        {/* <Typography>
        For more information please visit{" "}
        <a href="https://www.bprim.org/" target="_blank">
          https://www.bprim.org/
        </a>
      </Typography> */}
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
