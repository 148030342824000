import React, { useState } from "react";
import { Paper, Tooltip } from "@mui/material";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { Print } from "@mui/icons-material";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTypography,
} from "../../css/StyledComponents";

import PdfMaker from "../pdfMaker/PdfMaker";
import { pincodes } from "../../constants/pincodes";
import { LoadingSpinner } from "../../constants/loadingSpinner";

const userGetAllBillsQuery = loader(
  "../../graphqlCalls/payment/userGetAllBills.gql"
);
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Bills(props) {
  const fUser = props.fUser;

  const [bills, setBills] = useState([]);

  const getStartDate = (dateIs) => {
    // let dateIsDate = new Date(0);
    // dateIsDate.setUTCSeconds(dateIs);
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  const getEndDate = (dateIs) => {
    // let dateIsDate = new Date(0);
    // dateIsDate.setUTCSeconds(dateIs);
    const formattedDate = new Date(dateIs);

    let subscriptionDays = 365;
    let result = formattedDate.setDate(
      formattedDate.getDate() + subscriptionDays
    );
    let endDate = new Date(result);

    return `${endDate.getDate()} ${
      monthNames[endDate.getMonth()]
    } ${endDate.getFullYear()}`;
  };

  const { data, error, loading, refetch, networkStatus } = useQuery(
    userGetAllBillsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetAllBills }) => {
        setBills(userGetAllBills);
      },
    }
  );

  return (
    <div style={{ margin: "5vh 5vw", height: "800px" }}>
      <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
        Bills
      </StyledTypography>
      <br />
      <StyledTableContainer component={Paper}>
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell>Sr No.</StyledTableCell>
              <StyledTableCell>Date of Payment</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Download Receipt</StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {bills.map((bill, index) => (
              <StyledTableRow>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  {getStartDate(Date.parse(bill.created))}
                </StyledTableCell>
                <StyledTableCell>{bill.description}</StyledTableCell>
                <StyledTableCell>
                  <Tooltip title="Download invoice">
                    <StyledButton
                      size="large"
                      disabled={loading}
                      startIcon={<Print />}
                      onClick={() =>
                        PdfMaker(
                          fUser,
                          bill.amount,
                          bill.description,
                          bill.to,
                          bill.from,
                          bill.state,
                          bill.pincode,
                          bill.GSTNo,
                          bill.paymentId,
                          getStartDate(Date.parse(bill.created))
                        )
                      }
                    ></StyledButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
      <br />
      {LoadingSpinner(loading)}
    </div>
  );
}
