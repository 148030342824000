import { useState, CSSProperties } from "react";
import RingLoader from "react-spinners/RingLoader";
import FadeLoader from "react-spinners/FadeLoader";


export const LoadingSpinner = (loading) => (
  <FadeLoader
    loading={loading}
    cssOverride={{
      display: "block",
      margin: "0 auto",
      borderColor: "Gold",
    }}
    color="#7393B3"
    // size={25}
    aria-label="Loading Spinner"
    data-testid="loader"
  />
);
