export const examsList = [
  //any changes here make to backend examsList const as well
  "SSC Mathematics",
  "HSC Mathematics",
  "IIT Mains",
  "IIT Advance",
  "Pre-RMO",
  "RMO",
  "NMTC",
  "JMO",
  "AMC-8",
  "AMC-10",
  "AMC-12",
  "Euclid",
  "Pascal",
  "Ramanujan-Contest",
  "BMTSC",
  "Gauss-Contest",
  "Kaprekar-Contest",
  "Aryabhata-Contest",
  "UGC Mathematics",
  "IIT JAM Mathematics",
  "Madhava-Contest",
  "Under Graduate I",
  "Under Graduate II",
  "Under Graduate III",
];
