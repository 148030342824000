import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getCurrentUser } from "aws-amplify/auth";

import { LoadingSpinner } from "../constants/loadingSpinner";

import NavBar from "../components/NavBar";
import Home from "../components/home/home";

// assignment
import Assignments from "../components/assignment/Assignments";
import Assignment from "../components/assignment/assignmentScreen/Assignment";
import CreateAssignment from "../components/assignment/createAssignment/createAssignment";

// course
import Courses from "../components/course/Courses";
import Course from "../components/course/courseScreen";
import { NewCourse } from "../components/course/NewCourse";

// question
import Questions from "../components/question/Questions";
import QuestionSubs from "../components/question/questionScreen/teacher/QuestionSubs";
import QuestionScreen from "../components/question/questionScreen/student/Question";
import TestQuestionScreen from "../components/question/questionScreen/student/TestQuestion";
import CreateQuestion from "../components/question/questionScreen/teacher/CreateQuestion";
import GenerateQuestions from "../components/question/questionScreen/teacher/GenerateQuestions";
// test
import Tests from "../components/test/Tests";
import Test from "../components/test/testScreen/Test";
import TakeTest from "../components/test/takeTest/TakeTest";
import CreateTest from "../components/test/createTest/createTest";

// exam
import IndividualRegistration from "../components/registration/IndividualRegistration";
import BulkRegistration from "../components/registration/BulkRegistration";
import AlreadyRegRegistration from "../components/registration/AlreadyRegRegistration";
import ExamInfo from "../components/registration/ExamInfo";
import ExamList from "../components/registration/ExamList";
import CreateExam from "../components/registration/CreateExam";
import ManageRegistration from "../components/registration/MangageRegistration";

// institute
import InstituteList from "../components/institute/InstituteList";
import Institute from "../components/institute/Institute";

// performance
import PerformanceScreen from "../components/performance/performanceScreen/PerformanceScreen";
import StudentPerformanceList from "../components/performance/studentPerformanceList/StudentPerformanceList";
import ExaminerScreen from "../components/test/examinerScreen/examinerScreen";

// bill
import Billing from "../components/billing/Billing";
import Bills from "../components/billing/Bills";

// profile
import TeacherProfile from "../components/profile/teacher/TeacherProfile";
import StudentProfile from "../components/profile/student/StudentProfile";
import InstituteProfile from "../components/profile/institute/InstituteProfile";

// user lists
import Students from "../components/student/students";
import Teachers from "../components/teacher/teachers";
import AddBulkStudents from "../components/student/AddBulkStudents";
import AddBulkTeachers from "../components/teacher/AddBulkTeachers";

const getUserQuery = loader("../graphqlCalls/user/getUser.gql");

const MainFlow = () => {
  const navigate = useNavigate();

  const [fetchedUser, setFetchedUser] = useState(null); //Don't Change default value from null
  const [fetchingUser, setFetchingUser] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [restartPage, setRestartPage] = useState(false);

  const { signOut } = useAuthenticator();

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
    // console.log("cu", currentUrl);
  });

  useEffect(() => {
    getCurrentUser().then(({ signInDetails }) => {
      setUserEmail(signInDetails.loginId);
      setFetchingUser(false);
    });
  }, []);

  const { refetch, loading } = useQuery(getUserQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !userEmail || fetchingUser,
    variables: {
      userEmail: userEmail,
    },
    onCompleted: ({ getUser }) => {
      console.log("Success!");
      setFetchedUser(getUser);
    },
    onError: (error) => {
      // alert(error);
      //   "message": "NetworkError when attempting to fetch resource."
      // if (error?.message != "Failed to fetch") alert("Something went wrong");
    },
  });
  useEffect(() => {
    refetch();
  }, [userEmail]);

  //REDIRECT TO /tests
  // useEffect(() => {
  //   navigate("/tests");
  // }, []);

  const _handleSignOut = async () => {
    signOut()
      .then(() => {
        navigate("/");
        window.location.reload(); //to completely signOut user
      })
      .catch((err) => {
        alert("Something went wrong");
      });
  };

  if (fetchingUser || loading) {
    return (
      <div>
        <br />
        <br />
        {LoadingSpinner(fetchingUser || loading)}
      </div>
    );
  }
  if (fetchedUser) {
    return (
      <div style={{ width: "100%" }}>
        {/* disabling navbar while taking tests */}
        {currentUrl != "/takeTest" || fetchedUser.role !== "Student" ? (
          <NavBar fUser={fetchedUser} />
        ) : null}

        {fetchedUser.role === "Teacher" ? (
          <Routes>
            <Route exact path="/" element={<Home fUser={fetchedUser} />} />
            {/* <Route
              path="/assignments"
              element={<Assignments fUser={fetchedUser} />}
            />
            <Route
              path="/assignment"
              element={<Assignment fUser={fetchedUser} />}
            />
            <Route
              path="/newAssignment"
              element={<CreateAssignment fUser={fetchedUser} />}
            />
            <Route path="/courses" element={<Courses fUser={fetchedUser} />} />
            <Route path="/course" element={<Course fUser={fetchedUser} />} />
            <Route
              path="/newCourse"
              element={<NewCourse fUser={fetchedUser} />}
            /> */}
            <Route
              path="/questions"
              element={<Questions fUser={fetchedUser} />}
            />
            <Route
              path="/quesSubs"
              element={<QuestionSubs fUser={fetchedUser} />}
            />
            <Route
              path="/newQuestion"
              element={<CreateQuestion fUser={fetchedUser} />}
            />
            <Route
              path="/generateQuestions"
              element={<GenerateQuestions fUser={fetchedUser} />}
            />
            <Route path="/tests" element={<Tests fUser={fetchedUser} />} />
            <Route path="/test" element={<Test fUser={fetchedUser} />} />
            <Route
              path="/newTest"
              element={<CreateTest fUser={fetchedUser} />}
            />
            <Route
              path="/examList"
              element={<ExamList fUser={fetchedUser} />}
            />
            <Route
              path="/manageRegistration"
              element={<ManageRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/individualReg"
              element={<IndividualRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/bulkReg"
              element={<BulkRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/alreadyReg"
              element={<AlreadyRegRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/examInfo"
              element={<ExamInfo fUser={fetchedUser} />}
            />
            <Route
              path="/newExam"
              element={<CreateExam fUser={fetchedUser} />}
            />
            <Route
              path="/studentPerformanceList"
              element={<StudentPerformanceList fUser={fetchedUser} />}
            />
            <Route
              path="/performanceScreen"
              element={<PerformanceScreen fUser={fetchedUser} />}
            />
            <Route
              path="/examinerScreen"
              element={<ExaminerScreen fUser={fetchedUser} />}
            />
            <Route path="/bills" element={<Bills fUser={fetchedUser} />} />
            <Route path="/billing" element={<Billing fUser={fetchedUser} />} />
            <Route
              path="/teacherProfile"
              element={<TeacherProfile fUser={fetchedUser} />}
            />
            <Route
              path="/studentProfile"
              element={<StudentProfile fUser={fetchedUser} />}
            />
            <Route
              path="/students"
              element={<Students fUser={fetchedUser} />}
            />
            <Route
              path="/addBulkStudents"
              element={<AddBulkStudents fUser={fetchedUser} />}
            />
            {/* place all new routes above this*/}
            <Route path="/" element={<Home fUser={fetchedUser} />} />
            {/* DON'T PLACE ANE ROUTES BELOW THIS*/}
          </Routes>
        ) : fetchedUser.role === "Student" ? (
          <Routes>
            <Route exact path="/" element={<Home fUser={fetchedUser} />} />
            {/* <Route
              path="/assignments"
              element={<Assignments fUser={fetchedUser} />}
            />
            <Route
              path="/assignment"
              element={<Assignment fUser={fetchedUser} />}
            /> */}
            <Route
              path="/question"
              element={<QuestionScreen fUser={fetchedUser} />}
            />
            {/* <Route
              path="/testQuestion"
              element={
                <QuestionScreen
                  fUser={fetchedUser}
                  setUrl={setCurrentUrl}
                  setRestartPage={setRestartPage}
                />
              }
            /> */}
            <Route
              path="/tests"
              element={<Tests fUser={fetchedUser} setUrl={setCurrentUrl} />}
            />
            <Route path="/test" element={<Test fUser={fetchedUser} />} />
            <Route
              path="/takeTest"
              element={<TakeTest fUser={fetchedUser} setUrl={setCurrentUrl} />}
            />
            <Route
              path="/examList"
              element={<ExamList fUser={fetchedUser} />}
            />
            <Route
              path="/manageRegistration"
              element={<ManageRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/individualReg"
              element={<IndividualRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/examInfo"
              element={<ExamInfo fUser={fetchedUser} />}
            />
            <Route
              path="/instituteList"
              element={<InstituteList fUser={fetchedUser} />}
            />
            <Route
              path="/institute"
              element={<Institute fUser={fetchedUser} />}
            />
            <Route
              path="/performanceScreen"
              element={<PerformanceScreen fUser={fetchedUser} />}
            />
            <Route path="/billing" element={<Billing fUser={fetchedUser} />} />
            <Route
              path="/studentProfile"
              element={<StudentProfile fUser={fetchedUser} />}
            />
            {/* place all new routes above this*/}
            <Route path="/" element={<Home fUser={fetchedUser} />} />
            {/* DON'T PLACE ANE ROUTES BELOW THIS*/}
          </Routes>
        ) : fetchedUser.role === "Institute" ? (
          <Routes>
            <Route exact path="/" element={<Home fUser={fetchedUser} />} />
            {/* <Route
              path="/assignments"
              element={<Assignments fUser={fetchedUser} />}
            />
            <Route
              path="/assignment"
              element={<Assignment fUser={fetchedUser} />}
            />
            <Route
              path="/newAssignment"
              element={<CreateAssignment fUser={fetchedUser} />}
            />
            <Route path="/courses" element={<Courses fUser={fetchedUser} />} />
            <Route path="/course" element={<Course fUser={fetchedUser} />} />
            <Route
              path="/newCourse"
              element={<NewCourse fUser={fetchedUser} />}
            /> */}
            <Route
              path="/questions"
              element={<Questions fUser={fetchedUser} />}
            />
            <Route
              path="/quesSubs"
              element={<QuestionSubs fUser={fetchedUser} />}
            />
            <Route
              path="/newQuestion"
              element={<CreateQuestion fUser={fetchedUser} />}
            />
            <Route
              path="/generateQuestions"
              element={<GenerateQuestions fUser={fetchedUser} />}
            />
            <Route path="/tests" element={<Tests fUser={fetchedUser} />} />
            <Route path="/test" element={<Test fUser={fetchedUser} />} />
            <Route
              path="/newTest"
              element={<CreateTest fUser={fetchedUser} />}
            />
            <Route
              path="/examList"
              element={<ExamList fUser={fetchedUser} />}
            />
            <Route
              path="/manageRegistration"
              element={<ManageRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/individualReg"
              element={<IndividualRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/bulkReg"
              element={<BulkRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/alreadyReg"
              element={<AlreadyRegRegistration fUser={fetchedUser} />}
            />
            <Route
              path="/examInfo"
              element={<ExamInfo fUser={fetchedUser} />}
            />
            <Route
              path="/newExam"
              element={<CreateExam fUser={fetchedUser} />}
            />
            <Route
              path="/studentPerformanceList"
              element={<StudentPerformanceList fUser={fetchedUser} />}
            />
            <Route
              path="/performanceScreen"
              element={<PerformanceScreen fUser={fetchedUser} />}
            />
            <Route
              path="/examinerScreen"
              element={<ExaminerScreen fUser={fetchedUser} />}
            />
            <Route path="/bills" element={<Bills fUser={fetchedUser} />} />
            <Route path="/billing" element={<Billing fUser={fetchedUser} />} />
            <Route
              path="/teacherProfile"
              element={<TeacherProfile fUser={fetchedUser} />}
            />
            <Route
              path="/studentProfile"
              element={<StudentProfile fUser={fetchedUser} />}
            />
            <Route
              path="/instituteProfile"
              element={<InstituteProfile fUser={fetchedUser} />}
            />
            <Route
              path="/students"
              element={<Students fUser={fetchedUser} />}
            />
            <Route
              path="/teachers"
              element={<Teachers fUser={fetchedUser} />}
            />
            <Route
              path="/addBulkStudents"
              element={<AddBulkStudents fUser={fetchedUser} />}
            />
            <Route
              path="/addBulkTeachers"
              element={<AddBulkTeachers fUser={fetchedUser} />}
            />
            {/* place all new routes above this*/}
            <Route path="/" element={<Home fUser={fetchedUser} />} />
            {/* DON'T PLACE ANE ROUTES BELOW THIS*/}
          </Routes>
        ) : (
          <Typography variant="h5" style={{ fontWeight: "bold", margin: 20 }}>
            Please Refresh Page, For any Technical assistance please call : +91
            9967976846 || +91 9762938277
          </Typography>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
            margin: 20,
            position: "absolute",
            // right: 20,
            top: 50,
          }}
        >
          Please Refresh Page, For any Technical assistance please call :+91
          9359022506 || +91 9967976846 || +91 9762938277
        </Typography>
        <Button
          onClick={() => _handleSignOut()}
          variant="contained"
          color="primary"
          style={{ position: "absolute", right: 20, top: 20 }}
        >
          Sign Out
        </Button>
      </div>
    );
  }
};

export default MainFlow;
