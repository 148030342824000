import React, { useState } from "react";
import { Paper, Tooltip } from "@mui/material";
import { Visibility, Print } from "@mui/icons-material";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";

import {
  RootDiv,
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTypography,
} from "../../css/StyledComponents";

import PdfMaker from "../pdfMaker/PdfMaker";
import { pincodes } from "../../constants/pincodes";

const doInstitutePaymentMutation = loader(
  "../../graphqlCalls/payment/DoInstitutePayment.gql"
);
const updatePostInstitutePaymentMutation = loader(
  "../../graphqlCalls/payment/UpdatePostInstitutePayment.gql"
);
const getInstituteSubStatusQuery = loader(
  "../../graphqlCalls/payment/getInstituteSubscriptionStatus.gql"
);
const studentGetAllInstituteExamsQuery = loader(
  "../../graphqlCalls/exam/studentGetAllInstituteExams.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Institute = (props) => {
  const fUser = props.fUser;

  const {
    state: { institute },
  } = useLocation();

  const [GSTNo, setGSTNo] = useState("");
  const [pincode, setPincode] = useState(0);
  const [state, setState] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [billDate, setBillDate] = useState(0);
  const [paidExams, setPaidExams] = useState([]);
  const [unpaidExams, setUnpaidExams] = useState([]);

  const navigate = useNavigate();

  // const { data, error, loading, refetch, networkStatus } = useQuery(
  //   getInstituteSubStatusQuery,
  //   {
  //     fetchPolicy: "network-only",
  // notifyOnNetworkStatusChange: true,
  //     variables: {
  //       sId: fUser ? fUser._id : null,
  //       iId: institute._id,
  //     },
  //     // skip: !fUser || fUser.role != "Student",
  //     skip: true,
  //     onError: (err) => {
  //       alert("Something went wrong");
  //       // console.log(JSON.stringify(err, null, 2));
  //     },
  //     onCompleted: ({ getInstituteSubscriptionStatus }) => {
  //       // console.log("getSubscriptionStatus", getInstituteSubscriptionStatus);
  //       if (
  //         getInstituteSubscriptionStatus &&
  //         getInstituteSubscriptionStatus.status == true
  //       ) {
  //         setPaymentDone(true);
  //         setState(getInstituteSubscriptionStatus.state);
  //         setPincode(getInstituteSubscriptionStatus.pincode);
  //         setGSTNo(getInstituteSubscriptionStatus.GSTNo);
  //         setPaymentId(getInstituteSubscriptionStatus.paymentId);
  //         setBillDate(Date.parse(getInstituteSubscriptionStatus.billDate));
  //       } else setPaymentDone(false);
  //     },
  //   }
  // );

  const { loading: studLoading } = useQuery(studentGetAllInstituteExamsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      instiId: institute?._id,
    },
    skip: !institute || !fUser || fUser.role != "Student",
    onCompleted: ({ studentGetAllInstituteExams }) => {
      setPaidExams(studentGetAllInstituteExams.paid);
      setUnpaidExams(studentGetAllInstituteExams.unpaid);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const [
    DoInstitutePaymentMutation,
    { loading: fetchingPayment, error: errorCreating, data: dataCreating },
  ] = useMutation(doInstitutePaymentMutation);

  const [
    UpdatePostInstitutePaymentMutation,
    {
      loading: updatingPostPayment,
      error: errorPostUpdate,
      data: dataPostUpdate,
    },
  ] = useMutation(updatePostInstitutePaymentMutation);

  if (!institute) {
    navigate("/instituteList");
    return null;
  }

  const getStartDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  const getEndDate = (dateIs) => {
    const formattedDate = new Date(dateIs);

    let subscriptionDays = 365;
    let result = formattedDate.setDate(
      formattedDate.getDate() + subscriptionDays
    );
    let endDate = new Date(result);

    return `${endDate.getDate()} ${
      monthNames[endDate.getMonth()]
    } ${endDate.getFullYear()}`;
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const getState = (pincodeIp) => {
    const found = pincodes.find((pc) => pc.Pincode == pincodeIp);
    if (found) {
      return found.StateName;
    }
    return "";
  };
  // async function DisplayRazorpay() {
  //   let callInfo;
  //   if (fetchingPayment) return;

  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     alert("Payment Gateway failed to load. Are you online?");
  //     return;
  //   }

  //   try {
  //     callInfo = await DoInstitutePaymentMutation({
  //       variables: {
  //         input: {
  //           instituteId: institute._id,
  //         },
  //       },
  //       onError: (err) => {
  //         alert("Something went wrong");
  //         // console.log(JSON.stringify(err, null, 2));
  //       },
  //     });
  //   } catch (error) {
  //     // console.log(JSON.stringify(error, null, 2));
  //     alert("Something went wrong");
  //   }

  //   const dp = callInfo.data.DoInstitutePayment;

  //   const options = {
  //     key: "rzp_test_UL2w8U1WKAUgx8",
  //     currency: dp.currency,
  //     amount: dp.amount.toString(),
  //     order_id: dp.id,
  //     name: "Subscription Fees",
  //     description: "Exam Fee (email id cannot be changed)",
  //     handler: async function (response) {
  //       //manual refresh if no auto-refresh
  //       // window.location.reload();
  //       // console.log("rzp res", response);
  //       // alert(response.razorpay_payment_id);
  //       // setPaymentId(response.razorpay_payment_id);
  //       // alert(response.razorpay_order_id);
  //       // alert(response.razorpay_signature);
  //       // setPaymentDone(true);
  //       try {
  //         const callInfo = await UpdatePostInstitutePaymentMutation({
  //           variables: {
  //             input: {
  //               studentId: fUser._id,
  //               state: state,
  //               pincode: parseInt(pincode),
  //               GSTNo: GSTNo,
  //               paymentId: response.razorpay_payment_id,
  //               orderId: response.razorpay_order_id,
  //             },
  //           },
  //           onError: (err) => {
  //             alert("Something went wrong");
  //             // console.log("err", err);
  //           },
  //         });
  //         refetch();
  //       } catch (error) {
  //         alert("Something went wrong");
  //       }
  //     },
  //     prefill: {
  //       email: fUser.mail,
  //     },
  //     readonly: { email: true },
  //   };
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  const examListTable = (exList, paid, isCreator) => {
    return (
      <div>
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                {paid ? <StyledTableCell>Bill</StyledTableCell> : null}
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {exList.map((examBill, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{examBill.exam.name}</StyledTableCell>
                  {paid ? (
                    <StyledTableCell>
                      <Tooltip title="Download invoice">
                        <StyledButton
                          size="large"
                          startIcon={<Print />}
                          onClick={() =>
                            PdfMaker(
                              fUser,
                              examBill.receipt?.amount,
                              examBill.receipt?.description,
                              examBill.receipt?.to,
                              examBill.receipt?.from,
                              examBill.receipt?.state,
                              examBill.receipt?.pincode,
                              examBill.receipt?.GSTNo,
                              examBill.receipt?.paymentId,
                              getStartDate(
                                Date.parse(examBill.receipt?.created)
                              )
                            )
                          }
                        ></StyledButton>
                      </Tooltip>
                    </StyledTableCell>
                  ) : null}
                  <StyledTableCell>
                    {
                      <Link
                        exact
                        to={"/examInfo"}
                        state={{
                          exam: examBill.exam,
                          paid: paid,
                          isCreator: isCreator,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="View">
                          <StyledButton startIcon={<Visibility />}>
                            View
                          </StyledButton>
                        </Tooltip>
                      </Link>
                    }
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
      </div>
    );
  };

  return (
    <RootDiv>
      <div>
        <StyledTypography variant="h6">
          Institute Name: {institute.portrait.alias.first}
        </StyledTypography>
        {/* <StyledTypography  variant="h6">
          Address: {institute.portrait.address.line1}
        </StyledTypography> */}
        {/* <StyledTypography  variant="h6">
          Email: {institute.portrait.pingAt.virtualAdd}
        </StyledTypography> */}
      </div>
      {fUser.role === "Student" ? (
        <div>
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "bold", margin: 10 }}
          >
            Exams Registered by You
          </StyledTypography>
          {examListTable(paidExams, true, false)}
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "bold", margin: 10 }}
          >
            You can also Register for the following exams:
          </StyledTypography>
          {examListTable(unpaidExams, false, false)}
        </div>
      ) : null}
    </RootDiv>
  );
};

export default Institute;
