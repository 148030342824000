import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";

import { StyledButton } from "../../css/StyledComponents";

const doPaymentMutation = loader("../../graphqlCalls/payment/DoPayment.gql");

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payment = ({ fUser, studentMail, Disabled }) => {
  const [
    DoPaymentMutation,
    { loading: fetchingPayment, error: errorCreating, data: dataCreating },
  ] = useMutation(doPaymentMutation);

  async function DisplayRazorpay() {
    let callInfo;
    if (fetchingPayment) return;

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }

    try {
      callInfo = await DoPaymentMutation();
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      alert("Something went wrong");
    }

    const dp = callInfo.data.DoPayment;

    const options = {
      key: "rzp_test_UL2w8U1WKAUgx8",
      currency: dp.currency,
      amount: dp.amount?.toString(),
      order_id: dp.id,
      name: "Registration Fees",
      description: "Annual Fee (email id cannot be changed)",
      handler: function (response) {
        //manual refresh if no auto-refresh
        window.location.reload();
      },
      prefill: {
        email: studentMail,
      },
      readonly: { email: true },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  return (
    <div>
      <StyledButton
        variant="contained"
        disabled={fetchingPayment || Disabled}
        size="medium"
        onClick={DisplayRazorpay}
      >
        Pay Rs.590
      </StyledButton>
    </div>
  );
};

export default Payment;
