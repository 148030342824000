import React, { useState } from "react";
import { Paper, Tooltip } from "@mui/material";
import { Visibility, Print } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";

import PdfMaker from "../pdfMaker/PdfMaker";
import Header from "../header/Header";
import { LoadingSpinner } from "../../constants/loadingSpinner";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTypography,
  StyledGrid,
  RootDiv,
  TableParentDiv,
} from "../../css/StyledComponents";

const userGetExamsQuery = loader("../../graphqlCalls/exam/userGetAllExams.gql");
const studentGetExamsQuery = loader(
  "../../graphqlCalls/exam/studentGetAllExams.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ExamList = ({ fUser }) => {
  const [userCreatedExams, setUserCreatedExams] = useState([]);
  const [userOtherExams, setUserOtherExams] = useState([]);
  const [userAllExams, setUserAllExams] = useState([]);
  let [loading, setLoading] = useState(true);

  const [paidExams, setPaidExams] = useState([]);
  const [unpaidExams, setUnpaidExams] = useState([]);

  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };

  const { loading: userLoading } = useQuery(userGetExamsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      userT: fUser ? fUser.role : null,
    },
    skip: !fUser || fUser.role == "Student",
    onCompleted: ({ userGetAllExams }) => {
      setUserCreatedExams(userGetAllExams.creator);
      setUserOtherExams(userGetAllExams.other);
      setUserAllExams([...userGetAllExams.creator, ...userGetAllExams.other]);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });
  const { loading: studLoading } = useQuery(studentGetExamsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
    },
    skip: !fUser || fUser.role != "Student",
    onCompleted: ({ studentGetAllExams }) => {
      setPaidExams(studentGetAllExams.paid);
      setUnpaidExams(studentGetAllExams.unpaid);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });

  const examListTable = (exList, paid) => {
    return (
      <TableParentDiv>
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Name
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Reg. Start Date
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Reg. End Date
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Organiser
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Enrollments
                </StyledTableCell>
                {paid ? (
                  <StyledTableCell style={{ fontWeight: "bold" }}>
                    Bill
                  </StyledTableCell>
                ) : null}
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>
            <StyledTableBody>
              {exList.map((examBill, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{examBill.exam.name}</StyledTableCell>
                  <StyledTableCell>
                    {getFormattedDate(Date.parse(examBill.exam.regStart))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getFormattedDate(Date.parse(examBill.exam.regEnd))}
                  </StyledTableCell>
                  <StyledTableCell>{examBill.exam.creatorName}</StyledTableCell>
                  <StyledTableCell>
                    {examBill.exam.isCreator ? examBill.exam.enrollments : "-"}
                  </StyledTableCell>
                  {paid ? (
                    <StyledTableCell>
                      <Tooltip title="Download invoice">
                        <StyledButton
                          size="large"
                          startIcon={<Print />}
                          onClick={() =>
                            PdfMaker(
                              fUser,
                              examBill.receipt?.amount,
                              examBill.receipt?.description,
                              examBill.receipt?.to,
                              examBill.receipt?.from,
                              examBill.receipt?.state,
                              examBill.receipt?.pincode,
                              examBill.receipt?.GSTNo,
                              examBill.receipt?.paymentId,
                              getFormattedDate(
                                Date.parse(examBill.receipt?.created)
                              )
                            )
                          }
                        ></StyledButton>
                      </Tooltip>
                    </StyledTableCell>
                  ) : null}
                  <StyledTableCell>
                    {
                      <Link
                        exact
                        to={"/examInfo"}
                        state={{
                          exam: examBill.exam,
                          paid: paid,
                          isCreator: examBill.exam.isCreator,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="View">
                          <StyledButton startIcon={paid ? <Visibility /> : ""}>
                            {paid ? "View" : "Register"}
                          </StyledButton>
                        </Tooltip>
                      </Link>
                    }
                    {paid ? (
                      <Link
                        exact
                        to={"/manageRegistration"}
                        state={{
                          id: fUser._id,
                          exam: examBill.exam,
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <Tooltip title="View">
                          <StyledButton
                            // variant="contained"
                            size="large"
                          >
                            Manage
                          </StyledButton>
                        </Tooltip>
                      </Link>
                    ) : null}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </StyledTableBody>
          </StyledTable>
        </StyledTableContainer>
        <br />
      </TableParentDiv>
    );
  };

  return (
    <RootDiv>
      <Header flag={10} fUser={fUser} />
      {LoadingSpinner(studLoading || userLoading)}
      {fUser.role === "Teacher" || fUser.role === "Institute" ? (
        <div style={{ width: "100%" }}>
          {/* <StyledTypography variant="h6" style={{ fontWeight: "bold", margin: 10 }}>
            Exams Offered
          </StyledTypography>
          {examListTable(userCreatedExams, false, true)}
          <StyledTypography variant="h6" style={{ fontWeight: "bold", margin: 10 }}>
            Exams Created by Other Institute/Teacher
          </StyledTypography>
          {examListTable(userOtherExams, false, false)} */}
          {examListTable(userAllExams, false)}
        </div>
      ) : fUser.role === "Student" ? (
        <div style={{ width: "100%" }}>
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "bold", margin: 10, justifyContent: "center" }}
          >
            {/* Exams Registered by You */}
            Registrations by You
          </StyledTypography>
          {examListTable(paidExams, true)}
          <br />
          <br />
          <br />
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "bold", margin: 10 }}
          >
            {/* You can also Register for the following exams: */}
            You can also Register for the following :
          </StyledTypography>
          {examListTable(unpaidExams, false)}
        </div>
      ) : null}
    </RootDiv>
  );
};

export default ExamList;
