import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Funnel } from "devextreme-react";
import {
  // Funnel,
  Border,
  Font,
  Item,
  // Label,
  // Legend,
  // Margin,
  // Title,
  // Tooltip,
} from "devextreme-react/funnel";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Title,
  Subtitle,
  Tooltip,
  Grid as ChartGrid,
  Format,
  Label,
  ValueAxis,
} from "devextreme-react/chart";

import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { MathJax } from "better-react-mathjax";

import {
  StyledPaper,
  StyledTypography,
  StyledCard,
  StyledCardContent,
  WrapTextDiv,
  StyledGrid,
  ColumnFlexDiv,
  RowFlexDiv,
} from "../../../css/StyledComponents";

import "../Performance.css";

import { LoadingSpinner } from "../../../constants/loadingSpinner";

const studentGetATestPerformanceQuery = loader(
  "../../../graphqlCalls/test/studentGetATestPerformance.gql"
);
const studentGetOnlyTestMarksQuery = loader(
  "../../../graphqlCalls/test/studentGetOnlyTestMarks.gql"
);

const performanceType = [
  { value: "student", performance: "Student" },
  { value: "overall", performance: "Overall" },
];
const initialFormValues = {
  fName: "",
  lName: "",
  grade: "",
  finalPer: 0,
  quesList: [],
  bloomData: [
    {
      value: 0,
      bloomName: "Create",
    },
    {
      value: 0,
      bloomName: "Evaluate",
    },
    {
      value: 0,
      bloomName: "Analyze",
    },
    {
      value: 0,
      bloomName: "Apply",
    },
    {
      value: 0,
      bloomName: "Understand",
    },
    {
      value: 0,
      bloomName: "Remember",
    },
  ],
  studOverallBloomGraphData: [
    {
      bloomName: "Remember",
      overall: 0,
      student: 0,
    },
    {
      bloomName: "Understand",
      overall: 0,
      student: 0,
    },
    {
      bloomName: "Apply",
      overall: 0,
      student: 0,
    },
    {
      bloomName: "Analyze",
      overall: 0,
      student: 0,
    },
    {
      bloomName: "Evaluate",
      overall: 0,
      student: 0,
    },
    {
      bloomName: "Create",
      overall: 0,
      student: 0,
    },
  ],
  questionWiseGraphData: [
    {
      type: "Question Average Deviation",
      overall: 0,
      student: 0,
    },
    {
      type: "Question Level Weightage",
      overall: 0,
      student: 0,
    },
    {
      type: "Question Marks Obtained Weightage",
      overall: 0,
      student: 0,
    },
    {
      type: "Question Performance Score Weightage",
      overall: 0,
      student: 0,
    },
  ],
  barPerformanceData: [
    {
      type: "Bloom Taxonomy",
      overall: 0,
      student: 0,
    },
    {
      type: "Student Correctly Solved Weightage",
      overall: 0,
      student: 0,
    },
    {
      type: "Final Performance",
      overall: 0,
      student: 0,
    },
  ],
  totalScore: 0,
  totalQuesMarks: 0,
  pass: -2,
};
const bloomTagsList = [
  "Create",
  "Evaluate",
  "Analyze",
  "Apply",
  "Understand",
  "Remember",
];
// const useStyles = makeStyles((theme) => ({
//   pyramid: {
//     width: "700px",
//     height: "500px",
//     position: "relative",
//     left: "25vw",
//     [theme.breakpoints.down("md")]: {
//       // width: "300px",
//       height: "400px",
//     },
//   },
//   bar: {
//     width: "700px",
//     height: "500px",
//     [theme.breakpoints.down("md")]: {
//       // width: "300px",
//       height: "400px",
//     },
//   },
//   bloomLine: {
//     width: "700px",
//     height: "500px",
//     [theme.breakpoints.down("md")]: {
//       // width: "300px",
//       height: "400px",
//     },
//   },
//   questionLine: {
//     width: "700px",
//     height: "500px",
//     [theme.breakpoints.down("md")]: {
//       // width: "300px",
//       height: "400px",
//     },
//   },
//   chartRow: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-around",
//     [theme.breakpoints.down("md")]: {
//       flexDirection: "column",
//     },
//   },
// }));

export default function PerformanceScreen(props) {
  const [formValues, setValues] = useState(initialFormValues);
  const fUser = props.fUser;

  const { state } = useLocation();

  const navigate = useNavigate();

  const getLangIndex = (arr, lang) => {
    return arr.findIndex((element) => {
      return element.language === lang;
    });
  };

  const getBloomData = (bloomObj) => {
    let bloomData = [];
    // }
    for (let i = 0; i < bloomTagsList.length; i++) {
      bloomData.push({
        value:
          bloomObj[bloomTagsList[i]] >= 0
            ? bloomObj[bloomTagsList[i]].toFixed(2)
            : 0,
        bloomName: bloomTagsList[i],
      });
    }
    return bloomData;
  };
  const getStudOverallBloomData = (sgatp) => {
    let bloomData = [];
    for (let i = 0; i < bloomTagsList.length; i++) {
      bloomData.push({
        student: sgatp.studBloomTagCorSolved[bloomTagsList[i]],
        overall: sgatp.OverallBloomTagCorSolved[bloomTagsList[i]],
        bloomName: bloomTagsList[i],
      });
    }
    return bloomData;
  };
  const { data, error, loading, refetch } = useQuery(
    studentGetATestPerformanceQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: state ? state.studId : null,
        sId: state ? state.subId : null,
        tId: state ? state.testId : null,
        // userEmail: "chinmay.irock@gmail.com",
      },
      skip: !state || !fUser,
      onError: (err) => {
        // alert("Something went wrong");
        // console.log(JSON.stringify(err, null, 2));
        alert(
          err.message == "Error: You have not submitted the Test yet! :(" ||
            err.message == "Error: All questions not yet checked"
            ? err.message
            : "Something went wrong"
        );
        // refetch()
      },
      onCompleted: ({ studentGetATestPerformance }) => {
        // console.log("sgtp", studentGetATestPerformance);
        // console.log("sts", sTopics);
        setValues({
          ...formValues,
          quesList: studentGetATestPerformance.quesList,
          fName: studentGetATestPerformance.studPortrait.alias.first,
          lName: studentGetATestPerformance.studPortrait.alias.last,
          grade: studentGetATestPerformance.studPortrait.grade,
          finalPer: studentGetATestPerformance.finalPerformance.toFixed(2),
          totalScore: studentGetATestPerformance.totalScore,
          totalQuesMarks: studentGetATestPerformance.totalQuesMarks,
          pass: studentGetATestPerformance.pass,
          bloomData: getBloomData(studentGetATestPerformance.bloomScore),
          studOverallBloomGraphData: getStudOverallBloomData(
            studentGetATestPerformance
          ),
          questionWiseGraphData: [
            {
              type: "Question Average Deviation",
              overall: studentGetATestPerformance.OverallQuesAvgDeviation,
              student: studentGetATestPerformance.studentWiseQuesAvgDeviation,
            },
            {
              type: "Question Level Weightage",
              overall: studentGetATestPerformance.OverallQuesLevelWeightage,
              student: studentGetATestPerformance.studentWiseQuesLevelWeightage,
            },
            {
              type: "Question Marks Obtained Weightage",
              overall:
                studentGetATestPerformance.OverallQuesMarkObtainedWeightage,
              student:
                studentGetATestPerformance.studentWiseQuesMarkObtainedWeightage,
            },
            {
              type: "Question Performance Score Weightage",
              overall:
                studentGetATestPerformance.OverallQuesPerformanceScoreWeightage,
              student:
                studentGetATestPerformance.studentWiseQuesPerformanceScoreWeightage,
            },
          ],
          barPerformanceData: [
            {
              type: "Bloom Taxonomy",
              overall: studentGetATestPerformance.OverallQuesBloomTagScore,
              student: studentGetATestPerformance.studentWiseQuesBloomTagScore,
            },
            {
              type: "Student Correctly Solved Weightage",
              overall: studentGetATestPerformance.OverallQuesCorSolvedWeightage,
              student:
                studentGetATestPerformance.studentWiseQuesCorSolvedWeightage,
            },
            {
              type: "Final Performance",
              overall: studentGetATestPerformance.avgPerformance,
              student: studentGetATestPerformance.finalPerformance,
            },
          ],
        });
      },
    }
  );
  // const { loading } = useQuery(studentGetOnlyTestMarksQuery, {
  //   fetchPolicy: "network-only",
  //   notifyOnNetworkStatusChange: true,
  //   variables: {
  //     userId: state ? state.studId : null,
  //     sId: state ? state.subId : null,
  //     tId: state ? state.testId : null,
  //   },
  //   skip: !state || !fUser,
  //   onError: (err) => {
  //     alert(
  //       err.message == "Error: You have not submitted the Test yet! :(" ||
  //         err.message == "Error: All questions not yet checked"
  //         ? err.message
  //         : "Something went wrong"
  //     );
  //   },
  //   onCompleted: ({ studentGetOnlyTestMarks }) => {
  //     setValues({
  //       ...formValues,
  //       fName: studentGetOnlyTestMarks.studPortrait.alias.first,
  //       lName: studentGetOnlyTestMarks.studPortrait.alias.last,
  //       grade: studentGetOnlyTestMarks.studPortrait.grade,
  //       totalScore: studentGetOnlyTestMarks.totalScore,
  //       totalQuesMarks: studentGetOnlyTestMarks.totalQuesMarks,
  //       pass: studentGetOnlyTestMarks.pass,
  //     });
  //   },
  // });
  useEffect(() => {
    if (!state)
      navigate(fUser.role !== "Student" ? "/tests" : "/", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <br />
      {LoadingSpinner(loading)}
      {fUser.role !== "Student" ? (
        <WrapTextDiv>
          <ColumnFlexDiv>
            <RowFlexDiv>
              <StyledTypography variant="h5">Student Name:</StyledTypography>
              <StyledTypography variant="h5">
                {formValues.fName} {formValues.lName}
              </StyledTypography>
              <StyledTypography variant="h5">
                Student Grade: {formValues.grade}
              </StyledTypography>
            </RowFlexDiv>
            <StyledTypography variant="h5">
              Student Score: {formValues.totalScore}
            </StyledTypography>
          </ColumnFlexDiv>
        </WrapTextDiv>
      ) : (
        <WrapTextDiv>
          <StyledTypography variant="h5">
            Your Total Score: {formValues.totalScore}
          </StyledTypography>
        </WrapTextDiv>
      )}
      <WrapTextDiv>
        <StyledTypography variant="h5">
          Total Test Marks: {formValues.totalQuesMarks}
        </StyledTypography>
      </WrapTextDiv>
      <WrapTextDiv>
        {formValues.pass == -1 || formValues.pass == 1 ? (
          <StyledTypography variant="h5">
            Result:{" "}
            <span style={{ color: "red" }}>
              {formValues.pass == -1
                ? "FAIL"
                : formValues.pass == 1
                ? "PASS"
                : "TBD"}
            </span>
          </StyledTypography>
        ) : null}
      </WrapTextDiv>

      <div>
        <div>
          <StyledPaper>
            <Chart
              className="bloomLine"
              dataSource={formValues.studOverallBloomGraphData}
            >
              <CommonSeriesSettings argumentField="bloomName" type="line" />
              {performanceType?.map((index) => (
                <Series
                  key={index.value}
                  valueField={index.value}
                  name={index.performance}
                />
              ))}
              <Margin left={20} bottom={20} top={25} />
              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Bloom Taxonomy Performance"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
          <StyledPaper>
            <Chart
              className="questionLine"
              dataSource={formValues.questionWiseGraphData}
            >
              <CommonSeriesSettings argumentField="type" type="line" />
              {performanceType?.map((index) => (
                <Series
                  key={index.value}
                  valueField={index.value}
                  name={index.performance}
                />
              ))}
              <Margin left={20} bottom={20} top={25} />
              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Question-wise Performance"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
        </div>
        <br />
        <div>
          <StyledPaper>
            <Chart className="bloomLine" dataSource={formValues.quesList}>
              <CommonSeriesSettings argumentField="quesNo" type="line" />
              <Series valueField="indiTimeSpent" name="Student" />
              <Series valueField="avgTimeSpent" name="Overall" />
              <Margin left={20} bottom={20} top={25} />
              <ValueAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
                title="seconds"
              >
                <ChartGrid visible={false} />
              </ValueAxis>
              <ArgumentAxis
                // valueMarginsEnabled={false}
                // discreteAxisDivisionMode="crossLabels"
                title="question numbers"
                tickInterval={1}
              >
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Time Spent on Questions"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
          <StyledPaper>
            <Chart className="bar" dataSource={formValues.barPerformanceData}>
              <CommonSeriesSettings
                argumentField="state"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
              >
                <Label visible={true}>
                  <Format type="fixedPoint" precision={0} />
                </Label>
              </CommonSeriesSettings>
              <Margin left={20} bottom={20} top={25} />
              <Series
                argumentField="type"
                valueField="student"
                name="Student"
              />
              <Series
                argumentField="type"
                valueField="overall"
                name="Overall"
              />
              <Title text="Student Performance"></Title>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
              ></Legend>
            </Chart>
          </StyledPaper>
        </div>
        <StyledPaper>
          <ColumnFlexDiv>
            <h2>Performance based on Bloom Taxonomy</h2>
            <Funnel
              id="bp123"
              className="pyramid"
              dataSource={formValues.bloomData}
              inverted={true}
              algorithm="dynamicHeight"
              palette="Harmony Light"
              argumentField="bloomName"
              valueField="value"
            >
              <Margin bottom={30} top={30} />
              <Title>
                <Margin bottom={30} />
              </Title>
              <Tooltip enabled={true} />
              <Item>
                <Border visible={true} />
              </Item>
              <Legend visible={true} />
              <Label
                visible={true}
                horizontalAlignment="left"
                backgroundColor="none"
              >
                <Font size={16} />
              </Label>
            </Funnel>
          </ColumnFlexDiv>
        </StyledPaper>
        {fUser.role !== "Student" ? (
          <div>
            <h2>Question wise performance</h2>
            <StyledGrid container spacing={1}>
              {formValues.quesList.map((ques, index) => {
                return (
                  // <div className={classes.div1}>
                  <StyledGrid item xs={12} sm={4} key={index}>
                    <StyledCard>
                      <p>{ques.quesNo})</p>
                      <StyledCardContent>
                        <MathJax dynamic={true}>
                          {ques.quesIs[
                            getLangIndex(ques.quesIs, ques.quesLangs[0])
                          ] &&
                          ques.quesIs[
                            getLangIndex(ques.quesIs, ques.quesLangs[0])
                          ]?.is
                            ? ques.quesIs[
                                getLangIndex(ques.quesIs, ques.quesLangs[0])
                              ].is
                            : `Not Available in ${ques.quesLangs[0]} language`}
                        </MathJax>
                      </StyledCardContent>
                      <StyledCardContent>
                        <p>
                          No. students correctly solved: {ques.nosCorSolved}
                        </p>
                      </StyledCardContent>
                    </StyledCard>
                  </StyledGrid>
                  // </div>
                );
              })}
            </StyledGrid>
          </div>
        ) : null}
      </div>
      <h2>Total Percent:</h2>
      <p>{formValues.finalPer}%</p>
    </div>
  );
}
