import React from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/system";

import { Link, useHistory } from "react-router-dom";

const FooterDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  color: "white",
  backgroundColor: "#1f5156",
  bottom: 0,
  width: "100%",
  height: 40,
  textAlign: "left",
  paddingTop: 15,
  zIndex: 10,
  paddingLeft: 20,
  [theme.breakpoints.down("xs")]: {
    fontSize: 11,
    paddingTop: 25,
    textAlign: "center",
    paddingBottom: 10,
  },
}));
const ButtonsDiv = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: 10,
  right: 10,
  [theme.breakpoints.down("xs")]: {
    display: "flex",
    // paddingBottom: 25,
    position: "relative",
    paddingTop: 10,
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  fontSize: 12,
  "&:hover": {
    color: "#f5b921",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: 7,
    width: 2,
  },
}));
export default function Footer() {
  const [hostname, setHostname] = React.useState(window.location.hostname);

  return (
    <FooterDiv>
      <Typography>
        Copyright © Fenlei Technology Private Limited, 2022
      </Typography>
      <ButtonsDiv>
        {hostname === "bprim.gyansopan.com" ? (
          <Link
            exact
            to={{
              pathname: "/aboutUs",
            }}
            style={{
              textDecoration: "none",
              width: "100%",
              margin: "0px !important",
              textAlign: "left",
            }}
          >
            <StyledButton>About Us</StyledButton>
          </Link>
        ) : null}
        <Link
          exact
          to={{
            pathname: "/contactUs",
          }}
          style={{
            textDecoration: "none",
            width: "100%",
            margin: "0px !important",
            textAlign: "left",
          }}
        >
          <StyledButton>Contact Us</StyledButton>
        </Link>
        {hostname === "bprim.gyansopan.com" ? (
          <Link
            exact
            to={{
              pathname: "/faqs",
            }}
            style={{
              textDecoration: "none",
              width: "100%",
              margin: "0px !important",
              textAlign: "left",
            }}
          >
            <StyledButton>FAQs</StyledButton>
          </Link>
        ) : null}
        <Link
          exact
          to={{
            pathname: "/privacyPolicy",
          }}
          style={{
            textDecoration: "none",
            width: "100%",
            margin: "0px !important",
            textAlign: "left",
          }}
        >
          <StyledButton>Privacy Policy</StyledButton>
        </Link>
        <Link
          exact
          to={{
            pathname: "/dataPolicy",
          }}
          style={{
            textDecoration: "none",
            width: "100%",
            margin: "0px !important",
            textAlign: "left",
          }}
        >
          <StyledButton>Data Policy</StyledButton>
        </Link>
        <Link
          exact
          to={{
            pathname: "/termsAndConditions",
          }}
          style={{
            textDecoration: "none",
            width: "100%",
            margin: "0px !important",
            textAlign: "left",
          }}
        >
          <StyledButton>Terms of Use</StyledButton>
        </Link>
      </ButtonsDiv>
    </FooterDiv>
  );
}
