import React from "react";
import { Typography } from "@mui/material";
import Footer from "../Footer";
import Header from "../FrontHeader";

export default function TermsAndConditions() {
  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      <div style={{ textAlign: "justify", margin: 40 }}>
        <Typography
          variant="h5"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Terms of Use
        </Typography>
        <Typography
          variant="h6"
          style={{ textAlign: "right", fontWeight: "bold" }}
        >
          6th September , 2022
        </Typography>{" "}
        <br />
        <br />
        <Typography>
          Welcome to the terms and conditions ("Terms'') of FENLEI TECHNOLOGY
          PRIVATE LIMITED (hereafter "FENLEI"), a company with its registered
          office at F.NO.14, KRUSHNAI APARTMENT, LANE 17A, GARMALA, DHAYARI,
          PUNE 411041, Maharashtra, and a business registered under the
          Companies Act, 2013. Fenlei designed and developed GYANSOPAN in the
          educational technology sector for its business expansion.
          <br />
          <br />
          You, whether an individual or acting on behalf of an organization
          ("you, your, user") and FENLEI ("we," "us," or "our") have agreed to
          these Terms {"&"} Conditions in writing.
          <br />
          <br />
          These terms and the Privacy Policy posted on the Website constitute
          your agreement with us.
          <br />
          <br />
          <b>
            <u>ABOUT GYANSOPAN </u>
          </b>
          <br />
          <br />
          In order to improve educational services and student academic
          performance, the FENLEI is developing to provide the GYANSOPAN
          educational platform to cater to the needs of educational
          institutions, the teacher community, and students of any grade. Under
          this GYANSOPAN, the stakeholders have various modules or services
          designed or developed according to their demand and usage.
          <br />
          <br />
          <b>
            <u>ENTRY AGE FOR STUDENTS</u>
          </b>
          <br />
          <br />
          Students must have a minimum grade of V (5th) standard to access the
          GYANSOPAN. To access a service such as the 5th Grade Online
          Examination, the student needs to provide its credibility.
          <br />
          <br />
          <b>
            <u>USER SIGNUP GUIDELINES AND PRIVACY</u>
          </b>
          <br />
          <br />
          Utilizing this website ensures that:
          <br />
          <ul>
            <li>To use the website, you might need to register or sign up.</li>
            <li>
              You promise to keep the user's password and email address private.
            </li>
            <li>
              You acknowledge that anyone who signs in using your user ID and
              password is doing so with your permission, and that the company is
              not liable if the wrong person signs a page using those
              credentials.
            </li>
            <li>
              You undertake to preserve the truth of your legal capacity and to
              abide by these terms and conditions.
            </li>
            <li>
              All registration information you provide must be true, accurate,
              and complete to the best of your knowledge.
            </li>
            <li>
              The GYANSOPAN Privacy Policy applies to the user-provided personal
              information.
            </li>
          </ul>
          <br />
          We reserve the right to suspend or terminate your account and prohibit
          any and all current or future use of the Site if you provide any
          information that is untrue, inaccurate, not current, or incomplete, or
          that is obscene or otherwise objectionable, or if the FENLEI has good
          faith grounds to suspect that such information is untrue, inaccurate,
          not current, or incomplete, or that is objectionable (or any portion
          thereof)
          <br />
          <br />
          <b>
            <u>YOUR CONTENT</u>
          </b>
          <br />
          <br />
          In accordance with these Terms and Conditions, "YOUR CONTENT" refers
          to all materials that you submit to this Website, including any audio,
          video, text, images, photographs, graphics, comments, suggestions,
          personal information, or the opportunity to create, submit, post,
          display, transmit, perform, publish, distribute, or broadcast content.
          <br />
          <br />
          Provided that you are accountable for preserving the secrecy of your
          content. This implies that the user is accountable for all content
          uploaded by the user, and you undertake to notify us right away of any
          illegal use of your account.
          <br />
          <br />
          The FENLEI reserves the right, at any time and for any reason, without
          prior notice,
          <br />
          <ol>
            <li>to edit or otherwise change any Contributions;</li>
            <li>
              to reclassify any Contributions to position them in more
              appropriate areas on the Site; and
            </li>
            <li>to remove or delete any contributions.</li>
          </ol>
          <br />
          <b>
            <u>DATA OWNERSHIP</u>
          </b>
          <br />
          <br />
          The data you produce and store is your property. We respect your data
          ownership by refraining from editing, disseminating, or otherwise
          altering information unless you first provide GYANSOPAN permission and
          the necessary license.
          <br />
          <br />
          You consent to granting GYANSOPAN access to process your data as
          required by GYANSOPAN in order to provide the services.
          <br />
          <br />
          <b>
            <u>FEES AND PAYMENTS</u>
          </b>
          <br />
          <br />
          This is an educational tool and the fees are subject to the various
          examinations and test-series registration. The fees for each
          registration is decided by their respective creators. The payments you
          are required to pay are subject to the terms and conditions of each
          examination.
          <br />
          <br />
          <b>
            <u>REFUND AND CANCELLATION</u>
          </b>
          <br />
          <br />
          The refund and cancellation mechanism for each registration is decided
          by the respective examination creators. Before doing any registration
          you are subjected to the refund and cancellation policy of the exam,
          which will be provided during the registration process. If the
          registration process does not include the refund and cancellation
          condition , you are supposed to consider that you cannot cancel your
          registration.
          <br />
          <br />
          <b>
            <u>SERVICE DELIVERY</u>
          </b>
          <br />
          <br />
          This is an online educational tool and its services can be availed on
          successful verification of your identity and the respective
          registration. There is no physical services being delivered through
          this application. Some of the services might be enabled to you under
          successful payment and accepting terms and conditions.
          <br />
          <br />
          <b>
            <u>INTELLECTUAL PROPERTY RIGHTS</u>
          </b>
          <br />
          <br />
          The Company owns this website and all of the data, materials,
          documents, videos, and associated visuals (collectively, "Content").
          Due to the fact that all content is the company's intellectual
          property, it is adequately protected from unlawful reproduction and
          distribution by Indian and international intellectual property laws,
          copyright laws, trademark laws, and conventions. We give you
          permission to access, print, and download the content solely for your
          own internal use and information, as long as you keep any copyright
          and other proprietary notices present in the content.
          <br />
          <br />
          The content may not be altered in any way, copied or transmitted in
          any way, publicly performed, distributed, or used in any other way for
          commercial or public use. Your right to use this website would be
          suspended or terminated if you made any unauthorized use of the
          content or violated these terms in any other way. Additionally, you
          will need to delete and discard any downloaded content.
          <br />
          <br />
          The service's trademarks and logos are the property of their
          respective owners. Without the express written consent of the person
          or entity that owns the content, you are not permitted to otherwise
          reproduce, edit, copy, distribute, or use it for commercial reasons.
          <br />
          <br />
          We don't guarantee that the content will be updated, so it might be
          out of date. There could be typographical or technical errors in the
          content. Specifications and product descriptions could change.
          <br />
          <br />
          <b>
            <u>PROHIBITED USE</u>
          </b>
          <br />
          <br />
          The site may only be used for the purposes for which it is made
          available to you. You are categorically and unequivocally prohibited
          from doing everything listed below:
          <br />
          <ol>
            <li>
              Utilizing a bot, script, or other automated or non-human method to
              access the Site;
            </li>
            <li>
              To use the site in violation of any relevant local, state,
              national, or international law or regulation;
            </li>
            <li>
              Misuse of the website to engage in any form of marketing or
              advertising;
            </li>
            <li>
              Create a phony identity that could be deceptive or that represents
              you incorrectly;
            </li>
            <li>
              Without the owner's express written consent, modify, display,
              host, distribute, adapt, or reproduce another user's content;
            </li>
            <li>
              Harass, irritate, intimidate, or threaten any of our staff members
              or agents who are assisting you in using any part of the site;
            </li>
            <li>
              Without the owner's consent, creating, transmitting, obtaining,
              storing, or using data or electronic copies of copyrighted
              content.
            </li>
            <li>
              Any content you obtain from other service users is given to you AS
              IS and is exclusively for your information and personal use; it is
              not intended for copying, sending, reproducing, etc.;
            </li>
            <li>
              Use the links to third-party websites, transfer the services to
              third parties, or use trademarks, contents, etc. of third-party
              websites without adhering to their website terms and conditions;
            </li>
            <li>
              Using the service for immoral acts that are hidden from the
              general public and that undermine the integrity and unity of the
              nation; using the service to send unsolicited bulk emails or junk
              mail, spam, chain letters, or phishing scams.
            </li>
          </ol>
          <br />
          <b>
            <u>RIGHT TO MAKE CHANGES TO THE AGREEMENT</u>
          </b>
          <br />
          <br />
          This website may occasionally and for any cause undergo additions,
          deletions, or changes. We have the right to amend the terms of the
          agreement.
          <br />
          <br />
          We are under no duty to let you know about such changes. Only when the
          "LAST UPDATED" date of these terms and conditions is changed will you
          be informed.
          <br />
          <br />
          <b>
            <u>NO WARRANTIES</u>
          </b>
          <br />
          <br />
          Without any express or implied claims or guarantees of any kind,
          including, but not limited to, implied warranties of merchantability,
          fitness for a specific purpose, title, or non-infringement, this
          website and its contents are supplied "AS-IS-AND-AS." Information on
          the website may not be updated by GYANSOPAN without notice.
          <br />
          <br />
          The website is secure and free of bugs, viruses, and other malware,
          but GYANSOPAN makes no reasonable effort to guarantee it. Use of the
          GYANSOPAN services is at your own risk and responsibility. You are
          solely liable for any damage to your devices that is caused; GYANSOPAN
          makes no warranty regarding such harm.
          <br />
          <br />
          <b>
            <u>COMPLIANCE WITH APPLICABLE LAWS</u>
          </b>
          <br />
          <br />
          You consent to using this GYANSOPAN only in the ways that are
          permitted by these Terms of Service. Additionally, you promise not to
          use GYANSOPAN in any way that is against local laws. Additionally, you
          are not permitted to transmit anything that is obscene, defamatory,
          harassing, abusive, threatening, invading a user's privacy, infringing
          on another person's intellectual property rights using this platform.
          <br />
          <br />
          <b>
            <u>LIMITATION OF LIABILITY</u>
          </b>
          <br />
          <br />
          In no event shall we be liable for any direct, indirect, special,
          incidental, consequential, exemplary or punitive damages or any other
          loss or damage, including but not limited to loss of business profits,
          goodwill, computer failure, business data or information loss,
          equipment failure, business interruption arising from or in connection
          with the existence, use, inability to use, or results of use of the
          Content, or any other loss arising out of or resulting from your use
          of or inability to use, the Website or the Content GYANSOPAN's total
          liability to you for any service, whether direct or indirect, shall in
          no case exceed the fees that you paid for that service.
          <br />
          <br />
          <b>
            <u>INDEMNIFICATION</u>
          </b>
          <br />
          <br />
          You agree to hold the company fully indemnified from and against all
          liabilities, costs, claims, causes of action, damages, and expenses
          (including reasonable attorney's fees), resulting from or connected in
          any way to your violation of any of the terms of this agreement.
          <br />
          <br />
          <b>
            <u>INFORMATION SHARE</u>
          </b>
          <br />
          <br />
          You grant us permission to synchronize (including through an
          application) your device with the platform, as well as all essential
          rights.
          <br />
          <br />
          You must update your account information on Platform if you change or
          deactivate your mobile phone number or email address in order to
          prevent your messages from going to the person who gets your old
          number.
          <br />
          <br />
          <b>
            <u>SITE MANAGEMENT</u>
          </b>
          <br />
          <br />
          We manage this website from our office in Pune, India's State of
          Maharashtra, or from a location elsewhere in India. We make no claims
          regarding the suitability or accessibility of the content on this site
          for use outside of India, and access to the content from countries
          where it is banned is forbidden. It is prohibited for you to use,
          export, or re-export the content, or any copy or adaptation of it, in
          contravention of any applicable laws or rules, including, without
          limitation, international export laws and rules. If you choose to use
          this website from a location outside of India, you do so at your own
          risk and are in charge of adhering to any local laws that may be in
          force there.
          <br />
          <br />
          We reserve the right, but not the duty, to manage the GYANSOPAN in a
          way that promotes its proper operation while also safeguarding our
          rights and property.
          <br />
          <br />
          <b>
            <u>SUSPENSION AND TERMINATION</u>
          </b>
          <br />
          <br />
          Unless the Agreement is terminated, this Agreement will be in effect
          starting on the Effective Date and lasting for the Initial Term.
          <br />
          <br />
          This agreement may be terminated by either party with due notice to
          the other party. Additionally, GyanSopan may limit, suspend, or
          terminate your user account(s) and access to the Services in its sole
          discretion and without limiting its other remedies, delete or remove
          your Data and/or Credentials, and take technical and/or legal measures
          to prevent you from using the Services if you fail (or are otherwise
          unwilling or unable) to fulfill your obligations.
          <br />
          <br />
          If GYANSOPAN discovers any illegal behavior, your user account may be
          suspended for a fair amount of time. Similar to the above, your user
          account will be stopped if it is discovered to have been inactive for
          an extended length of time. Upon request from law enforcement or other
          government entities, your account will be suspended.
          <br />
          <br />
          Additionally, if it is determined that the terms and services
          indicated have not been followed, we reserve the right to terminate
          your account. In the event that GYANSOPAN violates its
          responsibilities under these terms, you have the right to terminate
          your user account and are entitled to a prorated refund of all
          previously paid fees as agreed.
          <br />
          <br />
          <b>
            <u>End of Terms of Service</u>
          </b>
          <br />
          <br />
          Please feel free to get in touch with us at the following email id if
          you have any queries about these terms or the privacy policy:
          <br />
          <br />
          E-MAIL {"–"} <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a>
          <br />
          <br />
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
