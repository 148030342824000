import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Link } from "react-router-dom";
import { Visibility, Delete } from "@mui/icons-material";
import { Tooltip, Paper } from "@mui/material";
// import SearchBar from "material-ui-search-bar";

import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledButton,
  StyledTableButton,
} from "../../../css/StyledComponents";

import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
const getStudentsQuery = loader(
  "../../../graphqlCalls/student/userGetAllStudents.gql"
);
const deleteStudentMutation = loader(
  "../../../graphqlCalls/student/UserDeleteStudent.gql"
);

export default function StudentList(props) {
  const [students, setStudents] = useState([]);
  const [studentToDelete, setStudentToDelete] = useState("");
  const [studentToDeleteName, setStudentToDeleteName] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  const [open, setOpen] = useState(false);

  const fUser = props.fUser;

  useEffect(() => {
    requestSearch(" ");
  }, []);

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getStudentsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onCompleted: ({ userGetAllStudents }) => {
        setStudents(userGetAllStudents);
        setRows(userGetAllStudents);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [
    DeleteStudentMutation,
    { loading: deleting, error: errorDeleting, data: dataDeleting },
  ] = useMutation(deleteStudentMutation, {
    onCompleted(data) {
      NotificationsSuccess("Deleted!");
      refetch();
    },
  });

  const deleteStudent = async () => {
    if (deleting) return;
    try {
      const callInfo = await DeleteStudentMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            email: studentToDelete,
          },
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      alert("Something went wrong");
    }
  };

  const requestSearch = (searchedValue) => {
    const filteredRows = students.filter((row) => {
      return row.student.portrait.alias.first
        .toLowerCase()
        .includes(searchedValue.toLowerCase());
    });
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const onSort = (type) => {
    const sorted = students.slice().sort((a, b) => {
      const isReversed =
        type === "ascending" ? 1 : type === "descending" ? -1 : 0;
      return (
        isReversed *
        a.student.portrait.alias.first.localeCompare(
          b.student.portrait.alias.first
        )
      );
    });
    setRows(sorted);
  };

  return (
    <div>
      {NotificationsContainer()}
      <StyledDialog maxWidth="md" open={open} disableEscapeKeyDown>
        <StyledDialogContent>
          <Paper>Are You Sure you want to Delete {studentToDeleteName} ?</Paper>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="contained"
            onClick={async () => {
              await deleteStudent();
              setOpen(false);
            }}
          >
            Yes,Delete
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <StyledButton onClick={() => onSort("ascending")}>Sort A-Z</StyledButton>
      <StyledButton onClick={() => onSort("descending")}>Sort Z-A</StyledButton>
      {/* <SearchBar
        value={searched}
        placeholder="Search By Name"
        onChange={(searchedValue) => requestSearch(searchedValue)}
        onCancelSearch={() => cancelSearch()}
        defaultValue=" "
      /> */}
      <StyledTableContainer component={Paper}>
        <StyledTable aria-label="simple table">
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                Name
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                Grade
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                Category
              </StyledTableCell>
              <StyledTableCell> </StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {rows.map((studCat, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {studCat?.student?.portrait.alias.first}{" "}
                  {studCat?.student?.portrait.alias.last}
                </StyledTableCell>
                <StyledTableCell>
                  {studCat?.student?.portrait.grade}
                </StyledTableCell>
                <StyledTableCell>{studCat.category}</StyledTableCell>
                <StyledTableCell>
                  <Link
                    exact
                    to={"/studentProfile"}
                    state={{
                      id: studCat?.student?._id,
                      email: studCat?.student?.portrait.pingAt.virtualAdd,
                      // primaryTopic: props.primaryTopic,
                      // secondaryTopic: props.secondaryTopic,
                      // gradeLevel: props.gradeLevel,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Tooltip title="View">
                      <StyledTableButton
                        // variant="contained"
                        sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
                        size="large"
                        startIcon={<Visibility />}
                        //   className={classes.buttonStyles}
                      ></StyledTableButton>
                    </Tooltip>
                  </Link>
                  <Tooltip title="Remove">
                    <StyledTableButton
                      disabled={deleting}
                      sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
                      onClick={() => {
                        setStudentToDeleteName(`${studCat?.student?.portrait.alias.first} 
                        ${studCat?.student?.portrait.alias.last}`);
                        setStudentToDelete(
                          studCat?.student?.portrait.pingAt.virtualAdd
                        );
                        setOpen(true);
                      }}
                      size="large"
                      startIcon={<Delete />}
                    ></StyledTableButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
}
