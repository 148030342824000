export const schoolList = [
  "A ONE SCHOOL",
  "A V B MATRIC HR SEC SCHOOL",
  "A V HIGH SCHOOL SHILADIH",
  "A.A.M. CHILDRENS ACADEMY",
  "A.B. MEMORIAL SCHOOL",
  "A.B. PUBLIC JUNIOR HIGH SCHOOL",
  "A.B.C. PUBLIC SCHOOL",
  "A.B.M. JUNIOR COLLEGE",
  "A.B.M. PUBLIC SCHOOL",
  "A.B.M. SCHOOL",
  "A.C. SCHOOL",
  "A.C.L. E.M. PUBLIC SCHOOL",
  "A.C.M.E. ACADEMY",
  "A.D. PUBLIC SCHOOL",
  "A.D.L.S. SUNSHINE SCHOOL",
  "A.E.C.S. PAVAN SCHOOL",
  "A.E.C.S. RAMAPRIYA SCHOOL",
  "A.E.S. CENTRAL SCHOOL",
  "A.G. D.A.V. CENTENARY PUBLIC SCHOOL",
  "A.G. HIGH SCHOOL AND G.D. PARIKH HIGHER SECONDARY SCHOOL",
  "A.G.K.M. HIGH SCHOOL",
  "A.I.M. SALCETE HIGH SCHOOL",
  "A.I.S. ENGLISH MEDIUM SCHOOL",
  "A.J.D. ALMEIDA HIGH SCHOOL",
  "A.J.I. ENGLISH SCHOOL",
  "A.K. DAV PUBLIC SCHOOL",
  "A.K. GHOSH MEMORIAL SCHOOL",
  "A.K. JOSHI ENGLISH MEDIUM SCHOOL",
  "A.K.M. HIGH SCHOOL",
  "A.K.M. HIGHER SECONDARY SCHOOL",
  "A.K.T. ACADEMY MATRICULATION HIGHER SECONDARY SCHOOL",
  "A.L.G. MATRICULATION SCHOOL",
  "A.M.A.L.A. GIRLS HIGHER SECONDARY SCHOOL",
  "A.N. GURRAL MEMORIAL HIGH SCHOOL",
  "A.N. PUBLIC SECONDARY SCHOOL",
  "A.P. INTERNATIONAL SCHOOL",
  "A.P. PATIL HIGH SCHOOL",
  "A.P. RESIDENTAL SCHOOL",
  "A.P. RESIDENTIAL SCHOOL",
  "A.P.G. PUBLIC SCHOOL",
  "A.P.J. PUBLIC SCHOOL",
  "A.P.R.M. CENTRAL SCHOOL",
  "A.P.S. ACADEMY SENANI VIHAR",
  "A.P.S. PUBLIC SCHOOL",
  "A.R.J. PUBLIC SCHOOL",
  "A.S. HIGH SCHOOL",
  "A.S. MODERN PRIMARY SCHOOL",
  "A.S. MODERN SCHOOL",
  "A.S. MODERN SENIOR SECONDARY SCHOOL",
  "A.S.C. PUBLIC SCHOOL",
  "A.S.T. JOSEPHS BOYS HIGHER SECONDARY",
  "A.V. INTERNATIONAL PUBLIC SCHOOL",
  "A.V.B. PUBLIC SCHOOL",
  "A.V.M. HIGH SCHOOL",
  "A.V.M. SR. SEC. SCHOOL",
  "A.V.N. ENGLISH SCHOOL",
  "A.V.N. PUBLIC SCHOOL",
  "A.V.R. PUBLIC SCHOOL",
  "AACHARYA SHRI RAM MIDDLE SCHOOL",
  "AADARSH VIDHYA NIKETAN SCHOOL",
  "AADHUNIK PUBLIC SCHOOL",
  "AADHURAA SPECIAL SCHOOL",
  "AAKASH INTERNATIONAL SCHOOL",
  "AAMBIKA ENGLISH MEDIUM SCHOOL",
  "AANAND MARG SCHOOL",
  "AARADHYA INTERNATIONAL SCHOOL",
  "AARON PUBLIC SCHOOL",
  "AATHICHUDY SPECIAL SCHOOL",
  "AAYAPPA PUBLIC SCHOOL",
  "ABACUS MONTESSORI SCHOOL",
  "ABALUS PUBLIC SCHOOL",
  "ABDUL HAKEEM HINDU MUSLIM SCHOOL",
  "ABDUL SALAM RAFI RES SCHOOL",
  "ABHA PUBLIC SCHOOL",
  "ABHANGA SPECIAL SCHOOL FOR MENTALLY RETARDED",
  "ABHINAV BAL BHARATHI SENIOR SECONDARY SCHOOL",
  "ABHINAV BHARATI HIGH SCHOOL",
  "ABHINAV PRIMARY SCHOOL",
  "ABHISAR PUBLIC SCHOOL",
  "ABHISHEK PUBLIC SCHOOL",
  "ABHYASA RESIDENTIAL PUBLIC SCHOOL",
  "ABHYUDAYA HIGH SCHOOL",
  "ABOA PUBLIC SCHOOL",
  "ABOTANI VIDYA NIKETAN",
  "ABRAHAM MATRICULATION SCHOOL",
  "ABUTARA HIGH SCHOOL",
  "ACHARIYA BALA SIKSHA MANDIR",
  "ACHARIYA SIKSHA MANDIR",
  "ACHARYA NARENDER DEO PUBLIC SCHOOL",
  "ACHARYA PATHASALA PUBLIC SCHOOL",
  "ACHARYA VIDYA KULA SCHOOL",
  "ACLEM PUBLIC SCHOOL",
  "ACTIVITY HIGH SCHOOL",
  "ACTS SECONDARY SCHOOL",
  "ADAM PUBLIC SCHOOL",
  "ADAMAS INTERNATIONAL SCHOOL",
  "ADAMS EARLY LEARNING SCHOOL",
  "ADANI D.A.V. PUBLIC SCHOOL",
  "ADARSH ACADEMY SCHOOL",
  "ADARSH BAL NIKETAN",
  "ADARSH BAL VIDYALAYA",
  "ADARSH BOUDHIK HIGHER SECONDARY SCHOOL",
  "ADARSH ENGLISH MEDIUM SCHOOL",
  "ADARSH ENGLISH SCHOOL",
  "ADARSH GRAMIN PUBLIC SCHOOL",
  "ADARSH HIGH SCHOOL",
  "ADARSH NIVASI SCHOOL",
  "ADARSH PUBLIC SCHOOL",
  "ADARSH PUBLIC SENIOR SECONDARY SCHOOL",
  "ADARSH RESIDENTIAL SCHOOL",
  "ADARSH SENIOR SECONDARY SCHOOL",
  "ADARSH VIDYA KENDRA",
  "ADARSH VIDYA MANDIR SCHOOL",
  "ADARSH VIDYA MANDIR",
  "ADARSH VIDYALAYA JUNIOR HIGH SCHOOL",
  "ADHARSH VIDHYALAYA HIGHER SECONDARY SCHOOL",
  "ADHARSHILA ACADEMY",
  "ADHARSHILA THE FOUNDATION",
  "ADHIPARASAKTHI HIGHER SECONDARY SCHOOL",
  "ADHITYA TECHNO SCHOOL",
  "ADHYAPANA SCHOOL",
  "ADHYAYAN CENTRAL SCHOOL",
  "ADHYAYAN SCHOOL",
  "ADI SHANKARACHARYA CONVENT PUBLIC SCHOOL",
  "ADIDRAVIDA BOYS HIGHER SECONDARY SCHOOL",
  "ADINATH SENIOR SECONDARY SCHOOL",
  "ADITYA BIRLA HIGHER SECONDARY SCHOOL",
  "ADITYA BIRLA PUBLIC SCHOOL",
  "ADITYA ENGLISH SCHOOL",
  "ADITYA GIRLS HIGH SCHOOL",
  "ADITYA PUBLIC SCHOOL",
  "ADITYA VIDHYASHRAM MONTESSORI SCHOOL",
  "ADITYA VIDHYASHRAM RESIDENTIAL SCHOOL",
  "ADVANCE ACADEMY",
  "ADVANCED ACADEMY",
  "AGA KHAN SCHOOL",
  "AGARWAL PUBLIC SCHOOL",
  "AGGARSAIN PUBLIC SCHOOL",
  "AGGARWAL MATRICULATION SCHOOL",
  "AGGARWAL PUBLIC SCHOOL",
  "AGRA GLORY PUBLIC INTER COLLEGE",
  "AGRAHAR CHARBATIA AGRAHAT HIGH SCHOOL",
  "AGRASAIN BALIKA SIKSHA SADAN SCHOOL",
  "AGRASEN D.A.V. PUBLIC SCHOOL",
  "AGRASEN VIDYALAYA HIGHER SEC SCHOOL",
  "AGROHA PUBLIC SCHOOL",
  "AGYAWANTI MARWAHA DAV GIRLS SENIOR SECONDARY SCHOOL",
  "AHIMSA PUBLIC SCHOOL",
  "AHIR MODERN PUBLIC SCHOOL",
  "AHLCON INTERNATIONAL SCHOOL",
  "AHMED AQUEEL EASTERN SAINT SCHOOL",
  "AHMEDABAD INTERNATIONAL SCHOOL",
  "AHMEDABAD PUBLIC SCHOOL",
  "AHUJA SCHOOL AND COLLEGE",
  "AIDED HIGH SCHOOL",
  "AIR FORCE CHILDREN SCHOOL",
  "AIR FORCE JUNIOR HIGH SCHOOL",
  "AIR FORCE PUBLIC SCHOOL",
  "AIR FORCE SCHOOL CONTACT INFORMATION",
  "AIR FORCE SCHOOL",
  "AJ KANERIA MIDDLE SCHOOL",
  "AJANTA PUBLIC SCHOOL",
  "AJAY PUBLIC SCHOOL",
  "AJIT KARAM SINGH INTERNATIONAL PUBLIC SCHOOL",
  "AJIT VIDYA MANDIR",
  "AJMANI INTERNATIONAL SCHOOL",
  "AJMERA GLOBAL SCHOOL - IB WORLD SCHOOL",
  "AJMERA MUKESH NEMICHAND BHAI SCHOOL",
  "AKAL ACADEMY",
  "AKASH INTERNATIONAL RESIDENTIAL SCHOOL",
  "AKASHDEEP PUBLIC SCHOOL",
  "AKELLA PUBLIC SCHOOL",
  "AKHILA PUBLIC SCHOOL",
  "AKLANK DAY BOARDING CUM RESIDENTIAL SCHOOL",
  "AKLANK PUBLIC SCHOOL",
  "AKLANK SENIOR SECONDARY SCHOOL",
  "AKLAVYA INTERNATIONAL SCHOOL",
  "AKLIA SENIOR SECONDRY SCHOOL",
  "AKSHAR VIDYADHAM",
  "AKSHARA FUN SCHOOL",
  "AKSHARA INTERNATIONAL SCHOOL",
  "AKSHARA RESIDENTIAL SCHOOL",
  "AKSHARA SCHOOL OF EXCELLENCE",
  "AKSHARA SCHOOL",
  "AKSHAYA ACADEMY MATRIC. HR. SEC. SCHOOL",
  "AKSHAYA VIDYALAYA MATRIC HIGHER SECONDARY SCHOOL",
  "AL - AMEEN INTERNATIONAL PUBLIC SCHOOL",
  "AL - AMEEN PUBLIC SCHOOL",
  "AL ANSAR ENG MED PUBLIC SCHOOL",
  "AL FALAH ENGLISH SCHOOL",
  "AL- FALAH NURSERY L.P. SCHOOL",
  "AL HARAMAIN ENGLISH SCHOOL",
  "AL HIRA MODEL SCHOOL",
  "AL HUDA MODAL SCHOOL",
  "AL MADRASA TUS SAIFIYA TUL BURHANIYAH",
  "AL MADRASA-TUS-SAIFIYA-TUL-BURHANIAH",
  "AL QAMAR ACADEMY",
  "ALAN FELDMAN PUBLIC SCHOOL",
  "ALANGAD JAMA-ATH PUBLIC SCHOOL",
  "ALARD PUBLIC SCHOOL",
  "AL-BARKAAT PUBLIC SCHOOL",
  "ALEEMUL ISLAM HIGHER SECONDARY SCHOOL",
  "ALEKHYA PUBLIC SCHOOL",
  "ALEXANDRA SCHOOL",
  "ALFA ENGLISH MEDIUM SCHOOL",
  "ALFEEN PUBLIC SCHOOL",
  "ALFRED NOBEL PUBLIC SCHOOL",
  "AL-HIRA PUBLIC SCHOOL",
  "ALIGARH MODERN SCHOOL",
  "ALIGARH PUBLIC COLLEGIATE SCHOOL",
  "ALIGARH PUBLIC SCHOOL",
  "ALIYA ARABIC ENGLISH SCHOOL",
  "ALL SAINTS COLLEGE",
  "ALL SAINTS CONVENT SCHOOL",
  "ALL SAINT'S DEOCESIAN",
  "ALL SAINTS HIGH SCHOOL",
  "ALL SAINTS INTERNATIONAL RESIDENTIAL SCHOOL",
  "ALL SAINTS PUBLIC SCHOOL",
  "ALL SAINTS SCHOOL",
  "ALLAHABAD PUBLIC SCHOOL SUBEDARGANJ",
  "ALLAHABAD PUBLIC SCHOOL",
  "ALLAMA SHIBLI URDU PRIMARY SCHOOL",
  "ALLENGANJ NURSERY SCHOOL",
  "ALLONS PUBLIC SCHOOL",
  "ALLURI SITARAMA RAJU RESIDENTIAL PUBLIC SCHOOL",
  "ALLURISITARAMA RAJU RESIDENTIAL PUBLIC SCHOOL,",
  "ALMA MATER SCHOOL",
  "ALMIGHTY NURSERY & PRIMARY SCHOOL",
  "ALOK BHARTI PUBLIC SCHOOL",
  "ALOK E.M. HIGH SCHOOL",
  "ALOK ENGLISH MIDIUM SCHOOL",
  "ALOK PUBLIC SCHOOL",
  "ALOK SCHOOL",
  "ALOK SENIOR SECONDARY SCHOOL",
  "ALPHA ENGLISH SCHOOL",
  "ALPHA MATRICULATION HIGHER SECONDARY SCHOOL",
  "ALPHA PUBLIC SCHOOL",
  "ALPHONSA CONVENT SCHOOL",
  "ALPHONSA ENG MEDIUM SCHOOL",
  "ALPHONSA ENG. MED. SCHOOL",
  "ALPHONSA ENGLISH MEDIUM NURSERY SCHOOL",
  "ALPHONSA ENGLISH MEDIUM SCHOOL",
  "ALPHONSA HIGH SCHOOL",
  "ALPHONSA RESIDENTIAL SCHOOL",
  "ALPHORES SCHOOL",
  "ALPINE ACADEMY",
  "ALPINE CONVENT SCHOOL",
  "ALPINE INTERNATIONAL SCHOOL",
  "ALPINE PUBLIC SCHOOL",
  "ALPINE VALLEY BOARDING SCHOOL",
  "ALVERNIA MATRICULATION HIGHER SECONDARY SCHOOL",
  "ALWAR PUBLIC SCHOOL",
  "ALWIN MEMORIAL PUBLIC SCHOOL",
  "ALWYN ENGLISH PRIMERY SCHOOL",
  "AMALA NURSERY AND L.P. SCHOOL",
  "AMALAGIRI BETHANY VIDYALAYA",
  "AMALAMBIKA CONVENT ENGLISH SCHOOL",
  "AMALORPAVAM HIGHER SECONDARY SCHOOL",
  "AMAN DEEP PUBLIC SCHOOL",
  "AMAR JEEVAN PUBLIC SCHOOL",
  "AMAR JYOTHI PUBLIC SCHOOL",
  "AMAR JYOTI PUBLIC SCHOOL",
  "AMAR PASHA PUBLIC SCHOOL",
  "AMAR PUBLIC SCHOOL",
  "AMAR VANI SCHOOL",
  "AMARA JYOTHI PUBLIC SCHOOL",
  "AMARAJYOTHI ENGLISH PRIMARY SCHOOL",
  "AMARJYOTI SARASWATI INTERNATIONAL SCHOOL",
  "AMARNATH VIDYA ASHRAM SENIOR SECONDARY PUBLIC SCHOOL",
  "AMARPURI SENIOR SECONDARY PUBLIC SCHOOL",
  "AMBALA PUBLIC SCHOOL",
  "AMBEDKAR PUBLIC SCHOOL",
  "AMBEDKAR PUBLIC SCHOOL63 NETAJI SUBHASH PATH",
  "AMBER VALLEY RESIDENTIAL SCHOOL",
  "AMBIENCE OF ANGELS",
  "AMBIKA DEVI HIGH SCHOOL",
  "AMBIKA ENGLISH MEDIUM SCHOOL",
  "AMBIKA HIGHER SECONDARY SCHOOL",
  "AMBIKA MODERN SCHOOL",
  "AMBIKA PUBLIC SCHOOL",
  "AMBUJA PUBLIC SCHOOL",
  "AMBUJA VIDYA PEETH",
  "AMEENIYA HIGH SCHOOL",
  "AMENITY PUBLIC SCHOOL",
  "AMERICAN EMBASSY SCHOOL",
  "AMERICAN FRIENDSHIP RESIDENTIAL SCHOOL",
  "AMERICAN INTERNATIONAL SCHOOL",
  "AMERICAN MONTESSORI INTERNATIONAL SCHOOL",
  "AMERICAN SCHOOL OF BOMBAY",
  "AMITY GLOBAL SCHOOL",
  "AMITY INTERNATIONAL PUBLIC SCHOOL",
  "AMITY INTERNATIONAL SCHOOL",
  "AMLA HIGH SCHOOL",
  "AMMA HPS SARTHI",
  "AMRIT BHARTI PUBLIC SEC. SCHOOL",
  "AMRIT HIGH SCHOOL",
  "AMRIT INDO CANADIAN ACADEMY",
  "AMRIT MODEL SENIOR SECONDARY SCHOOL",
  "AMRITA SANSKRIT HIGHER SECONDARY SCHOOL",
  "AMRITA VIDYALAM",
  "AMRITA VIDYALAYAM",
  "AMRITSAR PUBLIC SCHOOL",
  "AMRUTHA PUBLIC SCHOOL",
  "AMTUL'S PUBLIC SCHOOL",
  "AMUDH PRIMARY SCHOOL",
  "AMUDHAM MATRICULATION HIGHER SECONDARY SCHOOL",
  "ANAMAY SCHOOL",
  "ANAND BAGH PUBLIC SCHOOL",
  "ANAND BHAVAN SCHOOL",
  "ANAND BHAWAN SCHOOL",
  "ANAND ENGLISH MEDIUM SCHOOL",
  "ANAND HIGH SCHOOL",
  "ANAND HIGHER SECONDARY SCHOOL",
  "ANAND MEMORIAL ACADEMY",
  "ANAND NIKETAN PRIMARY SCHOOL",
  "ANAND NIKETAN",
  "ANAND PUBLIC SCHOOL",
  "ANAND PUBLIC SENIOR SECONDARY SCHOOL",
  "ANANDA MARGA HIGH SCHOOL",
  "ANANDABAVAN SEVA SANSTHA GAUSHALA TRUST SCHOOL",
  "ANANTH INTERNATIONAL SCHOOL",
  "ANANTH LEWIS PUBLIC SCHOOL",
  "ANANTH MEMORIAL MAT. HIGH SECONDARY SCHOOL",
  "ANANTH NURSERY AND PRIMARY",
  "ANANTHA INTERNATIONAL SCHOOL",
  "ANANTHA VIDYA NIKETAN",
  "ANANTHA VIDYANIKETANA",
  "ANDAVAR MATRIC SCHOOL",
  "ANDH MAHAVIDYALAYA",
  "ANDHRA ASSOCIATION ENGLISH SCHOOL",
  "ANGAPPA C.B.S.E. SENIOR SECONDARY SCHOOL",
  "ANGEL CONVENT SCHOOL",
  "ANGEL ENGLISH PUBLIC SCHOOL",
  "ANGEL HEARTS",
  "ANGEL LAND HIGH SCHOOL",
  "ANGEL PUBLIC SCHOOL",
  "ANGELS ACADEMY SCHOOL",
  "ANGELS ARC ENGLISH MEDIUM SCHOOL",
  "ANGELS ARC SENIOR SECONDARY SCHOOL",
  "ANGEL'S NURSERY SCHOOL",
  "ANGELS PUBLIC SCHOOL",
  "ANGEL'S PUBLIC SCHOOL",
  "ANGELS PUBLIC SENIOR SECONDARY SCHOOL",
  "ANGLO SANSKRIT MODEL SR. SEC. SCHOOL",
  "ANGU MIDDLE SCHOOL",
  "ANIL SARASWATI VIDHYA MANDIR HIGHER SECONDARY SCHOOL",
  "ANITA PUBLIC SCHOOL",
  "ANJUMAN HIGH SCHOOL",
  "ANJUMAN SECONDARY SCHOOL",
  "ANJUMAN URDU PRIMARY SCHOOL",
  "ANJUMAN-I-ISLAM PUBLIC SCHOOL",
  "ANKIT PUBLIC SCHOOL",
  "ANKITA MONTESSORI HOUSE OF CHILDREN",
  "ANKUR ENGLISH SCHOOL",
  "ANKUR PUBLIC SCHOOL",
  "ANNA NEHRU MATRICULATION SCHOOL",
  "ANNAI PLAY NURSERY SCHOOL",
  "ANNAI VEILANKANNI'S MATRICULATION HIGHER SECONDARY SCHOOL",
  "ANNAMALAI MATRIC SCHOOL",
  "ANOOP MODEL SCHOOL",
  "ANSAR PUBLIC SCHOOL",
  "ANSARI OXFORD CENTRAL SCHOOL",
  "ANUBHUTI SCHOOL",
  "ANUGRAHA MANDHIR K.G. AND PRIMARY SCHOOL",
  "ANUJ MODERN PUBLIC SCHOOL",
  "ANUPAM PRIMARY KUMAR SCHOOL",
  "ANUPAM PUBLIC SCHOOL",
  "ANUPAM PUBLIC SENIOR SECONDARY SCHOOL",
  "ANUPAMA NURSERY SCHOOL",
  "ANURADHA PUBLIC SR. SEC. SCHOOL",
  "APEEJAY SCHOOL",
  "APEX INTERNATIONAL PUBLIC SCHOOL",
  "APEX PRIMARY AND NURSERY SCHOOL",
  "APEX PUBLIC SCHOOL",
  "APEX SR. SEC. SCHOOL",
  "APIRAM GOGOI GIRLS HIGH SCHOOL",
  "APL GLOBAL SCHOOL",
  "APOLLO ACADEMY",
  "APOLLO INTERNATIONAL SCHOOL",
  "APOLLO NATIONAL PUBLIC SCHOOL",
  "APOLLO PUBLIC SCHOOL",
  "APPLE GROVE SCHOOL",
  "APPLE KIDS INTERNATIONAL PRE-SCHOOL",
  "APPLE WOOD PUBLIC SCHOOL",
  "APPOLLO NATIONAL PUBLIC SCHOOL",
  "APRICOT HIGH SCHOOL",
  "AR PUBLIC SCHOOL",
  "ARABINDA MADHYAMIK VIDYALAYA",
  "ARABINDA PURNANGA BIDYALAYA",
  "ARADHANA SCHOOL",
  "ARAFA PUBLIC SCHOOL",
  "ARALDIHI HIGH SCHOOL",
  "ARAMBAGH HIGH SCHOOL",
  "ARARIA PUBLIC SCHOOL",
  "ARAVALI PUBLIC SCHOOL",
  "ARAVINDA MODEL SCHOOL",
  "ARBOR INTERNATIONAL SCHOOL",
  "ARC CHARBATIA",
  "ARCADIA ACADEMY",
  "ARCADIAN PUBLIC SCHOOL",
  "ARCHANGEL PRIMARY SCHOOL",
  "ARISE PUBLIC SCHOOL",
  "ARJ PUBLIC SCHOOL",
  "ARK PUBLIC SCHOOL",
  "ARMY PUBLIC SCHOOL",
  "ARMY SCHOOL KANDRORI",
  "ARMY SCHOOL MISSAMARI",
  "ARMY SCHOOL",
  "ARORVANSH PUBLIC SCHOOL",
  "ARPAN PUBLIC SCHOOL",
  "ARSHA VIDYA VIKASA KENDRA",
  "ARSHA VIJNANA BHARTHI SCHOOL",
  "ARTIFICIAL INTELLIGENCE SCHOOL",
  "ARUL MALAR MATRICULATION HIGHER SECONDARY SCHOOL",
  "ARULMIGU MEENAKSHI AMMAN HIGHER SECONDARY SCHOOL",
  "ARUN UDAY SCHOOL",
  "ARUNACHAL PALI VIDYAPITH",
  "ARUNACHALA NURSERY AND PRIMARY SCHOOL",
  "ARUNODAYA PUBLIC SCHOOL",
  "ARUNODYA PUBLIC SCHOOL",
  "ARWACHIN INTERNATIONAL SCHOOL",
  "ARWACHIN PUBLIC SCHOOL",
  "ARWACHIN SHIKSHA SADAN SCHOOL",
  "ARYA ACADEMY",
  "ARYA ADRASH SEC. SR. SCHOOL",
  "ARYA BAL SHANTI NIKETAN",
  "ARYA GIRLS HIGH SCHOOL",
  "ARYA GIRLS PUBLIC SCHOOL",
  "ARYA HIGH SCHOOL",
  "ARYA KANYA HIGH SCHOOL",
  "ARYA KANYA VIDYALAYA",
  "ARYA PUBLIC SCHOOL",
  "ARYA SENIOR SECONDARY SCHOOL",
  "ARYA SR. SEC. SCHOOL",
  "ARYA VEDIC PUBLIC SCHOOL",
  "ARYA VIDYALAYA",
  "ARYAN INTERNATIONAL SCHOOL",
  "ARYAN PUBLIC SCHOOL",
  "ASAIAN CHRISTIAN HIGH SCHOOL",
  "ASAN MEMORIAL MATRICULATION & HIGHER SECONDARY SCHOOL",
  "ASANSOL ST. ANTHONY SECONDARY SCHOOL",
  "ASC PUBLIC SCHOOL",
  "ASCENT PUBLIC SCHOOL",
  "ASHA BHARTI PUBLIC SCHOOL",
  "ASHA KIRAN ORPHANAGE",
  "ASHA NOBLE PUBLIC SCHOOL",
  "ASHA PUBLIC SCHOOL",
  "ASHIANA PUBLIC SCHOOL",
  "ASHMAH INTERNATIONAL SCHOOL",
  "ASHOK BANGAR MEMORIAL ENGLISH SCHOOL",
  "ASHOK HALL GIRLS HIGHER SECONDARY SCHOOL",
  "ASHOK HALL GIRLS' RESIDENTIAL SCHOOL",
  "ASHOK INTERNATIONAL PUBLIC SCHOOL",
  "ASHOK LNTERNATIONAL PUBLIC SCHOOL",
  "ASHOK MEMORIAL PUBLIC SCHOOL",
  "ASHOKA HALL PUBLIC SCHOOL",
  "ASHOKA MEMORIAL PUBLIC SCHOOL",
  "ASHOKA UNIVERSAL SCHOOL",
  "ASHOKHALL GIRLS' RESIDENTIAL SCHOOL",
  "ASHRAM HIGH SCHOOL FOR GIRLS",
  "ASHRAM UPPER PRIMARY SCHOOL",
  "ASHWANI PUBLIC SCHOOL",
  "ASIAN INTERNATIONAL SCHOOL",
  "ASIAN PUBLIC SCHOOL",
  "ASSAM RIFLES HIGH SCHOOL",
  "ASSAM RIFLES JUNIOR SCHOOL",
  "ASSAM RIFLES MIDDLE SCHOOL",
  "ASSAM RIFLES SECONDARY SCHOOL",
  "ASSAM RIFLES TRAINING CENTRE HIGH SCHOOL",
  "ASSAM VALLEY SCHOOL",
  "ASSEMBLY OF GOD CHURCH SCHOOL",
  "ASSEMBLY OF GOD SCHOOL",
  "ASSISI CONVENT SCHOOL",
  "ASSISI ENGLISH MEDIUM HIGHER SECONDARY SCHOOL",
  "ASSUMPTION CONVENT SCHOOL",
  "ASSUMPTION HIGH SCHOOL",
  "ATAM DEVKI NIKETAN",
  "ATHENE NURSERY AND L.P. SCHOOL",
  "ATHREYA PUBLIC SCHOOL",
  "ATMIYA VIDYA MANDIR",
  "ATOMIC ENERGY CENTRAL SCHOOL",
  "ATTAPPADI ADIVASI HIGH SCHOOL",
  "ATUL VIDYALAYA",
  "AUCKLAND HOUSE SCHOOL",
  "AUDEN INSTITUTE OF EDUCATION",
  "AUDISANKARA SCHOOL",
  "AUM VALLEY SCHOOL",
  "AUROBINDO PUBLIC SCHOOL",
  "AUROBINDO SCHOOL OF NEW THOUGHT",
  "AUTHPUR NATIONAL MODEL SCHOOL",
  "AUXILIUM CONVENT SCHOOL",
  "AUXILIUM ENGLISH MEDIUM SCHOOL",
  "AUXILIUM HIGHER SECONDARY SCHOOLKATTAPANA",
  "AUXILIUM NAVA JYOTI SCHOOL",
  "AUXILIUM SCHOOL",
  "AVADH INTERNATIONAL SCHOOL",
  "AVADH PUBLIC SCHOOL",
  "AVALON HEIGHTS INTERNATIONAL SCHOOL",
  "AVATAR PUBLIC SCHOOL",
  "AVILA CONVENT MATRICULATION HIGHER SECONDARY SCHOOL",
  "AVON CAREER MODEL PUBLIC SCHOOL",
  "AVVAI GIRLS HIGHER SECONDARY SCHOOL",
  "AWADH PUBLIC SCHOOL",
  "AYATPUR HIGH SCHOOL",
  "AYESHA COVENT SCHOOL",
  "AYESHA TARIN MODERN PUBLIC SCHOOL",
  "AZAAN INTERNATIONAL SCHOOL",
  "AZAD EDUCATION CENTRE",
  "AZAD PUBLIC SCHOOL",
  "AZAD PUBLIC SN. SECONDARY SCHOOL",
  "AZADI VIDYAPITH",
  "AZIMUDDIN ASHRAF ISLAMIA INTER COLLEGE",
  "AZRA PUBLIC SCHOOL",
  "B B M B D A V PUBLIC SCHOOL",
  "B G S INTERNATIONAL RESIDENTIAL SCHOOL",
  "B K E & I CO-EDUCATIONAL PRIMARY SCHOOL",
  "B M M ENGLISH MEDIUM SCHOOL",
  "B N PUBLIC SCHOOL",
  "B P INDIAN PUBLIC SCHOOL",
  "B P S PUBLIC SCHOOL",
  "B R C M PUBLIC SCHOOL",
  "B S F SENIOR SECONDARY SCHOOL",
  "B S P CENTRAL SCHOOL",
  "B V BHAVANS LLOYDS VIDYA NIKETAN",
  "B V G GUNACHARN",
  "B. V. REDDY SR. SEC. SCHOOL",
  "B. VASUDEVA HIGH SCHOOL",
  "B.A AND K.R. CONCEPT SCHOOL",
  "B.B.L. PUBLIC SCHOOL",
  "B.C.M SCHOOL",
  "B.C.M. ARYA MODEL SENIOR SECONDARY SCHOOL",
  "B.C.M. HIGH SCHOOL",
  "B.C.M. SCHOOL",
  "B.D. MEMORIAL ENGLISH HIGH SCHOOL",
  "B.D. PUBLIC SCHOOL",
  "B.D. ROSARY SCHOOL",
  "B.D. SOMANI INTERNATIONAL SCHOOL",
  "B.E.M. HIGH SCHOOL",
  "B.E.M.L PUBLIC SCHOOL",
  "B.G.S. CENTRAL SCHOOL",
  "B.G.S. INTERNATIONAL PUBLIC SCHOOL",
  "B.G.S. INTERNATIONAL RESIDENTIAL SCHOOL",
  "B.G.S. NATIONAL PUBLIC SCHOOL",
  "B.G.S. WORLD SCHOOL",
  "B.J.B. ENGLISH MEDIUM SCHOOL",
  "B.J.B. JUNIOR COLLEGE",
  "B.J.M. CARMEL ACADEMY",
  "B.J.S. PUBLIC SCHOOL",
  "B.K. BAJORIA SCHOOL",
  "B.L. CENTRAL PUBLIC SCHOOL",
  "B.L. CENTRAL PUBLIC SR. SEC. SCHOOL",
  "B.L. INDO ANGLIAN PUBLIC SCHOOL",
  "B.M. HIGH SCHOOL",
  "B.N. PUBLIC SCHOOL",
  "B.N. SAHA D.A.V. PUBLIC SCHOOL",
  "B.P. AGRAWAL HIGH SCHOOL",
  "B.P. INDIAN PUBLIC SCHOOL",
  "B.R. BIRLA PUBLIC SCHOOL",
  "B.R. BIRLA PUBLIC SEN. SEC. SCHOOL",
  "B.R. PATEL PRIMARY SCHOOL",
  "B.R. SENIOR SECONDARY SIKSHAN SANSTHAN",
  "B.R.B. MODEL SCHOOL",
  "B.R.C.M. PUBLIC SCHOOL",
  "B.R.J.D. PUBLIC SCHOOL",
  "B.R.J.P. PARDIWALA ENGLISH MEDIUM SCHOOL",
  "B.R.M.V. GIRLS HIGH SCHOOL",
  "B.R.S. PUBLIC SCHOOL",
  "B.S.F. RESIDENTIAL SCHOOL",
  "B.S.F. SENIOR SECONDARY SCHOOL",
  "B.S.K. CHOURIDIYA SENIOR SECONDARY SCHOOL",
  "B.S.P. SENIOR SECONDARY SCHOOL",
  "B.S.S. PUBLIC SCHOOL",
  "B.S.T. SENIOR SECONDARY SCHOOL",
  "B.V. BHAVAN'S RESIDENTIAL PUBLIC SCHOOL",
  "B.V. REDDY SR. SEC. SCHOOL",
  "B.V.B.S. RAJASHREE VIDYA MANDIR",
  "B.V.BHAVAN'S LLOYDS VIDYA NIKETAN",
  "B.V.D. HIGH SCHOOL",
  "B.V.M. HIGH SCHOOL",
  "B.V.S. CONVENT PUBLIC SCHOOL",
  "B.V.V. SANGMA'S BASAVESHWAR",
  "B.Y.K. RESIDENTIAL HIGH SCHOOL",
  "BAANKURA PUBLIC SCHOOL",
  "BABA APPO AAP GURU NANAK PUBLIC SCHOOL",
  "BABA BANDA BAHADUR PUBLIC SCHOOL",
  "BABA BIRAM DASS D.A.V. PUBLIC SCHOOL",
  "BABA FARID PUBLIC SCHOOL",
  "BABA FARID SENIOR SECONDARY SCHOOL",
  "BABA GANDHA SINGH PUBLIC SCHOOL",
  "BABA INTERNATIONAL PLAY SCHOOL",
  "BABA ISHAR SINGH PUBLIC SCHOOL",
  "BABA KADERA SINGH VIDYA MANDIR",
  "BABA KARTAR SINGH PUBLIC SCHOOL",
  "BABA LALWANI PUBLIC SCHOOL",
  "BABA MANJH CONVENT SCHOOL",
  "BABA RAM DAS VIDYAPEETH",
  "BABARPURA MODEL PUBLIC SCHOOL",
  "BABU BODH RAJ CONVENT SCHOOL",
  "BABU BRISH BHAN DAV PUBLIC SCHOOL",
  "BABY DOLL PLAY SCHOOL",
  "BABY MARTIN INTERNATIONAL SCHOOL",
  "BABY MARTIN PUBLIC SCHOOL",
  "BABY MODEL SCHOOL",
  "BABYLAND KINDERGARTEN",
  "BACHPAN PLAY SCHOOL",
  "BACHPAN S.S. DAV PUBLIC SCHOOL",
  "BACHPAN SCHOOL",
  "BACHPAN",
  "BADAMBADI HIGH SCHOOL",
  "BADEN POWELL PUBLIC SCHOOL",
  "BADRI PRASAD PUBLIC SCHOOL",
  "BAHADUR CHAND MUNJAL ARYA MODEL SCHOOL",
  "BAHADUR THUTAPAKARI HIGH SCHOOL",
  "BAHA'I SECONDARY SCHOOL",
  "BAHAIS SCHOOL",
  "BAHERA MUNGA SCHOOL",
  "BAJAJ PUBLIC SCHOOL",
  "BAKSHI PUBLIC SCHOOL",
  "BAKSHI'S SPRINGDALES SEC SCHOOL",
  "BAL BARI PUBLIC SCHOOL",
  "BAL BHARATHI SCHOOL",
  "BAL BHARATI PUBLIC SCHOOL",
  "BAL BHARTI JAIN PUBLIC SCHOOL",
  "BAL BHARTI MODEL PUBLIC SCHOOL",
  "BAL BHARTI PUBLIC SCHOOL",
  "BAL BHARTI SCHOOL",
  "BAL BHAVAN INTERNATIONAL SCHOOL",
  "BAL CONVENT PUBLIC SCHOOL",
  "BAL JYOTI PUBLIC SCHOOL",
  "BAL KIRAN MIDDLE SCHOOL",
  "BAL MANDIR MODEL SCHOOL",
  "BAL MANDIR SENIOR SECONDARY SCHOOL",
  "BAL NIKETAN GANDHI SEVA SADAN",
  "BAL PRIYA EDUCATIONAL SOCHOOL",
  "BAL RASHMI SCHOOL",
  "BAL SAINIK PUBLIC SCHOOL",
  "BAL SHIKHA NIKETAN",
  "BAL VIDYA BHAVAN PUBLIC SCHOOL",
  "BAL VIDYA MANDIR",
  "BAL VIDYA NIKETAN SCHOOL",
  "BAL VIDYALAYA MADHYAMIK SCHOOL",
  "BAL VIKAS SCHOOL",
  "BAL VINAY HIGHER SECONDARY SCHOOL",
  "BAL VISHWA BHARTI SENIOR SECONDARY SCHOOL",
  "BALA BHAVAN MATRICULATION SCHOOL",
  "BALAJI CONVENT SCHOOL",
  "BALALOK MATRICULATION HR. SEC. SCHOOL",
  "BALANGIR PUB SCHOOL",
  "BALAVIKAS E.M. HIGH SCHOOL",
  "BALCO TOWNSHIP H.S. SCHOOL",
  "BALDWIN ACADEMY",
  "BALDWIN BOYS HIGH SCHOOL",
  "BALDWIN BOYS' HIGH SCHOOL",
  "BALDWIN CO-EDUCATION EXTENSION HIGH SCHOOL",
  "BALDWIN GIRLS' HIGH SCHOOL",
  "BALIANTA HIGH SCHOOL",
  "BALIBAREI HIGH SCHOOL",
  "BALIKA VIDYA PEETH",
  "BALIKA VIDYALA",
  "BALINGA HIGH SCHOOL",
  "BALLABH VIDYA PEETH HIGHER SECONDARY SCHOOL",
  "BALLIPADA HIGH SCHOOL",
  "BALLY NISCHINDA CHITTARANJAN VIDYALAYA",
  "BALLY SANTIRAM VIDYALAYA",
  "BALMITRA PUBLIC SCHOOL",
  "BALMUKUND RAMCHANDRA SARASWATI VIDYA MANDIR",
  "BALODYANA ENGLISH SCHOOL",
  "BALURGHAT HIGH SCHOOL",
  "BAN NABAGRAM HIGH SCHOOL",
  "BANAGRAM HIGH SCHOOL",
  "BANAMALIPUR JUNIOR COLLEGE",
  "BANASHTALI VIDYAPITH",
  "BANASTHALI PUBLIC SCHOOL",
  "BANASTHALI VIDHYAPITH",
  "BANGALORE INTERNATIONAL PUBLIC SCHOOL",
  "BANGALORE INTERNATIONAL SCHOOL",
  "BANGALORE MILITARY SCHOOL",
  "BANGALORE PUBLIC SCHOOL",
  "BANKAURA GIRLS' HIGH SCHOOL",
  "BANKURA CHRISTIAN COLLEGIATE SCHOOL",
  "BANKURA ZILLA SCHOOL",
  "BANTRA B.B.P.C. GIRLS' HIGH SCHOOL",
  "BANTRA M.S.P.C. HIGH SCHOOL",
  "BANWARI LAL MEMORIAL GURUKUL SCHOOL",
  "BAPATLA PUBLIC SCHOOL",
  "BAPPUJI CENTRAL SCHOOL",
  "BAPPUJI ENGLISH MEDIUM SCHOOL",
  "BAPTIST GIRLS SCHOOL",
  "BAPTIST SENIOR SECONDARY SCHOOL",
  "BAPUJI HIGH SCHOOL",
  "BARASAT INDIRA GANDHI MEMORIAL",
  "BARASAT KALI KRISHNA GIRLS HIGH SCHOOL",
  "BARAUNI REFINERY D.A.V PUBLIC SCHOOL",
  "BARBIE PLAY SCHOOL",
  "BARING COLLEGIATE SENIOR SECONDARY SCHOOL",
  "BARING SCHOOL",
  "BARISHA GIRLS HIGH SCHOOL",
  "BARISHA HIGH SCHOOL",
  "BARNES SCHOOL",
  "BARPATHAR HIGHER SECONDARY SCHOOL",
  "BARRACKPORE DEVI PRASAD HIGH SCHOOL",
  "BARRACKPUR CANTONMENT GIRLS HIGH SCHOOL",
  "BARROWS BLUE BELLS SCHOOL",
  "BARUIPUR GIRLS HIGH SCHOOL",
  "BASANT VALLEY PUBLIC SCHOOL",
  "BASAVA INTERNATIONAL SCHOOL",
  "BASAVA RESIDENTIAL GIRLS SCHOOL",
  "BASAVANANDA HIGH SCHOOL",
  "BASAVARAJESWARI PUBLIC SCHOOL & COLLEGE",
  "BASAVESHWAR HIGH SCHOOL",
  "BASEL MISSION HIGHER SECONDARY SCHOOL",
  "BASELIOS AUGEN PUBLIC SCHOOL",
  "BASHYAM PUBLIC SCHOOL",
  "BASIC ENGLISH SENIOR SECONDARY SCHOOL",
  "BASIL MEMORIAL PRIMARY SCHOOL",
  "BATCHU ENGLISH MEDIUM UPPER PRIMARY SCHOOL",
  "BATHESDA CHRISTIAN ACADEMY",
  "BAWA LALVANI PUBLIC SCHOOL",
  "BEACON ENGLISH MEDIUM H. S. SCHOOL",
  "BEACON ENGLISH SCHOOL",
  "BEACON HIGH SCHOOL",
  "BEANT VIDYA BHAWAN",
  "BEARYS PUBLIC SCHOOL",
  "BEE LINE PUBLIC SCHOOL",
  "BEERSHEBA PUBLIC SCHOOL",
  "BEERSHEBA SCHOOL",
  "BEGUNKODAR HIGH SCHOOL",
  "BELAR DHANYAKURIA BALIKA VIDYALAYA",
  "BELBAUGH SCHOOL",
  "BELFIELD MATRIC HIGHER SECONDARY SCHOOL",
  "BELGAUM MILITARY SCHOOL",
  "BELIEVERS CHURCH RESIDENTIAL SCHOOL",
  "BELL MATRICULATION HIGHER SECONDARY SCHOOL",
  "BELL TOLL PUBLIC HIGH SCHOOL",
  "BELL TOLL PUBLIC SCHOOL",
  "BELPAHAR ENGLISH MEDIUM SCHOOL",
  "BELPHAR ENGLISH MED SCHOOL",
  "BEML PUBLIC SCHOOL",
  "BEN HUR PUBLIC SCHOOL",
  "BENGALI HIGH SCHOOL",
  "BENGALI UCHHTAR MADHYAMIK SCHOOL",
  "BENGARDEN SCHOOL",
  "BENIS PUBLIC SCHOOL",
  "BENTICK GIRLS HIGHER SECONDARY SCHOOL",
  "BESANT THEOSOPHICAL HIGHER SECONDARY SCHOOL",
  "BESCHI ENGLISH MEDIUM PRIMARY SCHOOL",
  "BEST HIGHER SECONDARY SCHOOL",
  "BETHANY ACADEMY SCHOOL",
  "BETHANY ACADEMY",
  "BETHANY CONVENT HIGH SCHOOL",
  "BETHANY CONVENT SCHOOL",
  "BETHANY HIGH SCHOOL",
  "BETHANY PRIMARY SCHOOL",
  "BETHANY SCHOOL",
  "BETHEL ENGLISH MEDIUM SCHOOL",
  "BETHLEHEM GIRLS HIGH SCHOOL",
  "BETHSADA PUB.SCHOOL",
  "BEULA MATRICULATION HIGHER SECONDARY SCHOOL",
  "BHABHA PUBLIC SECONDARY SCHOOL",
  "BHABHA SENIOR SECONDARY SCHOOL",
  "BHADRACHALAM PUBLIC SCHOOL AND JR. COLLEGE",
  "BHADRAKALI HIGH SCHOOL",
  "BHAGABAT DEV NODAL M.E. SCHOOL",
  "BHAGABATI BIDYAPITHA",
  "BHAGAT ASSAN AND PUBLIC MIDDLE SCHOOL",
  "BHAGIRATH PRIMARY SCHOOL",
  "BHAGIRATHI DASS DAV PUBLIC SCHOOL",
  "BHAGIRATHI VIDYALAYA",
  "BHAGOWANPUR HIGH SCHOOL",
  "BHAGWAN MAHAVEER DAYANIKETAN JAIN SCHOOL",
  "BHAGWAN PARSHURAM PUBLIC SCHOOL",
  "BHAI DAN SINGH PUBLIC SR. SEC. SCHOOL",
  "BHAI JOGA SINGH GIRLS PUBLIC SCHOOL",
  "BHAI JOGA SINGH PUBLIC SCHOOL",
  "BHAI MASTAN SINGH PUBLIC SCHOOL",
  "BHAKT ENGLISH MEDIUM SCHOOL",
  "BHAKTIVEDANTA GURUKULA AND INTERNATIONAL SCHOOL",
  "BHANDARI PUBLIC SCHOOL",
  "BHANGAR HIGH SCHOOL",
  "BHANODAYA CONVENT SCHOOL",
  "BHANSALI VIDYA MANDIR PUBLIC SCHOOL",
  "BHANWARI DEVI JAIN MODERN SCHOOL",
  "BHARAD VISHWA VIDYAPEETH SCHOOL",
  "BHARAT KANYA UCHATAR MADHYAMIK VIDHYALAYA",
  "BHARAT MATA HIGHER SECONDARY SCHOOL",
  "BHARAT NATIONAL PUBLIC SCHOOL",
  "BHARAT PUBLIC SCHOOL",
  "BHARATEEYA VIDYA KENDRAM",
  "BHARATESH ENGLISH MEDIUM SCHOOL",
  "BHARATH INTERNATIONAL PUBLIC SCHOOL",
  "BHARATH MONTESSORI MATRIC HR. SEC. SCHOOL",
  "BHARATH SENIOR SECONDARY SCHOOL",
  "BHARATH VIDHYALAYA MATRIC SCHOOL",
  "BHARATHAMATHA HIGHER SECONDARY SCHOOL",
  "BHARATHI GRAMMAR SCHOOL",
  "BHARATHI HIGH SCHOOL",
  "BHARATHI HIGHER PRIMARY SCHOOL",
  "BHARATHI KALVI NILAYAM HIGHER SECONDARY SCHOOL",
  "BHARATHI MATRIC HIGHER SECONDARY SCHOOL",
  "BHARATHI MATRICULATION HIGHER SECONDARY SCHOOL",
  "BHARATHI SCHOOL",
  "BHARATHI VIDYALAYA SCHOOL",
  "BHARATHIDASAN MATRICULATION HIGHER SECONDARY SCHOOL",
  "BHARATHIYA PUBLIC SCHOOL",
  "BHARATI UCHCHANGAL VIDYALAYA",
  "BHARATI VIDYAPEETH GOD’S VALLEY INTERNATIONAL SCHOOL",
  "BHARATI VIDYAPEETH PRE PRIMARY SCHOOL ENGLISH MEDIUM",
  "BHARATIYA HINDU HIGH SCHOOL",
  "BHARATIYA SHIKSHA VIKASH SANSTHAN",
  "BHARATIYA VIDYA BHAVAN MATRICULATION HIGHER SECONDARY SCHOOL",
  "BHARATIYA VIDYA BHAVAN SCHOOL",
  "BHARATIYA VIDYA BHAVAN SENIOR SECONDARY SCHOOL",
  "BHARATIYA VIDYA BHAVAN",
  "BHARATIYA VIDYA BHAVAN'S PUBLIC SCHOOL",
  "BHARATIYA VIDYA BHAVAN'S RESIDENTIAL PUBLIC SCHOOL",
  "BHARATIYA VIDYA BHAVAN'S V.M. PUBLIC SCHOOL",
  "BHARATIYA VIDYA BHAVAN'S VIDYASHRAM",
  "BHARATIYA VIDYA BHAWAN ENG MEDIUM SCHOOL",
  "BHARATIYA VIDYA BHAWAN S L PUBLIC SCHOOL",
  "BHARATIYA VIDYA BHAWANS RAJASHREE VIDYA MANDIR",
  "BHARATIYA VIDYA VIKAS PUBLIC SCHOOL",
  "BHARATMATHA ENG. MEDIUM SCHOOL",
  "BHARTI ASHRAM PRIMARY SACHOOL",
  "BHARTI PUBLIC SCHOOL",
  "BHARTI SHISHU MANDIR SCHOOL",
  "BHARTI VIDYA MANDIR H.S. SCHOOL",
  "BHARTIYA BAL VIDYA MANDIR",
  "BHARTIYA MAHAVIDYALAYA",
  "BHARTIYA PUBLIC SCHOOL",
  "BHARTIYA SANSKRATI SHIKSHAN SANSTHAN",
  "BHARTIYA SANSKRITI HIGHER SECONDARY SCHOOL",
  "BHARTIYA VIDHYA MANDIR MATRICULATION HIGHER SECONDARY SCHOOL",
  "BHARTIYA VIDYA BHAVAN'S VIDYASHRAM PUBLIC SCHOOL",
  "BHARTIYA VIDYA BHAWAN BIRLA WHITE VIDYA MANDIR",
  "BHARTIYA VIDYA BHAWAN",
  "BHARTIYA VIDYA MANDIR",
  "BHARTIYA VIDYAPEETH BAL BHARTI",
  "BHASHYAM BLOOMS",
  "BHASHYAM EDUCATHON INSTITUTIONS SCHOOL",
  "BHASHYAM EDUCATIIONA INSTITUTIONS SCHOOL",
  "BHASHYAM PUBLIC SCHOOL",
  "BHATNAGAR INTERNATIONAL FOUNDATION SCHOOL",
  "BHAVAN’S SAWAN PUBLIC SCHOOL",
  "BHAVANI VIDYA NIKETAM HINDI HIGH SCHOOL",
  "BHAVAN'S CIPL PUBLIC SCHOOL",
  "BHAVAN'S GANDHI VIDYASHRAM",
  "BHAVAN'S K D KANORIA VIDYA MANDIR",
  "BHAVANS PUBLIC SCHOOL",
  "BHAVAN'S PUBLIC SCHOOL",
  "BHAVAN'S S.V. VIDYALAYA",
  "BHAVAN'S SHRI A.K. DOSHI VIDYALAYA",
  "BHAVAN'S VIDYASHRAM",
  "BHAVYA PUBLIC SCHOOL",
  "BHAWANI SHIKSHA NIKETAN SENIOR SECONDARY SCHOOL",
  "BHEGA'S HIGH SCHOOL",
  "BHEL SHIKSHA NIKETAN",
  "BHITAN HIGH SCHOOL",
  "BHIWANI PUBLIC SCHOOL",
  "BHOGAVATI HIGH SCHOOL",
  "BHOLAGURI KAMALA MIRI HIGHER SECONDARY SCHOOL",
  "BHOLANANDA NATIONAL VIDYALAYA",
  "BHONSALA MILITARY SCHOOL",
  "BHOORI BAI BAL NIKETAN PRIMARY SCHOOL",
  "BHOPAL NOBLE'S PUBLIC SCHOOL",
  "BHOPAL PUBLIC SCHOOL",
  "BHORURAM JIRAM DAS PUBLIC SCHOOL",
  "BHORURAM JIRAM DASS PUBLIC SCHOOL",
  "BHRIGOO PUBLIC SCHOOL",
  "BHUBANESHWAR MODEL SCHOOL",
  "BHUBANESHWAR PUBLIC SCHOOL STATION SQUARE",
  "BHUBANESWAR MODEL PUBLIC SCHOOL",
  "BHUJARIPARA M.C. HIGH SCHOOL",
  "BHULANPUR HIGH SCHOOL",
  "BHUPAL NOBLES PUBLIC SCHOOL",
  "BHUPINDRA INTERNATIONAL PUBLIC SCHOOL",
  "BHUVANAGIRI PUBLIC SCHOOL",
  "BHUVANAGIRI PUBLIC SCHOOL,",
  "BIDHANNAGAR MUNICIPAL SCHOOL",
  "BIDYA BHAVAN",
  "BIDYA EVENING INTER COLLEGE",
  "BIJAYA LAMI SARAN PUR HIGH SCHOOL",
  "BIJENDRA PUBLIC SCHOOL",
  "BIKANER BOYS' SCHOOL",
  "BIKANER PUBLIC SCHOOL",
  "BIKRAMPUR HIGHER SECONDARY SCHOOL",
  "BILL MEMORIAL ENGLISH MEDIUM SCHOOL",
  "BILLABONG HIGH INTERNATIONAL SCHOOL",
  "BILLAGRAMM HIGH SCHOOL",
  "BILLIMORIA HIGH SCHOOL",
  "BILLS PUBLIC SCHOOL",
  "BINWA SR. SEC. PUBLIC SCHOOL",
  "BIRLA PUBLIC SCHOOL VIDYA NIKETAN",
  "BIRLA PUBLIC SCHOOL",
  "BIRLA SAGAR HIGHER SECONDARY SCHOOL",
  "BIRLA VIDYAMANDIR",
  "BISHOP BERETTA HIGH SCHOOL",
  "BISHOP CONRAD SCHOOL",
  "BISHOP CONRAD SENIOR SECONDARY SCHOOL",
  "BISHOP COTTON BOYS' SCHOOL",
  "BISHOP COTTON GIRLS' SCHOOL",
  "BISHOP COTTON PUBLIC SCHOOL",
  "BISHOP COTTON SCHOOL",
  "BISHOP HARTMANN ACADEMY",
  "BISHOP M.M. C.S.P.M. HIGH SCHOOL",
  "BISHOP MOORE VIDYAPITH",
  "BISHOP MORROW SCHOOL",
  "BISHOP SARGANT SCHOOL",
  "BISHOP SPEECHLY VIDYAPEETH",
  "BISHOP WESTCOTT ' SCHOOL",
  "BISHOP WESTCOTT BOYS' SCHOOL",
  "BISHOP WESTCOTT SCHOOL",
  "BISHOP WILLIAM MEMORIAL SCHOOL",
  "BISHOP'S SCHOOL",
  "BITS PUBLIC SCHOOL",
  "BIYANI HIGH SCHOOL",
  "BKS VIDYA BHAVAN SCHOOL",
  "BLACK DIAMOND PUBLIC SCHOOL",
  "BLACKDALE PUBLIC SCHOOL",
  "BLAIR INTERNATIONAL SCHOOL",
  "BLESSED MOTHER TERESA MODEL HIGHER SECONDARY SCHOOL",
  "BLESSED SACRAMENT HIGH SCHOOL",
  "BLESSINGS RESIDENTIAL MATRIC. HIGHER SECONDARY SCHOOL",
  "BLIND BOYS INSTITUTE",
  "BLIND RELIEF ASSOCIATIONS MUNDLE ENGLISH MEDIUM SCHOOL",
  "BLOOM ERA PUBLIC SCHOOL",
  "BLOOMING BUDS BETHANIA ENGLISH SCHOOL",
  "BLOOMING BUDS CO. ED. SCHOOL",
  "BLOOMING BUDS PUBLIC SCHOOL",
  "BLOOMING BUDS SCHOOL",
  "BLOOMING CENTRAL ACADEMY SCHOOL",
  "BLOOMING DAIL HIGH SCHOOL",
  "BLOOMING DALE SCHOOL",
  "BLOOMING DALES INTERNATIONAL SCHOOL",
  "BLOOMING DALE'S' MODERN SCHOOL",
  "BLOOMING DALES SCHOOL",
  "BLOOMING VALE PUB SCHOOL",
  "BLOSSOM CONVENT SCHOOL",
  "BLOSSOM INTERNATIONAL RESIDENTIAL SCHOOL",
  "BLOSSOM PUBLIC SCHOOL",
  "BLOSSOM SENIOR SECONARY SCHOOL",
  "BLOSSOMS CONVENT SCHOOL",
  "BLOSSOMS SCHOOL",
  "BLOSSOMS SENIOR SECONDARY SCHOOL",
  "BLUE BELL KINDER GARTEN",
  "BLUE BELL PUBLIC SCHOOL",
  "BLUE BELLS MODEL SENIOR SECONDARY SCHOOL",
  "BLUE BELLS PUBLIC SCHOOL",
  "BLUE BELLS SENIOR SECONDARY MODEL SCHOOL",
  "BLUE BIRD HIGHER SECONDARY SCHOOL",
  "BLUE BUDS TECHNO SCHOOL",
  "BLUE DIAMOND PUBLIC SCHOOL",
  "BLUE DIAMOND SCHOOL",
  "BLUE FLOWER PUBLIC HIGH SCHOOL",
  "BLUE HEAVEN SECONDARY SCHOOL",
  "BLUE MOUNT PUBLIC SCHOOL",
  "BLUE MOUNTAIN SCHOOL",
  "BLUE PINE RESIDENTIAL ENGLISH SCHOOL",
  "BLUE STAR PUBLIC SCHOOL",
  "BN. ENGLISH MEDIUM SCHOOL",
  "BOARD OF HIGH SCHOOL",
  "BOARD OF SCHOOL EDUCATION HARYANA",
  "BOBBILE SUNANDA RANI RUSHI SCHOOL",
  "BOBSON PUBLIC SCHOOL",
  "BODHI PUBLIC SCHOOL",
  "BODHICARIYA SENIOR SECONDARY SCHOOL",
  "BODHIRUKSH PRE PRIMARY SCHOOL",
  "BOHRA PUBLIC SCHOOL",
  "BOHRA YOUTH PUBLIC SCHOOL",
  "BOILER PLANT MIDDLE SCHOOL",
  "BOKARO PUBLIC SCHOOL",
  "BOLPUR NICHUPOTI NIRAD BARAN HIGH SCHOOL",
  "BOLTONS PUBLIC SCHOOL",
  "BOMBAY INTERNATIONAL SCHOOL",
  "BOMBAY SCOTTISH SCHOOL",
  "BONGAIGAON HIGH SCHOOL",
  "BONGAIGAON RAILWAY HIGHER SECONDARY SCHOOL",
  "BONGAON HIGH SCHOOL",
  "BOON PUBLIC SCHOOL",
  "BOOPATHI PUBLIC SCHOOL",
  "BOUM KAKIR MISSION SCHOOL",
  "BOYS' HIGH SCHOOL & COLLEGE",
  "BOYS' HIGH SCHOOL",
  "BOYS TOWN PUBLIC SCHOOL",
  "BR DAV PUBLIC SCHOOL",
  "BR PUBLIC SCHOOL",
  "BRAHAM RISHI BAWRA HIGH SECONDARY SCHOOL",
  "BRAHAMANAND PUBLIC SCHOOL",
  "BRAHAMLEEN SWAMI AMAR DEVJI MEMORIAL",
  "BRAHAMPURI PUBLIC SENIOR SECONDARY SCHOOL",
  "BRAHMANAND PUBLIC SCHOOL",
  "BRAHMANANDA SWAMI SIVAYOGI HIGH SCHOOL",
  "BRAHMAPUTRA VALLEY ACADEMY",
  "BRAHMESWAR HIGH SCHOOL",
  "BRAHMPURI PUBLIC SCHOOL",
  "BRAHMRISHI MISSION SCHOOL",
  "BRAIN INTERNATIONAL SCHOOL",
  "BRAINTREE GLOBAL SCHOOL",
  "BRAINWORKS PLAYSCHOOL",
  "BRAL KINDERGARTEN SCHOOL",
  "BREEKS ALL INDIA SECONDARY SCHOOL",
  "BRIDGE INTERNATIONAL SCHOOL",
  "BRIGADIER RAM SINGH PUBLIC SCHOOL",
  "BRIGHT BEGINNING SCHOOL",
  "BRIGHT CHILDREN ACADEMY",
  "BRIGHT CONVENT HIGH SCHOOL",
  "BRIGHT HIGHER SECONDARY SCHOOL",
  "BRIGHT HOME PUBLIC SCHOOL",
  "BRIGHT INSTITUTE OF OPEN SCHOOL",
  "BRIGHT KIDS INTERNATIONAL PRE SCHOOL",
  "BRIGHT LAND PUBLIC SCHOOL",
  "BRIGHT LAND SR. SEC. SCHOOL AND BRIGHT LAND PRIMARY SCHOOL",
  "BRIGHT MOON SHIKSHA SAMITI",
  "BRIGHT STAR ACADEMY",
  "BRIGHT START FELLOWSHIP INTERNATIONAL SCHOOL",
  "BRIGHT SUN SCHOOL",
  "BRIGHT WAY PUBLIC SCHOOL",
  "BRIGHTER ACADEMY",
  "BRIGHTLAND GIRLS SENIOR SECONDARY SCHOOL",
  "BRIGHT'S GROVE PUBLIC SCHOOL",
  "BRIGHTWAYS SENIOR SECONDARY SCHOOL",
  "BRIJ BHUSHAN LAL PUBLIC SCHOOL",
  "BRIJWASI SCHOLARS' CAMBRIDGE",
  "BRILLIANT HIGHER SECONDARY SCHOOL",
  "BRILLIANT PUBLIC SCHOOL",
  "BRINDAVAN PUBLIC SCHOOL",
  "BRIO INTERNATIONAL SCHOOL",
  "BRITISH CONVENT SCHOOL",
  "BRITISH INTERNATIONAL SCHOOL",
  "BRITISH OLIVIA SCHOOL",
  "BROADWAY PUBLIC SCHOOL",
  "BROOK INTERNATIONAL SCHOOL",
  "BROOKLYN BIRDIES SCHOOL",
  "BROOKLYN PLAY SCHOOL",
  "BROOKSIDE ADVENTIST SECONDARY SCHOOL",
  "BROWNWOOD PUBLIC SCHOOL",
  "BRSK INTERNATIONAL PUBLIC SCHOOL",
  "BSF SENIOR SECONDARY RESIDENTIAL SCHOOL",
  "BSM PUBLIC INTER COLLEGE",
  "BSN HIGH SCHOOL",
  "BSP SR. SECONDARY SCHOOL",
  "BSR ENGLISH MEDIUM SCHOOL",
  "BUDDHA PUBLIC SCHOOL",
  "BUDDING BUDS SCHOOL",
  "BUDGE BUDGE ST. PAUL'S DAY SCHOOL",
  "BUDHA DAL PUBLIC SCHOOL",
  "BUDS & FLOWERS HIGH SCHOOL",
  "BUDS AND BLOSSOMS SCHOOL",
  "BUDS SENIOR SCHOOL",
  "BUNDELKHAND INTER COLLEGE",
  "BUNDELKHAND PUBLIC SCHOOL",
  "BURDWAN HOLY CHILD SCHOOL",
  "BURDWAN MUNICIPAL HIGH SCHOOL",
  "BURGESS ENGLISH HIGHER SECONDARY SCHOOL",
  "BURHANI ENGLISH MEDIUM SCHOOL",
  "BUTTERFLY SCHOOL",
  "BUXI JAGABANDHU ENGLISH MEDIUM SCHOOL",
  "BVB VIKRAM ISPAT VIDYA MANDIR",
  "C SYED MOHAMMED HAJI MEM. CENTRAL SCHOOL",
  "C. K. C. GIRLS HIGH SCHOOL",
  "C.A.L. PUBLIC SCHOOL",
  "C.B.M. HIGH SCHOOL",
  "C.C.A SCHOOL",
  "C.F.C PUBLIC SCHOOL",
  "C.F.C. PUBLIC SCHOOL",
  "C.G.R. INTERNATIONAL SCHOOL",
  "C.H. MOHAMMED KOYA MEMORIAL HIGHER SECONDARY SCHOOL",
  "C.J.S PUBLIC SCHOOL",
  "C.L. GUPTA WORLD SCHOOL",
  "C.L. SACHDEVA DAV CENTENARY PUBLIC SCHOOL",
  "C.L.H SR. SEC. SCHOOL",
  "C.L.M. PUBLIC SCHOOL",
  "C.M.I. PUBLIC SCHOOL",
  "C.M.R. NATIONAL PUBLIC SCHOOL",
  "C.M.S. GIRLS SEN. SEC. SCHOOL",
  "C.N. MEHTA GIRLS HIGH SCHOOL",
  "C.N.K. PUBLIC SCHOOL",
  "C.N.S.T. NURSERY AND SCHOOL",
  "C.P. BROWNS TALENT SCHOOL",
  "C.P. VIDYA NIKETAN",
  "C.R. CENTRAL SENIOR SECONDARY SCHOOL",
  "C.R. PUBLIC SCHOOL",
  "C.R.P.F PUBLIC SCHOOL",
  "C.R.P.F. SCHOOL",
  "C.S. INTERNATIONAL SCHOOL",
  "C.S.H.P MEMORIAL PUBLIC SCHOOL",
  "C.S.I. GIRLS HIGH SCHOOL",
  "C.S.I. GOOD SHEPHERD",
  "C.S.I. MATRICULATION HIGHER SECONDARY SCHOOL",
  "C.S.I. MONAHAN GIRLS HIGHER SECONDARY SCHOOL",
  "C.S.I. RAJA GOPAL HIGH SCHOOL",
  "C.T PUBLIC SCHOOL",
  "C.U. SHAH ENGLISH SCHOOL",
  "C.U. SHAH PREPARATORY SCHOOL",
  "CAESAR SCHOOL",
  "CALCUTTA PUBLIC SCHOOL",
  "CALIBERS ENGLISH MEDIUM SCHOOL",
  "CALICUT INTERNATIONAL SCHOOL",
  "CALIFORNIA PUBLIC SCHOOL",
  "CAMBRIAN HALL",
  "CAMBRIAN PUBLIC SCHOOL",
  "CAMBRIDGE CONVENT SENIOR SECONDARY RESIDENTIAL SCHOOL",
  "CAMBRIDGE COURT HIGH SCHOOL",
  "CAMBRIDGE ENGLISH SCHOOL",
  "CAMBRIDGE HIGH SCHOOL",
  "CAMBRIDGE INTERNATIONAL SCHOOL",
  "CAMBRIDGE INTERNATIONAL",
  "CAMBRIDGE MATRIC HIGHER SECONDARY SCHOOL",
  "CAMBRIDGE PUBLIC SCHOOL",
  "CAMBRIDGE SCHOOL",
  "CAMBRIGE PUBLIC SCHOOL",
  "CAMPION ANGLO-INDIAN HIGHER SECONDARY SCHOOL",
  "CAMPION INTERNATIONAL SCHOOL",
  "CAMPION SCHOOL",
  "CAMPUS PUBLIC SCHOOL",
  "CANADIAN INTERNATIONAL SCHOOL",
  "CANAL VALLEY PUBLIC SCHOOL",
  "CANOSSA CONVENT HIGH SCHOOL",
  "CANTERBURY PUBLIC SCHOOL",
  "CANTONMENT BOARD HIGH SCHOOL",
  "CAPITAL HIGH SCHOOL",
  "CAPITAL PUBLIC MONTESSORI SCHOOL",
  "CAPITAL PUBLIC SCHOOL",
  "CAPITOL PUBLIC SCHOOL",
  "CARDINAL PADIYARA PUBLIC SCHOOL",
  "CARE AND CAREER SCHOOL",
  "CARE INTERNATIONAL SCHOOL",
  "CAREER ACADEMY",
  "CAREER BUILDING ACADEMY",
  "CAREER CONVENT SCHOOL",
  "CAREER PUBLIC SCHOOL",
  "CARMAL SCHOOL",
  "CARMEL BALIKA VIDYALAYA",
  "CARMEL CONVENT ENGLISH MEDIUM SCHOOL",
  "CARMEL CONVENT HIGH SCHOOL",
  "CARMEL CONVENT SCHOOL",
  "CARMEL CONVENT SENIOR SECONDARY SCHOOL",
  "CARMEL ENGLISH MEDIUM SCHOOL",
  "CARMEL ENGLISH SCHOOL",
  "CARMEL GARDEN PUBLIC SCHOOL",
  "CARMEL HIGH SCHOOL",
  "CARMEL JUNIOR COLLEGE",
  "CARMEL PRIMARY SCHOOL ENGLISH MEDIUM",
  "CARMEL PUB.SCHOOL",
  "CARMEL PUBLIC SCHOOL",
  "CARMEL SCHOOL",
  "CARMEL SEC SCHOOL",
  "CARMELAGIRI ENGLISH MEDIUM SCHOOL",
  "CARMELAGIRI PUBLIC SCHOOL",
  "CARMELGIRI ENGLISH MED. SCHOOL",
  "CARMELITE CONVENT ENGLISH HIGH SCHOOL",
  "CASTERBRIDGE SCHOOL",
  "CATHEDRAL & JOHN CONNON SCHOOL",
  "CATHOLIC SCHOOL",
  "CAUVERY PUBLIC SCHOOL",
  "CECIL CONVENT SCHOOL",
  "CEDAR SPRING HIGH SCHOOL",
  "CEEDEES MONTESSORI SCHOOL",
  "CEERI VIDYA MANDIR",
  "CELESTIAL HIGH SCHOOL",
  "CENTENARY PUBLIC SCHOOL",
  "CENTERAL PUBLIC SR. SEC. SCHOOL",
  "CENTRAL ACADEMY PUBLIC SCHOOL",
  "CENTRAL ACADEMY SCHOOL",
  "CENTRAL ACADEMY SENIOR SECONDARY SCHOOL",
  "CENTRAL ACADEMY",
  "CENTRAL AGRA PUBLIC SCHOOL",
  "CENTRAL CITY PUBLIC SCHOOL",
  "CENTRAL CONVENT SCHOOL",
  "CENTRAL HINDU GIRLS SENIOR SECONDARY SCHOOL",
  "CENTRAL INDIA PUBLIC SCHOOL",
  "CENTRAL MALWA ACADEMY",
  "CENTRAL MODEL HIGH SCHOOL",
  "CENTRAL PUBLIC SCHOOL",
  "CENTRAL PUBLIC SENIOR SECONDARY SCHOOL",
  "CENTRAL PUBLIC SR SEC SCHOOL",
  "CENTRAL SCHOOL FOR TIBETANS",
  "CENTRAL SCHOOL FOR TIBTANS",
  "CENTRAL SCHOOL",
  "CEOA SCHOOL",
  "CH CHARAN SINGH MEMORIAL PUBLIC SCHOOL",
  "CH CHHABIL DASS PUBLIC SCHOOL",
  "CH DEVI LAL MEMORIAL PUBLIC SCHOOL",
  "CH HARPAL SINGH CONVENT SCHOOL",
  "CH. JAGAT MOHAN MEMORIAL PUBLIC SCHOOL",
  "CH. MALOOK SINGH GOVERNMENT SARVODAYA BAL VIDYA",
  "CH. MOTA RAM MEEL MEMORIAL SR. SEC. PUBLIC SCHOOL",
  "CHAGRAM HIGH SCHOOL",
  "CHAITANYA CENTRAL SCHOOL",
  "CHAITANYA ENGLISH MEDIUM SCHOOL",
  "CHAITANYA GIRLS HIGH SCHOOL",
  "CHAITANYA PUBLIC SCHOOL & RESIDENTIAL HIGH SCHOOL",
  "CHAITANYA PUBLIC SCHOOL",
  "CHAITANYA VIDYALAYA",
  "CHAITHANYA NURSERY AND PRIMARY SCHOOL",
  "CHALAPATHI RESIDENTIAL SCHOOL",
  "CHALDEAN SYRIAN HIGHER SECONDARY SCHOOL",
  "CHAMAN LAL D.A.V. SENIOR PUBLIC SCHOOL",
  "CHAMAN VATIKA RESIDENTIAL PUBLIC SCHOOL",
  "CHAMAN VATIKA SCHOOL",
  "CHAMERA H E PROJECT SCHOOL",
  "CHAMRAJ HIGHER SECONDARY SCHOOL",
  "CHANAKYA ACADEMY",
  "CHANDA PUBLIC SCHOOL",
  "CHANDAMAMA PUBLIC SCHOOL",
  "CHANDANVAN PUBLIC SCHOOL",
  "CHANDER BALA MODI ACADEMY",
  "CHANDIGARH PUBLIC SCHOOL",
  "CHANDRA SHAIKHAR PUBLIC SCHOOL",
  "CHANDRABHAGA TUKOBA HIGH SCHOOL",
  "CHANDRAKANT PATIL ENGLISH MED. SCHOOL",
  "CHANDRAKANTHI PUBLIC SCHOOL",
  "CHANDRAMAULI VIDYALAYA",
  "CHANDY MATRICULATION SCHOOL",
  "CHANGMAI GAON L.P SCHOOL",
  "CHARKAL GRAM HIGH SCHOOL",
  "CHATURVEDI AJAY ARVIND MEMORIAL SCHOOL",
  "CHAVARA C.M.I. PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "CHAVARA HIGHER SECONDARY SCHOOL",
  "CHAVARA INTERNATIONAL SCHOOL",
  "CHENNAI PUBLIC SCHOOL",
  "CHERPALCHERI ENG. MED CENTRAL SCHOOL",
  "CHERRY FIELDS PRE SCHOOL",
  "CHERUBS ENGLISH MEDIUM PUBLIC SCHOOL",
  "CHERUPUSHPA BETHNI SCHOOL",
  "CHERUPUSHPA HIGH SCHOOL",
  "CHETANA HIGH SCHOOL",
  "CHETHANA SPECIAL SCHOOL",
  "CHETTINAD PUBLIC SCHOOL",
  "CHETTINAD VIDHYASHRAM",
  "CHHATRAPATI SHIVAJI PUBLIC SCHOOL",
  "CHHATRASAL PUBLIC SCHOOL",
  "CHHAYA PUBLIC SCHOOL",
  "CHHOTOBAINAN HIGH SCHOOL",
  "CHIDARTHA PUBLIC SCHOOL",
  "CHIEF KHALSA DIWAN PUBLIC SCHOOL",
  "CHILD HOME SHIKSHA SAMITI",
  "CHILD JESUS HR. SEC. SCHOOL",
  "CHILDREN COLLEGE",
  "CHILDREN CONWENT SCHOOL",
  "CHILDREN GARDEN PLAY SCHOOL",
  "CHILDREN HIGHER SECONDARY SCHOOL",
  "CHILDREN JOLLY PUBLIC SCHOOL",
  "CHILDREN MEMORIAL DAV PUBLIC SCHOOL",
  "CHILDREN MEMORIAL PUBLIC SCHOOL",
  "CHILDREN MEMORIAL SCHOOL",
  "CHILDREN SCHOOL",
  "CHILDREN SENIOR SECONDARY SCHOOL",
  "CHILDREN'S ACADEMY",
  "CHILDREN'S CENTRE OF INTEGRAL EDUCATION",
  "CHILDRENS CONVENT AND SCHOOL",
  "CHILDREN'S PUBLIC ACADEMY",
  "CHILKARANI ENGLISH MEDIUM SCHOOL",
  "CHIMES MONTESSORI HOUSE OF CHILDRAN",
  "CHINAR PUBLIC SCHOOL",
  "CHINAR SCHOOL",
  "CHINMAYA INTERNATIONAL RESIDENTIAL SCHOOL",
  "CHINMAYA INTERNATIONAL RESIDENTIAL SCHOOL, COIMBATORE",
  "CHINMAYA VIDHYALAYA MATRICULATION SCHOOL",
  "CHINMAYA VIDYALAYA (E.M.)",
  "CHINMAYA VIDYALAYA",
  "CHINMAYA VIDYALAYA,UNCHAHAR",
  "CHINMAYA VIDYALYA",
  "CHINPAI HIGH SCHOOL",
  "CHINTATHRIPET HIGHER SECONDARY SCHOOL",
  "CHIRANJEEVEE PUBLIC SCHOOL",
  "CHIREC PUBLIC SCHOOL",
  "CHIRST CHURCH BOY'S & GIRLS SENIOR SECONDARY SCHOOL",
  "CHITKARA INTERNATIONAL SCHOOL",
  "CHITRA CONVENT JUNIOR HIGH SCHOOL",
  "CHITRA INTER COLLEGE",
  "CHITRA PUBLIC SCHOOL",
  "CHITRAGUPT BALIKA VIDYALAYA",
  "CHITRAKOOT PUBLIC SCHOOL",
  "CHITRANSH PUBLIC SCHOOL",
  "CHITTARANJAN HIGH SCHOOL",
  "CHITTARANJAN MAHALI SAMITI GIRLS HIGH SCHOOL",
  "CHOITHRAM INTERNATIONAL SCHOOL",
  "CHOLAN MATRICULATION HIGHER SECONDARY SCHOOL",
  "CHOPASNI SENIOR SECONDARY SCHOOL",
  "CHOTHAVOOR HIGH SCHOOL",
  "CHOTTU RAM MEMORIAL PUBLIC SCHOOL",
  "CHOUDHARY BALBIR SINGH PUBLIC SCHOOL",
  "CHOUDHARY GHARSIRAM PUBLIC SCHOOL",
  "CHOW NANDA MEMORIAL SCHOOL",
  "CHRIST C.M.I. PUBLIC SCHOOL",
  "CHRIST CHURCH BOYS’ SENIOR SECONDARY SCHOOL",
  "CHRIST CHURCH COLLEGE",
  "CHRIST CHURCH HIGH SCHOOL",
  "CHRIST CHURCH SCHOOL FOR BOYS & GIRLS",
  "CHRIST CHURCH SCHOOL",
  "CHRIST CHURCH SENIOR SCHOOL FOR BOYS & GIRLS",
  "CHRIST INTERNATIONAL SCHOOL",
  "CHRIST JYOTHI SCHOOL",
  "CHRIST JYOTI CONVENT SCHOOL",
  "CHRIST KING CONVENT HIGH SCHOOL",
  "CHRIST KING CONVENT SCHOOL",
  "CHRIST NAGAR HIGHER SECONDARY SCHOOL",
  "CHRIST NAGAR INTERNATIONAL SCHOOL",
  "CHRIST NAGAR PUBLIC SCHOOL",
  "CHRIST P.U. COLLEGE",
  "CHRIST PUBLIC SCHOOL",
  "CHRIST RAJA CONVENT SCHOOL",
  "CHRIST SR. SEC. SCHOOL",
  "CHRIST THE KING COLLEGE",
  "CHRIST THE KING SCHOOL",
  "CHRIST VIDYANIKETHAN SCHOOL",
  "CHRISTIAN EMINENT HIGHER SECONDARY SCHOOL",
  "CHRISTIAN ENGLISH COLLEGE",
  "CHRISTIAN MISSION SCHOOL",
  "CHRISTOPHER PUBLIC SCHOOL",
  "CHRISTU JYOTHI CONVENT SCHOOL",
  "CHRISTU JYOTI CONVENT SCHOOL",
  "CHRISTUKULA MISSION HIGHER SECONDARY SCHOOL",
  "CHRISTVA MAHILALAYAM PUBLIC SCHOOL",
  "CHUBBY CHICKS (INCLUSIVE EDUCATION)",
  "CHUNI LAL SACHDEVA DAV CENTENARY PUBLIC SCHOOL",
  "CHUNNI LAL PUBLIC INTER COLLEGE",
  "CHURCH SCHOOL",
  "CITADEL RESIDENTIAL SCHOOL",
  "CITY CENTRE HIGH SCHOOL",
  "CITY ENGLISH SCHOOL",
  "CITY INTERNATIONAL SCHOOL",
  "CITY LIFE PUBLIC SCHOOL",
  "CITY MODERN PUBLIC SCHOOL",
  "CITY MONTESSORI HIGHER SECONDARY SCHOOL",
  "CITY MONTESSORI SCHOOL",
  "CITY PUBLIC SCHOOL",
  "CITY SHINE PUBLIC SCHOOL",
  "CITY TALENT SCHOOL",
  "CITY VOCATIONAL PUBIC SCHOOL",
  "CLARENCE HIGH SCHOOL",
  "CLARENCE PUBLIC SCHOOL",
  "CLAY INDIA INTERNATIONAL SENOR SECONDARY SCHOOL",
  "CLM PUBLIC SCHOOL",
  "CLUNY CONVENT HIGH SCHOOL",
  "COAST GUARD PUBLIC SCHOOL",
  "COCHIN PUBLIC SCHOOL",
  "CODESH SCHOOL - PANCHGANI",
  "CODESH SCHOOL",
  "COL. BROWN CAMBRIDGE SCHOOL",
  "COL. V.R. MOHAN D.A.V. PUBLIC SCHOOL",
  "COL.V.R.MOHAN D.A.V. PUBLIC SCHOOL",
  "COLONEL OXFORD SCHOOL",
  "COLONEL PUBLIC SCHOOL",
  "COLONEL SATSANGI'S KIRAN MEMORIAL PUBLIC SCHOOL",
  "COLONEL’S BRIGHTLAND PUBLIC SCHOOL",
  "COLONEL'S ACADEMY",
  "COLUMBIA CONVENT",
  "COLUMBUS PUBLIC SCHOOL",
  "COMET MENSA PUBLIC SCHOOL",
  "COMFORD PUBLIC SCHOOL",
  "COMMANDER TECTICAL WING SCHOOL",
  "COMORIN INTERNATIONAL SCHOOL",
  "COMPOSITE SPORTS RESIDENTIAL HIGH SCHOOL",
  "COMPUTER LABORATORIES BRNES SCHOOL",
  "CONTAI DISARI PUBLIC SCHOOL",
  "CONVENT OF JESUS & MARY HIGH SCHOOL",
  "CONVENT OF JESUS AND MARY HIGH SCHOOL",
  "CONVENT OF JESUS AND MARY",
  "COORG PUBLIC SCHOOL",
  "CORDOVA PUBLIC SCHOOL",
  "CORPORATION BOYS HIGHER SECONDARY SCHOOL",
  "CORPORATION GIRLS HIGHER SECONDARY SCHOOL",
  "CORPORATION HIGHER SECONDARY SCHOOL",
  "COSMOPOLITAN PUBLIC SCHOOL",
  "COSMOS CASTLE INTERNATIONAL SCHOOL",
  "COSMOS PUBLIC SCHOOL",
  "CRATE PUBLIC SCHOOL",
  "CREANE MEMORIAL HIGH SCHOOL",
  "CREATIVE PUBLIC HIGHER SECONDARY SCHOOL",
  "CRESCENT CASTLE HIGHER SECONDARY SCHOOL",
  "CRESCENT ENGLISH MEDIUM HIGH SCHOOL",
  "CRESCENT PUBLIC SCHOOL",
  "CRESCENT RESIDENTAL MATRICULATION SCHOOL",
  "CRONY PUBLIC SCHOOL",
  "CROSS LAND PUBLIC SCHOOL",
  "CROWN PUBLIC SCHOOL",
  "CRPF PUBLIC SCHOOL",
  "CRRI HIGH SCHOOL",
  "CRYSTAL PUBLIC SCHOOL",
  "CSI HIGH SCHOOL",
  "CUNCOLIM UNITED HIGH SCHOOL",
  "CYBERNETICS SECONDARY SCHOOL",
  "CYGNUS HIGH WORLD SCHOOL",
  "D A V ACC PUBLIC SCHOOL",
  "D A V AMBUJA VIDYA NIKETAN",
  "D A V CENTENARY PUBLIC SCHOOL",
  "D A V MODEL SR. SEC. SCHOOL",
  "D A V PUBLIC SCHOOL",
  "D A V PUBLIC SENIOR SECONDARY SCHOOL",
  "D COSTA MARIA ONESIAVILLA",
  "D ERING MEMORIAL GOVERNMENT SECONDARY SCHOOL",
  "D H LAWRENCE SENIOR SECONDARY SCHOOL",
  "D P M PUBLIC SCHOOL",
  "D P S MEWAT MODEL SCHOOL",
  "D. C. LEWIS MEMORIAL SCHOOL",
  "D.A.V CENTENARY PUBLIC SCHOOL",
  "D.A.V GIRLS' SENIOR SECONDARY SCHOOL",
  "D.A.V PUBLIC SCHOOL",
  "D.A.V PUBLIC SR. SEC. SCHOOL",
  "D.A.V. ALOK PUBLIC SCHOOL",
  "D.A.V. B.D.L. PUBLIC SCHOOL",
  "D.A.V. BHEL SCHOOL",
  "D.A.V. CENTENARY PUBLIC SCHOOL",
  "D.A.V. CO-EDUCATIONAL HIGH SCHOOL",
  "D.A.V. GIRLS SENIOR SECONDARY SCHOOL",
  "D.A.V. HIGH SCHOOL",
  "D.A.V. INTERNATIONAL SCHOOL",
  "D.A.V. ISPAT SENIOR SECONDARY PUBLIC SCHOOL",
  "D.A.V. MODEL HIGH SCHOOL",
  "D.A.V. MODEL SCHOOL",
  "D.A.V. PPUBLIC SCHOOL",
  "D.A.V. PUBILC SCHOOL",
  "D.A.V. PUBLIC CONVENT SCHOOL",
  "D.A.V. PUBLIC SCHOOL",
  "D.A.V. SENIOR PUBLIC SCHOOL",
  "D.A.V. SENIOR SECONDARY SCHOOL",
  "D.A.V. SR. SEC SCHOOL",
  "D.A.V.AMBUJA VIDYA NIKETAN",
  "D.A.V.EDWARD GANJ PUBLIC SCHOOL",
  "D.A.V.PUBLIC SCHOOL",
  "D.B.M.S. ENGLISH SCHOOL",
  "D.C. LEWIS MEMORIAL SCHOOL",
  "D.C. MODEL INTERNATIONAL SCHOOL",
  "D.C.M. PRESIDENCY SCHOOL",
  "D.C.M. PUBLIC SCHOOL",
  "D.C.O. SARVAJANIK HIGH SCHOOL",
  "D.E.S. ENGLISH MEDIUM SCHOOL",
  "D.G.R. PUBLIC SCHOOL",
  "D.H. BHARAT SEVASRAM SANGHA PRANAV VIDYAPITH",
  "D.I.P.S. SCHOOL",
  "D.J. DAMANIA BAL MANDIR SCHOOL",
  "D.L.F. PUBLIC SCHOOL",
  "D.N. EVEREST MODEL HIGH SCHOOL",
  "D.N. MODEL SENIOR SECONDARY SCHOOL",
  "D.P. VIDYAPEETH",
  "D.P.S. INTERNATIONAL",
  "D.R. INTERNATIONAL SCHOOL",
  "D.R.A HIGH SCHOOL",
  "D.R.I.E.M.S. SCIENCE COLLEGE",
  "D.R.K. ADARSH VIDYA MANDIR",
  "D.R.S.INTERNATIONAL SCHOOL",
  "D.S. PUBLIC SCHOOL",
  "D.S.B. INTERNATIONAL SCHOOL",
  "D.S.K. PUBLIC SCHOOL",
  "D.S.KOTHARI KANYA SCHOOL",
  "D.S.S.D. GIRLS HIGH SCHOOL",
  "D.V.M. PUBLIC SCHOOL",
  "D.Y. CHOUGULE BHARATESH HIGH SCHOOL",
  "D.Y. PATIL INTERNATIONAL SCHOOL",
  "D’ ASSISI SCHOOL",
  "DADHICHI PUBLIC SCHOOL",
  "DAFFODIL PUBLIC SCHOOL",
  "DAFFODILS CONCEPT SCHOOL",
  "DAFFODILS EGLISH SCHOOL",
  "DAFFODILS ENGLISH SCHOOL",
  "DAFFODILS HIGH SCHOOL",
  "DAFFODIL'S HOME",
  "DAFFODILS PRE SCHOOL",
  "DAFFODILS PUBLIC SCHOOL",
  "DAGSHAI PUBLIC SCHOOL",
  "DAILY MIRROR PUBLIC SCHOOL",
  "DAISIES SCHOOL",
  "DAISY INTERNATIONAL SCHOOL",
  "DAKHEERATH E.M.HIGH SCHOOL",
  "DALHOUSIE PUBLIC SCHOOL",
  "DALMIA VIDYA MANDIR",
  "DALY COLLEGE",
  "DAMODAR HIGHER SECONDARY SCHOOL",
  "DANIEL PUBLIC SCHOOL",
  "DANIESHMISSION HIGHER SECONDARY SCHOOL",
  "DANISH MATRICULATION HIGHER SECONDARY SCHOOL",
  "DARSHAN ACADEMY",
  "DARUL AMAN ENGLISH MEDIUM SCHOOL",
  "DARUL FALAH ENGLISH SCHOOL",
  "DARUL HIDAYA HIGHER SECONDARY SCHOOL",
  "DARUL ULOOM ENGLISH SCHOOL",
  "DARULULOOM HUSSAINIAIAH SCHOOL",
  "DARUSSALAM PUBLIC SCHOOL",
  "DASAR HIGH SCHOOL",
  "DASHMESH PUBLIC HIGH SCHOOL",
  "DASHMESH PUBLIC SCHOOL",
  "DASMESH PARIVAR INTERNATIONAL SCHOOL",
  "DASMESH PUBLIC SCHOOL",
  "DASMESH SENIOR SECONDARY SCHOOL",
  "DAULAT HUSSAIN MUSLIM INDIAN HIGH SCHOOL",
  "DAULAT RAM ABBI DAV PUBLIC SCHOOL",
  "DAV CENTENARY PUBLIC SCHOOL",
  "DAV CENTENARY SCHOOL",
  "DAV MULTI PURPOSE PUBLIC SCHOOL",
  "DAV PUBLIC SCHOOL",
  "DAV SENIOR SECONDARY PUBLIC SCHOOL",
  "DAV SR. SEC. PUBLIC SCHOOL",
  "DAWN BOARDING SCHOOL",
  "DAWN INTERNATIONAL SCHOOL",
  "DAWN PUBLIC SCHOOL",
  "DAY STAR SCHOOL",
  "DAYA RAM PUBLIC SCHOOL",
  "DAYAL PUBLIC SCHOOL",
  "DAYAL SINGH PUBLIC SCHOOL",
  "DAYANAND ADARSH VIDYALAYA",
  "DAYANAND ANGLO VEDIC PUBLIC SCHOOL",
  "DAYANAND BAL NIKETAN SR. SEC. SCHOOL",
  "DAYANAND HIGH SCHOOL",
  "DAYANAND KANYA VIDYALAYA",
  "DAYANAND MODEL SCHOOL",
  "DAYANAND MODEL SENIOR SECONDARY SCHOOL",
  "DAYANAND PUBLIC SCHOOL",
  "DAYANANDA SAGAR INTERNATIONAL SCHOOL",
  "DAYAPURAM RESIDENTIAL SCHOOL",
  "DAYAWATI DHARMAVIRA PUBLIC SCHOOL",
  "DAYAWATI MODI ACADEMY",
  "DAYAWATI MODI PUBLIC SCHOOL",
  "DAYSTAR KINDERGARDEN",
  "DDRC",
  "DE NOBILI SCHOOL",
  "DE PAUL INTERNATIONAL RESIDENTIAL SCHOOL",
  "DE PAUL PUBLIC SCHOOL",
  "DE PAUL SCHOOL",
  "DE PAUL SCHOOLDATAGANJ ROAD BUDAUN",
  "DE SOUZA'S SCHOOL",
  "DECCAN INTERNATIONAL SCHOOL",
  "DECCAN PRIMARY SCHOOL",
  "DECCAN PUBLIC SCHOOL",
  "DECENT PUBLIC SCHOOL",
  "DEEMA PUBLIC SCHOOL",
  "DEEN BANDHU PUBLIC SCHOOL",
  "DEEP CHAND BMR PUBLIC SCHOOL",
  "DEEP MEMORIAL PUBLIC SCHOOL",
  "DEEP PUBLIC SCHOOL",
  "DEEPAK MEMORIAL ACADEMY",
  "DEEPAK MEMORIAL PUBLIC SCHOOL",
  "DEEPIKA ENGLISH MEDIUM SCHOOL",
  "DEEPIKA PUBLIC SCHOOL",
  "DEEPTHI NUR. & ENGLISH MEDIUM PRIMARY SCHOOL",
  "DEEPTI CONVENT HIGHER SECONDARY SCHOOL",
  "DEEPTI CONVENT SCHOOL",
  "DEEPTI ENGLISH MEDIUM SCHOOL",
  "DEEPVIHAR HIGH SCHOOL",
  "DEEPVIHAR HIGHER SECONDARY SCHOOL",
  "DEEWAN PUBLIC SCHOOL",
  "DEFENCE PUBLIC SCHOOL",
  "DEFENCE PUBLIC SENIOR SECONDARY SCHOOL",
  "DEFENCE SECONDARY SCHOOL",
  "DEHRADUN PUBLIC SCHOOL",
  "DELHI MODEL PUBLIC SCHOOL",
  "DELHI PUBLIC SCHOOL",
  "DELHI PUBLIS SCHOOL",
  "DEMONSTRATION MULTIPURPOSE SCHOOL",
  "DEO MEMORIAL PUBLIC SCHOOL",
  "DE-PAUL E.M.U.P.SCHOOL",
  "DERIAPUR ANCHAL HIGH SCHOOL",
  "DERPUR HIGH SCHOOL",
  "DESH BHAGAT MEMORIAL SCHOOL",
  "DESH BHARAT PUBLIC SCHOOL",
  "DESHAYES MOUNT SCHOOL",
  "DESHBANDHU NAGAR MADHYAMIK VIDYALAYA",
  "DESIA MATRICULATION HIGHER SECONDARY SCHOOL",
  "DESIRE PUBLIC SCHOOL & DESIRE COACHING CENTER",
  "DESOUZA'S SCHOOL",
  "DESRAJ VADHERA DAV CENT PUBLIC SCHOOL",
  "DEUCHA GOURANGINI HIGH SCHOOL",
  "DEV DARSHAN PUBLIC SCHOOL",
  "DEV INTERNATIONAL SCHOOL",
  "DEVA ANNAI MATRICULATION SCHOOL",
  "DEVA MATHA CENTRAL SCHOOL",
  "DEVAGIRI C.M.I. PUBLIC SCHOOL",
  "DEVALOIS HIGHER SECONDARY SCHOOL",
  "DEVAMATHA C.M.I. PUBLIC SCHOOL",
  "DEVASYA INTERNATIONAL PUBLIC SCHOOL",
  "DEVGUN MODEL HIGH SCHOOL",
  "DEVI ENGLISH MEDIUM SCHOOL",
  "DEVI VILASOM E.M.L.P. SCHOOL",
  "DEVINE LIGHT INTERNATIONAL SCHOOL",
  "DEVPARA HIGH SCHOOL",
  "DEVSTHALY VIDYAPEETH",
  "DEWAN BAL KRISHAN PUBLIC SCHOOL",
  "DEWAN HARIKRISHAN DAS S D PUBLIC SCHOOL",
  "DEWAN PUBLIC INTERNATIONAL SCHOOL",
  "DEWAN PUBLIC SCHOOL",
  "DEXTER PRE NURSERY SCHOOL",
  "DGV PUBLIC SCHOOL",
  "DHAMI PUBLIC SCHOOL",
  "DHANALAKSHMI SRINIVASAN MATRICULATION HIGHER SECONDARY SCHOOL",
  "DHANBAD PUBLIC SCHOOL",
  "DHANVANDHIRI MENTALLY RETARDED AND DRUG ADDICTORS WELFARE ASSOCIATION",
  "DHANYAKURIA HIGHER SECONDARY SCHOOL",
  "DHARAM PUBLIC SCHOOL",
  "DHARAMAVANT JUNIOR COLLEGE",
  "DHARANI MATRICULATION HIGHER SECONDARY SCHOOL",
  "DHARMAVIR ANAND DIGE JIDD SPECIAL SCHOOL FOR HANDICAPPED CHILDREN",
  "DHARUME HIGH PUBLIC SCHOOL",
  "DHAVA AMUDHAM MATRIC HIGHER SECONDARY SCHOOL",
  "DHILWAN INTERNATIONAL PUBLIC SCHOOL",
  "DHINGLIGHAR KIDS HOUSE",
  "DHIRUBHAI AMBANI INTERNATIONAL SCHOOL",
  "DHOLPUR MILITARY SCHOOL",
  "DHRUV EDUCATION HIGH SCHOOL",
  "DHRUVA VIDYAPEETH",
  "DHULESHWAR GARDEN PUBLIC SCHOOL",
  "DIAMOND DRILL PUBLIC SCHOOL",
  "DIAMOND HARBOUR HIGH SCHOOL",
  "DIAMOND JUBILEE ELEMENTARY SCHOOL",
  "DIAMOND JUBILEE MATRICULATION SCHOOL",
  "DIAMOND PUBLIC SCHOOL",
  "DIANA PUBLIC SCHOOL",
  "DIG VIJAY MEMORIAL SCHOOL",
  "DIKSHANT INTERNATIONAL SCHOOL",
  "DILSUKHNAGAR PUBLIC SCHOOL",
  "DINA NATH PUBLIC SCHOOL",
  "DINABANDHU BEHERA GIRLS HIGH SCHOOL",
  "DIPS SCHOOL",
  "DISARI PUBLIC SCHOOL",
  "DISARII PUBLIC SCHOOL",
  "DISCIPLINED DISCIPLES INTERNATIONAL SCHOOL",
  "DISNEYLAND PUBLIC SCHOOL",
  "DIVINE CHILD INTERNATIONAL SCHOOL",
  "DIVINE CHILD PUBLIC SCHOOL",
  "DIVINE CROSS MISSION SPECIAL SCHOOL",
  "DIVINE ENGLISH SCHOOL",
  "DIVINE INTERNATIONAL SCHOOL",
  "DIVINE MERCY SCHOOL",
  "DIVINE PUBLIC SCHOOL & JUNIOR COLLEGE",
  "DIVINE PUBLIC SCHOOL",
  "DIVINE RESIDENTIAL PUBLIC SCHOOL & JUNIOR COLLEGE",
  "DIVYA JYOTHI PUBLIC SCHOOL",
  "DIVYA PUBLIC SCHOOL",
  "DIWAKAR MODEL SENIOR SECONDARY SCHOOL",
  "DK PUBLIC SCHOOL",
  "DNYAN PRABODHAN MANDIR",
  "DNYANDEEP ENGLISH MEDIUM SCHOOL",
  "DOABA PUBLIC SCHOOL",
  "DOABA PUBLIC SENIOR SECONDARY SCHOOL",
  "DOCTOR KONNANATHU BALAKRISHNA MENON MEMMORIAL HIGH SCHOOL",
  "DOLL'S HOUSE",
  "DOLO MANDO RESIDENTIAL ENGLISH SCHOOL",
  "DOLPHIN INTERNATIONAL RESIDENTIAL SCHOOL",
  "DOLPHIN INTERNATIONAL SCHOOL",
  "DOLPHIN PUBLIC SCHOOL",
  "DOLPHINS INTERNATIONAL SCHOOL",
  "DOMINIC SAVIO ENGLISH MEDIUM SCHOOL",
  "DON & DONNA CONVENT",
  "DON BOSCO ACADEMY",
  "DON BOSCO CENTRAL SCHOOL",
  "DON BOSCO ENGLISH MEDIUM HIGH SCHOOL",
  "DON BOSCO HIGH SCHOOL",
  "DON BOSCO HIGHER SECONDARY SCHOOL",
  "DON BOSCO MATRICULATION HIGHER SECONDARY SCHOOL",
  "DON BOSCO PUBLIC SCHOOL",
  "DON BOSCO SCHOOL",
  "DON BOSCO SENIOR SECONDARY SCHOOL",
  "DONYI POLO MISSION SCHOOL",
  "DONYI POLO VIDYA BHAWAN",
  "DONYI PUBLIC SCHOOL",
  "DOON CAMBRIDGE SCHOOL",
  "DOON GLOBAL SCHOOL",
  "DOON INTERNATIONAL PUBLIC SCHOOL",
  "DOON INTERNATIONAL SCHOOL",
  "DOON PUBLIC SCHOOL",
  "DOON VALLEY CAMBRIDGE SCHOOL",
  "DOON VALLEY PUBLIC SCHOOL",
  "DORAHA PUBLIC SCHOOL",
  "DOVETON BOYS' HIGHER SECONDARY SCHOOL",
  "DOVETON GIRLS' HIGHER SECONDARY SCHOOL",
  "DOW HILL SCHOOL",
  "DPS PANIPAT CITY",
  "DR DAULAT RAM BHALLA DAV CENTENARY PUBLIC SCHOOL",
  "DR DEVI CHAND D A V PUBLIC SCHOOL",
  "DR JAGDISH MEMORIAL SCHOOL",
  "DR KARAMVIR PUBLIC SCHOOL",
  "DR KAUSHAL CONVENT SCHOOL",
  "DR SHIVNARAYAN ARYA INTER COOLEGE",
  "DR. A.R. UNDRE ENGLISH HIGH SCHOOL",
  "DR. AMRIT LAL ISHARAT MEMORIAL SUNBEAM SCHOOL",
  "DR. AUGUSTO SAISA HIGH SCHOOL",
  "DR. BEDEKAR VIDYA MANDIR",
  "DR. BHEEMRAO AMBEDKAR SARSWATI SHISHU MANDIR. SINGHA BANPUR, BASTI",
  "DR. BRIJ KISHORI DUBEY MEMORIAL SCHOOL",
  "DR. DASARATHAN INTERNATIONAL SCHOOL",
  "DR. G.R. PUBLIC SCHOOL",
  "DR. G.S. KALYANASUNDARAM MEMORIAL SCHOOL",
  "DR. GRAHAM'SHOMES",
  "DR. HERMANN GUNDERT FOUNDATION SCHOOL",
  "DR. K.K.R'S GOWTHAM CONCEPT SCHOOL",
  "DR. K.K.R'S GOWTHAM INTERNATIONAL SCHOOL",
  "DR. K.N. MODI GLOBAL SCHOOL",
  "DR. KONDABOLU LAKSHMI PRASAD PUBLIC SCHOOL",
  "DR. M.C.M. DAV CENT. PUBLIC SCHOOL",
  "DR. MAHARAJ KRISHNA KAPUR ARYA MODEL SCHOOL",
  "DR. N. SHANKARA ADYANTHAYA MEMORIAL ENGLISH MEDIUM HIGH SCHOOL",
  "DR. NAGENDRA PUBLIC SCHOOL",
  "DR. P.K. PUBLIC SCHOOL",
  "DR. RADHAKRISHNAN GIRLS SCHOOL",
  "DR. RANJIT INTERNATIONAL SCHOOL",
  "DR. RIZVI LEARNERS ACADEMY",
  "DR. S RADHAKRISHNAN PUBLIC SCHOOL",
  "DR. SAIF-UD-DIN KITCHLU MEM PUBLIC SCHOOL",
  "DR. SAROJINI NAIDU HIGH SCHOOL",
  "DR. SONEY LAL PATEL PUBLIC SCHOOL",
  "DR. VIRENDRA SWAROOP EDUCATION CENTRE",
  "DR. VIRENDRA SWAROOP PUBLIC SCHOOL",
  "DR. VIRENDRA SWARUP MEMORIAL PUBLIC SCHOOL",
  "DR. ZAKIR HUSAIN PRIMARY SCHOOL",
  "DR. ZAKIR HUSSAIN MIDDLE SCHOOL",
  "DR.SONEY LAL PATEL PUBLIC SCHOOL",
  "DRAVID HIGH SCHOOL",
  "DREAM FLED PUBLIC SCHOOL",
  "DREAM LAND PUBLIC SCHOOL",
  "DREAMLAND SCHOOL",
  "DRISHTI DR. R.C. JAIN INNOVATIVE PUBLIC SCHOOL",
  "DRONA PUBLIC SCHOOL",
  "DRONACHARYA PUBLIC SCHOOL",
  "DRS INTERNATIONAL SCHOOL",
  "DRUK WHITE LOTUS SCHOOL",
  "DSK SCHOOL",
  "DUFFDON INTERNATIONAL PUBLIC SCHOOL",
  "DUNDLOD PUBLIC SCHOOL",
  "DUNDLOD VIDYAPEETH",
  "DUNGARPUR PUBLIC SCHOOL",
  "DUNLOP ENGLISH MEDIUM SCHOOL",
  "DUNNE'S INSTITUTE",
  "DURGA DEVI BASSI DAV CENT. PUBLIC SCHOOL",
  "DURGA PRASAD PUBLIC SCHOOL",
  "DURGA PRASAD SARAF VIDYAPEETH",
  "DURGA PUBLIC SCHOOL",
  "DVC HIGHER SECONDARY SCHOOL",
  "DWAR JINGKYRMEN SCHOOL FOR CHILDREN",
  "DWARKA INTERNATIONAL SCHOOL",
  "DWARKESH VIDYASHRAM CBSE SCHOOL",
  "DWARKIBAI GANGADHAR KHETAN INTERNATIONAL SCHOOL",
  "DYANRRABHODINI PUBLIC SCHOOL",
  "DYANSTY INTERNATIONAL SCHOOL",
  "DYNAMIC PUBLIC HIGHER SECONDARY SCHOOL",
  "DYNASTY INTER PUBLIC SCHOOL",
  "DYNASTY INTERNATIONAL T.T. PUBLIC SCHOOL",
  "E.C.I. CHALET DAY SCHOOL",
  "E.P.CONVENT",
  "EAST AND WEST HIGH SCHOOL",
  "EAST END PUBLIC SCHOOL",
  "EAST POINT PUBLIC SCHOOL",
  "EAST POINT SECONDARY SCHOOL",
  "EAST POINT SR. SEC. SCHOOL",
  "EAST WEST PUBLIC SCHOOL",
  "EAST WOOD INTERNATION SCHOOL",
  "EAST WOOD INTERNATIONAL SCHOOL",
  "EASTERN PUBLIC SCHOOL",
  "EASTERN RAILWAY INTER COLLEGE",
  "EASTWEST PUBLIC SCHOOL",
  "EBENEZER INTERNATIONAL RESIDENTIAL SCHOOL",
  "EBENEZER INTERNATIONAL SCHOOL",
  "EBENEZER MODERN MATRICULATION SCHOOL",
  "EBEN-EZER PUBLIC SCHOOL",
  "ECOLE MONDIALE WORLD SCHOOL",
  "ECOLE PUBLIC SCHOOL",
  "EDEN INTERNATIONAL SCHOOL",
  "EDEN PUBLIC SCHOOL",
  "EDMONTON PUBLIC SCHOOL",
  "EDUSTAR INTERNATIONAL SCHOOL",
  "EDWARDS ENGLISH SCHOOL",
  "EGMONT INTERNATIONAL PREPARATORY SCHOOL",
  "EICHER SCHOOL",
  "EINISSI MISSION SCHOOL",
  "EINSTEIN PUBLIC SCHOOL",
  "EKAM PUBLIC SCHOOL",
  "EKASHILA HIGH SCHOOL",
  "EKLAVAYA RESIDENTIAL SCHOOL",
  "EKLAVYA EDUCATION FOUNDATION SCHOOL",
  "EKLAVYA MODEL RESIDENTIAL SCHOOL",
  "EKLAVYA MODERN RESIDENTIAL SCHOOL",
  "EKLAVYA SCHOOL",
  "EKRA PUBLIC GIRLS INTER COLLEGE",
  "EKTA PUBLIC SCHOOL",
  "EKYA SCHOOL",
  "EL NISSI MISSION SCHOOL",
  "ELAHI ORIENTAL ARABIC HIGH SCHOOL",
  "ELEAZAE ENGLISH SCHOOL",
  "ELEGANT PUBLIC SCHOOL",
  "ELITE ENGLISH ACADEMY SCHOOL",
  "ELITE HIGHER SECONDARY SCHOOL",
  "ELOHIM EDUCATIONAL & RELIGIOUS CHARITABLE TRUST",
  "ELSIE EVANGELICAL SCHOOL",
  "EMANUEL PUBLIC SCHOOL",
  "E-MAX INTERNATIONAL SCHOOL",
  "EMBASSY PUBLIC SCHOOL",
  "EMEA HIGHER SECONDARY SCHOOL",
  "EMERALD HEIGHTS INTERNATIONAL SCHOOL",
  "EMINENT PUBLIC SCHOOL",
  "EMM AAR INTERNATIONAL SCHOOL",
  "EMMANUEL ENGLISH SCHOOL",
  "EMMANUEL MARTHOMA E.M. SCHOOL",
  "EMMANUEL MISSION SCHOOL",
  "EMMANUEL MISSION SENIOR SECONDARY SCHOOL",
  "EMMANUEL MISSION SENIOR SECONDERY SCHOOL",
  "EMMANUEL MISSION SR SEC SCHOOL",
  "EMMANUEL PUBLIC SCHOOL",
  "EMMANUEL SR SEC SCHOOL",
  "EMMANVEL MISSION PUBLIC SCHOOL",
  "EMMAUS - SWISS REFERRAL HOSPITAL SCHOOL",
  "EMMAUS SWISS HIGH SCHOOL",
  "EMRALD HEIGHTS SCHOOL",
  "ENGLISH UNION SCHOOL",
  "ERA INTERNATIONAL SCHOOL",
  "ESS ESS CONVENT SCHOOL",
  "ESSAR INTERNATIONAL SCHOOL",
  "ETERNAL LIFE SECONDARY SCHOOL",
  "ETHIRAJ MATRICULATION SCHOOL",
  "EUREKA PUBLIC SCHOOL",
  "EURO INTERNATIONAL SCHOOL",
  "EURO KIDS INTERNATIONAL LIMITED",
  "EUROKIDS INTL PRE SCHOOL",
  "EVER GREEN HIGH SCHOOL",
  "EVEREST ENGLISH MEDIUM SCHOOL",
  "EVEREST PUBLIC SCHOOL",
  "EVERGREEN PUBLIC SCHOOL",
  "EVERGREEN PUBLIC SVHOOL",
  "EVERSHINE PUBLIC HIGH SCHOOL",
  "EVERSHINE RESIDENTIAL SCHOOL",
  "EVERWIN MATRICULATION HR. SEC. SCHOOL",
  "EVERWIN VIDHYASHRAM",
  "EWING CHRISTIAN PUBLIC SCHOOL",
  "EXCEL CENTRAL SCHOOL",
  "EXCEL GLOBAL SCHOOL",
  "EXCELSIOR AMERICAN SCHOOL",
  "F.S.D.A. ENGLISH HIGH SCHOOL",
  "FABINDIA SCHOOL",
  "FABLE PUBLIC SCHOOL",
  "FAITH CALVARY SCHOOL",
  "FAIZABAD PUBLIC SCHOOL",
  "FAIZ-E-AAM MODERN EDUCATION CENTRE",
  "FANINDRA DEV INSTITUTION SCHOOL",
  "FATEPUR HIGH SCHOOL",
  "FATHER AGNEL SCHOOL",
  "FATHER GEORGE KIZHAKKACHALIL MEMORIAL HIGH SCHOOL",
  "FATHER LEBLOND SCHOOL",
  "FATHERS PUBLIC SCHOOL",
  "FATHERSON PUBLIC SCHOOL",
  "FATHIMA ORIENTAL ARABIC GIRLS HIGH SCHOOL",
  "FATIMA CONVENT HIGH SCHOOL",
  "FATIMA CONVENT HIGHER SECONDARY SCHOOL",
  "FATIMA CONVENT SCHOOL",
  "FATIMA ENGLISH HIGH SCHOOL",
  "FATIMA GIRL ENGLISH SCHOOL",
  "FATIMA PRIMARY SCHOOL",
  "FATIMA SCHOOL",
  "FAUJA SINGH MANN SILVER CREEK SCHOOL",
  "FAZL E OMAR PUBLIC SCHOOL",
  "FEDERAL PUBLIC SCHOOL",
  "FEDRICK PUBLIC SCHOOL",
  "FENTON GANJ GIRLS SR. SEC. SCHOOL",
  "FIELD MARSHAL HIGH SCHOOL",
  "FIMMILY PUBLIC SCHOOL",
  "FIRST MARK PUBLIC SCHOOL",
  "FLAIZ MEMORIAL HIGHER SECONDARY SCHOOL OF S.D.A.",
  "FLAIZ MEMORIAL HIGHER SECONDARY SCHOOL",
  "FLAIZ MEMORIALHIGHER SECONDARY SCHOOL OF SDA,",
  "FLIX PUBLIC SCHOOL",
  "FLORENCE ACADEMY SCHOOL",
  "FLORENCE DALE PUBLIC SCHOOL",
  "FLORENCE PUBLIC SCHOOL",
  "FORT CATHOLIC GIRLS' SCHOOL",
  "FORT INTERNATIONAL ACADEMY",
  "FORUNA D.M. PUBLIC SCHOOL",
  "FOUNDATION INTERNATIONAL SCHOOL",
  "FOUNDATION PUBLIC SCHOOL",
  "FR. AGNEL HIGH SCHOOL INPCO PANAJI",
  "FR. AGNEL MULTIPURPOSE HIGHER SCHOOL",
  "FR. AGNEL SCHOOL",
  "FR. THOMAS PORUKARA CENTERAL SCHOOL",
  "FRANCIS D’ ASSISI SCHOOL",
  "FRANCIS MIDDLE SCHOOL",
  "FRAVASHI ACADEMY",
  "FREDERIC OSANAM E.M. L.P. SCHOOL",
  "FROBEL CHILDREN SCHOOL",
  "FUSCO'S SCHOOL",
  "FUSION INTERNATIONAL SCHOOL",
  "FUTURE BHUBANESWAR SCHOOL",
  "FUTURE KIDS PRIMARY SCHOOL",
  "G A V PUBLIC SCHOOL",
  "G B S PUBLIC SCHOOL",
  "G M A CITY PUBLIC SCHOOL",
  "G M T PUBLIC SCHOOL",
  "G R G NATIONAL GIRLS SENIOR SECONDARY SCHOOL",
  "G. G CHITNIS ENGLISH MEDIUM HIGH SCHOOL",
  "G. VENKATAPPA MEMORIAL SCHOOL",
  "G.B. INTERNATIONAL SCHOOL",
  "G.B.S. PUBLIC SCHOOL",
  "G.D. BIRLA MEMORIAL SCHOOL",
  "G.D. GOENKA PUBLIC SCHOOL",
  "G.D. MOTHERS INTERNATIONAL SCHOOL",
  "G.D. PUBLIC SCHOOL",
  "G.D. SHAH HIGH SCHOOL",
  "G.D. SOMANI MEMORIAL SCHOOL",
  "G.E.B. VIDHYALAYA",
  "G.E.M. PUBLIC SCHOOL",
  "G.E.M.S. N.P.S. INTERNATIONAL SCHOOL",
  "G.G. INTERNATIONAL SCHOOL",
  "G.G.D. GOENKA PUBLIC SCHOOL",
  "G.H. RAISONI VIDYANIKETAN",
  "G.H.G. ACADEMY",
  "G.H.G. KHALSA HIGH SCHOOL",
  "G.L. PUBLIC SCHOOL",
  "G.M.A. CITY PUBLIC SCHOOL",
  "G.M.T. PUBLIC SENIOR SECONDARY SCHOOL",
  "G.N. NATIONAL PUBLIC SCHOOL",
  "G.R. MEMORIAL PUBLIC SCHOOL",
  "G.R.D. ACADEMY",
  "G.R.G. MEMORIAL TRIBAL HIGHER SECONDARY",
  "G.R.M. PUBLIC JUNIOR HIGH SCHOOL",
  "G.S. ACADEMY GORAKHPUR",
  "G.S. CONVENT SCHOOL",
  "G.S. JANGID MEMORIAL SCHOOL",
  "G.S. MEMORIAL SR. SEC. SCHOOL",
  "G.S. PUBLIC SCHOOL",
  "G.V.M'S S.N.J.A. HIGHER SECONDARY SCHOOL",
  "GAGAN BHARTI PUBLIC SCHOOL",
  "GAGAN INTERNATIONAL SCHOOL",
  "GAGAN PUBLIC SCHOOL",
  "GAGAN SHIVALIK SENIOR SECONDARY SCHOOL",
  "GAIA PLAY SCHOOL AND DAYCARE",
  "GAIL D.A.V. PUBLIC SCHOOL",
  "GALAXY PUBLIC SCHOOL",
  "GALLILIAN PUBLIC SCHOOL",
  "GANAPATHY LYER GIRLS HIGH SCHOOL",
  "GANDHI BAL NIKETAN SENIOR SECONDARY SCHOOL",
  "GANDHI CITY PUBLIC SCHOOL",
  "GANDHI NAGAR PUBLIC SCHOOL",
  "GANDHI PUBLIC SCHOOL",
  "GANDHIGRAM JUNIOR COLLEGE",
  "GANESHRAM JIWNANI PUBLIC SCHOOL",
  "GANGOTHRI INTERNATIONAL PUBLIC SCHOOL",
  "GANPAR RAI SOLARPURIA",
  "GANPAT RAI SALARPURIA SARASWATI",
  "GANRA PADUMA HIGH SCHOOL",
  "GARDEN NEW PUBLIC SCHOOL",
  "GARDEN VALLEY INTERNATIONAL SCHOOL",
  "GARGI GIRLS’ SCHOOL",
  "GARIFA HIGH SCHOOL",
  "GARMEL PUBLIC SCHOOL",
  "GATEWAY AMERICAN INTERNATIONAL SCHOOL",
  "GATEWAY INTERNATIONAL SCHOOL",
  "GATHASAMANA CONVENT AND ENGLISH MEDIUM SCHOOL",
  "GAUR BRAHAMAN CENTRAL SR. SEC. SCHOOL",
  "GAURAV PUBLIC SCHOOL",
  "GAUTAM PUBLIC SCHOOL",
  "GAYATHRI CENTRAL SCHOOL",
  "GAYATHRI ENGLISH MEDIUM SCHOOL",
  "GAYATRI CONVENT",
  "GAYATRI PUBLIC SCHOOL",
  "GAYATRI VIDYAPEETH",
  "GD ACADMY PUBLIC SCHOOL",
  "GD GOENKA INTERNATIONAL SCHOOL ROHTAK",
  "GD GOENKA WORLD SCHOOL",
  "GD GOENKA SCHOOL",
  "GEAR INNOVATIVE INTERNATIONAL SCHOOL",
  "GEETA INTERNATIONAL PUBLIC SCHOOL",
  "GEETA PUBLIC SCHOOL",
  "GEETA SANJAY MEMORIAL PUBLIC SCHOOL",
  "GEETA VIDYA MANDIR PUBLIC SCHOOL",
  "GEETANJALI PUBLIC HIGH SCHOOL",
  "GEETANJALI PUBLIC SCHOOL",
  "GEETANJALI SR. SEC. SCHOOL",
  "GEETHA HIGH SCHOOL",
  "GEETHAANJALI ALL INDIA SECONDARY SCHOOL",
  "GEETHANJALI HIGHER PRIMARY SCHOOL",
  "GEETHANJALI PUBLIC SCHOOL",
  "GEM INTERNATIONAL RESIDENTIAL SCHOOL",
  "GEM PUBLIC SENIOR SECONDARY SCHOOL",
  "GEMS AKADEMIA INTERNATIONAL SCHOOL",
  "GEMS ENGLISH SCHOOL",
  "GEMS INTERNATIONAL SCHOOL",
  "GEMS NPS INTERNATIONAL SCHOOL-GUWAHATI",
  "GEN. GURNAM SINGH PUBLIC SCHOOL",
  "GENERAL THIMMAIAH PUBLIC SCHOOL",
  "GENIUS CAMBRIDGE INTERNATIONAL SCHOOL",
  "GENIUS INTERNATIONAL PUBLIC SCHOOL",
  "GENIUS PUBLIC SCHOOL",
  "GEORGIAN PUBLIC SCHOOL",
  "GHANAPRIYA WOMEN COLLEGE",
  "GHANASHAYM HEMALATA VIDYA MANDIR",
  "GHANASHYAM HEMALATA VIDYAMANDI",
  "GHANSHYAM HEMLATA VIDYA MANDIR",
  "GHAYASIBAN PUBLIC COLLEGE",
  "GHAZIABAD PUBLIC SCHOOL",
  "GHAZIPUR GIRLS SCHOOL",
  "GHOMAN INTERNATIONAL PUBLIC SCHOOL",
  "GHUMUSHAR TECHNICAL HIGH SCHOOL",
  "GIAN BHARTI PUBLIC HIGH SCHOOL",
  "GIAN JYOTI PUBLIC SCHOOL",
  "GIRIDEEPAM BETHANY ENGLISH SCHOOL",
  "GIRIDEEPAM NURSERY & L.P.SCHOOL",
  "GIRIRAJ PUBLIC SCHOOL",
  "GIRJA SHANER SARLA DEVI PUBLIC SCHOOL",
  "GIRLS' HIGH SCHOOL & COLLEGE",
  "GIRVANI JUNIOR COLLEGE",
  "GITA BAL NIKETAN SENIOR SECONDARY SCHOOL",
  "GITA BHAWAN PUBLIC SCHOOL",
  "GITA NIKETAN AWASIYA VIDYALAYA",
  "GITA VIDYA MANDIR PUBLIC SCHOOL",
  "GITANJALI DEVSHALA",
  "GITANJALI INTERNATIONAL SCHOOL",
  "GITANJALI PUBLIC SCHOOL",
  "GITANJALI SCHOOL",
  "GITARAM ACADEMY",
  "GITARATTAN JINDAL PUBLIC SCHOOL",
  "GLACIER PUBLIC SCHOOL",
  "GLANE HILL SCHOOL",
  "GLENDALE ACADEMY INTERNATIONAL",
  "GLENDALE ACADEMY",
  "GLENHILL PUBLIC SCHOOL",
  "GLITZY PUBLIC SCHOOL",
  "GLOBAL INDIAN INTERNATIONAL SCHOOL",
  "GLOBAL PUBLIC SCHOOL",
  "GLOBE PUBLIC SCHOOL",
  "GLOBLE PUBLIC SCHOOL",
  "GLORIOUS PUBLIC SCHOOL",
  "GLOWINSTAR INTEGRATED SCHOOL",
  "GNANA BHARATHI ENGLISH MEDIUM SCHOOL",
  "GNANA BHASKARA PUBLIC SCHOOL",
  "GNANA DEEPA ACADEMY RESIDENTIAL SCHOOL",
  "GNANA JYOTHI SCHOOL",
  "GNANODAYA PUBLIC SCHOOL",
  "GOALPARA ENGLISH SCHOOL",
  "GOBIND INTERNATIONAL PUBLIC SCHOOL",
  "GOBIND RAM KATARUKA DAV PUBLIC SCHOOL",
  "GOBINDGARH PUBLIC SCHOOL",
  "GODAWARI PUBLIC SCHOOL",
  "GODWIN PUBLIC SCHOOL",
  "GOENKA PUBLIC SCHOOL",
  "GOETHALS MEMORIAL SCHOOL",
  "GOGHAT HIGH SCHOOL",
  "GOHPUR HIGH SCHOOL",
  "GOKHALE PUBLIC SCHOOL",
  "GOKULA VIDYALAYA",
  "GOKULDAS PUBLIC SCHOOL",
  "GOLAYA PROGRESSIVE PUBLIC SCHOOL",
  "GOLD FIELD PUBLIC SCHOOL",
  "GOLDCREST HIGH SCHOOL",
  "GOLDEN BELLS PUBLIC SCHOOL",
  "GOLDEN BIRDS PUBLIC HIGH SCHOOL",
  "GOLDEN EARTH GLOBAL SCHOOL",
  "GOLDEN ERA PUBLIC SCHOOL",
  "GOLDEN FUTURE PUBLIC SCHOOL",
  "GOLDEN GLEANS PUBLIC SCHOOL",
  "GOLDEN HEIGHTS SCHOOL",
  "GOLDEN INTERNATIONAL SCHOOL",
  "GOLDEN PUBLIC SCHOOL",
  "GOLDEN PUBLIC SENIOR SECONDARY SCHOOL",
  "GOLDEN VALLEY SENIOR SECONDARY SCHOOL",
  "GOMATESH ENGLISH MEDIUM PRIMARY SCHOOL",
  "GOMATESH HIGH SCHOOL",
  "GOMATHY GLOBAL SCHOOL",
  "GOMATHY INTERNATIONAL SCHOOL",
  "GONDIA PUBLIC SCHOOL",
  "GOOD EARTH SCHOOL",
  "GOOD HOPE EDUCATION SOCIETY",
  "GOOD SHEPHERD CONVENT",
  "GOOD SHEPHERD E.M. SCHOOL",
  "GOOD SHEPHERD ENGLISH MEDIUM SCHOOL",
  "GOOD SHEPHERD ENGLISH SCHOOL",
  "GOOD SHEPHERD INTERNATIONAL SCHOOL",
  "GOOD SHEPHERD MODEL SCHOOL",
  "GOOD SHEPHERD MODERN ENGLISH SCHOOL",
  "GOOD SHEPHERD PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "GOOD SHEPHERD PUBLIC SCHOOL",
  "GOODWILL HIGH SCHOOL",
  "GOODWILL MATRICULATION HIGHER SCHOOL",
  "GOODWILL PUBLIC SCHOOL",
  "GOODWILLS ENGLISH MEDIUM SCHOOL",
  "GOPAL CHAKRABORTY PUBLIC SCHOOL",
  "GOPAL SHARMA INTERNATIONAL SCHOOL",
  "GOPAL VIDYA MANDIR SENIOR SECONDARY SCHOOL",
  "GOPALAN INTERNATIONAL SCHOOL",
  "GOPALAN NATIONAL SCHOOL",
  "GOPALAPURAM BOYS HIGHER SECONDARY SCHOOL",
  "GOPALNAGAR HARIPADA INSTITUTION SCHOOL",
  "GORABAZAR ISWAR CHANDRA INSTITUTE",
  "GORAKHPUR PUBLIC SCHOOL",
  "GORKEY PUBLIC SCHOOL",
  "GORTON MISSION SCHOOL",
  "GOSPEL HOME SCHOOL",
  "GOURI SHANKAR RESIDENTIAL ENGLISH MEDIUM SCHOOL",
  "GOURISANKAR RESIDENTIAL ENGLISH MEDIUM SCHOOL",
  "GOUTHAM PUBLIC SCHOOL",
  "GOUTHO BART HIGHER SECONDARY SCHOOL",
  "GOVERMENT HIGH SCHOOL",
  "GOVERNMENT BOYS SENIOR SECONDARY SCHOOL",
  "GOVERNMENT CO-ED SECONDARY SCHOOL",
  "GOVERNMENT HIGH SCHOOL",
  "GOVERNMENT HIGHER SECONDARY SCHOOL",
  "GOVERNMENT MODEL SENIOR SECONDARY SCHOOL",
  "GOVERNMENT SARVODAYA BAL VIDYALAYA",
  "GOVERNMENT SECONDARY SCHOOL",
  "GOVERNMENT SEN. SEC. SCHOOL",
  "GOVERNMENT SENIOR SECONDARY SCHOOL",
  "GOVERNMENTHIGHER SECONDARY SCHOOL",
  "GOVIND RAM LATARUKA DAV PUBLIC SCHOOL",
  "GOVINDA RAO HIGH SCHOOL",
  "GOVT HIGH SCHOOL",
  "GOVT. ASHRAM HIGH SCHOOL",
  "GOVT. SECONDARY SCHOOL",
  "GOVT. SENIOR SECONDARY SCHOOL",
  "GOWTHAM MODEL SCHOOL",
  "GOWTHAM PUBLIC SCHOOL",
  "GOWTHAMI PUBLIC HIGH SCHOOL",
  "GOWTHAMI PUBLIC SCHOOL",
  "GOYAL PUBLIC SCHOOL",
  "GRACE CONVENT SENIOR SECONDARY SCHOOL",
  "GRACE GARDEN SCHOOL",
  "GRACE MATRICULATION HIGHER SECONDARY SCHOOL",
  "GRACE PUBLIC SCHOOL",
  "GRACIOUS ENGLISH MEDIUM SCHOOL",
  "GRACY AROGYA MARY CONVENT SCHOOL",
  "GRADIANS PUBLIC SCHOOL",
  "GRAIN CHAMBER PUBLIC SCHOOL",
  "GRAMODAYA INTERNATIONAL COLLEGE",
  "GRAMYANCHAL PUBLIC SCHOOL",
  "GRAND COLUMBUS INTERNATIONAL SCHOOL",
  "GRANDVIEW PREP SCHOOL",
  "GREAT ASHOKA PUBLIC SCHOOL",
  "GREAT HEART PUBLIC SCHOOL",
  "GREAT MISSION PUBLIC SCHOOL",
  "GREEN BIRDS HIGH SCHOOL",
  "GREEN CASTLE PUBLIC SCHOOL",
  "GREEN DALE SR. SEC. SCHOOL",
  "GREEN FIELD ACADEMY",
  "GREEN FIELD ENGLISH SCHOOL",
  "GREEN FIELD PUBLIC SCHOOL",
  "GREEN FIELD SMART SCHOOL",
  "GREEN FIELDS SCHOOL",
  "GREEN GROOVE PUBLIC SCHOOL",
  "GREEN LAND CONVENT SCHOOL",
  "GREEN LAND DAY BOARDING PUBLIC SCHOOL",
  "GREEN LAND PUBLIC SCHOOL",
  "GREEN LAND SENIOR SECONDARY PUBLIC SCHOOL",
  "GREEN MEADOWS SCHOOL",
  "GREEN MOUNT SCHOOL",
  "GREEN PARK CENTRAL SCHOOL",
  "GREEN PARK MATRIC HIGHER SECONDARY SCHOOL",
  "GREEN VALES PUBLIC SCHOOL",
  "GREEN VALLEY CENTRAL SCHOOL",
  "GREEN VALLEY CONVENT SCHOOL",
  "GREEN VALLEY EDUCATIONAL INSTITUTION",
  "GREEN VALLEY ENGLISH SCHOOL",
  "GREEN VALLEY INTERNATIONAL PUBLIC SCHOOL",
  "GREEN VALLEY PUBLIC SCHOOL",
  "GREEN VALLEY SCHOOL",
  "GREEN VIEW PUBLIC SCHOOL",
  "GREEN WOOD ENGLISH SCHOOL",
  "GREEN WOOD PUBLIC HIGH SCHOOL",
  "GREEN WOOD PUBLIC SCHOOL",
  "GREEN WOOD PUBLIC SR. SEC. SCHOOL",
  "GREEN WOODS PUBLIC SCHOOL",
  "GREEN WOODS SECONDARY SCHOOL",
  "GREENFIELD ACADEMY",
  "GREENFIELD PUBLIC SCHOOL",
  "GREENFIELDS PUBLIC SCHOOL",
  "GREENLAND DAY BOARDING PUBLIC SCHOOL",
  "GREENLAND PUBLIC SCHOOL",
  "GREENLAWNS HIGH SCHOOL",
  "GREENLAWNS SCHOOL",
  "GREENWICH PUBLIC SCHOOL",
  "GREENWOOD HIGH INTERNATIONAL SCHOOL",
  "GREENWOOD HIGH SCHOOL",
  "GREENWOOD PUBLIC SCHOOL",
  "GREENWOOD SECONDARY SCHOOL",
  "GREETS ACADEMY CBSE PUBLIC SCHOOL",
  "GREETS CHRISTIAN PUBLIC SCHOOL",
  "GREETS PUBLIC SCHOOL",
  "GREGORIAN PUBLIC SCHOOL",
  "GRIZZLY VIDYALAYA",
  "GUARDIAN AND GUIDE ENGLISH SCHOOL",
  "GUHAN MATRICULATION AND HIGHER SECONDARY SCHOOL",
  "GUIDANCE ENGLISH MEDIUM SCHOOL",
  "GUJARAT PUBLIC SCHOOL",
  "GUJRAWALA GURU NANAK PUBLIC SCHOOL",
  "GULAB CHAND RAMJI MALDE HIGH SCHOOL",
  "GULAM NABI AZAD URDU GIRLS HIGH SCHOOL",
  "GULMOHAR PUBLIC SCHOOL",
  "GULMOHUR HIGH SCHOOL",
  "GUNTUR SREE VENUS PUBLIC SCHOOL",
  "GURDASPUR PUBLIC SCHOOL",
  "GURKIRPA PUBLIC SCHOOL",
  "GURPREET HOLY HEART PUBLIC SCHOOL",
  "GURU AMAR DAS PUBLIC SCHOOL",
  "GURU AMAR DASS PUBLIC SCHOOL",
  "GURU ANGAD DEV PUBLIC SCHOOL",
  "GURU ANGAD PUBLIC SCHOOL",
  "GURU DASMESH ACADEMY",
  "GURU GOBIND SINGH PUBLIC SCHOOL",
  "GURU GOVIND SINGH PUBLIC SCHOOL",
  "GURU HARGOBIND PUBLIC SCHOOL",
  "GURU HARGOBIND SENIOR SECONDARY PUBLIC SCHOOL",
  "GURU HARI KRISHAN PUBLIC SCHOOL",
  "GURU HARKISHAN PUBLIC SCHOOL",
  "GURU HARKRISHAN PUBLIC SCHOOL",
  "GURU KALGIDHAR PUBLIC HIGH SCHOOL",
  "GURU NANAK ACADEMY",
  "GURU NANAK DEV ACADEMY",
  "GURU NANAK DEV DAV PUBLIC SCHOOL",
  "GURU NANAK DEV PUBLIC SCHOOL",
  "GURU NANAK ENGLISH MEDIUM PUBLIC SCHOOL",
  "GURU NANAK ENGLISH SCHOOL",
  "GURU NANAK FOUNDATION PUBLIC SCHOOL",
  "GURU NANAK GIRLS HIGH SCHOOL",
  "GURU NANAK GIRLS SENIOR SECONDARY SCHOOL",
  "GURU NANAK HIGHER SECONDARY SCHOOL",
  "GURU NANAK INTERNATIONAL SCHOOL",
  "GURU NANAK PREM KARAMSAR PUBLIC SCHOOL",
  "GURU NANAK PUBLIC SCHOOL",
  "GURU NANAK PUBLIC SEN. SEC. SCHOOL",
  "GURU NANAK SECONDARY SCHOOL",
  "GURU NANAK SENIOR HIGHER SECONDARY SCHOOL",
  "GURU NANAK SENIOR SECONDARY SCHOOL",
  "GURU NANAK VTH CENTENARY SCHOOL",
  "GURU RAM DASS ACADEMY",
  "GURU RAM DASS PUBLIC MIDDLE SCHOOL",
  "GURU RAM DASS PUBLIC SCHOOL",
  "GURU RAVIDASS HIGH SCHOOL",
  "GURU TEG BAHADUR ACADEMY",
  "GURU TEG BAHADUR PUBLIC SCHOOL",
  "GURU TEGH BAHADUR PUBLIC SCHOOL",
  "GURUGRAM PUBLIC SCHOOL",
  "GURUJI MATRICULATION SCHOOL",
  "GURUKUL INTERNATIONAL SCHOOL",
  "GURUKUL JUNIOR",
  "GURUKUL PUBLIC HIGH SCHOOL",
  "GURUKUL PUBLIC SCHOOL",
  "GURUKUL SCHOOL",
  "GURUKUL VIDYALAYA",
  "GURUKUL WORLD",
  "GURUKUL",
  "GURUKULAM PUBLIC SCHOOL",
  "GURUNANAK INTERNATIONAL PUBLIC SCHOOL",
  "GURUNANAK PUBLIC HIGH SCHOOL",
  "GURUSREE PUBLIC SCHOOL",
  "GUWAHATI PUBLIC SCHOOL",
  "GWALIOR PUBLIC SCHOOL",
  "GWALIOR RAYONS HIGH SCHOOL",
  "GYAANDEEP SHIKSHA BHARTI",
  "GYAN BHARTI PUBLIC SCHOOL",
  "GYAN BHARTI",
  "GYAN DEEP PUBLIC SCHOOL",
  "GYAN DEVI PUBLIC SCHOOL",
  "GYAN DHWAJ SCHOOL",
  "GYAN GANGA INTERNATIONAL SCHOOL",
  "GYAN GANGA PUBLIC SCHOOL",
  "GYAN GANGA VIDYAPEETH",
  "GYAN JYOTI GLOBAL SCHOOL",
  "GYAN KUNJ ACADEMY",
  "GYAN MANDIR PUBLIC SCHOOL",
  "GYAN MATA VIDYA VIHAR",
  "GYAN VATIKA SCHOOL",
  "GYANDEEP BOARDING SCHOOL",
  "GYANDEEP ENGLISH SCHOOL",
  "GYANDEEP PUBLIC SCHOOL",
  "GYANKALASH INTERNATIONAL SCHOOL",
  "GYANODAY S M V M HR. SEC. SCHOOL",
  "GYANODAYA PUBLIC SCHOOL",
  "GYANODAYA VIDYA MANDIR",
  "GYANODAYA VIDYALAYA",
  "GYANSAGAR INTERNATIONAL SCHOOL",
  "GYANSTHALI HIGH SCHOOL",
  "GYANSTHALI PUBLIC SCHOOL",
  "GYUDMED TANTRIC MONASTIC SCHOOL",
  "H D SENIOR SECONDARY PUBLIC SCHOOL",
  "H I M CHRISTIAN ACADEMY",
  "H R GREEN FIELD HIGH SCHOOL",
  "H. A. L SCHOOL",
  "H. S BRAR PUBLIC SCHOOL",
  "H.A.L. GNANAJYOTI SCHOOL",
  "H.A.L.SENIOR SECONDARY SCHOOL",
  "H.C.J. ACADEMY",
  "H.D. PUBLIC SCHOOL",
  "H.G. INTERNATIONAL SCHOOL",
  "H.M. BHAGVATSINHJI HIGH SCHOOL",
  "H.M. DAV PUBLIC SCHOOL",
  "H.M. SENIOR SECONDARY SCHOOL",
  "H.P.S. SR. SEC. SCHOOL",
  "H.R. GREEN FIELD SR. SEC. SCHOOLADDRESS : MAIN BADLI ROAD",
  "H.R.A. INTERNATIONAL SCHOOL",
  "H.R.PUBLIC SCHOOL",
  "H.T.M. HIGH SCHOOL",
  "H.V SCHOOL",
  "H.V.B. ACADEMY",
  "HABRA HIGH SCHOOL",
  "HADI ACADEMY",
  "HAINAULT PUBLIC SCHOOL",
  "HAJI G.U. PATEL HIGH SCHOOL",
  "HAL PUBLIC SCHOOL",
  "HALLELUJAH ENGLISH SCHOOL",
  "HALLMARK PUBLIC SCHOOL",
  "HALLMARK WORLD SCHOOL",
  "HALWASIYA VIDYA VIHAR SENIOR SECONDARY SCHOOL",
  "HAMIRPUR PUBLIC SCHOOL",
  "HANIFA ENGLISH MEDIUM SCHOOL",
  "HANSRAJ MORARJI PUBLIC SCHOOL",
  "HANSRAJ PUBLIC SCHOOL",
  "HANUMANT HIGH SCHOOL",
  "HAPPY BLUE BIRD PUBLIC SCHOOL",
  "HAPPY CONVENT SCHOOL TAPUKARA",
  "HAPPY EVERGREEN SENIOR SECONDRY SCHOOL",
  "HAPPY EVERGREEN SR. SEC. SCHOOL",
  "HAPPY HOME PUBLIC SCHOOL",
  "HAPPY HOME SCHOOL",
  "HAPPY HOME SENIOR SECONDARY SCHOOL",
  "HAPPY HOURS SCHOOL",
  "HAPPY KIDS MONTESSORI SCHOOL",
  "HAPPY SUNSHINE PUBLIC SCHOOL",
  "HAPPY VALLEY PLAY SCHOOL",
  "HAR KRISHAN PUBLIC SCHOOL",
  "HARDAYAL PUBLIC SCHOOL",
  "HARDUTT MEMORIAL PUBLIC SCHOOL",
  "HARE RAMA HARE KRISHNA RESIDENTIAL PUBLIC SCHOOL",
  "HARI OM SHIV OM PUBLIC SCHOOL",
  "HARI SRI VIDYA NIDHI SCHOOL",
  "HARIA SCHOOL BHARATIYA VIDYA BHAVANS",
  "HARICHARAN MAHAMAYA GIRLS HIGH SCHOOL",
  "HARIHARA HIGH SCHOOL",
  "HARIHARANAND SHANTINIKETAN GARDEN PUBLIC SCHOOL",
  "HARITHA BALA KUTEER",
  "HARSH VIDYA MANDIR CONVENT SCHOOL",
  "HARSHA INTERNATIONAL PUBLIC SCHOOL",
  "HARSHINI ENGLISH MEDIUM SCHOOL",
  "HARTMAN GIRLS HIGH SCHOOL",
  "HARTMANN COLLEGE",
  "HARVARD PUBLIC SCHOOL",
  "HARVEST INTERNATIONAL SCHOOL",
  "HARWARD PUBLIC SCHOOL",
  "HARYANA POLICE PUBLIC SCHOOL",
  "HARYANA PUBLIC SCHOOL",
  "HASDEO PUBLIC SCHOOL",
  "HATIA HIGH SCHOOL",
  "HAWKSWORTH VIDYAPITH",
  "HEBRON SCHOOL",
  "HEERA PUBLIC SCHOOL",
  "HEGDE NAGAR PUBLIC SCHOOL",
  "HELEEMA MATRICULATION SCHOOL",
  "HELEN LOWRY HIGHIER SECONDARY SCHOOL",
  "HELLENS SCHOOL",
  "HEMKUNT PUBLIC SCHOOL",
  "HERITAGE INTERNATIONAL SCHOOL",
  "HERITAGE PUBLIC SCHOOL",
  "HERITAGE VALLEY THE INDIAN SCHOOL",
  "HI TECH PUBLIC SCHOOL",
  "HIDAYA PUBLIC SCHOOL",
  "HILDAS PUBLIC SCHOOL",
  "HILL BLOOMS SCHOOL",
  "HILL GROVE COL'S ACADEMY",
  "HILL GROVE PUBLIC SCHOOL",
  "HILL TOP PUBLIC SCHOOL",
  "HILL TOP SCHOOL DALHOUSIE",
  "HILL TOP SCHOOL",
  "HILL VIEW PUBLIC SCHOOL",
  "HILLGROVE SENIOR SECONDARY PUBLIC SCHOOL",
  "HILLOCK INTERNATIONAL SCHOOL",
  "HILLS PUBLIC HIGH SCHOOL",
  "HILLSIDE PUBLIC SCHOOL",
  "HILLVIEW ADVENTIST SCHOOL",
  "HIM ACADEMY PUBLIC SCHOOL",
  "HIM SAI JYOTI PUBLIC SR. SEC. SCHOOL",
  "HIM VALLEY PUBLIC HIGH SCHOOL",
  "HIMALAYA INTERNATIONAL SCHOOL",
  "HIMALAYA PUBLIC SCHOOL",
  "HIMALAYAN ACADEMY",
  "HIMALAYAN INTERNATIONAL RESIDENTIAL SCHOOL",
  "HIMALAYAN INTERNATIONAL SCHOOL",
  "HIMALAYAN PARAMOUNT PUBLIC SCHOOL",
  "HIMALI BOARDING SCHOOL",
  "HIMALI BOARDING SCHOOL, KURSEONG",
  "HIMGIRI PUBLIC SCHOOL",
  "HIMLAND PUBLIC SCHOOL",
  "HIND ZINC SCHOOL",
  "HINDI PUTRI PATHSHALA SENIOR SECONDARY SCHOOL",
  "HINDU PUBLIC SCHOOL",
  "HINDU SENIOR SECONDARY HIGH SCHOOL",
  "HINDU UNION COMMITTEE HIGH SECONDARY SCHOOL",
  "HINDUSTAN INTERNATIONAL ACADEMY",
  "HIRA PUBLIC SCHOOL",
  "HIRANANDANI FOUNDATION SCHOOL",
  "HI-TECH MODERN RESIDENTIAL HIGH SCHOOL",
  "HOLEY HEART PUBLIC SCHOOL",
  "HOLI CHILD SCHOOL",
  "HOLI FATH CONVENT SCHOOL",
  "HOLY ANGEL CONVENT SCHOOL",
  "HOLY ANGEL PUBLIC SCHOOL",
  "HOLY ANGEL'S CONVENT SCHOOL",
  "HOLY ANGELS ENGLISH SCHOOL",
  "HOLY ANGEL'S HIGH SCHOOL",
  "HOLY ANGELS I.S.C. SCHOOL",
  "HOLY ANGELS' ISC SCHOOL",
  "HOLY ANGEL'S MATRICULATION HIGHER SECONDARY SCHOOL",
  "HOLY ANGELS MATRICULATION SCHOOL",
  "HOLY ANGELS MODEL SCHOOL",
  "HOLY ANGELS PUBLIC SCHOOL",
  "HOLY ANGEL'S PUBLIC SCHOOL",
  "HOLY ANGELS RESIDENTIAL SCHOOL",
  "HOLY ANGELS SCHOOL",
  "HOLY ANGELS SENIOR SECONDARY SCHOOL",
  "HOLY CHILD BAL BHARTI SCHOOL",
  "HOLY CHILD CENTRAL SCHOOL",
  "HOLY CHILD CONVENT SCHOOL",
  "HOLY CHILD ENGLISH ACADEMY",
  "HOLY CHILD PUBLIC SCHOOL",
  "HOLY CHILD SCHOOL",
  "HOLY CHILDREN'S PUBLIC SCHOOL",
  "HOLY CROSS ASHRAM SCHOOL",
  "HOLY CROSS CONVENT SCHOOL",
  "HOLY CROSS GIRLS HIGH SCHOOL",
  "HOLY CROSS HIGH SCHOOL",
  "HOLY CROSS HIGHER SECONDARY SCHOOL",
  "HOLY CROSS PUBLIC SCHOOL",
  "HOLY CROSS SCHOOL",
  "HOLY CROSS SENIOR SECONDARY SCHOOL",
  "HOLY CROSS VIDYA SADAN SENIOR SECONDARY SCHOOL",
  "HOLY FACE SCHOOL",
  "HOLY FAMILY CONVENT SCHOOL",
  "HOLY FAMILY PUBLIC SCHOOL",
  "HOLY FAMILY SCHOOL",
  "HOLY GANGES PUBLIC SCHOOL",
  "HOLY HEART HIGH SCHOOL",
  "HOLY HEART PRESIDENCY SCHOOL",
  "HOLY HEART PUBLIC SCHOOL",
  "HOLY HOME PUBLIC SCHOOL",
  "HOLY HOME",
  "HOLY MARY CENTRAL SCHOOL",
  "HOLY MARY PUBLIC SCHOOL",
  "HOLY MISSION HIGH SCHOOL",
  "HOLY MISSION SCHOOL",
  "HOLY MISSION SR. SEC. SCHOOL",
  "HOLY MOTHER PUBLIC SCHOOL",
  "HOLY PUBLIC SCHOOL",
  "HOLY SAI INTERNATIONAL SCHOOL",
  "HOLY SAINT PUBLIC SCHOOL",
  "HOLY TRINITY SCHOOL",
  "HOLY WRIT INTERNATIONAL SCHOOL AND JUNIOR COLLEGE",
  "HON'BLE SHARAD PAWAR PUBLIC SCHOOL",
  "HONGIRANA SCHOOL OF EXCELLENCE",
  "HOPE ACADEMY SCHOOL",
  "HOPETOWN GIRLS' SCHOOL",
  "HORIZON ACADEMY",
  "HORSLEY HILLS SCHOOL",
  "HOWARD PUBLIC SCHOOL",
  "HOWLY HIGH SCHOOL",
  "HOYSALA PUBLIC SCHOOL",
  "HUDA PUBLIC SCHOOL",
  "HUMA PUBLIC SCHOOL",
  "HUME MCHENRY MEMORIAL HIGH SCHOOL OF S.D.A.",
  "HUSSAINI HIGH SCHOOL",
  "HUTCHINGS HIGH SCHOOL",
  "HUTO ENGLISH SCHOOL",
  "HYDERABAD PUBLIC SCHOOL",
  "I E S PUBLIC SCHOOL",
  "I.A.T.S. PUBLIC SCHOOL",
  "I.B. DAV PUBLIC SCHOOL",
  "I.C.L. PUBLIC SCHOOL",
  "I.D.A. SCUDDER SCHOOL",
  "I.G.M. PUBLIC SCHOOL",
  "I.K. JOTE PUBLIC SCHOOL",
  "I.M.N.S. GIRLS HIGHER SECONDARY SCHOOL",
  "I.S.MAROL SCHOOL",
  "I.S.R.A. HIGH SCHOOL",
  "ICHAPUR NORTHLAND HIGH SCHOOL",
  "ICHTHUS ENGLISH MEDIUM SCHOOL",
  "ICON PUBLIC SCHOOL",
  "IDA SCUDDER SCHOOL",
  "IDEAL BHAI SCHOOL",
  "IDEAL CONVENT SCHOOL",
  "IDEAL FOUNDATION SCHOOL",
  "IDEAL PUBLIC SCHOOL",
  "IDHAYAM RAJENDRAN RESIDENTIAL SCHOOL",
  "IDUS INTERNATIONAL TECHNO SCHOOL",
  "IMAM RABBANI PUBLIC SCHOOL",
  "IMMACULATE HEART OF MARY HIGH SCHOOL",
  "IMMACULATE HEART OF MARYS MIDDLE SCHOOL",
  "IMMACULATE NURSURY SCHOOL",
  "IMPERIAL PUBLIC SCHOOL",
  "INDEPENDENT JUNIOR COLLEGE OF SCIENCE AND VOCATIONAL COURSES RURAL INSTITUTE",
  "INDIA FIRST FOUNDATION SCHOOL",
  "INDIA INTERNATIONAL SCHOOL",
  "INDIAN BLOSSOMS INTERNATIONAL SCHOOL",
  "INDIAN HERITAGE SCHOOL",
  "INDIAN ICONS TALENT SCHOOL",
  "INDIAN MODEL SCHOOL",
  "INDIAN NATIONAL PUBLIC SCHOOL",
  "INDIAN NATIONAL SCHOOL",
  "INDIAN PUBLIC SCHOOL",
  "INDIRA ACADEMY",
  "INDIRA ENGLISH MEDIUM SCHOOL",
  "INDIRA GANDHI GIRLS HIGH SCHOOL",
  "INDIRA GANDHI MEMORIAL PUBLIC SCHOOL",
  "INDIRA PUBLIC SCHOOL",
  "INDIRAPURAM PUBLIC SCHOOL",
  "INDO AMERICAN PUBLIC SCHOOL",
  "INDO ENGLISH SCHOOL",
  "INDO GERMAN PUBLIC SCHOOL",
  "INDO PUBLIC SCHOOL",
  "INDORE PUBLIC SCHOOL",
  "INDRA MODEL SCHOOL",
  "INDRAPRASTHA PUBLIC SCHOOL",
  "INDUR PRIMARY SCHOOL",
  "INDUS INTERNATIONAL SCHOOL",
  "INDUS PUBLIC SCHOOL",
  "INDUS VALLEY PUBLIC SCHOOL",
  "INDUS WORLD SCHOOL",
  "INFANAT JESUS PUBLIC SCHOOL",
  "INFANT JESUS ANGLO - INDIAN HIGHER SECONDARY SCHOOL",
  "INFANT JESUS CONVENT SCHOOL",
  "INFANT JESUS COTTAGE SCHOOL",
  "INFANT JESUS ENGLISH MEDIUM SCHOOL",
  "INFANT JESUS INTERNATIONAL SCHOOL",
  "INFANT JESUS MATRICULATION HIGHER SECONDARY SCHOOL",
  "INFANT JESUS PUBLIC SCHOOL",
  "INFANT JESUS RESIDENTIAL SCHOOL",
  "INFANT JESUS SCHOOL",
  "INFOTRACE INTERNATIONAL",
  "INGRAHAM INSTITUTE ENGLISH MEDIUM SCHOOL",
  "INGRAHAM INSTITUTE ENGLISH SCHOOL",
  "INNISFREE HOUSE SCHOOL",
  "INNOCENT HEARTS SCHOOL",
  "INSPIRATION PUBLIC SCHOOL",
  "INTEGRATED PUBLIC SCHOOL",
  "INTEGRATED RURAL ENGLISH MEDIUM SCHOOL",
  "INTER NATIONAL SCHOOL",
  "INTERNATIONAL ACADEMY",
  "INTERNATIONAL BHARTI SCHOOL",
  "INTERNATIONAL FATEH ACADEMY",
  "INTERNATIONAL GOOD BIRD SCHOOL",
  "INTERNATIONAL MATHEMATICS & SCIENCE ACADEMY,",
  "INTERNATIONAL PUBLIC SCHOOL",
  "INTERNATIONAL SAHAJA PUBLIC SCHOOL",
  "INTERNATIONAL SCHOOL AAMBY",
  "INTERNATIONAL SCHOOL OF HYDERABAD",
  "INTERNATIONAL SCHOOL OF SCHOLARS",
  "INTERNATIONAL SCHOOL",
  "IQRA MODEL SCHOOL",
  "IQRA PUBLIC RESIDENTIAL SCHOOL",
  "IQRA PUBLIC SCHOOL",
  "IRA INTERNATIONAL SCHOOL",
  "IRFAN RASOOL MEMORIAL JANTA SCHOOL",
  "IRIS PUBLIC SCHOOL",
  "IRODOV COLLEGE OF SCIENCE",
  "IRSHADIYA PUBLIC SCHOOL",
  "ISHA HOME SCHOOL",
  "ISHAIATH PUBLIC SCHOOL",
  "ISHAN INTERNATIONAL PUBLIC SCHOOL",
  "ISHAQUE PATEL PUBLIC SCHOOL",
  "ISHAR DEVI GUMBER DAV CENTENARY PUBLIC SCHOOL",
  "ISHWAR DEVI DAV PUBLIC SCHOOL",
  "ISLAMIC INTERNATIONAL SCHOOL",
  "ISPAT E. M. SCHOOL",
  "ISPAT E.M. SCHOOL",
  "ISPAT ENGLISH MEDIUM SCHOOL",
  "ITI CENTRAL SCHOOL",
  "ITL PUBLIC SCHOOL",
  "IVY LEAGUEACADEMY,",
  "J B MONTERSARY SCHOOL",
  "J P PUBLIC SCHOOL",
  "J P VIDYA MANDIR",
  "J S S PUBLIC SCHOOL",
  "J&K SAINIK SCHOOL",
  "J. SIKILE SCHOOL",
  "J.B. MUNICIPAL HINDI SCHOOL",
  "J.B. PETIT HIGH SCHOOL",
  "J.B.C.N. INTERNATIONAL SCHOOL",
  "J.B.M. PUBLIC SCHOOL",
  "J.B.R.PUBLIC SCHOOL",
  "J.C.B.SR.SEC.SCHOOL",
  "J.D. ARYA PUBLIC SCHOOL",
  "J.D. PATEL HIGHER SECONDARY HIGH SCHOOL",
  "J.D. PUBLIC SCHOOL",
  "J.D. SUNRISE PUBLIC SCHOOL",
  "J.E.M. HIGH SCHOOL",
  "J.F.S. MIDDLE SCHOOL",
  "J.G. INTERNATIONAL SCHOOL",
  "J.H. TARAPORE SCHOOL",
  "J.J. PUBLIC SCHOOL",
  "J.K. PUBLIC HIGH SCHOOL",
  "J.K. PUBLIC SCHOOL",
  "J.K.G. INTERNATIONAL SCHOOL",
  "J.K.K. NATTRAJA MATRIC. HR. SEC. SCHOOL",
  "J.L.J. CONCEPT SCHOOL",
  "J.M. INTERNATIONAL SCHOOL",
  "J.M. MODEL MIDDLE SCHOOL",
  "J.M. PUBLIC SCHOOL",
  "J.M.J. ENGLISH MEDIUM SCHOOL",
  "J.M.J. MATRIC HIGHER SECONDARY SCHOOL",
  "J.N. MODERN PUBLIC SCHOOL",
  "J.P. INTERNATIONAL SCHOOL",
  "J.P. ROTARY HIGH SCHOOL",
  "J.P. WORLD SCHOOL",
  "J.P.S. ACADEMY",
  "J.R. CAMBRIDGE SCHOOL",
  "J.S.MEMORIAL SCHOOL",
  "J.S.S. INTERNATIONAL SCHOOL",
  "J.S.S. PUBLIC SCHOOL",
  "J.S.S.PUBLIC SCHOOL",
  "J.V.P. D.A.V. PUBLIC SCHOOL",
  "JACK N JILL A PLAY SCHOOL",
  "JAFRABAD SAGAR PRIMARY SCHOOL",
  "JAGAN NATH JAIN DAV PUBLIC SCHOOL",
  "JAGAN NATH SINGH KANYA VIDYALAYA",
  "JAGAT JYOTI HIGH SCHOOL",
  "JAGDISH BAL MANDIR PUBLIC SCHOOL",
  "JAGDISHWAR PUBLIC SCHOOL",
  "JAGRAN PUBLIC SCHOOL",
  "JAGRITI PUBLIC SCHOOL",
  "JAI BALA JI HIGH SCHOOL",
  "JAI BHARATH MATRICULATION SCHOOL",
  "JAI BHARATI PUBLIC SCHOOL",
  "JAI DEEP PUBLIC SCHOOL",
  "JAI HIND PUBLIC SCHOOL",
  "JAI MANN PUBLIC SCHOOL",
  "JAI MATHA PUBLIC SCHOOL",
  "JAI PUBLIC SCHOOL",
  "JAI RANI PUBLIC SCHOOL",
  "JAI SUBASH PUBLIC SCHOOL",
  "JAIDEEP SECONDARY SCHOOL",
  "JAIGOPAL CARODIA CORPN HIGH SCHOOL",
  "JAIGOPAL GARODIA HINDU VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
  "JAIMATHA ENGLISH MEDIUM SCHOOL",
  "JAIN INTERNATIONAL RESIDENTIAL SCHOOL",
  "JAIN INTERNATIONAL SCHOOL",
  "JAIN PUBLIC SCHOOL",
  "JAINENDRA PUBLIC SCHOOL",
  "JAIPUR PUBLIC SCHOOL",
  "JAIRAT HIGH SCHOOL",
  "JALAHALLI PUBLIC SCHOOL",
  "JALANDHAR MODEL SCHOOL",
  "JALENDRA KUMHAR PUBLIC SCHOOL",
  "JALPAIGURI H.S. SCHOOL",
  "JALPAIGURI ZILLA SCHOOL",
  "JAMA E NAEEMA",
  "JAMA-ATH RESIDENTIAL PUBLIC SCHOOL",
  "JAMES MEMORIAL HIGH SCHOOL",
  "JAMIA ASHRAFIA SCHOOL",
  "JAMIA HASSANIA PUBLIC SCHOOL",
  "JAMIA MILLIA ISLAMIA HIGHER SECONDARY SCHOOL",
  "JAMNAB NARSEE SCHOOL",
  "JAMNABEN HEERA CHAND AMBANI SCHOOL",
  "JAMNABEN HIRACHAND AMBANI SCHOOL",
  "JAMON KIDS SCHOOL",
  "JAMSHEDPUR PUBLIC SCHOOL",
  "JAMUNA MODEL SENIOR SECONDARY SCHOOL",
  "JAN SAHYOG INTER COLLEGE",
  "JANASAKTHI PUBLIC SCHOOL",
  "JANKI DEVI PUBLIC SCHOOL",
  "JANKI VIDYA NIKETAN",
  "JANKIDEVI PUBLIC SCHOOL",
  "JANTA INTER COLLEGE",
  "JANTPUR PUBLIC SCHOOL",
  "JANUS GLOBAL MATRICULATION SCHOOL",
  "JAORA PUBLIC SCHOOL",
  "JASDEV MIDDLE SCHOOL",
  "JASSVER ENGLISH MEDIUM SCHOOL",
  "JASUDBEN M.L. SCHOOL",
  "JASWANT LAL PUBLIC SCHOOL",
  "JATH HIGH SCHOOL",
  "JATH HIGHSCHOOL",
  "JATINDERA GREENFIELD SCHOOL",
  "JAWAHAR NAVODAYA SCHOOL",
  "JAWAHAR NAVODAYA VIDAYALA (JNV)",
  "JAWAHAR NAVODAYA VIDAYALA",
  "JAWAHAR NAVODAYA VIDAYALAYA",
  "JAWAHAR NAVODAYA VIDHYALAYA CALICUT",
  "JAWAHAR NAVODAYA VIDHYALAYA",
  "JAWAHAR NAVODAYA VIDYALAY",
  "JAWAHAR NAVODAYA VIDYALAYA SCHOOL",
  "JAWAHAR NAVODAYA VIDYALAYA SR SEC SCHOOL",
  "JAWAHAR NAVODAYA VIDYALAYA",
  "JAWAHAR NAVODAYA VIDYALAYAS",
  "JAWAHAR NAVODAYA VIDYALAYASA SCHOOL",
  "JAWAHAR NAVODYA VIDYALAYA",
  "JAWAHAR NAVODYA VIDYALYA",
  "JAWAHAR PUBLIC SCHOOL",
  "JAWAHAR PUBLIC SENIOR SECONDARY SCHOOL",
  "JAWAHAR VEDIC PUBLIC SCHOOL",
  "JAY INTERNATIONAL SCHOOL",
  "JAYA JAYA SANKARA INTERNATIONAL SCHOOL",
  "JAYAM VIDHYALAYA MATRIC SCHOOL",
  "JAYANTI PRASAD DAV PUBLIC SCHOOL",
  "JAYASREE PUBLIC SCHOOL",
  "JAYCEE HIGHER SECONDARY SCHOOL",
  "JAYCEES PREPARATORY SCHOOL",
  "JAYCEES PUBLIC SCHOOL",
  "JAYDEEP SINGH JI HIGH SCHOOL",
  "JAYSHREE CHEMICALS SCHOOL",
  "JBM GLOBAL SCHOOL",
  "JCD SENIOR SECONDARY SCHOOL",
  "JEEVA VELU MATRICULATION HIGHER SECONDARY SCHOOL",
  "JEEVAN JYOTHI CONVENT SCHOOL",
  "JEEVAN JYOTHI PUBLIC SCHOOL",
  "JEEVAN JYOTI SR. SEC. SCHOOL",
  "JEEVAN MARG SOPHIA SECONDARY SCHOOL",
  "JEEVANA MATRICULATION SCHOOL",
  "JEEVANA SCHOOL",
  "JEEWAN PUBLIC SCHOOL",
  "JENKINS SCHOOL",
  "JEORI PUBLIC SCHOOL",
  "JESUS & MARY ACADEMY",
  "JESUS & MARY SCHOOL AND COLLEGE",
  "JESUS & MARY SCHOOL",
  "JESUS AND MARY ACADEMY",
  "JESUS AND MARY SECONDARY SCHOOL",
  "JESUS SACRED HEART SCHOOL",
  "JESUS SAVIOURS SCHOOL",
  "JEWAN DEEP PUBLIC SCHOOL",
  "JHALDA GIRLS HIGH SCHOOL",
  "JHAMKU DEVI GIRLS SCHOOL",
  "JHARPADA HIGH SCHOOL",
  "JHUNJHUNU ACADEMY",
  "JIND PUBLIC SCHOOL",
  "JINDAL MODERN SCHOOL",
  "JINDAL SCHOOL",
  "JINESHWAR PUBLIC HIGHER SECONDRY SCHOOL",
  "JINGLE BELL ACADEMY",
  "JINGLE BELL SCHOOL",
  "JINGLE BELLS PUBLIC SCHOOL",
  "JINVANI BHARTI PUBLIC SCHOOL",
  "JITPUR HIGH SCHOOL",
  "JIVA PUBLIC SCHOOL",
  "JIYA LAL MITTAL D.A.V. PUBLIC SCHOOL",
  "JIYA MEMORIAL PUBLIC SCHOOL",
  "JNANA SWEEKAR PUBLIC SCHOOL",
  "JNANA VIKAS PUBLIC SCHOOL",
  "JNANADEEPA SCHOOL",
  "JNANASAROVARA INTERNATIONAL RESIDENTIAL SCHOOL",
  "JNANODAYA CENTRAL SCHOOL",
  "JNK PUBLIC SCHOOL",
  "JNS PUBLIC SCHOOL",
  "JODHA MAL PUBLIC SCHOOL",
  "JOGINDRA CONVENT SCHOOL",
  "JOHARI MALL HIGH SCHOOL",
  "JOHN BOSCO PUBLIC SCHOOL",
  "JOHN F.KENNEDY PUBLIC SCHOOL",
  "JOHN MILTON PUBLIC SCHOOL",
  "JOHN TRINITY SCHOOL",
  "JOHN WESELY CONVENT SR.SEC. SCHOOL",
  "JOHNSON GRAMMAR SCHOOL",
  "JON SIDDARTHA PUBLIC SCHOOL",
  "JOSEPH AND MARY PUBLIC SCHOOL",
  "JOSEPHINE ALPHONSA CONVENT",
  "JOSEPHS PUBLIC SCHOOL",
  "JOSEPH'S SCHOOL",
  "JOTHI MATRICULATION HIGHER SECONDARY SCHOOL",
  "JOTHI VALLAR HIGHER SECONDARY SCHOOL",
  "JOWAI PUBLIC SCHOOL",
  "JOY AND CHAMPION PUBLIC SCHOOL",
  "JOY SENIOR SECONDARY SCHOOL",
  "JOYBELLS SCHOOL",
  "JPJ DAV PUBLIC SCHOOL",
  "JUBILEE ENGLISH SCHOOL",
  "JUBILEE HILLS PUBLIC SCHOOL",
  "JUBILEE INTERNATIONAL PUBLIC SCHOOL",
  "JUBILEE MEMORIAL SCHOOL",
  "JULIAN DAY SCHOOL (NADIA)",
  "JUPITER +2 WOMEN'S SCIENCE COLLEGE",
  "JUPITER HIGH SCHOOL",
  "JWAHAR NVODAYA VIDYALAYA",
  "JYOTHI ENGLISH MEDIUM SCHOOL",
  "JYOTHI HIGH SCHOOL",
  "JYOTHI PUBLIC SCHOOL",
  "JYOTI ENGLISH MEDIUM HIGH SCHOOL",
  "JYOTI ENGLISH MEDIUM SCHOOL",
  "JYOTI MODEL SCHOOL",
  "JYOTI NIKETAN SCHOOL",
  "JYOTI PUBLIC SCHOOL",
  "JYOTI SEC. SCHOOL",
  "JYOTI VIDYALAYA",
  "JYOTIRMAY INTERNATIONAL SCHOOL",
  "JYOTIRMOY PUBLIC SCHOOL",
  "K C M PUBLIC HIGH SCHOOL",
  "K C PUBLIC SCHOOL",
  "K D B PUBLIC SCHOOL",
  "K K PUBLIC SCHOOL",
  "K L ARYA D A V PUBLIC SCHOOL",
  "K L M INTERNATIONAL SCHOOL",
  "K L MEHTA DAYANAND PUBLIC S S SCHOOL",
  "K P PUBLIC SCHOOL",
  "K V PUBLIC SCHOOL",
  "K. JOHN PUBLIC SCHOOL",
  "K.B.T. PUBLIC SCHOOL",
  "K.C. INTERNATIONAL SCHOOL",
  "K.C. PUBLIC SCHOOL",
  "K.C.L. PUBLIC SCHOOL",
  "K.C.M SCHOOL",
  "K.C.P. SIDDHARTHA ADARSH RESIDENTIAL PUBLIC SCHOOL",
  "K.D. FIELD PUBLIC SCHOOL",
  "K.D. INTERNATIONAL SCHOOL",
  "K.E. CARMEL SCHOOL",
  "K.G. PUBLIC MATRIC HIGHER SECONDARY SCHOOL",
  "K.G.S. METRIC SCHOOL",
  "K.G.S. PUBLIC SCHOOL",
  "K.J. SOMAIYA ENGLISH MEDIUM SCHOOL",
  "K.K. CAMBRIDGE INTERNATIONAL SCHOOL",
  "K.K. PUBLIC SCHOOL",
  "K.L. INTERNATIONAL SCHOOL",
  "K.L.G. PUBLIC SCHOOL",
  "K.L.M. INTERNATIONAL SCHOOL",
  "K.L.R. JUNIOR AND VOCATIONAL COLLEGE",
  "K.L.R. TECHNO KIDS SCHOOL AND HIGH SCHOOL",
  "K.M PUBLIC SCHOOL",
  "K.M. PUBLIC SCHOOL",
  "K.M.B. ENGLISH HIGH SCHOOL",
  "K.M.O. HIGHER SECONDARY SCHOOL",
  "K.M.S.M. SAI PUBLIC SCHOOL",
  "K.N. INTERNATIONAL SCHOOL",
  "K.N.K. PUBLIC SCHOOL",
  "K.N.R. CENTRAL SCHOOL",
  "K.P.R. AND J.L. SIDDHARTHA HIGHT SCHOOL",
  "K.P.R. AND J.L. SIDDHARTHA JR. COLLEGE",
  "K.S. ABDULLAH ENGLISH MED. SCHOOL",
  "K.S. PUBLIC SCHOOL",
  "K.S. REDDY MODEL SCHOOL",
  "K.S.M. PUBLIC SCHOOL",
  "K.S.R. AKSHARA ACADEMY",
  "K.V.G. INTERNATIONAL PUBLIC SCHOOL",
  "K.V.M. PUBLIC SCHOOL",
  "K.V.S.R.T. JUNIOR COLLEGE",
  "KAANGER VALLEY ACADEMY",
  "KACHHWA CHRISTIAN SCHOOL",
  "KADAMBINI SHISHU VIHAR H S SCHOOL",
  "KAGHAZNAGAR PUBLIC SCHOOL",
  "KAHLWAN HARCHOWAL SCHOOL",
  "KAILASH DEVI MEMORIAL PUBLIC SCHOOL",
  "KAILASH VIDYA VIHAR",
  "KAINAT INTERNATIONAL SCHOOL",
  "KAINTAL SCHOOL",
  "KAKATIYA PUBLIC SCHOOL",
  "KAKATIYA WOMEN'S JUNIOR COLLEGE",
  "KAKO HILL SCHOOL",
  "KALA BHAVAN TALENT RESIDENTIAL SCHOOL",
  "KALA NIKETAN INTERNATIONAL SCHOOL",
  "KALAIMAGAL MATRICULATION HIGHER SECONDARY SCHOOL",
  "KALASALINGAM MODEL MATRICULATION HIGH SCHOOL",
  "KALAWATI VIDYA BHARTI PUBLIC SCHOOL",
  "KALGIDHAR PUBLIC SCHOOL",
  "KALI RAM D.A.V. PUBLIC SCHOOL",
  "KALIAPANI HIGH SCHOOL",
  "KALIBARI HIGH SCHOOL",
  "KALIDAS MONTESSORI SENIOR SECONDARY SCHOOL",
  "KALINDI PUBLIC SCHOOL",
  "KALIRAM CHANDRAKAR PUBLIC SCHOOL",
  "KALKA PUBLIC SCHOOL",
  "KALLIVAYALIL PAPPAN MEMORIAL SCHOOL",
  "KALPA SCHOOL",
  "KALPAVRUKSHA MODEL SCHOOL",
  "KALYAN BAL BIHAR",
  "KALYAN CREATIVE PUBLIC SCHOOL",
  "KALYAN PUBLIC SCHOOL",
  "KALYANI CENTRA MODEL SCHOOL",
  "KALYANI PUBLIC SCHOOL",
  "KALYANI UNIVERSITY EXPERIMENTAL HIGH SCHOOL",
  "KAMAKOTI VIDYALAYA",
  "KAMAL MEMORIAL PUBLIC SCHOOL",
  "KAMALA JUNIOR COLLEGE",
  "KAMANI FORWARD HIGH SCHOOL",
  "KAMARAJ ENG MED SCHOOL",
  "KAMARAJ ENGLISH MED. SCHOOL",
  "KAMARAJ HR. SEC. SCHOOL",
  "KAMARAJAR VIDYASALAI MATRICULATION SCHOOL",
  "KAMARBANDHA HIGHER SECONDARY SCHOOL",
  "KAMLA INTERNATIONAL PUBLIC SCHOOL",
  "KAMLA MONTESSORI SCHOOL",
  "KAMLA NEHRU HIGHER SECONDARY SCHOOL",
  "KAMLA NEHRU INSTITUTE OF CHILD EDUCATION",
  "KAMLA RANI SANGHI PUBLIC SCHOOL",
  "KAMLA SAKLECHA",
  "KANAKRAJ SAWANTRAJ LODHA PUBLIC SCHOOL",
  "KANAN DEVI MEMORIAL SCHOOL",
  "KANCHRAPARA ENGLISH MEDIUM SCHOOL",
  "KANDHASAMY KANDAR HIGHER SECONDARY SCHOOL",
  "KANDY PUBLIC SCHOOL",
  "KANGAROO KIDS AND BILLABONG HIGH INTERNATIONAL SCHOOL",
  "KANGAROO KIDS",
  "KANHA MAKHAN PUBLIC SCHOOL",
  "KANJIRAMKULAM PANCHAYATH HIGH SCHOOL",
  "KANNA ENGLISH MEDIUM SCHOOL",
  "KANNADA CONVENT HIGH SCHOOL",
  "KANPUR PUBLIC SCHOOL",
  "KANTABANJI LIONS PUBLIC SCHOOL",
  "KANVA PUBLIC SCHOOL",
  "KANYA GURUKUL HIGH SCHOOL",
  "KANYAKUBJA PUBLIC SCHOOL",
  "KANYAKUMARI PUBLIC SCHOOL",
  "KAPOL VIDYANIDHI INTERNATIONAL SCHOOL",
  "KAPURTHALA PUBLIC HIGH SCHOOL",
  "KARA PRESCHOOL AND DAYCARE CENTRES",
  "KARAM DEVI MEMORIAL ACADEMY INTERNATIONAL SCHOOL",
  "KARAN PUBLIC SCHOOL",
  "KARAUMBIAH'S ACADEMY FOR LEARNING AND SPORTS",
  "KARL HUBER SCHOOL",
  "KARNAL PUBLIC SCHOOL",
  "KARNATAKA NATIONAL ENGLISH SCHOOL",
  "KARNATAKA NAVACHETHANA BLIND SCHOOL",
  "KARNAVATI PUBLIC SCHOOL",
  "KARNI BAL MANDIR SECONDERY SCHOOL",
  "KARTHIKEYA PUBLIC SCHOOL",
  "KARUR SARASWATI VIDHYALAYA",
  "KASAULI INTERNATIONAL PUBLIC SCHOOL",
  "KASHI BRIGHT ANGEL'S SCHOOL",
  "KASHINATHA PALOD PUBLIC SCHOOL",
  "KASTURBA PUBLIC SCHOOL",
  "KASTURI RAM INTERNATIONAL SCHOOL",
  "KASTURI RAM MANIDEEP ASHRAM",
  "KAUMARAM SUSHILA INTERNATIONAL RESIDENTIAL SCHOOLGANAPATHY",
  "KAUTILYA PUBLIC SCHOOL",
  "KAVERI GIRLS HIGH SCHOOL",
  "KAY JAY MODEL HIGH SCHOOL",
  "KDB PUBLIC SCHOOL",
  "KEJIA PUBLIC SCHOOL",
  "KELLET HIGHER SECONDARY SCHOOL",
  "KEMPEGOWDA RESIDENTIAL SCHOOL",
  "KEMPFORT PUBLIC SCHOOL",
  "KENBRIDGE SCHOOL",
  "KENDERIYA VIDYALAYA (HAFLONG)",
  "KENDRIYA VDYALAYA",
  "KENDRIYA VIDHYALAYA",
  "KENDRIYA VIDYALAYA BORJHAR",
  "KENDRIYA VIDYALAYA",
  "KENNEDY HIGH SCHOOL",
  "KENNEDY INTERNATIONAL RESIDENTIAL SCHOOL",
  "KENNEDY PUBLIC SCHOOL",
  "KENNEDY RESIDENTIAL SCHOOLS",
  "KENNETH GEORGE ENGLISH SCHOOL",
  "KENWOOD PUBLIC SCHOOL",
  "KERALA PUBLIC SCHOOL",
  "KERALA SAMAJAM MODEL SCHOOL",
  "KESWICK PUBLIC SCHOOL",
  "KEWALRAM CHANRAI PUBLIC SCHOOL",
  "KHADIJATUL KUBRA GIRLS PUBLIC SCHOOL",
  "KHAGRA GURUDAS TARASUNDARI INSTITUTION",
  "KHAITAN PUBLIC SCHOOL",
  "KHALSA COLLEGE PUBLIC SCHOOL",
  "KHALSA GIRLS HIGH SCHOOL",
  "KHALSA HIGHER SECONDARY SCHOOL",
  "KHALSA MONTESSORI SCHOOL",
  "KHALSA NATIONAL PUBLIC SCHOOL",
  "KHALSA PUBLIC SCHOOL",
  "KHANDELWAL CENTRAL ACADEMY",
  "KIDDIE KOOP HIGH SCHOOL",
  "KIDDY'S SCHOOL OF EXCELLENCE",
  "KIDS CLUB SCHOOL",
  "KIDS DELHI PUBLIC SCHOOL",
  "KIDS FOUNDATION SCHOOL",
  "KIDS KINGDOM RESIDENTIAL SCHOOL",
  "KIDS POINT",
  "KIDSVILLA KINDERGARTEN SCHOOL",
  "KIDZEE INTERGRATED PUBLIC SCHOOL",
  "KIDZEE PLAY SCHOOL",
  "KIDZEE",
  "KIDZONE INTERNATIONAL",
  "KIIT INTERNATIONAL SCHOOL",
  "KIMMINS HIGH SCHOOL",
  "KINDER SCHOLARS INTERNATIONAL PRE-SCHOOL",
  "KINDERSTAND PLAY SCHOOL",
  "KINDLE PUBLIC SCHOOL",
  "KING GEORGE HIGH SCHOOL",
  "KING GEORGE INTER COLLEGE",
  "KINGCUP PUBLIC SCHOOL",
  "KINGS ENGLISH SCHOOL",
  "KIRAN MEMORIAL PUBLIC SCHOOL",
  "KIRAN PUBLIC SCHOOL",
  "KIRPAL SAGAR ACADEMY",
  "KIRUBAI PUBLIC SCHOOL",
  "KISHOR BAL NIKETAN UPPER PRIMARY SCHOOL",
  "KISHOR SURYAWANSHI INTERNATIONAL SCHOOL",
  "KITTUR RANI CHANNAMMA RESIDENTIAL SCHOOL FOR GIRLS",
  "KLES ENGLISH MEDIUM SCHOOL",
  "KLINE MEMORIAL SCHOOL",
  "KNOWLEDGE VALLEY INTERNATIONAL SCHOOL",
  "KODAIKANAL INTERNATIONAL SCHOOL",
  "KODAIKANAL PUBLIC SCHOOL",
  "KODERMA HIGH SCHOOL",
  "KOLHAPUR PUBLIC SCHOOL",
  "KOLPING MATRICULATION HIGHER SECONDARY SCHOOL",
  "KONARK PUBLIC SCHOOL",
  "KONCEPT PUBLIC SCHOOL",
  "KONGU NATIONAL MATRICULATION HIGHER SECONDARY SCHOOL",
  "KONGU VELLALAR MATRIC HIGHER SECONDARY SCHOOL",
  "KONGUNADU HIGHER SECONDARY SCHOOL",
  "KONNAGAR HINDU GIRLS HIGH SCHOOL",
  "KOORMANCHAL ACADEMY",
  "KORAN SARAI HIGH SCHOOL",
  "KORAN SARAI MADHYA VIDYALAYA",
  "KOTAGIRI PUBLIC SCHOOL",
  "KOTAK SALESIAN SCHOOL",
  "KOTHARI INTERNATIONAL SCHOOL",
  "KOUSTUV INSTITUTES OF SCIENCE",
  "KRISHNA DEVI PUBLIC HIGH SCHOOL",
  "KRISHNA INTERNATIONAL SCHOOL",
  "KRISHNA MEMORIAL SCHOOL",
  "KRISHNA PUBLIC SCHOOL",
  "KRISHNA VIDYA HIGH SCHOOL",
  "KRISHNAGAR ACADEMY",
  "KRIYAYOGA ASHRAM BRAHMACHARI SHANKARANANDA",
  "KRUSHNA CHANDRA GIRLS HIGH SCHOOL",
  "KSHITIJ HAPPY SCHOOL",
  "KULDEEP SINGH MEMORIAL PUBLIC SCHOOL",
  "KULLU VALLEY SCHOOL",
  "KULWANT RAI DAV PUBLIC SCHOOL",
  "KUMADHAVATHI RESIDENTIAL CENTRAL SCHOOL(KRCS)",
  "KUMAR CHANDRIKASHRAM VIDYA NIKETAN",
  "KUNDAN VIDYA MANDIR",
  "KUNWAR CONVENT SCHOOL",
  "KURIAKOSE ELIAS ENGLISH MEDIUM SCHOOL",
  "KURINJI HIGHER SECONDARY SCHOOL",
  "KURU OM VIDYALAYA",
  "KUSUMATI CHHANAGHAR HIGH SCHOOL",
  "L C R T PUBLIC SCHOOL",
  "L R SETIA D A V SENIOR SECONDARY MODEL SCHOOL",
  "L.B.S. PUBLIC SCHOOL",
  "L.K. INTERNATIONAL SCHOOL",
  "L.K. SINGHANIA EDUCATION CENTRE",
  "L.M.G.HIGH SCHOOL",
  "L.M.S. HIGH SCHOOL",
  "L.N. PUBLIC SCHOOL",
  "L.N.T. GLOBAL SCHOOL",
  "L.P. SAVANI INTERNATIONAL",
  "L.T.R. PUBLIC SCHOOL",
  "LA FOUNDATION SCHOOL",
  "LA MARTINIERE COLLEGE",
  "LA MARTINIERE GIRLS COLLEGE",
  "LA. FOUNDATION SCHOOL",
  "LABAN BENGALI BOYS HIGHER SECONDARY SCHOOL",
  "LABOUR GURUKULAM JUNIOR COLLEGE & PUBLIC SCHOOL",
  "LABOUR INDIA PUBLIC SCHOOL",
  "LADAKH RIGJUNG PUBLIC SCHOOL",
  "LADY KHATUN MARIUM SCHOOL",
  "LADY NYE HIGHER SECONDARY SCHOOL",
  "LADY VLANKANNI ENGLISH SCHOOL",
  "LAHORIA HIGH SCHOOL",
  "LAJPAT PUBLIC SCHOOL",
  "LAJPAT RAI D.A.V.PUBLIC SCHOOL",
  "LAJWANTI MEMORIAL PUBLIC SCHOOL",
  "LAKE MOUNT PUBLIC SCHOOL",
  "LAKES INTERNATIONAL SCHOOL",
  "LAKSHANIKA INTERNATIONAL SCHOOL",
  "LAKSHDHAM HIGH SCHOOL",
  "LAKSHMI CHORDIA MEMORIAL MATRIC. HR. SEC. SCHOOL",
  "LAKSHMI ENGLISH MEDIUM SCHOOL",
  "LAKSHMI ENGLISH SCHOOL",
  "LAKSHMI SCHOOL",
  "LAKSHMIPAT SINGHANIA ACADEMY",
  "LAKSHMIPAT SINGHANIA PUBLIC SCHOOL",
  "LAKSHMIPAT SINGHANIA SCHOOL",
  "LAL BAHADUR SHASTRI SAINIK SECONDARY SCHOOL",
  "LALA ARJAN DAS MEMORIAL D A V PUBLIC SCHOOL",
  "LALA JAGAT NARAIAN DAV MODEL SCHOOL",
  "LALA KAMLAPAT SINGHANIA EDN CENTRE",
  "LALA SARKARU MAL SARVHITKARI VIDYA",
  "LALA SAWAN RAM MEMO PUBLIC SCHOOL",
  "LALAJI MEMORIAL OMEGA INTERNATIONAL SCHOOL",
  "LALITA PUBLIC SCHOOL",
  "LALJI MEHROTRA LIONS SCHOOL",
  "LAMBA PUBLIC SCHOOL",
  "LANCERS PUBLIC SCHOOL",
  "LATE MRS. HOUSABAI JAYPAL MAGDUM PUBLIC HIGH SCHOOL",
  "LATE NARAYANDAS BHAWANDAS CHHABADA MILITARY SCHOOL",
  "LAUREATE PUBLIC SCHOOL",
  "LAUREL'S ENGLISH MEDIUM SCHOOL",
  "LAURELS SCHOOL INTERNATIONAL",
  "LAVKUSH MODEL SCHOOL",
  "LAWRANCE INTERNATIONAL SCHOOL",
  "LAWRENCE AND MAYO PUBLIC SCHOOL",
  "LAWRENCE INTERNATIONAL SCHOOL",
  "LAWRENCE PUBLIC SCHOOL",
  "LAWRENCE PUBLIC SENIOR SECONDARY SCHOOL",
  "LAWRENCE SCHOOL",
  "LAXMAN PUBLIC SCHOOL",
  "LAXMI GLOBAL SCHOOL",
  "LAXMI INTERNATIONAL SCHOOL",
  "LAXMI PUBLIC SCHOOL",
  "LAXMI PUBLIC SR. SEC. SCHOOL",
  "LE MER ENGLISH MEDIUM PUBLIC SCHOOL",
  "LEAD WELL PUBLIC SCHOOL",
  "LEADS PUBLIC SCHOOL",
  "L'ECOLE CHEMPAKA SILVER ROCKS",
  "LEELAWATI PUBLIC SCHOOL",
  "LEMMON WORLD SCHOOL",
  "LEONARD HIGHER SECONDARY SCHOOL",
  "LESDING SENIOR SECONDARY SCHOOL",
  "LHL CBSE SCHOOL",
  "LIEVENS ACADEMY",
  "LIFE LINE PUBLIC SCHOOL",
  "LILAWATI CONVENT SCHOOL",
  "LILIANES HIGH SCHOOL",
  "LILLIPUT ISLAND NURSERY SCHOOL",
  "LILLIPUT SEC SCHOOL SUMERPUR",
  "LILY LITTLE FLOWER HIGH SCHOOL",
  "LIONS BAL VIDYA MANDIR",
  "LIONS CLUB ENGLISH MEDIUM HIGH SCHOOL",
  "LIONS CONVENT H.S. SCHOOL",
  "LIONS D.A.V. PUBLIC SCHOOL",
  "LIONS DEAF DUMB HANDICAPPED SCHOOL",
  "LIONS PUBLIC SCHOOL",
  "LION'S PUBLIC SCHOOL",
  "LIONS SCHOOL",
  "LITTLE ANGEL CO-ED SCHOOL",
  "LITTLE ANGEL SCHOOL",
  "LITTLE ANGELS ENGLISH HIGHER SECONDARY SCHOOL",
  "LITTLE ANGELS' ENGLISH HIGHER SECONDARY SCHOOL",
  "LITTLE ANGELS ENGLISH MEDIUM NURSERY SCHOOL",
  "LITTLE ANGELS HIGH SCHOOL",
  "LITTLE ANGELS PUBLIC SCHOOL",
  "LITTLE ANGELS SCHOOL",
  "LITTLE ANGLES ENGLISH SCHOOL",
  "LITTLE BLOSSOMS SCHOOL",
  "LITTLE BUDS HIGH SCHOOL",
  "LITTLE BUDS PUBLIC SCHOOL",
  "LITTLE FAIRY PUBLIC SCHOOL",
  "LITTLE FLOWER CONVENT SCHOOL",
  "LITTLE FLOWER ENGLISH MEDIUM SCHOOL",
  "LITTLE FLOWER HIGHER SECONDARY SCHOOL",
  "LITTLE FLOWER HR.SEC.SCHOOL",
  "LITTLE FLOWER NURSERY SCHOOL",
  "LITTLE FLOWER PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "LITTLE FLOWER PUBLIC SCHOOL",
  "LITTLE FLOWER SCHOOL",
  "LITTLE FLOWER VIDYANIKETAN",
  "LITTLE FLOWER VISITATIAN CONVENT SCHOOL",
  "LITTLE FLOWER'S PUBLIC SENIOR SECONDARY SCHOOL",
  "LITTLE GENIUS PUBLIC SCHOOL",
  "LITTLE HEARTS PUBLIC SCHOOL",
  "LITTLE KINGDOM SCHOOL",
  "LITTLE LILLIES SCHOOL",
  "LITTLE ROCK INDIAN SCHOOL",
  "LITTLE ROSE HIGHER SEC SCHOOL",
  "LITTLE ROSE SCHOOL",
  "LITTLE STAR HIGH SCHOOL",
  "LITTLE STAR HIGHER SECONDARY SCHOOL",
  "LITTLE STAR MODEL SCHOOL",
  "LITTLE STAR PLAY SCHOOL",
  "LITTLE STAR SHAILESH MEMORIAL SCHOOL",
  "LITTLE WONDERS CONVENT SCHOOL",
  "LITTLE WONDERS PUBLIC SCHOOL",
  "LOHIA NAGAR MOUNT CARMEL HIGH SCHOOL",
  "LOK PURAM PUBLIC SCHOOL",
  "LOK VIDYALAYA",
  "LOKPARA HIGH SCHOOL",
  "LONG VIEW PUBLIC SCHOOL",
  "LORD JESUS PUBLIC SCHOOL",
  "LORD KRISHNA INTERNATIONAL SCHOOL",
  "LORD KRISHNA PUBLIC SCHOOL",
  "LORD MAHAVIR JAIN PUBLIC SCHOOL",
  "LORD MAHAVIRA ACADEMY",
  "LORD SHIVA HIGH SCHOOL",
  "LORD SHIVA MODEL SCHOOL",
  "LORD'S ACADEMY",
  "LORETO CONVENT INTERMEDIATE COLLEGE",
  "LORETO CONVENT SCHOOL",
  "LORETO ENGLISH SCHOOL",
  "LORVEN PUBLIC SCHOOL",
  "LOTUS AN. VENKATACHALAM CHETTIAR SCHOOL",
  "LOTUS NATIONAL SCHOOL",
  "LOTUS PLAY SCHOOL",
  "LOTUS VALLEY INTERNATIONAL SCHOOL",
  "LOURDE MATHA HIGH SCHOOL",
  "LOURDE MOUNT SCHOOL",
  "LOURDES GIRLS HIGH SCHOOL",
  "LOURDES PUBLIC SCHOOL",
  "LOURDS MOUNT PUBLIC SCHOOL",
  "LOURDU ENGLISH HIGHER PRIMARY SCHOOL",
  "LOVE DALE CENTRAL SCHOOL",
  "LOVEDALE SR. SCHOOL",
  "LOVELY BUDS PUBLIC SCHOOL",
  "LOVELY ROSE PUBLIC SCHOOL",
  "LOWER SYARI SECONDARY SCHOOL",
  "LOWRY MEMORIAL HIGH SCHOOL",
  "LOYAL PUBLIC SCHOOL",
  "LOYOLA CONVENT SCHOOL",
  "LOYOLA HIGH SCHOOL",
  "LOYOLA INTERNATIONAL RESIDENTIAL SCHOOL",
  "LOYOLA PUBLIC SCHOOL",
  "LOYOLA PUBLIC SCHOOL,",
  "LOYOLA SCHOOL",
  "LR PUBLIC SCHOOL",
  "LT. ATUL KATARYA MEMORIAL SCHOOL",
  "LUCKNOW PUBLIC COLLEGIATE",
  "LUCKNOW PUBLIC SCHOOL",
  "LUCKY BAL NIKETAN SENIOR SECONDARY SCHOOL",
  "LUCKY CENTRAL ACADEMY SECONDARY SCHOOL, THABUKARA",
  "LUCKY CENTRAL ACADEMY SR.SECONDARY SCHOOL",
  "LUCKY PUBLIC SCHOOL",
  "LUDARS CONVENT SCHOOL",
  "LUEDOLF HIGH SCHOOL",
  "LUVDALE SENIOR SCHOOL",
  "LUXMI PUBLIC SCHOOL",
  "M D N PUBLIC SCHOOL",
  "M E S SCHOOL",
  "M G PUBLIC SCHOOL",
  "M G SCHOOL FOR EXCELLENCE",
  "M K D DAV PUBLIC SCHOOL",
  "M R A D.A.V.PUBLIC SCHOOL",
  "M S JUNIOR HIGH SCHOOL GHUTARA BEWAR MAINPURI",
  "M. J. ACTIVITY HIGH SCHOOL",
  "M.A.F. ACADEMY",
  "M.A.S.D. PUBLIC SCHOOL",
  "M.B. INTERNATIONAL SCHOOL",
  "M.B.D. PUBLIC SCHOOL",
  "M.B.S. PUBLIC SCHOOL",
  "M.C. SINDHI PANCHAYAT SR. SEC. SCHOOL",
  "M.C.M. KOTHARI INTERNATIONAL GIRL'S RESIDENTIAL SCHOOL",
  "M.D. DAYANAND MODEL SCHOOL",
  "M.D. MODEL SCHOOL",
  "M.D.H. INTERNATIONAL SCHOOL",
  "M.D.K. ARYA SR. SEC. PUBLIC SCHOOL",
  "M.D.S. PUBLIC SCHOOL",
  "M.D.SAMATHA HIGHSCHOOL KOLHAPUR",
  "M.E.C. PUBLIC SCHOOL",
  "M.E.S. KISHORE KENDRA PUBLIC SCHOOL",
  "M.E.S. PUBLIC SCHOOL",
  "M.E.S. RAJA RESIDENTIAL SCHOOL AND JUNIOR COLLEGE",
  "M.E.S. SCHOOL",
  "M.E.S. UDYOGAMANDAL SCHOOL",
  "M.E.T. PUBLIC SCHOOL",
  "M.G. CONVENT SCHOOL",
  "M.G.D. GIRLS' PUBLIC SCHOOL",
  "M.G.M. ENGLISH MEDIUM H.S. SCHOOL",
  "M.G.M. HIGHER SECONDARY SCHOOL",
  "M.G.M. PUBLIC SCHOOL",
  "M.G.M. SCHOOL",
  "M.G.MEMORIAL H.S SCHOOL",
  "M.G.N. GIRLS SENIOR SECONDARY SCHOOL",
  "M.G.N. PUBLIC SCHOOL",
  "M.H.R. SR. SEC. SCHOOL",
  "M.I.A. DAV PUBLIC SCHOOL",
  "M.I.T. INTERNATIONAL SCHOOL",
  "M.K. DAV PUBLIC SCHOOL",
  "M.K. PUBLIC SCHOOL",
  "M.K.H. SANCHETI PUBLIC SCHOOL",
  "M.K.R. AYYA NADAR JEYALAKSHMI AMMAL ENGLISH MEDIUM SCHOOL",
  "M.L.B. GIRLS HIGHER SECONDARY SCHOOL",
  "M.M. INTERNATIONAL SCHOOL",
  "M.M. PUBLIC SCHOOL",
  "M.M. SENIOR SECONDARY PUBLIC SCHOOL",
  "M.N. ABDUL RAHMAN HIGHER SECONDARY SCHOOL",
  "M.P. MEMORIAL PUBLIC SCHOOL",
  "M.R. CITI PUBLIC SCHOOL",
  "M.R. SENIOR SECONDARY SCHOOL",
  "M.R.C. PUBLIC SCHOOL",
  "M.R.C.R. PUBLIC SCHOOL",
  "M.S PUBLIC SCHOOL GHUTARA BEWAR MAINPURI",
  "M.S. ADARSH PUBLIC SCHOOL",
  "M.S. D.A.V. PUBLIC SCHOOL",
  "M.S. MODEL SCHOOL",
  "M.S. PUBLIC SCHOOL",
  "M.S. SARASWATI SENIOR SECONDARY SCHOOL",
  "M.S. SARASWATI SR. SEC. SCHOOL",
  "M.S.B. EDUCATIONAL INSTITUTE",
  "M.S.D. SPRINGBELLS PUBLIC SCHOOL",
  "M.S.V. PUBLIC SCHOOL",
  "M.U. COLLEGE",
  "M.V. RANA VINAY MANDIR",
  "MA DURGAJI VIDYALAYA",
  "MA SARADA PUBLIC SCHOOL",
  "MAA ANJANI PUBLIC SCHOOL",
  "MAA BHARANDI HIGH SCHOOL",
  "MAA BHARTI PUBLIC SCHOOL",
  "MAA BHARTI SCHOOL",
  "MAA BHARTI SENIOR SECONDARY SCHOOL",
  "MAA DURGA MODERN PUBLIC SCHOOL",
  "MAA SARASWATI MODEL SCHOOL",
  "MAA SARASWATI SISHU MANDIR",
  "MAA SHARDA PUBLIC SCHOOL",
  "MACAROON STUDENT'S ACADEMY",
  "MACRO VISION ACADEMY",
  "MADANAPALLE RESIDENTIAL SCHOOL",
  "MADARSA POINT PUBLIC SCHOOL",
  "MADHABPUR MIDDLE ENGLISH SCHOOL",
  "MADHAPAR PATEL ENGLISH SCHOOL",
  "MADHAVA KRIPA HIGH SCHOOL",
  "MADHU SUDAN PUBLIC SCHOOL",
  "MADHUBAN PUBLIC SCHOOL",
  "MADHUBAN VATIKA PUBLIC SCHOOL",
  "MADHUSTHALI VIDYAPEETH",
  "MADHYA VIDYALAYA KORAN SARAI",
  "MADINA PUBLIC SCHOOL",
  "MADNAWATI PUBLIC SCHOOL",
  "MADONA PUBLIC SCHOOL",
  "MADONNA ENGLISH SCHOOL",
  "MADRAS MATRICULATION HIGHER SECONDARY SCHOOL",
  "MADRESA TAHERIYA PRIMARY SCHOOL",
  "MADURA PUBLIC SCHOOL",
  "MAGANBHAI UMEDBHAI PATEL TECHNICAL HIGH SCHOOL",
  "MAGARPATTA CITY PUBLIC SCHOOL",
  "MAGDALENE MATRICULATION SCHOOL",
  "MAHABIRI SARASWATI VIDYA MANDIR",
  "MAHABODHI RESIDENTIAL SCHOOL",
  "MAHADEV DESAI PUBLIC SCHOOL",
  "MAHAJANA PUBLIC SCHOOL",
  "MAHALA RESIDENTIAL PUBLIC SCHOOL",
  "MAHAMAYA VIHAR PUBLIC SCHOOL",
  "MAHAPRAGYA PUBLIC SCHOOL",
  "MAHARAJA AGARSAIN HIGH SCHOOL",
  "MAHARAJA AGARSAIN PUBLIC SCHOOL",
  "MAHARAJA AGARSEN PUBLIC SCHOOL",
  "MAHARAJA AGGARSAIN ADARSH PUBLIC SCHOOL",
  "MAHARAJA AGGARSAIN PUBLIC SCHOOL",
  "MAHARAJA AGRASEN PUBLIC SCHOOL",
  "MAHARAJA INTERNATIONAL SCHOOL",
  "MAHARAJA METRIC HR. SEC. SCHOOL",
  "MAHARAJA PUBLIC SCHOOL",
  "MAHARAJA RANJIT SINGH POLICE PUBLIC SCHOOL",
  "MAHARAJA RANJIT SINGH PUBLIC SCHOOL",
  "MAHARAJA SAWAI BHAWANI SINGH SCHOOL",
  "MAHARAJA SAWAI MAN SINGH VIDYALAYA",
  "MAHARAJA SINGH INTER COLLEGE",
  "MAHARANA MEWAR PUBLIC SCHOOL",
  "MAHARANA PRATAP PUBLIC SCHOOL",
  "MAHARANI GAYATRI DEVI GIRLS' PUBLIC SCHOOL",
  "MAHARANI GAYATRI DEVI PUBLIC SCHOOL",
  "MAHARANI LAXMI BAI MEMORIAL INTER COLLEGE",
  "MAHARANI USHA RANI HIGH SCHOOL",
  "MAHARISHI DAYANAND PUBLIC SCHOOL",
  "MAHARISHI GYAN MANDIR",
  "MAHARISHI PUBLIC SCHOOL",
  "MAHARISHI VIDHYA MANDIR SENIOR SECONDARY SCHOOL",
  "MAHARISHI VIDYA MANDIR PUBLIC SCHOOL",
  "MAHARISHI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
  "MAHARISHI VIDYA MANDIR SR. SEC. SCHOOL",
  "MAHARISHI VIDYA MANDIR",
  "MAHARSHI ARVIND VIDYA MANDIR",
  "MAHARSHI DAYANAND PUBLIC SCHOOL",
  "MAHARSHI PUBLIC SCHOOL",
  "MAHARSHI VIDYA MANDIR",
  "MAHATAMA GANDHI INTERNATIONAL SCHOOL",
  "MAHATAMA JYOTI RAO PHULE PUBLIC SCHOOL",
  "MAHATMA BUDH NATIONAL PUBLIC SCHOOL",
  "MAHATMA EDUCATION SOCIETY'S HOC INTERNATIONAL SCHOOL",
  "MAHATMA GANDHI CONVENT HIGHER SECONDARY SCHOOL",
  "MAHATMA GANDHI INTERNATIONAL SCHOOL",
  "MAHATMA GANDHI PUBLIC SCHOOL",
  "MAHATMA GANDHI SENIOR SECONDARY SCHOOL",
  "MAHATMA HANSRAJ MODERN SCHOOL",
  "MAHATMA HANSRAJ PUBLIC SCHOOL",
  "MAHATMA JYOTI RAO PUBLIC SCHOOL",
  "MAHATMA LAXMANDAS SCHOOL",
  "MAHATMA TALENT PUBLIC SCHOOL",
  "MAHAVEER JAIN HIGHER SECONDARY SCHOOL",
  "MAHAVEER PUBLIC SCHOOL",
  "MAHAVIR SR. MODEL SCHOOL",
  "MAHENDRA MATRICULATION HIGHER SECONDARY SCHOOL",
  "MAHERWADA ANUPAM PRIMARY SCHOOL",
  "MAHESH PUBLIC SCHOOL",
  "MAHESHTALA HIGH SCHOOL",
  "MAHESHWARI GIRLS PUBLIC SCHOOL",
  "MAHESHWARI PUBLIC SCHOOL",
  "MAHILA ADHYAPAN MANDIR",
  "MAHINDRA MATRICULATIONHIGH SCHOOL",
  "MAHINDRA PUBLIC SCHOOL",
  "MAHINDRA WORLD SCHOOL",
  "MAHIPUR HIGH SCHOOL",
  "MAHITA PUBLIC SCHOOL",
  "MAHLARA PUBLIC SCHOOL",
  "MAJ. R N KAPOOR DAV PUBLIC SCHOOL",
  "MALAVI DEVI DAYANAND MODEL SCHOOL",
  "MALDA RAILWAY HIGH SCHOOL",
  "MALIK DEENAR PUBLIC SCHOOL",
  "MALLYA ADITI INTERNATIONAL SCHOOL",
  "MALTI SIKSHA SANSTHAN",
  "MALVI DEVI DAYANAND MODEL SCHOOL",
  "MALVIYA PUBLIC SCHOOL",
  "MALWA INTERNATIONAL PUBLIC SCHOOL",
  "MALWA SCHOOL",
  "MAMATA PUBLIC SCHOOL",
  "MAMTA HIGH SCHOOL",
  "MAMTA NIKETAN CONVENT SCHOOL",
  "MANA PUBLIC SECONDARY SCHOOL",
  "MANAK LAO PUBLIC SCHOOL",
  "MANAS CONVENT SCHOOL",
  "MANAS INTERNATIONAL SCHOOL",
  "MANASA ENGLISH MEDIUM HIGH SCHOOL",
  "MANASAROVAR PRIYANKA PUBLIC SEC. SCHOOL",
  "MANAV MANGAL PUBLIC SCHOOL",
  "MANAV MANGAL SCHOOL",
  "MANAV MANGAL SMART SCHOOL",
  "MANAV MODEL SCHOOL",
  "MANAV PUBLIC SCHOOL",
  "MANAV RACHNA INTERNATIONAL SCHOOL",
  "MANAV SCHOOL",
  "MANAV SEHYOG SCHOOL",
  "MANAVA BHARATI INDIA INTERNATIONAL SCHOOL",
  "MANAVA BHAWAN PUBLIC SCHOOL",
  "MANAVIYAM PUBLIC SCHOOL",
  "MANCHESTER INTERNATIONAL SCHOOL",
  "MANCHESTER'S PUBLIC SCHOOL",
  "MANDAVYA ENGISH MEDIUM HIGH SCHOOL",
  "MANDINI PUBLIC SCHOOL",
  "MANDOR PUBLIC SCHOOL",
  "MANDSAUR INTERNATIONAL SCHOOL",
  "MANGADU PUBLIC SCHOOL",
  "MANGAIYARKARASIYAR NURSERY AND PRIMARY SCHOOL",
  "MANGALAM MATRICULATION HIGH SCHOOL",
  "MANGLAM D.A.V.PUBLIC SCHOOL",
  "MANICKAM MATRICULATION SCHOOL",
  "MANIKGARH CEMENT ENGLISH SCHOOL",
  "MANILAL MEHTA GIRLS HIGHER SECONDARY SCHOOL",
  "MANIPAL ASNANI PUBLIC SCHOOL",
  "MANIPAL PUBLIC SCHOOL",
  "MANIPAL SHARADA PUBLIC SCHOOL",
  "MANIPUR PUBLIC SCHOOL",
  "MANISHA INTERNATIONAL SCHOOL",
  "MANJEERA CONCEPT SCHOOL",
  "MANJU PRIMARY AND HIGH SCHOOL",
  "MANN PUBLIC SCHOOL",
  "MANNAR MEMORAIL MATRIC SCHOOL",
  "MANOHAR LAL SARAF DAV PUBLIC SCHOOL",
  "MANOVIKAS ENGLISH MEDIUM SCHOOL",
  "MANSAROVAR PUBLIC SCHOOL",
  "MANTHAN INTERNATIONAL SCHOOL",
  "MANTORA PUBLIC SCHOOL",
  "MANU VATIKA DAY BOARDING SCHOOL",
  "MAR ATHANASIUS INTERNATIONAL SCHOOL",
  "MAR BASELIOS PUBLIC SCHOOL",
  "MAR BASELIOUS ENG MEDIUM SCHOOL",
  "MAR GREGORIOS MEMORIAL ENGLISH SCHOOL",
  "MAR GREGORIOUS MEMO SR. SEC. SCHOOL",
  "MAR SEVERIOS ENGLISH SCHOOL",
  "MAR THOMA PUBLIC SCHOOL",
  "MAR THOMA RESIDENTIAL SCHOOL",
  "MARATHI PRIMARY SCHOOL",
  "MARIA AGNES ENGLISH MEDIUM CONVENT SCHOOL",
  "MARIA ASSUMPTA CONVENT SCHOOL",
  "MARIA SCHOOL",
  "MARIA'S PUBLIC SCHOOL",
  "MARIE POUSSEPIN'S ACADEMY",
  "MARIGOLD PUBLIC SCHOOL",
  "MARION VILLA CONVENT I.C.S.E. SCHOOL",
  "MARS PUBLIC SCHOOL",
  "MARSHALL SCHOOL",
  "MARTIN LUTHER PUBLIC SCHOOL",
  "MARUTHI HIGHER SECONDARY SCHOOL",
  "MARUTHI VIDYALAYA",
  "MARUTHUPANDIYAR HIGH SCHOOL",
  "MARUTI ACADEMY",
  "MARUTIRAO KOTE ABHINAV PUBLIC SCHOOL",
  "MARY ANGEL'S PUBLIC SCHOOL",
  "MARY GIRI VIDYA MANDIR",
  "MARY IMMACULATE SCHOOL",
  "MARY MATHA (ICSE) SCHOOL",
  "MARY MATHA ENGLISH MEDIUM SCHOOL",
  "MARY MODEL SCHOOL",
  "MARY MOUNT PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "MARY MOUNT SCHOOL",
  "MARY PUBLIC SCHOOL",
  "MARY WARD E.M. SCHOOL",
  "MARYA NIVAS MATRICULATION HIGHER SECONDARY SCHOOL",
  "MARYGIRI PUBLIC SCHOOL",
  "MASD PUBLIC SCHOOL",
  "MASHRAK HIGH SCHOOL",
  "MASIHI HIGH SCHOOL",
  "MASONIC PUBLIC SCHOOL",
  "MASSEEH DILASA SCHOOL",
  "MASTER TARA SINGH PUBLIC SCHOOL",
  "MASTERY INTERNATIONAL SCHOOL",
  "MATA BAINTI DEVI CONVENT SCHOOL",
  "MATA GUJRI GIRLS' PUBLIC SCHOOL",
  "MATA GUJRI PUBLIC SCHOOL",
  "MATA JAI KAUR PUBLIC SCHOOL",
  "MATA KASHTURI DEVI PUBLIC SCHOOL",
  "MATA KAULAN JI PUBLIC SCHOOL",
  "MATA MARIAM JAN SEVA VIDYALAYA",
  "MATA PUNNA DEVI DAV PUBLIC SCHOOL",
  "MATA RAM PIARI DAV PUBLIC SCHOOL",
  "MATA RUKMANI RAI ARYA GIRLS SEC.SCHOOL",
  "MATA SAHIB KAUR PUBLIC SCHOOL",
  "MATA SAVITRI DEVI SANJEEVANI PUBLIC SCHOOL",
  "MATA SHIV DEVI PUBLIC SCHOOL",
  "MATER DEI INSTITUTION",
  "MATHA NAGAR PUBLIC SCHOOL",
  "MATHEWS MAR ATHANASIUS RESIDENTIAL SCHOOL",
  "MATIWALA PUBLIC SCHOOL",
  "MATLIWALA PUBLIC SCHOOL",
  "MATRIX HIGH SCHOOL",
  "MATRUCHHAYA KANYA VIDYALAYA",
  "MATRUSRI ANGLO VEDIC SCHOOL",
  "MATURSRI ANGLO-VEDIC SCHOOL",
  "MAULANA AZAAD PUBLIC SCHOOL",
  "MAULANA AZAD PUBLIC SCHOOL",
  "MAXFORT SCHOOL",
  "MAYA DEVI GOEL PUBLIC SCHOOL",
  "MAYA DEVI MEMORIAL ADARSH CONVENT SCHOOL",
  "MAYA PUBLIC SENIOR SECONDARY SCHOOL",
  "MAYANK PUBLIC SCHOOL",
  "MAYO COLLEGE GIRLS SCHOOL",
  "MAYO COLLEGE GIRLS' SCHOOL",
  "MAYO COLLEGE",
  "MAYOOR SCHOOL",
  "MAYOR WORLD SCHOOL",
  "MAYUR PUBLIC SCHOOL",
  "MAYURA SCHOOL",
  "MAZZARELLO SCHOOL",
  "MC GUFFEY INTERNATIONAL SCHOOL",
  "MCTM INTERNATIONAL SCHOOL",
  "MD PUBLIC SCHOOL",
  "MECAB MEMORIAL HIGH SCHOOL",
  "MEDHA PUBLIC SCHOOL",
  "MEDICAL COLLEGE PUBLIC SCHOOL",
  "MEDONNA MONTESSORI PUBLIC SCHOOL",
  "MEENAKSHI PUBLIC SCHOOL",
  "MEENAMPARK MATRICULATION SCHOOL",
  "MEERA PUBLIC SCHOOL",
  "MEERUT CITY PUBLIC SCHOOLA",
  "MEERUT PUBLIC GIRLS SCHOOL",
  "MEGA MANIPUR SCHOOL",
  "MEGHALAYA POLICE PUBLIC SCHOOL",
  "MEHTA GURUKUL PUBLIC SCHOOL",
  "MELA DEVI KALRA ARYA SCHOOL",
  "MELMARUVATHUR ADHIPARASAKTHI HIGH SCHOOL",
  "MENNONITE ENGLISH SENIOR SECONDARY SCHOOL",
  "MENTAL RETARDED RESIDENTIAL SCHOOL",
  "MEPKO MODEL HIGH SCHOOL",
  "MERCEDES- BENZ INTERNATIONAL SCHOOL",
  "MERCHANT HIGHER SECONDARY SCHOOL",
  "MERI TO RIOUS PUBLIC SCHOOL",
  "MERIDIAN CONVENT",
  "MERIDIAN SCHOOL",
  "MERRY ANGELS PUBLIC SCHOOL",
  "MERRY CONVENT SCHOOL",
  "MES RAJA RESIDENTIAL SCHOOL",
  "METAS MCD SCHOOL OF SEVENTH-DAY ADVENTISTS",
  "METHODIST SCHOOL",
  "METRO DELHI INTERNATIONAL SCHOOL",
  "METRO PUBLIC SCHOOL",
  "METROPOLITAN SCHOOL",
  "MGM PUBLIC SCHOOL",
  "MGR ADARSH PUBLIC MATRICULATION HIGHER SECONDARY SCHOOL",
  "MIDNAPORE TOWN SCHOOL",
  "MILAN PUBLIC SCHOOL",
  "MILANJYOTI MIDDLE SCHOOL",
  "MILES BRONSON RESIDENTIAL SCHOOL",
  "MILESTONE PUBLIC SCHOOL",
  "MILIND PUBLIC SCHOOL",
  "MILL SID PUBLIC SCHOOL",
  "MILLENIUM PUBLIC SCHOOL",
  "MILTON GRAMMAR ENGLISH MEDIUM SCHOOL",
  "MILTON PUBLIC SCHOOL",
  "MINERVA PUBLIC SCHOOL",
  "MINI LAND PUBLIC SCHOOL",
  "MINTU INTERNATIONAL PUBLIC SCHOOL",
  "MIRANDA ENGLISH MEDIUM SCHOOL",
  "MISSION GIRLS' HIGH SCHOOL",
  "MISSION PRIMARY SCHOOL",
  "MISSION SCHOOL",
  "MITHI GOVINDRAM PUBLIC SCHOOL",
  "MITHILA PUBLIC SCHOOL",
  "MITTAL INTERNATIONAL SCHOOL",
  "MLS DAV PUBLIC SCHOOL",
  "MOBIL PUBLIC SCHOOL",
  "MODEL PUBLIC HIGH SCHOOL",
  "MODEL PUBLIC SCHOOL",
  "MODEL SCHOOL",
  "MODEL STUDY SCHOOL",
  "MODERN ACADMY",
  "MODERN B.P. PUBLIC SCHOOL",
  "MODERN CHILD PUBLIC SCHOOL",
  "MODERN CONVENT SCHOOL",
  "MODERN DELHI PUBLIC SCHOOL",
  "MODERN ENGLISH MEDIUM SCHOOL",
  "MODERN ENGLISH SCHOOL",
  "MODERN ERA PUBLIC SCHOOL",
  "MODERN GYAN DEEP PUBLIC SCHOOL",
  "MODERN MONTESSORI INTERNATIONAL",
  "MODERN NATIONAL PUBLIC SCHOOL",
  "MODERN NEW DELHI PUBLIC SCHOOL",
  "MODERN PUBLIC SCHOOL",
  "MODERN SAINIK SCHOOL",
  "MODERN SANDEEPNI SCHOOL",
  "MODERN SCHOOL",
  "MODERN SENIOR SECONDARY SCHOOL",
  "MODERN VIDYA NIKETAN",
  "MODI INST OF EDUCATIONAL RESEARCH",
  "MODI PUBLIC SCHOOL",
  "MODISH PUBLIC SCHOOL",
  "MODREN DREAMLAND PUBLIC SCHOOL",
  "MODY SCHOOL",
  "MOHANPUR HIGH SCHOOL",
  "MOHTA PUBLIC SCHOOL",
  "MOHYAL PUBLIC SCHOOL",
  "MONA MODERN HR. SEC. SCHOOL",
  "MONAD PUBLIC SCHOOL",
  "MONAL PUBLIC SR. SEC. SCHOOL",
  "MONT FORT HR.SEC.SCHOOL",
  "MONTESSORI HIGH SCHOOL",
  "MONTESSORIE CAMBRIDGE SCHOOL",
  "MONTESSORY MODEL SCHOOL",
  "MONTFORT A.I. HIGHER SECONDARY SCHOOL",
  "MONTFORT HIGHER SECONDARY SCHOOL (CBSE)",
  "MONTFORT SCHOOL",
  "MONTGOMERY GURU NANAK PUBLIC SCHOOL",
  "MOON LIGHT MODAL SCHOOL",
  "MOON LIGHT PUBLIC SCHOOL",
  "MORNING GLORY PUBLIC SCHOOL",
  "MORNING STAR NURSERY AND PRIMARY SCHOOL",
  "MORNING STAR PUBLIC HIGH SCHOOL",
  "MORNING STAR SCHOOL FOR DEAF",
  "MORNING STAR SCHOOL",
  "MOSES NURSERY & PRIMARY SCHOOL",
  "MOTHER ATHENA SCHOOL",
  "MOTHER CHHANKAUR CONVENT SCHOOL",
  "MOTHER DIVINE PUBLIC SCHOOL",
  "MOTHER DURGA PUBLIC SCHOOL",
  "MOTHER HALEENA PUB SCHOOL",
  "MOTHER INDIA INTERNATIONAL RESIDENTIAL PUBLIC SCHOOL",
  "MOTHER INDIA PUBLIC SCHOOL",
  "MOTHER LAND ACADEMY",
  "MOTHER LAND PUBLIC SCHOOL",
  "MOTHER MARY SCHOOL",
  "MOTHER MIRA PUBLIC SCHOOL",
  "MOTHER NORA PUBLIC SCHOOL",
  "MOTHER SCHOLAR PUBLIC SCHOOL",
  "MOTHER TERESA MODERN PUBLIC SCHOOL",
  "MOTHER TERESA SCHOOL",
  "MOTHERLAND ACADEMY",
  "MOTHERLAND PUBLIC SCHOOL",
  "MOTHER'S HOME PUBLIC SCHOOL",
  "MOTHER'S INTERNATIONAL SCHOOL",
  "MOTHERS PET INTERNATIONAL SCHOOL",
  "MOTHERS PUBLIC SCHOOL",
  "MOTHER'S PUBLIC SCHOOL",
  "MOTI RAM ARYA MODERN PUBLIC SCHOOL",
  "MOTILAL NEHRU PUBLIC SCHOOL",
  "MOUNT ABU INTERNATIONAL SCHOOL",
  "MOUNT ABU PUBLIC SCHOOL",
  "MOUNT ASSISI SCHOOL",
  "MOUNT ASSISI SENIOR SCHOOL",
  "MOUNT BETHANY PUBLIC SCHOOL",
  "MOUNT CARMEL CHRISTIAN ACADEMY",
  "MOUNT CARMEL CONVENT ANGLO - INDIAN GIRLS HIGH SCHOOL",
  "MOUNT CARMEL CONVENT HIGH SCHOOL",
  "MOUNT CARMEL CONVENT HIGHER SECONDARY SCHOOL",
  "MOUNT CARMEL HIGH SCHOOL",
  "MOUNT CARMEL SCHOOL",
  "MOUNT DEEPAM MISSION SCHOOL",
  "MOUNT HERMON SCHOOL",
  "MOUNT LITERA ZEE SCHOOL",
  "MOUNT SAINT JOSEPH SCHOOL",
  "MOUNT SEENA PUBLIC SCHOOL",
  "MOUNT SENORIA SCHOOL",
  "MOUNT SINAI SCHOOL",
  "MOUNT ST. PATRICK ACADEMY",
  "MOUNT TABOR RESIDENTIAL CENTRAL SCHOOL",
  "MOUNT VIEW SCHOOL",
  "MOUNT ZION MATRICULATION HIGHER SECONDARY SCHOOL",
  "MOUNTAIN HOME SCHOOL & JUNIOR COLLEGE",
  "MOUTORH MANADA SUNDARI HIGH SCHOOL",
  "MRS SHAILAJA SHINDE TECHNICAL HIGH SCHOOL AND JUNIOR COLLEGE",
  "MRS. MARIADAS U.P. SCHOOL",
  "MT. ZION ENGLISH SCHOOL",
  "MT. ZION RESIDENTIAL SCHOOL",
  "MUJAMMA ENGLISH MEDIUM SCHOOL",
  "MUKAND LAL PUBLIC SCHOOL",
  "MUKARRAM JAH SCHOOL",
  "MUKAT PUBLIC SCHOOL",
  "MUKUNDAPURAM PUBLIC SCHOOL",
  "MULA PUBLIC SCHOOL",
  "MULJIBHAI MEHTA INTERNATIONAL SCHOOL",
  "MUMAL PUBLIC SCHOOL",
  "MUNAM PUBLIC SCHOOL",
  "MUNI MAYARAM JAIN PUBLIC SCHOOL",
  "MUNICIPAL CORPORATION URDU HIGH SCHOOL",
  "MUNICIPAL GIRLS HIGH SCHOOL",
  "MUNICIPAL GIRLS HIGHER SECONDARY SCHOOL",
  "MUNICIPAL HIGH SCHOOL",
  "MUNICIPAL HIGHER SECONDARY SCHOOL",
  "MUNICIPALITY HIGH SCHOOL",
  "MURUGAIYYAN MEMORIAL MODEL HIGHER SECONDARY SCHOOL",
  "MUSSOORIE INTERNATIONAL SCHOOL",
  "MUSSOORIE PUBLIC SCHOOL",
  "MUTHARAIYAR ENGLISH HIGH SCHOOL",
  "MUTHU MATRICULATION SCHOOL",
  "MUTHUKUMARASAMY DEVASTHANAM HINDU HIGHER SECONDARY SCHOOL",
  "MY HOME EDUCATIONAL SOCIETY",
  "MY RICH DAD'S ACADEMY",
  "MYSORE PUBLIC SCHOOL",
  "N S DAV PUBLIC SCHOOL",
  "N V K S HIGHER SECONDARY SCHOOL",
  "N. KRISHNASAMY MUDALIAR HIGHER SCHOOL",
  "N.B.R. MATRICULATION SCHOOL",
  "N.C. MODEL SCHOOL",
  "N.E. RAILWAY SENIOR SECONDARY SCHOOL",
  "N.E.L.C. DON BOSCO SCHOOL",
  "N.E.S. INTERNATIONAL SCHOOL",
  "N.I.I.S. JUNIOR COLLEGE",
  "N.M. JAIN GIRLS SENIOR SECONDARY SCHOOL",
  "N.M. MATRICULATION SCHOOL",
  "N.R. PUBLIC SCHOOL",
  "N.R.I.'S INDIAN SPRINGS SCHOOL",
  "N.S.M. PUBLIC SCHOOL",
  "N.S.S. HILL SPRING INTERNATIONAL SCHOOL",
  "N.S.S. PUBLIC & NURSERY SCHOOL",
  "N.S.S. PUBLIC SCHOOL",
  "N.S.V.MEMORIAL SCHOOL",
  "N.T.P.C. SACHDEVA INTERNATIONAL SCHOOL",
  "N.V. ENGLISH MEDIUM SCHOOL",
  "N.V. VIDYANIKETAN HIGH SCHOOL",
  "N.V.R. HIGH SCHOOL",
  "NACHIKETA PUBLIC SCHOOL",
  "NADAR HIGH SCHOOL",
  "NAG MATRICULATION SCHOOL HIGHER SECONDARY SCHOOL",
  "NAGARJUNA GURUKUL INTERNATIONAL SCHOOL",
  "NAGARJUNA HIGH SCHOOL",
  "NAGARJUNA PUBLIC HIGH SCHOOL",
  "NAGARJUNA PUBLIC SCHOOL",
  "NAGGAJI SARASWATI VIDYA MANDIR",
  "NAIPUNNYA PUBLIC SCHOOL",
  "NALANDA ACADEMY SENIOR SECONDARY SCHOOL",
  "NALANDA GURUKULA INTERNATIONAL SCHOOL",
  "NALANDA INTERNATIONAL SCHOOL",
  "NALANDA PUBLIC HIGH SCHOOL",
  "NALANDA PUBLIC SCHOOL",
  "NALANDA RESIDENTAL SCHOOL",
  "NALWA LOVELY PUBLIC SCHOOL",
  "NALWA PUBLIC SCHOOL",
  "NAMCHI PUBLIC SCHOOL",
  "NAMMA MONTESSORI PUBLIC SHAALE",
  "NANDHA CENTRAL SCHOOL",
  "NANDHA MATRIC HIGHER SECONDARY SCHOOL",
  "NANDI NEXT GEN SCHOOL",
  "NANDINI VIDYANIKETHANA",
  "NANKANA SAHIB PUBLIC SCHOOL",
  "NARAIN PUBLIC SCHOOL",
  "NARAYANA CONCEPT SCHOOL",
  "NARAYANA JUNIOR COLLEGE",
  "NARBHERAM HANSRAJ ENGLISH SCHOOL",
  "NARMADA DEVI SINGHANIA INTERNATIONAL SCHOOL",
  "NARMADA VALLEY ACADEMY",
  "NARMADA VALLEY INTERNATIONAL SCHOOL",
  "NARRAH G.K. GIRLS HIGH SCHOOL",
  "NARSINGH DASS PUBLIC SCHOOL",
  "NARSINGH HIGH SCHOOL",
  "NARVANA HIGH SCHOOL",
  "NASR SCHOOL BOYS",
  "NASR SCHOOL",
  "NATIONAL ACADEMY FOR LEARNING",
  "NATIONAL CONVENT SCHOOL",
  "NATIONAL HILL VIEW MONTESSORI PUBLIC SCHOOL",
  "NATIONAL MODEL SCHOOL",
  "NATIONAL MONTESSORI PUBLIC SCHOOL",
  "NATIONAL PUBLIC ENGLISH SCHOOL",
  "NATIONAL PUBLIC HIGH SCHOOL",
  "NATIONAL PUBLIC SCHOOL KORAMANGALA",
  "NATIONAL PUBLIC SCHOOL",
  "NATIONAL RESIDENTIAL SCHOOL",
  "NATIONAL VICTOR PUBLIC SCHOOL",
  "NATIONAL VIDYALAYA",
  "NAV GIAN DEEP PUBLIC SCHOOL",
  "NAV JEEVAN ACADEMY SENIOR PUBLIC SCHOOL",
  "NAV JEEVAN MISSION SCHOOL",
  "NAV JYOTI PUBLIC SCHOOL",
  "NAV UDAY CONVENT PUBLIC SCHOOL",
  "NAVA BHARAT PUBLIC HIGH SCHOOL",
  "NAVA JEEVAN PUBLIC SCHOOL",
  "NAVA JEEVAN PUBLIC SCHOOL,",
  "NAVA NIRMAN PUBLIC SCHOOL",
  "NAVABHARAT PUBLIC SCHOOL",
  "NAVABHARATHY PUBLIC SCHOOL",
  "NAVAL CHILDREN SCHOOL",
  "NAVAL PUBLIC SCHOOL",
  "NAVAL VIDYA BHARATI UPPER PRIMARY SCHOOL",
  "NAVANAGAR ROTARY ENGLISH MEDIUM HIGH SCHOOL",
  "NAVDEEP PUBLIC SCHOOL",
  "NAVEEN CARRIER HIGH SCHOOL",
  "NAVEEN DABAR PUBLIC SCHOOL",
  "NAVEEN HIGH SCHOOL",
  "NAVJEEVAN AAROGYA SANSTHA",
  "NAVJEEVAN HIGH SCHOOL AND JUNIOR COLLEGE",
  "NAVJEEVAN PUBLIC SCHOOL SOCIETY",
  "NAVJEEVAN PUBLIC SCHOOL",
  "NAVJYOTHI PUBLIC SCHOOL",
  "NAVJYOTI VIDYALAYA",
  "NAVNEETA KUNWAR PUBLIC SCHOOL",
  "NAVNIDH HASSOMAL LAKHANI PUBLIC SCHOOL",
  "NAVODAYA PUBLIC SCHOOL",
  "NAVODAYA VIDYALAYA SAMITI",
  "NAVRACHANA INTERNATIONAL SCHOOL",
  "NAVY CHILDREN SCHOOL",
  "NAVYUG PUBLIC HIGH SCHOOL",
  "NAVYUG PUBLIC SCHOOL",
  "NAVYUG VIDHYALAYA",
  "NAVYUG VIDYALAYA",
  "NAYARHAT HIGH SCHOOL",
  "NAZARETH ACADEMY",
  "NAZARETH HOME ENGLISH MEDIUM SCHOOL",
  "NAZARETH MODEL HIGH SCHOOL",
  "NAZARETH SCHOOL",
  "ND DAV PUBLIC SCHOOL",
  "NECK CHAND SENIOR SECONDARY SCHOOL",
  "NEETA MODERN PUBLIC SCHOOL",
  "NEHRU INTERNATIONAL PUBLIC SCHOOL",
  "NEHRU MATRIC SCHOOL",
  "NEHRU PUBLIC SCHOOL",
  "NELLORE CENTRAL SCHOOL",
  "NEO CONVENT PUBLIC SCHOOL",
  "NES NATIONAL PUBLIC SCHOOL",
  "NESFIELD PUBLIC SCHOOL",
  "NEST CHILDREN SEC. SCHOOL",
  "NEST PUBLIC SCHOOL",
  "NETA JI CENTANARY SCHOOL",
  "NETAJEE DEFENCE ACADEMY",
  "NETAJI MEMORIAL PUBLIC SCHOOL",
  "NETAJI MODEL SCHOOL",
  "NETAJI NURSING SCHOOL",
  "NETAJI PUBLIC SCHOOL",
  "NETAJI SUBASH D.A.V. PUBLIC SCHOOL",
  "NETAJI SUBHASH PUBLIC SCHOOL",
  "NETARHAT RESIDENTIAL SCHOOL",
  "NEUGAL PUBLIC SR. SEC. SCHOOL",
  "NEW - AHILYABAI HOLKAR MADAYAMIC VIDYALAY",
  "NEW ADARSH PUBLIC SCHOOL",
  "NEW ANGEL PUBLIC SCHOOL",
  "NEW APOSTOLIC PUBLIC SCHOOL",
  "NEW ARTS",
  "NEW BALDWIN ENGLISH PRIMARY SCHOOL",
  "NEW BALDWIN INTERNATIONAL RESIDENTIAL SCHOOL",
  "NEW BALDWIN PUBLIC SCHOOL",
  "NEW BHARATH SECONDARY SCHOOL",
  "NEW BISHOP COTTON PUBLIC SCHOOL",
  "NEW BLOSSOM PUBLIC SCHOOL",
  "NEW CAMBRIDGE PUBLIC SCHOOL",
  "NEW CARRIER PUBLIC JUNIOR SCHOOL",
  "NEW CENTRAL PUBLIC SCHOOL",
  "NEW CENTURY PUBLIC SCHOOL",
  "NEW DEHRADUN PUBLIC SCHOOL",
  "NEW DIGAMBAR PUBLIC SCHOOL",
  "NEW ENGLISH SCHOOL",
  "NEW ERA ACADEMY",
  "NEW ERA HIGH SCHOOL",
  "NEW ERA MISSION HIGH SCHOOL",
  "NEW ERA NOBLE SCHOOL",
  "NEW ERA PROGRESSIVE SCHOOL",
  "NEW ERA PUBLIC SCHOOL",
  "NEW ERA SECONDARY SCHOOL",
  "NEW ERAHIGH SCHOOL",
  "NEW GANDHI PUBLIC SCHOOL",
  "NEW GENERATION PUBLIC SCHOOL",
  "NEW GREAT ADIAL PUBLIC SCHOOL",
  "NEW HAPPY PUBLIC SCHOOL",
  "NEW HARISON PUBLIC SCHOOL",
  "NEW HORION PUBLIC SCHOOL",
  "NEW HORIZON PUBLIC SCHOOL",
  "NEW HOWRIGEN PUBLIC SCHOOL",
  "NEW INDIA ENGLISH MEDIUM PUBLIC SCHOOL",
  "NEW INDIA PUBLIC SCHOOL",
  "NEW INDIAN PUBLIC SCHOOL",
  "NEW INDIRA PRIYADARSINI SCHOOL",
  "NEW INDUS VALLEY RESIDENTIAL SCHOOL",
  "NEW JAI BHARTI PUBLIC SCHOOL",
  "NEW KRISHNA PUBLISH SCHOOL",
  "NEW LIFE ENGLISH MEDIUM SCHOOL",
  "NEW LIGHT ACADEMY",
  "NEW LIGHT PUBLIC SCHOOL",
  "NEW LOOK CENTRAL SCHOOL",
  "NEW MILLENNIUM SCHOOL",
  "NEW MILTON PUBLIC SCHOOL",
  "NEW ORANGE CITY CONVENT SCHOOL",
  "NEW OXFORD PUBLIC SCHOOL",
  "NEW PUBLIC ENGLISH SCHOOL",
  "NEW PUBLIC HIGH SCHOOL",
  "NEW PUBLIC SCHOOL",
  "NEW PUBLIC SR. SEC. SCHOOL",
  "NEW RAJASTHAN PUBLIC SCHOOL",
  "NEW RAJASTHAN PUBLIC SHIKSHAN SANSTHAN",
  "NEW SARASWATI PUBLIC SECONDARY SCHOOL",
  "NEW SENIOR SECONDARY SCHOOL",
  "NEW SOUTH WALES PUBLIC SCHOOL",
  "NEW ST STEPHEN'S PUBLIC SCHOOL",
  "NEW ST. SOLDIER SR. SEC. PUBLIC SCHOOL",
  "NEW STEWART SCHOOL",
  "NEW TOWN HALL PUBLIC SCHOOL",
  "NEW VIKAS PUBLIC SCHOOL",
  "NEW VISHNA BHARTI PUBLIC SCHOOL",
  "NEW VISION SCHOOL",
  "NEW WISDOM PUBLIC HIGH SCHOOL",
  "NEW YASHODA PUBLIC SCHOOL",
  "NEWTUNKIDS INTERNATIONAL SCHOOL",
  "NICHIPHU CHILDREN FOUNDATION SCHOOL",
  "NIGHTINGALE SENIOR SECONDARY SCHOOL",
  "NIGHTINGALES ENGLISH SCHOOL",
  "NIKHIL SHYAMA D A V PUBLIC SCHOOL",
  "NIKOS PUBLIC SCHOOL",
  "NIMAR CAMBRIDGE SCHOOL",
  "NIMS INTERNATIONAL SCHOOL",
  "NIMT SCHOOL",
  "NIRAJ PUBLIC SCHOOL",
  "NIRDOSH SCHOOL",
  "NIRMAL ASHRAM DEEPMALA PAGARANI PUBLIC SCHOOL",
  "NIRMAL HIGHER SECONDARY SCHOOL",
  "NIRMAL HRIDAI HIGH SCHOOL",
  "NIRMAL NIKETAN SCHOOL",
  "NIRMAL PUBLIC SCHOOL",
  "NIRMALA CONVENT HIGH SCHOOL",
  "NIRMALA CONVENT SCHOOL",
  "NIRMALA ENGLISH MEDIUM HIGH SCHOOL",
  "NIRMALA ENGLISH SCHOOL",
  "NIRMALA GIRLS' HIGH SCHOOL",
  "NIRMALA HIGH SCHOOL",
  "NIRMALA MATHA CONVENT SCHOOL",
  "NIRMALA MATHA PUBLIC SCHOOL",
  "NIRMALA MIDDLE SCHOOL",
  "NIRMALA PUBLIC SCHOOL",
  "NIRMALA SENIOR SECONDARY SCHOOL",
  "NISHAN PUBLIC SCHOOL",
  "NISHANT PUBLIC SCHOOL",
  "NITTE INTERNATIONAL SCHOOL",
  "NIVASI HIGH SCHOOL",
  "NIZAMIA PUBLIC SCHOOL",
  "NNDM BEERSHEEBA PUBLIC SCHOOL",
  "NOBLE PUBLIC SCHOOL",
  "NOBLE PUBLIC SCHOOL,",
  "NOIDA PUBLIC SCHOOL",
  "NOONMATI PUBLIC SCHOOL",
  "NOOPUR NAAD SAMASTHAN",
  "NOOR HIGH SCHOOL",
  "NOORPUR PUBLIC SCHOOL",
  "NOORUL HUDA ENG MEDIUM SCHOOL",
  "NORTH ACADEMY SCHOOL",
  "NORTH POINT CHILDRENS SCHOOL",
  "NORTH POINT CHILDREN'S SCHOOL",
  "NORTH POINT ENGLISH ACADEMY",
  "NORTH POINT HIGHER SEC SCHOOL",
  "NORTH POINT SCHOOL",
  "NORTHERN INTERNATIONAL SCHOOL",
  "NORTHWICK GIRLS HIGHER SECONDARY SCHOOL",
  "NOSEGAY PUBLIC SCHOOL",
  "NOTRE DAME ACADEMY",
  "NOTRE DAME OF HOLY CROSS SCHOOL",
  "NOTRE DAME SCHOOL",
  "NUCHHUNGI ENGLISH MEDIUM SCHOOL",
  "NURSING RAO DESAI HIGH SCHOOL",
  "NURTURE INTERNATIONAL SCHOOL",
  "NYAYA NAGAR PUBLIC SCHOOL",
  "O D M PUBLIC SCHOOL",
  "O P S INTERNATIOAL SCHOOL",
  "O S D A V PUBLIC SCHOOL",
  "O.N.G.C. PUBLIC SCHOOL",
  "O.P. JINDAL SCHOOL",
  "OAK GOVE SCHOOL",
  "OAKRIDGE INTERNATIONAL SCHOOL",
  "OAKS HIGH SCHOOL",
  "OAKTREE INTERNATIONAL SCHOOL",
  "OASIS INTERNATIONAL SCHOOL",
  "OASIS PUBLIC SCHOOL",
  "OASIS SCHOOL",
  "OBEROI INTERNATIONAL SCHOOL",
  "ODM PUBLIC SCHOOL",
  "OIL VALLEY SCHOOL",
  "OJASWINI SCHOOL",
  "OJU MISSION SCHOOL",
  "OKRABARI ALABAKSH HIGH SCHOOL",
  "OLCOTT MEMORIAL HIGH SCHOOL",
  "OLIVE INTERNATIONAL SCHOOL",
  "OLIVE PUBLIC SCHOOL",
  "OLIVER PUBLIC SCHOOL",
  "OM PARKASH BANSAL MODEL SCHOOL",
  "OM PRABHA JAIN SENIOR MODEL SCHOOL",
  "OM PRAKASH BANSAL MODERN SCHOOL",
  "OM PRAKASH GANAPATI MEMORIAL SCHOOL",
  "OM PUBLIC SCHOOL",
  "OM SHANTHI HIGHER SECONDARY SCHOOL",
  "OMKARANANDA SARASWATI NILAYAM",
  "OMSHREE PUBLIC SCHOOL",
  "OMVEER SHARMA",
  "ONGOLE PUBLIC SCHOOL",
  "ONGOLE PUBLIC SCHOOL,",
  "ONKAR NATH DHAWAN PUBLIC SCHOOL",
  "OPM HIGH SCHOOL",
  "OPTIMUM INTERNATIONAL SCHOOL",
  "OPY WORLD SCHOOL",
  "ORCHID INTERNATIONAL SCHOOL",
  "ORCHID INTERNATIONAL SCOOL",
  "ORCHID INTERNATIONAL SCOOL, NASHIK",
  "ORCHIDS INTERNATIONAL SCHOOL",
  "ORCHIDS PUBLIC SCHOOL",
  "ORCHIDS THE INTERNATIONAL SCHOOL",
  "ORDNANCE FACTORY SCHOOL",
  "ORIENTAL PRIMARY AND HIGH SCHOOL",
  "ORIENTAL PUBLIC SCHOOL",
  "ORION ENGLISH MEDIUM SCHOOL",
  "ORISSA NOBLE SCHOOL",
  "ORISSA PUBLIC SCHOOL",
  "ORLEANCE PUBLIC SCHOOL",
  "OSAP HIGH SCHOOL",
  "OSCAR PUBLIC SCHOOL",
  "OUR LADY OF FATIMA CONVENT SECONDARY SCHOOL",
  "OUR LADY OF FATIMA HIGH SECONDARY SCHOOL",
  "OUR LADY OF MERCY SCHOOL",
  "OUR LADY OF THE SNOWS HIGH SCHOOL",
  "OUR PUBLIC SCHOOL",
  "OXFORD ACADEMY",
  "OXFORD ENGLISH SCHOOL",
  "OXFORD GREEN PUBLIC SCHOOL",
  "OXFORD HIGHER SECONDARY SCHOOL",
  "OXFORD INTERNATIONAL PUBLIC SCHOOL",
  "OXFORD MODERN PUBLIC SCHOOL",
  "OXFORD PRIMARY SCHOOL",
  "OXFORD PUBLIC SCHOOL",
  "OXFORD SCHOOL",
  "OXFORD SENIOR SECONDARY SCHOOL",
  "OXFORD WORLD SCHOOL",
  "OYSTER INTERNATIONAL SCHOOL AND JUNIOR COLLEGE",
  "P D DHANUKA SARASWATI VIDYA MANDIR",
  "P M S PUBLIC SCHOOL",
  "P. OBUL REDDY PUBLIC SCHOOL",
  "P.A. INAMDAR ENGLISH SCHOOL",
  "P.D.M. PUBLIC SCHOOL",
  "P.D.R. VELLACHIMMAL MATRIC HIGHER SECONDARY SCHOOL",
  "P.D.S. PUBLIC SCHOOL",
  "P.G.P. INTERNATIONAL SCHOOL",
  "P.K.R. JAIN PUBLIC SCHOOL",
  "P.K.S. INTERNATIONAL SCHOOL",
  "P.K.S. SARVAJANIK SCHOOL",
  "P.N. PANIKKAR SOUHRUDA HIGH SCHOOL",
  "P.OBUL REDDY PUBLIC SCHOOL",
  "P.P. CONVENT SCHOOL",
  "P.P. INTERNATIONAL SCHOOL",
  "P.R. WASSON PUBLIC SCHOOL",
  "P.S. CHIDAMBARA NADAR SENIOR ENGLIGH SCHOOL",
  "P.S. HIGH SCHOOL",
  "P.S. NURSERY & PRIMARY SCHOOL",
  "P.S.N.PUBLIC SCHOOL",
  "P.T.D.P. MISHRA MEMORIAL PUBLIC SCHOOL",
  "P.V.R. HIGH SCHOOL",
  "PACHIGOLLA SUBBA RAO CREATIVE PUBLIC SCHOOL",
  "PADAMSHREE N N MOHAN GEETA ADARSH SCHOOL",
  "PADMA BINANI DAV PUB SCHOOL",
  "PADMASHREE DR. D.Y. PATIL PUBLIC SCHOOL",
  "PADMAVATHI JUNIOR COLLEGE FOR WOMEN",
  "PADMAVATHI PUBLIC SCHOOL",
  "PADMAVATHI VIDYALAYA",
  "PADMAVATHY PUBLIC SCHOOL",
  "PALADUGU NAGAIAH CHOWDARY PUBLIC SCHOOL (P.N.C)",
  "PALANIAPPA MEMORIAL HIGHER SECONDARY SCHOOL",
  "PALLIKOODAM SCHOOL",
  "PAMPOSH PUBLIC SCHOOL",
  "PANACEA NATIONAL PUBLIC SCHOOL",
  "PANBAI INTERNATIONAL SCHOOL",
  "PANCHAJANYA BRILLIANTS SCHOOL",
  "PANCHAYAT HIGH SCHOOL",
  "PANCHSHEEL GIRLS HIGH SCHOOL",
  "PANDIT MOHAN LAL S.D. PUBLIC SCHOOL",
  "PANINEEYA MAHAVIDAYALAYA PUBLIC SCHOOL",
  "PANJTANIYA ENGLISH HIGHER SECONDARY SCHOOL",
  "PANKAJAM GIRLS HIGH SCHOOL",
  "PANTAPARA MODERN PUBLIC SCHOOL",
  "PARAGON 69 SENIOR SECONDARY SCHOOL",
  "PARAGON SR. SEC. SCHOOL",
  "PARAM GYAN NIKETAN",
  "PARAMITA HIGH SCHOOL",
  "PARAMJYOTI PUBLIC SCHOOL",
  "PARAMJYOTI PUBLIC SCHOOL,",
  "PARAMOUNT ACADEMY",
  "PARAMOUNT PUBLIC SCHOOL",
  "PARAMOUNT SCHOOL",
  "PARAS VIDYA VIHAR",
  "PARASRAMPURIA GEMS INTERNATIONAL SCHOOL",
  "PARASRAMPURIA HIGHER SECONDARY SCHOOL",
  "PARIVARTHAN GURUKUL HERITAGE ENGLISH MEDIUM SCHOOL",
  "PARK GLOBAL SCHOOL",
  "PARKWOOD SCHOOL INTERNATIONAL",
  "PARLE TILAK VIDYALAYA",
  "PARMANAND MALANI ENGLISH SCHOOL",
  "PARMAR INTERNATIONAL SCHOOL",
  "PARMESHWARI DEVI DHANUKA SARASWATI VIDYA MANDIR",
  "PARTAP PUBLIC SCHOOL",
  "PARTAP WORLD SCHOOL",
  "PARTH PUBLIC HIGH SCHOOL",
  "PARTH PUBLIC SCHOOL",
  "PARVARA PUBLIC SCHOOL",
  "PARVATI RADHKISHEN FOMRA SCHOOL",
  "PASAMALAR MIDDLE SCHOOL",
  "PATEL PRE UNIVERSITY COLLEGE",
  "PATEL PUBLIC SCHOOL",
  "PATH FINDER HIGH SCHOOL",
  "PATHANAMTHITTA PUBLIC SCHOOL",
  "PATHANIA PUBLIC SCHOOL",
  "PATHANJALI RES SCHOOL",
  "PATHWAYS WORLD SCHOOL",
  "PATIAPATHAR HIGH SCHOOL",
  "PATKAI CHRISTIAN ACADEMY",
  "PATRA GURUKUL INTERNATIONAL SCHOOL",
  "PATRATU SCHOOL OF ECONOMICS",
  "PATRATU SCHOOL",
  "PATRISWAMY INTERNATIONAL PUBLIC SCHOOL",
  "PAUL INTERNATIONAL SCHOOL",
  "PAVAN CONCEPT SCHOOL",
  "PAWAR PUBLIC SCHOOL",
  "PEACE PUBLIC SCHOOL",
  "PEARL INTERNATIONAL SCHOOL",
  "PEARL MATRIC SCHOOL",
  "PEARL ROSARY SCHOOL AFFILIATED TO WBBSE",
  "PEEPAL GROVE SCHOOL",
  "PEEVEES PUBLIC SCHOOL",
  "PENDEKANTI PUBLIC SCHOOL",
  "PENGUIN CASTLE PRE SCHOOL",
  "PENGUIN KIDS INTERNATIONAL SCHOOL",
  "PENGUINS KIDS",
  "PENTECOSTAL CHRISTIAN PUBLIC SCHOOL",
  "PERIYAR HIGHER SECONDARY SCHOOL",
  "PERIYAR MEMORIAL SCHOOL",
  "PETIT SEMINAIRE HIGHER SECONDARY SCHOOL",
  "PHOENIX PUBLIC RESIDENTIAL SCHOOL",
  "PHOENIX PUBLIC SCHOOL",
  "PHOENIX RESIDENTIAL PUBLIC SCHOOL",
  "PHONIX PUBLIC SCHOOL",
  "PHUL MOTI MEMORIAL UNIVERSAL SMART",
  "PINE GROVE PUBLIC SCHOOL",
  "PINE MOUNT SCHOOL",
  "PINE WOOD SCHOOL",
  "PINEGROVE SCHOOL",
  "PINEWOOD INTERNATIONAL SENIOR SECONDARY SCHOOL",
  "PINEWOOD RESIDENTIAL SCHOOL",
  "PINEWOODS INTERNATIONAL HIGH SCHOOL",
  "PINK MODEL SECONDARY SCHOOL",
  "PINK MODEL SENIOR SECONDARY SCHOOL",
  "PINKCITY PUBLIC SCHOOL",
  "PINNACLE HIGH INTERNATIONAL SCHOOL",
  "PIONEER MONTESSORI SCHOOL",
  "PIONEER PUBLIC SCHOOL",
  "PIONEER SCHOOL",
  "PIPS WORLD SCHOOL",
  "PLATINUM JUBILEE HIGH SCHOOL",
  "PODAR INTERNATIONAL SCHOOL",
  "PODAR JUMBO KIDS PLAY SCHOOL",
  "PODAR WORLD SCHOOL",
  "POLE STAR PUBLIC SCHOOL",
  "POLICE D.A.V. PUBLIC SCHOOL",
  "POLICE PUBLIC SCHOOL",
  "POLKAJI SHIKSHAN SANSTHAN",
  "PONCOS HIGHER SECONDARY SCHOOL",
  "POOJA INTERNATIONAL TECHNO SCHOOL",
  "POOJA MODERN PUBLIC SCHOOL",
  "POOJA PUBLIC SCHOOL",
  "POORNA PRAJNA PUBLIC SCHOOL",
  "POTTAVAYAL HIGH SCHOOL",
  "PRABHARANI PUBLIC SCHOOL",
  "PRABHAT PUBLIC SCHOOL",
  "PRABHAT RESIDENTIAL PUBLIC SCHOOL",
  "PRABHAT TARA SCHOOL",
  "PRABHATH PUBLIC SCHOOL",
  "PRABHUJEE ENGLISH MEDIUM SCHOOL",
  "PRACHARYA JETWAN INTER COLLEGE",
  "PRAGATHI PUBLIC SCHOOL",
  "PRAGATHI RESIDENTIAL AND PUBLIC SCHOOL",
  "PRAGATI ASHRAM HIGH SCHOOL",
  "PRAGATI LITTLE PUBLIC SCHOOL",
  "PRAGATI PUBLIC SCHOOL",
  "PRAGATI SCHOOL",
  "PRAGATO PUBLIC SCHOOL",
  "PRAGMA PUBLIC SCHOOL",
  "PRAGNA HIGH SCHOOL",
  "PRAGYA VIDYA VIHAR PUBLIC SCHOOL",
  "PRAGYAN PUBLIC SCHOOL",
  "PRAKASH PUBLIC SCHOOL",
  "PRAKASH VIDYALAYA",
  "PRAKRIYA GREEN WISDOM SCHOOL",
  "PRANAVA THE SCHOOL",
  "PRANAVANANDA VIDYA MNANDIR",
  "PRANJALI INTERNATIONAL SCHOOL",
  "PRARTHNA VIDYALAYA",
  "PRATAP PUBLIC SCHOOL",
  "PRATAP SASAN BOYS HIGH SCHOOL",
  "PRATHIBA HIGH SCHOOL",
  "PRATHIBHA INTERNATIONAL SHOOL",
  "PRATHIBHA PUBLIC SCHOOL",
  "PRATIBHA PALLAVAN PUBLIC SCHOOL",
  "PRATIBHA PUBLIC SCHOOL",
  "PRATIBHA VIKAS PUBLIC SCHOOL",
  "PRATIK SCIENCE COLLEGE",
  "PRAYAAG INTERNATIONAL SCHOOL",
  "PRAYAG MONTESSORI",
  "PREET PUBLIC SCHOOL",
  "PREETHI ENGLISH MEDIUM SCHOOL",
  "PRELUDE PUBLIC SCHOOL",
  "PREM BAHADUR SINGH BHADHA PUBLIC SCHOOL",
  "PREM JOT SENIOR SECONDARY SCHOOL",
  "PREMA VIJAYAN HIGH SCHOOL",
  "PREMIER INTERNATIONAL SCHOOL",
  "PREMIER PUBLIC SCHOOL",
  "PRERANA PUBLIC SECONDARY SCHOOL",
  "PRERNA CONVENT HIGH SCHOOL",
  "PRERNA KIRAN PUBLIC SCHOOL",
  "PRERNA PUBLIC SCHOOL",
  "PRESENTATION CONVENT HIGHER PRIMARY SCHOOL",
  "PRESENTATION GIRL'S HIGH SCHOOL",
  "PRESHITHAMATHA SCHOOL",
  "PRESIDENCY CENTRAL SCHOOL",
  "PRESIDENCY GIRLS HIGHER SECONDARY SCHOOL",
  "PRESIDENCY HIGH SCHOOL",
  "PRESIDENCY SCHOOL",
  "PRESIDENCY THE INTERNATIONAL SCHOOL",
  "PRESTIGE PUBLIC SCHOOL",
  "PRICELESS PEARL SCHOLAR'S ACADEMY",
  "PRIDE ENGLISH SCHOOL",
  "PRIME ACADEMY",
  "PRIMROSE ENGLISH MEDIUM HIGH SCHOOL",
  "PRIMROSE SCHOOL",
  "PRIMUS PUBLIC SCHOOL",
  "PRIN ROTARY CENTRAL SCHOOL",
  "PRINCE AND PUBLIC SCHOOL",
  "PRINCE ENGLISH SCHOOL",
  "PRINCE INTERNATIONAL SCHOOL",
  "PRINCE PUBLIC SCHOOL",
  "PRINCESS ESIN GIRLS' SCHOOL",
  "PRINCETON PUBLIC SCHOOL",
  "PRISTINE CHILDREN HIGH SCHOOL",
  "PRISTINE PUBLIC SCHOOL",
  "PRITA LEE LESSON SCHOOL",
  "PRIYA DARSHINI PUBLIC SCHOOL",
  "PRIYADARSHINI PUBLIC SCHOOL",
  "PRIYAM NURSERY AND PRIMARY SCHOOL",
  "PRIYANKA MODERN SCHOOL",
  "PRODIGY PUBLIC SCHOOL",
  "PROGRESS PUBLIC SCHOOL",
  "PROGRESSIVE EDUCATION (II) SCHOOL",
  "PROGRESSIVE PUBLIC SCHOOL",
  "PSSEMR SCHOOL",
  "PT. D.P. MISHRA MEMORIAL PUBLIC SCHOOL",
  "PT. RAMKINKAR UPADHYAY VIDYAPEETH",
  "PT.UMA DUTT PUBLIC SCHOOL",
  "PUBLIC SCHOOL",
  "PUBLIC SCHOOL,",
  "PUNE CAMBRIDGE PUBLIC SCHOOL",
  "PUNE INTERNATIONAL SCHOOL",
  "PUNEET PUBLIC SCHOOL",
  "PUNJAB CHILDREN ACADEMY",
  "PUNJAB CONVENT SCHOOL",
  "PUNJAB INTERNATIONAL PUBLIC SCHOOL",
  "PUNJAB INTERNATIONAL SCHOOL",
  "PUNJAB PUBLIC SCHOOL",
  "PUPIL TREE SCHOOL",
  "PUPILS OWN HIGH SCHOOL",
  "PURAN CHANDRA VIDHYA NIKETAN",
  "PURAN SASAN UP.ME SCHOOL",
  "PURULIA MUNICIPAL MANAGED HIGH SCHOOL",
  "PURULIA ZILLA SCHOOL",
  "PUSA PUBLIC SENIOR SECONDARY GIRLS SCHOOL",
  "PUSHP VIDYA MANDIR SCHOOL",
  "PUSHPA CONVENT HIGHER SECONDARY SCHOOL",
  "PUSHPA MIDDLE SCHOOL",
  "PUSHPAGIRI CONCEPT SCHOOL",
  "PUSHPAGIRI RESIDENTIAL PUBLIC SCHOOL",
  "PUSHPANJALI PUBLIC SCHOOL",
  "PUTIMARI HIGH SCHOOL",
  "Q.I.S. HIGH SCHOOL",
  "QUEEN MARY SCHOOL",
  "QUEEN MARYS SCHOOL",
  "QUEEN MARY'S SCHOOL",
  "QUEEN MIRA INTERNATIONAL SCHOOL",
  "QUEEN OF ANGELS' CONVENT HIGH SCHOOL",
  "QUEEN OF ANGELS' CONVENT HIGHER SECONDARY SCHOOL",
  "QUEEN'S PUBLIC SENIOR SECONDARY SCHOOL",
  "QUENIE SECONDARY SCHOOL",
  "R A N PUBLIC SCHOOL",
  "R D. B.DAV SR. SEC. PUBLIC SCHOOL",
  "R J S INTERNATIONAL SCHOOL",
  "R K S PUBLIC SCHOOL",
  "R M M DAV PUBLIC SCHOOL",
  "R P S PUBLIC SCHOOL",
  "R P S RESIDENTIAL PUBLIC SCHOOL",
  "R R K SCHOOL",
  "R.A.J.S. DAV PUBLIC SCHOOL",
  "R.B. GURUKUL",
  "R.B.K. INTERNATIONAL ACADEMY",
  "R.B.K. SCHOOL",
  "R.C. GREEN FIELD SR. SEC. SCHOOL",
  "R.C.C.E. PUBLIC SCHOOL",
  "R.C.M. ST. THOMAS HIGH SCHOOL",
  "R.D.M. SARASWATI PUBLIC SCHOOL",
  "R.I.M.S. INTERNATIONAL SCHOOL AND JUNIOR COLLEGE",
  "R.J.S. INTERNATIONAL SCHOOL",
  "R.K. PUBLIC SCHOOL",
  "R.K.S. ENGLISH MIDDLE SCHOOL",
  "R.K.S.D. PUBLIC SCHOOL",
  "R.M.P.HIGHER SECONDARY SCHOOL BUDGAM KASHMIR",
  "R.M.S. INTERNATIONAL SCHOOL",
  "R.N.S. VIDYANIKETAN",
  "R.P. MODEL PUBLIC SCHOOL",
  "R.R. MORARKA PUBLIC SCHOOL",
  "R.R. SENIOR SECONDARY PUBLIC SCHOOL",
  "R.R.C.M. SR. SEC. SCHOOL",
  "R.S. PUBLIC SENIOR SECONDARY SCHOOL",
  "R.S.D. ACADEMY PUBLIC SCHOOL",
  "R.S.D. RAJ RATTAN PUBLIC SCHOOL",
  "R.T. NAGAR PUBLIC SCHOOL",
  "R.V. PRIMARY SCHOOL",
  "R.V. PUBLIC SCHOOL",
  "R.V.BHIDE INSTITUTE FOR HEARING HANDICAPPED",
  "R.V.G. HIGHER SECONDARY SCHOOL",
  "R.V.S. ACADEMY",
  "R.V.S.R. CENTRAL SCHOOL",
  "RAAK INTERNATIONAL SCHOOL",
  "RAATH INTERNATIONAL SCHOOL",
  "RABINDRA SENIOR SECONDARY SCHOOL",
  "RADCLIFFE SCHOOL",
  "RADHA GOVIND PUBLIC SCHOOL",
  "RADHA KRISHNA SENIOR SECONDARY PUBLIC SCHOOL",
  "RADHA MADHAV PUBLIC SCHOOL",
  "RADHA SAOMI ENGLISH SCHOOL",
  "RADHA VATIKA SENIOR SECONDARY SCHOOL",
  "RADIANT INTERNATIONAL SCHOOL",
  "RADIANT PUBLIC SCHOOL",
  "RADIANT STARS ENGLISH SCHOOL",
  "RADIENT CENTRAL CHILDREN ACADEMY",
  "RADIENT ROSES PUBLIC SCHOOL",
  "RAEKOT PUBLIC SCHOOL",
  "RAFFLES INTERNATIONAL SCHOOL",
  "RAGENDRA SWARUP PUBLIC SCHOOL",
  "RAGHUKUL DEFENCE SCHOOL",
  "RAGHUKUL VIDYA PEETH",
  "RAHMAT NAGAR PUBLIC SCHOOL",
  "RAHUL INTERNATIONAL SCHOOL",
  "RAHUL MEMORIAL PUBLIC SCHOOL",
  "RAHUL MODEL PUBLIC SCHOOL",
  "RAHUL VIGNAN VIDYALAYAM",
  "RAI SCHOOL",
  "RAIGAD MILITARY SCHOOL AND JUNIOR COLLEGE OF SCIENCE",
  "RAILBOW INTERNATIONAL SCHOOL",
  "RAILWAY BOYS HIGH SCHOOL",
  "RAILWAY COLONY MUNICIPAL HIGHER SECONDARY SCHOOL",
  "RAINBOW INTERNATIONAL SCHOOL",
  "RAINBOW PUBLIC SCHOOL",
  "RAINBOW RESIDENTIAL PUBLIC SCHOOL",
  "RAJ KARNI GALHOTRA D.A.V. PUBLIC SCHOOL",
  "RAJ PUBLIC SCHOOL",
  "RAJA DALIP SINGH MEMORIAL PUBLIC SCHOOL",
  "RAJA PUBLIC SCHOOL",
  "RAJA RAM MOHAN ROY ACADEMY CAMBRIDGE SCHOOL",
  "RAJA RAM MOHAN ROY ACADEMY",
  "RAJABHAU BONDRE MUNICIPAL HIGH SCHOOL",
  "RAJAGIRI CHRISTU JAYANTHI PUBLIC SCHOOL",
  "RAJAGIRI PUBLIC SCHOOL",
  "RAJARSHI PUBLIC SCHOOL",
  "RAJASTHAN GIRLS PUBLIC SCHOOL",
  "RAJBAI PREMJIBHAI BHAVANI VIDYA MANDIR",
  "RAJDHANI PUBLIC SCHOOL",
  "RAJEEV GANDHI NAVODAYA VIDYALAYA",
  "RAJEEV GANDHI NAVODYA VIDYALYA",
  "RAJENDRA LOHIA VIDYA MANDIR",
  "RAJENDRA PUBLIC SCHOOL",
  "RAJENDRA VIDYALAYA",
  "RAJESHWAR CONVENT HIGHER SECONDARY SCHOOL",
  "RAJGHAT BESANT SCHOOL",
  "RAJIV GANDHI INTERNATIONAL MODERN SCHOOL",
  "RAJKISHAN MEMORIAL SENIOR SECONDARY SCHOOL",
  "RAJKIYA JANJATI BALIKA AVASIYA MADAYMIK VIDYALAYA",
  "RAJKIYA PRATIBHA VIKAS VIDYALAYA",
  "RAJKUMAR COLLEGE",
  "RAJKUMAR KHANDELWAL HIGHER SECONDARY SCHOOL",
  "RAJKUMARI SANATOMBI DEVI VIDYALAYA",
  "RAJMATA KRISHNA KUMARI GIRLS PUBLIC SCHOOL",
  "RAJNI PUBLIC SCHOOL",
  "RAJPRABHA MATRICULATION SCHOOL",
  "RAKHI PUBLIC SCHOOL",
  "RALLA MAL BUCHAR DAV CENT. PUBLIC SCHOOL",
  "RAM DAYAL PUBLIC SCHOOL",
  "RAM KRISHNA SIKSHYA KENDRA",
  "RAM LAKHAN BHATT INTERNATIONAL SCHOOL",
  "RAM LAKHAN PUBLIC SCHOOL",
  "RAM LAL BHASIN PUBLIC SCHOOL",
  "RAM NAGAR PUBLIC SCHOOL",
  "RAM PRASAD DAV PUBLIC SCHOOL",
  "RAM RAHIM BAL VIDYA MANDIR",
  "RAM RAJ COMPOSITE JUNIOR COLLEGE",
  "RAM SARAN SINGH VIDYALAYA",
  "RAM SHANTI VIDYA MANDIR",
  "RAMA KRISHNA PUBLIC SCHOOL",
  "RAMA SR. SEC. SCHOOL",
  "RAMACHANDRA KIDZEE SCHOOL",
  "RAMADEVI PUBLIC SCHOOL",
  "RAMAGIRI PUBLIC SCHOOL",
  "RAMAKRISHNA MISSION ENGLISH SCHOOL",
  "RAMAKRISHNA MISSION SCHOOL",
  "RAMAKRISHNA MISSION VIDYALAYA",
  "RAMAKRISHNA MISSION VIDYAPITH",
  "RAMAKRISHNA MISSION",
  "RAMAKRISHNA PUBLIC SCHOOL",
  "RAMAKRISHNA SARADA MISSION GIRLS SCHOOL",
  "RAMAKRISHNA VIVEKANANDA SARADA VIDYAPITH",
  "RAMANA MAHARISHI LOYOLA ACADEMY",
  "RAMANAMAHARISHI MATRIC HIGHER SECONDARY SCHOOL",
  "RAMANAND SR. HR. SEC. SCHOOL",
  "RAMANLAL SHARAWALA PUBLIC SCHOOL",
  "RAMANUJAN PUBLIC SCHOOL",
  "RAMAVARMA MEMORIAL PUBLIC SCHOOL",
  "RAMCHANDER PUBLIC SCHOOL",
  "RAM-EESH INTERNATIONAL SCHOOL",
  "RAMJAS NURSERY PRIMARY SCHOOL",
  "RAMJAS PUBLIC SCHOOL (DAY BOARDING)",
  "RAMJAS PUBLIC SCHOOL",
  "RAMJIDAS BHAGWANDAS DAV SR SC PUBLIC SCHOOL",
  "RAMPUR HIGH SCHOOL",
  "RANA PUBLIC SENIOR SECONDARY SCHOOL",
  "RANBIR SINGH MODEL SCHOOL",
  "RANCE PUBLIC SCHOOL",
  "RANGE SCHOOL",
  "RANI INDUMATI VIDYA NIKETAN",
  "RANI JAI HIGHER SECONDARY SCHOOL",
  "RANI LAXMI BAI MEMORIAL",
  "RANI LAXMI BAI PUBLIC SCHOOL",
  "RANI LAXMIBAI PUBLIC SCHOOL",
  "RANI MATHA PUBLIC SCHOOL",
  "RANI PUBLIC SCHOOL",
  "RANIGANJ LIONS JDM CHANANI DAV PUBLIC SCHOOL",
  "RANIHAT HIGH SCHOOL",
  "RAO LAL SINGH PUBLIC SCHOOL",
  "RAO PAHLAD SINGH SR. SEC. SCHOOL",
  "RAO RAM CHANDER MEMORIAL PUBLIC SCHOOL",
  "RAO'S HIGH SCHOOL",
  "RASBIHARI INTERNATIONAL SCHOOL",
  "RASHTREEYA VIDYALAYA PUBLIC SCHOOL",
  "RASHTRIYA GOPAL VIDYAMANDIA",
  "RASHTRIYA INDIAN MILITARY COLLEGE",
  "RASHTRIYA MILITARY SCHOOL CHAIL",
  "RASHTRIYA MILITARY SCHOOL",
  "RASHTRIYAN MILITARY COLLEGE",
  "RATHI INTERNATIONAL SCHOOL",
  "RATLAM PUBLIC HR. SECONDARY SCHOOL",
  "RATNA MEMORIAL PUBLIC SCHOOL",
  "RATTAN DAI KHOSLA DAV MODEL SCHOOL",
  "RAUNAQ PUBLIC SCHOOL",
  "RAVINDRA BHARATHI IIT OLYMPIAD AC ACADEMY",
  "RAVINDRA BHARATHI PUBLIC SCHOOL",
  "RAVINDRA BHARATHI SCHOOL",
  "RAVINDRA BHARTI PUBLIC SCHOOL",
  "RAVINDRA ENGLISH MEDIUM SCHOOL",
  "RAVINDRA RESIDENTIAL SCHOOL",
  "RAVITEJA PUBLIC SCHOOL",
  "RAWAL INTERNATIONAL SCHOOL",
  "RAWAL PUBLIC SCHOOL",
  "RAWAT SENIOR SECONDARY SCHOOL",
  "RAYALASEEMA PUBLIC SCHOOL",
  "RAYAT INTERNATIONAL SCHOOL",
  "RAYJIG PUBLIC SCHOOL",
  "RB PUBLIC SCHOOL",
  "RBANC HIGHER SECONDARY SCHOOL",
  "RD PUBLIC SCHOOL",
  "RED CARPET HIGH SCHOOL",
  "RED ROSE CONVENT SCHOOL",
  "RED ROSE PUBLIC HIGH SCHOOL",
  "RED ROSE PUBLIC SCHOOL",
  "RED ROSE PUBLIC SR. SEC. SCHOOL",
  "RED ROSES PUBLIC SCHOOL",
  "REDWOODS ENGLISH SCHOOL",
  "REGENCY PUBLIC SCHOOL",
  "RELWX PUBLIC SCHOOL",
  "RESIDENTIAL GIRLS HIGH SCHOOL",
  "RESIDENTIAL SUN-BEAM PUBLIC SCHOOL",
  "REX HIGHER SECONDARY SCHOOL",
  "REYAN PUBLIC SCHOOL",
  "RICH HARVEST PUBLIC SCHOOL",
  "RICHMONDD GLOBAL SCHOOL",
  "RIFA MODERN PUBLIC SCHOOL",
  "RIMS INTERNATIONAL SCHOOL & JUNIOR COLLEGE",
  "RISA PUBLIC SCHOOL",
  "RISHABH PUBLIC SCHOOL",
  "RISHI GALAV PUBLIC SCHOOL",
  "RISHI PUBLIC SCHOOL",
  "RISHI VALLEY SCHOOL",
  "RISHIKUL ARYA PUBLIC SCHOOL",
  "RISHIKUL SHAIKSHANIK SANSTHAN",
  "RISING PUBLIC SHCOOL",
  "RISING STAR ACADEMY SEC. PUBLIC SCHOOL",
  "RISING SUN PUBLIC SCHOOL",
  "RIVER VIEW ENGLISH SCHOOL",
  "RIVERDALE INTERNATIONAL RESIDENTIAL SCHOOL",
  "RIVERDALE INTERNATIONAL RESIDENTIAL SCHOOL, PUNE",
  "RIVERDALE INTERNATIONAL SCHOOL",
  "RIVERSIDE PUBLIC SCHOOL",
  "ROCKFIELD PUBLIC SCHOOL",
  "ROCKWELL INTERNATIONAL SCHOOL",
  "ROCKWOOD INTERNATIONAL SCHOOL",
  "ROHINI PUBLIC SCHOOL",
  "RONGAMATI GIRLS M. V. SCHOOL",
  "ROOP KRISHAN PUBLIC SCHOOL",
  "ROORKE PUBLIC SCHOOL",
  "ROOTS INTERNATIONAL SCHOOL",
  "ROOTS PUBLIC SCHOOL",
  "ROOTS SCHOOL",
  "ROSARY CONVENT PRE SCHOOL",
  "ROSARY HR. SEC. SCHOOL",
  "ROSARY PUBLIC SCHOOL",
  "ROSARY SENIOR SECONDARY SCHOOL",
  "ROSE BUD SCHOOL",
  "ROSE GARDEN MATRICULATION SCHOOL",
  "ROSE LAND PUBLIC SCHOOL",
  "ROSE MARY CONVENT SCHOOL",
  "ROSE PUBLIC GIRLS SR. SEC. SCHOOL",
  "ROSE PUBLIC SCHOOL",
  "ROSE VALLEY PUBLIC SCHOOL",
  "ROSEAL PUBLIC SCHOOL",
  "ROSEMARY PUBLIC SCHOOL",
  "ROSHAN LAL SHARMA PUBLIC SCHOOL",
  "ROSY PUBLIC SCHOOL",
  "ROTARY PUBLIC SCHOOL",
  "ROTARY SCHOOL ANAND SHANDAR",
  "ROYAL ACADEMY",
  "ROYAL COLLEGE",
  "ROYAL CONCORDE INTERNATIONAL SCHOOL",
  "ROYAL CONVENT SCHOOL",
  "ROYAL GONDWANA PUBLIC SCHOOL",
  "ROYAL INTERNATIONAL SCHOOL",
  "ROYAL KIDS CONVENT",
  "ROYAL KIDS' CONVENT",
  "ROYAL MODEL SEN. SEN. SCHOOL",
  "ROYAL PALACE SCHOOL",
  "ROYAL PUBLIC SR. SEC. SCHOOL",
  "ROYAL RAJASTHAN PUBLIC SCHOOL",
  "ROYALE CONCORDE INTERNATIONAL SCHOOL",
  "ROYCE INTERNATIONAL SCHOOL",
  "RRN DAV PUBLIC SCHOOL",
  "RUBEN MATRIC HIGHER SECONDARY SCHOOL",
  "RUCHIKA HIGH SCHOOL",
  "RUCHIKA PRESCHOOL",
  "RUCHIKA SCHOOL",
  "RUDRA INTERNATIONAL SCHOOL",
  "RUKMANI BIRLA MODERN HIGH SCHOOL",
  "RUKMANI DEVI PUBLIC SCHOOL",
  "RUKMANI VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
  "RUKMINI DEVI PUBLIC SCHOOL",
  "RUPNATH BRAHMA SCHOOL",
  "RUSTOMJEE INTERNATIONAL SCHOOL",
  "RYAN INTERNATIONAL SCHOOL",
  "RYAT INTERNATIONAL SCHOOL",
  "RYEWOOD INTERNATIONAL SCHOOL",
  "S B O A PUBLIC SENIOR SECONDARY SCHOOL",
  "S B R D A V PUBLIC SCHOOL",
  "S B R L VAISH RES EDU PUBLIC ACADEMIC",
  "S B S SENIOR SECONDARY SCHOOL",
  "S D MARUTI NANDAN VIDYA MANDIR",
  "S D MODEL SENIOR SECONDARY SCHOOL",
  "S D PUBLIC SCHOOL",
  "S D SHARMA D A V CENTENARY PUBLIC SCHOOL",
  "S D VIDYA MANDIR",
  "S E RLY MIXED HIGHER SEC SCHOOL",
  "S G PUBLIC SCHOOL",
  "S J S PUBLIC SCHOOL",
  "S M S PUBLIC SCHOOL",
  "S N PUBLIC SCHOOL",
  "S R CENTURY PUBLIC SCHOOL",
  "S R DAV PUBLIC SCHOOL",
  "S S BAJWA MEMORIAL PUB.SCHOOL",
  "S S D KRISHNA VATIKA SCHOOL",
  "S TEJA SINGH KANDHARI PUBLIC SCHOOL",
  "S V J C D A V PUBLIC SCHOOL",
  "S. CADAMBI VIDYA KENDRA ENGLISH SEC. SCHOOL",
  "S. D. A. ENGLISH RESIDENTIAL HIGH SCHOOL",
  "S. D. SENIOR SECONDARY BOYS SCHOOL",
  "S. DESA SINGH MAJITHIA PUBLIC SCHOOL",
  "S. V. M. CENTRAL PUBLIC SCHOOL",
  "S.A.N. JAIN MODEL SR. SEC SCHOOL",
  "S.A.N. JAIN SENIOR SECONDARY SCHOOL",
  "S.B. GARDI CAMBRIDGE INTERNATIONAL SCHOOL",
  "S.B. MARICLATION SCHOOL",
  "S.B. PUBLIC SCHOOL",
  "S.B.I.O.A. PUBLIC SCHOOL",
  "S.B.O.A.PUBLIC SCHOOL",
  "S.B.R.D.A.V.PUBLIC SCHOOL",
  "S.B.R.S. GURUKUL",
  "S.C.B. MEDICAL PUBLIC SCHOOL",
  "S.D. ADARSH PUBLIC SCHOOL",
  "S.D. MODEL HIGH SCHOOL",
  "S.D. MODEL SCHOOL FOR BOYS & GIRLS",
  "S.D. MODEL SCHOOL FOR BOYS AND GIRLS",
  "S.D. MODEL SCHOOL",
  "S.D. MODERN PUBLIC SCHOOL",
  "S.D. PUBLIC SCHOOL",
  "S.D. SENIOR SECONDARY GIRLS SCHOOL",
  "S.D.A. ENGLISH HIGH SCHOOL",
  "S.D.A. ENGLISH RESIDENTIAL HIGH SCHOOL",
  "S.D.A. HIGH SCHOOL",
  "S.D.A. HIGHER SECONDARY SCHOOL",
  "S.D.A. INTER COLLEGE",
  "S.D.A. SENIOR SECONDARY SCHOOL",
  "S.D.A.H.S. SCHOOL",
  "S.D.A.HIGH SCHOOL",
  "S.D.K.L.D.A.V.CENTENARY PUBLIC SCHOOL",
  "S.D.M. PUBLIC SCHOOL",
  "S.D.R. SCHOOL",
  "S.E. INTERNATIONAL SCHOOL",
  "S.E. RLY. MIXED HIGHER SECONDARY SCHOOL (E.M.)",
  "S.E.A. INTERNATIONAL SCHOOL",
  "S.F.S. ENGLISH MEDIUM HIGH SCHOOL",
  "S.F.S. PRIMARY SCHOOL",
  "S.G. INTERNATIONAL PUBLIC SCHOOL",
  "S.G. PUBLIC SCHOOL",
  "S.G.B.D. HIGH SCHOOL",
  "S.G.K.M. INTERNATIONAL SCHOOL",
  "S.G.M. PUBLIC SCHOOL",
  "S.G.M. SHIROIYA SENIOR SECONDARY ENGLISH MED. SCHOOL",
  "S.G.N. INTERNATIONAL SCHOOL",
  "S.G.V.P. INTERNATIONAL SCHOOL",
  "S.J.R. KENGERI PUBLIC SCHOOL",
  "S.K. MODEL SCHOOL",
  "S.K. PAYAL PUBLIC SCHOOL",
  "S.K.G.P. HIGH SCHOOL",
  "S.K.M.PUBLIC SCHOOL",
  "S.K.P.D. BOYS HIGHER SECONDARY SCHOOL",
  "S.K.S. PUBLIC SCHOOL",
  "S.K.V. HIGH SCHOOL",
  "S.K.V. MATRIC AND HIGHER SECONDARY SCHOOL",
  "S.L. BAJORIA FOUNDATION HIGH SCHOOL",
  "S.L. SURL D.A.V. PUBLIC SCHOOL",
  "S.L.B.PUBLIC SCHOOL",
  "S.M. ALI HIGH SCHOOL",
  "S.M. ARYA PUBLIC SCHOOL",
  "S.M. NIMAWAT PUBLIC SCHOOL",
  "S.M. PUBLIC SCHOOL",
  "S.M.S MEMORIAL PUBLIC SCHOOL",
  "S.M.S. DUTTA MEMO NOSEGAY PUBLIC SCHOOL",
  "S.M.S. MEMORIAL PUBLIC SCHOOL",
  "S.N. BADANGADIA SCHOOL",
  "S.N. KANSAGRA SCHOOL",
  "S.N. PUBLIC HIGH SCHOOL",
  "S.N. SHARADA GIRLS HIGH SCHOOL",
  "S.N.S. MATRIC SCHOOL",
  "S.N.V. INTERNATIONAL SCHOOL",
  "S.P. PRIME PUBLIC SCHOOL",
  "S.P.G. INTERNATIONAL PUBLIC SCHOOL",
  "S.P.G. INTERNATIONAL PUBLIC SCHOOLS.NO. 203 NEAR DURVANGPUR GARDEN",
  "S.P.M. D.A.V. PUBLIC SCHOOL",
  "S.P.S. CONVENT SCHOOL",
  "S.PRAKASH RESIDENTIAL SCHOOL,",
  "S.R. CAPITAL PUBLIC SCHOOL",
  "S.R. CENTURY PUBLIC SCHOOL",
  "S.R. DAYANAND PUBLIC SCHOOL",
  "S.R. PUBLIC SENIOR SECONDARY SCHOOL",
  "S.R.S. PUBLIC SCHOOL",
  "S.R.V. HIGHER SECONDARY SCHOOLS",
  "S.R.V. MATRICULATION HIGHER SECONDARY SCHOOL",
  "S.S. KANHAIYA LAL AGGARWAL MEM BAL VIDYA MANDIR",
  "S.S. MEMORIAL PUBLIC SCHOOL",
  "S.S. MODY PUBLIC SCHOOL",
  "S.S. PUBLIC SCHOOL",
  "S.S.B. INTERNATIONAL SCHOOL",
  "S.S.K.V. MATRIC HIGHER SECONDARY SCHOOL",
  "S.S.P. INTERNATIONAL SCHOOL",
  "S.S.PUBLIC SCHOOL",
  "S.V. PUBLIC SCHOOL",
  "S.V.E.I. PUBLIC SCHOOL",
  "S.V.J.C.D.A.V.PUBLIC SCHOOL",
  "S.V.K.M. INTERNATIONAL SCHOOL",
  "S.V.N. MATRIC AND HIGHER SECONDARY SCHOOL",
  "S.V.R. PUBLIC SCHOOL",
  "S.V.S. INTERNATIONAL SCHOOL",
  "S.V.S. PUBLIC SCHOOL",
  "SACHDEVE PUBLIC SCHOOL",
  "SACHKHAND CONVENT SCHOOL",
  "SACRED HEART ANGLO INDIAN GIRLS HIGH SCHOOL",
  "SACRED HEART CONVENT HIGH SCHOOL",
  "SACRED HEART CONVENT PUBLIC SCHOOL",
  "SACRED HEART CONVENT SCHOOL",
  "SACRED HEART GIRLS' HIGH SCHOOL",
  "SACRED HEART GIRLS HR. SEC. SCHOOL",
  "SACRED HEART HIGH SCHOOL",
  "SACRED HEART HIGHER PRIMARY SCHOOL",
  "SACRED HEART MATRICULATION HIGHER SECONDRAY SCHOOL",
  "SACRED HEART NURSERY SCHOOL",
  "SACRED HEART OF JESUS MIDDLE SCHOOL",
  "SACRED HEART PUBLIC SCHOOL",
  "SACRED HEART SCHOOL",
  "SACRED HEART SENIOR SECONDARY SCHOOL",
  "SACRED HEART SR. SEC. SCHOOL",
  "SACRED SOUL CONVENT SCHOOL",
  "SADA SUKH MEMORIAL PUBLIC SCHOOL",
  "SADBHAVNA PUBLIC SCHOOL",
  "SADHU SINGH D.A.V. PUBLIC SCHOOL",
  "SADHU VASWANI INTERNATIONAL SCHOOL FOR GIRLS",
  "SADHU VASWANI INTERNATIONAL SCHOOL",
  "SAFFRON CITY PUBLIC SCHOOL",
  "SAFFRON CITY SCHOOL",
  "SAFFRON PUBLIC SCHOOL",
  "SAFFRON PUBLLIC SCHOOL",
  "SAGAR PUBLIC SCHOOL",
  "SAHARANPUR PUBLIC SCHOOL",
  "SAHEED LAXMAN NAYAK PUBLIC SCHOOL",
  "SAHIBJADA AJIT SINGH PUBLIC SCHOOL",
  "SAHIBZADA AJIT SINGH ACADEMY",
  "SAHIBZADA AJIT SINGH",
  "SAHID GANJ PUBLIC SCHOOL",
  "SAHID VIR NARAYAN HIGHER SECONDARY SCHOOL",
  "SAHIL PUBLIC SCHOOL",
  "SAHU PUBLIC SCHOOL",
  "SAHYADRI CENTRAL SCHOOL",
  "SAHYADRI SCHOOL",
  "SAI BABA CENTRAL SCHOOL",
  "SAI INTERNATIONAL SCHOOL",
  "SAI MEMORIAL GIRLS SCHOOL",
  "SAI RAM MATRICULATION HIGHER SECONDARY SCHOOL",
  "SAI SHIBANI INTERNATIONAL SCHOOL",
  "SAIEMA MANSOOR PUBLIC SCHOOL",
  "SAIFEE HALL S.G.J. ENGLISH PUBLIC SCHOOL",
  "SAILEE INTERNATIONAL SCHOOL",
  "SAIN DASS A.S. GIRLS HIGH SCHOOL",
  "SAINI PUBLIC SCHOOL",
  "SAINIK FOUNDATIONS SCHOOL",
  "SAINIK SCHOOL (GOPALGANJ)ADDRESS : P.O. HATHWA",
  "SAINIK SCHOOL (KORUKONDA)",
  "SAINIK SCHOOL (REWA)",
  "SAINIK SCHOOL (SR. SEC. SCHOOL )",
  "SAINIK SCHOOL (TILAIYA)",
  "SAINIK SCHOOL NALANDA",
  "SAINIK SCHOOL",
  "SAINT AUGUSTINE ACADEMY",
  "SAINT FARID CONVENT SCHOOL",
  "SAINT FATEH SINGH CONVENT SCHOOL",
  "SAINT GIRI PUBLIC SCHOOL",
  "SAINT GREEK PUBLIC SENIOR SECONDARY SCHOOL",
  "SAINT HARKEWAL VIDYAPEETH HIGH SCHOOL",
  "SAINT LAWRENCE SCHOOL",
  "SAINT MARY HIGH SCHOOL",
  "SAINT MARYS SCHOOL",
  "SAINT MARY'S SCHOOL",
  "SAINT MSG GLORIOUS INTERNATIONAL SCHOOL",
  "SAINT PAUL CONVENT SCHOOL",
  "SAINT PAUL SCHOOL",
  "SAINT PAUL'S HIGH SCHOOL",
  "SAINT PAUL'S SCHOOL",
  "SAINT PAUL'S SR. SEC. SCHOOL",
  "SAINT SOILDER CONVENT SCHOOL",
  "SAINT SOLDIER CONVENT SCHOOL",
  "SAINT SOLDIER INTERNATIONAL CONVENT SCHOOL",
  "SAINT SOLDIER PUBLIC SCHOOL",
  "SAINT SOLDIER SENIOR SECONDARY CONVENT SCHOOL",
  "SAINT XAVIER'S SCHOOL",
  "SAKALDHARI PRASAD INTERNATIONAL PUBLIC SCHOOL",
  "SAKET PUBLIC SCHOOL",
  "SALEM ENGLISH SCHOOL",
  "SALMARA HIGH SCHOOL",
  "SALWAN PUBLIC SCHOOL",
  "SAM INTERNATIONAL SCHOOL",
  "SAMAR STUDY HALL SENIOR SECONDARY SCHOOL",
  "SAMARITAN SCHOOL",
  "SAMBODH SCHOOL",
  "SAMDARIYA PUBLIC SCHOOL",
  "SAMPOORANAND CENTRAL PUBLIC SCHOOL",
  "SAMRAT ASHOK PUBLIC SCHOOL",
  "SAMRAT PUBLIC SR. SEC. SCHOOL",
  "SAMSKARA BHARATI ENGLISH MEDIUM SCHOOL",
  "SAMTA SHIKSHA NIKETAN",
  "SAN JOSE HIGHER SECONDARY SCHOOL",
  "SANADAN VIDYA NIKETAN PUBLIC SCHOOL",
  "SANATAN DHARAM PUBLIC SCHOOL",
  "SANATAN DHARM INTER COLLEGE",
  "SANATAN VIDYA MANDIR",
  "SANATHAN DHARAM MODERN PUBLIC SCHOOL",
  "SANATHANA PUBLIC SCHOOL AND CHILD CARE CENTRE",
  "SANAWAR AN INSTITUTE FOR CHILDERN",
  "SANCHETNA PUBLIC SR. SEC. SCHOOL",
  "SANCTA MARIA INTERNATIONAL SCHOOL",
  "SANDEEPANI VIDYANIKETHAN",
  "SANDUR GIRLS RESIDENTIAL SCHOOL",
  "SANDUR RESIDENTIAL COMPOSITE JUNIOR COLLEGE",
  "SANDUR RESIDENTIAL SCHOOL",
  "SANFORT SCHOOL",
  "SANGAM ACADEMY",
  "SANGAM SCHOOL OF EXCELLENCE",
  "SANGAMITRA PUBLIC SCHOOL",
  "SANGANABASAVA INTERNATIONAL RESIDENTIAL SCHOOL",
  "SANGFORD HR. SEC. SCHOOL",
  "SANGFORD SCHOOL",
  "SANGU GIRLS HIGH SCHOOL",
  "SANJAY BAL VIDYALAYA SENIOR SECONDARY PUBLIC SCHOOL",
  "SANJAY CONVENT SCHOOL",
  "SANJAY GANDHI MEMORIAL PUBLIC VIDYALAYA",
  "SANJAY PUBLIC SCHOOL",
  "SANJEEVAN PUBLIC SCHOOL",
  "SANJEEVAN VIDYALAYA VIDYANIKETAN",
  "SANJIVANI INTERNATIONAL SCHOOL",
  "SANJOS PUBLIC SCHOOL",
  "SANKALP DAY BOARDING SCHOOL",
  "SANKARA VIDYALAYA MATRICULATION HR SEC SCHOOL",
  "SANKARAMANGALAM PUBLIC SCHOOL",
  "SANSKAR BHARATI PUBLIC SCHOOL",
  "SANSKAR CITY INTERNATIONAL SCHOOL",
  "SANSKAR INTERNATIONAL SCHOOL",
  "SANSKAR PUBLIC SCHOOL",
  "SANSKAR SCHOOL AND COLLEGE",
  "SANSKIRITI K.M.V. SCHOOL",
  "SANSKRITI K.M.V. SCHOOL",
  "SANSKRITI THE GURUKUL",
  "SANSKRITI THE SCHOOL",
  "SANT BABA BHAG SINGH INTERNATIONAL SCHOOL",
  "SANT CHINMAYA PUBLIC SCHOOL",
  "SANT GADGE BABA ENGLISH PRIMARY SCHOOL",
  "SANT HARKEWAL VIDYAPEETH HIGH SCHOOL",
  "SANT ISHAR SINGH JI MEMORIAL PUBLIC SCHOOL",
  "SANT ISHAR SINGH PUBLIC SCHOOL",
  "SANT ISHER SINGH PUBLIC SCHOOL",
  "SANT KABIR SECONDARY SCHOOL",
  "SANT KARAM SINGH ACADEMY",
  "SANT KIRPAL SINGH SEWA PANTHI SENIOR SECONDARY PUBLIC SCHOOL",
  "SANT MEMORIAL PUBLIC SCHOOL",
  "SANT NAMDEV PUBLIC SCHOOL",
  "SANT NIKKA SINGH PUBLIC SCHOOL",
  "SANT NIRANKARI GIRLS SENIOR SECONDARY SCHOOL",
  "SANT NIRANKARI PUBLIC SCHOOL",
  "SANT NISCHAL SINGH PUBLIC SCHOOL",
  "SANT PARMANAND PUBLIC SCHOOL",
  "SANT RAGHBIR SINGH AIMS PUBLIC SCHOOL",
  "SANT ROSHAN LAL VIDYA MANDIR SR. SEC. SCHOOL",
  "SANT SAR PUBLIC SCHOOL",
  "SANT SARWAN DASS MODEL SCHOOL",
  "SANT SHRI ASARAMJI PUBLIC SCHOOL",
  "SANT TUKARAM NATIONAL MODEL SCHOOL",
  "SANTA CLARA MATRICULATION SCHOOL",
  "SANTA MARIA ACADEMY INTERNATIONAL",
  "SANTA MARIA HIGH SCHOOL",
  "SANTA MARIA PUBLIC SCHOOL",
  "SANTANU ENGLISH MEDIUM SCHOOL",
  "SANTHAMANI ENGLISH HIGH SCHOOL",
  "SANTHINIKETHAN HIGH SCHOOL",
  "SANTHIVARDHANA MINISTRIES",
  "SANTHOME PUBLIC SCHOOL",
  "SANTI NIKETAN PUBLIC SCHOOL",
  "SANTOM PUBLIC SCHOOL",
  "SAPIENT PUBLIC SCHOOL",
  "SAPPHIRE INTERNATIONAL SCHOOL",
  "SAPTARSHI PUBLIC SCHOOL",
  "SAPTHAGIRI PUBLIC SCHOOL",
  "SAR PUBLIC SCHOOL",
  "SARADA HIGH SCHOOL",
  "SARADA NIKETAN HIGH SCHOOL",
  "SARADA PUBLIC SCHOOL",
  "SARADAR HIGHER SECONDARY SCHOOL",
  "SARAF PUBLIC SCHOOL",
  "SARAIL SHIVAM PUBLIC SCHOOL",
  "SARALA BIRLA ACADEMY",
  "SARALA BIRLA PUBLIC SCHOOL",
  "SARANG CONVENT SCHOOL",
  "SARASWATHI MATRIC HIGHER SECONDARY SCHOOL",
  "SARASWATHI VIDYANIKETHAN PUBLIC SCHOOL",
  "SARASWATI INTERNATIONAL SCHOOL",
  "SARASWATI MODEL SCHOOL",
  "SARASWATI MODERN SCHOOL",
  "SARASWATI NIKETAN PUBLIC SCHOOL",
  "SARASWATI SENIOR SECONDARY SCHOOL",
  "SARASWATI SENIOR SECONDARY VIDYA MANDIR",
  "SARASWATI SHISHU MANDIR SENIOR SECONDARY SCHOOL",
  "SARASWATI SHISHU MANDIR",
  "SARASWATI SHISHU VIDYA MANDIR",
  "SARASWATI SISHU SADAN",
  "SARASWATI VIDYA MANDIR UCHCHTAR",
  "SARASWATI VIDYA MANDIR",
  "SARASWATI VIDYA",
  "SARDAR SENIOR HIGHER SECONDARY SCHOOL",
  "SARGODHA NATIONAL PUBLIC SR. SEC. SCHOOL",
  "SARISHA HIGH SCHOOL",
  "SARLA CHOPRA D.A.V. PUBLIC SCHOOL",
  "SARSWATI VIDYA MANDIR SENIOR SECONDARY SCHOOL",
  "SARTI DEVI RAJA RAM PUBLIC SCHOOL",
  "SARUP PUBLIC SCHOOL",
  "SARVAGYA PUBLIC SCHOOL",
  "SARVANI PUBLIC SCHOOL",
  "SARVHITKARI KESHAV VIDYA NIKETAN",
  "SARVHITKARI SHIKSHA NIKETAN PUBLIC SCHOOL",
  "SARVHITKARI SHIKSHA NIKETAN",
  "SARVODAY PUBLIC SCHOOL",
  "SARVODAYA PUBLIC SCHOOL",
  "SARVODAYA VIDYALAYA",
  "SARVOTTAM ACADEMY",
  "SARWAN INTERNATIONAL SCHOOL",
  "SAS BILLABONG HIGH SCHOOL",
  "SAT GYAN PUBLIC SCHOOL",
  "SATAVAHANA PUBLIC SCHOOL",
  "SATLUJ PUBLIC SCHOOL",
  "SATPURA VALLEY PUBLIC SCHOOL",
  "SATYA JUNIOR COLLEGE",
  "SATYA SPECIAL SCHOOL",
  "SATYAM PUBLIC SCHOOL",
  "SATYAM PUBLIC SCHOOL,",
  "SATYANAND PUBLIC SCHOOL",
  "SATYANARYAN ACADEMY",
  "SATYASHWARI MEMORIAL PUBLIC SCHOOL",
  "SATYAVATI PUBLIC SECONDARY SCHOOL",
  "SAUPIN'S SCHOOL",
  "SAVIDRI VIDAYALAYA",
  "SAVIOR JESUS MATRICULATION HIGHER SECONDARY SCHOOL",
  "SAVIOUR PUBLIC SCHOOL",
  "SAVITHRI AMMAL ORIENTAL HIGHER SECONDARY SCHOOL",
  "SAVITRI PUBLIC SCHOOL",
  "SAWAN INTERNATIONAL SCHOOL",
  "SAWAN PUBLIC SCHOOL",
  "SAYAD PUBLIC SCHOOL",
  "SAYYID AKBAR HUSAYNI SCHOOL",
  "SBOA PUBLIC SCHOOL",
  "SCHOLAR FIELDS PUBLIC SCHOOL",
  "SCHOLARS INTERNATIONAL SCHOOL",
  "SCHOLAR'S PRIDE PUBLIC SCHOOL",
  "SCHOLARS PUBLIC SCHOOL",
  "SCHOOL OF BRILLIANT",
  "SCHOOL OF SCHOLARS",
  "SCHOOL VIVEKANANDA",
  "SCINDIA KANYA VIDYALAYA",
  "SCOPE PUBLIC SCHOOL",
  "SCOTTISH HIGH INTERNATIONAL SCHOOL",
  "SCOTTISH PUBLIC SCHOOL",
  "SDA HIGH SCHOOL",
  "SEA ROCK INTERNATIONAL SCHOOL",
  "SEABA INTERNATIONAL SCHOOL",
  "SECOND MART PUBLIC SCHOOL",
  "SECONDARY DELHI PUBLIC SCHOOL",
  "SECREAD HEART PUBLIC SCHOOL",
  "SECULAR PUBLIC SCHOOL",
  "SEEDLING MODERN HIGH SCHOOL",
  "SEEDLING MODERN PUBLIC SCHOOL",
  "SEEDLING NURSERY SCHOOL",
  "SEEDLING PUBLIC SCHOOL",
  "SEEN PUBLIC SCHOOL",
  "SELAQUI INTERNATIONAL SCHOOL",
  "SELEINA CHARLES MEMORIAL ENGLISH SCHOOL",
  "SENDHWA PUBLIC SCHOOL",
  "SENGYA SAMBUDHAN RAJEE SCHOOL",
  "SENT SAI PUBLIC SCHOOL",
  "SENTHIL MATRICULATION HIGHER SECONDARY SCHOOL",
  "SERVITE ENGLISH SCHOOL",
  "SERWO ENGLISH MEDIUM SCHOOL",
  "SESHADRIPURAM PUBLIC SCHOOL",
  "SESOMU SCHOOL",
  "SETH BADRI PARSHAD DAV CENT PUBLIC SCHOOL",
  "SETH BHAGWAN DASS PUBLIC SCHOOL",
  "SETH CHANNURAM BAJAJ MEMORIAL PUBLIC SCHOOL",
  "SETH DWARKA PRESAD BAJAJ EDUCATION CENTRE",
  "SETH GIRDHARI LAL BIHANI S.D. SENIOR SECONDARY SCHOOL",
  "SETH HUKAM CHAND S D PUBLIC SCHOOL",
  "SETH HUKUM CHAND S D PUBLIC SCHOOL",
  "SETH M.R. JAIPURIA SCHOOL",
  "SETH RAMJIDAS MODI VIDYA NIKETAN SOCIETY",
  "SETH RAMLAL KHEMKA PUBLIC SCHOOL",
  "SETH TEKCHAND MEMORIAL PUBLIC SCHOOL",
  "SETH TOLA RAM BAFNA ACADEMY",
  "SETIA DAV PUBLIC SCHOOL",
  "SEVEN DAYS PUBLIC SCHOOL",
  "SEVEN ISLES CAMBRIDGE INTERNATIONAL SCHOOL",
  "SEVENTH - DAY ADVENTIST HIGHER SECONDARY SCHOOL",
  "SEVENTH ADVENTIST ENGLISH HIGH SCHOOL",
  "SEVENTH ADVENTIST HIGH SCHOOL",
  "SEVENTH DAY ADVENTIST ENG. MEDIUM RES. SCHOOL",
  "SEVENTH DAY ADVENTIST ENGLISH MEDIUM SCHOOL",
  "SEVENTH DAY ADVENTIST ENGLISH SCHOOL",
  "SEVENTH DAY ADVENTIST HIGH SCHOOL",
  "SEVENTH DAY ADVENTIST HIGHER SECONDARY SCHOOL",
  "SEVENTH DAY ADVENTIST INTER COLLEGE",
  "SEVENTH DAY ADVENTIST SCHOOL",
  "SEVENTH DAY ADVENTIST SR. SEC. SCHOOL",
  "SEVENTH DAY ADVENTISTS HIGH SCHOOL",
  "SEVENTH DAY ADVENTISTS SCHOOL",
  "SEVENTH-DAY ADVENTIST ENGLISH HIGH SCHOOL",
  "SEVENTH-DAY ADVENTIST ENGLISH SCHOOL",
  "SEVENTHDAY ADVENTIST MATRICULATION SCHOOL",
  "SEVENTH-DAY ADVENTIST SENIOR SECONDARY SCHOOL",
  "SEWA RAM DAV PUBLIC SCHOOL",
  "SGGS PUBLIC SCHOOL",
  "SH GURU RAM RAI PUBLIC SCHOOL",
  "SH GURUMANCHH VIDHYAMANDIR",
  "SH.GURU NANAK DEV SIKH PUBLIC SCHOOL",
  "SH.SURENDER KUMAR GUPTA SARVHITKARI VIDYA MANDIR",
  "SHADLEY PUBLIC SCHOOL",
  "SHAGUN PUBLIC SCHOOL",
  "SHAH BALWANT RAI DAV PUBLIC SCHOOL",
  "SHAH FAIZ MEMORIAL PUBLIC SCHOOL",
  "SHAH FAIZ PUBLIC SCHOOL",
  "SHAH INTERNATIONAL SCHOOL",
  "SHAH SATMAMJI GIRLS SCHOOL",
  "SHAHEED BHAI DIALAJI PUBLIC SCHOOL",
  "SHAHEED CAPTAIN D.K. KHOLA PUBLIC SCHOOL",
  "SHAHEED DARSHAN SINGH PHERUMAN PUBLIC SCHOOL",
  "SHAHEED KARTAR SINGH SARABHA INTERNATIONAL PUBLIC SCHOOL",
  "SHAHEEN TALENT SCHOOL - PU COLLEGE IIT ACADEMY",
  "SHAIFFALI PUBLIC SCHOOL",
  "SHAKTI JUNIOR COLLEGE",
  "SHAKTI MANDIR PREMWATI PUBLIC SCHOOL",
  "SHAKTI OM MODEL SCHOOL",
  "SHAKTI SHANTI ACADEMY",
  "SHAKUNTALA VIDYALAYA",
  "SHALINI KANNADA AND ENGLISH PRIMARY SCHOOL",
  "SHALOM HILLS INTERNATIONAL SCHOOL",
  "SHALOM INTERNATIONAL SCHOOL",
  "SHALOM PUBLIC SCHOOL",
  "SHALOM RESIDENTIAL PUBLIC SCHOOL",
  "SHAMAYITA CONVENT SCHOOL",
  "SHAMROCK CHRISTIAN SCHOOL",
  "SHANBAG HIGH SCHOOL",
  "SHANKARRAO MOHITE- PATIL ENGLISH SCHOOL AND JUNIOR COLLEGE",
  "SHANTANU JUNIOR HIGH SCHOOL",
  "SHANTHA PUBLIC SCHOOL",
  "SHANTHAL JYOTHI PUBLIC SCHOOL",
  "SHANTHI MATRICULATION HIGHER SECONDARY SCHOOL",
  "SHANTHI VIJAI PRIMARY SCHOOL",
  "SHANTHI VIJAYA GIRLS HIGHER SECONDARY SCHOOL",
  "SHANTHINIKETAN PUBLIC SCHOOL",
  "SHANTI BHAVAN RESIDENTIAL SCHOOL",
  "SHANTI BIHAR PUBLIC SCHOOL",
  "SHANTI DEVI PUBLIC SCHOOL",
  "SHANTI GYAN INTERNATIONAL SCHOOL",
  "SHANTI INTERNATIONAL PUBLIC SCHOOL",
  "SHANTI NIKETAN ADARSH VIDYALAYA",
  "SHANTI NIKETAN AWASIYA BAL VIDYALAYA",
  "SHANTI NIKETAN HIGH SCHOOL",
  "SHANTI NIKETAN PUBLIC SCHOOL",
  "SHANTI NIKETAN VIDYAPEETH",
  "SHANTI PUBLIC HIGH SCHOOL",
  "SHANTI VIDYA MANDIR",
  "SHANTINIKETAN E.M. SCHOOL",
  "SHANTINIKETAN ENGLISH MEDIUM SCHOOL",
  "SHANTINIKETAN ENGLISH MEDIUM SCHOOL,",
  "SHANTINIKETAN INTERNATIONAL SCHOOL",
  "SHANTINIKETAN PUBLIC SCHOOL",
  "SHARAD PAWAR INTERNATIONAL SCHOOL",
  "SHARADA GYAN PEETH INTERNATIONAL SCHOOL",
  "SHARADA MANDIR SCHOOL",
  "SHARADA RESIDENTIAL SCHOOL",
  "SHARADA VIDYANIKETHANA PUBLIC SCHOOL",
  "SHARANABASAVESHWAR PUBLIC SCHOOL",
  "SHARANABASAVESHWAR VIDYA SAMITI SCHOOL",
  "SHARANALAYA PUBLIC SCHOOL",
  "SHARATH MEMORIAL SCHOOL",
  "SHARDA INTERNATIONAL SCHOOL",
  "SHARDA PUBLIC HIGH SCHOOL",
  "SHARDA PUBLIC SCHOOL",
  "SHARDEIN SCHOOL",
  "SHARONS PUBLIC SCHOOL",
  "SHARP SENIOR SECONDARY SCHOOL",
  "SHATABDI PUBLIC SCHOOL",
  "SHAYONA INTERNATIONAL SCHOOL",
  "SHEE GURULAM HIGHER SECONDARY SCHOOL",
  "SHEELA RANI TANGRI DAV PUBLIC SCHOOL",
  "SHEKHAR PARMAR",
  "SHEKHAWATI INTERNATIONAL ACADEMY",
  "SHEKINAH PUBLIC SCHOOL",
  "SHEMFORD SCHOOL",
  "SHEMROCK BLISS PRESCHOOL",
  "SHEMROCK BLOOMING BUDS PRE SHCOOL",
  "SHEMROCK BUSY BEES PRESHCOOL",
  "SHEMROCK CRAYONS",
  "SHEMROCK DREAMWORLD PRESCHOOL",
  "SHEMROCK GLOBAL PRESCHOOL",
  "SHEMROCK KARMEL PRESCHOOL",
  "SHEMROCK KIDSZONE",
  "SHEMROCK LOTUS PRESCHOOL",
  "SHEMROCK MEHARS PRESCHOOL",
  "SHEMROCK MILESTONES",
  "SHEMROCK PLUMS",
  "SHEMROCK PRERNA PRESCHOOL",
  "SHEMROCK SENIOR SECONDARY SCHOOL",
  "SHEMROCK SMILES PRESCHOOL",
  "SHEMROCK STEPPING STONES",
  "SHER SINGH PUBLIC SCHOOL",
  "SHERWOOD COLLEGE",
  "SHERWOOD CONVENT SCHOOL",
  "SHERWOOD PUBLIC SCHOOL",
  "SHERWOOD PUBLIC SCHOOL,",
  "SHETABDI PUBLIC SCHOOL",
  "SHIFALY INTERNATIONAL SCHOOL",
  "SHIGALLY HILL INTERNATIONAL ACADEMY",
  "SHIGALLY HILL INTERNATIONAL ACADEMY, UTTARAKHAND",
  "SHIKHAR SHISHU SADAN",
  "SHIKSHA BHARTI PUBLIC SCHOOL",
  "SHIKSHA BHARTI VIDYA NIKETAN",
  "SHIKSHA THE GURUKUL",
  "SHIKSHANTAR SR. SEC. SCHOOL",
  "SHIKSHYA NIKETAN",
  "SHILLONG PUBLIC SCHOOL",
  "SHIMLA PUBLIC SCHOOL",
  "SHIRDI SAI PUBLIC SCHOOL",
  "SHISHU NIKETAN MODEL SCHOOL",
  "SHISHU NIKETAN PUBLIC SCHOOL",
  "SHISHU NIKETAN PUBLIC SECONDARY SCHOOL",
  "SHISHU NISTHA NIKETAN",
  "SHISHU VIHAR HIGHER PRAIMARY SCHOOL",
  "SHISHUGHAR SHALA HIGHER PRIMARY SCHOOL",
  "SHISHUVAN ENGLISH MEDIUM SCHOOL",
  "SHIV DAYAL PUBLIC SCHOOL",
  "SHIV DEVI GIRLS HIGH SCHOOL",
  "SHIV JYOTI CONVENT SCHOOL",
  "SHIV JYOTI PUBLIC SCHOOL",
  "SHIV PUBLIC SENIOR SECONDARY SCHOOL",
  "SHIVA PUBLIC SCHOOL",
  "SHIVALIK MODEL SCHOOL",
  "SHIVALIK PUBLIC SCHOOL",
  "SHIVALIK PUBLIC SENIOR SECONDARY SCHOOL",
  "SHIVALIK WALA DOON SCHOOL",
  "SHIVAM HIGH SCHOOL",
  "SHIVAM KUMAR LEWIS MEMORIAL SCHOOL",
  "SHIVAM PUBLIC SCHOOL",
  "SHIVANI MATRICULATION SCHOOL",
  "SHIVANIAA PUBLIC SCHOOL",
  "SHIVDALE SCHOOL",
  "SHIVHARSH KISAN P.G. COLLEGE",
  "SHIVSHARDA PUBLIC SCHOOL",
  "SHRADHA PUBLIC SCHOOL",
  "SHRAVAN VIKAS MANDIR DEAF AND DUMB SCHOOL",
  "SHREE BHARATHI VIDYALAYA",
  "SHREE BHAVAN'S BHARTI PUBLIC SCHOOL",
  "SHREE CHAITHANYA PUBLIC SCHOOL",
  "SHREE DADIJI MANDIR TRUST PRABHAVATI PUBLIC SCHOOL",
  "SHREE DAKSHINAMURTI VINAY MANDIR",
  "SHREE GOUTAMCHAND KOTHARI JAIN HIGHER SECONDARY",
  "SHREE HANUMAN VYAYAM SHALA PUBLIC SCHOOL",
  "SHREE HINDOCHA HIGHER SECONDARY SCHOOL",
  "SHREE J.M. KANYA VIDHYALAYA",
  "SHREE JEE PUBLIC SECONDARY SCHOOL",
  "SHREE KHUBLAL PRAMILA VIDYA VIHAR",
  "SHREE KRISHNA PRANAMI PUBLIC SCHOOL",
  "SHREE MAHAVIR JAIN MODEL SCHOOL",
  "SHREE RAM PUBLIC SCHOOL",
  "SHREE SAI NATIONAL HIGH SCHOOL",
  "SHREE SANSKAR ACADEMY",
  "SHREE SARASSWATHI VIDHYAAH MANDHEER INSTITUTIONS",
  "SHREE SWAMI NARAYAN GURUKUL INTERNATIONAL",
  "SHREE SWAMINARAYAN ENGLISH SECONDARY SCHOOL",
  "SHREE SWAMINARAYAN GOODWILL SCHOOL",
  "SHREE SWAMINARAYAN GURUKUL",
  "SHREE SWAMINARAYAN HIGH SCHOOL",
  "SHREE SWAMINARAYAN INTERNATIONAL SCHOOL",
  "SHREE SWAMINARAYAN M.G.K. ENGLISH PRIMARY SCHOOL",
  "SHREE SWAMINARAYAN PRIMARY SCHOOL",
  "SHREE SWAMINARAYAN PUBLIC SCHOOL",
  "SHREE SWAMINARAYAN V.M. KOTECHA PRIMARY SCHOOL",
  "SHREE TYAGI MODERN PUBLIC SCHOOL",
  "SHREE VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
  "SHREEDI BAABHAA MATRICULATION SCHOOL",
  "SHREEJI PRATHMIK SHALA",
  "SHREEMAN VIDYALAYA",
  "SHREEVALLABH ASHRAM'S M.C.M. KOTHARI INTERNATIONAL GIRL'S RESIDENTIAL SCHOOL",
  "SHRESHTA PUBLIC SCHOOL MATHIKARE",
  "SHRI ADARSH SHIKSHAN SANSTHAN SR. SEC. SCHOOL GIDAWALA",
  "SHRI AGARSEN PUBLIC SCHOOL",
  "SHRI AJIT SENIOR SECONDARY SCHOOL",
  "SHRI ANDARAM PARIHAR HIGH SCHOOL",
  "SHRI AYYAPPA PUBLIC SCHOOL",
  "SHRI BALAJI INTERNATIONAL SCHOOL",
  "SHRI BHAWANI NIKETAN PUBLIC SCHOOL",
  "SHRI C.K. CHANDEL MEMORIAL HSS",
  "SHRI D.D. PATEL HIGH SCHOOL",
  "SHRI DUNGARGARH PUBLIC SCHOOL",
  "SHRI GUJARATI VIDYA MANDIR",
  "SHRI GURU GANGDEV JI MODEL SCHOOL",
  "SHRI GURU HARIKRISHAN PUBLIC SCHOOL",
  "SHRI GURU HARKRISHAN INTERNATIONAL PUBLIC SCHOOL",
  "SHRI GURU HARKRISHAN PUBLIC SCHOOL",
  "SHRI GURU NANAK DEV SIKH ACADEMY",
  "SHRI GURU NANAK PUBLIC BOYS INTER COLLEGE",
  "SHRI GURU RAM DAS KHALSA SR. SEC. SCHOOL",
  "SHRI GURU RAM RAI PUBLIC SCHOOL",
  "SHRI GURU RAMCHANDRA JHA PUBLIC SCHOOL",
  "SHRI GURU TEGH BAHADUR ACADEMY",
  "SHRI GURUNANAK PUBLIC SCHOOL",
  "SHRI HANUMAN VYAYAM SHALA PUBLIC SCHOOL",
  "SHRI J.N. PATEL HIGH SCHOOL",
  "SHRI JAGANNATH SHAH MEMORIAL PUBLIC SCHOOL",
  "SHRI K.N. SHAH HIGH SCHOOL & SMT. MANIBEN B.M. SHAH HIGHER SECONDARY SCHOOL",
  "SHRI KAILASHAT SINGHNIA HR. SEC SCHOOL",
  "SHRI KAILASHPAT SINGHANIA HIGHER SECONDAY SCHOOL",
  "SHRI KANTI PRAKASH SARASWATI VIDYA MANDIR",
  "SHRI KASHI VISHVANATH INTER COLLEGE",
  "SHRI KRISHNA PARNAMI PUBLIC SCHOOL",
  "SHRI KUMAVAT KSHATRIYA SECONDARY SCHOOL",
  "SHRI MAHARISHI VIDYA MANDIR",
  "SHRI MAHAVEER DIGAMBER JAIN HIGH SECONDARY SCHOOL",
  "SHRI MAHAVIR DIGAMBER JAIN BALIKA VIDYALAYA",
  "SHRI MAHAVIR JAIN PUBLIC SCHOOL",
  "SHRI MANJUNATHESHWARA CENTRAL SCHOOL",
  "SHRI MARULA SHANKAR HANDICAPPED SCHOOL",
  "SHRI MORESHWAR ABHYANKAR PUBLIC SCHOOL",
  "SHRI NIRMAL VIVEK SCHOOL",
  "SHRI PARVATI JAIN HIGH SCHOOL",
  "SHRI PASHWARNATH SR. SEC. SCHOOL",
  "SHRI PATCHEAPPANE SCHOOL",
  "SHRI PRAGYA PUBLIC SCHOOL",
  "SHRI RAM ASHRAM PUBLIC SCHOOL",
  "SHRI RAM PUBLIC SCHOOL",
  "SHRI RAMA BHARTI PUBLIC SCHOOL",
  "SHRI RAMJI LAL HIGHER SECONDARY SCHOOL",
  "SHRI RAMPRATAP MALPANI MOOK BADHIR VIDYALAYA",
  "SHRI RAMSWAROOP MEMORIAL PUBLIC SCHOOL",
  "SHRI RANGANATH INTERNATIONAL SCHOOL",
  "SHRI RASHTRIYA BAL VIDYALAYA",
  "SHRI S.N. SIDHESHWAR SENIOR SECONDARY PUBLIC SCHOOL",
  "SHRI SAMARTH SADGURU KISAN GIRI BABA VIDYALAY",
  "SHRI SATYA SAI PUBLIC SCHOOL",
  "SHRI SHALLESHWAR IC SARILA",
  "SHRI SHANKER MUMUKSHU VIDYAPEETH",
  "SHRI SHARDA ENGLISH MEDIUM SCHOOL",
  "SHRI SITARAM JAYANTI SR. SEC. SCHOOL",
  "SHRI TAGORE VIDYA MANDIR",
  "SHRI VIDYA JUNIOR COLLEGE",
  "SHRI VIVEKANANDA HIGH SCHOOL",
  "SHRI. MOTIRAMJI CHANDRBHANJI THAKARE SECONDARY AND HIGHER SECONDARY M.C.V.C. COLLEGE",
  "SHRIBABA MASTNATH PUBLIC SCHOOL",
  "SHRIBABA MASTNATH RESIDENTIAL PUBLIC SCHOOL",
  "SHRIMAD BHAGWAD GEETA VARISHT MADHMIK VIDHAYALA",
  "SHRIMANTA SHANKAR ACADEMY",
  "SHRINE PUBLIC SCHOOL",
  "SHRIPATRAO BHOSALE HIGH SCHOOL",
  "SHRIRAM PRIMARY SCHOOL",
  "SHRISHTI VIDYASHRAM SR.SEC SCHOOL",
  "SHUBAM ASSOCIATES",
  "SHWETA PUBLIC SCHOOL",
  "SHWETAMBER JAIN PUBLIC SCHOOL",
  "SHYAM BIHARI PUBLIC SCHOOL",
  "SHYAM LAL MEMORIAL HIGH SCHOOL",
  "SHYAMLAL.D.A.V.PUBLIC SCHOOL",
  "SIDDAGANGA PUBLIC SCHOOL",
  "SIDDARTH PUBLIC SCHOOL",
  "SIDDHARTH INTERNATIONAL PUBLIC SCHOOL",
  "SIDDHARTH PRE-UNIVERSITY COLLEGE",
  "SIDDHARTH PUBLIC SCHOOL",
  "SIDDHARTHA CONCEPT SCHOOL",
  "SIDDHARTHA INTERNATIONAL SCHOOL",
  "SIDDHARTHA JR. COLLEGE",
  "SIDDHARTHA LITTLE PUBLIC SCHOOL",
  "SIDDHARTHA PUBLIC SCHOOL",
  "SIDDHARTHA RESIDENTIAL HIGH SCHOOL",
  "SIDDHARTHA RESIDENTIAL SCHOOL",
  "SIDDHI VINAYAK INTERNATIONAL SCHOOL",
  "SIDHARTHA E.M. HIGH SCHOOL",
  "SIDHARTHA INTERNATIONAL SCHOOL",
  "SIDHU MEMORIAL PUBLIC SCHOOL",
  "SIKH MISSIONARY PUBLIC SCHOOL",
  "SIKKIM HIMALAYN ACADEMY",
  "SIKSHA NIKETAN",
  "SILAS INTERNATIONAL SCHOOL",
  "SILICON CITY PUBLIC SCHOOL",
  "SILVER BELLS SCHOOL",
  "SILVER CONVENT SCHOOL",
  "SILVER DROP HIGH SCHOOL",
  "SILVER HILLS PUBLIC SCHOOL",
  "SILVER OAK INTERNATIONAL SCHOOL",
  "SILVER OAK PUBLIC SCHOOL",
  "SILVER OAK SCHOOL",
  "SILVER OAK SCHOOL",
  "SILVER ROSE SCHOOL",
  "SILVERBELLS PUBLIC SCHOOL",
  "SILVERDALE PUBLIC SCHOOL",
  "SIMHAPURI PUBLIC SCHOOL",
  "SIMIGO INTERNATIONAL SCHOOL",
  "SINA NATH MEMORIAL CONVENT SCHOOL",
  "SINGAPORE INTERNATIONAL SCHOOL",
  "SINGHAD PUBLIC SCHOOL",
  "SINHGAD PUBLIC SCHOOL",
  "SIR C.R.R. PUBLIC SCHOOL",
  "SIR CHHOTU RAM SENIOR SECONDARY SCHOOL",
  "SIR CHOTU DAS PUBLIC SCHOOL",
  "SIR SYED MEMORIAL SCHOOL",
  "SIR SYED PUBLIC SCHOOL",
  "SISHU GRIHA ENGLISH SCHOOL",
  "SISHU NIKETAN PUBLIC SECONDARY SCHOOL",
  "SISHYA MATRIC HIGHER SECONDARY SCHOOL",
  "SISHYA SCHOOL",
  "SISHYA",
  "SISNAT PUBLIC SCHOOL",
  "SISTER NIVEDITA ENGLISH HIGH SCHOOL",
  "SISTER NIVEDITA SCHOOL",
  "SITA GRAMMAR SCHOOL",
  "SITA PUBLIC SCHOOL",
  "SITA RAMDAV PUBLIC SCHOOL",
  "SIVA SIVANI PUBLIC SCHOOL (DEVAR YAMJAL CAMPUS)",
  "SIVA SIVANI PUBLIC SCHOOL (UKKUNAGARAM CAMPUS)",
  "SIVA SIVANI PUBLIC SCHOOL",
  "SIVANAND PRAVEEN PUBLIC SCHOOL",
  "SIVANI PUBLIC SCHOOL",
  "SIVASIVANI PUBLIC SCHOOL,",
  "SKYLAND PUBLIC SCHOOL",
  "SKYLARK INTERNATIONAL SCHOOL",
  "SKYWORD PUBLIC SCHOOL",
  "SLOPE LAND PUBLIC SCHOOL",
  "SMALL ANGELS PLAYWAY SCHOOL",
  "SMALL WONDER PUBLIC SCHOOL",
  "SMALL WONDERS SCHOOL",
  "SMARTKIDZ PLAY SCHOOL",
  "SMITA PATIL PUBLIC SCHOOL",
  "SMT PARVATHAMMA SHAMANUR SHIVASHANKARAPPA ENGLISH MEDIUM RESIDENTIAL SCHOOL (PSSEMR)",
  "SMT. DHAN DEVI DAV PUBLIC SCHOOL",
  "SMT. DRAKSHAYANI KALYANSHETTAR PUBLIC SCHOOL",
  "SMT. HALAMMA SHAMANUR SHIVAPPA NURSERY PRIMARY AND HIGH SCHOOL",
  "SMT. JAIDEVI MEMORIAL PUBLIC SCHOOL",
  "SMT. JAYSHREEBEN VIRAMBHAI GODHANIYA",
  "SMT. KAMALA SAKLECHA GYAN MANDIR",
  "SMT. KARAN BAI DAV CENT. PUBLIC SCHOOL",
  "SMT. KESRI DEVI LOHIA PUBLIC SCHOOL",
  "SMT. KRISHNA MOOLCHAND MAHESWARI VIVEKANANDA VIDYALAYA",
  "SMT. LILAVATIB PODAR HIGH SCHOOL",
  "SMT. R.S.M. POONAWALA SARVAJANIK EXPERIMENTAL SCHOOL",
  "SMT. RAMRATI DEVI SENIOR SECONDARY SCHOOL",
  "SMT. S.A. RAO ENGLISH MEDIUM SCHOOL",
  "SMT. S.M. VAGHASIA ENGLISH MEDIUM SCHOOL",
  "SMT. TAISAHEB KANYA VIDYALAYA",
  "SMT.PARVATHAMMA SHAMANUR SHIVASHANKARAPPA ENGLISH MEDIUM RESIDENTIAL SCHOOL",
  "SNEHA OPPORTUNITY SCHOOL",
  "SNEHA SCHOOL",
  "SNVP GIRLS INTERNATIONAL SCHOOL",
  "SOBHANA PUBLIC SCHOOL",
  "SOBTIS PUBLIC SCHOOL",
  "SOCIAL CONVENT INTERNATIONAL SCHOOL",
  "SOCIAL MODEL HIGH SCHOOL",
  "SOCIETE PROGRESSISTE HIGH SCHOOL",
  "SOCIETY ARYANS PUBLIC SCHOOL",
  "SOCIETY FOR EDUCATION OF THE DEAF & BLIND",
  "SOHRAB PUBLIC SCHOOL",
  "SOLANKI PUBLIC SCHOOL",
  "SOLAR INTERNATIONAL SCHOOL",
  "SOLDIER SENIOR SECONDARY PUBLIC SCHOOL",
  "SOM CONVENT GURUKUL",
  "SOMAIYA SHISHU NIKETAN PRIMARY SCHOOL",
  "SOMANY VIDYA PEETH PUBLIC SCHOOL",
  "SOMERVILLE SCHOOL",
  "SONA MODERN PUBLIC SCHOOL",
  "SONIA INTERNATIONAL SCHOOL",
  "SOPHIA GIRLS' SCHOOL",
  "SOPHIA GIRLS' SENIOR SECONDARY SCHOOL",
  "SOPHIA HIGH SCHOOL",
  "SOPHIA SENIOR SECONDARY SCHOOL",
  "SOPPI UPPER DAPARIJA",
  "SORSFORT INTERNATIONAL SCHOOL",
  "SOUNDARARAJA VIDYALAYA",
  "SOUTH ACADEMY HIGH SCHOOL",
  "SOUTH DELHI PUBLIC SCHOOL",
  "SOUTH EAST ASIAN INTERNATIONAL SCHOOL",
  "SOUTH POINT PUBLIC SCHOOL",
  "SOUTH TOWN PUBLIC SCHOOL",
  "SOUTH VALLEY INTERNATIONAL SCHOOL",
  "SOUTHEND PUBLIC SCHOOL",
  "SPARKRILL INTERNATIONAL SCHOOL",
  "SPICER HIGHER SECONDARY SCHOOL",
  "SPRING BALES SENIOR SECONDARY PUBLIC SCHOOL",
  "SPRING DALE PUBLIC SCHOOL",
  "SPRING DALE SCHOOL",
  "SPRING DALE SENIOR SCHOOL",
  "SPRING DALE SENIOR SECONDARY PUBLIC SCHOOL",
  "SPRING DALES ENGLISH SCHOOL",
  "SPRING DALES PUBLIC SCHOOL",
  "SPRING DALES SCHOOL",
  "SPRING FIELD CONVENT SCHOOL",
  "SPRING FIELD PUBLIC SCHOOL",
  "SPRING MEADOWS PUBLIC SCHOOL",
  "SPRING VALLEY PUBLIC SCHOOL",
  "SPRING WOOD SCHOOL",
  "SPRINGDALE COLLEGE",
  "SPRINGDALE PUBLIC SCHOOL",
  "SPRINGDALE SCHOOL",
  "SPRINGDALE SENIOR SCHOOL",
  "SPRINGDALE SENIOR SECONDARY SCHOOL",
  "SPRINGER PUBLIC SCHOOL",
  "SPRINGFIELD SCHOOL",
  "SREE AYYAPPA PUBLIC SCHOOL",
  "SREE CHITRA PUBLIC SCHOOL",
  "SREE GOKULAM PUBLIC SCHOOL",
  "SREE NARAYAN PUBLIC SCHOOL",
  "SREE NARAYANA PUBLIC SCHOOL",
  "SREE NARAYANA VIDYAPEETAM PUBLIC SCHOOL",
  "SREE RAMA VIDYALAYA",
  "SREE SIDDAGANGA SCHOOL FOR BLIND",
  "SREE SIMHAPURI DAY SCHOOL",
  "SREE SWAMY VIVEKANANDA P.U. COLLEGE",
  "SREE VIDYANIKETHAN INTERNATIONAL SCHOOL",
  "SREE VIDYAPEETH",
  "SREENIDHI INTERNATIONAL SCHOOL",
  "SREEPURAM ENGLISH MEDIUM HIGH SCHOOL & JUNIOR COLLEGE",
  "SREYAS PUBLIC SCHOOL",
  "SRI ANNAIRANI CONVENT HIGHER SECONDARY SCHOOL",
  "SRI ARAVINDAR HIGHER SECONDARY SCHOOL",
  "SRI ARAVINDAR VIDHYALAYA MATRICULATION SCHOOL",
  "SRI ATMANANDA MEMORIAL SCHOOL",
  "SRI AUROBINDO INT EDU & RES CENTRE",
  "SRI AUROBINDO INTERNATIONAL CENTRE OF EDUCATION",
  "SRI AUROBINDO INTERNATIONAL SCHOOL",
  "SRI AUROBINDO NURSERY SCHOOL",
  "SRI AUROBINDO PUBLIC SCHOOL",
  "SRI AUROBINDO'S ROURKELA SCHOOL",
  "SRI B.M. PATIL PUBLIC SCHOOL",
  "SRI BALASAI RESIDENTIAL HIGH SCHOOL",
  "SRI BHARATHI MATRICHIGHER HIGHER SECONDARY SCHOOL",
  "SRI BHARATHY VIDYALAYA MATRICULATION SCHOOL",
  "SRI BHUVANDENDRA RESIDENTIAL SCHOOL",
  "SRI CHAITANYA BHARATHI PUBLIC SCHOOL",
  "SRI CHAITANYA TECHNO SCHOOL",
  "SRI CHIRONGI LAL HIGH SCHOOL",
  "SRI D.D.CHHIMWAL MEMORIAL PUBLIC SCHOOL",
  "SRI DASHMESH ACADEMY",
  "SRI DAULAT RAM PUBLIC SCHOOL",
  "SRI GIRIMARUTHI MAKKALA MANDIRA PUBLIC SCHOOL",
  "SRI GNANANANDA MATRIC. HR. SEC. SCHOOL",
  "SRI GNYANODAYA PUBLIC SCHOOL",
  "SRI GOMATHI AMBAL MATRICULATION HR SEC SCHOOL",
  "SRI GOUTHAMI PUBLIC SCHOOL",
  "SRI GURU GOBIND SINGH PUBLIC SCHOOL",
  "SRI GURU HARKISHAN PUBLIC SCHOOL",
  "SRI GURU HARKISHEN PUBLIC SCHOOL",
  "SRI GURU HARKRISHAN INTERNATIONAL SCHOOL",
  "SRI GURU HARKRISHAN PUBLIC SCHOOL",
  "SRI GURU HARKRISHAN SR. SEC. SCHOOL",
  "SRI GURU HARKRISHEN PUBLIC SCHOOL",
  "SRI GURU NANAK ACADEMY",
  "SRI GURU NANAK PUBLIC SCHOOL",
  "SRI GURU RAM RAI PUBLIC SCHOOL",
  "SRI GURU TEG BAHADUR PUBLIC SCHOOL",
  "SRI GURUHARKRISHAN PUBLIC SCHOOL",
  "SRI HARSHA VARDHANA PUBLIC SCHOOL",
  "SRI INTERNATIONAL SCHOOL",
  "SRI K.V. ENGLISH SCHOOL",
  "SRI KALIKAMBA HIGH SCHOOL",
  "SRI KODANDA RAMA SWAMY HIGH SCHOOL",
  "SRI KRISHNA INTERNATIONAL SCHOOL",
  "SRI KRISHNA MISSION SCHOOL",
  "SRI KRISHNA PUBLIC SCHOOL",
  "SRI KRISHNA PUBLIC SENIOR SECONDARY SCHOOL",
  "SRI KRISHNAVENI SCHOOL",
  "SRI KUMARAN PUBLIC SCHOOL",
  "SRI LAKSHMI BRILLIANT SCHOOL",
  "SRI LAKSHMI INTERNATIONAL PUBLIC SCHOOL",
  "SRI MUKHA PUBLIC SCHOOL",
  "SRI NALANDA PUBLIC SCHOOL",
  "SRI NAVADURGA ENGLISH HIGH SCHOOL",
  "SRI NIKENTAN JI PUBLIC SCHOOL",
  "SRI NIWASA RAMANUJAN PUBLIC SCHOOL",
  "SRI PARSHWANATH JAIN VIDYALAYA",
  "SRI POOJITHA PUBLIC SCHOOL",
  "SRI PRAKASH VIDYANIKETAN",
  "SRI R M JAIN VIDYASHARAM",
  "SRI R.M. JAIN VIDHYAPEETH",
  "SRI R.V. SCHOOL",
  "SRI RAJ HIGH SCHOOL",
  "SRI RAMA RURAL HIGH SCHOOL",
  "SRI RAMAKRISHNA PARAMAHAMSAR ENGLISH HIGH SCHOOL",
  "SRI RAMAKRISHNA PUBLIC SCHOOL",
  "SRI RAMAKRISHNA VIDYA MANDIR",
  "SRI RAMAKRISHNA VIDYALAYA HIGHER SECONDARY SCHOOL",
  "SRI RAMANA VIDYALAYA MONTESSORI MATRICULATION SCHOOL",
  "SRI RAMKRISHNA HIGH SCHOOL",
  "SRI RANGANATH INTERNATIONAL SCHOOL (SRI)",
  "SRI RANGANATH INTERNATIONAL SCHOOL",
  "SRI SADINENI CHOWDARAIAH RESIDENTIAL PUBLIC SCHOOL (SSCRP)",
  "SRI SAI BABA INTERNATIONAL PUBLIC SCHOOL",
  "SRI SAI CHAITANYA HIGH SCHOOL",
  "SRI SAI GRAMMAR HIGH SCHOOL",
  "SRI SAI KRISHNA ENGLISH MEDIUM SCHOOL",
  "SRI SAI PUBLIC SCHOOL",
  "SRI SAI PUBLIC SCHOOL,",
  "SRI SAKTHI VIKAAS MATRICULATION SCHOOL",
  "SRI SAMPOURNA VIDYALAYAM",
  "SRI SANKARA VIDYALAYA HIGHER SECONDARY SCHOOL",
  "SRI SATHYA SAI GURUKULAM E.M. SCHOOL",
  "SRI SATHYA SAI GURUKULAM ENGLISH MEDIUM SCHOOL",
  "SRI SATHYA SAI HIGHER SECONDARY SCHOOL LOGO",
  "SRI SATHYA SAI KIDDIES ABODE",
  "SRI SATHYA SAI LOKA SEVA BOYS HIGH SCHOOL",
  "SRI SATHYA SAI PUBLIC SCHOOL",
  "SRI SATHYA SAI VIDYA NIKETAN",
  "SRI SATHYA SAI VIDYA VIHAR ENG. MED. SCHOOL",
  "SRI SATHYA SAI VIDYA VIHAR",
  "SRI SATYA SAI SCHOOL",
  "SRI SHANTI VIDYA MANDIR HIGH SCHOOL",
  "SRI SHARADA PUBLIC SCHOOL",
  "SRI SHUDDADWAITA VAISHNAV HIGH SCHOOL",
  "SRI SIDDHI VINAYAKA RESIDENTIAL SCHOOL",
  "SRI SOBHA PUBLIC SCHOOL",
  "SRI SRI RAVISHANKAR BAL MANDIR",
  "SRI SRI RAVISHANKAR VIDYA MANDIR",
  "SRI SRI RAVISHANKAR VIDYAMANDIR",
  "SRI SUBABHARATHI MATRICULATION SCHOOL",
  "SRI SUKHMANI INTERNATIONAL SCHOOL",
  "SRI SUNANDA PUBLIC SCHOOL",
  "SRI SURYA CHANDRA PUBLIC SCHOOL",
  "SRI SWAMI CHINNA JEEYAR INTERNATIONAL SCHOOL",
  "SRI TARALABALU JAGADGURU RESIDENTIAL SCHOOL (S.T.J.)",
  "SRI TARALABALU SCHOOL",
  "SRI USHODAYA RESIDENTIAL SCHOOL",
  "SRI V.D.S. JAIN HIGHER SECONDARY SCHOOL",
  "SRI VANI EDUCATION CENTRE",
  "SRI VANI HIGH SCHOOL",
  "SRI VANI PUBLIC SCHOOL",
  "SRI VENKATANAGA PUBLIC SCHOOL (S.V.N.)",
  "SRI VENU CENTRAL SCHOOL",
  "SRI VIDHYA ACADEMY INTERNATIONAL RESIDENTIAL SCHOOL",
  "SRI VIDHYA MANDIR MATRIC HIGHER SECONDARY SCHOOL",
  "SRI VIDYA MANDIR HIGHER SECONDARY SCHOOL",
  "SRI VIDYA PUBLIC SCHOOL",
  "SRI VIGNANIKETAN PUBLIC SCHOOL",
  "SRI VIGNYANIKETHAN PUBLIC SCHOOL",
  "SRI VISHWESHWARAIAH SCHOOL",
  "SRI VISWASANTHI ENGLISH MEDIUM SCHOOL",
  "SRI. MARKAZ PABLIC SCHOOL",
  "SRIBATSA HIGH SCHOOL",
  "SRIKRISHNA PUBLIC SCHOOL",
  "SRIMAYAPUR INTERNATIONAL SCHOOL",
  "SRINIVASA VIDYA NIKETAN",
  "SRIPRAKASH RESIDENTIAL SCHOOL,",
  "SRISADINENI CHOWDARAIAH RESIDENTIAL PUBLIC SCHOOL",
  "SRISHTI INTERNATIONAL MONTESSORI PLAY SCHOOL",
  "SRT D.A.V. PUBLIC SCHOOL",
  "SRUSHTI PUBLIC SCHOOL NELAMANGALA",
  "SRUTHI VIDHYODHAYA",
  "SSAM RIFLES PUBLIC SCHOOL",
  "ST ANSELMS NORTH CITY SCHOOL",
  "ST AUGUSTINES PUBLIC SCHOOL",
  "ST GEORGE'S CONVENT SCHOOL",
  "ST JOHNS PUBLIC SCHOOL",
  "ST JOSEPH’S CONVENT PUNJABI HIGH SCHOOL",
  "ST JOSEPH'S HIGHER SECONDARY SCHOOL",
  "ST JOSEPH'S KANNADA HIGHER PRIMARY SCHOOL",
  "ST MARIA GORETTI SCHOOL",
  "ST MARY'S SCHOOL",
  "ST MEERA'S PUBLIC SCHOOL",
  "ST MERRY PUBLIC SCHOOL",
  "ST PAUL'S PUBLIC SCHOOL",
  "ST PHILOMEN'S PUBLIC SCHOOL",
  "ST SOLDIER DIVINE PUBLIC SCHOOL",
  "ST STANISLAUS HIGH SCHOOL",
  "ST THERESA'S SCHOOL",
  "ST XAVIER SCHOOL",
  "ST XAVIER’S SR. SEC. SCHOOL",
  "ST XAVIER'S HIGH SCHOOL",
  "ST XAVIER'S PUBLIC SCHOOL",
  "ST. AGNES PRIMARY SCHOOL",
  "ST. ALBERT'S HIGH SCHOOL",
  "ST. AL-HANEEF EDUCATIONAL CENTRE",
  "ST. ALOYSIUS ANGLO - INDIAN HIGH SCHOOL",
  "ST. ALOYSIUS COLLEGE",
  "ST. ALOYSIUS CONVENT I.S.C. SCHOOL",
  "ST. AMTUL'S PUBLIC SCHOOL",
  "ST. ANDREWS PUBLIC SCHOOL",
  "ST. ANDREW'S PUBLIC SCHOOL",
  "ST. ANJANI'S PUBLIC SCHOOL",
  "ST. ANNE'S CONVENT SCHOOL",
  "ST. ANNE'S ENGLISH MEDIUM SCHOOL",
  "ST. ANNE'S GIRLS HIGH SCHOOL",
  "ST. ANNE'S HIGH SCHOOL",
  "ST. ANNE'S SCHOOL",
  "ST. ANNIE'S CONVENT SCHOOL",
  "ST. ANN'S CONVENT SCHOOL",
  "ST. ANN'S E.M. SCHOOL",
  "ST. ANN'S ENG. MED. SCHOOL",
  "ST. ANN'S HIGH SCHOOL",
  "ST. ANN'S PUBLIC SCHOOL",
  "ST. ANN'S SCHOOL",
  "ST. ANSELMS' SCHOOL",
  "ST. ANSELM'S SR SEC SCHOOL",
  "ST. ANTHONY PUBLIC SCHOOL",
  "ST. ANTHONY SENIOR SECONDARY SCHOOL",
  "ST. ANTHONY'S HIGHER SECONDARY SCHOOL",
  "ST. ANTHONY'S SCHOOL FOR GIRLS",
  "ST. ANTHONY'S SCHOOL",
  "ST. ANTHONY'S SECONDARY SCHOOL",
  "ST. ANTHONY'S SENIOR SECONDARY SCHOOL",
  "ST. ANTONY'S CONVENT SCHOOL",
  "ST. ANTONYS HIGH SCHOOL",
  "ST. ANTONY'S PUBLIC SCHOOL",
  "ST. ARNOLD'S SCHOOL",
  "ST. AUGUSTINE MIDDLE SCHOOL",
  "ST. AUGUSTINE’S, KALIMPONG",
  "ST. AUGUSTINE'S ENGLISH MEDIUM SCHOOL",
  "ST. BASIL'S HIGH SCHOOL",
  "ST. BERSOUMA'S PUBLIC SCHOOL",
  "ST. CARMEL HIGH SCHOOL",
  "ST. CATHERINES SCHOOL",
  "ST. CECILIAS PUBLIC SCHOOL",
  "ST. CHARLES BORROMEO CONVENT SCHOOL",
  "ST. CHARLES HIGH SCHOOL",
  "ST. CHARLES SCHOOL",
  "ST. CLARE'S SCHOOL",
  "ST. COLOMBO PUBLIC SCHOOL",
  "ST. CONRAD'S INTER COLLEGE",
  "ST. DOMINIC'S SCHOOL",
  "ST. DON BOSCO HIGH SCHOOL",
  "ST. DON BOSCO SCHOOL",
  "ST. EDMUND'S SCHOOL",
  "ST. FARID PUBLIC SCHOOL",
  "ST. FLOWERS ENGLISH MEDIUM SCHOOL",
  "ST. FRANCIS CONVENT HIGH SCHOOL",
  "ST. FRANCIS' CONVENT INTER COLLEGE",
  "ST. FRANCIS CONVENT SCHOOL",
  "ST. FRANCIS DE SALES PUBLIC SCHOOL",
  "ST. FRANCIS E.M. SCHOOL",
  "ST. FRANCIS ENGLISH MEDIUM HIGH SCHOOL",
  "ST. FRANCIS ENGLISH MEDIUM SCHOOL",
  "ST. FRANCIS ICSE SCHOOL",
  "ST. FRANCIS INTER COLLEGE",
  "ST. FRANCIS OF ASSISI HIGH SCHOOL",
  "ST. FRANCIS OF ASSISI SCHOOL",
  "ST. FRANCIS SCHOOL",
  "ST. FRANCIS XAVIER GIRLS' HIGH SCHOOL",
  "ST. GEE VARGHESH PUBLIC SCHOOL",
  "ST. GEORGE ENGLISH MEDIUM SCHOOL",
  "ST. GEORGE PUBLIC SCHOOL",
  "ST. GEORGE’S COLLEGE",
  "ST. GEORGE'S COLLEGE",
  "ST. GEORGE'S CONVENT SCHOOL",
  "ST. GEORGE'S GIRLS' GRAMMAR SCHOOL",
  "ST. GEORGE'S GRAMMAR SCHOOL",
  "ST. GERMN HIGH SCHOOL",
  "ST. GREGORIOS ENGLISH SCHOOL",
  "ST. GREGORIOS SCHOOL",
  "ST. GREGORIOUS PUBLIC SCHOOL",
  "ST. HELENA'S SCHOOL AND JUNIOR COLLEGE",
  "ST. HELENS GIRLS HIGHER SECONDARY SCHOOL",
  "ST. JAMES CONVENT SCHOOL",
  "ST. JAMES' CONVENT SCHOOL",
  "ST. JAMES SCHOOL",
  "ST. JEAN ENGLISH MEDIUM SCHOOL",
  "ST. JEEVAN PUBLIC SECONDARY SCHOOL",
  "ST. JOHN HIGHER SECONDARY SCHOOL",
  "ST. JOHN MARY VIANNEY ENGLISH MEDIUM SCHOOL",
  "ST. JOHN’S PUBLIC SCHOOL",
  "ST. JOHN'S BAPTIST PUBLIC SCHOOL",
  "ST. JOHN'S ENGLISH MEDIUM SCHOOL",
  "ST. JOHNS HIGH SCHOOL",
  "ST. JOHN'S HIGH SCHOOL",
  "ST. JOHN'S HIGHER SECONDARY SCHOOL",
  "ST. JOHN'S INTERNATIONAL RESIDENTIAL SCHOOL",
  "ST. JOHN'S PARISH SCHOOL",
  "ST. JOHN'S RESIDENTIAL SCHOOL",
  "ST. JOHN'S SCHOOL",
  "ST. JOHN'S SENIOR SECONDARY SCHOOL",
  "ST. JOHN'S SR SEC SCHOOL",
  "ST. JOHN'S VESTRY HIGHER SECONDARY SCHOOL",
  "ST. JOSEPH CONVENT BOYS SCHOOL",
  "ST. JOSEPH CONVENT JUNIOR SCHOOL",
  "ST. JOSEPH CONVENT SCHOOL",
  "ST. JOSEPH HIGH SCHOOL",
  "ST. JOSEPH HIGHER SECONDARY SCHOOL",
  "ST. JOSEPH INTERNATIONAL ACADEMY",
  "ST. JOSEPH LYCEUM",
  "ST. JOSEPH MATRICULATION SCHOOL",
  "ST. JOSEPH MIDDLE SCHOOL",
  "ST. JOSEPH PUBLIC SCHOOL",
  "ST. JOSEPH SCHOOL",
  "ST. JOSEPH SECONDARY SCHOOL",
  "ST. JOSEPH’S SCHOOL",
  "ST. JOSEPH’S SR. SEC. ENGLISH MEDIUM SCHOOL",
  "ST. JOSEPH’SHIGH SCHOOL",
  "ST. JOSEPH'S ACADEMY",
  "ST. JOSEPH'S AKSHARADHAMA SCHOOL",
  "ST. JOSEPH'S BOYS HIGH SCHOOL",
  "ST. JOSEPH'S BOYS SCHOOL",
  "ST. JOSEPH'S COLLEGE",
  "ST. JOSEPH'S CONVENT ENGLISH MEDIUM SCHOOL",
  "ST. JOSEPH'S CONVENT HIGH SCHOOL",
  "ST. JOSEPHS CONVENT HIGHER SECONDARY SCHOOL",
  "ST. JOSEPH'S CONVENT HIGHER SECONDARY SCHOOL",
  "ST. JOSEPH'S CONVENT SCHOOL",
  "ST. JOSEPH'S CONVENT SECONDARY SCHOOL",
  "ST. JOSEPH'S CONVENT SENIOR SECONDARY SCHOOL",
  "ST. JOSEPH'S CPNVENT HR. SEC. SCHOOL",
  "ST. JOSEPH'S ENGLISH MEDIUM AND PUBLIC SCHOOL",
  "ST. JOSEPH'S ENGLISH MEDIUM SCHOOL",
  "ST. JOSEPH'S ENGLISH SCHOOL",
  "ST. JOSEPH'S GIRLS' HIGH SCHOOL",
  "ST. JOSEPH'S HIGH SCHOOL",
  "ST. JOSEPH'S HIGHER SECONDARY SCHOOL",
  "ST. JOSEPH'S HR. SEC. SCHOOL",
  "ST. JOSEPH'S HR.SEC. SCHOOL",
  "ST. JOSEPH'S INTERNATIONAL SCHOOL",
  "ST. JOSEPHS PLAY SCHOOL",
  "ST. JOSEPH'S PUBLIC SCHOOL",
  "ST. JOSEPHS SCHOOL",
  "ST. JOSEPH'S SCHOOL",
  "ST. JOSEPH'S SEC. SCHOOL",
  "ST. JUDE'S CONVENT SCHOOL",
  "ST. JUDE'S PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "ST. JULIANA'S PUBLIC SCHOOL",
  "ST. KABIR CONVENT SCHOOL",
  "ST. KABIR PUBLIC SCHOOL",
  "ST. KABIR'S RESIDENTIAL & DAY SCHOOL",
  "ST. KAREN'S HIGH SCHOOL",
  "ST. LAWRENCE ENGLISH SCHOOL",
  "ST. LAWRENCE SENIOR SECONDARY SCHOOL",
  "ST. LOUIS DE GONZAGUE HIGH SCHOOL",
  "ST. M. G. PUBLIC SCHOOL",
  "ST. M.G. PUBLIC SCHOOL",
  "ST. MARIA GORETTI INTER COLLEGE",
  "ST. MARK'S COLLEGE",
  "ST. MARRYS SENIOR SEC. CONVENT SCHOOL",
  "ST. MARTHA'S PRE UNIVERSITY COLLEGE",
  "ST. MARTINS HIGH SCHOOL",
  "ST. MARTIN'S HIGH SCHOOL",
  "ST. MARTIN'S PUBLIC SCHOOL",
  "ST. MARY CONVENT SCHOOL",
  "ST. MARY INTERNATIONAL SCHOOL",
  "ST. MARY'S ACADEMY",
  "ST. MARY'S CONVENT HIGH SCHOOL",
  "ST. MARYS CONVENT SCHOOL",
  "ST. MARY'S CONVENT SCHOOL",
  "ST. MARY'S CONVENT SR. SEC. SCHOOL",
  "ST. MARY'S ENGLISH MEDIUM SCHOOL",
  "ST. MARY'S HIGH SCHOOL",
  "ST. MARY'S HIGHER SECONDARY SCHOOL",
  "ST. MARY'S I.C.S.E. SCHOOL",
  "ST. MARY'S INTERNATIONAL SCHOOL",
  "ST. MARY'S MATRICULATION HR.SEC. SCHOOL",
  "ST. MARY'S MATRICULATION SCHOOL",
  "ST. MARY'S MIDDLE SCHOOL",
  "ST. MARY'S PUBLIC SCHOOL",
  "ST. MARY'S RESIDENTIAL PUBLIC SCHOOL",
  "ST. MARY'S RESIDENTIAL SCHOOL",
  "ST. MARY'S SCHOOL",
  "ST. MARY'S SENIOR SECONDARY SCHOOL",
  "ST. MATHEWS ACADEMY",
  "ST. MATHEW'S PUBLIC SCHOOL",
  "ST. MATTHIAS HIGHER SECONDARY SCHOOL",
  "ST. MICHAELS CONVENT SCHOOL",
  "ST. MICHAEL'S CONVENT SCHOOL",
  "ST. MICHAEL'S GIRLS MATRICULATION HR.SEC. SCHOOL",
  "ST. MICHAELS MIDDLE SCHOOL",
  "ST. MICHAL ANGLO VIDYALAYA",
  "ST. MIRA'S ENGLISH SCHOOL",
  "ST. MOMINA SCHOOL",
  "ST. MOTHER TERESA MIDDLE SCHOOL",
  "ST. MOTHER TERESA SEC. SCHOOL",
  "ST. NORBERT'S SCHOOL",
  "ST. P.B.N. PUBLIC SCHOOL",
  "ST. PATRICK'S ACADEMY",
  "ST. PATRICK'S HIGHER SECONDARY SCHOOL",
  "ST. PATRICK'S JUNIOR COLLEGE",
  "ST. PATRICK'S SCHOOL",
  "ST. PAUL MITTAL SCHOOL",
  "ST. PAUL SCHOOL",
  "ST. PAUL SEC. SCHOOL",
  "ST. PAUL SECONDARY SCHOOL",
  "ST. PAUL’S SCHOOL",
  "ST. PAUL'S ACADEMY",
  "ST. PAUL'S CHURCH COLLEGE",
  "ST. PAUL'S CONVENT SCHOOL",
  "ST. PAUL'S ENGLISH SCHOOL",
  "ST. PAUL'S HIGH SCHOOL",
  "ST. PAUL'S HIGHER SECONDARY SCHOOL",
  "ST. PAULS MATRICULATION HIGHER SECONDARY SCHOOL",
  "ST. PAULS PUBLIC SCHOOL",
  "ST. PAUL'S SCHOOL",
  "ST. PAUL'S SENIOR SECONDARY SCHOOL",
  "ST. PETER SCHOOL",
  "ST. PETER'S ACADEMY",
  "ST. PETER'S CENTRAL PUBLIC SCHOOL",
  "ST. PETER'S COLLEGE",
  "ST. PETER'S ENGLISH MEDIUM SCHOOL",
  "ST. PETER'S HR. SEC. SCHOOL",
  "ST. PETER'S SCHOOL",
  "ST. PETER'S SR. SEC. SCHOOL",
  "ST. PHILOMENA PUBLIC SCHOOL",
  "ST. PHILOMENA'S ENGLISH PRIMARY SCHOOL",
  "ST. PHILOMENAS PUBLIC SCHOOL",
  "ST. PHILOMINAS GIRLS HIGH SCHOOL",
  "ST. PIUS X ENGLISH SCHOOL",
  "ST. PRAYAG PUBLIC SCHOOL",
  "ST. QUEEN MARY'S PUBLIC SCHOOL",
  "ST. R C SCIENTIFIC CONVENT SCHOOL",
  "ST. R.C. CONVENT SCHOOL",
  "ST. RAPHAEL'S GIRLS HIGHER SECONDARY SCHOOL",
  "ST. ROCKS GIRLS HIGH SCHOOL",
  "ST. SOLDIER DIVINE PUBLIC SCHOOL",
  "ST. SOLDIER ELITE CONVENT SCHOOL",
  "ST. SOLDIER INT CONVENT SCHOOL",
  "ST. SOLDIER PUBLIC SCHOOL",
  "ST. SOLDIER SENIOR SECONDARY PUBLIC SCHOOL",
  "ST. SOLIDER DIVINE PUBLIC SCHOOL",
  "ST. STANISLAUS HIGH SCHOOL",
  "ST. STEPHEN'S BES ANIA PUBLIC SCHOOL",
  "ST. STEPHENS PUBLIC SCHOOL",
  "ST. STEPHEN'S SCHOOL (RANAGHAT)",
  "ST. STEPHEN'S SCHOOL",
  "ST. TERESA CONVENT SCHOOL",
  "ST. TERESAS SCHOOL",
  "ST. TERESA'S SCHOOL",
  "ST. THERESA BACQ PUBLIC SCHOOL",
  "ST. THERESA NURSERY SCHOOL",
  "ST. THERESA SCHOOL",
  "ST. THERESA'S GIRLS HIGHER SECONDARY SCHOOL",
  "ST. THERESA'S MATRIC HIGHER SECONDARY SCHOOL",
  "ST. THERESA'S SCHOOL",
  "ST. THERESE'S SCHOOL",
  "ST. THOM SCHOOL",
  "ST. THOMAS – RESIDENTIAL SCHOOL",
  "ST. THOMAS' COLLEGE",
  "ST. THOMAS CONVENT SCHOOL",
  "ST. THOMAS ENGLISH HIGH SCHOOL",
  "ST. THOMAS ENGLISH SCHOOL",
  "ST. THOMAS HIGH SCHOOL",
  "ST. THOMAS HIGHER SECONDARY SCHOOL",
  "ST. THOMAS PUBLIC SCHOOL",
  "ST. THOMAS RESIDENTIAL SCHOOL",
  "ST. THOMAS S.S.SCHOOL",
  "ST. THOMAS SCHOOL",
  "ST. THOMAS SENIOR SECONDARY SCHOOL",
  "ST. THOMAS SENOIR SECONDARY SCHOOL",
  "ST. VINCENT PALLOTTI INTERNATIONAL RESIDENTIAL SCHOOL",
  "ST. VINCENT PALLOTTI SCHOOL",
  "ST. VINCENT PUBLIC SCHOOL",
  "ST. VINCENT’S ORIYA MEDIUM PRIMARY SCHOOL",
  "ST. VINCENT'S CONVENT SCHOOL",
  "ST. VINCENT'S HIGH & TECHNICAL SCHOOL",
  "ST. VINCENT'S SCHOOL",
  "ST. VIVEKANAND PUBLIC SCHOOL",
  "ST. XAVIER HIGH SCHOOL",
  "ST. XAVIER INTERNATIONAL SCHOOL",
  "ST. XAVIER SCHOOL",
  "ST. XAVIER",
  "ST. XAVIER’S GROUP OF SCHOOL",
  "ST. XAVIER’S SENIOR SECONDARY SCHOOL",
  "ST. XAVIER’S WORLD SCHOOL FOR GIRLS",
  "ST. XAVIER’S WORLD SCHOOL",
  "ST. XAVIERA'S SENIOR SECONDARY SCHOOL (CBSE)",
  "ST. XAVIER'S - HIGH SCHOOL",
  "ST. XAVIER'S CONVENT SCHOOL",
  "ST. XAVIER'S ENGLISH SCHOOL",
  "ST. XAVIER'S HIGH SCHOOL (ICSE)",
  "ST. XAVIERS HIGH SCHOOL",
  "ST. XAVIER'S HIGH SCHOOL",
  "ST. XAVIER'S HIGH SSHOOL",
  "ST. XAVIER'S HIGHER SECONDARY SCHOOL",
  "ST. XAVIER'S INTERNATIONAL SCHOOL",
  "ST. XAVIERS JUNIOR/SENIOR SCHOOL",
  "ST. XAVIER'S PUBLIC SCHOOL",
  "ST. XAVIERS SCHOOL",
  "ST. XAVIER'S SCHOOL",
  "ST. XAVIER'S SENIOR SECONDARY SCHOOL",
  "ST. XAVIER'S UPPER PRIMARY SCHOOL",
  "ST. XAVIER'S WORLD SCHOOL (BEST CBSE SCHOOL IN INDIA)",
  "ST. XAVIER'S",
  "ST. YASH PUBLIC SCHOOL",
  "ST.ALOYSIUS ANGLO-INDIANHIGH SCHOOL,",
  "ST.ANN'S CONVENT SCHOOL,",
  "ST.ANN'S ENGLISH MEDIUM SCHOOL,",
  "ST.ANSELM'S SCHOOL",
  "ST.ATTRI PUBLIC SCHOOL",
  "ST.CARMEL HIGH SCHOOL",
  "ST.CARMEL SCHOOL",
  "ST.EZRA INTERNATIONAL SCHOOL",
  "ST.FARID PUBLIC SCHOOL",
  "ST.JOSEPH PUBLIC SCHOOL",
  "ST.JOSEPH'SHIGH SCHOOL,",
  "ST.KABIR PUBLIC SCHOOL",
  "ST.MARY'S PUBLIC SCHOOL",
  "ST.MARY'S PUBLIC SCHOOL,",
  "ST.MARY'S SCHOOL",
  "ST.PATRICK MATRICULATION HIGHER SECONDARY SCHOOL",
  "ST.PAUL'S SCHOOL",
  "ST.PAUL'S SENIOR SECONDARY SCHOOL",
  "ST.PETER'S ACADEMY",
  "ST.PETER'S SCHOOL",
  "ST.SOLIDER PARADISE PUBLIC SCHOOL",
  "ST.STEPHEN’S SCHOOL",
  "ST.XAVIER'S HIGH SCHOOL",
  "STANDARD PUBLIC SCHOOL",
  "STANI MEMORIAL PUBLIC SCHOOL",
  "STAR MATRICULATION HIGHER SECONDARY SCHOOL",
  "STAR PLUS CONVENT SCHOOL",
  "STAREX PLAY SCHOOL",
  "STATE PUBLIC SCHOOL",
  "STATUS PUBLIC SCHOOL",
  "STEELMANS PUBLIC SCHOOL",
  "STELLA MARIS CONVENT HIGH SCHOOL",
  "STELLA MARIS CONVENT SECONDARY SCHOOL",
  "STELLA MARIS PUBLIC SCHOOL",
  "STELLA MARY HIGHER SECONDARY SCHOOL",
  "STEP BY STEP HIGH SCHOOL",
  "STERLING TAEKWONDO SCHOOL",
  "STEWART SCHOOL",
  "STONEHILL INTERNATIONAL SCHOOL",
  "STRAWBERRY FIELDS WORLD SCHOOL",
  "STRISTYS",
  "STT.XAVIER'S SCHOOL",
  "STUDY WELL PUBLIC SCHOOL",
  "SUBHASH PUBLIC SCHOOL",
  "SUBHASH TAYAL PUBLIC SCHOOL",
  "SUBRAHMANYA MODEL HIGH SCHOOL",
  "SUBRAMANIA BHARATHI ENGLISH HIGH SCHOOL",
  "SUDESH VATIKA CONVENT SCHOOL",
  "SUJATHA PUBLIC SCHOOL",
  "SUJATHA SCHOOL",
  "SUKHRAM MEMORIAL PUBLIC SCHOOL",
  "SULTAN PUBLIC SCHOOL",
  "SULTAN-UL-ULOOM PUBLIC SCHOOL",
  "SUMAN DAY BOARDING SENIOR SECONDARY SCHOOL",
  "SUMER MAL JAIN PUBLIC SCHOOL",
  "SUMER SINGH PUBLIC SCHOOL",
  "SUMMER FIELDS SCHOOL",
  "SUMMER HILL CONVENT SCHOOL",
  "SUMMERFIELD SCHOOL",
  "SUN LINE PUBLIC SCHOOL",
  "SUN SHINE BOARDING SCHOOL",
  "SUN SHINE RESIDENTIAL PUBLIC SCHOOL",
  "SUN VALLEY INTERNATIONAL SCHOOL",
  "SUNDARGARH PUBLIC SCHOOL",
  "SUNFLOWER BOARDING SCHOOL",
  "SUNGLOW INTERNATIONAL SCHOOL",
  "SUNRISE INTERNATIONAL RESIDENTIAL SCHOOL",
  "SUNRISE INTERNATIONAL SCHOOL",
  "SUNRISE PUBLIC SCHOOL",
  "SUNSHINE CONVENT SCHOOL",
  "SUNSHINE MISSION SCHOOL",
  "SUNSHINE PREPARATORY HIGH SCHOOL",
  "SUNSHINE PREPRATORY HIGH SCHOOL",
  "SUNSHINE PRIMARY AND HIGH SCHOOL",
  "SUNSHINE PUBLIC SCHOOL",
  "SUNWAY SCHOOL",
  "SUPERME PUBLIC SCHOOL",
  "SURAJ SCHOOL",
  "SURAJ SENIOR SECONDARY SCHOOL",
  "SURAJ SR. SEC. SCHOOL",
  "SURAJ SR.SEC.SCHOOL",
  "SUREKHA PRAKASH BHAI PUBLIC SCHOOL",
  "SURESH CHAND AGARWAL MEMORIAL PUBLIC SCHOOL",
  "SURI PUBLIC AND C.M.M. HIGH SCHOOL",
  "SURRENDRA PUBLIC SCHOOL",
  "SURYA CONVENT SCHOOL",
  "SURYA PUBLIC SCHOOL",
  "SURYODAYA INTERNATIONAL PUBLIC SCHOOL",
  "SUSHIL HARI INTERNATIONAL RESIDENTIAL SCHOOL",
  "SUTLEJ PUBLIC SR SEC SCHOOL",
  "SWADIM PUBLIC SCHOOL",
  "SWAMI ANAND MURTI UMA BHARTI PUBLIC SCHOOL",
  "SWAMI HARIHARANAND PUBLIC SCHOOL",
  "SWAMI HARSEVANAND PUBLIC SCHOOL",
  "SWAMI JANKI SARAN PUBLIC SCHOOL",
  "SWAMI KESHWANAND MEMORIAL PUBLIC SCHOOL",
  "SWAMI KESHWANAND SENIOR SECONDARY SCHOOL",
  "SWAMI MOHAN DASS MODEL SCHOOL",
  "SWAMI RAM TIRTHA PUBLIC HIGH SCHOOL",
  "SWAMI SANT DASS PUBLIC SCHOOL",
  "SWAMI SHARDHANAND DAV CENTENARY PUBLIC SCHOOL",
  "SWAMI UMA BHARTI PUBLIC SCHOOL",
  "SWAMI VIVEKANAND BAL NIKETAN SECONDERY SCHOOL",
  "SWAMI VIVEKANAND INTERNATIONAL SCHOOL",
  "SWAMI VIVEKANAND PUBLIC SCHOOL",
  "SWAMI VIVEKANAND SR. SEC. SCHOOL",
  "SWAMI VIVEKANAND VIDYAPITH",
  "SWAMI VIVEKANANDA BANA BHARTI",
  "SWAMI VIVEKANANDA PUBLIC SCHOOL",
  "SWAMI VIVEKANANDA SHIKSHAHRAM",
  "SWAMINARAYAN DHAM INTERNATIONAL SCHOOL",
  "SWAMINARAYAN HIGHER SECONDARY SCHOOL",
  "SWAMINARAYAN INTERNATIONAL SCHOOL",
  "SWAMY VIVEKANANDA MATRICULATION HIGHER SECONDARY SCHOOL",
  "SWARAJ INDIA PUBLIC SCHOOL",
  "SWARANJALI PUBLIC SCHOOL",
  "SWARGARANI SCHOOL",
  "SWARGIA RAJA BAHADUR SINGH KHETRI PUBLIC SCHOOL",
  "SWARN PUBLIC SCHOOL",
  "SWARNANDHRA PUBLIC SCHOOL",
  "SWARNIM PUBLIC SCHOOL",
  "SWARUP FOUNDATION D.A.V. PUBLIC SCHOOL",
  "SWAYAM CAMBRIDGE INTERNATIONAL SCHOOL",
  "SYMBIOSIS PUBLIC SCHOOL",
  "SYNA INTERNATIONAL SCHOOL",
  "SYNOD HIGH SCHOOL",
  "SYRIAN JACOBITE PUBLIC SCHOOL",
  "T.D.T.A. RANJI AARON MEMORIAL TRUST HIGHER SECONDARY SCHOOL",
  "T.K.M. CENTENARY PUBLIC SCHOOL",
  "T.K.M. PUBLIC SCHOOL",
  "T.N.J. INTERNATIONAL SCHOOL",
  "T.R.H. PUBLIC SCHOOL",
  "T.S.S.M. SECONDARY SCHOOL",
  "T.T. PUBLIC SCHOOL",
  "T.V.S. ACADEMY MONTESSORI AND ENGLISH MEDIUM SCHOOL",
  "T.V.S. ACADEMY",
  "T.V.S. SCHOOL",
  "TAGORE ACADEMY",
  "TAGORE INTERNATIONAL SCHOOL",
  "TAGORE INTERNATIONAL SMART SCHOOL",
  "TAGORE INTERNATIONAL SR. SEC. SCHOOL",
  "TAGORE MODERN PUBLIC SCHOOL",
  "TAGORE PUBLIC SCHOOL",
  "TAGORE SR. SEC. SCHOOL",
  "TAGORE VIDYA MANDIR",
  "TAKSHASHILA PUBLIC SCHOOL",
  "TAKSHASHILA RESIDENTIAL SCHOOL",
  "TAKSHASHILA VIDYALAYA",
  "TAKSHASILA PUBLIC SCHOOL",
  "TAKSHILA PUBLIC SCHOOL",
  "TAKTSE INTERNATIONAL SCHOOL",
  "TALENT PUBLIC SCHOOL",
  "TAMLUK HAMILTON HIGH SCHOOL P.S. TAMLUK",
  "TAPOBAN HIGH SCHOOL",
  "TAPODHANI PUBLIC SCHOOL",
  "TAPTI VALLEY INTERNATIONAL SCHOOL",
  "TASHI NAMGYAL ACADEMY",
  "TATA CHEM D.A.V. PUBLIC SCHOOL",
  "TATA D.A.V. PUBLIC SCHOOL",
  "TATACHEM D.A.V. PUBLIC SCHOOL",
  "TAURIAN WORLD SCHOOL",
  "TAYLORHIGH SCHOOL AND JUNIOR COLLEGE,",
  "TECHNO INDIA GROUP PUBLIC SCHOOL",
  "TECNIA INTERNATIONAL SCHOOL",
  "TELLICHERRY PUBLIC SCHOOL",
  "TENDER HEART SCHOOL",
  "TENDONG EDUCATIONAL INSTITUTE",
  "TERESA PUBLIC SCHOOL",
  "THAKUR INTERNATIONAL SCHOOL",
  "THAKUR PUBLIC SCHOOL",
  "THAMARAI INTERNATIONAL SCHOOL",
  "THE ADITHYA BIRLA PUBLIC SCHOOL",
  "THE ADITYA BIRLA PUBLIC SCHOOL",
  "THE AIR FORCE SCHOOL",
  "THE ARYANS SCHOOL",
  "THE ASSAM VALLEY SCHOOL",
  "THE ASSEMBLY OF GOD SCHOOL",
  "THE B AVAB F. PETIT ' HIGH SCHOOL",
  "THE BANYAN TREE IB WORLD SCHOOL",
  "THE BANYAN TREE WORLD SCHOOL",
  "THE BHARDWAJ HIGH SCHOOL",
  "THE BISHOP'S CO-ED SCHOOL",
  "THE BISHOP'S SCHOOL",
  "THE BLUE MOUNTAIN SCHOOL",
  "THE BRITISH CO-ED HIGH SCHOOL",
  "THE BRITISH SCHOOL",
  "THE CAMFORD INTERNATIONAL SCHOOL",
  "THE CATHEDRAL HIGH SCHOOL",
  "THE CAUVERY PUBLIC SCHOOL",
  "THE DALY COLLEGE",
  "THE DELHI PUBLIC SCHOOL SOCIETY",
  "THE DIAMOND JUBILEE HIGH SCHOOL",
  "THE DOON DHRUVA PUBLIC SCHOOL",
  "THE DOON SCHOOL",
  "THE EARTH PUBLIC SCHOOL",
  "THE EASTWOOD HIGH SCHOOL",
  "THE EMERALD HEIGHTS INTERNATIONAL SCHOOL",
  "THE FRANK ANTHONY PUBLIC SCHOOL",
  "THE FRONTLINE ACADEMY MATRICULATION HR. SEC. SCHOOL",
  "THE FUTURE KID'S SCHOOL",
  "THE GLOBAL PUBLIC SCHOOL",
  "THE GREEN HILLS PUBLIC SCHOOL",
  "THE GRILLAGE PUBLIC SCHOOL",
  "THE GURUKUL SENIOR SECONDARY SCHOOL",
  "THE GURUKUL",
  "THE HILLTOP DAY BOARDING SCHOOL",
  "THE HIMALAYAN PUBLIC SCHOOL",
  "THE HYDERABAD PUBLIC SCHOOL",
  "THE INDIAN ACADEMY",
  "THE INDIAN PUBLIC SCHOOL",
  "THE INDIAN SCHOOL",
  "THE INTERNATIONAL SCHOOL OF HYDERABAD",
  "THE INTERNATIONAL SCHOOL",
  "THE JAIN INTERNATIONAL SCHOOL",
  "THE JAINTPUR PUBLIC SCHOOL",
  "THE K T S PUBLIC SCHOOL",
  "THE KANTA HIGH SCHOOL",
  "THE KOSALA SCHOOL",
  "THE KUBER PUBLIC SCHOOL",
  "THE LAIDLAW MEMORIAL SCHOOL AND JUNIOR COLLEGE",
  "THE LAWRENCE PUBLIC SCHOOL",
  "THE LAWRENCE SCHOOL",
  "THE LAWRENCEHILLS",
  "THE LEXICON INTERNATIONAL SCHOOL",
  "THE LITTLE KINGDOM SENIOR SCHOOL",
  "THE LORDS' INTERNATIONAL SCHOOL",
  "THE LUCKNOW PUBLIC COLLEGIATE DAY BOARDING CUM RESIDENTIAL PUBLIC SCHOOL",
  "THE LUCKNOW PUBLIC COLLEGIATE DAY BOARDING PUBLIC SCHOOL",
  "THE LUCKNOW PUBLIC COLLEGIATE",
  "THE M.M.HIGHER SECONDARY SCHOOL",
  "THE MADANI PUBLIC SCHOOL",
  "THE MAHINDRA UNITED WORLD COLLEGE",
  "THE MANIK PUBLIC SCHOOL",
  "THE MANSA RAM SENIOR SECONDARY SCHOOL",
  "THE MASCOT SCHOOL",
  "THE MILLENIUM SCHOOL",
  "THE MILLENNIUM SCHOOL",
  "THE MODEL PUBLIC SCHOOL",
  "THE MONARCH INTERNATIONAL SCHOOL",
  "THE NALGONDA PUBLIC SCHOOL",
  "THE NANDYAL PUBLIC SCHOOL",
  "THE NATIONAL BOYS HIGH SCHOOL",
  "THE NAVIN RASHTRA BHARATI VIDYALAYA",
  "THE NEW CAMBRIDGE ENGLISH SCHOOL",
  "THE NEW CAMBRIDGE PUBLIC SCHOOL",
  "THE NEW DIGAMBER PUBLIC SCHOOL",
  "THE NEW TULIP INTERNATIONAL SCHOOL",
  "THE NOBLE SCHOOL",
  "THE OXFORD ENGLISH SCHOOL",
  "THE PARADISE INTERNATIONAL SCHOOL",
  "THE PEEPAL GROVE SCHOOL",
  "THE PILLARS PUBLIC SENIOR SECONDARY SCHOOL",
  "THE PIONEER SCHOOL",
  "THE PLAY SCHOOL",
  "THE PRAJNA INTERNATIONAL SCHOOL",
  "THE PRESIDENCY PUBLIC SCHOOL",
  "THE PUNJAB PUBLIC SCHOOL",
  "THE RADIANT WAY SCHOOL",
  "THE RAJAS INTERNATIONAL SCHOOL",
  "THE RAJASTHAN SCHOOL",
  "THE RAJKUMAR COLLEGE",
  "THE REGENCY PUBLIC SCHOOL",
  "THE SACRED HEART HIGH SCHOOL",
  "THE SACRED HEART PUBLIC SCHOOL",
  "THE SACREDHEARTHIGH SCHOOL",
  "THE SAGAR SCHOOL",
  "THE SANSKAAR VALLEY SCHOOL",
  "THE SCHOLAR HIGH SCHOOL",
  "THE SCHOLAR'S HOME",
  "THE SCINDIA SCHOOL",
  "THE SCOTTISH SCHOOL",
  "THE SECUNDERABAD PUBLIC SCHOOL",
  "THE SECUNDRABAD HIGH SCHOOL",
  "THE SENIOR SCHOLAR'S SCHOOL",
  "THE SENIOR STUDY -II SCHOOL",
  "THE SHARPAGE HIGER SECONDARY SCHOOL",
  "THE SHISHUKUNJ INTERNATIONAL SCHOOL",
  "THE SHRI RAM SCHOOL",
  "THE SIVAKASI LIONS MATRICULATION HR. SEC. SCHOOL",
  "THE SKY SCHOOL",
  "THE ST. XAVIER'S SCHOOL",
  "THE STUDY SCHOOL",
  "THE SUDARSHAN VIDYA MANDIR I.C.S.E. ACADEMY",
  "THE T.V.S. ACADEMY",
  "THE TIBETAN SOS CHILDREN'S VILLAGE SCHOOL",
  "THE UNIQUE ACADEMY",
  "THE VALLEY SCHOOL",
  "THE VELAMMAL INTERNATIONAL SCHOOL",
  "THE VIDHYANJALI INTERNATIONAL SCHOOL",
  "THE VIKASA SCHOOL",
  "THE VILLAGE INTERNATIONAL SCHOOL",
  "THE VIVERLY PUBLIC SCHOOL",
  "THE VIZAG INTERNATIONAL SCHOOL",
  "THE WALDEN POND SCHOOL",
  "THEHYDERABAD PUBLIC SCHOOL",
  "THILLAI MATRICULATION SCHOOL",
  "THIRUVALLUVAR GOVT. GIRLS HIGHER SEC SCHOOL",
  "THIRUVALLUVAR MATRICULATION SCHOOL",
  "THOLIKAPPIYANAR MIDDLE SCHOOL",
  "THREE DOTS SENIOR SECONDARY SCHOOL",
  "THRIVENI ACADEMY",
  "THUABARI LP SCHOOL",
  "THUNCHAN SMARAKA PUBLIC SCHOOL",
  "TIBETAIN'S CHILDERN'S VILLAGE SCHOOL",
  "TIBETAN CHILDREN'S VILLAGE SCHOOL",
  "TILAK INTERNATIONAL SCHOOL",
  "TIMBI PRIMARY SCHOOL",
  "TIMES COLLEGE OF +2 SCIENCE AND COMMERCE",
  "TIMPANY SCHOOL",
  "TINSUKIA ENGLISH ACADEMY",
  "TINU PUBLIC SCHOOL",
  "TINY BLOSSOMS",
  "TINY DALE SCHOOL",
  "TINY TOTS ADVENTIST SCHOOL",
  "TINY TOTS PLAYWAY SCHOOL",
  "TINY TOTS PUBLIC SCHOOL",
  "TINY TOTS SCHOOL",
  "TINY TOTS SECONDARY SCHOOL",
  "TIPPASANDRA OXFORD PUBLIC SCHOOL",
  "TITIKSHA PUBLIC SCHOOL",
  "TOC H PUBLIC SCHOOL",
  "TODDLERS INTERNATIONAL SCHOOL",
  "TOMI ENGLISH MEDIUM SCHOOL",
  "TOMOAE PRIMARY ENGLISH MEDIUM SCHOOL",
  "TOUCH WOOD SCHOOL",
  "TOWN HIGH SCHOOL",
  "TRANSLAM ACADEMY INTERNATIONAL",
  "TRH PUBLIC SCHOOL",
  "TRIBUNE MODEL SCHOOL",
  "TRINITY INTERNATIONAL SCHOOL",
  "TRINITY LYCEUM SCHOOL",
  "TRINITY PUBLIC SCHOOL",
  "TRINITY SCHOOL",
  "TRIO WORLD SCHOOL",
  "TRIVANDRUM INTERNATIONAL SCHOOL",
  "TRIVENI ACADEMY",
  "TRIVENI MEMORIAL SR. SEC. SCHOOL",
  "TRIVENI PUBLIC SCHOOL",
  "TRUEMAN ENGLISH SCHOOL",
  "TSS INTERNATIONAL SCHOOL",
  "TULA'S INTERNATIONAL SCHOOL",
  "TULI PUBLIC SCHOOL",
  "TULIPS INTERNATIONAL SR. SEC. SCHOOL",
  "TULSI PUBLIC SCHOOL",
  "TULSI VIDYA BHARATI PUBLIC SCHOOL",
  "TULSIRAM MAHESHWARI PUBLIC SCHOOL",
  "TUNBRIDGE HIGH SCHOOL",
  "TURA PUBLIC SCHOOL",
  "TURU PUBLIC SCHOOL",
  "TUSA SARDAR PUBLIC SCHOOL",
  "TWINKLERS SCHOOL",
  "TWINKLING STARS PLAY SCHOOL",
  "TYAGI PUBLIC SCHOOL",
  "U.C.S.K.M. SCHOOL",
  "U.S.P.C. JAIN PUBLIC SCHOOL",
  "UCSKM PUBLIC SCHOOL",
  "UDAISHWAR PUBLIC SCHOOL",
  "UDAYA BHARATI PUBLIC SCHOOL",
  "UDAYA PUBLIC SCHOOL",
  "UJWALA UPPER PRIMARY SCHOOL",
  "UMA RANA PUBLIC SCHOOL",
  "UMMAT PUBLIC SCHOOL",
  "UNION PRIMARY SCHOOL",
  "UNISON WORLD SCHOOL",
  "UNITED CHRISTIAN BOYS SR. SEC. SCHOOL",
  "UNITED CHRISTIAN GIRLS SENIOR SECONDARY SCHOOL",
  "UNITED PUBLIC SCHOOL",
  "UNITY MATRICULATION SCHOOL",
  "UNITY PUBLIC SCHOOL",
  "UNIVERSAL ENGLISH MEDIUM SCHOOL",
  "UNIVERSAL ENGLISH SCHOOL",
  "UNIVERSAL HIGH SCHOOL",
  "UNIVERSAL PUBLIC INTERMEDIATE COLLEGE",
  "UNIVERSAL PUBLIC SCHOOL",
  "UNIVERSITY HIGH SCHOOL",
  "UPADHYAY PUBLIC SCHOOL",
  "UPENDRA BHANJA HIGH SCHOOL",
  "URUSULINE ENGLISH MEDIUM SCHOOL",
  "UTOPIA COL SATSANGI'S KIRAN MEMORIAL SCHOOL",
  "UTTARA KHAND PUBLIC SCHOOL",
  "V. K. S PUBLIC SCHOOL",
  "V.D.S. GIRLS HIGH SCHOOL",
  "V.E.S. MODEL CONVENT SCHOOL",
  "V.G.A. PUBLIC SCHOOL",
  "V.J. PATEL PRACTICING SCHOOL",
  "V.K. PATEL HIGH SCHOOL",
  "V.K.S PUBLIC SCHOOL",
  "V.L.S. INTERNATIONAL SCHOOL",
  "V.N. MEMORIAL PUBLIC SCHOOL",
  "V.P.S. PUBLIC SCHOOL",
  "V.R. SENIOR SECONDARY PUBLIC SCHOOL",
  "V.R.PUBLIC SCHOOL",
  "V.R.S. AND V.J. RESIDENTIAL SCHOOL",
  "V.S.M. PUBLIC SCHOOL",
  "V.S.P.K. INTERNATIONAL SCHOOL",
  "V.S.S. INTERNATIONAL PUBLIC SCHOOL",
  "VADI HUSNA PUBLIC SCHOOL",
  "VAEL'S BILLABONG HIGH INTERNATIONAL SCHOOL",
  "VAGAD GURUKUL INTERNATIONAL SCHOOL",
  "VAISH GIRLS SENIOR SECONDARY SCHOOL",
  "VAISH PUBLIC SCHOOL",
  "VAKIL HIGHER SECONDARY SCHOOL",
  "VALLABH PUBLIC SCHOOL",
  "VALLAL S.I ALAGAR SWAMY CHETTIYAR HIGHER SECONDARY SCHOOL",
  "VALLEY PUBLIC SCHOOL",
  "VALLIAMMAL VIDYALAYA HIGHER SECONDARY SCHOOL",
  "VANASTHALI PUBLIC SCHOOL",
  "VANASTHALI PUBLIC SR. SEC. SCHOOL",
  "VANAVANI MATRICULATION HIGHER SECONDARY SCHOOL",
  "VANDANA CONVENT SR. SEC. SCHOOL",
  "VANITA PUBLIC SCHOOL",
  "VAPI PUBLIC SCHOOL",
  "VARANASI PUBLIC SCHOOL",
  "VARDHMAN INTERNATIONAL PUBLIC SCHOOL",
  "VARIN INTERNATIONAL RESIDENTIAL SCHOOL",
  "VARMAS PUBLIC SCHOOL",
  "VASANT VALLEY PUBLIC SCHOOL",
  "VASAVI IDEAL PUBLIC SCHOOL",
  "VASAVI PUBLIC SCHOOL",
  "VASAVI VIDYA PEETHA",
  "VASHISHTA ST. JOSEPHS SCHOOL",
  "VASHISHTHA SENIOR SECONDARY SCHOOL",
  "VASHIST PUBLIC SCHOOL",
  "VASISHAT BHARTI INTERNATIONAL SCHOOL",
  "VASISHTA SCHOOL",
  "VASISTHA SCHOOL,",
  "VATSALYA INTERNATIONAL SCHOOL",
  "VATSALYA PUBLIC HR SEC. SCHOOL",
  "VATSALYA PUBLIC SCHOOL",
  "VED PAL PUBLIC SCHOOL",
  "VED VYASA D.A.V. PUBLIC SCHOOL",
  "VEDA SCHOOL",
  "VEDANT INTERNATIONAL SCHOOL",
  "VEDASRI PUBLIC SCHOOL",
  "VEDBYASH RESIDENTIAL COLLEGE",
  "VEDIC KANYA HIGHER SECONDARY SCHOOL",
  "VEER PUBLIC SCHOOL",
  "VEERA VELUTHAMPY SMRITHI PUBLIC SCHOOL",
  "VEERAMACHANENI PADDAYYA SIDDHARTHA PUBLIC SCHOOL (V.P.S)",
  "VELAMMAL MATRICULATION SCHOOL",
  "VELAMMAL RESIDENTIAL SCHOOL",
  "VELAMMAL VIDYALAYA SCHOOL",
  "VELLALAR GIRLS HIGH SCHOOL FOR GIRLS",
  "VEL'S HIGHER SECONDARY SCHOOL",
  "VENKAT INTERNATIONAL PUBLIC SCHOOL",
  "VENKATESWAR ENGLISH MEDIUM SCHOOL",
  "VENKATESWAR SCHOOL",
  "VENU GOPAL CHETTY HIGHER SECONDARY SCHOOL",
  "VENUS INTERNATIONAL PUBLIC SCHOOL",
  "VENUS PUBLIC SCHOOL",
  "VETRI VIKAAS HIGHER SECONDARY SCHOOL",
  "VIANNEY VIDYA MANDIR",
  "VIBGYOR HIGH",
  "VICTOR PUBLIC SCHOOL",
  "VICTORIA INTERNATIONAL PUBLIC SCHOOL",
  "VICTORIA INTERNATIONAL SCHOOL",
  "VICTORIA PUBLIC SCHOOL",
  "VICTORIA PUBLIC SENIOR SECONDARY SCHOOL",
  "VICTORIOUS KIDSS EDUCARES",
  "VICTORY HIGH SCHOOL",
  "VIDHYA BHARTI DAY BOARDING PUBLIC SCHOOL",
  "VIDHYA BHUVAN PUBLIC SCHOOL",
  "VIDHYA PEETHAM MATRICULATION SCHOOL",
  "VIDHYASHRAM INTERNATIONAL SCHOOL",
  "VIDYA BAL BHAWAN PUBLIC SCHOOL",
  "VIDYA BHARAT SCHOOL",
  "VIDYA BHARTI PUBLIC SCHOOL",
  "VIDYA BHAVAN HIGH SCHOOL",
  "VIDYA BHAVAN PUBLIC SCHOOL",
  "VIDYA BHAWAN PUBLIC SCHOOL",
  "VIDYA BHUMI PUBLIC SCHOOL",
  "VIDYA DEVI JINDAL SHOOL",
  "VIDYA EDUCATION CENTRE FOR GIRLS",
  "VIDYA INDEPENDENT P.U. SCIENCE COLLEGE",
  "VIDYA JAIN PUBLIC SCHOOL",
  "VIDYA JYOTHI SCHOOL",
  "VIDYA JYOTI SCHOOL",
  "VIDYA MANDIR ENGLISH MEDIUM HIGH SCHOOL",
  "VIDYA MANDIR PUBLIC SCHOOL",
  "VIDYA MANDIR SR.SECONDARY SCHOOL",
  "VIDYA NIKETAN PUBLIC SCHOOL",
  "VIDYA NIKETAN SCHOOL",
  "VIDYA NIKETAN",
  "VIDYA PRAKASH PUBLIC SCHOOL",
  "VIDYA PRATISHTHAN'S BAL VIKAS MANDIR",
  "VIDYA PRATISHTHAN'S MAGARPATTA CITY PUBLIC SCHOOL",
  "VIDYA SANSKAAR INTERNATIONAL PUBLIC SCHOOL",
  "VIDYA SANSKAR INTERNATIONAL SCHOOL",
  "VIDYA VALLEY SCHOOL",
  "VIDYA VIHAR PUBLIC SCHOOL",
  "VIDYA VIHAR RESIDENTIAL SCHOOL",
  "VIDYA VIKAS ACADEMY",
  "VIDYA VIKAS SCHOOL",
  "VIDYANAGAR PUBLIC SCHOOL",
  "VIDYANIDHI PUBLIC SCHOOL",
  "VIDYANIKETAN ENGLISH SCHOOL",
  "VIDYANIKETAN PUBLIC HIGH SCHOOL",
  "VIDYANIKETAN PUBLIC SCHOOL",
  "VIDYANJALI CO EDUCATION ENG. MED. SCHOOL",
  "VIDYANJALI HIGH SCHOOL",
  "VIDYANJALI INTERNATIONAL SCHOOL",
  "VIDYANJALI PUBLIC SCHOOL",
  "VIDYAPEETH PUBLIC SCHOOL",
  "VIDYARANYA HIGH SCHOOL FOR BOYS & GIRLS",
  "VIDYASAGAR PUBLIC SCHOOL",
  "VIDYASHILP ACADEMY",
  "VIDYASHILP PUBLIC SCHOOL",
  "VIDYASHRAM PUBLIC SCHOOL",
  "VIDYASTHALI PUBLIC SCHOOL",
  "VIDYATMIKA PUBLIC SCHOOL",
  "VIDYAVANAM RESIDENTIAL SCHOOL,",
  "VIDYAVANI PUBLIC SCHOOL",
  "VIDYAVARDHINI ENGLISH SCHOOL",
  "VIDYAVARIDHI INTERNATIONAL SCHOOL",
  "VIDYAVASINI PUBLIC SCHOOL",
  "VIDYODAYA PUBLIC SCHOOL",
  "VIG ENGLISH SCHOOL",
  "VIGNAN LITTLE PUBLIC SCHOOL",
  "VIGNAN PUBLIC SCHOOL",
  "VIGNAN STEEL CITY PUBLIC SCHOOL",
  "VIGNAN VIDYALAYAM HIGH SCHOOL",
  "VIGNANA JYOTHI PUBLIC SCHOOL",
  "VIGNAN'S GREEN FIELDS PUBLIC SCHOOL",
  "VIGNANS PUBLIC SCHOOL",
  "VIGYAN VALLEY RESIDENTIAL PUBLIC SCHOOL",
  "VIGYANANAND KENDRIYA VIDYALAYA",
  "VIGYANAND KENDRIYA VIDYALAYA",
  "VIJAY HIGH SCHOOL",
  "VIJAY LAXMI MEMORIAL PUBLIC SCHOOL",
  "VIJAY MODEL HIGH SCHOOL",
  "VIJAY PUBLIC SCHOOL",
  "VIJAYAMATHA PUBLIC SCHOOL",
  "VIJAYANANDA HIGHER SECONDARY SCHOOL",
  "VIJAYASHREE PUBLIC SCHOOL",
  "VIJAYAVANI RESIDENTIAL SCHOOL CHOWDEPALLE,",
  "VIJAYAVANI RESIDENTIAL SCHOOL",
  "VIJETHA PUBLIC SCHOOL",
  "VIJNANA VIHAR RESIDENTIAL SCHOOL",
  "VIJNANA VIHAR RESIDENTIAL SCHOOL,",
  "VIKAASA SCHOOL",
  "VIKAR MAAN HIGH SCHOOL",
  "VIKAS BAL NIKETAN SECONDARY SCHOOL",
  "VIKAS BHARTI PUBLIC SCHOOL",
  "VIKAS SCHOOL",
  "VIKAS VIDYALAYA MATRICULATION HIGHER SECONDARY SCHOOL",
  "VIKAS VIDYALAYA",
  "VIKASA THE CONCEPTS SCHOOL",
  "VIKASA VIDYA VANAM SCHOOL",
  "VIKASH CONVENT SCHOOL",
  "VIKASH JUNIOR COLLEGE",
  "VIKASH RESIDENTIAL COLLEGE",
  "VIKASH THE CONCEPT SCHOOL",
  "VIKLAP PUBLIC SCHOOL",
  "VILLA THERESA HIGH SCHOOL",
  "VIMALA CENTRAL SCHOOL",
  "VIMALA CONVENT SCHOOL",
  "VIMALA HRIDAYA SCHOOL",
  "VIMALA PUBLIC SCHOOL",
  "VIMALAGIRI PUBLIC SCHOOL",
  "VINAYAKA PUBLIC SCHOOL",
  "VIRENDER PUBLIC SCHOOL",
  "VIRMANI PUBLIC SCHOOL",
  "VIRUDHUNAGAR T.S.M. MANICKAM NADAR JANAKI AMMAL SCHOOL",
  "VISAKA CENTRAL SCHOOL",
  "VISHNU BHAGWAN PUBLIC SCHOOL",
  "VISHNU PUBLIC SCHOOL",
  "VISHVAS PUBLIC SCHOOL",
  "VISHWA BHARATHI MAT. HR. SEC. SCHOOL",
  "VISHWA BHARTI PUBLIC SCHOOL",
  "VISHWA SHANTHI PUBLIC SCHOOL",
  "VISHWADEEP HIGHER SECONDARY SCHOOL",
  "VISHWADEEPTI VIDYALAYA PUBLIC SCHOOL",
  "VISHWAKARMA PUBLIC SCHOOL",
  "VISHWAPADMAM PUBLIC SCHOOL",
  "VISHWASANTHI PUBLIC SCHOOL",
  "VISHWASHANTI GURUKUL",
  "VISWA JYOTHI PUBLIC SCHOOL",
  "VISWADEEPTHI C.M.I. PUBLIC SCHOOL",
  "VISWADEEPTI VIDYALAYA PUBLIC SCHOOL",
  "VISWAJYOTHI C.M.I. PUBLIC SCHOOL",
  "VISWAJYOTHI PUBLIC SCHOOL",
  "VISWAM PUBLIC SCHOOL",
  "VISWASS JUNIOR RESIDENTIAL COLLEGE",
  "VISWASS PUBLIC SCHOOL",
  "VITALA PUBLIC SCHOOL",
  "VIVEK HIGH SCHOOL",
  "VIVEK INTERNATIONAL SCHOOL",
  "VIVEK VIHAR PUBLIC SCHOOL",
  "VIVEKA FOUNDATIONS SCHOOL",
  "VIVEKA KENDRA PUBLIC SCHOOL",
  "VIVEKANAND KENDRA VIDYALAYA",
  "VIVEKANAND MEM PUBLIC SCHOOL",
  "VIVEKANAND PRATISHTHAN PRE-PRIMARY SCHOOL",
  "VIVEKANAND PUBLIC SCHOOL",
  "VIVEKANAND SENIOR SECONDARY SCHOOL",
  "VIVEKANAND SHIKSHYA KENDRA",
  "VIVEKANANDA ACADEMY",
  "VIVEKANANDA EDUCATIONAL CENTRE",
  "VIVEKANANDA ENGLISH HIGH SCHOOL",
  "VIVEKANANDA HIGHER SECONDARY SCHOOL",
  "VIVEKANANDA KENDRA VIDYALAYA (NEC)",
  "VIVEKANANDA KENDRA VIDYALAYA",
  "VIVEKANANDA KENDRE VIDYALAYA",
  "VIVEKANANDA MISSION SCHOOL",
  "VIVEKANANDA PUBLIC SCHOOL",
  "VIVEKANANDA RESIDENTIAL SCHOOL",
  "VIVEKANANDA SIKSHA NIKETAN HIGH SCHOOL",
  "VIVEKANANDA VIDYA KALA ASHRAM GURUKULAM RESIDENTIAL SCHOOL",
  "VIVEKANANDHA HIGHER SECONDARY SCHOOL",
  "VIVEKANANDHA MATRICULATION SCHOOL",
  "VIVEKANANDHA VIDHYA MANDHIR MATRICULATION SCHOOL",
  "VOC GOVERNMENT HIGHER SECONDARY SCHOOL",
  "VRINDAVAN INTERNATIONAL PUBLIC SCHOOL",
  "VRINDAVAN PUBLIC SCHOOL",
  "VRUKSHA - INTERNATIONAL SCHOOL OF MONTESSORI",
  "VRUKSHA INTERNATIONAL SCHOOL OF MONTESSORI",
  "VYANKATESH PUBLIC SCHOOL",
  "VYAPAR MANDAL PUBLIC SCHOOL",
  "VYASA INTERNATIONAL SCHOOL",
  "VYOMAYANA SAMSTHA VIDYALAYA",
  "W. JOHN MULTIPURPOSE BOARDING SCHOOL",
  "WAHEGURU PUBLIC SCHOOL",
  "WALSINGHAM HOUSE SCHOOL",
  "WANI PUBLIC SCHOOL AND JUNIOR COLLEGE",
  "WARANGAL CENTRAL PUBLIC SCHOOL",
  "WARANGAL PUBLIC SCHOOL",
  "WARSI HIGHER SECONDARY SCHOOL",
  "WEIDNER MEMORIAL SR. SEC. SCHOOL",
  "WEIDNER MEMORIAL",
  "WELHAM BOYS SCHOOL",
  "WELHAM BOY'S SCHOOL",
  "WELHAM GIRLS SCHOOL",
  "WELHAM GIRLS' SCHOOL",
  "WELKIN NATIONAL SCHOOL",
  "WENDY MILI PUBLIC SCHOOL",
  "WENDY SCHOOL JUNIOR COLLEGE",
  "WESLEY CENTENARY HIGH SCHOOL",
  "WESLEY ENGLISH HIGH SCHOOL",
  "WEST POINT MODEL SCHOOL",
  "WEST POINT SCHOOL",
  "WESTLY HIGHER SECONDARY SCHOOL",
  "WESTWOOD INTERNATIONAL SCHOOL",
  "WHITE ANGELS ENGLISH HIGH SCHOOL",
  "WHITE HOUSE PUBLIC SCHOOL",
  "WHITE LEAF PUBLIC SCHOOL",
  "WHITE MEMORIAL MATRICULATION SCHOOL",
  "WHITEHALL PUBLIC SCHOOL",
  "WHIZ KIDZ",
  "WILLIAM HENRY SMITH MEMORIAL SCHOOL",
  "WILSON HIGH SCHOOL",
  "WIMBERG ENGLISH SCHOOL",
  "WINNERS PUBLIC SCHOOL",
  "WISDOM HIGH INTERNATIONAL SCHOOL",
  "WISDOM HIGH SCHOOL",
  "WISDOM INTERNATIONAL SCHOOL",
  "WISDOM PUBLIC SCHOOL",
  "WISEMAN HIGHER SECONDARY SCHOOL",
  "WITTY INTERNATIONAL SCHOOL",
  "WONDERLAND PUBLIC SCHOOL",
  "WOOD LAND SCHOOL",
  "WOOD ROW SCHOOL",
  "WOODBINE MODERN SCHOOL",
  "WOODLANDS OVERSEAS SCHOOL",
  "WOODSTOCK PUBLIC SCHOOL",
  "WOODSTOCK SCHOOL",
  "WORLDWAY INTERNATIONAL SCHOOL",
  "WYLIE MEMORIAL HIGH SCHOOL",
  "WYUBERG ALLEN SCHOOL",
  "Y S SCHOOL",
  "Y.S. PUBLIC SCHOOL",
  "YADAVINDRA PUBLIC SCHOOL",
  "YADU PUBLIC SCHOOL",
  "YAGAPPA INTERNATIONAL SCHOOL",
  "YASH VIDYA MANDIR",
  "YASHASHVI INTERNATIONAL SCHOOL",
  "YASHVANTRAO CHAVAN PRIMARY ENGLISH SCHOOL",
  "YASHWANT JUNIOR COLLEGE",
  "YAVATMAL PUBLIC SCHOOL",
  "YOGA WAY PUBLIC SCHOOL",
  "YOGANANDA SARASWATI PRIMARY SCHOOL",
  "YOUNG BLOOD PUBLIC SCHOOL",
  "YOUNG SCHOLARS PUBLIC SCHOOL",
  "YOUNG SCHOLARS SCHOOL",
  "YUGANTAR PUBLIC SCHOOL",
  "YUGCHETNA PUBLIC SCHOOL",
  "YUGDHARMA PUBLIC SCHOOL",
  "YUVABHARATHI PUBLIC SCHOOL",
  "Z.P.H.SCHOOL",
  "ZAKIR HUSAIN MODEL SENIOR SECONDARY SCHOOL",
  "ZIAUDDIN KHAN MEMORIAL SR. SEC. SCHOOL",
  "ZILLA PARISHAD GIRLS HIGH SCHOOL",
  "ZILLA PARISHAD HIGH SCHOOL",
  "ZION ENGLISH MEDIUM SCHOOL",
  "ZYDUS SCHOOL FOR EXCELLENCE",
];
