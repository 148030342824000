import React, { useState } from "react";
import Header from "../header/Header";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";

import StudentList from "./studentList/StudentList";

const Students = ({ fUser }) => {
  const headerFlag = 6;

  return (
    <RootDiv>
      <Header flag={headerFlag} />

      {/* <StyledGrid item container>
          <StyledGrid item xs={false} sm={1} />
          <StyledGrid item xs={12} sm={10}>
            <Search flag={flag} />
          </StyledGrid>
          <StyledGrid item xs={false} sm={1} />
        </StyledGrid> */}

      <StyledGrid item xs={12} sm={10}>
        <StudentList fUser={fUser} />
      </StyledGrid>
    </RootDiv>
  );
};

export default Students;
