import React, { useState } from "react";
import { MathJax } from "better-react-mathjax";
import { Link } from "react-router-dom";

import {
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledButton,
  StyledTextField,
  BasicDiv,
  TitleDiv,
} from "../../../css/StyledComponents";

const PublishTestCourseCard = (props) => {
  const fUser = props.fUser;
  const getLangIndex = (arr, lang) => {
    return arr.findIndex((element) => {
      return element.language === lang;
    });
  };
  return (
    <BasicDiv>
      <p>{props.QuestionNo})</p>
      <StyledCard>
        <StyledCardContent>
          {props.quesLangs.map((lang) => {
            return (
              <div key={`1${lang}`}>
                <MathJax dynamic={true}>
                  {props.Question[getLangIndex(props.Question, lang)] &&
                  props.Question[getLangIndex(props.Question, lang)]?.is
                    ? props.Question[getLangIndex(props.Question, lang)].is
                    : `Not Available in ${lang} language`}
                </MathJax>
              </div>
            );
          })}
        </StyledCardContent>

        <StyledCardContent>
          <TitleDiv>
            <StyledTextField
              value={`Correct Marks:${props.correctMarks}`}
              variant="filled"
            />
            <StyledTextField
              value={`Wrong Marks:${props.wrongMarks}`}
              variant="filled"
            />
          </TitleDiv>
          <TitleDiv>
            {fUser.role === "Teacher" || fUser.role === "Institute" ? (
              <Link
                exact
                to={"/quesSubs"}
                state={{
                  qId: props.id,
                  tId: props.testId,
                  type: "tests",
                  correctMarks: props.correctMarks,
                  wrongMarks: props.wrongMarks,
                  navFrom: "test",
                  quesNo: props.QuestionNo,
                }}
                style={{ textDecoration: "none" }}
              >
                <StyledCardActions>
                  <StyledButton variant="contained" size="small">
                    Submissions
                  </StyledButton>
                </StyledCardActions>
              </Link>
            ) : null}
            <Link
              exact
              to={
                fUser.role === "Teacher" || fUser.role === "Institute"
                  ? "/newQuestion"
                  : fUser.role === "Student"
                  ? "/question"
                  : null
              }
              state={{
                id: props.id,
                subId: props.subId,
                correctMarks: props.correctMarks,
                wrongMarks: props.wrongMarks,
              }}
              style={{ textDecoration: "none" }}
            >
              <StyledCardActions>
                <StyledButton variant="contained" size="small">
                  View
                </StyledButton>
              </StyledCardActions>
            </Link>
          </TitleDiv>
        </StyledCardContent>
      </StyledCard>
    </BasicDiv>
  );
};

export default PublishTestCourseCard;
