import React from "react";
import BPrim from "../../constants/aboutUs/bPrim";
import Footer from "../Footer";
import Header from "../FrontHeader";

const AboutUs = () => {
  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      <br />
      <BPrim />
      <Footer />
    </div>
  );
};

export default AboutUs;
