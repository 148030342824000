import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
  Hidden,
} from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/client";
import ReactPaginate from "react-paginate";

import {
  StyledButton,
  StyledActiveButton,
  StyledDrawer,
  StyledTextField,
  StyledIconButton,
  StyledTypography,
  StyledGrid,
  ToolBarDiv,
  RootDiv,
  StyledImg,
  StyledNav,
  LeftDrawerDiv,
  ColumnFlexDiv,
  DrawerDiv,
} from "../../../css/StyledComponents";

import { LoadingSpinner } from "../../../constants/loadingSpinner";
import "./examinerPagination.css";

const getAllStudScreenshotsQuery = loader(
  "../../../graphqlCalls/testScreenshot/userGetAllTestScreenshots.gql"
);
const getAStudScreenshotsQuery = loader(
  "../../../graphqlCalls/testScreenshot/userGetStudentScreenshots.gql"
);
const getAStudScreenshotsAnalysisQuery = loader(
  "../../../graphqlCalls/testScreenshot/userGetStudentScreenshotsAnalysis.gql"
);
const initialFormValues = {
  students: [
    {
      studId: "",
      name: "Loading...",
      grade: "Loading...",
      screenshots: [],
    },
  ],
};

export default function ExaminerScreen(props) {
  const { window } = props;
  const fUser = props.fUser;

  const navigate = useNavigate();
  const { state } = useLocation();

  const newTheme = createTheme({
    overrides: {
      Muibutton: {
        label: {
          justifyContent: "justify",
        },
      },
    },
  });

  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [formValues, setValues] = useState(initialFormValues);
  const [pageNumber, setPageNumber] = useState(0);
  const [active, setActive] = useState({
    activeIndex: 0,
    studId: "",
    name: initialFormValues.students[0].name,
    grade: initialFormValues.students[0].grade,
    screenshots: [],
  });
  const { loading: fetchingSc } = useQuery(getAllStudScreenshotsQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      tId: state ? state.testId : null,
    },
    skip: !state || !fUser,
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: ({ userGetAllTestScreenshots }) => {
      let students = userGetAllTestScreenshots.map((studentSc, index) => {
        return {
          activeIndex: index,
          studId: studentSc.student._id,
          name: `${
            studentSc.student.portrait.alias.first
              ? studentSc.student.portrait.alias.first
              : ""
          } ${
            studentSc.student.portrait.alias.last
              ? studentSc.student.portrait.alias.last
              : ""
          }`,
          grade: studentSc.student.portrait.grade,
          totalScore: studentSc.totalScore,
          pass: studentSc.pass,
          screenshots: [],
        };
      });
      if (students.length != 0) {
        students[0].screenshots = userGetAllTestScreenshots[0].screenshots;
        setValues({
          students: students,
        });
        setActive({ ...students[0], activeIndex: 0 });
      } else {
        alert("No Submissions yet");
      }
    },
  });
  // const { loading: fetchingStudSc, refetch } = useQuery(
  //   getAStudScreenshotsQuery,
  //   {
  //     fetchPolicy: "network-only",
  //     notifyOnNetworkStatusChange: true,
  //     variables: {
  //       userId: active.studId,
  //       tId: state ? state.testId : null,
  //     },
  //     skip: !state || !fUser || active.studId == "",
  //     onError: (err) => {
  //       alert("Something went wrong");
  //       // console.log(JSON.stringify(err, null, 2));
  //     },
  //     onCompleted: ({ userGetStudentScreenshots }) => {
  //       // ISSUE: FETCH STUDENT ALSO WITH THEIR SC? CUZ WRONG SC SHOULDN'T COME FOR WRONG STUDENT
  //       setActive({
  //         ...active,
  //         screenshots: userGetStudentScreenshots,
  //       });
  //     },
  //   }
  // );
  const { loading: fetchingStudScAnalysis, refetch } = useQuery(
    getAStudScreenshotsAnalysisQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: active.studId,
        tId: state ? state.testId : null,
      },
      skip: !state || !fUser || active.studId == "",
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetStudentScreenshotsAnalysis }) => {
        // ISSUE: FETCH STUDENT ALSO WITH THEIR SC? CUZ WRONG SC SHOULDN'T COME FOR WRONG STUDENT
        setActive({
          ...active,
          screenshots: userGetStudentScreenshotsAnalysis,
        });
      },
    }
  );
  let studentsPerPage = 13;
  const pagesVisited = pageNumber * studentsPerPage;

  const displayStudents = formValues.students
    ? formValues.students
        .slice(pagesVisited, pagesVisited + studentsPerPage)
        .map((stud, index) => {
          return (
            <ThemeProvider theme={newTheme} key={index}>
              {active.activeIndex === index ? (
                <StyledActiveButton
                  key={index}
                  onClick={() => {
                    setActive({ ...stud, activeIndex: index });
                    refetch();
                  }}
                >
                  {stud.name}
                </StyledActiveButton>
              ) : (
                <StyledButton
                  key={index}
                  onClick={() => {
                    setActive({ ...stud, activeIndex: index });
                    refetch();
                  }}
                >
                  {stud.name}
                </StyledButton>
              )}
            </ThemeProvider>
          );
        })
    : [];

  const pageCount = formValues.students
    ? Math.ceil(formValues.students.length / studentsPerPage)
    : 0;

  const changepage = ({ selected }) => {
    setPageNumber(selected);

    const firstStudOnPage = selected * studentsPerPage;
    setActive({
      ...formValues.students[firstStudOnPage],
      activeIndex: 0,
    });
    refetch();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <ColumnFlexDiv style={{ marginTop: "70px" }}>
      <ToolBarDiv>
        <StyledTypography style={{ fontWeight: "bold" }}>
          Student List
        </StyledTypography>
      </ToolBarDiv>
      {formValues.students.length != 0 ? displayStudents : null}
      <DrawerDiv>
        {formValues.students.length != 0 ? (
          <ReactPaginate
            previousLabel={"←"}
            nextLabel={"→"}
            pageCount={pageCount}
            pageRangeDisplayed={pageCount}
            onPageChange={changepage}
            containerClassName={"paginationBttnsStudentDrawer"}
            previousLinkClassName={"paginationLink"}
            nextLinkClassName={"paginationLink"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        ) : null}
      </DrawerDiv>
    </ColumnFlexDiv>
  );
  useEffect(() => {
    if (!state) navigate("/tests", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }

  return (
    <RootDiv>
      <StyledIconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
      >
        <StyledImg
          src="https://cdn.iconscout.com/icon/free/png-512/menu-bar-lines-option-list-hamburger-web-6-3228.png"
          alt="MenuIcon"
        />
      </StyledIconButton>
      <StyledNav>
        <StyledDrawer
          variant="temporary"
          // anchor={theme.direction === "rtl" ? "right" : "left"}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, //Better open performance on mobile.
          }}
        >
          {drawer}
        </StyledDrawer>
        <StyledDrawer
          sx={{
            display: { xs: "none", sm: "block" },
          }}
          variant="permanent"
          open
        >
          {drawer}
        </StyledDrawer>
      </StyledNav>
      <LeftDrawerDiv>
        {active.name != "Loading..." ? (
          <StyledTextField
            label="Student :"
            value={active ? active.name : ""}
            variant="filled"
            disabled
          />
        ) : null}
        {active.name != "Loading..." ? (
          <StyledTextField
            label="Grade :"
            value={active ? active.grade : ""}
            variant="filled"
            disabled
          />
        ) : null}
        {active.name != "Loading..." ? (
          <StyledTextField
            label="Score :"
            value={active ? active.totalScore : ""}
            variant="filled"
            disabled
          />
        ) : null}
        {active.name != "Loading..." ? (
          active.pass == -1 || active.pass == 1 ? (
            <StyledTypography variant="h5" disabled>
              Result:{" "}
              <span style={{ color: "red" }}>
                {active.pass == -1 ? "FAIL" : active.pass == 1 ? "PASS" : "TBD"}
              </span>
            </StyledTypography>
          ) : null
        ) : null}

        {fetchingSc || fetchingStudScAnalysis ? (
          LoadingSpinner(fetchingSc || fetchingStudScAnalysis)
        ) : active.screenshots && active.screenshots.length != 0 ? (
          <StyledGrid item xs={12} sm={12} style={{ margin: 7 }}>
            {active.screenshots.map((scObj, index) => (
              <img
                src={scObj.sc}
                alt="Random"
                width={360}
                height={200}
                style={{
                  borderStyle: scObj.faceCount != 1 ? "solid" : "",
                  borderColor: scObj.faceCount != 1 ? "red" : "",
                  borderWidth: scObj.faceCount != 1 ? "thick" : "",
                  margin: 2,
                }}
              />
            ))}
          </StyledGrid>
        ) : (
          <StyledTypography
            variant="h5"
            style={{ fontWeight: "bold", margin: 20 }}
          >
            No Screenshots
          </StyledTypography>
        )}
      </LeftDrawerDiv>
    </RootDiv>
  );
}
