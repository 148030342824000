import React from "react";
import { Typography } from "@mui/material";

const bPrim = () => {
  return (
    <div style={{ textAlign: "justify", margin: 20 }}>
      <Typography
        variant="h5"
        style={{ textAlign: "left", fontWeight: "bold", marginTop: "35px" }}
      >
        Contact us - Bhaskaracharya Pratishthana
      </Typography>
      <br />
      <Typography>
        <b>Address:</b>
        <br /> 56/14, Off Law College Road,
        <br /> Damle Road, Erandwane,
        <br />
        Pune - 411004,
        <br /> Maharashtra
      </Typography>
      <br />
      <Typography>
        For more information please visit{" "}
        <a href="https://www.bprim.org/" target="_blank">
          https://www.bprim.org/
        </a>
      </Typography>
    </div>
  );
};

export default bPrim;
