import * as Yup from "yup";

const mobileNoFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const RegistrationValidationForm = Yup.object().shape({
  pincode: Yup.number()
    .required("Please enter the Pin code")
    .typeError("Pin code should contain numbers only")
    .integer("Pin code must be a 6 digit number")
    .max(999999, "Pin code must be a 6 digit number")
    .min(100000, "Pin code must be a 6 digit number"),
});

export default RegistrationValidationForm;
