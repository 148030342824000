import React, { useState, useEffect } from "react";
import { Tabs, Tab, AppBar, Paper } from "@mui/material";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { MathJax } from "better-react-mathjax";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Grid as ChartGrid,
  Title,
  Subtitle,
  Tooltip,
  Format,
  Label,
  ValueAxis,
} from "devextreme-react/chart";
import { Funnel } from "devextreme-react";
import {
  Border,
  Font,
  Item,
  // Label,
  // Legend,
  // Margin,
  // Title,
  // Tooltip,
} from "devextreme-react/funnel";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import ReactExports from "react-export-excel";

import {
  StyledToolbar,
  StyledCard,
  StyledCardContent,
  StyledGrid,
  StyledTable,
  StyledButton,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledPaper,
  RootDiv,
  ColumnFlexDiv,
} from "../../../css/StyledComponents";

import "../Performance.css";

import { LoadingSpinner } from "../../../constants/loadingSpinner";
import { quesLangList } from "../../../constants/quesLangList";

const ExcelFile = ReactExports.ExcelFile;
const ExcelSheet = ReactExports.ExcelSheet;
const ExcelColumn = ReactExports.ExcelColumn;

const teacherGetTestPerformancesQuery = loader(
  "../../../graphqlCalls/test/teacherGetTestPerformances.gql"
);
const userGetAllTestUnattemptedStudentsQuery = loader(
  "../../../graphqlCalls/test/userGetAllTestUnattemptedStudents.gql"
);

const initialFormValues = {
  quesList: [],
  studentPerforms: [],
  bloomData: [
    {
      value: 0,
      bloomName: "Create",
    },
    {
      value: 0,
      bloomName: "Evaluate",
    },
    {
      value: 0,
      bloomName: "Analyze",
    },
    {
      value: 0,
      bloomName: "Apply",
    },
    {
      value: 0,
      bloomName: "Understand",
    },
    {
      value: 0,
      bloomName: "Remember",
    },
  ],
  overallBloomGraphData: [
    {
      bloomName: "Remember",
      overall: 0,
    },
    {
      bloomName: "Understand",
      overall: 0,
    },
    {
      bloomName: "Apply",
      overall: 0,
    },
    {
      bloomName: "Analyze",
      overall: 0,
    },
    {
      bloomName: "Evaluate",
      overall: 0,
    },
    {
      bloomName: "Create",
      overall: 0,
    },
  ],
  questionWiseGraphData: [
    {
      type: "Question Average Deviation",
      overall: 0,
    },
    {
      type: "Question Level Weightage",
      overall: 0,
    },
    {
      type: "Question Marks Obtained Weightage",
      overall: 0,
    },
    {
      type: "Question Performance Score Weightage",
      overall: 0,
    },
  ],
  barPerformanceData: [
    {
      type: "Bloom Taxonomy",
      overall: 0,
    },
    {
      type: "Student Correctly Solved Weightage",
      overall: 0,
    },
    {
      type: "Final Performance",
      overall: 0,
    },
  ],
};
const bloomTagsList = [
  "Create",
  "Evaluate",
  "Analyze",
  "Apply",
  "Understand",
  "Remember",
];
// const useStyles = makeStyles((theme) => ({
//   pyramid: {
//     width: "700px",
//     height: "500px",
//     position: "relative",
//     left: "25vw",
//     [theme.breakpoints.down("xs")]: {
//       // width: "400px",
//       height: "400px",
//     },
//   },
//   bar: {
//     width: "700px",
//     height: "500px",
//     [theme.breakpoints.down("xs")]: {
//       // width: "400px",
//       height: "400px",
//     },
//   },
//   bloomLine: {
//     width: "700px",
//     height: "500px",
//     // margin: "10px",
//     [theme.breakpoints.down("xs")]: {
//       // width: "400px",
//       height: "400px",
//     },
//   },
//   questionLine: {
//     width: "700px",
//     height: "500px",
//     [theme.breakpoints.down("xs")]: {
//       // width: "400px",
//       height: "400px",
//     },
//   },
// }));

export default function StudentPerformanceList(props) {
  const [value, setValue] = useState(0);
  const [formValues, setValues] = useState(initialFormValues);
  const [unattemptedStudents, setUnattemptedStudents] = useState({
    isOfExam: false,
    students: [],
  });
  const fUser = props.fUser;
  const { state } = useLocation();

  const navigate = useNavigate();

  const type = ["Overall", "Student Wise"];

  const handleTabsChange = (event, value) => {
    setValue(value);
  };
  const getLangIndex = (arr, lang) => {
    return arr.findIndex((element) => {
      return element.language === lang;
    });
  };
  const getBloomData = (bloomObj) => {
    let bloomData = [];
    for (let i = 0; i < bloomTagsList.length; i++) {
      bloomData.push({
        value:
          bloomObj[bloomTagsList[i]] >= 0
            ? bloomObj[bloomTagsList[i]].toFixed(2)
            : 0,
        bloomName: bloomTagsList[i],
      });
    }
    return bloomData;
  };
  const getOverallBloomCorSolvedData = (bloomObj) => {
    let bloomData = [];
    for (let i = 0; i < bloomTagsList.length; i++) {
      bloomData.push({
        overall:
          bloomObj[bloomTagsList[i]] >= 0
            ? bloomObj[bloomTagsList[i]].toFixed(2)
            : 0,
        bloomName: bloomTagsList[i],
      });
    }
    return bloomData;
  };
  const { loading: loadingUnAttempted } = useQuery(
    userGetAllTestUnattemptedStudentsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        tId: state ? state.testId : null,
      },
      skip: !state || !fUser,
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetAllTestUnattemptedStudents }) => {
        setUnattemptedStudents({
          isOfExam: userGetAllTestUnattemptedStudents.isOfExam,
          students: userGetAllTestUnattemptedStudents.students,
        });
      },
    }
  );

  const { data, error, loading, refetch, networkStatus } = useQuery(
    teacherGetTestPerformancesQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        tId: state ? state.testId : null,
      },
      skip: !state || !fUser,
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ teacherGetTestPerformances }) => {
        setValues({
          ...formValues,
          quesList: teacherGetTestPerformances.quesList,
          studentPerforms: teacherGetTestPerformances.studPerformances,
          bloomData: getBloomData(
            teacherGetTestPerformances.averageBloomScores
          ),
          overallBloomGraphData: getOverallBloomCorSolvedData(
            teacherGetTestPerformances.OverallBloomTagCorSolved
          ),
          questionWiseGraphData: [
            {
              type: "Question Average Deviation",
              overall: teacherGetTestPerformances.OverallQuesAvgDeviation,
            },
            {
              type: "Question Level Weightage",
              overall: teacherGetTestPerformances.OverallQuesLevelWeightage,
            },
            {
              type: "Question Marks Obtained Weightage",
              overall:
                teacherGetTestPerformances.OverallQuesMarkObtainedWeightage,
            },
            {
              type: "Question Performance Score Weightage",
              overall:
                teacherGetTestPerformances.OverallQuesPerformanceScoreWeightage,
            },
          ],
          barPerformanceData: [
            {
              type: "Bloom Taxonomy",
              overall: teacherGetTestPerformances.OverallQuesBloomTagScore,
            },
            {
              type: "Student Correctly Solved Weightage",
              overall: teacherGetTestPerformances.OverallQuesCorSolvedWeightage,
            },
            {
              type: "Final Performance",
              overall: teacherGetTestPerformances.avgPerformance,
            },
          ],
        });
      },
    }
  );
  // -1 == "NOT SUBMITTED" -2 == "NOT APPEARED" UPDATE CODE IN FE IN ANY CHANGES HERE, ADD "RESULT NOT CALCULATED" IN FUTURE

  const formattedStudentPerfomance = formValues.studentPerforms?.map(
    (studData) => {
      return {
        name: `${studData.studPortrait.alias.first} ${studData.studPortrait.alias.last}`,
        schoolName: studData.studPortrait.academic.schoolName
          ? `${studData.studPortrait.academic.schoolName}`
          : "not given",
        schoolPincode: studData.studPortrait.academic.schoolPincode
          ? `${studData.studPortrait.academic.schoolPincode}`
          : "not given",
        grade: `${studData.studPortrait.grade}`,
        contactNo: studData.studPortrait.pingAt.cell
          ? `${studData.studPortrait.pingAt.cell}`
          : "not given",
        emailId: studData.studPortrait.pingAt.virtualAdd
          ? `${studData.studPortrait.pingAt.virtualAdd}`
          : "not given",
        performance:
          studData.finalPerformance === -1
            ? "Not Submitted"
            : studData.finalPerformance === -2
            ? "Not Appeared"
            : `${studData.finalPerformance}`,
        totalScore:
          studData.finalPerformance === -1
            ? "Not Submitted"
            : studData.finalPerformance === -2
            ? "Not Appeared"
            : `${studData.totalScore}`,
      };
    }
  );
  const formattedUnattemptedStudents = unattemptedStudents.students?.map(
    (studData) => {
      return {
        name: `${studData.studPortrait.alias.first} ${studData.studPortrait.alias.last}`,
        schoolName: studData.studPortrait.academic.schoolName
          ? `${studData.studPortrait.academic.schoolName}`
          : "not given",
        schoolPincode: studData.studPortrait.academic.schoolPincode
          ? `${studData.studPortrait.academic.schoolPincode}`
          : "not given",
        grade: `${studData.studPortrait.grade}`,
        contactNo: studData.studPortrait.pingAt.cell
          ? `${studData.studPortrait.pingAt.cell}`
          : "not given",
        emailId: studData.studPortrait.pingAt.virtualAdd
          ? `${studData.studPortrait.pingAt.virtualAdd}`
          : "not given",
        rType: `${
          studData.receipt?.paymentFor === "BULK" ? "BULK" : "INDIVIDUAL"
        }`,
        rName: `${studData.payer?.portrait.alias?.first} ${studData.payer?.portrait.alias?.last}`,
        rMobile: `${studData.payer?.portrait.pingAt?.cell}`,
        rEmail: `${studData.receipt?.from?.mail}`,
      };
    }
  );

  useEffect(() => {
    if (!state) navigate("/tests", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }

  return (
    <RootDiv>
      <AppBar position="static">
        <StyledToolbar>
          <Tabs
            value={value}
            onChange={handleTabsChange}
            style={{ display: "flex", justifyContent: "center" }}
            TabIndicatorProps={{
              style: { backgroundColor: "#1f5156" },
            }}
          >
            <Tab label="Overall" />
            <Tab label="Student Wise" />
          </Tabs>
        </StyledToolbar>
      </AppBar>
      {loading ? (
        <div>
          <br />
          {LoadingSpinner(loading)} <br />
        </div>
      ) : null}
      {/* <div
          style={{
            display: "flex",
            height: "1000px",
          }}
        > */}
      <h2>Question Wise Performance</h2>
      <StyledGrid container spacing={1}>
        {formValues.quesList?.map((ques, index) => {
          return (
            // <div className={classes.div1}>
            <StyledGrid item xs={12} sm={4} key={index}>
              <StyledCard>
                <p>{ques.quesNo})</p>
                <StyledCardContent>
                  <MathJax dynamic={true}>
                    {ques.quesIs[
                      getLangIndex(ques.quesIs, ques.quesLangs[0])
                    ] &&
                    ques.quesIs[getLangIndex(ques.quesIs, ques.quesLangs[0])]
                      ?.is
                      ? ques.quesIs[
                          getLangIndex(ques.quesIs, ques.quesLangs[0])
                        ].is
                      : `Not Available in ${ques.quesLangs[0]} language`}
                  </MathJax>
                </StyledCardContent>
                <StyledCardContent>
                  <p>No. students correctly solved: {ques.nosCorSolved}</p>
                  <p>Avg. Time Spent on question: {ques.avgTimeSpent} secs</p>
                </StyledCardContent>
              </StyledCard>
            </StyledGrid>
            // </div>
          );
        })}
      </StyledGrid>
      <TabPanel value={value} index={0}>
        <StyledPaper>
          <ColumnFlexDiv>
            <h2>Average Performance based on Bloom Taxonomy</h2>
            <Funnel
              className="pyramid"
              dataSource={formValues.bloomData}
              inverted={true}
              algorithm="dynamicHeight"
              palette="Harmony Light"
              argumentField="bloomName"
              valueField="value"
            >
              <Margin bottom={30} top={30} />
              <Title>
                <Margin bottom={30} />
              </Title>
              <Tooltip enabled={true} />
              <Item>
                <Border visible={true} />
              </Item>
              <Legend visible={true} />
              <Label
                visible={true}
                horizontalAlignment="left"
                backgroundColor="none"
              >
                <Font size={16} />
              </Label>
            </Funnel>
          </ColumnFlexDiv>
        </StyledPaper>
        <div>
          <StyledPaper>
            <Chart
              className="bloomLine"
              dataSource={formValues.overallBloomGraphData}
            >
              <CommonSeriesSettings argumentField="bloomName" type="line" />
              <Series valueField="overall" name="Overall" />
              <Margin left={20} bottom={20} top={25} />
              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Bloom Taxonomy Performance"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
          <StyledPaper>
            <Chart
              className="questionLine"
              dataSource={formValues.questionWiseGraphData}
            >
              <CommonSeriesSettings argumentField="type" type="line" />
              <Series valueField="overall" name="Overall" />
              <Margin left={20} bottom={20} top={25} />
              <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Question-wise Performance"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
          <StyledPaper>
            <Chart className="bloomLine" dataSource={formValues.quesList}>
              <CommonSeriesSettings argumentField="quesNo" type="line" />
              <Series valueField="avgTimeSpent" name="Overall" />
              <Margin left={20} bottom={20} top={25} />
              <ValueAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
                title="seconds"
              >
                <ChartGrid visible={false} />
              </ValueAxis>
              <ArgumentAxis title="question numbers" tickInterval={1}>
                <ChartGrid visible={false} />
              </ArgumentAxis>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
                itemTextPosition="bottom"
              />
              <Title text="Time Spent on Questions"></Title>
              <Tooltip enabled={true} />
            </Chart>
          </StyledPaper>
          <StyledPaper>
            <Chart className="bar" dataSource={formValues.barPerformanceData}>
              <CommonSeriesSettings
                argumentField="state"
                type="bar"
                hoverMode="allArgumentPoints"
                selectionMode="allArgumentPoints"
              >
                <Label visible={true}>
                  <Format type="fixedPoint" precision={0} />
                </Label>
              </CommonSeriesSettings>
              <Margin left={20} bottom={20} top={25} />
              <Series
                argumentField="type"
                valueField="overall"
                name="Overall"
              />
              <Title text="Overall Student Performance"></Title>
              <Legend
                verticalAlignment="bottom"
                horizontalAlignment="center"
              ></Legend>
            </Chart>
          </StyledPaper>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <StyledTableContainer component={Paper}>
            <StyledTable aria-label="simple table">
              <StyledTableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>School Name</StyledTableCell>
                  <StyledTableCell>Grade</StyledTableCell>
                  <StyledTableCell>Contact No</StyledTableCell>
                  <StyledTableCell>Email Id</StyledTableCell>
                  <StyledTableCell>Performance %</StyledTableCell>
                  <StyledTableCell>Marks</StyledTableCell>
                  <StyledTableCell>
                    <ExcelFile
                      element={
                        <StyledButton
                          variant="contained"
                          size="medium"
                          disabled={loading}
                        >
                          Download Performance
                        </StyledButton>
                      }
                    >
                      <ExcelSheet
                        data={formattedStudentPerfomance}
                        name="Student Performance"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="School Name" value="schoolName" />
                        <ExcelColumn
                          label="School Pincode"
                          value="schoolPincode"
                        />
                        <ExcelColumn label="Grade" value="grade" />
                        <ExcelColumn label="Contact Number" value="contactNo" />
                        <ExcelColumn label="Email Id" value="emailId" />
                        <ExcelColumn
                          label="Performance (%)"
                          value="performance"
                        />
                        <ExcelColumn label="Marks" value="totalScore" />
                      </ExcelSheet>
                    </ExcelFile>
                  </StyledTableCell>
                  <StyledTableCell>
                    <ExcelFile
                      element={
                        <StyledButton
                          variant="contained"
                          size="medium"
                          disabled={loadingUnAttempted}
                        >
                          Download Unattempted Students
                        </StyledButton>
                      }
                    >
                      <ExcelSheet
                        data={formattedUnattemptedStudents}
                        name="Unattempted Students"
                      >
                        <ExcelColumn label="Name" value="name" />
                        <ExcelColumn label="School Name" value="schoolName" />
                        <ExcelColumn
                          label="School Pincode"
                          value="schoolPincode"
                        />
                        <ExcelColumn label="Grade" value="grade" />
                        <ExcelColumn label="Contact Number" value="contactNo" />
                        <ExcelColumn label="Email Id" value="emailId" />
                        <ExcelColumn label="Registration Type" value="rType" />
                        <ExcelColumn
                          label="Registration By - Name"
                          value="rName"
                        />
                        <ExcelColumn
                          label="Registration By - Mobile No."
                          value="rMobile"
                        />
                        <ExcelColumn
                          label="Registration By - Email Id"
                          value="rEmail"
                        />
                      </ExcelSheet>
                    </ExcelFile>
                  </StyledTableCell>
                </StyledTableRow>
              </StyledTableHead>
              <StyledTableBody>
                {formValues.studentPerforms?.map((studData, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      {studData.studPortrait.alias.first}{" "}
                      {studData.studPortrait.alias.last}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.studPortrait.academic.schoolName
                        ? studData.studPortrait.academic.schoolName
                        : "not given"}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.studPortrait.grade}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.studPortrait.pingAt.cell
                        ? studData.studPortrait.pingAt.cell
                        : "not given"}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.studPortrait.pingAt.virtualAdd
                        ? studData.studPortrait.pingAt.virtualAdd
                        : "not given"}{" "}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.finalPerformance === -1
                        ? "Not Submitted"
                        : studData.finalPerformance === -2
                        ? "Not Appeared"
                        : studData.finalPerformance}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.finalPerformance === -1
                        ? "Not Submitted"
                        : studData.finalPerformance === -2
                        ? "Not Appeared"
                        : studData.totalScore}
                    </StyledTableCell>
                    <StyledTableCell>
                      {studData.finalPerformance != -1 &&
                      studData.finalPerformance != -2 ? (
                        <Link
                          exact
                          to={"/performanceScreen"}
                          state={{
                            studId: studData.studId,
                            subId: studData.subId,
                            testId: state.testId,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <StyledButton variant="contained" size="medium">
                            Open
                          </StyledButton>
                        </Link>
                      ) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
          </StyledTableContainer>
        </div>
      </TabPanel>
    </RootDiv>
  );
}

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <div>{children}</div>}</div>;
};
