import React from "react";
import { Typography } from "@mui/material";
import Footer from "../Footer";
import Header from "../FrontHeader";

export default function DataPolicy() {
  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      <div style={{ textAlign: "justify", margin: 40 }}>
        <Typography
          variant="h5"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Data Policy
        </Typography>
        <Typography
          variant="h6"
          style={{ textAlign: "right", fontWeight: "bold" }}
        >
          6th September , 2022
        </Typography>{" "}
        <br />
        <br />
        <Typography>
          Data Policy The primary purpose of data policy here is to advance our
          products or services by permitting analyses that allow for the fullest
          possible understanding of day-to-day challenges, to help develop and
          innovate new solutions, and to ensure that decisions are based on the
          best available evidence
          <br />
          <br /> The purpose of this policy is to clarify current practice on
          the use and sharing of data collected through the use of our products
          or services. Usage of Terms In this Data Policy, terms like “we”,
          “us”, “our”, etc. refer to FENLEI TECHNOLOGY PRIVATE LIMITED and you
          or the entity you represent or the permitted entity (third party
          entity of your entity you represent) (“you” or “your”).
          <br />
          <br />
          This data policy must be read in conjunction with the use of our
          products or services. If you have a question about this data policy,
          including any questions about your sensitive personal information; if
          you wish to withdraw or restrict any earlier consent; to have us
          correct or erase or port your sensitive personal information from our
          systems; or if you need information about your sensitive personal
          information which we hold, please contact:{" "}
          <a href="mailto: admin@fenlei.in">admin@fenlei.in</a>. <br />
          <br />
          Fenlei Technology complies with all applicable laws governing the use,
          process and transfer of sensitive personal information, protected
          personal information and or non identifiable personal information that
          we collect, store or otherwise have access to in the course of doing
          business. <br />
          <br />
          In addition to the applicable laws that may govern our individual
          business or relationships with you, this data policy also sets out how
          we operate, comply, and apply to the particular business or
          relationship with you.
          <br />
          <br /> By “Sensitive Personal Information” we mean data that is unique
          to an individual, such as name, email id, passwords; bank account or
          credit card or debit card or other payment instrument details;
          physical, psychological and mental health conditions; sexual
          orientation; medical records and history; biometric / finger-prints
          information, UIDAI Aadhaar Number, local identifier and any Central or
          State Government issued Photo identity card number; address, telephone
          or cell phone and/or fax number; Health Plan Beneficiary number, voter
          ld, pan card, photographic images which can individually identify, and
          any other unique identifying number,…, etc. By “Non-Personally
          Identifiable Information,” we mean information that does not identify
          any individual personally, but can provide us with usage data, either
          individually or in the aggregate.
          <br />
          <br /> Non-Personally Identifiable Information may include demographic
          information, clinical information, aggregated information, certain
          information like time of call, operating system and browser
          information, logs of remote internet protocol for request and
          response, logs of all domain information, uploaded and downloaded file
          information, hash of signatures, authentication details, and other
          non-personally identifiable information collected by us or provided by
          the user. A "Data Subject” refers to an identified or identifiable
          natural person.
          <br />
          <br />
          “Process” and “processing” mean any operation or set of operations
          performed on personal data, automated or otherwise, such as the
          collection, recording, organization, structuring, storage, web
          hosting, adaptation or alteration, retrieval, consultation, use,
          disclosure by transmission, dissemination or otherwise making
          available.
          <br />
          <br /> What kinds of information do we collect? We may collect
          non-identifiable personal information about you from sources that
          includes information that we receive from you, such as on applications
          or other forms when you visit/participate in{" "}
          <a href="https://bprim.gyansopan.com" target="_blank">
            https://bprim.gyansopan.com
          </a>
          <br />
          <br />
          and its subdomain or related applications, products, or services that
          are managed by Fenlei Technology Private Limited. The personal data
          that we collect, process, and use in connection with the services and
          websites may also include information that we collect when you
          interact with us and that you provide to us through our customer
          service or to our sales team.
          <br />
          <br /> We may use your provided phone number and/or email address to
          get in touch with you about our services. Other than anonymous user
          visits, we also encourage visitors to connect with us via email at{" "}
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a> and our other
          channels, and details, like name, email address, mailing address,
          contact number, and professional details like company and designation
          may be requested.
          <br />
          <br /> None of the users would be required to forcibly submit their
          personal information in any given circumstance. All information
          collected would be voluntarily submitted via designated web channels
          and shall be securely processed and stored only for the authorized
          purposes. Users may always refuse to supply individual identification
          details.
          <br />
          <br /> Non-individual details may include the browser name, IP
          address, device model, network details, server details, and other
          technical information such as the operating system and internet
          service providers. We collect information about the transaction.{" "}
          <br />
          <br />
          This includes any kind of transaction like payment information, such
          as your credit or debit card number and other card information, other
          account and authentication information, and billing, delivery, and
          contact details. Confidentiality We do not share your sensitive
          personal information with anyone, unless in exceptional circumstances
          where we need to comply with the law or take steps to protect our
          existence.
          <br />
          <br /> We will not use or disclose this personal data except as
          necessary to perform its business obligation or as legally obliged or
          permitted, or as permitted by a data subject, and then only (i) to
          persons (including our personnel) who have a legitimate need to access
          that personal data; and (ii) to the extent necessary for the relevant
          obligation or permission. When processing personal data is no longer
          reasonably required to l our agreement(s) or legal, including
          regulatory and accounting, obligations, we will cease all use of the
          personal data.
          <br />
          <br /> Third Party Data Processing We may also have access to personal
          data via a third-party processing agreement. This may include
          processing sensitive information. <br />
          <br />
          This personal data is processed in accordance with a legally binding
          agreement with the relevant third-party and complies with all
          applicable laws. How do we use this information? We use the
          information that we have (subject to choices you make) as described
          below, and to provide and support our products and related services
          described in the SaaS and Conditions. <br />
          <br />
          Here's how: We use the information we have to develop, test, and
          improve our products, including by conducting surveys and research,
          and testing and troubleshooting new products and features. We use the
          information that we have (including your activity off our Products,
          such as the websites you visit and transactions you do) to help and
          measure the effectiveness and distribution of the services.
          <br />
          <br /> We use the information that we have (including from research
          partners who we collaborate with) to conduct and support research and
          innovation on topics of general social welfare, technological
          advancement, client interest, etc. We may also process (i) all of the
          above information; and (ii) any other information which we have
          concerning personal data of any data subject where that is known or
          made known to us in the ordinary course of our business including but
          not limited to agreements and contracts for the supply of services and
          or products, to the extent that such processing is contemplated in or
          is within the good faith performance of our obligations or rights
          under such agreements or contracts and for any other legitimate
          purpose ancillary thereto.
          <br />
          <br /> How is this information shared? We work through cloud computing
          mechanisms, which help us to provide and improve our products and
          tools to grow businesses, which makes it possible to operate the
          services and products. We provide information and content to vendors
          and service providers who support our business, such as by providing
          technical infrastructure services, analyzing how our products are
          used, providing customer service, conducting surveys and providing
          analytics and measurement reports to our vendors and service
          providers. <br />
          <br />
          We access, preserve and share your information with regulators, law
          enforcement or In response to a legal request (eg. a search warrant,
          court order or subpoena), if we have a good-faith belief that the law
          requires us to do so or when we have a good-faith belief that it is
          necessary to: detect, prevent and address fraud, unauthorized use of
          the Products, breaches of our Terms or Policies, or other harmful or
          illegal activity; to protect ourselves (including our rights, property
          or Products), you or others, including as part of investigations or
          regulatory enquiries; or to prevent death or imminent bodily harm.
          <br />
          <br /> Security and Privacy A major goal of security and privacy is to
          assure that individuals’ information is properly protected while
          allowing the flow of information needed to provide. We have put in
          place technical, administrative, and physical safety procedures that
          are designed to defend protected information and sensitive personal
          information from unlawful access, discovery, use, and variation.{" "}
          <br />
          <br />
          We regularly evaluate our security procedures to consider appropriate
          new technology and methods. However, please be aware that despite our
          best efforts, no security measures are perfect or impenetrable and, in
          such an unfortunate event, Fenlei Technology will not be liable for
          any expense, loss or damage, including, without limitation, indirect
          or consequential loss or damage, or any expense, loss or damage
          whatsoever arising from use, or loss of use, of data, arising out of
          or in connection with the use of our products or services. <br />
          <br />
          All information is end-to-end encrypted in rest and transit(create,
          transmit, receive, or maintain). We account for and maintain all
          activities that occur under your user account and password. Users are
          accountable for maintaining the privacy of their accounts and
          passwords. <br />
          <br />
          Security Audit We follow the standard of OWASP TOP 10 Security Risks
          and Vulnerabilities like Injection, Broken Authentication, Sensitive
          Data Exposure, XML External Entities (XXE), Broken Access control,
          Security misconfigurations, Cross Site Scripting (XSS), Insecure
          Deserialization, Using Components with known vulnerabilities,
          insufficient logging and monitoring, and other through external
          authorized agencies. Any of our services or products can not be made
          use of without the valid Security audit certificates.
          <br />
          <br />
          Fenlei Technology plays a vital role in getting the security audit
          certificates from the authorized third party agencies for the products
          or services managed by Fenlei Technology Private Limited. In addition
          to OWASP TOP 10, we follow compliance such as GDPR (General Data
          Protection Regulation) on request from the clients or customers of our
          services or products. <br />
          <br />
          If any changes to this policy, we'll notify you before we make changes
          to this policy and give you the opportunity to review the revised
          policy before you choose to continue using our products. All queries
          regarding the content may be directed to: Email ID :
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a>
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
