import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RootDiv, StyledGrid } from "../../../css/StyledComponents";

import TakeTestContent from "./TakeTestContent";

const TakeTest = (props) => {
  const fUser = props.fUser;
  const setFetchedUser = props.setFetchedUser;
  const { state } = useLocation();
  const testId = state ? state.id : "";
  const submissionId = state?.subId;
  const duration = state?.duration;
  const startTime = state?.startTime;
  const endTime = state?.endTime;
  const restarted = state?.restarted;
  let currentTime = new Date();
  const enterTime = currentTime.getTime();

  useEffect(() => {
    props.setUrl(window.location.pathname);
  });

  return (
    <RootDiv>
      <StyledGrid container direction="column">
        <StyledGrid item container>
          <StyledGrid item xs={false} sm={1} />
          <StyledGrid item xs={12} sm={10}>
            {window.history.state?.backPressed ? (
              <TakeTestContent
                fUser={fUser}
                setFetchedUser={setFetchedUser}
                testId={window.history.state.testId}
                submissionId={window.history.state.submissionId}
                quesMarks={window.history.state.quesMarks}
                submissions={window.history.state.submissions}
                questions={window.history.state.questions}
                alertTimer={window.history.state.alertTimer}
                timeLeft={window.history.state.timeLeft}
                duration={window.history.state.duration}
                testImages={window.history.state.testImages}
                cameraPresent={window.history.state.cameraPresent}
                quesLangs={window.history.state.quesLangs}
                startTime={window.history.state.startTime}
                backPressed={true}
                restarted={window.history.state.restarted}
                displayQuesSubIndex={window.history.state.displayQuesSubIndex}
                timeElapsed={window.history.state.timeElapsed}
                endTime={window.history.state.endTime}
                totalTestMarks={window.history.state.totalTestMarks}
                testStarted={window.history.state.testStarted}
                pageNumberTf={window.history.state.pageNumberTf}
                pageNumberSs={window.history.state.pageNumberSs}
                pageNumberMs={window.history.state.pageNumberMs}
                pageNumberSubj={window.history.state.pageNumberSubj}
                pageNumberNum={window.history.state.pageNumberNum}
                pageNumberCS={window.history.state.pageNumberCS}
                subQuesSubmitTime={window.history.state.subQuesSubmitTime}
                testTimeOver={window.history.state.testTimeOver}
                schoolName={window.history.state.schoolName}
              />
            ) : window.history.state?.pageRefreshed ? (
              <TakeTestContent
                fUser={fUser}
                setFetchedUser={setFetchedUser}
                testId={window.history.state.testId}
                submissionId={window.history.state.submissionId}
                duration={window.history.state.duration}
                startTime={window.history.state.startTime}
                enterTime={enterTime}
                restarted={window.history.state.restarted}
                endTime={window.history.state.endTime}
                // setCamStream={setCamStream}
                // stopWebcam={stopWebcam}
              />
            ) : (
              <TakeTestContent
                fUser={fUser}
                setFetchedUser={setFetchedUser}
                testId={testId}
                submissionId={submissionId}
                duration={duration}
                startTime={startTime}
                enterTime={enterTime}
                restarted={restarted}
                endTime={endTime}
                // setCamStream={setCamStream}
                // stopWebcam={stopWebcam}
              />
            )}
          </StyledGrid>
          <StyledGrid item xs={false} sm={1} />
        </StyledGrid>
      </StyledGrid>
    </RootDiv>
  );
};

export default TakeTest;
