import React, { useState } from "react";
import { MathJax } from "better-react-mathjax";
import {
  CardDiv,
  CardParentDiv,
  StyledCard,
  StyledCardContent,
  StyledCardActions,
  StyledTextField,
  StyledCardButton,
  StyledCardTextField,
} from "../../../css/StyledComponents";

const TakeTestCourseCard = (props) => {
  const fUser = props.fUser;
  const getLangIndex = (arr, lang) => {
    return arr.findIndex((element) => {
      return element.language === lang;
    });
  };
  return (
    <CardParentDiv>
      <p>{props.FakeQuestionNo})</p>
      <StyledCard>
        <StyledCardContent>
          {props.quesLangs.map((lang) => {
            return (
              <div key={`1${lang}`}>
                <MathJax dynamic={true}>
                  {props.Question.ques[
                    getLangIndex(props.Question.ques, lang)
                  ] &&
                  props.Question.ques[getLangIndex(props.Question.ques, lang)]
                    ?.is
                    ? props.Question.ques[
                        getLangIndex(props.Question.ques, lang)
                      ].is
                    : `Not Available in ${lang} language`}
                </MathJax>
              </div>
            );
          })}
        </StyledCardContent>

        <StyledCardContent>
          <CardDiv>
            <StyledCardTextField
              variant="filled"
              value={`Correct Marks: ${props.correctMarks}`}
            />
            <StyledCardTextField
              variant="filled"
              value={`Wrong Marks: ${props.wrongMarks}`}
            />
          </CardDiv>
          <CardDiv>
            <StyledCardActions>
              <StyledCardButton
                variant="contained"
                size="small"
                onClick={() => {
                  props.setShowQuesList(false);
                  props.setDisplayQuestion({
                    ...props.Question,
                    quesNo: props.QuestionNo,
                    correctMarks: props.correctMarks,
                    wrongMarks: props.wrongMarks,
                  });
                  props.setDisplayQuesSubIndex(props.quesSubIndex);
                }}
              >
                View
              </StyledCardButton>
            </StyledCardActions>
          </CardDiv>
        </StyledCardContent>
      </StyledCard>
    </CardParentDiv>
  );
};

export default TakeTestCourseCard;
