export const gradesList =
    [
        "V",
        "VI",
        "VII",
        "VIII",
        "IX",
        "X",
        "XI",
        "XII",
        "Under Graduate I",
        "Under Graduate II",
        "Under Graduate III",
        "Under Graduate IV",
    ];