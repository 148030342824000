import * as Yup from "yup";

const mobileNoFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const RegistrationValidationForm = Yup.object().shape({
  email: Yup.string()
    .email("Email is invalid, Check for empty spaces")
    .required("Please enter student's email address"),
  grade: Yup.string().required("Please select student's grade"),
  firstName: Yup.string().required("Please enter student's first name"),
  lastName: Yup.string().required("Please enter student's last name"),
  pincode: Yup.number()
    .required("Please enter the Pin code")
    .typeError("Pin code should contain numbers only")
    .integer("Pin code must be a 6 digit number")
    .max(999999, "Pin code must be a 6 digit number")
    .min(100000, "Pin code must be a 6 digit number"),
  mobileNo: Yup.string()
    .required("Please enter student's mobile number")
    .matches(
      mobileNoFormat,
      "Phone number is not valid. Don't add country code"
    ),
});

export default RegistrationValidationForm;
