import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import ReactExports from "react-export-excel";

import "../../../css/content.css";
import {
  StyledGrid,
  StyledDialog,
  StyledDialogContent,
  StyledDialogActions,
  StyledButton,
  StyledInputLabel,
  StyledAutocomplete,
  StyledCheckbox,
  StyledTextField,
} from "../../../css/StyledComponents";

import { LoadingSpinner } from "../../../constants/loadingSpinner";

import PublishTestCourseCard from "./PublishTestCourseCard";

import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
import { subjectList } from "../../../constants/subjects";
import { quesLangList } from "../../../constants/quesLangList";

const ExcelFile = ReactExports.ExcelFile;
const ExcelSheet = ReactExports.ExcelSheet;
const ExcelColumn = ReactExports.ExcelColumn;

const teacherGetTestQuery = loader(
  "../../../graphqlCalls/test/teacherGetATest.gql"
);
const studentGetTestQuery = loader(
  "../../../graphqlCalls/test/studentGetATest.gql"
);
const getStudentsQuery = loader(
  "../../../graphqlCalls/student/userGetAllStudents.gql"
);
const userAssignStudentsTestMutation = loader(
  "../../../graphqlCalls/student/UserAssignStudentsTest.gql"
);
const userGetTestResultsMutation = loader(
  "../../../graphqlCalls/submission/UserGetTestResults.gql"
);

const initialMarks = {
  Beginner: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Intermediate: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
  Advance: {
    TNF: { correct: 0, wrong: 0 },
    ObjSingleSol: { correct: 0, wrong: 0 },
    ObjMultiSol: { correct: 0, wrong: 0 },
    Subjective: { correct: 0, wrong: 0 },
    Integral: { correct: 0, wrong: 0 },
  },
};
const PublishTestContent = (props) => {
  const [questions, setQuestions] = useState([]);
  const [students, setStudents] = useState([]);
  const [open, setOpen] = useState(false);
  const [selModel, setSelModel] = useState([]);
  const [assignedStuds, setAssignedStuds] = useState([]);
  const [editSelModel, setEditSelModel] = useState(false);
  const [quesMarks, setQuesMarks] = useState(initialMarks);
  const [quesLangs, setQuesLangs] = useState([quesLangList[0]]);
  const [testResults, setTestResults] = useState([{ columns: [], data: [] }]);

  const fUser = props.fUser;

  const checkedIcon = <CheckBox fontSize="small" />;
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const columns = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    {
      field: "grade",
      headerName: "Grade",
      width: 150,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
    },
  ];
  const rows = students.map((studCat, index) => {
    return {
      id: studCat.student._id,
      firstName: studCat.student.portrait.alias.first,
      lastName: studCat.student.portrait.alias.last,
      grade: studCat.student.portrait.grade,
      category: studCat.category,
    };
  });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h >= 0 ? h + (h == 1 ? " Hour " : " Hours ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Minute " : " Minutes ") : "";
    var sDisplay = s >= 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  const [
    UserAssignStudentsTest,
    { loading: assigning, error: errorAssigning, data: dataAssigning },
  ] = useMutation(userAssignStudentsTestMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      setAssignedStuds(selModel);
      NotificationsSuccess("Assigned!");
    },
  });
  const [
    UserGetTestResultsMutation,
    {
      loading: gettingResults,
      error: errorGettingResults,
      data: dataGettingResults,
    },
  ] = useMutation(userGetTestResultsMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {},
  });

  const assignStudents = async () => {
    if (assigning) return;
    try {
      const callInfo = await UserAssignStudentsTest({
        variables: {
          input: {
            userType: props.fUser ? props.fUser.role : null,
            userId: props.fUser ? props.fUser._id : null,
            testId: props.testId,
            students: selModel,
          },
        },
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const getResults = async () => {
    if (gettingResults) return;
    try {
      const callInfo = await UserGetTestResultsMutation({
        variables: {
          input: {
            userId: props.fUser ? props.fUser._id : null,
            testId: props.testId ? props.testId : null,
          },
        },
      });
      let resultsToSet = [[]];
      let fetchedTestResults = callInfo.data.UserGetTestResults.results;
      let noOfMetaRows = callInfo.data.UserGetTestResults.noOfMetaRows;
      let noOfStudentDets = callInfo.data.UserGetTestResults.noOfStudentDets;

      for (let q = 0; q < fetchedTestResults.length; q++) {
        let newRow = [];
        for (let w = 0; w < fetchedTestResults[0].length; w++) {
          newRow[w] = { value: "" };
        }
        resultsToSet[q] = newRow;
      }

      for (let i = 0; i < noOfMetaRows; i++) {
        let resultRow = [];
        for (let j = 0; j < fetchedTestResults[0].length; j++) {
          resultRow[j] = { value: `${fetchedTestResults[i][j]}` };
        }
        resultsToSet[i] = resultRow;
      }
      for (let i = noOfMetaRows; i < fetchedTestResults.length; i++) {
        let resultRow = [];
        for (let j = 0; j < noOfStudentDets; j++) {
          resultRow[j] = { value: `${fetchedTestResults[i][j]}` };
        }
        for (let j = noOfStudentDets; j < fetchedTestResults[0].length; j++) {
          let resObj = { value: "-" };
          if (fetchedTestResults[i][j]) {
            resObj = JSON.parse(fetchedTestResults[i][j]);
          }
          resultRow[j] = resObj;
        }
        resultsToSet[i] = resultRow;
      }
      setTestResults([{ columns: [], data: resultsToSet }]);
    } catch (error) {
      console.log("error", error);
      alert("Something went wrong");
    }
  };

  const { data: getStudentsData, loading: fetchingStudents } = useQuery(
    getStudentsQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      skip: !fUser || fUser.role === "Student" || props.isOfExam,
      variables: {
        userId: props.fUser ? props.fUser._id : null,
        userT: props.fUser ? props.fUser.role : null,
      },
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetAllStudents }) => {
        // ISSUE:CANT WE RUN BELOW LOGIN IN BE?
        const sModel = userGetAllStudents
          .map((studCat) => {
            let foundTest = studCat.student.tests
              ? studCat.student.tests.find(
                  (test) => test.testId?.toString() === props.testId?.toString()
                )
              : null;
            if (foundTest) return studCat.student._id;
          })
          .filter((stud) => stud);
        setSelModel(sModel);
        setAssignedStuds(sModel);

        setStudents(userGetAllStudents);
      },
    }
  );
  const returnQueryName = () => {
    if (fUser.role === "Teacher" || fUser.role === "Institute")
      return "teacherGetATest";
    else if (fUser.role === "Student") return "studentGetATest";
    else {
      console.log("HANDLE THIS CASE!");
    }
  };
  const returnQueryType = () => {
    if (fUser.role === "Teacher" || fUser.role === "Institute")
      return teacherGetTestQuery;
    else if (fUser.role === "Student") return studentGetTestQuery;
    else {
      console.log("HANDLE THIS CASE!");
    }
  };
  const returnQueryFields = () => {
    if (fUser.role === "Teacher" || fUser.role === "Institute") {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
          userId: fUser ? fUser._id : null, //why is this necessary??if not there then why does page refresh throw error?
          tId: props.testId,
        },
        skip: !fUser,
        onError: (err) => {
          alert("Something went wrong");
        },
        onCompleted: ({ teacherGetATest }) => {
          setQuesMarks(teacherGetATest.quesMarks);
          const questionsToSet = teacherGetATest.questions.map((testId) => {
            return { quesNo: testId.quesNo, ques: testId.id };
          });
          setQuestions(questionsToSet);
        },
      };
    } else if (fUser.role === "Student") {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
          userId: fUser ? fUser._id : null, //why is this necessary??if not there then why does page refresh throw error?
          tId: props.testId,
        },
        skip: !fUser,
        onError: (err) => {
          alert("Something went wrong");
        },
        onCompleted: ({ studentGetATest }) => {
          setQuesMarks(studentGetATest.quesMarks);
          const questionsToSet = studentGetATest.questions.map((testId) => {
            return { quesNo: testId.quesNo, ques: testId.id };
          });
          setQuestions(questionsToSet);
        },
      };
    } else {
      console.log("HANDLE THIS CASE!");
    }
  };
  const { loading, error, data, networkStatus, refetch } = useQuery(
    returnQueryType(),
    returnQueryFields()
  );
  let testStartTimeLocale, testEndTimeLocale;
  if (data && !loading) {
    const testStartTimeParsed = new Date(
      data[`${returnQueryName()}`].testStartTime
    );
    testStartTimeLocale = testStartTimeParsed.toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata",
    });
    const testEndTimeParsed = new Date(
      data[`${returnQueryName()}`].testEndTime
    );
    testEndTimeLocale = testEndTimeParsed.toLocaleTimeString("en-US", {
      timeZone: "Asia/Kolkata",
    });
  }
  let TNFQues = [],
    ObjSsQues = [],
    ObjMsQues = [],
    SubjQues = [],
    NumQues = [];
  if (questions != []) {
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].ques.quesType === "TNF") TNFQues.push(questions[i]);
      else if (questions[i].ques.quesType === "ObjSingleSol")
        ObjSsQues.push(questions[i]);
      else if (questions[i].ques.quesType === "ObjMultiSol")
        ObjMsQues.push(questions[i]);
      else if (questions[i].ques.quesType === "Subjective")
        SubjQues.push(questions[i]);
      else if (questions[i].ques.quesType === "Integral")
        NumQues.push(questions[i]);
    }
  }
  const [pageNumberTf, setPageNumberTf] = useState(0);
  const [pageNumberSs, setPageNumberSs] = useState(0);
  const [pageNumberMs, setPageNumberMs] = useState(0);
  const [pageNumberSubj, setPageNumberSubj] = useState(0);
  const [pageNumberNum, setPageNumberNum] = useState(0);

  let testsPerPage = 6;

  const pagesVisitedTf = pageNumberTf * testsPerPage;
  const pagesVisitedSs = pageNumberSs * testsPerPage;
  const pagesVisitedMs = pageNumberMs * testsPerPage;
  const pagesVisitedSubj = pageNumberSubj * testsPerPage;
  const pagesVisitedNum = pageNumberNum * testsPerPage;

  const displayTNFQues = TNFQues.slice(
    pagesVisitedTf,
    pagesVisitedTf + testsPerPage
  ).map((tfQues) => {
    return (
      <StyledGrid key={tfQues.ques._id} item xs={12} sm={4}>
        <PublishTestCourseCard
          //make changes to all displayQuesTypes
          correctMarks={
            quesMarks[`${tfQues["ques"]["gradeLevel"][0]["level"]}`]["TNF"][
              "correct"
            ]
          }
          wrongMarks={
            quesMarks[`${tfQues["ques"]["gradeLevel"][0]["level"]}`]["TNF"][
              "wrong"
            ]
          }
          id={tfQues.ques._id}
          subId={props.submissionId}
          testId={props.testId}
          Question={tfQues.ques.ques}
          quesLangs={quesLangs}
          QuestionNo={tfQues.quesNo}
          fUser={fUser}
        />
      </StyledGrid>
    );
  });
  const displayObjSsQues = ObjSsQues.slice(
    pagesVisitedSs,
    pagesVisitedSs + testsPerPage
  ).map((ssQues) => {
    return (
      <StyledGrid key={ssQues.ques._id} item xs={12} sm={4}>
        <PublishTestCourseCard
          //make changes to all displayQuesTypes
          correctMarks={
            quesMarks[`${ssQues["ques"]["gradeLevel"][0]["level"]}`][
              "ObjSingleSol"
            ]["correct"]
          }
          wrongMarks={
            quesMarks[`${ssQues["ques"]["gradeLevel"][0]["level"]}`][
              "ObjSingleSol"
            ]["wrong"]
          }
          id={ssQues.ques._id}
          subId={props.submissionId}
          testId={props.testId}
          Question={ssQues.ques.ques}
          quesLangs={quesLangs}
          QuestionNo={ssQues.quesNo}
          fUser={fUser}
        />
      </StyledGrid>
    );
  });
  const displayObjMsQues = ObjMsQues.slice(
    pagesVisitedMs,
    pagesVisitedMs + testsPerPage
  ).map((msQues) => {
    return (
      <StyledGrid key={msQues.ques._id} item xs={12} sm={4}>
        <PublishTestCourseCard
          correctMarks={
            quesMarks[`${msQues["ques"]["gradeLevel"][0]["level"]}`][
              "ObjMultiSol"
            ]["correct"]
          }
          wrongMarks={
            quesMarks[`${msQues["ques"]["gradeLevel"][0]["level"]}`][
              "ObjMultiSol"
            ]["wrong"]
          }
          id={msQues.ques._id}
          subId={props.submissionId}
          testId={props.testId}
          Question={msQues.ques.ques}
          quesLangs={quesLangs}
          QuestionNo={msQues.quesNo}
          fUser={fUser}
        />
      </StyledGrid>
    );
  });
  const displaySubjQues = SubjQues.slice(
    pagesVisitedSubj,
    pagesVisitedSubj + testsPerPage
  ).map((subQues) => {
    return (
      <StyledGrid key={subQues.ques._id} item xs={12} sm={4}>
        <PublishTestCourseCard
          correctMarks={
            quesMarks[`${subQues["ques"]["gradeLevel"][0]["level"]}`][
              "Subjective"
            ]["correct"]
          }
          wrongMarks={
            quesMarks[`${subQues["ques"]["gradeLevel"][0]["level"]}`][
              "Subjective"
            ]["wrong"]
          }
          id={subQues.ques._id}
          subId={props.submissionId}
          testId={props.testId}
          Question={subQues.ques.ques}
          quesLangs={quesLangs}
          QuestionNo={subQues.quesNo}
          fUser={fUser}
        />
      </StyledGrid>
    );
  });
  const displayNumQues = NumQues.slice(
    pagesVisitedNum,
    pagesVisitedNum + testsPerPage
  ).map((intQues) => {
    return (
      <StyledGrid key={intQues.ques._id} item xs={12} sm={4}>
        <PublishTestCourseCard
          correctMarks={
            quesMarks[`${intQues["ques"]["gradeLevel"][0]["level"]}`][
              "Integral"
            ]["correct"]
          }
          wrongMarks={
            quesMarks[`${intQues["ques"]["gradeLevel"][0]["level"]}`][
              "Integral"
            ]["wrong"]
          }
          id={intQues.ques._id}
          subId={props.submissionId}
          testId={props.testId}
          Question={intQues.ques.ques}
          quesLangs={quesLangs}
          QuestionNo={intQues.quesNo}
          fUser={fUser}
        />
      </StyledGrid>
    );
  });

  const pageCountTf = Math.ceil(TNFQues.length / testsPerPage);
  const pageCountSs = Math.ceil(ObjSsQues.length / testsPerPage);
  const pageCountMs = Math.ceil(ObjMsQues.length / testsPerPage);
  const pageCountSubj = Math.ceil(SubjQues.length / testsPerPage);
  const pageCountNum = Math.ceil(NumQues.length / testsPerPage);

  const changePageTf = ({ selected }) => {
    setPageNumberTf(selected);
  };
  const changePageSs = ({ selected }) => {
    setPageNumberSs(selected);
  };
  const changePageMs = ({ selected }) => {
    setPageNumberMs(selected);
  };
  const changePageSubj = ({ selected }) => {
    setPageNumberSubj(selected);
  };
  const changePageNum = ({ selected }) => {
    setPageNumberNum(selected);
  };
  const quesMapDisplay = [
    {
      header: "TRUE or FALSE",
      pgCount: pageCountTf,
      onPgChange: changePageTf,
      display: displayTNFQues,
    },
    {
      header: "Objective Single Solution",
      pgCount: pageCountSs,
      onPgChange: changePageSs,
      display: displayObjSsQues,
    },
    {
      header: "Objective Multi Solution",
      pgCount: pageCountMs,
      onPgChange: changePageMs,
      display: displayObjMsQues,
    },
    {
      header: "Subjective",
      pgCount: pageCountSubj,
      onPgChange: changePageSubj,
      display: displaySubjQues,
    },
    {
      header: "Integer Solution",
      pgCount: pageCountNum,
      onPgChange: changePageNum,
      display: displayNumQues,
    },
  ];
  if (loading || !data)
    return (
      <div>
        <br />
        {LoadingSpinner(loading || !data)}
      </div>
    ); //!data check to allow refresh
  return (
    <div>
      {NotificationsContainer()}
      <StyledDialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        fullWidth
        // aria-labelledby="form-dialog-title"
      >
        <StyledDialogContent>
          <br />
          {/* {LoadingSpinner(fetchingStudents)} //dont uncomment cuz i guess data grid has spinner*/}
          <div style={{ height: 400, width: "100%" }}>
            {/* <StyledButton
              // size="medium"
              variant="contained"
              color="primary"
              // className={classes.buttonStyles}
              onClick={() => {
                setEditSelModel(true);
              }}
            >
              Edit
            </StyledButton> */}
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              checkboxSelection
              loading={fetchingStudents}
              disableSelectionOnClick={assigning}
              onRowSelectionModelChange={(selectedRows) => {
                // if (editSelModel) setSelModel(selectedRows);
                setSelModel(selectedRows);
              }}
              selectionModel={selModel ? Object.values(selModel) : []}
            />
          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={() => {
              setSelModel(assignedStuds);
              handleClose();
            }}
            variant="contained"
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={selModel.length < 1}
            onClick={async () => {
              await assignStudents();
              handleClose();
            }}
          >
            Assign
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <div className="header1">
        <p className="title">
          Date :{" "}
          <span className="spanId1">
            {" "}
            {data[`${returnQueryName()}`].ofDate} (YYYY-MM-DD)
          </span>
        </p>
        <p className="title">
          Test Name :{" "}
          <span className="spanId1"> {data[`${returnQueryName()}`].name}</span>
        </p>
        {fUser.role === "Teacher" || fUser.role === "Institute" ? (
          <p className="title">
            Test Type :{" "}
            <span className="spanId1">
              {data[`${returnQueryName()}`].testType}
            </span>{" "}
            Cut Off :{" "}
            <span className="spanId1">
              {data[`${returnQueryName()}`].cutOff}
            </span>
          </p>
        ) : null}
        <p className="title">
          Test Code :{" "}
          <span className="spanId1">
            {" "}
            {data[`${returnQueryName()}`].testCode}
          </span>
        </p>
        <p className="title">
          Grades :
          {data[`${returnQueryName()}`].grade.map((item, index) => {
            return (
              <span key={index} className="spanId1">
                {item}
                {index != data[`${returnQueryName()}`].grade.length - 1
                  ? ", "
                  : ""}
              </span>
            );
          })}
        </p>
      </div>
      <div className="header1">
        <p className="title">
          Start Time : <span className="spanId1">{testStartTimeLocale}</span>
        </p>
        <p className="title">
          End Time : <span className="spanId1">{testEndTimeLocale}</span>
        </p>
        <p className="title">
          Duration :{" "}
          <span className="spanId1">
            {" "}
            {secondsToHms(data[`${returnQueryName()}`].duration)}
          </span>
        </p>
        {/* <p className="title">
          Competitive Exams :
          {data[`${returnQueryName()}`].compiExams.map((item, index) => {
            return (
              <span key={index} className="spanId1">
                {item}
              </span>
            );
          })}
        </p> */}
        {/* <p className="title">
          Grades :
          {data[`${returnQueryName()}`].grade.map((item, index) => {
            return (
              <span key={index} className="spanId1">
                {item}
                {index != data[`${returnQueryName()}`].grade.length - 1
                  ? ", "
                  : ""}
              </span>
            );
          })}
        </p> */}
        <div
          style={{
            marginLeft: "-9px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <StyledInputLabel htmlFor="exam">
            Question Languages:
          </StyledInputLabel>
          <StyledAutocomplete
            // CHECKBOX USED HERE EVEN THOUGH NOT MULTIPLE SELECTION
            multiple
            options={quesLangList}
            disableCloseOnSelect
            value={quesLangs}
            onChange={(event, value) => {
              setQuesLangs(value);
            }}
            getOptionLabel={(option) => option}
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>
            //     <StyledCheckbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       checked={selected}
            //     />
            //     {option}
            //   </React.Fragment>
            // )}
            renderInput={(params) => <StyledTextField {...params} />}
          />
        </div>
      </div>
      {/* {fUser.role === "Teacher" || fUser.role === "Institute" ? (
        <p className="title">
          Topics :
          {data[`${returnQueryName()}`].topics.map((item, index) => {
            return (
              <span key={index} className="spanId1">
                {item.secondaryTopic ? `${item.secondaryTopic}, ` : ""}
              </span>
            );
          })}
        </p>
      ) : null} */}
      <div className="header1">
        {!data[`${returnQueryName()}`].isOfExam &&
        (fUser.role === "Teacher" || fUser.role === "Institute") ? (
          <div>
            <StyledButton
              variant="contained"
              size="medium"
              onClick={() => {
                handleOpen();
              }}
            >
              Assign Students
            </StyledButton>
          </div>
        ) : null}
        {fUser.role === "Teacher" || fUser.role === "Institute" ? (
          <div>
            <ExcelFile
              element={
                <StyledButton
                  variant="contained"
                  size="medium"
                  disabled={gettingResults}
                >
                  Download Data
                </StyledButton>
              }
            >
              <ExcelSheet
                dataSet={testResults}
                name="Test Results"
              ></ExcelSheet>
            </ExcelFile>
            <StyledButton
              variant="contained"
              disabled={selModel.length < 1}
              onClick={async () => {
                await getResults();
              }}
            >
              Get Results
            </StyledButton>
          </div>
        ) : null}
        {/* {fUser.role === "Teacher" || fUser.role === "Institute" ? (
          <p className="title">
            Is going to be assigned to exam :
            <span className="spanId1">
              {data[`${returnQueryName()}`].isOfExam ? "Yes" : "No"}
            </span>
          </p>
        ) : null} */}
        {/* <div style={{ margin: 13 }}>
          <StyledInputLabel htmlFor="exam">
            Question Languages:
          </StyledInputLabel>
          <StyledAutocomplete
            // CHECKBOX USED HERE EVEN THOUGH NOT MULTIPLE SELECTION
            multiple
            options={quesLangList}
            disableCloseOnSelect
            // disabled={loading}
            // name="compiExams"
            value={quesLangs}
            // onChange={handleInputChange}
            onChange={(event, value) => {
              // console.log(value)
              setQuesLangs(value);
            }}
            // onInputChange={handleInputChange}
            getOptionLabel={(option) => option}
            // renderOption={(option, { selected }) => (
            //   <React.Fragment>
            //     <StyledCheckbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       checked={selected}
            //     />
            //     {option}
            //   </React.Fragment>
            // )}
            style={{ width: 330 }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                // label="Exams:"
                // name="compiExams"
                // value={formValues.compiExams}
                // onChange={handleInputChange}
                // disabled={loading}
                // InputLabelProps={{ className: classes.labelColor1 }}
              />
            )}
          />
        </div> */}
      </div>
      <div className="header1">
        {/* <p className="title">
          Subject :{" "}
          <span className="spanId1">
            {data[`${returnQueryName()}`].subject}
          </span>
        </p> */}
        {/* <p className="title">
          Start Time : <span className="spanId1">{testStartTimeLocale}</span>
        </p>
        <p className="title">
          End Time : <span className="spanId1">{testEndTimeLocale}</span>
        </p> */}
      </div>
      {quesMapDisplay.map((dispQues, index) => {
        if (dispQues.display.length == 0) return null;
        return (
          <div key={index}>
            <div className="header2">{dispQues.header}</div>

            <StyledGrid container spacing={1}>
              {dispQues.display}
            </StyledGrid>
            {dispQues.display.length != 0 ? (
              <ReactPaginate
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={dispQues.pgCount}
                onPageChange={dispQues.onPgChange}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"paginationLink"}
                nextLinkClassName={"paginationLink"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            ) : null}
            <br />
          </div>
        );
      })}
      {/* React.createElement(MyjQueryReactComponent, { context: UIHelper }), document.getElementById('root')
  jQuery(document).mouseleave(function(){console.log('outside p')}) */}
      {/* <div className="button">
        <StyledButton
          variant="contained"
          color="primary"
          className={classes.buttonStyles}
        >
          Publish
        </StyledButton>
      </div> */}
    </div>
  );
};

export default PublishTestContent;
