import {
  TextField,
  Button,
  InputLabel,
  Select,
  FormHelperText,
  Typography,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";

export const SignInParentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "fit-content",
  justifyContent: "space-evenly",
  width: "100vw",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
export const SignInDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "10vw",
  top: "20%",
  margin: "5vh 3px 5vh 3px",
  [theme.breakpoints.down("md")]: {
    right: "0vw",
    position: "relative",
  },
}));
export const CarouselDiv = styled("div")(({ theme }) => ({
  margin: 20,
  width: "57vw",
  position: "absolute",
  left: 20,
  top: "20%",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
export const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  fontFamily:
    "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
export const InnerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
  height: "70%",
  boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.15)",
  borderRadius: "6px",
  padding: "20px",
  margin: 20,
  [theme.breakpoints.down("md")]: {
    // width: "80%",
    padding: 0,
    margin: 0,
  },
}));
export const ColumnFlexDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
export const InstructionsDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    alignItems: "center",
  },
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  width: "150px",
  marginTop: "7px",
  color: "black",
  backgroundColor: "#f5b921",
  boxShadow: "none",
  borderRadius: "0px",
  fontSize: "0.875rem",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#ffae3c",
    boxShadow: "none",
  },
  [theme.breakpoints.down("md")]: {
    width: "fit-content",
  },
}));
export const StyledSpan = styled("span")(({ theme }) => ({
  display: "inline-block",
  marginLeft: "10px",
  color: "#f5b921",
  "&:hover": {
    cursor: "pointer",
    textDecoration: "underline",
    color: "#1f5156",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px",
  },
}));
export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#1F5156",
  margin: "20px 0px 10px 0px",
  // "&.MuiInputLabel-shrink": {
  //   margin: "10px 0px 0px 0px",
  // },
  [theme.breakpoints.down("md")]: {
    margin: "5px 0px 5px 0px",
  },
}));
export const StyledPara = styled("p")(({ theme }) => ({
  color: "grey",
  fontSize: "14px",
  fontFamily: "inherit",
  display: "flex",
  width: "max-content",
  marginRight: "25px",
  alignItems: "center",
  display: "inline-block",
  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    color: "grey",
    fontSize: "14px",
    fontFamily: "inherit",
    alignItems: "center",
    display: "inline",
    marginRight: "0px",
  },
}));
export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "350px",
  margin: "20px 0px 0px 0px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    margin: "5px 0px 5px 0px",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  width: "350px",
  margin: "20px 0px 0px 0px",
  [theme.breakpoints.down("md")]: {
    width: "80vw",
    margin: "5px 0px 5px 0px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textAlign: "justify",
  display: "flex",
  alignItems: "center",
  marginTop: 20,
  fontSize: 18,
  [theme.breakpoints.down("md")]: {
    display: "block",
    fontSize: 15,
    textAlign: "left",
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "red",
  marginLeft: 20,
  width: "18vw",
  [theme.breakpoints.down("md")]: {
    width: "fit-content",
    // marginLeft: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({}));
