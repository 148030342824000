import React from "react";
import { Typography } from "@mui/material";

const instructionDetails = () => {
  return (
    <div style={{ textAlign: "justify", margin: 20 }}>
      <Typography
        variant="h5"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Instructions for using GYANSOPAN
      </Typography>{" "}
      <br />
      <Typography variant="h6">
        <b>1. Instructions for student registration</b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        Please follow these steps for student to enroll for BMTSC:
        <ul typeof="1">
          <li>Sign up yourself using your authorized Email id.</li>
          <li>
            During sign up you may requested to provide your First name, Middle name, Last
            name, Mobile number and Grade
          </li>
          <li>
            On successful submission of sign up form, you will receive an email
            from <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>{" "}
            including <b>Username</b> and <b>Password</b>.
          </li>
          <li>
            During first successful sign-in you will be requested to change the system generated password which you use for future login.<b>So please remember your password</b>  
          </li>
          <li>
            On successful email verification you will get the access to the
            Bhaskaracharya Pratishthana GyanSopan application.
          </li>
          <li>Sign in to your GyanSopan account.</li>
          <li>Click on Registration tab.</li>
          <li>
            You will find BMTSC 2023-2024 under examination list, mentioning the
            Institute name as Bhaskaracharya Pratishthana.
          </li>
          <li>Click on view button to proceed further.</li>
          <li>
            You will find detailed information of BMTSC 2023-2024, including the
            syllabus and registration fees.
          </li>
          <li>
            Click on REGISTER button which will require your ID proof such as
            School ID card, AADHAAR card, Recent school fee receipt.
          </li>
          <li>Make the payment to confirm your registration.</li>
          <li>Upon successful registration you will recieve the aknowledgement of payment and BMTSC exam registration details on your email.</li>

        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>2. Instructions for group registration through teacher </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      Please follow these steps for teachers to enroll for BMTSC:
        <ul typeof="1">
          <li>
            Sign up yourself using your authorized Email id.
          </li>
          <li>
            During sign up you may requested to provide your First name, Middle name and Last
            name.
          </li>
          <li>
            On successful submission of sign up form, you will receive an email
            from <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>{" "}
            including <b>Username</b> and <b>Password</b>.
          </li>
          <li>
            During first successful sign-in you will be requested to change the system generated password which you use for future login.<b>So please remember your password</b>  
          </li>
          <li>
            On successful email verification you will get the access to the
            Bhaskaracharya Pratishthana GyanSopan application.
          </li>
          <li>Sign in to your GyanSopan account.</li>
          <li>Click on Registration tab.</li>
          <li>
            You will find BMTSC 2023-2024 under examination list, mentioning the
            Institute name as Bhaskaracharya Pratishthana.
          </li>
          <li>Click on view button to proceed further.</li>
          <li>
            You will find detailed information of BMTSC 2023-2024, including the
            syllabus and registration fees.
          </li>
          <li>
            <b>
            Click on BULK/Group REGISTRATION button which will require you to download
            an empty excel template.
            </b>
          </li>
          <li>
            You are then requested to fill the excel template with the student's
            details such as First Name, Middle Name, Last Name, EmailId, Grade
            and Mobile No.
          </li>
          <li>
            On completing the excel template, please upload it on the GyanSopan
            account
          </li>
          <li>Make the payment to confirm your registration.</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>3. Instructions for group registration through Institute </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      Please follow these steps for institutes to enroll for BMTSC:
        <ul typeof="1">
          <li>
            Sign up yourself using your authorized Email id.
          </li>
          <li>
            During sign up you may requested to provide your Institute name and Area pinCode.
          </li>
          <li>
            On successful submission of sign up form, you will receive an email
            from <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>{" "}
            including <b>Username</b> and <b>Password</b>.
          </li>
          <li>
            During first successful sign-in you will be requested to change the system generated password which you use for future login.<b>So please remember your password</b>  
          </li>
          <li>
            On successful email verification you will get the access to the
            Bhaskaracharya Pratishthana GyanSopan application.
          </li>
          <li>Sign in to your GyanSopan account.</li>
          <li>Click on Registration tab.</li>
          <li>
            You will find BMTSC 2023-2024 under examination list, mentioning the
            Institute name as Bhaskaracharya Pratishthana.
          </li>
          <li>Click on view button to proceed further.</li>
          <li>
            You will find detailed information of BMTSC 2023-2024, including the
            syllabus and registration fees.
          </li>
          <li>
            <b>
            Click on BULK/Group REGISTRATION button which will require you to download
            an empty excel template.
            </b>
          </li>
          <li>
            You are then requested to fill the excel template with the student's
            details such as First Name, Middle Name, Last Name, EmailId, Grade
            and Mobile No.
          </li>
          <li>
            On completing the excel template, please upload it on the GyanSopan
            account
          </li>
          <li>Make the payment to confirm your registration.</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>Youtube links</b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      Please follow these youtube links for more details:
        <ul typeof="1">
          <li>
          Bhaskaracharya Pratishthana - BMTSC 2023-2024 Registration for 5th and 6th Grade Students - <a href="https://www.youtube.com/watch?v=F1r8AGnEX6E">https://www.youtube.com/watch?v=F1r8AGnEX6E</a>
          </li>
          <li>GyanSopan - Update Your Profile - <a href="https://www.youtube.com/watch?v=daeOz7xHPyw">https://www.youtube.com/watch?v=daeOz7xHPyw</a></li>
          <li>GyanSopan CameraAccess - BMTSC Exam Bhaskaracharya Pratishthana - <a href="https://www.youtube.com/watch?v=KE90KxOPcOQ
">https://www.youtube.com/watch?v=KE90KxOPcOQ</a></li>
<li>Demo Test for Bhaskaracharya Pratishthana on GyanSopan - <a href="https://www.youtube.com/watch?v=lM6Pu8fCbpI">https://www.youtube.com/watch?v=lM6Pu8fCbpI</a></li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <br /> <br />
      <Typography variant="h6">
        <b>
          Who should I contact for more clarification on the BMTSC 2023-2024 Registration Process?{" "}
        </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        You can contact the following people:
        <ul typeof="1">
          <li>Dr. V V Acharya - Chief Coordinator BMTSC 2023-2024 </li>
          <li>Mr. Kiran Barve - +91 7040205185</li>
          <li>Ms. Medha Ambika - +91 9822211840</li>
          <li>Ms. Tejashree Gaidhani - +91 9762938277 (Only for Technical Issues)</li>
          <li>Mr. Jitendra Sadangi - +91 9967976846 (Only for Technical Issues)</li>
          <li>Mr. Chandrakant Juluri- +91 9405937332 (Only for Technical Issues)</li>
          <li> GyanSopan Admin : 
            <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>
          </li>
        </ul>
      </Typography>{" "}
      <br /> <br />
    </div>
  );
};

export default instructionDetails;
