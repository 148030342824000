import React, { useState, useRef, useEffect } from "react";
import { Paper, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Visibility, Delete } from "@mui/icons-material";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTypography,
  StyledGrid,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  ButtonDiv,
  TableParentDiv,
  ListDiv,
  StyledCard,
  StyledAutocomplete,
  StyledAutocompleteTextField,
  RowToColumnFlexDiv,
  StyledInputLabel,
  ColumnFlexDiv,
  RowFlexDiv,
  FiftyDiv,
  StyledDialogButton,
  TextAreaDiv,
  StyledTextField,
} from "../../css/StyledComponents";

import { LoadingSpinner } from "../../constants/loadingSpinner";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";

const userGetAExamRegistrationDetailQuery = loader(
  "../../graphqlCalls/exam/userGetAExamRegistrationDetail.gql"
);
const userUpdateExamRegistrationDetailMutation = loader(
  "../../graphqlCalls/exam/UserUpdateExamRegistrationDetail.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ManageRegistration = (props) => {
  const fUser = props.fUser;

  const { state } = useLocation();

  // ISSUE: fetch exam again from backend and set it again
  // const exam = state?.exam;
  const studentId = state?.id;
  const isCreator = state?.isCreator;

  const [exam, setExam] = useState(state?.exam ? state.exam : {});
  const [studentProfile, setStudentProfile] = useState({});
  const [pendingAmount, setPendingAmount] = useState();
  const [status, setStatus] = useState("");

  const emptyPaymentUpload = { file64: "" }; //UPDATE IN INDIVIDUAL REGISTRATION, if any change here
  const [paymentUploads, setPaymentUploads] = useState([]);
  const [paymentUploadsDialog, setPaymentUploadsDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) navigate("/examList", { replace: true });
  }, [navigate, state]);

  const { loading, refetch } = useQuery(userGetAExamRegistrationDetailQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      studentId: studentId,
      examId: exam ? exam._id : null,
    },
    skip: !fUser || !state,
    onCompleted: ({ userGetAExamRegistrationDetail }) => {
      // console.log(userGetAExamRegistrationDetail);
      setExam(userGetAExamRegistrationDetail.exam);
      setPendingAmount(userGetAExamRegistrationDetail.pendingAmount);
      setPaymentUploads(
        userGetAExamRegistrationDetail?.studentExamDetails?.paymentUploads
          ? userGetAExamRegistrationDetail.studentExamDetails.paymentUploads
          : []
      );
      setStatus(
        userGetAExamRegistrationDetail?.studentExamDetails?.status
          ? userGetAExamRegistrationDetail.studentExamDetails.status
          : ""
      );
      setStudentProfile(userGetAExamRegistrationDetail.studentProfile);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });
  const [
    UserUpdateExamRegistrationDetailMutation,
    { loading: updating, error: errorUpdating, data: dataUpdating },
  ] = useMutation(userUpdateExamRegistrationDetailMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: ({ UserUpdateExamRegistrationDetail }) => {
      NotificationsSuccess("Updated!");
      setPaymentUploadsDialog(false);
    },
  });
  const updateExamDetail = async ({ updateType, status }) => {
    if (updating || loading) return;

    try {
      const callInfo = await UserUpdateExamRegistrationDetailMutation({
        variables: {
          input: {
            userId: fUser ? fUser._id : null,
            studentId: studentId,
            examId: exam ? exam._id : null,
            updateType: updateType,
            status: status,
            paymentUploads: paymentUploads,
          },
        },
      });
      if (
        callInfo?.data?.UserUpdateExamRegistrationDetail?.status &&
        updateType === "STATUS"
      ) {
        setStatus(callInfo?.data?.UserUpdateExamRegistrationDetail?.status);
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const onUploadPaymentFileChange = (target, index) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        let tempPaymentUploads = [...paymentUploads];
        let tempPaymentsUploadsObject = { ...tempPaymentUploads[index] };
        tempPaymentsUploadsObject.file64 = result;
        tempPaymentUploads[index] = tempPaymentsUploadsObject;
        setPaymentUploads(tempPaymentUploads);
      }
    });
  };

  const renderPaymentUploadsDialog = () => {
    return (
      <StyledDialog
        maxWidth="xl"
        open={paymentUploadsDialog}
        onClose={() => {
          setPaymentUploadsDialog(false);
        }}
      >
        <StyledDialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          {/* {LoadingSpinner(updatingUserPostPayment || updatingPostPayment)} */}
          <StyledTypography
            variant="h5"
            style={{ fontWeight: "bold", margin: 20 }}
          >
            Your Receipts.
            <br />
          </StyledTypography>
          <label
            for="file-upload"
            style={
              {
                // margin: "0 0 2.5vh 0",
              }
            }
          >
            {`Upload Payment Receipt PDF or Image (size limit: 2mb/file)`}
          </label>
          <br />
          {paymentUploads.map((slip, index) => {
            return (
              <RowFlexDiv
                key={index}
                style={{
                  width: "85%",
                  borderStyle: "solid",
                  borderColor: "grey",
                  borderWidth: "medium",
                  borderRadius: 10,
                  margin: 5,
                  padding: 15,
                }}
              >
                <ColumnFlexDiv
                  style={{ width: "90%", alignItems: "flex-start" }}
                >
                  <input
                    type="file"
                    id="file-upload"
                    // disabled={
                    //   fetchingPayment ||
                    //   fetchingUserPayment ||
                    //   updatingUserPostPayment ||
                    //   updatingPostPayment
                    // }
                    accept="application/pdf,image/*"
                    name="filetobase64"
                    onChange={({ target }) => {
                      onUploadPaymentFileChange(target, index);
                    }}
                  />
                  {slip.file64 && slip.file64 != "" ? (
                    <a href={slip.file64} download="file">
                      Download Document
                    </a>
                  ) : null}
                </ColumnFlexDiv>
                {index > 0 ? (
                  <StyledDialogButton
                    // disabled={
                    //   fetchingPayment ||
                    //   fetchingUserPayment ||
                    //   updatingUserPostPayment ||
                    //   updatingPostPayment
                    // }
                    sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
                    style={{ width: "10%", margin: 0 }}
                    onClick={() => {
                      let tempPaymentUploads = [...paymentUploads];
                      tempPaymentUploads = tempPaymentUploads.filter(
                        (pu, puId) => puId != index
                      );
                      setPaymentUploads(tempPaymentUploads);
                    }}
                    size="large"
                    startIcon={<Delete />}
                  ></StyledDialogButton>
                ) : null}
              </RowFlexDiv>
            );
          })}
          {paymentUploads.length < 5 ? (
            <StyledButton
              variant="contained"
              // disabled={
              //   // loading ||
              //   fetchingPayment ||
              //   fetchingUserPayment ||
              //   updatingUserPostPayment ||
              //   updatingPostPayment
              // }
              size="medium"
              onClick={() => {
                let tempPaymentUploads = [...paymentUploads];
                tempPaymentUploads.push({ ...emptyPaymentUpload });
                setPaymentUploads(tempPaymentUploads);
              }}
            >
              Add
            </StyledButton>
          ) : (
            <StyledTypography>Only 5 files permitted</StyledTypography>
          )}
          <br />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="contained"
            // disabled={
            //   // loading ||
            //   fetchingPayment ||
            //   fetchingUserPayment ||
            //   updatingUserPostPayment ||
            //   updatingPostPayment
            // }
            size="medium"
            onClick={() => {
              let indexs = [];
              paymentUploads.map((puObj, puIdx) => {
                if (puObj.file64 && puObj.file64 != "") {
                  return;
                } else {
                  indexs.push(puIdx + 1);
                }
              });
              if (indexs.length == 0) {
                updateExamDetail({ updateType: "PAYMENTUPLOADS" });
              } else {
                alert(
                  `File Number ${indexs.join(" , ")} ${
                    indexs.length == 1 ? "is" : "are"
                  } Invalid or Empty`
                );
              }
            }}
          >
            Submit
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  };
  if (!state) {
    return null;
  }
  return (
    <div>
      {NotificationsContainer()}
      {renderPaymentUploadsDialog()}
      <br />
      <div>
        <StyledTypography>{exam.name}</StyledTypography>
        <StyledTypography>
          Registration Fees: Rs.{exam.fees?.individual}/- Individual
          {fUser.role === "Teacher" || fUser.role === "Institute"
            ? ` , Rs.${exam.fees?.bulk}/- Bulk Per Student`
            : ``}
        </StyledTypography>
        <StyledTypography>
          Registrations open on {getFormattedDate(Date.parse(exam.regStart))}{" "}
          and will close on {getFormattedDate(Date.parse(exam.regEnd))}
        </StyledTypography>
        <StyledTypography>
          Grades :
          {exam.grade.map((item, index) => {
            return (
              <span key={index}>
                {item}
                {index != exam.grade.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </StyledTypography>
        <StyledTypography>
          Payment Mode: {exam.paymentMode?.join(" , ")}
        </StyledTypography>
        <StyledTypography>Payment Type: {exam.paymentType}</StyledTypography>
        {exam.paymentType === "PARTIAL / FULL" ? (
          <StyledTypography>
            Minimum Registration Fee: Rs.{exam.minimumFee}/- Individual
          </StyledTypography>
        ) : null}
        {exam.concession ? (
          <StyledTypography>
            Concession Type:{" "}
            {exam.concessionType?.map((item, index) => {
              return (
                <span key={index}>
                  {item}
                  {index != exam.concessionType.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </StyledTypography>
        ) : null}
        <StyledTypography>Rounds: {exam.rounds}</StyledTypography>
        <br />
        <div>
          <StyledTextField
            name="firstName"
            value={
              studentProfile?.alias?.first ? studentProfile.alias.first : ""
            }
            disabled
            variant="filled"
            label="First Name:"
          />
          <StyledTextField
            name="middleName"
            value={
              studentProfile?.alias?.middle ? studentProfile.alias.middle : ""
            }
            disabled
            variant="filled"
            label="Middle Name:"
          />
          <StyledTextField
            name="lastName"
            value={studentProfile?.alias?.last ? studentProfile.alias.last : ""}
            disabled
            variant="filled"
            label="Last Name:"
          />
        </div>
        <br />
        <StyledTypography>
          Pending Amount: Rs.{pendingAmount}/-
        </StyledTypography>
        {pendingAmount ? (
          <StyledTypography style={{ color: "red" }}>PAY NOW</StyledTypography>
        ) : null}
        {paymentUploads.map((slip, index) => {
          return (
            <div key={index} style={{ margin: 5, padding: 5 }}>
              {slip.file64 && slip.file64 != "" ? (
                <a href={slip.file64} download="file">
                  Download Document {index + 1}
                </a>
              ) : null}
            </div>
          );
        })}
        <StyledButton onClick={() => setPaymentUploadsDialog(true)}>
          Edit Receipts
        </StyledButton>
        {isCreator ? (
          <ColumnFlexDiv>
            <StyledTypography>STATUS: {status}</StyledTypography>
            <RowFlexDiv>
              <StyledButton
                style={{ backgroundColor: "green" }}
                onClick={() => {
                  updateExamDetail({
                    updateType: "STATUS",
                    status: "APPROVED",
                  });
                }}
              >
                Approve
              </StyledButton>
              <StyledButton
                style={{ backgroundColor: "red" }}
                onClick={() => {
                  updateExamDetail({
                    updateType: "STATUS",
                    status: "REJECTED",
                  });
                }}
              >
                Reject
              </StyledButton>
            </RowFlexDiv>
          </ColumnFlexDiv>
        ) : null}
      </div>
    </div>
  );
};

export default ManageRegistration;
