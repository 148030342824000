import React, { useState } from "react";
import { FormControl, createFilterOptions } from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import {
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, Delete } from "@mui/icons-material";

import { pincodes } from "../../constants/pincodes";
import { schoolList } from "../../constants/schoolList";
import { schoolDocumentList } from "../../constants/schoolDocumentList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";
import UserRegistrationValidation from "../validations/UserRegistrationValidation";
import StudentRegistrationValidation from "../validations/StudentRegistrationValidation";
import { LoadingSpinner } from "../../constants/loadingSpinner";
import {
  StyledTypography,
  StyledTextField,
  StyledInputLabel,
  StyledSelect,
  StyledMenuItem,
  StyledAutocomplete,
  StyledFormHelperText,
  StyledCheckbox,
  StyledDialog,
  StyledDialogContent,
  StyledButton,
  ColumnFlexDiv,
  SchoolDiv,
  SelectDiv,
  TextAreaDiv,
  StyledDialogActions,
  RowFlexDiv,
  StyledDialogButton,
} from "../../css/StyledComponents";

const userUpdatePostExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserUpdatePostExamPayment.gql"
);
const userDoExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserDoExamPayment.gql"
);
const doExamPaymentMutation = loader(
  "../../graphqlCalls/payment/DoExamPayment.gql"
);
const updatePostExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UpdatePostExamPayment.gql"
);
const getExamSubscriptionStatusQuery = loader(
  "../../graphqlCalls/payment/getExamSubscriptionStatus.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const initialFormValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  grade: "",
  email: "",
  mobileNo: "",
  docType: "",
  docFile64: "",
  admissionRec64: "",
  schoolName: "",
  sPinCode: 0,
};

const IndividualRegistration = (props) => {
  const fUser = props.fUser;
  const {
    state: { exam },
  } = useLocation();

  const navigate = useNavigate();

  const [GSTNo, setGSTNo] = useState("");
  const [pincode, setPincode] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState(
    exam.minimumFee ? exam.minimumFee : 0
  );
  const emptyPaymentUpload = { file64: "" }; //UPDATE IN MANAGE REGISTRATION, if any change here
  const [paymentUploads, setPaymentUploads] = useState([
    { ...emptyPaymentUpload },
  ]);
  const [paymentUploadsDialog, setPaymentUploadsDialog] = useState(false);

  const [state, setState] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [billDate, setBillDate] = useState(0);
  const [studentExists, setStudentExists] = useState(true);
  const [formValues, setValues] = useState(initialFormValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  // const documentList = [
  //   "School Identity Card",
  //   "AADHAAR Card and School Admission Fee Receipt",
  // ];

  const radioIcon = <RadioButtonUncheckedOutlined fontSize="small" />;
  const radioCheckedIcon = <RadioButtonCheckedOutlined fontSize="small" />;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 75,
  });

  //   const headerFlag = 6;
  // const { data, error, loading, refetch, networkStatus } = useQuery(
  //   getExamSubscriptionStatusQuery,
  //   {
  // fetchPolicy: "network-only",
  //       notifyOnNetworkStatusChange: true,
  //     variables: {
  //       sId: fUser ? fUser._id : null,
  //     },
  //     skip: !fUser || fUser.role != "Student",
  //     onError: (err) => {
  //       alert("Something went wrong");
  //       // console.log(JSON.stringify(err, null, 2));
  //     },
  //     onCompleted: ({ getExamSubscriptionStatus }) => {
  //       // console.log("getSubscriptionStatus", getInstituteSubscriptionStatus);
  //       if (
  //         getExamSubscriptionStatus &&
  //         getExamSubscriptionStatus.status == true
  //       ) {
  //         setPaymentDone(true);
  //         setState(getExamSubscriptionStatus.state);
  //         setPincode(getExamSubscriptionStatus.pincode);
  //         setGSTNo(getExamSubscriptionStatus.GSTNo);
  //         setPaymentId(getExamSubscriptionStatus.paymentId);
  //         setBillDate(Date.parse(getExamSubscriptionStatus.billDate));
  //       } else setPaymentDone(false);
  //     },
  //   }
  // );
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadDocFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          docFile64: result,
        });
      }
    });
  };
  const onUploadPaymentFileChange = (target, index) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        let tempPaymentUploads = [...paymentUploads];
        let tempPaymentsUploadsObject = { ...tempPaymentUploads[index] };
        tempPaymentsUploadsObject.file64 = result;
        tempPaymentUploads[index] = tempPaymentsUploadsObject;
        setPaymentUploads(tempPaymentUploads);
      }
    });
  };

  const [
    UserDoExamPaymentMutation,
    {
      loading: fetchingUserPayment,
      error: errorUserPaying,
      data: dataUserPaying,
    },
  ] = useMutation(userDoExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
  });
  const [
    UserUpdatePostExamPaymentMutation,
    {
      loading: updatingUserPostPayment,
      error: errorUserPostUpdate,
      data: dataUserPostUpdate,
    },
  ] = useMutation(userUpdatePostExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Registered!");
    },
  });
  const [
    DoExamPaymentMutation,
    { loading: fetchingPayment, error: errorPaying, data: dataPaying },
  ] = useMutation(doExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
  });
  const [
    UpdatePostExamPaymentMutation,
    {
      loading: updatingPostPayment,
      error: errorPostUpdate,
      data: dataPostUpdate,
    },
  ] = useMutation(updatePostExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      navigate("/examInfo", {
        state: {
          exam: exam,
          paid: true,
          isCreator: false,
        },
      });
    },
  });
  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const getStartDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  const getState = (pincodeIp) => {
    const found = pincodes.find((pc) => pc.Pincode == pincodeIp);
    if (found) {
      return found.StateName;
    }
    return "";
  };
  async function DisplayRazorpay() {
    let callInfo;
    if (fetchingPayment || fetchingUserPayment) return;

    if (state == "") {
      alert("Enter valid pincode");
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }

    try {
      // callInfo = await DoInstitutePaymentMutation({
      //   variables: {
      //     input: {
      //       instituteId: institute._id,
      //     },
      //   },
      //   onError: (err) => {
      //     alert("Something went wrong");
      //     // console.log(JSON.stringify(err, null, 2));
      //   },
      // });

      if (fUser.role === "Teacher" || fUser.role === "Institute") {
        callInfo = await UserDoExamPaymentMutation({
          variables: {
            input: {
              email: formValues.email,
              examId: exam._id,
              paymentAmount: parseInt(paymentAmount),
            },
          },
        });
      } else if (fUser.role === "Student") {
        callInfo = await DoExamPaymentMutation({
          variables: {
            input: {
              examId: exam._id,
              paymentAmount: parseInt(paymentAmount),
            },
          },
        });
      }
    } catch (error) {
      alert("Something went wrong");
    }
    let dp;
    if (fUser.role === "Teacher" || fUser.role === "Institute") {
      dp = callInfo.data.UserDoExamPayment;
      setStudentExists(dp.userExists);
      if (dp.userExists) {
        alert("Student Already Exists!");
        return;
      }
    } else if (fUser.role === "Student") {
      dp = callInfo.data.DoExamPayment;
    }

    const options = {
      key: "rzp_test_UL2w8U1WKAUgx8",
      currency: dp.currency,
      amount:
        exam.paymentType === "PARTIAL / FULL"
          ? paymentAmount.toString()
          : dp.amount?.toString(),
      order_id: dp.id,
      name: "Registration Fees",
      description: `${exam.name} Fee (email id cannot be changed)`,
      handler: async function (response) {
        try {
          let callInfo;
          if (fUser.role === "Teacher" || fUser.role === "Institute") {
            callInfo = await UserUpdatePostExamPaymentMutation({
              variables: {
                input: {
                  userId: fUser._id,
                  userType: fUser.role,
                  examId: exam._id,
                  firstName: formValues.firstName,
                  middleName: formValues.middleName,
                  lastName: formValues.lastName,
                  email: formValues.email,
                  mobileNo: formValues.mobileNo,
                  grade: formValues.grade,
                  academic: {
                    docType: formValues.docType,
                    docFile64: formValues.docFile64,
                    admissionRec64: formValues.admissionRec64,
                    schoolName: formValues.schoolName,
                    schoolPincode: parseInt(formValues.sPinCode),
                  },
                  state: state,
                  pincode: parseInt(pincode),
                  GSTNo: GSTNo,
                  paymentId: response.razorpay_payment_id,
                  orderId: response.razorpay_order_id,
                  paymentAmount: parseInt(paymentAmount),
                },
              },
            });
          } else if (fUser.role === "Student") {
            callInfo = await UpdatePostExamPaymentMutation({
              variables: {
                input: {
                  studentId: fUser._id,
                  examId: exam._id,
                  state: state,
                  pincode: parseInt(pincode),
                  GSTNo: GSTNo,
                  paymentId: response.razorpay_payment_id,
                  orderId: response.razorpay_order_id,
                  paymentAmount: parseInt(paymentAmount),
                },
              },
            });
          }
        } catch (error) {
          alert("Something went wrong");
        }
      },
      prefill: {
        email: fUser.mail,
      },
      readonly: { email: true },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  let validationSchema;
  if (fUser.role === "Student")
    validationSchema = StudentRegistrationValidation;
  else validationSchema = UserRegistrationValidation;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const DoOfflinePayment = async () => {
    try {
      let callInfo;
      if (fUser.role === "Teacher" || fUser.role === "Institute") {
        callInfo = await UserUpdatePostExamPaymentMutation({
          variables: {
            input: {
              userId: fUser._id,
              userType: fUser.role,
              examId: exam._id,
              firstName: formValues.firstName,
              middleName: formValues.middleName,
              lastName: formValues.lastName,
              email: formValues.email,
              mobileNo: formValues.mobileNo,
              grade: formValues.grade,
              academic: {
                docType: formValues.docType,
                docFile64: formValues.docFile64,
                admissionRec64: formValues.admissionRec64,
                schoolName: formValues.schoolName,
                schoolPincode: parseInt(formValues.sPinCode),
              },
              state: state,
              pincode: parseInt(pincode),
              GSTNo: GSTNo,
              paymentId: "",
              orderId: "",
              paymentAmount: parseInt(paymentAmount),
              paymentUploads: paymentUploads,
            },
          },
        });
      } else if (fUser.role === "Student") {
        callInfo = await UpdatePostExamPaymentMutation({
          variables: {
            input: {
              studentId: fUser._id,
              examId: exam._id,
              state: state,
              pincode: parseInt(pincode),
              GSTNo: GSTNo,
              paymentId: "",
              orderId: "",
              paymentAmount: parseInt(paymentAmount),
              paymentUploads: paymentUploads,
            },
          },
        });
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const renderPaymentUploadsDialog = () => {
    return (
      <StyledDialog
        maxWidth="xl"
        open={paymentUploadsDialog}
        onClose={() => {
          setPaymentUploadsDialog(false);
        }}
      >
        <StyledDialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          {LoadingSpinner(updatingUserPostPayment || updatingPostPayment)}
          <StyledTypography
            variant="h5"
            style={{ fontWeight: "bold", margin: 20 }}
          >
            Your Receipts.
            <br />
          </StyledTypography>
          <label
            for="file-upload"
            style={
              {
                // margin: "0 0 2.5vh 0",
              }
            }
          >
            {`Upload Payment Receipt PDF or Image (size limit: 2mb/file)`}
          </label>
          <br />
          {paymentUploads.map((slip, index) => {
            return (
              <RowFlexDiv
                key={index}
                style={{
                  width: "85%",
                  borderStyle: "solid",
                  borderColor: "grey",
                  borderWidth: "medium",
                  borderRadius: 10,
                  margin: 5,
                  padding: 15,
                }}
              >
                <ColumnFlexDiv
                  style={{ width: "90%", alignItems: "flex-start" }}
                >
                  <input
                    type="file"
                    id="file-upload"
                    disabled={
                      fetchingPayment ||
                      fetchingUserPayment ||
                      updatingUserPostPayment ||
                      updatingPostPayment
                    }
                    accept="application/pdf,image/*"
                    name="filetobase64"
                    onChange={({ target }) => {
                      onUploadPaymentFileChange(target, index);
                    }}
                  />
                  {slip.file64 && slip.file64 != "" ? (
                    <a href={slip.file64} download="file">
                      Download Document
                    </a>
                  ) : null}
                </ColumnFlexDiv>
                {index > 0 ? (
                  <StyledDialogButton
                    disabled={
                      fetchingPayment ||
                      fetchingUserPayment ||
                      updatingUserPostPayment ||
                      updatingPostPayment
                    }
                    sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
                    style={{ width: "10%" }}
                    onClick={() => {
                      let tempPaymentUploads = [...paymentUploads];
                      tempPaymentUploads = tempPaymentUploads.filter(
                        (pu, puId) => puId != index
                      );
                      setPaymentUploads(tempPaymentUploads);
                    }}
                    size="large"
                    startIcon={<Delete />}
                  ></StyledDialogButton>
                ) : null}
              </RowFlexDiv>
            );
          })}
          {paymentUploads.length < 5 ? (
            <StyledButton
              variant="contained"
              disabled={
                // loading ||
                fetchingPayment ||
                fetchingUserPayment ||
                updatingUserPostPayment ||
                updatingPostPayment
              }
              size="medium"
              onClick={() => {
                let tempPaymentUploads = [...paymentUploads];
                tempPaymentUploads.push({ ...emptyPaymentUpload });
                setPaymentUploads(tempPaymentUploads);
              }}
            >
              Add
            </StyledButton>
          ) : (
            <StyledTypography>Only 5 files permitted</StyledTypography>
          )}
          <br />
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="contained"
            disabled={
              // loading ||
              fetchingPayment ||
              fetchingUserPayment ||
              updatingUserPostPayment ||
              updatingPostPayment
            }
            size="medium"
            onClick={() => {
              let indexs = [];
              paymentUploads.map((puObj, puIdx) => {
                if (puObj.file64 && puObj.file64 != "") {
                  return;
                } else {
                  indexs.push(puIdx + 1);
                }
              });
              if (indexs.length == 0) DoOfflinePayment();
              else {
                alert(
                  `File Number ${indexs.join(" , ")} ${
                    indexs.length == 1 ? "is" : "are"
                  } Invalid or Empty`
                );
              }
            }}
          >
            Register
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    );
  };
  const renderExamInfo = () => {
    return (
      <div>
        <StyledTypography variant="h5">
          Registration Open For {exam.name}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registration Fees: Rs.{exam.fees?.individual}/- Individual
          {fUser.role === "Teacher" || fUser.role === "Institute"
            ? ` , Rs.${exam.fees?.bulk}/- Bulk Per Student`
            : ``}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registrations open on {getFormattedDate(Date.parse(exam.regStart))}{" "}
          and will close on {getFormattedDate(Date.parse(exam.regEnd))}
        </StyledTypography>
        <StyledTypography>
          Payment Mode:{" "}
          {exam.paymentMode?.map((item, index) => {
            return (
              <span key={index}>
                {item}
                {index != exam.paymentMode.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </StyledTypography>
        <StyledTypography>Payment Type: {exam.paymentType}</StyledTypography>
        {exam.paymentType === "PARTIAL / FULL" ? (
          <StyledTypography>
            Minimum Registration Fee: Rs.{exam.minimumFee}/- Individual
          </StyledTypography>
        ) : null}
        {exam.concession ? (
          <StyledTypography>
            Concession Type:{" "}
            {exam.concessionType?.map((item, index) => {
              return (
                <span key={index}>
                  {item}
                  {index != exam.concessionType.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </StyledTypography>
        ) : null}
        <StyledTypography>
          *Please enter a valid Indian Pincode to enable Exam Registration
        </StyledTypography>

        <br />
        {fUser.role === "Teacher" || fUser.role === "Institute" ? (
          <div>
            <StyledTypography
              variant="h5"
              style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
            >
              Student's Details
            </StyledTypography>
            <TextAreaDiv>
              <FormControl
                {...register("email")}
                error={errors.email ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      disabled={
                        // loading ||
                        fetchingPayment ||
                        fetchingUserPayment ||
                        updatingUserPostPayment ||
                        updatingPostPayment
                      }
                      variant="filled"
                      label="Email"
                      required
                    />
                  )}
                  control={control}
                  name="email"
                />
                <StyledFormHelperText>
                  {errors.email?.message}
                </StyledFormHelperText>
              </FormControl>
              <FormControl
                {...register("mobileNo")}
                error={errors.mobileNo ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="mobileNo"
                      value={formValues.mobileNo}
                      onChange={handleInputChange}
                      disabled={
                        // loading ||
                        fetchingPayment ||
                        fetchingUserPayment ||
                        updatingUserPostPayment ||
                        updatingPostPayment
                      }
                      variant="filled"
                      label="Mobile No"
                      required
                    />
                  )}
                  control={control}
                  name="mobileNo"
                />
                <StyledFormHelperText>
                  {errors.mobileNo?.message}
                </StyledFormHelperText>
              </FormControl>
              <SelectDiv>
                <FormControl
                  required
                  {...register("grade")}
                  error={errors.grade ? true : false}
                >
                  <StyledInputLabel shrink>Grade</StyledInputLabel>
                  <Controller
                    render={({
                      field: { onChange, value, name, ...field },
                    }) => (
                      <StyledSelect
                        {...field}
                        name="grade"
                        value={formValues.grade}
                        onChange={handleInputChange}
                        disabled={
                          // loading ||
                          fetchingPayment ||
                          fetchingUserPayment ||
                          updatingUserPostPayment ||
                          updatingPostPayment
                        }
                      >
                        {exam.grade.map((grade, index) => {
                          return (
                            <StyledMenuItem key={index} value={grade}>
                              {grade}
                            </StyledMenuItem>
                          );
                        })}
                      </StyledSelect>
                    )}
                    control={control}
                    name="grade"
                  />
                  <StyledFormHelperText>
                    {errors.grade?.message}
                  </StyledFormHelperText>
                </FormControl>
              </SelectDiv>
            </TextAreaDiv>
            <TextAreaDiv>
              <FormControl
                {...register("firstName")}
                error={errors.firstName ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="firstName"
                      value={formValues.firstName}
                      onChange={handleInputChange}
                      disabled={
                        // loading ||
                        fetchingPayment ||
                        fetchingUserPayment ||
                        updatingUserPostPayment ||
                        updatingPostPayment
                      }
                      variant="filled"
                      label="First Name"
                      required
                    />
                  )}
                  control={control}
                  name="firstName"
                />
                <StyledFormHelperText>
                  {errors.firstName?.message}
                </StyledFormHelperText>
              </FormControl>
              <FormControl>
                <StyledTextField
                  name="middleName"
                  value={formValues.middleName}
                  onChange={handleInputChange}
                  disabled={
                    // loading ||
                    fetchingPayment ||
                    fetchingUserPayment ||
                    updatingUserPostPayment ||
                    updatingPostPayment
                  }
                  variant="filled"
                  label="Middle Name"
                />
              </FormControl>
              <FormControl
                {...register("lastName")}
                error={errors.lastName ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="lastName"
                      value={formValues.lastName}
                      onChange={handleInputChange}
                      disabled={
                        // loading ||
                        fetchingPayment ||
                        fetchingUserPayment ||
                        updatingUserPostPayment ||
                        updatingPostPayment
                      }
                      variant="filled"
                      label="Last Name"
                      required
                    />
                  )}
                  control={control}
                  name="lastName"
                />
                <StyledFormHelperText>
                  {errors.lastName?.message}
                </StyledFormHelperText>
              </FormControl>
            </TextAreaDiv>
            <TextAreaDiv>
              <SelectDiv>
                <SchoolDiv>
                  <StyledInputLabel
                    // shrink
                    // style={{ padding: "1vh 0 0 0", fontSize: "22px" }}
                    style={{ margin: 0 }}
                  >
                    Institute / School Name:
                  </StyledInputLabel>
                  <StyledAutocomplete
                    style={{ marginLeft: 0 }}
                    filterOptions={filterOptions}
                    options={schoolList}
                    disabled={
                      fetchingPayment ||
                      fetchingUserPayment ||
                      updatingUserPostPayment ||
                      updatingPostPayment
                    }
                    value={formValues.schoolName}
                    freeSolo
                    autoSelect
                    getOptionLabel={(option) => option}
                    onChange={(event, value) => {
                      setValues({ ...formValues, schoolName: value });
                    }}
                    // renderOption={(option, { selected }) => (
                    //   <React.Fragment>
                    //     <StyledCheckbox
                    //       icon={radioIcon}
                    //       checkedIcon={radioCheckedIcon}
                    //       checked={selected}
                    //     />
                    //     {option}
                    //   </React.Fragment>
                    // )}
                    renderInput={(params) => (
                      <StyledTextField {...params} style={{ marginLeft: 0 }} />
                    )}
                  />
                  <StyledFormHelperText style={{ marginLeft: 0 }}>
                    Type student's school name and submit even if not in list.
                  </StyledFormHelperText>
                </SchoolDiv>
              </SelectDiv>
              <StyledTextField
                name="sPinCode"
                variant="filled"
                type="number"
                disabled={
                  fetchingPayment ||
                  fetchingUserPayment ||
                  updatingUserPostPayment ||
                  updatingPostPayment
                }
                value={formValues.sPinCode}
                style={{
                  justifyContent: "center",
                  marginTop: 25,
                  marginLeft: 20,
                }}
                onChange={handleInputChange}
                label="Institute/School Pincode:"
              />
            </TextAreaDiv>
            <TextAreaDiv>
              <SelectDiv style={{ display: "flex", flexDirection: "column" }}>
                <StyledInputLabel shrink>Document Type:</StyledInputLabel>
                <StyledSelect
                  name="docType"
                  value={formValues.docType}
                  onChange={handleInputChange}
                  disabled={
                    fetchingPayment ||
                    fetchingUserPayment ||
                    updatingUserPostPayment ||
                    updatingPostPayment
                  }
                  style={{ marginLeft: 0 }}
                >
                  {schoolDocumentList.map((document, index) => {
                    return (
                      <StyledMenuItem key={index} value={document}>
                        {document}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              </SelectDiv>
              {formValues.docType ? (
                <ColumnFlexDiv
                  style={{ justifyContent: "center", marginLeft: 20 }}
                >
                  <label
                    for="file-upload"
                    style={
                      {
                        // margin: "0 0 2.5vh 0",
                      }
                    }
                  >
                    {/* {formValues.docType === "School Identity Card"
                      ? "Upload School Identity Card PDF"
                      : formValues.docType ===
                        "AADHAAR Card and School Admission Fee Receipt"
                      ? "Upload AADHAAR Card PDF"
                      : "Upload Document PDF"} */}
                    {`Upload ${formValues.docType} PDF or Image (size limit: 2mb)`}
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    disabled={
                      fetchingPayment ||
                      fetchingUserPayment ||
                      updatingUserPostPayment ||
                      updatingPostPayment
                    }
                    accept="application/pdf,image/*"
                    name="filetobase64"
                    onChange={onUploadDocFileChange}
                  />
                  {formValues.docFile64 && formValues.docFile64 != "" ? (
                    <a href={formValues.docFile64} download="file">
                      Download Document
                    </a>
                  ) : null}
                  {/* {formValues.docType ===
                  "AADHAAR Card and School Admission Fee Receipt" ? (
                    <div>
                      <label
                        for="file-upload"
                        style={
                          {
                            // margin: "0 0 2.5vh 0",
                          }
                        }
                      >
                        Upload School Admission Fee Receipt PDF (size limit: 2mb)
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        disabled={
                          fetchingPayment ||
                          fetchingUserPayment ||
                          updatingUserPostPayment ||
                          updatingPostPayment
                        }
                        accept="application/pdf,image/*"
                        // hidden
                        name="filetobase64"
                        onChange={onUploadReceiptFileChange}
                      />
                      {formValues.admissionRec64 &&
                      formValues.admissionRec64 != "" ? (
                        <a href={formValues.admissionRec64} download="file">
                          Download Document
                        </a>
                      ) : null}
                    </div>
                  ) : null} */}
                </ColumnFlexDiv>
              ) : null}
            </TextAreaDiv>
          </div>
        ) : null}
        <br />
        <br />
        <StyledTypography variant="h6">
          Please enter
          <span style={{ fontWeight: "bold", marginLeft: 10 }}>
            {" "}
            PINCODE* :
          </span>
          <FormControl
            {...register("pincode")}
            error={errors.pincode ? true : false}
          >
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledTextField
                  {...field}
                  name="pincode"
                  value={pincode}
                  type="number"
                  onChange={(e) => {
                    setPincode(e.target.value);
                    let stateIs = getState(e.target.value);
                    setState(stateIs);
                  }}
                  required
                />
              )}
              control={control}
              name="pincode"
            />
            <StyledFormHelperText>
              {errors.pincode?.message}
            </StyledFormHelperText>
          </FormControl>
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> STATE:</span>
          <StyledTextField name="state" value={state} disabled />
        </StyledTypography>
        <br />
        <StyledTypography variant="h6">
          For value added tax invoice, please provide
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> GSTIN:</span>
          <StyledTextField
            name="GSTNo"
            value={GSTNo}
            label="(optional)"
            onChange={(e) => {
              setGSTNo(e.target.value);
            }}
          />
        </StyledTypography>
        {exam.paymentType === "PARTIAL / FULL" ? (
          <StyledTypography variant="h6">
            Please enter the amount you would like to
            <span style={{ fontWeight: "bold", marginLeft: 10 }}> Pay:</span>
            <StyledTextField
              name="paymentAmount"
              value={paymentAmount}
              type="number"
              onChange={(e) => {
                setPaymentAmount(e.target.value);
              }}
            />
          </StyledTypography>
        ) : null}
        {exam.paymentMode.includes("OFFLINE") ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <StyledTypography variant="h6">
              Upload Receipt for Offline Payment
            </StyledTypography>
            <StyledButton
              variant="contained"
              disabled={
                // loading ||
                fetchingPayment ||
                fetchingUserPayment ||
                updatingUserPostPayment ||
                updatingPostPayment
              }
              size="medium"
              onClick={handleSubmit(() => setPaymentUploadsDialog(true))}
            >
              Receipts
            </StyledButton>
          </div>
        ) : null}

        <br />
        <StyledButton
          variant="contained"
          disabled={
            // loading ||
            fetchingPayment ||
            fetchingUserPayment ||
            updatingUserPostPayment ||
            updatingPostPayment
          }
          size="medium"
          onClick={handleSubmit(DisplayRazorpay)}
        >
          Register
        </StyledButton>
        <StyledTypography>*Payment is non-refundable</StyledTypography>
        <br />
        <br />
      </div>
    );
  };

  if (!exam) {
    navigate("/examList");
    return null;
  }

  return (
    <div>
      {NotificationsContainer()}
      {renderPaymentUploadsDialog()}
      <StyledDialog
        maxWidth="lg"
        open={updatingUserPostPayment || updatingPostPayment}
        onClose={() => {
          return;
        }}
      >
        <StyledDialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          {LoadingSpinner(updatingUserPostPayment || updatingPostPayment)}
          <StyledTypography
            variant="h5"
            style={{ fontWeight: "bold", margin: 20 }}
          >
            Please wait while we process your Payment.
            <br />
            <StyledTypography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
              }}
            >
              Do not refresh the page or go back.
            </StyledTypography>
          </StyledTypography>
          <br />
        </StyledDialogContent>
      </StyledDialog>
      {fUser.role === "Teacher" || fUser.role === "Institute"
        ? renderExamInfo()
        : fUser.role === "Student"
        ? renderExamInfo()
        : null}
    </div>
  );
};

export default IndividualRegistration;
