import React from "react";
import { Typography } from "@mui/material";
import Footer from "../Footer";
import Header from "../FrontHeader";

export default function PrivacyPolicy() {
  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      <div style={{ textAlign: "justify", margin: 40 }}>
        <Typography
          variant="h5"
          style={{ textAlign: "center", fontWeight: "bold" }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="h6"
          style={{ textAlign: "right", fontWeight: "bold" }}
        >
          6th September , 2022
        </Typography>{" "}
        <br />
        <br />
        <Typography>
          We are dedicated to protecting your privacy because we recognize how
          significant information confidentiality is to visitors to our website.
          This Fenlei Technology Private Limited ("Fenlei") GYANSOPAN Privacy
          Policy ("Privacy Policy") describes the circumstances under which we
          obtain personal information about you, the purposes for which we may
          use such information, and the security measures we have in place to
          protect that information
          <br />
          <br />
          You are understood to have given your consent to the collection and
          use of your information as described in this Privacy Policy by
          accessing and using our website and providing your information to us
          via email, usage of our website, or other means. The Terms of Use and
          this Privacy Policy will control how you use the website.
          <br />
          <br />
          You agree to the practices outlined in this Privacy Policy by
          accessing, utilizing, or using any other Services and features. The
          definitions given to the terms in the Terms of Use shall apply to this
          Privacy Policy.
          <br />
          <br />
          Generally, you are not required to provide any personal information
          about yourself or tell us who you are in order to browse our website
          (such as your name, company, phone number, address, or email address).
          <br />
          <br />
          <b>A Special Note:</b>
          <br />
          <br />
          The Website is applicable to Children under 18(Eighteen) Age. They are
          allowed to register with GYANSOPAN, use the online education service,
          or provide personal data. However, the identity provided by the
          children during the Sign-Up procedure is verified through the
          auto-verification of the Sign-Up’s email ID, and GYANSOPAN does not
          provide any Sign-Up IDs to any of its users.
          <br />
          <br />
          If you think that your child provided this kind of information on our
          website, we strongly encourage you to contact us immediately and we
          will use our best efforts to promptly remove such information from our
          records.
          <br />
          <br />
          <b>What are the purposes for which GYANSOPAN uses cookies?</b>
          <br />
          <br />A cookie is a simple text file that stores information about
          your surfing habits for future preference-enhancing purposes.
          <br />
          <br />
          The following are the purposes for which cookies are used:
          <ul>
            <li>
              IP Address, Device Type, Operating System, and Time Logs during
              your visit to the pages offered to you.
            </li>
          </ul>
          <br />
          <b>Data Information</b>
          <br />
          <br />
          We may ask for more precise information about you in the future. We
          could ask you for information such as your name, institute name, email
          address, and phone number ("User Information") if you want to learn
          more about us or our products. Although providing us with this
          information is optional, doing so may prevent you from submitting your
          request or inquiry..
          <br />
          <br />
          The processing of your data is authorized by law and required to carry
          out the contract, as stated in Article 6(1)(b) of the GDPR.
          <br />
          <br />
          No private data is disclosed to outside parties by us. We don't accept
          advertising, sell lists, or make any other kind of third-party money
          off the data collected by this website. However, we might use outside
          parties with whom we have a partnership to carry out services for us.
          These third parties are obligated to protect the privacy of any
          information and are not permitted to use it for any other purposes
          than those that have been approved by us.
          <br />
          <br />
          <b>Data collected by GYANSOPAN:</b>
          <br />
          <ol>
            <li>
              In order to gather some statistical data on how users interact
              with our website, our web server gathers IP addresses. When you
              use your computer to browse the internet, your computer is
              automatically given an IP address;
            </li>
            <li>
              The non-personally identifiable information we may gather in
              addition to the IP address includes demographic data (such as age,
              occupation, institute, zip code, etc.), browser types, domain
              names, and statistical information pertaining to the use of the
              company's website;
            </li>
            <li>Information provided by you when creating an account;</li>
            <li>
              Whenever you contact us with a question, you may opt to give us
              information such as your name, email address, phone number, the
              message's content, and/or any attachments you may send.
              information you give us when you request a meeting or a service
              demonstration; Information pertaining to any services you may have
              purchased or for any deal you may have made.
            </li>
            <li>
              Information you give us when you request a meeting or a service
              demonstration;
            </li>
            <li>
              Information pertaining to any services you may have purchased or
              for any deal you may have made.
            </li>
          </ol>
          <br />
          <b>Use of Information:</b>
          <br />
          <br />
          We utilize the data gathered for one or more of the following
          purposes:
          <br />
          <ol>
            <li>
              To get in touch with you about the services you've signed up for
              or the interests you've expressed by visiting the site;
            </li>
            <li>
              Performing statistical analysis and/or research on the data;
            </li>
            <li>
              Utilize the data gathered in such other ways as necessary to
              uphold the caliber, efficacy, and standard of our website,
              products, and services;
            </li>
            <li>Analyze and quantify user trends and behavior;</li>
            <li>
              To assist with data gathering, analytics, and strategic
              development;
            </li>
            <li>
              Evaluate or develop new features, as well as monitor, manage,
              troubleshoot, and/or improve our products, services, and any
              associated features;
            </li>
            <li>Maintaining internal records; </li>
            <li>To create marketing profiles and user profiles; </li>
            <li>To create users' and marketing profiles;</li>
            <li>To examine how our products and services are used.</li>
            <li>
              To oversee our interactions with our partners, advertising, and
              other third parties;
            </li>
            <li>
              Preventing any potentially unlawful behavior and any unwanted or
              abusive activities from being screened;
            </li>
            <li>
              Contact with the aim of conducting market research, including the
              delivery of marketing information that might be of interest to you
              using either soft-opt-in or opt-in methods.
            </li>
            <li>Identify and stop fraud</li>
          </ol>
          <br />
          <b> Opt-In Consent</b>
          <br />
          <ol>
            <li>
              Soft opt-in consent is a particular kind of permission that is
              applicable when you have already interacted with us (for instance,
              when you get in touch with us to seek more information about a
              specific product or service and we are marketing alternatives). We
              will consider your consent to be given under "soft opt-in" consent
              unless you choose to withdraw it.
            </li>
            <li>
              For other forms of e-marketing, we are required to gain your
              explicit approval. To do so, you must take decisive action, like
              checking a tick box that we will offer, to demonstrate your
              consent.
            </li>
            <li>
              You are providing your express agreement to the processing of your
              personal data in accordance with GDPR Article 6(1)(a).
            </li>
          </ol>
          <br />
          We want to make sure the data we get is correct. Please notify us by
          sending an email to
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a> if your contact
          information changes or if you want to have certain contact information
          removed from our records.
          <br />
          <br />
          Please let us know if you ever want to "opt out" of getting
          information from us by sending an email to{" "}
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a> Please be aware
          that after receiving your "opt out" notification, you might still
          receive communications for a short while due to lead times on some
          contacts.
          <br />
          <br />
          <b>Log Files</b>
          <br />
          <br />
          Using log files is a typical practice at GYANSOPAN. These records keep
          track of who visits which websites. This is a practice shared by all
          hosting firms and a component of their analytics. Internet protocol
          (IP) addresses, browser type, Internet service provider (ISP), date
          and time stamp, referring/exit sites, and even click counts are among
          the data that log files collect. These are not connected to any
          personally identifiable information. The information is used for trend
          analysis, website administration, tracking user behavior on the site,
          and demographic research.
          <br />
          <br />
          <b>Protection of your Personal Information</b>
          <br />
          <br />
          Despite our best efforts and the implementation of necessary
          technological and organizational protections to protect personally
          identifiable information from theft, abuse, modification, or
          destruction, data transfer via the internet may not be adequately
          secured by the sender.
          <br />
          <br />
          We are unable to ensure the secure transportation of any information
          you provide to us through this method, therefore you do so at your own
          risk.
          <br />
          <br />
          <b>Third Party Websites</b>
          <br />
          <br />
          The privacy policies of third parties whose websites may be mentioned
          or linked in the Website are not our responsibility.
          <br />
          <br />
          The privacy policy of GYANSOPAN does not apply to advertising or
          websites on other third party websites. As a result, we suggest that
          you review the individual Privacy Policies of these third-party ad
          servers for more information. It might also contain information on
          their policies and how to reject particular choices.
          <br />
          <br />
          Through the choices available in each browser, you can decide to turn
          off cookies. On the websites for each online browser, you may find
          more in-depth information about managing cookies with that browser.
          <br />
          <br />
          <b>Data Retention</b>
          <br />
          <br />
          The company will only keep information on our systems for the time
          frame required to perform the purposes mentioned in this Privacy
          Policy, unless a longer retention period is required or permitted by
          law.
          <br />
          <br />
          <b>Your rights under GDPR</b>
          <br />
          <br />
          You have the following rights in relation to the data GYANSOPAN holds
          if you reside in the EEA(European Economic Area):
          <br />
          <ol>
            <li>
              According to Article 15 of the GDPR, you have the right to access
              information from us, including the categories of your personal
              data involved and the purpose of processing them, as well as the
              right to a copy;
            </li>
            <li>
              In accordance with Article 16 of the GDPR, you have the right to
              request that inaccurate or incomplete personal data be corrected;{" "}
            </li>
            <li>
              Under certain conditions and in accordance with our Retention
              Policy, you have the right to request that we delete your personal
              information in accordance with Article 17 of the GDPR;
            </li>
            <li>
              Under certain conditions and in accordance with Article 18 of the
              GDPR, you have the right to request that we restrict the
              processing of your data;{" "}
            </li>
            <li>
              According to Article 20 of the GDPR, in situations where the
              information is processed with your consent or automatically, you
              have the right to transfer the information to a third party in a
              structured, widely-used, and machine-readable format;{" "}
            </li>
            <li>
              You have the right to object under Article 21 of the GDPR for
              reasons relevant to a specific circumstance, such as direct
              marketing objectives;
            </li>
          </ol>
          <br />
          Before we can act on a subject access request under the GDPR, we will
          always need to be certain of your identity. Typically, we will need
          identification documentation like a passport or driver's license.
          <br />
          <br />
          <b>Change of Business Ownership and Control:</b>
          <ol>
            <li>
              The company may occasionally increase or decrease the scope of its
              operations, which may entail selling or transferring control of
              all or a portion of the company. The provided information will,
              where it is pertinent to any portion of our business so
              transferred, be transferred along with that portion, and the new
              owner or newly controlling party will be permitted to use the
              information in accordance with the terms of this Privacy Policy
              for the purposes for which it was originally provided to us.
            </li>
            <li>
              We may also reveal information to a potential buyer of all or a
              portion of our business.
            </li>
            <li>
              In the situations mentioned above in section 2, we will take
              actions to protect your privacy.
            </li>
          </ol>
          <br />
          <b>Revisions to the Privacy Policy:</b>
          <br />
          <br />
          We retain the right to, at any moment, amend and change this privacy
          policy. Check the last updated date at the top of this page to see
          whether this Privacy Policy has changed since your last visit. As a
          result, you should routinely examine our privacy policy to make sure
          you are familiar with any changes. Acceptance of the provisions of the
          Privacy Policy, as updated or changed by us, is implied by use of the
          Website.
          <br />
          <br />
          <b>Resources</b>
          <br />
          <br />
          On our websites, we offer resources that are open to the public.
          Please be cautious while giving out any personal information on blogs
          and other websites as it could result in message solicitation. If you
          decide to disclose your personal information on such resources,
          GYANSOPAN won't be held responsible. Contact{" "}
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a> if you want
          your personal information removed from such resources.
          <br />
          <br />
          <b> Disclosure in compliance with legal obligations:</b>
          <br />
          <br />
          If we have a good faith belief that disclosing the information is
          required by law or legal process (such as in response to a subpoena,
          warrant, or other court order), is necessary to prevent abuse or
          unauthorized use of our Website, or is necessary to protect the
          rights, property, or personal safety of us, our clients, the general
          public, or is necessary for reasons related to national security, we
          may do so.
          <br />
          <br />
          WE, OUR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, AND AGENTS SHALL
          NOT BE RESPONSIBLE OR LIABLE FOR THE ACTS OR OMISSIONS OF ANY THIRD
          PARTY, OR FOR ANY UNAUTHORIZED INTERCEPTION OF DATA OR BREACHES
          ATTRIBUTABLE IN WHOLE OR IN PART TO THE ACTS OR OMISSIONS OF THIRD
          PARTIES.
          <br />
          <br />
          <b>Permission to use your company logo and name:</b>
          <br />
          <br />
          By enrolling, you give GYANSOPAN consent to use your company's name
          and logo as a client on our website and in posts on social media.
          <br />
          <br />
          <b>Grievance Redressal Mechanism</b>
          <br />
          <br />
          If you have any concerns about how your information is being used,
          processed, or disclosed, or if there has been a violation of these
          conditions, you should contact the designated grievance resolution
          officer listed below by mail or email at{" "}
          <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a>.
          <br />
          <br />
          Grievance Redressal Officer: Any complaints, abuse, or concerns over
          comments, material, or a violation of these rules must be reported
          right away to the designated grievance officer, who is listed below,
          in writing or by email that is signed electronically.
          <br />
          <br />
          <b>Grievance Redressal Officer</b>
          <br />
          <br />
          Name: JITENDRA SADANGI
          <br />
          <br />
          Email: <a href="mailto: admin@fenlei.in"> admin@fenlei.in</a>
          <br />
          <br />
          Fenlei Technology Private Limited.
          <br />
          14, Krushnai Apartment, Lane No. 17A,
          <br />
          Dhayari, Pune 411041, Maharashtra
          <br />
          <br />
          We kindly ask that you provide the following details in your
          complaint:-
          <br />
          <br />
          Identification of the data you have given.
          <br />
          <ol>
            <li>
              A clear indication of whether the material is sensitive or
              personal information.
            </li>
            <li>Your home address, contact information, or email address.</li>
            <li>
              A declaration that, in your good faith opinion, the information
              has been used improperly or disclosed without authorization, as
              applicable.
            </li>
            <li>
              A declaration that the information in the notice is correct and
              that you are authorized to act on behalf of the holder of the
              allegedly infringed right, made under penalty of perjury.
            </li>
          </ol>
          <br />
          The company may get in touch with you to check or go through the
          specifics of your complaint and the issues you raised.
          <br />
          <br />
          If a communication is sent to a non-designated individual in this
          regard, the company is not liable for it.
          <br />
          <br />
          Using the contact details provided above, you may also get in touch
          with us if you have any queries or suggestions regarding the policy.
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
