export const pincodes = [
  {
    Pincode: 515631,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515581,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515571,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515281,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515241,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534176,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 813105,
    StateName: "BIHAR",
  },
  {
    Pincode: 813203,
    StateName: "BIHAR",
  },
  {
    Pincode: 853202,
    StateName: "BIHAR",
  },
  {
    Pincode: 813222,
    StateName: "BIHAR",
  },
  {
    Pincode: 812005,
    StateName: "BIHAR",
  },
  {
    Pincode: 853203,
    StateName: "BIHAR",
  },
  {
    Pincode: 812006,
    StateName: "BIHAR",
  },
  {
    Pincode: 853204,
    StateName: "BIHAR",
  },
  {
    Pincode: 813209,
    StateName: "BIHAR",
  },
  {
    Pincode: 530052,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515871,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515842,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515803,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515775,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515405,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515865,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515455,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515611,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515867,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515812,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515832,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515870,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531173,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531219,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 853205,
    StateName: "BIHAR",
  },
  {
    Pincode: 813210,
    StateName: "BIHAR",
  },
  {
    Pincode: 813108,
    StateName: "BIHAR",
  },
  {
    Pincode: 813212,
    StateName: "BIHAR",
  },
  {
    Pincode: 515101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503186,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503180,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503187,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503125,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503112,
    StateName: "TELANGANA",
  },
  {
    Pincode: 344704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 503124,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503309,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503108,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503306,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503123,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516129,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516237,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516130,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516151,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 147111,
    StateName: "PUNJAB",
  },
  {
    Pincode: 788162,
    StateName: "ASSAM",
  },
  {
    Pincode: 788150,
    StateName: "ASSAM",
  },
  {
    Pincode: 788801,
    StateName: "ASSAM",
  },
  {
    Pincode: 788161,
    StateName: "ASSAM",
  },
  {
    Pincode: 788152,
    StateName: "ASSAM",
  },
  {
    Pincode: 788155,
    StateName: "ASSAM",
  },
  {
    Pincode: 208001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 503310,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503110,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503145,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503120,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503235,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503321,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503185,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503230,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503246,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503305,
    StateName: "TELANGANA",
  },
  {
    Pincode: 273001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 505186,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505153,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505162,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505152,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505514,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505187,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505212,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505525,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505209,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505188,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505122,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505498,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524324,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 501301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500088,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500100,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501401,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500078,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501509,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500043,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500087,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501505,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500055,
    StateName: "TELANGANA",
  },
  {
    Pincode: 813207,
    StateName: "BIHAR",
  },
  {
    Pincode: 813110,
    StateName: "BIHAR",
  },
  {
    Pincode: 813109,
    StateName: "BIHAR",
  },
  {
    Pincode: 813107,
    StateName: "BIHAR",
  },
  {
    Pincode: 813211,
    StateName: "BIHAR",
  },
  {
    Pincode: 522658,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522614,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 344011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142049,
    StateName: "PUNJAB",
  },
  {
    Pincode: 503207,
    StateName: "TELANGANA",
  },
  {
    Pincode: 851210,
    StateName: "BIHAR",
  },
  {
    Pincode: 851127,
    StateName: "BIHAR",
  },
  {
    Pincode: 534239,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534237,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534202,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534243,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534199,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 848201,
    StateName: "BIHAR",
  },
  {
    Pincode: 534134,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502293,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502284,
    StateName: "TELANGANA",
  },
  {
    Pincode: 851113,
    StateName: "BIHAR",
  },
  {
    Pincode: 851111,
    StateName: "BIHAR",
  },
  {
    Pincode: 851112,
    StateName: "BIHAR",
  },
  {
    Pincode: 503301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503206,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503245,
    StateName: "TELANGANA",
  },
  {
    Pincode: 585447,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585404,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 151214,
    StateName: "PUNJAB",
  },
  {
    Pincode: 503114,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503144,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503122,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507204,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507169,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507208,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507168,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507003,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507158,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507209,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507122,
    StateName: "TELANGANA",
  },
  {
    Pincode: 851120,
    StateName: "BIHAR",
  },
  {
    Pincode: 848204,
    StateName: "BIHAR",
  },
  {
    Pincode: 851204,
    StateName: "BIHAR",
  },
  {
    Pincode: 851211,
    StateName: "BIHAR",
  },
  {
    Pincode: 847451,
    StateName: "BIHAR",
  },
  {
    Pincode: 851128,
    StateName: "BIHAR",
  },
  {
    Pincode: 502114,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534238,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534206,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534210,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 472445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 851213,
    StateName: "BIHAR",
  },
  {
    Pincode: 851214,
    StateName: "BIHAR",
  },
  {
    Pincode: 851201,
    StateName: "BIHAR",
  },
  {
    Pincode: 515231,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515271,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515164,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515303,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515556,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515661,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515672,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515521,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 783135,
    StateName: "ASSAM",
  },
  {
    Pincode: 827016,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 515591,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515641,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515531,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515541,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515511,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515551,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515154,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515341,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515286,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515144,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505129,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505470,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505505,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505474,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505475,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505502,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505490,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505472,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505480,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754031,
    StateName: "ODISHA",
  },
  {
    Pincode: 754028,
    StateName: "ODISHA",
  },
  {
    Pincode: 754027,
    StateName: "ODISHA",
  },
  {
    Pincode: 754037,
    StateName: "ODISHA",
  },
  {
    Pincode: 754022,
    StateName: "ODISHA",
  },
  {
    Pincode: 754290,
    StateName: "ODISHA",
  },
  {
    Pincode: 505497,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505471,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505476,
    StateName: "TELANGANA",
  },
  {
    Pincode: 515133,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515159,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515651,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 141114,
    StateName: "PUNJAB",
  },
  {
    Pincode: 825103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829143,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 824304,
    StateName: "BIHAR",
  },
  {
    Pincode: 802204,
    StateName: "BIHAR",
  },
  {
    Pincode: 802158,
    StateName: "BIHAR",
  },
  {
    Pincode: 523264,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523263,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524221,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524152,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524222,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524236,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523262,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524223,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524224,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524239,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 501503,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501121,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501111,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501142,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501501,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501203,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501141,
    StateName: "TELANGANA",
  },
  {
    Pincode: 813213,
    StateName: "BIHAR",
  },
  {
    Pincode: 822123,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 531084,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531114,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531022,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 822132,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 444602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 531126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516329,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516293,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516321,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531149,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 501144,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501504,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524300,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524142,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 852161,
    StateName: "BIHAR",
  },
  {
    Pincode: 534208,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534204,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534207,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 851118,
    StateName: "BIHAR",
  },
  {
    Pincode: 788163,
    StateName: "ASSAM",
  },
  {
    Pincode: 788165,
    StateName: "ASSAM",
  },
  {
    Pincode: 788734,
    StateName: "ASSAM",
  },
  {
    Pincode: 788736,
    StateName: "ASSAM",
  },
  {
    Pincode: 788737,
    StateName: "ASSAM",
  },
  {
    Pincode: 788166,
    StateName: "ASSAM",
  },
  {
    Pincode: 788735,
    StateName: "ASSAM",
  },
  {
    Pincode: 788156,
    StateName: "ASSAM",
  },
  {
    Pincode: 533274,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533253,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533250,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533229,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533248,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533238,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533236,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533251,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533244,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533249,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515445,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825405,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533290,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 754025,
    StateName: "ODISHA",
  },
  {
    Pincode: 802205,
    StateName: "BIHAR",
  },
  {
    Pincode: 768033,
    StateName: "ODISHA",
  },
  {
    Pincode: 788806,
    StateName: "ASSAM",
  },
  {
    Pincode: 788723,
    StateName: "ASSAM",
  },
  {
    Pincode: 788733,
    StateName: "ASSAM",
  },
  {
    Pincode: 788701,
    StateName: "ASSAM",
  },
  {
    Pincode: 788725,
    StateName: "ASSAM",
  },
  {
    Pincode: 788726,
    StateName: "ASSAM",
  },
  {
    Pincode: 788728,
    StateName: "ASSAM",
  },
  {
    Pincode: 147007,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140402,
    StateName: "PUNJAB",
  },
  {
    Pincode: 333517,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 847122,
    StateName: "BIHAR",
  },
  {
    Pincode: 331027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333020,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333055,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 759151,
    StateName: "ODISHA",
  },
  {
    Pincode: 201001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 847227,
    StateName: "BIHAR",
  },
  {
    Pincode: 847232,
    StateName: "BIHAR",
  },
  {
    Pincode: 847421,
    StateName: "BIHAR",
  },
  {
    Pincode: 847231,
    StateName: "BIHAR",
  },
  {
    Pincode: 847214,
    StateName: "BIHAR",
  },
  {
    Pincode: 503003,
    StateName: "TELANGANA",
  },
  {
    Pincode: 575009,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 141110,
    StateName: "PUNJAB",
  },
  {
    Pincode: 802183,
    StateName: "BIHAR",
  },
  {
    Pincode: 802313,
    StateName: "BIHAR",
  },
  {
    Pincode: 802206,
    StateName: "BIHAR",
  },
  {
    Pincode: 802314,
    StateName: "BIHAR",
  },
  {
    Pincode: 802159,
    StateName: "BIHAR",
  },
  {
    Pincode: 847301,
    StateName: "BIHAR",
  },
  {
    Pincode: 282001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 501106,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781320,
    StateName: "ASSAM",
  },
  {
    Pincode: 781367,
    StateName: "ASSAM",
  },
  {
    Pincode: 781377,
    StateName: "ASSAM",
  },
  {
    Pincode: 781371,
    StateName: "ASSAM",
  },
  {
    Pincode: 788117,
    StateName: "ASSAM",
  },
  {
    Pincode: 788817,
    StateName: "ASSAM",
  },
  {
    Pincode: 788113,
    StateName: "ASSAM",
  },
  {
    Pincode: 788802,
    StateName: "ASSAM",
  },
  {
    Pincode: 383002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383422,
    StateName: "GUJARAT",
  },
  {
    Pincode: 343029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 382430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 847104,
    StateName: "BIHAR",
  },
  {
    Pincode: 847302,
    StateName: "BIHAR",
  },
  {
    Pincode: 846003,
    StateName: "BIHAR",
  },
  {
    Pincode: 384330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 494230,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 141008,
    StateName: "PUNJAB",
  },
  {
    Pincode: 504204,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504294,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504206,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504207,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504216,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504205,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504231,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504214,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504215,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504209,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504219,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503307,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503165,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503175,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503164,
    StateName: "TELANGANA",
  },
  {
    Pincode: 533237,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515561,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506342,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507210,
    StateName: "TELANGANA",
  },
  {
    Pincode: 847204,
    StateName: "BIHAR",
  },
  {
    Pincode: 847427,
    StateName: "BIHAR",
  },
  {
    Pincode: 506112,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506135,
    StateName: "TELANGANA",
  },
  {
    Pincode: 495116,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495004,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 788816,
    StateName: "ASSAM",
  },
  {
    Pincode: 784171,
    StateName: "ASSAM",
  },
  {
    Pincode: 784149,
    StateName: "ASSAM",
  },
  {
    Pincode: 516193,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516269,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516505,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516268,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516105,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516128,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 494347,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494237,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494010,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494552,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 503308,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503218,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503213,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516152,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516150,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 495224,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495112,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495222,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495550,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495113,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 847105,
    StateName: "BIHAR",
  },
  {
    Pincode: 759147,
    StateName: "ODISHA",
  },
  {
    Pincode: 822112,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822129,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822114,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822133,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822120,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 754023,
    StateName: "ODISHA",
  },
  {
    Pincode: 754296,
    StateName: "ODISHA",
  },
  {
    Pincode: 754024,
    StateName: "ODISHA",
  },
  {
    Pincode: 755013,
    StateName: "ODISHA",
  },
  {
    Pincode: 160055,
    StateName: "PUNJAB",
  },
  {
    Pincode: 755043,
    StateName: "ODISHA",
  },
  {
    Pincode: 755014,
    StateName: "ODISHA",
  },
  {
    Pincode: 639103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754082,
    StateName: "ODISHA",
  },
  {
    Pincode: 505425,
    StateName: "TELANGANA",
  },
  {
    Pincode: 755016,
    StateName: "ODISHA",
  },
  {
    Pincode: 754217,
    StateName: "ODISHA",
  },
  {
    Pincode: 754219,
    StateName: "ODISHA",
  },
  {
    Pincode: 754218,
    StateName: "ODISHA",
  },
  {
    Pincode: 754253,
    StateName: "ODISHA",
  },
  {
    Pincode: 754239,
    StateName: "ODISHA",
  },
  {
    Pincode: 754208,
    StateName: "ODISHA",
  },
  {
    Pincode: 754292,
    StateName: "ODISHA",
  },
  {
    Pincode: 754222,
    StateName: "ODISHA",
  },
  {
    Pincode: 754240,
    StateName: "ODISHA",
  },
  {
    Pincode: 754210,
    StateName: "ODISHA",
  },
  {
    Pincode: 754289,
    StateName: "ODISHA",
  },
  {
    Pincode: 754214,
    StateName: "ODISHA",
  },
  {
    Pincode: 754248,
    StateName: "ODISHA",
  },
  {
    Pincode: 754244,
    StateName: "ODISHA",
  },
  {
    Pincode: 754216,
    StateName: "ODISHA",
  },
  {
    Pincode: 754223,
    StateName: "ODISHA",
  },
  {
    Pincode: 505529,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505460,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754295,
    StateName: "ODISHA",
  },
  {
    Pincode: 754032,
    StateName: "ODISHA",
  },
  {
    Pincode: 802222,
    StateName: "BIHAR",
  },
  {
    Pincode: 802162,
    StateName: "BIHAR",
  },
  {
    Pincode: 533232,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535525,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515763,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515701,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 755017,
    StateName: "ODISHA",
  },
  {
    Pincode: 755018,
    StateName: "ODISHA",
  },
  {
    Pincode: 754205,
    StateName: "ODISHA",
  },
  {
    Pincode: 755036,
    StateName: "ODISHA",
  },
  {
    Pincode: 755027,
    StateName: "ODISHA",
  },
  {
    Pincode: 755003,
    StateName: "ODISHA",
  },
  {
    Pincode: 822121,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 516401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 335522,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 506105,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506315,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506368,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506132,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506104,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506122,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506365,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506369,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506006,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506330,
    StateName: "TELANGANA",
  },
  {
    Pincode: 495552,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495559,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495668,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495669,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 515408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515415,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515751,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533107,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535260,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516349,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517194,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517152,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517297,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517236,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517234,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 847304,
    StateName: "BIHAR",
  },
  {
    Pincode: 847121,
    StateName: "BIHAR",
  },
  {
    Pincode: 847107,
    StateName: "BIHAR",
  },
  {
    Pincode: 846009,
    StateName: "BIHAR",
  },
  {
    Pincode: 847115,
    StateName: "BIHAR",
  },
  {
    Pincode: 847306,
    StateName: "BIHAR",
  },
  {
    Pincode: 846005,
    StateName: "BIHAR",
  },
  {
    Pincode: 847307,
    StateName: "BIHAR",
  },
  {
    Pincode: 847337,
    StateName: "BIHAR",
  },
  {
    Pincode: 846007,
    StateName: "BIHAR",
  },
  {
    Pincode: 847123,
    StateName: "BIHAR",
  },
  {
    Pincode: 847202,
    StateName: "BIHAR",
  },
  {
    Pincode: 305813,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305811,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 741123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 305004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 786003,
    StateName: "ASSAM",
  },
  {
    Pincode: 786184,
    StateName: "ASSAM",
  },
  {
    Pincode: 786004,
    StateName: "ASSAM",
  },
  {
    Pincode: 786101,
    StateName: "ASSAM",
  },
  {
    Pincode: 786602,
    StateName: "ASSAM",
  },
  {
    Pincode: 786614,
    StateName: "ASSAM",
  },
  {
    Pincode: 786001,
    StateName: "ASSAM",
  },
  {
    Pincode: 785676,
    StateName: "ASSAM",
  },
  {
    Pincode: 785675,
    StateName: "ASSAM",
  },
  {
    Pincode: 786010,
    StateName: "ASSAM",
  },
  {
    Pincode: 786012,
    StateName: "ASSAM",
  },
  {
    Pincode: 786610,
    StateName: "ASSAM",
  },
  {
    Pincode: 786621,
    StateName: "ASSAM",
  },
  {
    Pincode: 786611,
    StateName: "ASSAM",
  },
  {
    Pincode: 847101,
    StateName: "BIHAR",
  },
  {
    Pincode: 847203,
    StateName: "BIHAR",
  },
  {
    Pincode: 845432,
    StateName: "BIHAR",
  },
  {
    Pincode: 845433,
    StateName: "BIHAR",
  },
  {
    Pincode: 845305,
    StateName: "BIHAR",
  },
  {
    Pincode: 845434,
    StateName: "BIHAR",
  },
  {
    Pincode: 845435,
    StateName: "BIHAR",
  },
  {
    Pincode: 845456,
    StateName: "BIHAR",
  },
  {
    Pincode: 845436,
    StateName: "BIHAR",
  },
  {
    Pincode: 845437,
    StateName: "BIHAR",
  },
  {
    Pincode: 506103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506007,
    StateName: "TELANGANA",
  },
  {
    Pincode: 847303,
    StateName: "BIHAR",
  },
  {
    Pincode: 495663,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 501143,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501502,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501158,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524421,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524323,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524129,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524345,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524121,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524313,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524366,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524303,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524308,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524302,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524318,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524319,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 500036,
    StateName: "TELANGANA",
  },
  {
    Pincode: 523171,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523190,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523167,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523181,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523184,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523169,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523157,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523185,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523170,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 847106,
    StateName: "BIHAR",
  },
  {
    Pincode: 845401,
    StateName: "BIHAR",
  },
  {
    Pincode: 841302,
    StateName: "BIHAR",
  },
  {
    Pincode: 786103,
    StateName: "ASSAM",
  },
  {
    Pincode: 786612,
    StateName: "ASSAM",
  },
  {
    Pincode: 787033,
    StateName: "ASSAM",
  },
  {
    Pincode: 784161,
    StateName: "ASSAM",
  },
  {
    Pincode: 787055,
    StateName: "ASSAM",
  },
  {
    Pincode: 503225,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503212,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503219,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503311,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503223,
    StateName: "TELANGANA",
  },
  {
    Pincode: 494635,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494335,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494332,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 523281,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523271,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 500005,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500113,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501359,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501218,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500112,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501506,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501510,
    StateName: "TELANGANA",
  },
  {
    Pincode: 494111,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494442,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494115,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 507182,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507159,
    StateName: "TELANGANA",
  },
  {
    Pincode: 491441,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 535463,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535523,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503188,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535594,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535521,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535524,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535526,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535534,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535582,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 488059,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 212308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 522646,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533436,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533291,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533446,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 501508,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501512,
    StateName: "TELANGANA",
  },
  {
    Pincode: 501511,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500039,
    StateName: "TELANGANA",
  },
  {
    Pincode: 786008,
    StateName: "ASSAM",
  },
  {
    Pincode: 786191,
    StateName: "ASSAM",
  },
  {
    Pincode: 494448,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 508253,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506003,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534267,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 848202,
    StateName: "BIHAR",
  },
  {
    Pincode: 851129,
    StateName: "BIHAR",
  },
  {
    Pincode: 851130,
    StateName: "BIHAR",
  },
  {
    Pincode: 851203,
    StateName: "BIHAR",
  },
  {
    Pincode: 851205,
    StateName: "BIHAR",
  },
  {
    Pincode: 502303,
    StateName: "TELANGANA",
  },
  {
    Pincode: 851212,
    StateName: "BIHAR",
  },
  {
    Pincode: 851215,
    StateName: "BIHAR",
  },
  {
    Pincode: 851216,
    StateName: "BIHAR",
  },
  {
    Pincode: 848203,
    StateName: "BIHAR",
  },
  {
    Pincode: 851206,
    StateName: "BIHAR",
  },
  {
    Pincode: 852129,
    StateName: "BIHAR",
  },
  {
    Pincode: 851131,
    StateName: "BIHAR",
  },
  {
    Pincode: 851117,
    StateName: "BIHAR",
  },
  {
    Pincode: 851217,
    StateName: "BIHAR",
  },
  {
    Pincode: 851132,
    StateName: "BIHAR",
  },
  {
    Pincode: 851218,
    StateName: "BIHAR",
  },
  {
    Pincode: 851133,
    StateName: "BIHAR",
  },
  {
    Pincode: 851134,
    StateName: "BIHAR",
  },
  {
    Pincode: 852219,
    StateName: "BIHAR",
  },
  {
    Pincode: 852128,
    StateName: "BIHAR",
  },
  {
    Pincode: 852132,
    StateName: "BIHAR",
  },
  {
    Pincode: 852130,
    StateName: "BIHAR",
  },
  {
    Pincode: 852131,
    StateName: "BIHAR",
  },
  {
    Pincode: 534222,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534425,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534448,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 852126,
    StateName: "BIHAR",
  },
  {
    Pincode: 852127,
    StateName: "BIHAR",
  },
  {
    Pincode: 500090,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524405,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515741,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532148,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 852221,
    StateName: "BIHAR",
  },
  {
    Pincode: 532455,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532457,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518196,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518543,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518523,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518553,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 822124,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822134,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517505,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503188,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508245,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508244,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508373,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508254,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508266,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508256,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503217,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500075,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500089,
    StateName: "TELANGANA",
  },
  {
    Pincode: 999999,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534406,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534460,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534462,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534426,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534455,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534456,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534449,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534475,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534451,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 783385,
    StateName: "ASSAM",
  },
  {
    Pincode: 532187,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533440,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518583,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518593,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523331,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 755025,
    StateName: "ODISHA",
  },
  {
    Pincode: 755004,
    StateName: "ODISHA",
  },
  {
    Pincode: 755005,
    StateName: "ODISHA",
  },
  {
    Pincode: 755062,
    StateName: "ODISHA",
  },
  {
    Pincode: 755026,
    StateName: "ODISHA",
  },
  {
    Pincode: 848506,
    StateName: "BIHAR",
  },
  {
    Pincode: 848216,
    StateName: "BIHAR",
  },
  {
    Pincode: 848113,
    StateName: "BIHAR",
  },
  {
    Pincode: 848207,
    StateName: "BIHAR",
  },
  {
    Pincode: 505525,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 802163,
    StateName: "BIHAR",
  },
  {
    Pincode: 755007,
    StateName: "ODISHA",
  },
  {
    Pincode: 755008,
    StateName: "ODISHA",
  },
  {
    Pincode: 755024,
    StateName: "ODISHA",
  },
  {
    Pincode: 755019,
    StateName: "ODISHA",
  },
  {
    Pincode: 755023,
    StateName: "ODISHA",
  },
  {
    Pincode: 753004,
    StateName: "ODISHA",
  },
  {
    Pincode: 754131,
    StateName: "ODISHA",
  },
  {
    Pincode: 754021,
    StateName: "ODISHA",
  },
  {
    Pincode: 754130,
    StateName: "ODISHA",
  },
  {
    Pincode: 755006,
    StateName: "ODISHA",
  },
  {
    Pincode: 755009,
    StateName: "ODISHA",
  },
  {
    Pincode: 523346,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523328,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523368,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523369,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 384440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 506310,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506314,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506166,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504299,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506317,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506008,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506163,
    StateName: "TELANGANA",
  },
  {
    Pincode: 846004,
    StateName: "BIHAR",
  },
  {
    Pincode: 385320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385505,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385535,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495220,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 506318,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516104,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517129,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 784175,
    StateName: "ASSAM",
  },
  {
    Pincode: 788815,
    StateName: "ASSAM",
  },
  {
    Pincode: 815352,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 518302,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518395,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518347,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518348,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518333,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518346,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518308,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518350,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518344,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518313,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518345,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518343,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518396,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518380,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518323,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517415,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517416,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517125,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506313,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508223,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506316,
    StateName: "TELANGANA",
  },
  {
    Pincode: 759101,
    StateName: "ODISHA",
  },
  {
    Pincode: 506222,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506005,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506333,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509382,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509407,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509204,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509205,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509334,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509350,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509353,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509371,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509339,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509352,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508214,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534461,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534452,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 851202,
    StateName: "BIHAR",
  },
  {
    Pincode: 523370,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523332,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523371,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523372,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523320,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523326,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523329,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518135,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 802160,
    StateName: "BIHAR",
  },
  {
    Pincode: 802161,
    StateName: "BIHAR",
  },
  {
    Pincode: 505503,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754035,
    StateName: "ODISHA",
  },
  {
    Pincode: 754201,
    StateName: "ODISHA",
  },
  {
    Pincode: 754030,
    StateName: "ODISHA",
  },
  {
    Pincode: 518674,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518134,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531118,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517643,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533464,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533430,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 822125,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 517589,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517590,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517591,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517620,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535593,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535125,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535568,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535579,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535591,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535128,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535546,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508206,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508233,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508221,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508279,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508212,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508280,
    StateName: "TELANGANA",
  },
  {
    Pincode: 333010,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 508277,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508284,
    StateName: "TELANGANA",
  },
  {
    Pincode: 845307,
    StateName: "BIHAR",
  },
  {
    Pincode: 824219,
    StateName: "BIHAR",
  },
  {
    Pincode: 824116,
    StateName: "BIHAR",
  },
  {
    Pincode: 824217,
    StateName: "BIHAR",
  },
  {
    Pincode: 824201,
    StateName: "BIHAR",
  },
  {
    Pincode: 824111,
    StateName: "BIHAR",
  },
  {
    Pincode: 768204,
    StateName: "ODISHA",
  },
  {
    Pincode: 524321,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524407,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524415,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524320,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524132,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524413,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524309,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524402,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517130,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517128,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517131,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 814165,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814154,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 516004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 788164,
    StateName: "ASSAM",
  },
  {
    Pincode: 788168,
    StateName: "ASSAM",
  },
  {
    Pincode: 517640,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517586,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517642,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517587,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517583,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517588,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517571,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517641,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517541,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517584,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517619,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517561,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517569,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 755010,
    StateName: "ODISHA",
  },
  {
    Pincode: 524347,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 515291,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515261,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515110,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515122,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 783330,
    StateName: "ASSAM",
  },
  {
    Pincode: 533223,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533254,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534245,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534266,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534268,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534265,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534338,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534250,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534281,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534275,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506165,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506349,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508112,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508286,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508115,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508252,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506169,
    StateName: "TELANGANA",
  },
  {
    Pincode: 382315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 506356,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506345,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504106,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534209,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533297,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533296,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533354,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533353,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 851126,
    StateName: "BIHAR",
  },
  {
    Pincode: 534467,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506331,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509338,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508205,
    StateName: "TELANGANA",
  },
  {
    Pincode: 787023,
    StateName: "ASSAM",
  },
  {
    Pincode: 787052,
    StateName: "ASSAM",
  },
  {
    Pincode: 787056,
    StateName: "ASSAM",
  },
  {
    Pincode: 523166,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523168,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 500064,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524127,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523274,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523292,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523279,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523116,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523272,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523104,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523110,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523112,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523246,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523227,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534280,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534217,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534330,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534227,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534320,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534328,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534324,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 852137,
    StateName: "BIHAR",
  },
  {
    Pincode: 852138,
    StateName: "BIHAR",
  },
  {
    Pincode: 852139,
    StateName: "BIHAR",
  },
  {
    Pincode: 852220,
    StateName: "BIHAR",
  },
  {
    Pincode: 523241,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523304,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523223,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523265,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524404,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524341,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524406,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524412,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508211,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508210,
    StateName: "TELANGANA",
  },
  {
    Pincode: 755028,
    StateName: "ODISHA",
  },
  {
    Pincode: 755022,
    StateName: "ODISHA",
  },
  {
    Pincode: 755011,
    StateName: "ODISHA",
  },
  {
    Pincode: 755012,
    StateName: "ODISHA",
  },
  {
    Pincode: 754246,
    StateName: "ODISHA",
  },
  {
    Pincode: 754224,
    StateName: "ODISHA",
  },
  {
    Pincode: 754213,
    StateName: "ODISHA",
  },
  {
    Pincode: 754231,
    StateName: "ODISHA",
  },
  {
    Pincode: 754227,
    StateName: "ODISHA",
  },
  {
    Pincode: 754215,
    StateName: "ODISHA",
  },
  {
    Pincode: 755061,
    StateName: "ODISHA",
  },
  {
    Pincode: 509202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 752001,
    StateName: "ODISHA",
  },
  {
    Pincode: 226001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 509321,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534444,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534244,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534236,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508255,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508105,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508113,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508285,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506367,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509133,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509128,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509132,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509126,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509153,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502221,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502278,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502267,
    StateName: "TELANGANA",
  },
  {
    Pincode: 788738,
    StateName: "ASSAM",
  },
  {
    Pincode: 815357,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 788160,
    StateName: "ASSAM",
  },
  {
    Pincode: 524134,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524414,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524131,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524410,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524409,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524343,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524203,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 500030,
    StateName: "TELANGANA",
  },
  {
    Pincode: 523135,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523165,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523187,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 752004,
    StateName: "ODISHA",
  },
  {
    Pincode: 822111,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533451,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508250,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508114,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508248,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534146,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522409,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 813101,
    StateName: "BIHAR",
  },
  {
    Pincode: 522015,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522017,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522019,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522020,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522502,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522016,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522508,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522303,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522509,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522659,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522663,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522616,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522612,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522233,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534316,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522302,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522018,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522410,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522435,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522426,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522438,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 841420,
    StateName: "BIHAR",
  },
  {
    Pincode: 841423,
    StateName: "BIHAR",
  },
  {
    Pincode: 814160,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814147,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814153,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814151,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 781335,
    StateName: "ASSAM",
  },
  {
    Pincode: 814156,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 534450,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534435,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504105,
    StateName: "TELANGANA",
  },
  {
    Pincode: 382122,
    StateName: "GUJARAT",
  },
  {
    Pincode: 504101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504110,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504306,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504109,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504310,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508258,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508257,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508377,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508243,
    StateName: "TELANGANA",
  },
  {
    Pincode: 533228,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 385421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 144402,
    StateName: "PUNJAB",
  },
  {
    Pincode: 847201,
    StateName: "BIHAR",
  },
  {
    Pincode: 783124,
    StateName: "ASSAM",
  },
  {
    Pincode: 504323,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504311,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504312,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504293,
    StateName: "TELANGANA",
  },
  {
    Pincode: 847103,
    StateName: "BIHAR",
  },
  {
    Pincode: 480997,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 521225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 605403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 202001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 509132,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521110,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521185,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522436,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522402,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522413,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522412,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522421,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522613,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504251,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504203,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504104,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507167,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507170,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507305,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507163,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507318,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507165,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521260,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521330,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521324,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505530,
    StateName: "TELANGANA",
  },
  {
    Pincode: 226003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 505303,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505403,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505404,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505305,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505307,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 814111,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 754220,
    StateName: "ODISHA",
  },
  {
    Pincode: 521004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 284001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 521366,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 811301,
    StateName: "BIHAR",
  },
  {
    Pincode: 811303,
    StateName: "BIHAR",
  },
  {
    Pincode: 815355,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 788120,
    StateName: "ASSAM",
  },
  {
    Pincode: 815359,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815354,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 848114,
    StateName: "BIHAR",
  },
  {
    Pincode: 754225,
    StateName: "ODISHA",
  },
  {
    Pincode: 754212,
    StateName: "ODISHA",
  },
  {
    Pincode: 754250,
    StateName: "ODISHA",
  },
  {
    Pincode: 505467,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505469,
    StateName: "TELANGANA",
  },
  {
    Pincode: 523291,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 845107,
    StateName: "BIHAR",
  },
  {
    Pincode: 841440,
    StateName: "BIHAR",
  },
  {
    Pincode: 841416,
    StateName: "BIHAR",
  },
  {
    Pincode: 845101,
    StateName: "BIHAR",
  },
  {
    Pincode: 845438,
    StateName: "BIHAR",
  },
  {
    Pincode: 804401,
    StateName: "BIHAR",
  },
  {
    Pincode: 534122,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534327,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534230,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 752119,
    StateName: "ODISHA",
  },
  {
    Pincode: 752107,
    StateName: "ODISHA",
  },
  {
    Pincode: 752106,
    StateName: "ODISHA",
  },
  {
    Pincode: 752104,
    StateName: "ODISHA",
  },
  {
    Pincode: 505306,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521320,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 636705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 804432,
    StateName: "BIHAR",
  },
  {
    Pincode: 804405,
    StateName: "BIHAR",
  },
  {
    Pincode: 504304,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504307,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754200,
    StateName: "ODISHA",
  },
  {
    Pincode: 841231,
    StateName: "BIHAR",
  },
  {
    Pincode: 841227,
    StateName: "BIHAR",
  },
  {
    Pincode: 841434,
    StateName: "BIHAR",
  },
  {
    Pincode: 841435,
    StateName: "BIHAR",
  },
  {
    Pincode: 841236,
    StateName: "BIHAR",
  },
  {
    Pincode: 841238,
    StateName: "BIHAR",
  },
  {
    Pincode: 841240,
    StateName: "BIHAR",
  },
  {
    Pincode: 841239,
    StateName: "BIHAR",
  },
  {
    Pincode: 841507,
    StateName: "BIHAR",
  },
  {
    Pincode: 841439,
    StateName: "BIHAR",
  },
  {
    Pincode: 841241,
    StateName: "BIHAR",
  },
  {
    Pincode: 841504,
    StateName: "BIHAR",
  },
  {
    Pincode: 841243,
    StateName: "BIHAR",
  },
  {
    Pincode: 841226,
    StateName: "BIHAR",
  },
  {
    Pincode: 841244,
    StateName: "BIHAR",
  },
  {
    Pincode: 841506,
    StateName: "BIHAR",
  },
  {
    Pincode: 522034,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522112,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522619,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524342,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 847428,
    StateName: "BIHAR",
  },
  {
    Pincode: 788101,
    StateName: "ASSAM",
  },
  {
    Pincode: 788126,
    StateName: "ASSAM",
  },
  {
    Pincode: 788111,
    StateName: "ASSAM",
  },
  {
    Pincode: 788114,
    StateName: "ASSAM",
  },
  {
    Pincode: 788106,
    StateName: "ASSAM",
  },
  {
    Pincode: 788123,
    StateName: "ASSAM",
  },
  {
    Pincode: 788110,
    StateName: "ASSAM",
  },
  {
    Pincode: 500052,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500079,
    StateName: "TELANGANA",
  },
  {
    Pincode: 523230,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523253,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523224,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509129,
    StateName: "TELANGANA",
  },
  {
    Pincode: 751002,
    StateName: "ODISHA",
  },
  {
    Pincode: 504308,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504309,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504346,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504313,
    StateName: "TELANGANA",
  },
  {
    Pincode: 824236,
    StateName: "BIHAR",
  },
  {
    Pincode: 804435,
    StateName: "BIHAR",
  },
  {
    Pincode: 804402,
    StateName: "BIHAR",
  },
  {
    Pincode: 804419,
    StateName: "BIHAR",
  },
  {
    Pincode: 804421,
    StateName: "BIHAR",
  },
  {
    Pincode: 804423,
    StateName: "BIHAR",
  },
  {
    Pincode: 804426,
    StateName: "BIHAR",
  },
  {
    Pincode: 804428,
    StateName: "BIHAR",
  },
  {
    Pincode: 804406,
    StateName: "BIHAR",
  },
  {
    Pincode: 804417,
    StateName: "BIHAR",
  },
  {
    Pincode: 804418,
    StateName: "BIHAR",
  },
  {
    Pincode: 804420,
    StateName: "BIHAR",
  },
  {
    Pincode: 852108,
    StateName: "BIHAR",
  },
  {
    Pincode: 852109,
    StateName: "BIHAR",
  },
  {
    Pincode: 852110,
    StateName: "BIHAR",
  },
  {
    Pincode: 852115,
    StateName: "BIHAR",
  },
  {
    Pincode: 804422,
    StateName: "BIHAR",
  },
  {
    Pincode: 804429,
    StateName: "BIHAR",
  },
  {
    Pincode: 804424,
    StateName: "BIHAR",
  },
  {
    Pincode: 804425,
    StateName: "BIHAR",
  },
  {
    Pincode: 804427,
    StateName: "BIHAR",
  },
  {
    Pincode: 494001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 502291,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500008,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507203,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507160,
    StateName: "TELANGANA",
  },
  {
    Pincode: 533355,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503230,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534416,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522009,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505453,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505325,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505331,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505454,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505455,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505462,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505405,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505524,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505402,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505304,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505466,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505528,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505415,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505401,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505445,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507183,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507304,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507161,
    StateName: "TELANGANA",
  },
  {
    Pincode: 755044,
    StateName: "ODISHA",
  },
  {
    Pincode: 509135,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506244,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534269,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502251,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502249,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502286,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502256,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502257,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502318,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508374,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509335,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505451,
    StateName: "TELANGANA",
  },
  {
    Pincode: 535547,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 841245,
    StateName: "BIHAR",
  },
  {
    Pincode: 502287,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502246,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 814143,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815353,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814152,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 523201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505481,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505531,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505473,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506347,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506142,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506151,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506144,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506143,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506172,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506319,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505452,
    StateName: "TELANGANA",
  },
  {
    Pincode: 753008,
    StateName: "ODISHA",
  },
  {
    Pincode: 755015,
    StateName: "ODISHA",
  },
  {
    Pincode: 506370,
    StateName: "TELANGANA",
  },
  {
    Pincode: 523180,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509130,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509131,
    StateName: "TELANGANA",
  },
  {
    Pincode: 383001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 508234,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516108,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508376,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506329,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508004,
    StateName: "TELANGANA",
  },
  {
    Pincode: 841237,
    StateName: "BIHAR",
  },
  {
    Pincode: 841502,
    StateName: "BIHAR",
  },
  {
    Pincode: 384241,
    StateName: "GUJARAT",
  },
  {
    Pincode: 754114,
    StateName: "ODISHA",
  },
  {
    Pincode: 505504,
    StateName: "TELANGANA",
  },
  {
    Pincode: 535592,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 385340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384275,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384246,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841242,
    StateName: "BIHAR",
  },
  {
    Pincode: 841287,
    StateName: "BIHAR",
  },
  {
    Pincode: 755051,
    StateName: "ODISHA",
  },
  {
    Pincode: 509219,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508204,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508246,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508218,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508224,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508238,
    StateName: "TELANGANA",
  },
  {
    Pincode: 517193,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517277,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506145,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506221,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506223,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506252,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506348,
    StateName: "TELANGANA",
  },
  {
    Pincode: 517581,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517113,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517172,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517237,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517112,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517192,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517291,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517299,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517536,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517645,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534326,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502314,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506391,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754228,
    StateName: "ODISHA",
  },
  {
    Pincode: 754245,
    StateName: "ODISHA",
  },
  {
    Pincode: 534411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505450,
    StateName: "TELANGANA",
  },
  {
    Pincode: 518385,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 384272,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384151,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384285,
    StateName: "GUJARAT",
  },
  {
    Pincode: 755049,
    StateName: "ODISHA",
  },
  {
    Pincode: 515621,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523226,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523280,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523182,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523303,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505527,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509358,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505526,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505532,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502295,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502345,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502281,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502276,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502372,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502280,
    StateName: "TELANGANA",
  },
  {
    Pincode: 787026,
    StateName: "ASSAM",
  },
  {
    Pincode: 787059,
    StateName: "ASSAM",
  },
  {
    Pincode: 509152,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509127,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508278,
    StateName: "TELANGANA",
  },
  {
    Pincode: 393140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 508208,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508247,
    StateName: "TELANGANA",
  },
  {
    Pincode: 841505,
    StateName: "BIHAR",
  },
  {
    Pincode: 503235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503321,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533352,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 503145,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 491888,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 508101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 517526,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509409,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509411,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508355,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505184,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505174,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505208,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505185,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505416,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754008,
    StateName: "ODISHA",
  },
  {
    Pincode: 508111,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502375,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502279,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502311,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502210,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502228,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521122,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521133,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521136,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517582,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517520,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517502,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517503,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508217,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509208,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509351,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509337,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509210,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509340,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509380,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509324,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521150,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521132,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502312,
    StateName: "TELANGANA",
  },
  {
    Pincode: 814149,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814150,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 754294,
    StateName: "ODISHA",
  },
  {
    Pincode: 841446,
    StateName: "BIHAR",
  },
  {
    Pincode: 384221,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 509320,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509357,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509349,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509360,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509375,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509203,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509206,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 752111,
    StateName: "ODISHA",
  },
  {
    Pincode: 509408,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509207,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509228,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509327,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509410,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509325,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506170,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509216,
    StateName: "TELANGANA",
  },
  {
    Pincode: 522439,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534165,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521202,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521111,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521105,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509217,
    StateName: "TELANGANA",
  },
  {
    Pincode: 515321,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 752114,
    StateName: "ODISHA",
  },
  {
    Pincode: 509106,
    StateName: "TELANGANA",
  },
  {
    Pincode: 517507,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 392165,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 781137,
    StateName: "ASSAM",
  },
  {
    Pincode: 781381,
    StateName: "ASSAM",
  },
  {
    Pincode: 781030,
    StateName: "ASSAM",
  },
  {
    Pincode: 573103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573162,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 507157,
    StateName: "TELANGANA",
  },
  {
    Pincode: 783350,
    StateName: "ASSAM",
  },
  {
    Pincode: 783369,
    StateName: "ASSAM",
  },
  {
    Pincode: 783337,
    StateName: "ASSAM",
  },
  {
    Pincode: 783346,
    StateName: "ASSAM",
  },
  {
    Pincode: 783373,
    StateName: "ASSAM",
  },
  {
    Pincode: 783361,
    StateName: "ASSAM",
  },
  {
    Pincode: 783332,
    StateName: "ASSAM",
  },
  {
    Pincode: 532458,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781380,
    StateName: "ASSAM",
  },
  {
    Pincode: 781141,
    StateName: "ASSAM",
  },
  {
    Pincode: 781121,
    StateName: "ASSAM",
  },
  {
    Pincode: 781122,
    StateName: "ASSAM",
  },
  {
    Pincode: 781123,
    StateName: "ASSAM",
  },
  {
    Pincode: 781120,
    StateName: "ASSAM",
  },
  {
    Pincode: 781124,
    StateName: "ASSAM",
  },
  {
    Pincode: 781129,
    StateName: "ASSAM",
  },
  {
    Pincode: 781102,
    StateName: "ASSAM",
  },
  {
    Pincode: 782403,
    StateName: "ASSAM",
  },
  {
    Pincode: 502296,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502270,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502294,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502300,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502319,
    StateName: "TELANGANA",
  },
  {
    Pincode: 754010,
    StateName: "ODISHA",
  },
  {
    Pincode: 517592,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502329,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521328,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508117,
    StateName: "TELANGANA",
  },
  {
    Pincode: 782402,
    StateName: "ASSAM",
  },
  {
    Pincode: 781024,
    StateName: "ASSAM",
  },
  {
    Pincode: 781366,
    StateName: "ASSAM",
  },
  {
    Pincode: 781025,
    StateName: "ASSAM",
  },
  {
    Pincode: 781026,
    StateName: "ASSAM",
  },
  {
    Pincode: 781027,
    StateName: "ASSAM",
  },
  {
    Pincode: 393030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 521311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521106,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521207,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521109,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521403,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515134,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176200,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176059,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 521401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524230,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524310,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 508126,
    StateName: "TELANGANA",
  },
  {
    Pincode: 811305,
    StateName: "BIHAR",
  },
  {
    Pincode: 788102,
    StateName: "ASSAM",
  },
  {
    Pincode: 521312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 855101,
    StateName: "BIHAR",
  },
  {
    Pincode: 394810,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 524225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524226,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524227,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524228,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523261,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523183,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523302,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 577002,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577589,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577530,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577534,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577004,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 493344,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493449,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493116,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493332,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493554,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493118,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493338,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493221,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 523113,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502107,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502269,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502273,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502307,
    StateName: "TELANGANA",
  },
  {
    Pincode: 851116,
    StateName: "BIHAR",
  },
  {
    Pincode: 502371,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502285,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502306,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502313,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502290,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502321,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521149,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 573111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 504292,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504273,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521131,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176217,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176057,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176204,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176210,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176213,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176205,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176202,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176065,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176058,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176211,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176206,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 783375,
    StateName: "ASSAM",
  },
  {
    Pincode: 783354,
    StateName: "ASSAM",
  },
  {
    Pincode: 532123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532122,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532264,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532263,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532220,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532243,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532290,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532222,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532322,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532195,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532242,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532292,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504296,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506371,
    StateName: "TELANGANA",
  },
  {
    Pincode: 491445,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 573115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 506355,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506224,
    StateName: "TELANGANA",
  },
  {
    Pincode: 531149,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502305,
    StateName: "TELANGANA",
  },
  {
    Pincode: 393110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392155,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392012,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176022,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176056,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176037,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 516350,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516474,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 393150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392015,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387710,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388625,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387365,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387380,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 751017,
    StateName: "ODISHA",
  },
  {
    Pincode: 506134,
    StateName: "TELANGANA",
  },
  {
    Pincode: 518390,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388590,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388480,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388180,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825321,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 532215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532221,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 829101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829105,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829110,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 493770,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493996,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 516127,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516107,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 392230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176225,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 532212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176403,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176216,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 387310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 516454,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176051,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 517644,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505330,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505501,
    StateName: "TELANGANA",
  },
  {
    Pincode: 493890,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 176401,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 532284,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532203,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532404,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532219,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532291,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517504,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 829202,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829203,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 502032,
    StateName: "TELANGANA",
  },
  {
    Pincode: 811211,
    StateName: "BIHAR",
  },
  {
    Pincode: 521125,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521135,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521120,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 577007,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 493335,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493441,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 509401,
    StateName: "TELANGANA",
  },
  {
    Pincode: 383235,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385545,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 787110,
    StateName: "ASSAM",
  },
  {
    Pincode: 782450,
    StateName: "ASSAM",
  },
  {
    Pincode: 787061,
    StateName: "ASSAM",
  },
  {
    Pincode: 521156,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 493222,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493196,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493113,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 829117,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829118,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825411,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829134,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829133,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 388140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 782140,
    StateName: "ASSAM",
  },
  {
    Pincode: 506175,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506303,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506168,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521332,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521356,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 788112,
    StateName: "ASSAM",
  },
  {
    Pincode: 518349,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516110,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 396440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 516203,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176214,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 393151,
    StateName: "GUJARAT",
  },
  {
    Pincode: 521158,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504272,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504297,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504295,
    StateName: "TELANGANA",
  },
  {
    Pincode: 521157,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521138,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521162,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521331,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521369,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 811308,
    StateName: "BIHAR",
  },
  {
    Pincode: 811317,
    StateName: "BIHAR",
  },
  {
    Pincode: 811312,
    StateName: "BIHAR",
  },
  {
    Pincode: 811313,
    StateName: "BIHAR",
  },
  {
    Pincode: 176201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176060,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176402,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 518176,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518502,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518122,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518112,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518166,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518511,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518513,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518155,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516356,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516310,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516431,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516175,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523286,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 504346,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516361,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516434,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506366,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506344,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534195,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534166,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516362,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516359,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516380,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516172,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531151,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 506352,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506343,
    StateName: "TELANGANA",
  },
  {
    Pincode: 811315,
    StateName: "BIHAR",
  },
  {
    Pincode: 811316,
    StateName: "BIHAR",
  },
  {
    Pincode: 811314,
    StateName: "BIHAR",
  },
  {
    Pincode: 811202,
    StateName: "BIHAR",
  },
  {
    Pincode: 502247,
    StateName: "TELANGANA",
  },
  {
    Pincode: 829106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 385570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385555,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385565,
    StateName: "GUJARAT",
  },
  {
    Pincode: 509336,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516257,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502316,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502271,
    StateName: "TELANGANA",
  },
  {
    Pincode: 493773,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493889,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 825101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 535502,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533348,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533351,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533349,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 507114,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507133,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507137,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507116,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507117,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507115,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507140,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507128,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507202,
    StateName: "TELANGANA",
  },
  {
    Pincode: 811302,
    StateName: "BIHAR",
  },
  {
    Pincode: 502115,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502255,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502113,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502109,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502248,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502125,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502117,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502334,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502310,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502108,
    StateName: "TELANGANA",
  },
  {
    Pincode: 516270,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516504,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 396427,
    StateName: "GUJARAT",
  },
  {
    Pincode: 517247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517423,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517352,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517414,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176064,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 781125,
    StateName: "ASSAM",
  },
  {
    Pincode: 781376,
    StateName: "ASSAM",
  },
  {
    Pincode: 531011,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531036,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531030,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535559,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535160,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535581,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535006,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535148,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535270,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515601,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 494661,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 312615,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 126110,
    StateName: "HARYANA",
  },
  {
    Pincode: 126101,
    StateName: "HARYANA",
  },
  {
    Pincode: 126125,
    StateName: "HARYANA",
  },
  {
    Pincode: 126116,
    StateName: "HARYANA",
  },
  {
    Pincode: 507136,
    StateName: "TELANGANA",
  },
  {
    Pincode: 494226,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494229,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494228,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 515331,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515202,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176203,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 509110,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509104,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509105,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509120,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522237,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523252,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523254,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523109,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509311,
    StateName: "TELANGANA",
  },
  {
    Pincode: 385540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 506332,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506324,
    StateName: "TELANGANA",
  },
  {
    Pincode: 385001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 506381,
    StateName: "TELANGANA",
  },
  {
    Pincode: 380058,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 534432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534427,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534437,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502277,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507118,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507303,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507120,
    StateName: "TELANGANA",
  },
  {
    Pincode: 855102,
    StateName: "BIHAR",
  },
  {
    Pincode: 509235,
    StateName: "TELANGANA",
  },
  {
    Pincode: 382433,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382425,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382435,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495683,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 804453,
    StateName: "BIHAR",
  },
  {
    Pincode: 385330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 509209,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509215,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509412,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509385,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509406,
    StateName: "TELANGANA",
  },
  {
    Pincode: 535004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535250,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825325,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825316,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829119,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829150,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825330,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 495691,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495689,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495442,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495330,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495003,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 383010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 507164,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507211,
    StateName: "TELANGANA",
  },
  {
    Pincode: 494670,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494336,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 825414,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 493891,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 509381,
    StateName: "TELANGANA",
  },
  {
    Pincode: 531061,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531019,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531034,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531031,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509376,
    StateName: "TELANGANA",
  },
  {
    Pincode: 509326,
    StateName: "TELANGANA",
  },
  {
    Pincode: 393010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 829201,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 324005,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 247001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 324002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 324003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 680001,
    StateName: "KERALA",
  },
  {
    Pincode: 494333,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494776,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 388630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 491335,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491332,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491331,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 506015,
    StateName: "TELANGANA",
  },
  {
    Pincode: 781101,
    StateName: "ASSAM",
  },
  {
    Pincode: 670301,
    StateName: "KERALA",
  },
  {
    Pincode: 325209,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 365555,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365601,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364522,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365435,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383434,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 847234,
    StateName: "BIHAR",
  },
  {
    Pincode: 784163,
    StateName: "ASSAM",
  },
  {
    Pincode: 785104,
    StateName: "ASSAM",
  },
  {
    Pincode: 785611,
    StateName: "ASSAM",
  },
  {
    Pincode: 785602,
    StateName: "ASSAM",
  },
  {
    Pincode: 176219,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176052,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 491221,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491225,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491226,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 364130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364505,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360024,
    StateName: "GUJARAT",
  },
  {
    Pincode: 847215,
    StateName: "BIHAR",
  },
  {
    Pincode: 847235,
    StateName: "BIHAR",
  },
  {
    Pincode: 847236,
    StateName: "BIHAR",
  },
  {
    Pincode: 847411,
    StateName: "BIHAR",
  },
  {
    Pincode: 382424,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495448,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495674,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 363621,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364730,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364740,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364750,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785703,
    StateName: "ASSAM",
  },
  {
    Pincode: 785101,
    StateName: "ASSAM",
  },
  {
    Pincode: 785631,
    StateName: "ASSAM",
  },
  {
    Pincode: 785010,
    StateName: "ASSAM",
  },
  {
    Pincode: 360311,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785702,
    StateName: "ASSAM",
  },
  {
    Pincode: 785612,
    StateName: "ASSAM",
  },
  {
    Pincode: 364140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 490021,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 490025,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491228,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491230,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491771,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 785618,
    StateName: "ASSAM",
  },
  {
    Pincode: 364765,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360060,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360470,
    StateName: "GUJARAT",
  },
  {
    Pincode: 534002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 531162,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530027,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785614,
    StateName: "ASSAM",
  },
  {
    Pincode: 785622,
    StateName: "ASSAM",
  },
  {
    Pincode: 785625,
    StateName: "ASSAM",
  },
  {
    Pincode: 785619,
    StateName: "ASSAM",
  },
  {
    Pincode: 785613,
    StateName: "ASSAM",
  },
  {
    Pincode: 785705,
    StateName: "ASSAM",
  },
  {
    Pincode: 785615,
    StateName: "ASSAM",
  },
  {
    Pincode: 785603,
    StateName: "ASSAM",
  },
  {
    Pincode: 785601,
    StateName: "ASSAM",
  },
  {
    Pincode: 382405,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495557,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495687,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 364310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364060,
    StateName: "GUJARAT",
  },
  {
    Pincode: 786174,
    StateName: "ASSAM",
  },
  {
    Pincode: 250001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 121102,
    StateName: "HARYANA",
  },
  {
    Pincode: 682052,
    StateName: "KERALA",
  },
  {
    Pincode: 250110,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 464770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 786154,
    StateName: "ASSAM",
  },
  {
    Pincode: 786146,
    StateName: "ASSAM",
  },
  {
    Pincode: 786156,
    StateName: "ASSAM",
  },
  {
    Pincode: 786148,
    StateName: "ASSAM",
  },
  {
    Pincode: 518145,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518594,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518563,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518512,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518573,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516433,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 495661,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 847308,
    StateName: "BIHAR",
  },
  {
    Pincode: 491223,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 504301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 781023,
    StateName: "ASSAM",
  },
  {
    Pincode: 781017,
    StateName: "ASSAM",
  },
  {
    Pincode: 531077,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531087,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531027,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531113,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530029,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530047,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530045,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530048,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530012,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531163,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 493555,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 821101,
    StateName: "BIHAR",
  },
  {
    Pincode: 847239,
    StateName: "BIHAR",
  },
  {
    Pincode: 847424,
    StateName: "BIHAR",
  },
  {
    Pincode: 847410,
    StateName: "BIHAR",
  },
  {
    Pincode: 847240,
    StateName: "BIHAR",
  },
  {
    Pincode: 843312,
    StateName: "BIHAR",
  },
  {
    Pincode: 385130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 813221,
    StateName: "BIHAR",
  },
  {
    Pincode: 502381,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 781015,
    StateName: "ASSAM",
  },
  {
    Pincode: 495682,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 518165,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518510,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 382120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 821102,
    StateName: "BIHAR",
  },
  {
    Pincode: 531115,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502331,
    StateName: "TELANGANA",
  },
  {
    Pincode: 803213,
    StateName: "BIHAR",
  },
  {
    Pincode: 801307,
    StateName: "BIHAR",
  },
  {
    Pincode: 803108,
    StateName: "BIHAR",
  },
  {
    Pincode: 531024,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 813103,
    StateName: "BIHAR",
  },
  {
    Pincode: 813202,
    StateName: "BIHAR",
  },
  {
    Pincode: 534197,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522236,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 382455,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 813104,
    StateName: "BIHAR",
  },
  {
    Pincode: 813201,
    StateName: "BIHAR",
  },
  {
    Pincode: 534235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 801301,
    StateName: "BIHAR",
  },
  {
    Pincode: 803110,
    StateName: "BIHAR",
  },
  {
    Pincode: 502336,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502335,
    StateName: "TELANGANA",
  },
  {
    Pincode: 814131,
    StateName: "BIHAR",
  },
  {
    Pincode: 813106,
    StateName: "BIHAR",
  },
  {
    Pincode: 531040,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531025,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531133,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531026,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531117,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 502130,
    StateName: "TELANGANA",
  },
  {
    Pincode: 382230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 491668,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 516355,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 382427,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841403,
    StateName: "BIHAR",
  },
  {
    Pincode: 491885,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491558,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491557,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491661,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 585102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585265,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585310,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 753014,
    StateName: "ODISHA",
  },
  {
    Pincode: 585311,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585312,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585313,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 517418,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517424,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516228,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516227,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516259,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518360,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518422,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518006,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518462,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518465,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518222,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518225,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 786002,
    StateName: "ASSAM",
  },
  {
    Pincode: 782482,
    StateName: "ASSAM",
  },
  {
    Pincode: 782490,
    StateName: "ASSAM",
  },
  {
    Pincode: 782480,
    StateName: "ASSAM",
  },
  {
    Pincode: 782470,
    StateName: "ASSAM",
  },
  {
    Pincode: 811101,
    StateName: "BIHAR",
  },
  {
    Pincode: 848209,
    StateName: "BIHAR",
  },
  {
    Pincode: 848210,
    StateName: "BIHAR",
  },
  {
    Pincode: 848127,
    StateName: "BIHAR",
  },
  {
    Pincode: 848236,
    StateName: "BIHAR",
  },
  {
    Pincode: 518508,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518452,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518463,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518466,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518220,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516502,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516162,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 841201,
    StateName: "BIHAR",
  },
  {
    Pincode: 841204,
    StateName: "BIHAR",
  },
  {
    Pincode: 841205,
    StateName: "BIHAR",
  },
  {
    Pincode: 841206,
    StateName: "BIHAR",
  },
  {
    Pincode: 841207,
    StateName: "BIHAR",
  },
  {
    Pincode: 841208,
    StateName: "BIHAR",
  },
  {
    Pincode: 841311,
    StateName: "BIHAR",
  },
  {
    Pincode: 841211,
    StateName: "BIHAR",
  },
  {
    Pincode: 531023,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516173,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516309,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516421,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516484,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516289,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516444,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516464,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535273,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535145,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517551,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781031,
    StateName: "ASSAM",
  },
  {
    Pincode: 783120,
    StateName: "ASSAM",
  },
  {
    Pincode: 783335,
    StateName: "ASSAM",
  },
  {
    Pincode: 783339,
    StateName: "ASSAM",
  },
  {
    Pincode: 783349,
    StateName: "ASSAM",
  },
  {
    Pincode: 783390,
    StateName: "ASSAM",
  },
  {
    Pincode: 829103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829109,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 535220,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535183,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516396,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516339,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516391,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535161,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535217,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535580,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535204,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 221007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 782462,
    StateName: "ASSAM",
  },
  {
    Pincode: 782441,
    StateName: "ASSAM",
  },
  {
    Pincode: 782485,
    StateName: "ASSAM",
  },
  {
    Pincode: 782486,
    StateName: "ASSAM",
  },
  {
    Pincode: 782435,
    StateName: "ASSAM",
  },
  {
    Pincode: 782481,
    StateName: "ASSAM",
  },
  {
    Pincode: 782428,
    StateName: "ASSAM",
  },
  {
    Pincode: 600100,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 522324,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522313,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522202,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522309,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522113,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522258,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522341,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522304,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522306,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522265,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 783348,
    StateName: "ASSAM",
  },
  {
    Pincode: 783393,
    StateName: "ASSAM",
  },
  {
    Pincode: 783123,
    StateName: "ASSAM",
  },
  {
    Pincode: 783360,
    StateName: "ASSAM",
  },
  {
    Pincode: 521130,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 803212,
    StateName: "BIHAR",
  },
  {
    Pincode: 363330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 244001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 363745,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 853201,
    StateName: "BIHAR",
  },
  {
    Pincode: 812004,
    StateName: "BIHAR",
  },
  {
    Pincode: 813204,
    StateName: "BIHAR",
  },
  {
    Pincode: 530044,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825413,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 387002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 535213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535281,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 785609,
    StateName: "ASSAM",
  },
  {
    Pincode: 785626,
    StateName: "ASSAM",
  },
  {
    Pincode: 848130,
    StateName: "BIHAR",
  },
  {
    Pincode: 848132,
    StateName: "BIHAR",
  },
  {
    Pincode: 848503,
    StateName: "BIHAR",
  },
  {
    Pincode: 530049,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530053,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533347,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 363410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363750,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363780,
    StateName: "GUJARAT",
  },
  {
    Pincode: 781364,
    StateName: "ASSAM",
  },
  {
    Pincode: 783388,
    StateName: "ASSAM",
  },
  {
    Pincode: 783384,
    StateName: "ASSAM",
  },
  {
    Pincode: 783380,
    StateName: "ASSAM",
  },
  {
    Pincode: 781150,
    StateName: "ASSAM",
  },
  {
    Pincode: 782401,
    StateName: "ASSAM",
  },
  {
    Pincode: 781005,
    StateName: "ASSAM",
  },
  {
    Pincode: 828109,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826004,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828110,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828111,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828113,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 493195,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 802207,
    StateName: "BIHAR",
  },
  {
    Pincode: 802208,
    StateName: "BIHAR",
  },
  {
    Pincode: 802156,
    StateName: "BIHAR",
  },
  {
    Pincode: 802203,
    StateName: "BIHAR",
  },
  {
    Pincode: 523245,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 574313,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 530040,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530043,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 828203,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828116,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828305,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828129,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828205,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 523270,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 848125,
    StateName: "BIHAR",
  },
  {
    Pincode: 516214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516267,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516233,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516217,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516503,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517390,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517280,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517391,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 491227,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 534003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 848129,
    StateName: "BIHAR",
  },
  {
    Pincode: 848131,
    StateName: "BIHAR",
  },
  {
    Pincode: 848133,
    StateName: "BIHAR",
  },
  {
    Pincode: 524315,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524304,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524346,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524306,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524317,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524234,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530031,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 243001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110074,
    StateName: "DELHI",
  },
  {
    Pincode: 362263,
    StateName: "GUJARAT",
  },
  {
    Pincode: 251001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 362140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110073,
    StateName: "DELHI",
  },
  {
    Pincode: 110041,
    StateName: "DELHI",
  },
  {
    Pincode: 110059,
    StateName: "DELHI",
  },
  {
    Pincode: 110087,
    StateName: "DELHI",
  },
  {
    Pincode: 110063,
    StateName: "DELHI",
  },
  {
    Pincode: 847409,
    StateName: "BIHAR",
  },
  {
    Pincode: 847238,
    StateName: "BIHAR",
  },
  {
    Pincode: 365620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 847109,
    StateName: "BIHAR",
  },
  {
    Pincode: 313011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 365540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 192201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 782143,
    StateName: "ASSAM",
  },
  {
    Pincode: 782128,
    StateName: "ASSAM",
  },
  {
    Pincode: 782120,
    StateName: "ASSAM",
  },
  {
    Pincode: 782144,
    StateName: "ASSAM",
  },
  {
    Pincode: 782102,
    StateName: "ASSAM",
  },
  {
    Pincode: 782122,
    StateName: "ASSAM",
  },
  {
    Pincode: 782141,
    StateName: "ASSAM",
  },
  {
    Pincode: 782103,
    StateName: "ASSAM",
  },
  {
    Pincode: 782429,
    StateName: "ASSAM",
  },
  {
    Pincode: 782448,
    StateName: "ASSAM",
  },
  {
    Pincode: 363040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 782446,
    StateName: "ASSAM",
  },
  {
    Pincode: 782447,
    StateName: "ASSAM",
  },
  {
    Pincode: 782442,
    StateName: "ASSAM",
  },
  {
    Pincode: 785008,
    StateName: "ASSAM",
  },
  {
    Pincode: 785004,
    StateName: "ASSAM",
  },
  {
    Pincode: 785610,
    StateName: "ASSAM",
  },
  {
    Pincode: 360576,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110018,
    StateName: "DELHI",
  },
  {
    Pincode: 362625,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362225,
    StateName: "GUJARAT",
  },
  {
    Pincode: 574219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574326,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802212,
    StateName: "BIHAR",
  },
  {
    Pincode: 802219,
    StateName: "BIHAR",
  },
  {
    Pincode: 821110,
    StateName: "BIHAR",
  },
  {
    Pincode: 802220,
    StateName: "BIHAR",
  },
  {
    Pincode: 821113,
    StateName: "BIHAR",
  },
  {
    Pincode: 786147,
    StateName: "ASSAM",
  },
  {
    Pincode: 786145,
    StateName: "ASSAM",
  },
  {
    Pincode: 786179,
    StateName: "ASSAM",
  },
  {
    Pincode: 841202,
    StateName: "BIHAR",
  },
  {
    Pincode: 844127,
    StateName: "BIHAR",
  },
  {
    Pincode: 363423,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363760,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363775,
    StateName: "GUJARAT",
  },
  {
    Pincode: 185121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185153,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185212,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185133,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 491336,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495555,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495006,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 841209,
    StateName: "BIHAR",
  },
  {
    Pincode: 360030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 517326,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 784501,
    StateName: "ASSAM",
  },
  {
    Pincode: 784110,
    StateName: "ASSAM",
  },
  {
    Pincode: 784112,
    StateName: "ASSAM",
  },
  {
    Pincode: 784184,
    StateName: "ASSAM",
  },
  {
    Pincode: 784168,
    StateName: "ASSAM",
  },
  {
    Pincode: 784170,
    StateName: "ASSAM",
  },
  {
    Pincode: 784178,
    StateName: "ASSAM",
  },
  {
    Pincode: 784027,
    StateName: "ASSAM",
  },
  {
    Pincode: 495686,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 844111,
    StateName: "BIHAR",
  },
  {
    Pincode: 844103,
    StateName: "BIHAR",
  },
  {
    Pincode: 844113,
    StateName: "BIHAR",
  },
  {
    Pincode: 844501,
    StateName: "BIHAR",
  },
  {
    Pincode: 844503,
    StateName: "BIHAR",
  },
  {
    Pincode: 844502,
    StateName: "BIHAR",
  },
  {
    Pincode: 844504,
    StateName: "BIHAR",
  },
  {
    Pincode: 844116,
    StateName: "BIHAR",
  },
  {
    Pincode: 844118,
    StateName: "BIHAR",
  },
  {
    Pincode: 844102,
    StateName: "BIHAR",
  },
  {
    Pincode: 844505,
    StateName: "BIHAR",
  },
  {
    Pincode: 507103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507124,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507123,
    StateName: "TELANGANA",
  },
  {
    Pincode: 385560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385575,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495695,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495660,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495671,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 801104,
    StateName: "BIHAR",
  },
  {
    Pincode: 843113,
    StateName: "BIHAR",
  },
  {
    Pincode: 782461,
    StateName: "ASSAM",
  },
  {
    Pincode: 782439,
    StateName: "ASSAM",
  },
  {
    Pincode: 782445,
    StateName: "ASSAM",
  },
  {
    Pincode: 785699,
    StateName: "ASSAM",
  },
  {
    Pincode: 518599,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531083,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531028,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531085,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518206,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518467,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516163,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 574217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574328,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574198,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802202,
    StateName: "BIHAR",
  },
  {
    Pincode: 802201,
    StateName: "BIHAR",
  },
  {
    Pincode: 841286,
    StateName: "BIHAR",
  },
  {
    Pincode: 841232,
    StateName: "BIHAR",
  },
  {
    Pincode: 802352,
    StateName: "BIHAR",
  },
  {
    Pincode: 802311,
    StateName: "BIHAR",
  },
  {
    Pincode: 585325,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 502103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 781029,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 783323,
    StateName: "ASSAM",
  },
  {
    Pincode: 783394,
    StateName: "ASSAM",
  },
  {
    Pincode: 783324,
    StateName: "ASSAM",
  },
  {
    Pincode: 585316,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585236,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 494446,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 494444,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382465,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841312,
    StateName: "BIHAR",
  },
  {
    Pincode: 495556,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495115,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495551,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 571215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571235,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 754005,
    StateName: "ODISHA",
  },
  {
    Pincode: 382130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 783134,
    StateName: "ASSAM",
  },
  {
    Pincode: 495334,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 783301,
    StateName: "ASSAM",
  },
  {
    Pincode: 783122,
    StateName: "ASSAM",
  },
  {
    Pincode: 382140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495445,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495118,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382449,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495335,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382470,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380060,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382225,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382463,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382255,
    StateName: "GUJARAT",
  },
  {
    Pincode: 843126,
    StateName: "BIHAR",
  },
  {
    Pincode: 843118,
    StateName: "BIHAR",
  },
  {
    Pincode: 843119,
    StateName: "BIHAR",
  },
  {
    Pincode: 783331,
    StateName: "ASSAM",
  },
  {
    Pincode: 783101,
    StateName: "ASSAM",
  },
  {
    Pincode: 783334,
    StateName: "ASSAM",
  },
  {
    Pincode: 783132,
    StateName: "ASSAM",
  },
  {
    Pincode: 783126,
    StateName: "ASSAM",
  },
  {
    Pincode: 507201,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507306,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507166,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507316,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507302,
    StateName: "TELANGANA",
  },
  {
    Pincode: 382016,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382308,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382423,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 531075,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531033,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531081,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 382481,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382213,
    StateName: "GUJARAT",
  },
  {
    Pincode: 783129,
    StateName: "ASSAM",
  },
  {
    Pincode: 783125,
    StateName: "ASSAM",
  },
  {
    Pincode: 783130,
    StateName: "ASSAM",
  },
  {
    Pincode: 783370,
    StateName: "ASSAM",
  },
  {
    Pincode: 783336,
    StateName: "ASSAM",
  },
  {
    Pincode: 783371,
    StateName: "ASSAM",
  },
  {
    Pincode: 783391,
    StateName: "ASSAM",
  },
  {
    Pincode: 783372,
    StateName: "ASSAM",
  },
  {
    Pincode: 531021,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 835225,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533462,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533463,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535280,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 509324,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535221,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 802351,
    StateName: "BIHAR",
  },
  {
    Pincode: 802152,
    StateName: "BIHAR",
  },
  {
    Pincode: 802312,
    StateName: "BIHAR",
  },
  {
    Pincode: 802223,
    StateName: "BIHAR",
  },
  {
    Pincode: 802154,
    StateName: "BIHAR",
  },
  {
    Pincode: 784182,
    StateName: "ASSAM",
  },
  {
    Pincode: 784174,
    StateName: "ASSAM",
  },
  {
    Pincode: 784507,
    StateName: "ASSAM",
  },
  {
    Pincode: 784101,
    StateName: "ASSAM",
  },
  {
    Pincode: 784172,
    StateName: "ASSAM",
  },
  {
    Pincode: 842004,
    StateName: "BIHAR",
  },
  {
    Pincode: 843108,
    StateName: "BIHAR",
  },
  {
    Pincode: 847408,
    StateName: "BIHAR",
  },
  {
    Pincode: 783376,
    StateName: "ASSAM",
  },
  {
    Pincode: 783333,
    StateName: "ASSAM",
  },
  {
    Pincode: 783347,
    StateName: "ASSAM",
  },
  {
    Pincode: 783345,
    StateName: "ASSAM",
  },
  {
    Pincode: 781134,
    StateName: "ASSAM",
  },
  {
    Pincode: 781104,
    StateName: "ASSAM",
  },
  {
    Pincode: 781127,
    StateName: "ASSAM",
  },
  {
    Pincode: 826006,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 847213,
    StateName: "BIHAR",
  },
  {
    Pincode: 847108,
    StateName: "BIHAR",
  },
  {
    Pincode: 843111,
    StateName: "BIHAR",
  },
  {
    Pincode: 843129,
    StateName: "BIHAR",
  },
  {
    Pincode: 843112,
    StateName: "BIHAR",
  },
  {
    Pincode: 524314,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524307,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533016,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533260,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533428,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 802155,
    StateName: "BIHAR",
  },
  {
    Pincode: 574223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 821103,
    StateName: "BIHAR",
  },
  {
    Pincode: 821111,
    StateName: "BIHAR",
  },
  {
    Pincode: 828204,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 783131,
    StateName: "ASSAM",
  },
  {
    Pincode: 783127,
    StateName: "ASSAM",
  },
  {
    Pincode: 783128,
    StateName: "ASSAM",
  },
  {
    Pincode: 533217,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533222,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533221,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533220,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 803211,
    StateName: "BIHAR",
  },
  {
    Pincode: 803214,
    StateName: "BIHAR",
  },
  {
    Pincode: 534442,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524137,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524344,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 844114,
    StateName: "BIHAR",
  },
  {
    Pincode: 844115,
    StateName: "BIHAR",
  },
  {
    Pincode: 521184,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524322,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523114,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523115,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 577228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577515,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577597,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577519,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577541,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577542,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577502,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 523273,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521190,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521163,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521402,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521250,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 785102,
    StateName: "ASSAM",
  },
  {
    Pincode: 786183,
    StateName: "ASSAM",
  },
  {
    Pincode: 786613,
    StateName: "ASSAM",
  },
  {
    Pincode: 786160,
    StateName: "ASSAM",
  },
  {
    Pincode: 784165,
    StateName: "ASSAM",
  },
  {
    Pincode: 786157,
    StateName: "ASSAM",
  },
  {
    Pincode: 786171,
    StateName: "ASSAM",
  },
  {
    Pincode: 786189,
    StateName: "ASSAM",
  },
  {
    Pincode: 786601,
    StateName: "ASSAM",
  },
  {
    Pincode: 786188,
    StateName: "ASSAM",
  },
  {
    Pincode: 786152,
    StateName: "ASSAM",
  },
  {
    Pincode: 786158,
    StateName: "ASSAM",
  },
  {
    Pincode: 786182,
    StateName: "ASSAM",
  },
  {
    Pincode: 784179,
    StateName: "ASSAM",
  },
  {
    Pincode: 784150,
    StateName: "ASSAM",
  },
  {
    Pincode: 784153,
    StateName: "ASSAM",
  },
  {
    Pincode: 784502,
    StateName: "ASSAM",
  },
  {
    Pincode: 784177,
    StateName: "ASSAM",
  },
  {
    Pincode: 784167,
    StateName: "ASSAM",
  },
  {
    Pincode: 784111,
    StateName: "ASSAM",
  },
  {
    Pincode: 788720,
    StateName: "ASSAM",
  },
  {
    Pincode: 844119,
    StateName: "BIHAR",
  },
  {
    Pincode: 844117,
    StateName: "BIHAR",
  },
  {
    Pincode: 495009,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 533406,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 365645,
    StateName: "GUJARAT",
  },
  {
    Pincode: 788711,
    StateName: "ASSAM",
  },
  {
    Pincode: 788719,
    StateName: "ASSAM",
  },
  {
    Pincode: 788781,
    StateName: "ASSAM",
  },
  {
    Pincode: 788722,
    StateName: "ASSAM",
  },
  {
    Pincode: 788724,
    StateName: "ASSAM",
  },
  {
    Pincode: 788712,
    StateName: "ASSAM",
  },
  {
    Pincode: 788713,
    StateName: "ASSAM",
  },
  {
    Pincode: 788709,
    StateName: "ASSAM",
  },
  {
    Pincode: 784103,
    StateName: "ASSAM",
  },
  {
    Pincode: 784105,
    StateName: "ASSAM",
  },
  {
    Pincode: 533211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781011,
    StateName: "ASSAM",
  },
  {
    Pincode: 533445,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533435,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533431,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533407,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533447,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533433,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 382321,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382042,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382028,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382426,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 784180,
    StateName: "ASSAM",
  },
  {
    Pincode: 786181,
    StateName: "ASSAM",
  },
  {
    Pincode: 786151,
    StateName: "ASSAM",
  },
  {
    Pincode: 786192,
    StateName: "ASSAM",
  },
  {
    Pincode: 786170,
    StateName: "ASSAM",
  },
  {
    Pincode: 786187,
    StateName: "ASSAM",
  },
  {
    Pincode: 843117,
    StateName: "BIHAR",
  },
  {
    Pincode: 843116,
    StateName: "BIHAR",
  },
  {
    Pincode: 521456,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521165,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521175,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524123,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524403,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531105,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 813205,
    StateName: "BIHAR",
  },
  {
    Pincode: 784189,
    StateName: "ASSAM",
  },
  {
    Pincode: 784506,
    StateName: "ASSAM",
  },
  {
    Pincode: 784025,
    StateName: "ASSAM",
  },
  {
    Pincode: 784505,
    StateName: "ASSAM",
  },
  {
    Pincode: 784117,
    StateName: "ASSAM",
  },
  {
    Pincode: 784514,
    StateName: "ASSAM",
  },
  {
    Pincode: 784144,
    StateName: "ASSAM",
  },
  {
    Pincode: 784508,
    StateName: "ASSAM",
  },
  {
    Pincode: 784509,
    StateName: "ASSAM",
  },
  {
    Pincode: 781318,
    StateName: "ASSAM",
  },
  {
    Pincode: 781317,
    StateName: "ASSAM",
  },
  {
    Pincode: 781355,
    StateName: "ASSAM",
  },
  {
    Pincode: 781303,
    StateName: "ASSAM",
  },
  {
    Pincode: 781340,
    StateName: "ASSAM",
  },
  {
    Pincode: 785688,
    StateName: "ASSAM",
  },
  {
    Pincode: 785698,
    StateName: "ASSAM",
  },
  {
    Pincode: 785670,
    StateName: "ASSAM",
  },
  {
    Pincode: 785692,
    StateName: "ASSAM",
  },
  {
    Pincode: 785673,
    StateName: "ASSAM",
  },
  {
    Pincode: 785686,
    StateName: "ASSAM",
  },
  {
    Pincode: 785689,
    StateName: "ASSAM",
  },
  {
    Pincode: 785682,
    StateName: "ASSAM",
  },
  {
    Pincode: 782425,
    StateName: "ASSAM",
  },
  {
    Pincode: 782125,
    StateName: "ASSAM",
  },
  {
    Pincode: 782139,
    StateName: "ASSAM",
  },
  {
    Pincode: 530003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530051,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530028,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533287,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533105,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533350,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533342,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533307,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 577517,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577545,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 782142,
    StateName: "ASSAM",
  },
  {
    Pincode: 782135,
    StateName: "ASSAM",
  },
  {
    Pincode: 781375,
    StateName: "ASSAM",
  },
  {
    Pincode: 781346,
    StateName: "ASSAM",
  },
  {
    Pincode: 781333,
    StateName: "ASSAM",
  },
  {
    Pincode: 781138,
    StateName: "ASSAM",
  },
  {
    Pincode: 781304,
    StateName: "ASSAM",
  },
  {
    Pincode: 781334,
    StateName: "ASSAM",
  },
  {
    Pincode: 781369,
    StateName: "ASSAM",
  },
  {
    Pincode: 781310,
    StateName: "ASSAM",
  },
  {
    Pincode: 781339,
    StateName: "ASSAM",
  },
  {
    Pincode: 781360,
    StateName: "ASSAM",
  },
  {
    Pincode: 781337,
    StateName: "ASSAM",
  },
  {
    Pincode: 524305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 524316,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 497101,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 571214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 495684,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495447,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 785667,
    StateName: "ASSAM",
  },
  {
    Pincode: 785669,
    StateName: "ASSAM",
  },
  {
    Pincode: 785684,
    StateName: "ASSAM",
  },
  {
    Pincode: 785685,
    StateName: "ASSAM",
  },
  {
    Pincode: 785674,
    StateName: "ASSAM",
  },
  {
    Pincode: 683104,
    StateName: "KERALA",
  },
  {
    Pincode: 682504,
    StateName: "KERALA",
  },
  {
    Pincode: 682506,
    StateName: "KERALA",
  },
  {
    Pincode: 854112,
    StateName: "BIHAR",
  },
  {
    Pincode: 854101,
    StateName: "BIHAR",
  },
  {
    Pincode: 522415,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 682308,
    StateName: "KERALA",
  },
  {
    Pincode: 682305,
    StateName: "KERALA",
  },
  {
    Pincode: 782121,
    StateName: "ASSAM",
  },
  {
    Pincode: 682315,
    StateName: "KERALA",
  },
  {
    Pincode: 682314,
    StateName: "KERALA",
  },
  {
    Pincode: 785697,
    StateName: "ASSAM",
  },
  {
    Pincode: 785640,
    StateName: "ASSAM",
  },
  {
    Pincode: 785687,
    StateName: "ASSAM",
  },
  {
    Pincode: 785671,
    StateName: "ASSAM",
  },
  {
    Pincode: 785672,
    StateName: "ASSAM",
  },
  {
    Pincode: 577556,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577512,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577513,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577514,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577553,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577566,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577601,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577528,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 841404,
    StateName: "BIHAR",
  },
  {
    Pincode: 843334,
    StateName: "BIHAR",
  },
  {
    Pincode: 843329,
    StateName: "BIHAR",
  },
  {
    Pincode: 782123,
    StateName: "ASSAM",
  },
  {
    Pincode: 843326,
    StateName: "BIHAR",
  },
  {
    Pincode: 843327,
    StateName: "BIHAR",
  },
  {
    Pincode: 843328,
    StateName: "BIHAR",
  },
  {
    Pincode: 843302,
    StateName: "BIHAR",
  },
  {
    Pincode: 577516,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 843330,
    StateName: "BIHAR",
  },
  {
    Pincode: 843331,
    StateName: "BIHAR",
  },
  {
    Pincode: 843311,
    StateName: "BIHAR",
  },
  {
    Pincode: 843313,
    StateName: "BIHAR",
  },
  {
    Pincode: 533343,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523335,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523334,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523333,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523330,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523356,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523327,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531127,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533261,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 784114,
    StateName: "ASSAM",
  },
  {
    Pincode: 802132,
    StateName: "BIHAR",
  },
  {
    Pincode: 821311,
    StateName: "BIHAR",
  },
  {
    Pincode: 802221,
    StateName: "BIHAR",
  },
  {
    Pincode: 781126,
    StateName: "ASSAM",
  },
  {
    Pincode: 781368,
    StateName: "ASSAM",
  },
  {
    Pincode: 781349,
    StateName: "ASSAM",
  },
  {
    Pincode: 781312,
    StateName: "ASSAM",
  },
  {
    Pincode: 788805,
    StateName: "ASSAM",
  },
  {
    Pincode: 788804,
    StateName: "ASSAM",
  },
  {
    Pincode: 788025,
    StateName: "ASSAM",
  },
  {
    Pincode: 788820,
    StateName: "ASSAM",
  },
  {
    Pincode: 788819,
    StateName: "ASSAM",
  },
  {
    Pincode: 788818,
    StateName: "ASSAM",
  },
  {
    Pincode: 788127,
    StateName: "ASSAM",
  },
  {
    Pincode: 788107,
    StateName: "ASSAM",
  },
  {
    Pincode: 788121,
    StateName: "ASSAM",
  },
  {
    Pincode: 788108,
    StateName: "ASSAM",
  },
  {
    Pincode: 821312,
    StateName: "BIHAR",
  },
  {
    Pincode: 577511,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577558,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577543,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 841441,
    StateName: "BIHAR",
  },
  {
    Pincode: 841405,
    StateName: "BIHAR",
  },
  {
    Pincode: 841425,
    StateName: "BIHAR",
  },
  {
    Pincode: 841426,
    StateName: "BIHAR",
  },
  {
    Pincode: 382722,
    StateName: "GUJARAT",
  },
  {
    Pincode: 497231,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497235,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 788832,
    StateName: "ASSAM",
  },
  {
    Pincode: 788830,
    StateName: "ASSAM",
  },
  {
    Pincode: 784523,
    StateName: "ASSAM",
  },
  {
    Pincode: 784528,
    StateName: "ASSAM",
  },
  {
    Pincode: 784521,
    StateName: "ASSAM",
  },
  {
    Pincode: 518007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518412,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518217,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 785690,
    StateName: "ASSAM",
  },
  {
    Pincode: 577596,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802157,
    StateName: "BIHAR",
  },
  {
    Pincode: 531035,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531055,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531111,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523357,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523336,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523367,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 494334,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494665,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494777,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494669,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 782002,
    StateName: "ASSAM",
  },
  {
    Pincode: 782003,
    StateName: "ASSAM",
  },
  {
    Pincode: 782136,
    StateName: "ASSAM",
  },
  {
    Pincode: 493448,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 518218,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 841508,
    StateName: "BIHAR",
  },
  {
    Pincode: 574220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 518216,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781313,
    StateName: "ASSAM",
  },
  {
    Pincode: 781344,
    StateName: "ASSAM",
  },
  {
    Pincode: 824123,
    StateName: "BIHAR",
  },
  {
    Pincode: 843314,
    StateName: "BIHAR",
  },
  {
    Pincode: 843315,
    StateName: "BIHAR",
  },
  {
    Pincode: 843316,
    StateName: "BIHAR",
  },
  {
    Pincode: 843318,
    StateName: "BIHAR",
  },
  {
    Pincode: 843319,
    StateName: "BIHAR",
  },
  {
    Pincode: 843320,
    StateName: "BIHAR",
  },
  {
    Pincode: 577525,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 495449,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 841419,
    StateName: "BIHAR",
  },
  {
    Pincode: 841424,
    StateName: "BIHAR",
  },
  {
    Pincode: 841417,
    StateName: "BIHAR",
  },
  {
    Pincode: 803307,
    StateName: "BIHAR",
  },
  {
    Pincode: 521126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 852133,
    StateName: "BIHAR",
  },
  {
    Pincode: 847452,
    StateName: "BIHAR",
  },
  {
    Pincode: 783382,
    StateName: "ASSAM",
  },
  {
    Pincode: 783392,
    StateName: "ASSAM",
  },
  {
    Pincode: 783383,
    StateName: "ASSAM",
  },
  {
    Pincode: 781306,
    StateName: "ASSAM",
  },
  {
    Pincode: 781343,
    StateName: "ASSAM",
  },
  {
    Pincode: 781373,
    StateName: "ASSAM",
  },
  {
    Pincode: 781347,
    StateName: "ASSAM",
  },
  {
    Pincode: 781374,
    StateName: "ASSAM",
  },
  {
    Pincode: 781348,
    StateName: "ASSAM",
  },
  {
    Pincode: 781372,
    StateName: "ASSAM",
  },
  {
    Pincode: 788011,
    StateName: "ASSAM",
  },
  {
    Pincode: 391165,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391152,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495554,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495117,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 384325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384412,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 787057,
    StateName: "ASSAM",
  },
  {
    Pincode: 384315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 532440,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535527,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532443,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532461,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532460,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 574279,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 843115,
    StateName: "BIHAR",
  },
  {
    Pincode: 842002,
    StateName: "BIHAR",
  },
  {
    Pincode: 391135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391168,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 577005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 824208,
    StateName: "BIHAR",
  },
  {
    Pincode: 824303,
    StateName: "BIHAR",
  },
  {
    Pincode: 824301,
    StateName: "BIHAR",
  },
  {
    Pincode: 824124,
    StateName: "BIHAR",
  },
  {
    Pincode: 784154,
    StateName: "ASSAM",
  },
  {
    Pincode: 784102,
    StateName: "ASSAM",
  },
  {
    Pincode: 784173,
    StateName: "ASSAM",
  },
  {
    Pincode: 784169,
    StateName: "ASSAM",
  },
  {
    Pincode: 784504,
    StateName: "ASSAM",
  },
  {
    Pincode: 784176,
    StateName: "ASSAM",
  },
  {
    Pincode: 391156,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 783381,
    StateName: "ASSAM",
  },
  {
    Pincode: 783389,
    StateName: "ASSAM",
  },
  {
    Pincode: 828121,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828402,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828115,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828117,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 813206,
    StateName: "BIHAR",
  },
  {
    Pincode: 824125,
    StateName: "BIHAR",
  },
  {
    Pincode: 786159,
    StateName: "ASSAM",
  },
  {
    Pincode: 848115,
    StateName: "BIHAR",
  },
  {
    Pincode: 848205,
    StateName: "BIHAR",
  },
  {
    Pincode: 848117,
    StateName: "BIHAR",
  },
  {
    Pincode: 848134,
    StateName: "BIHAR",
  },
  {
    Pincode: 848302,
    StateName: "BIHAR",
  },
  {
    Pincode: 848160,
    StateName: "BIHAR",
  },
  {
    Pincode: 848101,
    StateName: "BIHAR",
  },
  {
    Pincode: 848301,
    StateName: "BIHAR",
  },
  {
    Pincode: 848122,
    StateName: "BIHAR",
  },
  {
    Pincode: 848208,
    StateName: "BIHAR",
  },
  {
    Pincode: 848502,
    StateName: "BIHAR",
  },
  {
    Pincode: 848121,
    StateName: "BIHAR",
  },
  {
    Pincode: 781353,
    StateName: "ASSAM",
  },
  {
    Pincode: 781378,
    StateName: "ASSAM",
  },
  {
    Pincode: 781370,
    StateName: "ASSAM",
  },
  {
    Pincode: 781351,
    StateName: "ASSAM",
  },
  {
    Pincode: 781341,
    StateName: "ASSAM",
  },
  {
    Pincode: 788831,
    StateName: "ASSAM",
  },
  {
    Pincode: 848211,
    StateName: "BIHAR",
  },
  {
    Pincode: 848505,
    StateName: "BIHAR",
  },
  {
    Pincode: 848504,
    StateName: "BIHAR",
  },
  {
    Pincode: 854330,
    StateName: "BIHAR",
  },
  {
    Pincode: 813206,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 534186,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534112,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854317,
    StateName: "BIHAR",
  },
  {
    Pincode: 534342,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534318,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534315,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534302,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 384265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384225,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 521180,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521164,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521226,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781350,
    StateName: "ASSAM",
  },
  {
    Pincode: 781338,
    StateName: "ASSAM",
  },
  {
    Pincode: 784104,
    StateName: "ASSAM",
  },
  {
    Pincode: 786150,
    StateName: "ASSAM",
  },
  {
    Pincode: 786126,
    StateName: "ASSAM",
  },
  {
    Pincode: 533292,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533284,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533286,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533289,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533285,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533294,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533288,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 786190,
    StateName: "ASSAM",
  },
  {
    Pincode: 785105,
    StateName: "ASSAM",
  },
  {
    Pincode: 682306,
    StateName: "KERALA",
  },
  {
    Pincode: 682030,
    StateName: "KERALA",
  },
  {
    Pincode: 521247,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521230,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 134205,
    StateName: "HARYANA",
  },
  {
    Pincode: 522102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522310,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522257,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522261,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522259,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521181,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521246,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521227,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521228,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521457,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521256,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521183,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 192305,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 361335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495119,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 384530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384465,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384435,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384441,
    StateName: "GUJARAT",
  },
  {
    Pincode: 800009,
    StateName: "BIHAR",
  },
  {
    Pincode: 800007,
    StateName: "BIHAR",
  },
  {
    Pincode: 803203,
    StateName: "BIHAR",
  },
  {
    Pincode: 534305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534196,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854103,
    StateName: "BIHAR",
  },
  {
    Pincode: 855105,
    StateName: "BIHAR",
  },
  {
    Pincode: 521321,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521333,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521344,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521343,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521345,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521323,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521325,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520012,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805104,
    StateName: "BIHAR",
  },
  {
    Pincode: 805106,
    StateName: "BIHAR",
  },
  {
    Pincode: 805109,
    StateName: "BIHAR",
  },
  {
    Pincode: 805122,
    StateName: "BIHAR",
  },
  {
    Pincode: 520007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521151,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521108,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521134,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521245,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520015,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521178,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521229,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805121,
    StateName: "BIHAR",
  },
  {
    Pincode: 805123,
    StateName: "BIHAR",
  },
  {
    Pincode: 805124,
    StateName: "BIHAR",
  },
  {
    Pincode: 805125,
    StateName: "BIHAR",
  },
  {
    Pincode: 805126,
    StateName: "BIHAR",
  },
  {
    Pincode: 805127,
    StateName: "BIHAR",
  },
  {
    Pincode: 805129,
    StateName: "BIHAR",
  },
  {
    Pincode: 805130,
    StateName: "BIHAR",
  },
  {
    Pincode: 805131,
    StateName: "BIHAR",
  },
  {
    Pincode: 363435,
    StateName: "GUJARAT",
  },
  {
    Pincode: 134203,
    StateName: "HARYANA",
  },
  {
    Pincode: 134201,
    StateName: "HARYANA",
  },
  {
    Pincode: 783325,
    StateName: "ASSAM",
  },
  {
    Pincode: 521340,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 835102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533263,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533262,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533341,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533306,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533264,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533305,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 788004,
    StateName: "ASSAM",
  },
  {
    Pincode: 533340,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533234,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533125,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533295,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533293,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518533,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805107,
    StateName: "BIHAR",
  },
  {
    Pincode: 805111,
    StateName: "BIHAR",
  },
  {
    Pincode: 521107,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 782411,
    StateName: "ASSAM",
  },
  {
    Pincode: 782104,
    StateName: "ASSAM",
  },
  {
    Pincode: 782413,
    StateName: "ASSAM",
  },
  {
    Pincode: 782127,
    StateName: "ASSAM",
  },
  {
    Pincode: 782126,
    StateName: "ASSAM",
  },
  {
    Pincode: 782105,
    StateName: "ASSAM",
  },
  {
    Pincode: 785616,
    StateName: "ASSAM",
  },
  {
    Pincode: 782440,
    StateName: "ASSAM",
  },
  {
    Pincode: 521104,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521182,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805112,
    StateName: "BIHAR",
  },
  {
    Pincode: 533233,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 384229,
    StateName: "GUJARAT",
  },
  {
    Pincode: 824207,
    StateName: "BIHAR",
  },
  {
    Pincode: 824211,
    StateName: "BIHAR",
  },
  {
    Pincode: 384290,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384255,
    StateName: "GUJARAT",
  },
  {
    Pincode: 824210,
    StateName: "BIHAR",
  },
  {
    Pincode: 521329,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521390,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521326,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521327,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521263,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854104,
    StateName: "BIHAR",
  },
  {
    Pincode: 854106,
    StateName: "BIHAR",
  },
  {
    Pincode: 854107,
    StateName: "BIHAR",
  },
  {
    Pincode: 855107,
    StateName: "BIHAR",
  },
  {
    Pincode: 360530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361011,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361013,
    StateName: "GUJARAT",
  },
  {
    Pincode: 533309,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533308,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533345,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533256,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533346,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805128,
    StateName: "BIHAR",
  },
  {
    Pincode: 391110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 788710,
    StateName: "ASSAM",
  },
  {
    Pincode: 391107,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 782124,
    StateName: "ASSAM",
  },
  {
    Pincode: 782426,
    StateName: "ASSAM",
  },
  {
    Pincode: 782101,
    StateName: "ASSAM",
  },
  {
    Pincode: 782427,
    StateName: "ASSAM",
  },
  {
    Pincode: 785681,
    StateName: "ASSAM",
  },
  {
    Pincode: 785680,
    StateName: "ASSAM",
  },
  {
    Pincode: 785661,
    StateName: "ASSAM",
  },
  {
    Pincode: 785662,
    StateName: "ASSAM",
  },
  {
    Pincode: 782106,
    StateName: "ASSAM",
  },
  {
    Pincode: 788006,
    StateName: "ASSAM",
  },
  {
    Pincode: 517167,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517429,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517127,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517132,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517126,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517422,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517370,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517351,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 785014,
    StateName: "ASSAM",
  },
  {
    Pincode: 785636,
    StateName: "ASSAM",
  },
  {
    Pincode: 785112,
    StateName: "ASSAM",
  },
  {
    Pincode: 785630,
    StateName: "ASSAM",
  },
  {
    Pincode: 785691,
    StateName: "ASSAM",
  },
  {
    Pincode: 785683,
    StateName: "ASSAM",
  },
  {
    Pincode: 523315,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523373,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533006,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521322,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 801505,
    StateName: "BIHAR",
  },
  {
    Pincode: 801109,
    StateName: "BIHAR",
  },
  {
    Pincode: 801110,
    StateName: "BIHAR",
  },
  {
    Pincode: 847305,
    StateName: "BIHAR",
  },
  {
    Pincode: 805113,
    StateName: "BIHAR",
  },
  {
    Pincode: 805103,
    StateName: "BIHAR",
  },
  {
    Pincode: 805108,
    StateName: "BIHAR",
  },
  {
    Pincode: 521148,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521261,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 805105,
    StateName: "BIHAR",
  },
  {
    Pincode: 848206,
    StateName: "BIHAR",
  },
  {
    Pincode: 848213,
    StateName: "BIHAR",
  },
  {
    Pincode: 854332,
    StateName: "BIHAR",
  },
  {
    Pincode: 854333,
    StateName: "BIHAR",
  },
  {
    Pincode: 532456,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532445,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532484,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532459,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532214,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854331,
    StateName: "BIHAR",
  },
  {
    Pincode: 533106,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 824102,
    StateName: "BIHAR",
  },
  {
    Pincode: 824129,
    StateName: "BIHAR",
  },
  {
    Pincode: 824115,
    StateName: "BIHAR",
  },
  {
    Pincode: 824302,
    StateName: "BIHAR",
  },
  {
    Pincode: 824103,
    StateName: "BIHAR",
  },
  {
    Pincode: 848501,
    StateName: "BIHAR",
  },
  {
    Pincode: 521212,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521286,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521241,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521170,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517419,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522111,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522262,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522315,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522268,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522314,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522316,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522329,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522317,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522318,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522256,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522330,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 843317,
    StateName: "BIHAR",
  },
  {
    Pincode: 517403,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517421,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517599,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517319,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517257,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517425,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 384430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384565,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841442,
    StateName: "BIHAR",
  },
  {
    Pincode: 824221,
    StateName: "BIHAR",
  },
  {
    Pincode: 802301,
    StateName: "BIHAR",
  },
  {
    Pincode: 841218,
    StateName: "BIHAR",
  },
  {
    Pincode: 841421,
    StateName: "BIHAR",
  },
  {
    Pincode: 845427,
    StateName: "BIHAR",
  },
  {
    Pincode: 845457,
    StateName: "BIHAR",
  },
  {
    Pincode: 845431,
    StateName: "BIHAR",
  },
  {
    Pincode: 845428,
    StateName: "BIHAR",
  },
  {
    Pincode: 845430,
    StateName: "BIHAR",
  },
  {
    Pincode: 845429,
    StateName: "BIHAR",
  },
  {
    Pincode: 845406,
    StateName: "BIHAR",
  },
  {
    Pincode: 518464,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518405,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532427,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533210,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533241,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531060,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531032,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531082,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531029,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 361280,
    StateName: "GUJARAT",
  },
  {
    Pincode: 855108,
    StateName: "BIHAR",
  },
  {
    Pincode: 522325,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522264,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522312,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534156,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534313,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 495553,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 785693,
    StateName: "ASSAM",
  },
  {
    Pincode: 782412,
    StateName: "ASSAM",
  },
  {
    Pincode: 786007,
    StateName: "ASSAM",
  },
  {
    Pincode: 522308,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522307,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505325,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 787035,
    StateName: "ASSAM",
  },
  {
    Pincode: 787053,
    StateName: "ASSAM",
  },
  {
    Pincode: 787034,
    StateName: "ASSAM",
  },
  {
    Pincode: 782137,
    StateName: "ASSAM",
  },
  {
    Pincode: 782138,
    StateName: "ASSAM",
  },
  {
    Pincode: 785664,
    StateName: "ASSAM",
  },
  {
    Pincode: 534331,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534329,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 785696,
    StateName: "ASSAM",
  },
  {
    Pincode: 533437,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533449,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533444,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533450,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515731,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515722,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515761,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515765,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535557,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 828131,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828304,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 535573,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535578,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 535102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515721,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515711,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515766,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515425,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515787,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515414,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 847401,
    StateName: "BIHAR",
  },
  {
    Pincode: 847222,
    StateName: "BIHAR",
  },
  {
    Pincode: 847224,
    StateName: "BIHAR",
  },
  {
    Pincode: 847102,
    StateName: "BIHAR",
  },
  {
    Pincode: 847225,
    StateName: "BIHAR",
  },
  {
    Pincode: 847223,
    StateName: "BIHAR",
  },
  {
    Pincode: 847212,
    StateName: "BIHAR",
  },
  {
    Pincode: 843131,
    StateName: "BIHAR",
  },
  {
    Pincode: 517417,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517426,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517350,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518468,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518598,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518010,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 788002,
    StateName: "ASSAM",
  },
  {
    Pincode: 788099,
    StateName: "ASSAM",
  },
  {
    Pincode: 788015,
    StateName: "ASSAM",
  },
  {
    Pincode: 788115,
    StateName: "ASSAM",
  },
  {
    Pincode: 788098,
    StateName: "ASSAM",
  },
  {
    Pincode: 788116,
    StateName: "ASSAM",
  },
  {
    Pincode: 788005,
    StateName: "ASSAM",
  },
  {
    Pincode: 788013,
    StateName: "ASSAM",
  },
  {
    Pincode: 788119,
    StateName: "ASSAM",
  },
  {
    Pincode: 788026,
    StateName: "ASSAM",
  },
  {
    Pincode: 788003,
    StateName: "ASSAM",
  },
  {
    Pincode: 788030,
    StateName: "ASSAM",
  },
  {
    Pincode: 788931,
    StateName: "ASSAM",
  },
  {
    Pincode: 785663,
    StateName: "ASSAM",
  },
  {
    Pincode: 785666,
    StateName: "ASSAM",
  },
  {
    Pincode: 515767,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515435,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 824112,
    StateName: "BIHAR",
  },
  {
    Pincode: 824113,
    StateName: "BIHAR",
  },
  {
    Pincode: 847402,
    StateName: "BIHAR",
  },
  {
    Pincode: 847226,
    StateName: "BIHAR",
  },
  {
    Pincode: 847403,
    StateName: "BIHAR",
  },
  {
    Pincode: 847229,
    StateName: "BIHAR",
  },
  {
    Pincode: 847228,
    StateName: "BIHAR",
  },
  {
    Pincode: 848102,
    StateName: "BIHAR",
  },
  {
    Pincode: 523186,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523260,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531020,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825322,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533432,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533434,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533483,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533429,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 824143,
    StateName: "BIHAR",
  },
  {
    Pincode: 824202,
    StateName: "BIHAR",
  },
  {
    Pincode: 847404,
    StateName: "BIHAR",
  },
  {
    Pincode: 788009,
    StateName: "ASSAM",
  },
  {
    Pincode: 784145,
    StateName: "ASSAM",
  },
  {
    Pincode: 384230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 801108,
    StateName: "BIHAR",
  },
  {
    Pincode: 384550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785701,
    StateName: "ASSAM",
  },
  {
    Pincode: 785665,
    StateName: "ASSAM",
  },
  {
    Pincode: 784164,
    StateName: "ASSAM",
  },
  {
    Pincode: 787054,
    StateName: "ASSAM",
  },
  {
    Pincode: 533339,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 784525,
    StateName: "ASSAM",
  },
  {
    Pincode: 784529,
    StateName: "ASSAM",
  },
  {
    Pincode: 784116,
    StateName: "ASSAM",
  },
  {
    Pincode: 784147,
    StateName: "ASSAM",
  },
  {
    Pincode: 784148,
    StateName: "ASSAM",
  },
  {
    Pincode: 784510,
    StateName: "ASSAM",
  },
  {
    Pincode: 784115,
    StateName: "ASSAM",
  },
  {
    Pincode: 394540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825314,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825408,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825403,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825315,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825404,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 524124,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 828142,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533448,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 788007,
    StateName: "ASSAM",
  },
  {
    Pincode: 784527,
    StateName: "ASSAM",
  },
  {
    Pincode: 784190,
    StateName: "ASSAM",
  },
  {
    Pincode: 784526,
    StateName: "ASSAM",
  },
  {
    Pincode: 518221,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 855117,
    StateName: "BIHAR",
  },
  {
    Pincode: 855116,
    StateName: "BIHAR",
  },
  {
    Pincode: 855115,
    StateName: "BIHAR",
  },
  {
    Pincode: 534198,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 824114,
    StateName: "BIHAR",
  },
  {
    Pincode: 824203,
    StateName: "BIHAR",
  },
  {
    Pincode: 824120,
    StateName: "BIHAR",
  },
  {
    Pincode: 824122,
    StateName: "BIHAR",
  },
  {
    Pincode: 824121,
    StateName: "BIHAR",
  },
  {
    Pincode: 385310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 385566,
    StateName: "GUJARAT",
  },
  {
    Pincode: 784524,
    StateName: "ASSAM",
  },
  {
    Pincode: 784522,
    StateName: "ASSAM",
  },
  {
    Pincode: 788031,
    StateName: "ASSAM",
  },
  {
    Pincode: 788010,
    StateName: "ASSAM",
  },
  {
    Pincode: 784146,
    StateName: "ASSAM",
  },
  {
    Pincode: 534145,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534341,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176061,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176082,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176125,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176073,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176067,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 518442,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781328,
    StateName: "ASSAM",
  },
  {
    Pincode: 781315,
    StateName: "ASSAM",
  },
  {
    Pincode: 781309,
    StateName: "ASSAM",
  },
  {
    Pincode: 781352,
    StateName: "ASSAM",
  },
  {
    Pincode: 515465,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515822,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515402,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515863,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515774,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825406,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825313,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825418,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825402,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825109,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825409,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825410,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825303,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825318,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825319,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825132,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 387305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387635,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176083,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 781128,
    StateName: "ASSAM",
  },
  {
    Pincode: 781136,
    StateName: "ASSAM",
  },
  {
    Pincode: 781354,
    StateName: "ASSAM",
  },
  {
    Pincode: 781103,
    StateName: "ASSAM",
  },
  {
    Pincode: 781382,
    StateName: "ASSAM",
  },
  {
    Pincode: 781131,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 176088,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176092,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176086,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176098,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176096,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 821104,
    StateName: "BIHAR",
  },
  {
    Pincode: 821305,
    StateName: "BIHAR",
  },
  {
    Pincode: 802226,
    StateName: "BIHAR",
  },
  {
    Pincode: 802213,
    StateName: "BIHAR",
  },
  {
    Pincode: 802215,
    StateName: "BIHAR",
  },
  {
    Pincode: 824127,
    StateName: "BIHAR",
  },
  {
    Pincode: 387610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 824220,
    StateName: "BIHAR",
  },
  {
    Pincode: 824205,
    StateName: "BIHAR",
  },
  {
    Pincode: 824206,
    StateName: "BIHAR",
  },
  {
    Pincode: 802214,
    StateName: "BIHAR",
  },
  {
    Pincode: 821106,
    StateName: "BIHAR",
  },
  {
    Pincode: 821107,
    StateName: "BIHAR",
  },
  {
    Pincode: 821112,
    StateName: "BIHAR",
  },
  {
    Pincode: 821108,
    StateName: "BIHAR",
  },
  {
    Pincode: 788104,
    StateName: "ASSAM",
  },
  {
    Pincode: 814114,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 174305,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174309,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174304,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177024,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176041,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176040,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 787060,
    StateName: "ASSAM",
  },
  {
    Pincode: 176039,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 784113,
    StateName: "ASSAM",
  },
  {
    Pincode: 383275,
    StateName: "GUJARAT",
  },
  {
    Pincode: 801102,
    StateName: "BIHAR",
  },
  {
    Pincode: 176103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176115,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176081,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176076,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176071,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176077,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176128,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176095,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176094,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 494337,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494771,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 383316,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383251,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841422,
    StateName: "BIHAR",
  },
  {
    Pincode: 841221,
    StateName: "BIHAR",
  },
  {
    Pincode: 841101,
    StateName: "BIHAR",
  },
  {
    Pincode: 841222,
    StateName: "BIHAR",
  },
  {
    Pincode: 841401,
    StateName: "BIHAR",
  },
  {
    Pincode: 841402,
    StateName: "BIHAR",
  },
  {
    Pincode: 841460,
    StateName: "BIHAR",
  },
  {
    Pincode: 841410,
    StateName: "BIHAR",
  },
  {
    Pincode: 494447,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494450,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 821109,
    StateName: "BIHAR",
  },
  {
    Pincode: 821310,
    StateName: "BIHAR",
  },
  {
    Pincode: 802218,
    StateName: "BIHAR",
  },
  {
    Pincode: 821306,
    StateName: "BIHAR",
  },
  {
    Pincode: 821105,
    StateName: "BIHAR",
  },
  {
    Pincode: 801304,
    StateName: "BIHAR",
  },
  {
    Pincode: 803302,
    StateName: "BIHAR",
  },
  {
    Pincode: 841443,
    StateName: "BIHAR",
  },
  {
    Pincode: 841411,
    StateName: "BIHAR",
  },
  {
    Pincode: 821308,
    StateName: "BIHAR",
  },
  {
    Pincode: 821309,
    StateName: "BIHAR",
  },
  {
    Pincode: 841414,
    StateName: "BIHAR",
  },
  {
    Pincode: 811212,
    StateName: "BIHAR",
  },
  {
    Pincode: 811213,
    StateName: "BIHAR",
  },
  {
    Pincode: 811214,
    StateName: "BIHAR",
  },
  {
    Pincode: 801302,
    StateName: "BIHAR",
  },
  {
    Pincode: 801303,
    StateName: "BIHAR",
  },
  {
    Pincode: 534340,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534111,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 388365,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392035,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392025,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392180,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388215,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 133302,
    StateName: "HARYANA",
  },
  {
    Pincode: 362120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362205,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362255,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362275,
    StateName: "GUJARAT",
  },
  {
    Pincode: 787031,
    StateName: "ASSAM",
  },
  {
    Pincode: 784160,
    StateName: "ASSAM",
  },
  {
    Pincode: 787051,
    StateName: "ASSAM",
  },
  {
    Pincode: 787058,
    StateName: "ASSAM",
  },
  {
    Pincode: 787032,
    StateName: "ASSAM",
  },
  {
    Pincode: 523117,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523228,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854312,
    StateName: "BIHAR",
  },
  {
    Pincode: 393050,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393025,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388713,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176063,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176093,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176102,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176084,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176087,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176091,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176085,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176107,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 494553,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494222,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494223,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494224,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 811102,
    StateName: "BIHAR",
  },
  {
    Pincode: 811103,
    StateName: "BIHAR",
  },
  {
    Pincode: 811105,
    StateName: "BIHAR",
  },
  {
    Pincode: 802216,
    StateName: "BIHAR",
  },
  {
    Pincode: 802217,
    StateName: "BIHAR",
  },
  {
    Pincode: 814120,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814133,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 841415,
    StateName: "BIHAR",
  },
  {
    Pincode: 360540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 854116,
    StateName: "BIHAR",
  },
  {
    Pincode: 854108,
    StateName: "BIHAR",
  },
  {
    Pincode: 854113,
    StateName: "BIHAR",
  },
  {
    Pincode: 854114,
    StateName: "BIHAR",
  },
  {
    Pincode: 854117,
    StateName: "BIHAR",
  },
  {
    Pincode: 855113,
    StateName: "BIHAR",
  },
  {
    Pincode: 854115,
    StateName: "BIHAR",
  },
  {
    Pincode: 855114,
    StateName: "BIHAR",
  },
  {
    Pincode: 811107,
    StateName: "BIHAR",
  },
  {
    Pincode: 804403,
    StateName: "BIHAR",
  },
  {
    Pincode: 804407,
    StateName: "BIHAR",
  },
  {
    Pincode: 491995,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 825407,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 811309,
    StateName: "BIHAR",
  },
  {
    Pincode: 811310,
    StateName: "BIHAR",
  },
  {
    Pincode: 811311,
    StateName: "BIHAR",
  },
  {
    Pincode: 811104,
    StateName: "BIHAR",
  },
  {
    Pincode: 803301,
    StateName: "BIHAR",
  },
  {
    Pincode: 811106,
    StateName: "BIHAR",
  },
  {
    Pincode: 803107,
    StateName: "BIHAR",
  },
  {
    Pincode: 815312,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815318,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 795114,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795005,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795008,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795104,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795125,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795141,
    StateName: "MANIPUR",
  },
  {
    Pincode: 522611,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522234,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522615,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522660,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522649,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521139,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 782410,
    StateName: "ASSAM",
  },
  {
    Pincode: 123411,
    StateName: "HARYANA",
  },
  {
    Pincode: 123301,
    StateName: "HARYANA",
  },
  {
    Pincode: 123103,
    StateName: "HARYANA",
  },
  {
    Pincode: 785632,
    StateName: "ASSAM",
  },
  {
    Pincode: 123106,
    StateName: "HARYANA",
  },
  {
    Pincode: 177118,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177119,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177028,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177040,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177025,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177041,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 123302,
    StateName: "HARYANA",
  },
  {
    Pincode: 123101,
    StateName: "HARYANA",
  },
  {
    Pincode: 177026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174405,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176048,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177020,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177033,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 811112,
    StateName: "BIHAR",
  },
  {
    Pincode: 803120,
    StateName: "BIHAR",
  },
  {
    Pincode: 522529,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522603,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522617,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522549,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522647,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522411,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522414,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 795116,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795117,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795139,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795101,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795135,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795006,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795136,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795159,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795118,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795106,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795112,
    StateName: "MANIPUR",
  },
  {
    Pincode: 491222,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494331,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 823311,
    StateName: "BIHAR",
  },
  {
    Pincode: 174312,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 824231,
    StateName: "BIHAR",
  },
  {
    Pincode: 823003,
    StateName: "BIHAR",
  },
  {
    Pincode: 804404,
    StateName: "BIHAR",
  },
  {
    Pincode: 824237,
    StateName: "BIHAR",
  },
  {
    Pincode: 823004,
    StateName: "BIHAR",
  },
  {
    Pincode: 824232,
    StateName: "BIHAR",
  },
  {
    Pincode: 823002,
    StateName: "BIHAR",
  },
  {
    Pincode: 824118,
    StateName: "BIHAR",
  },
  {
    Pincode: 824233,
    StateName: "BIHAR",
  },
  {
    Pincode: 795147,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795126,
    StateName: "MANIPUR",
  },
  {
    Pincode: 841406,
    StateName: "BIHAR",
  },
  {
    Pincode: 841408,
    StateName: "BIHAR",
  },
  {
    Pincode: 383462,
    StateName: "GUJARAT",
  },
  {
    Pincode: 795105,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795144,
    StateName: "MANIPUR",
  },
  {
    Pincode: 522657,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522437,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 802134,
    StateName: "BIHAR",
  },
  {
    Pincode: 522661,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522626,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 815314,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815313,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825167,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 841203,
    StateName: "BIHAR",
  },
  {
    Pincode: 841234,
    StateName: "BIHAR",
  },
  {
    Pincode: 841233,
    StateName: "BIHAR",
  },
  {
    Pincode: 841235,
    StateName: "BIHAR",
  },
  {
    Pincode: 389220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392011,
    StateName: "GUJARAT",
  },
  {
    Pincode: 828403,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829111,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829112,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829132,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829107,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815311,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 824234,
    StateName: "BIHAR",
  },
  {
    Pincode: 824235,
    StateName: "BIHAR",
  },
  {
    Pincode: 824209,
    StateName: "BIHAR",
  },
  {
    Pincode: 387520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841210,
    StateName: "BIHAR",
  },
  {
    Pincode: 493661,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 802135,
    StateName: "BIHAR",
  },
  {
    Pincode: 493225,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493526,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493445,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493111,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492101,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493114,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493228,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493551,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 535522,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532462,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 389390,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388710,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 688006,
    StateName: "KERALA",
  },
  {
    Pincode: 688505,
    StateName: "KERALA",
  },
  {
    Pincode: 688501,
    StateName: "KERALA",
  },
  {
    Pincode: 688522,
    StateName: "KERALA",
  },
  {
    Pincode: 688506,
    StateName: "KERALA",
  },
  {
    Pincode: 688538,
    StateName: "KERALA",
  },
  {
    Pincode: 688503,
    StateName: "KERALA",
  },
  {
    Pincode: 688504,
    StateName: "KERALA",
  },
  {
    Pincode: 800008,
    StateName: "BIHAR",
  },
  {
    Pincode: 814113,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814142,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814157,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815351,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 523111,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523108,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176097,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176089,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174505,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177401,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177042,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 123412,
    StateName: "HARYANA",
  },
  {
    Pincode: 370490,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396051,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396045,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 492015,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 505187,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 389230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370627,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370455,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370465,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389235,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388270,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825401,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825312,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 852214,
    StateName: "BIHAR",
  },
  {
    Pincode: 852111,
    StateName: "BIHAR",
  },
  {
    Pincode: 815315,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 533005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533344,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533468,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533461,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 370415,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370485,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370641,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370605,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370645,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370105,
    StateName: "GUJARAT",
  },
  {
    Pincode: 782410,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 123027,
    StateName: "HARYANA",
  },
  {
    Pincode: 177007,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176109,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 394510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394517,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395009,
    StateName: "GUJARAT",
  },
  {
    Pincode: 495446,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495450,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 781305,
    StateName: "ASSAM",
  },
  {
    Pincode: 781327,
    StateName: "ASSAM",
  },
  {
    Pincode: 781319,
    StateName: "ASSAM",
  },
  {
    Pincode: 781330,
    StateName: "ASSAM",
  },
  {
    Pincode: 781308,
    StateName: "ASSAM",
  },
  {
    Pincode: 682021,
    StateName: "KERALA",
  },
  {
    Pincode: 683501,
    StateName: "KERALA",
  },
  {
    Pincode: 682008,
    StateName: "KERALA",
  },
  {
    Pincode: 683511,
    StateName: "KERALA",
  },
  {
    Pincode: 683101,
    StateName: "KERALA",
  },
  {
    Pincode: 683572,
    StateName: "KERALA",
  },
  {
    Pincode: 683585,
    StateName: "KERALA",
  },
  {
    Pincode: 683578,
    StateName: "KERALA",
  },
  {
    Pincode: 683561,
    StateName: "KERALA",
  },
  {
    Pincode: 683574,
    StateName: "KERALA",
  },
  {
    Pincode: 683575,
    StateName: "KERALA",
  },
  {
    Pincode: 683579,
    StateName: "KERALA",
  },
  {
    Pincode: 683581,
    StateName: "KERALA",
  },
  {
    Pincode: 683594,
    StateName: "KERALA",
  },
  {
    Pincode: 370475,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370670,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394235,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 852215,
    StateName: "BIHAR",
  },
  {
    Pincode: 852112,
    StateName: "BIHAR",
  },
  {
    Pincode: 852113,
    StateName: "BIHAR",
  },
  {
    Pincode: 852216,
    StateName: "BIHAR",
  },
  {
    Pincode: 852217,
    StateName: "BIHAR",
  },
  {
    Pincode: 533201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533239,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533577,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533235,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532428,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532421,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532190,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532213,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 505503,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825311,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 784513,
    StateName: "ASSAM",
  },
  {
    Pincode: 495454,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 825323,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825336,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825317,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 493331,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 571101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 493663,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493662,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493888,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493778,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492109,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493992,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493885,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 845449,
    StateName: "BIHAR",
  },
  {
    Pincode: 845450,
    StateName: "BIHAR",
  },
  {
    Pincode: 845451,
    StateName: "BIHAR",
  },
  {
    Pincode: 845103,
    StateName: "BIHAR",
  },
  {
    Pincode: 845459,
    StateName: "BIHAR",
  },
  {
    Pincode: 845452,
    StateName: "BIHAR",
  },
  {
    Pincode: 845453,
    StateName: "BIHAR",
  },
  {
    Pincode: 845104,
    StateName: "BIHAR",
  },
  {
    Pincode: 845306,
    StateName: "BIHAR",
  },
  {
    Pincode: 845454,
    StateName: "BIHAR",
  },
  {
    Pincode: 845105,
    StateName: "BIHAR",
  },
  {
    Pincode: 845455,
    StateName: "BIHAR",
  },
  {
    Pincode: 845106,
    StateName: "BIHAR",
  },
  {
    Pincode: 591115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591153,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 532429,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 491444,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491881,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495692,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495688,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 829114,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829104,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 841509,
    StateName: "BIHAR",
  },
  {
    Pincode: 829113,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 495690,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 571440,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 491229,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491666,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 570008,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570019,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 491559,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497335,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497119,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 835204,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 571103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570026,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571617,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 688561,
    StateName: "KERALA",
  },
  {
    Pincode: 688502,
    StateName: "KERALA",
  },
  {
    Pincode: 688562,
    StateName: "KERALA",
  },
  {
    Pincode: 688002,
    StateName: "KERALA",
  },
  {
    Pincode: 688524,
    StateName: "KERALA",
  },
  {
    Pincode: 688530,
    StateName: "KERALA",
  },
  {
    Pincode: 688539,
    StateName: "KERALA",
  },
  {
    Pincode: 571610,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571601,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570018,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 685586,
    StateName: "KERALA",
  },
  {
    Pincode: 685590,
    StateName: "KERALA",
  },
  {
    Pincode: 685620,
    StateName: "KERALA",
  },
  {
    Pincode: 685616,
    StateName: "KERALA",
  },
  {
    Pincode: 685612,
    StateName: "KERALA",
  },
  {
    Pincode: 685605,
    StateName: "KERALA",
  },
  {
    Pincode: 685571,
    StateName: "KERALA",
  },
  {
    Pincode: 685566,
    StateName: "KERALA",
  },
  {
    Pincode: 685582,
    StateName: "KERALA",
  },
  {
    Pincode: 685589,
    StateName: "KERALA",
  },
  {
    Pincode: 685587,
    StateName: "KERALA",
  },
  {
    Pincode: 571105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571311,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571187,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 852122,
    StateName: "BIHAR",
  },
  {
    Pincode: 852123,
    StateName: "BIHAR",
  },
  {
    Pincode: 852124,
    StateName: "BIHAR",
  },
  {
    Pincode: 852202,
    StateName: "BIHAR",
  },
  {
    Pincode: 571602,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 803109,
    StateName: "BIHAR",
  },
  {
    Pincode: 854311,
    StateName: "BIHAR",
  },
  {
    Pincode: 854202,
    StateName: "BIHAR",
  },
  {
    Pincode: 854316,
    StateName: "BIHAR",
  },
  {
    Pincode: 571108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571604,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 854318,
    StateName: "BIHAR",
  },
  {
    Pincode: 854201,
    StateName: "BIHAR",
  },
  {
    Pincode: 854315,
    StateName: "BIHAR",
  },
  {
    Pincode: 854334,
    StateName: "BIHAR",
  },
  {
    Pincode: 854304,
    StateName: "BIHAR",
  },
  {
    Pincode: 854303,
    StateName: "BIHAR",
  },
  {
    Pincode: 562120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562159,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562162,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 124412,
    StateName: "HARYANA",
  },
  {
    Pincode: 562121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 835101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835234,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835209,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835210,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835216,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834004,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834003,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 811201,
    StateName: "BIHAR",
  },
  {
    Pincode: 571130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 124113,
    StateName: "HARYANA",
  },
  {
    Pincode: 124112,
    StateName: "HARYANA",
  },
  {
    Pincode: 124022,
    StateName: "HARYANA",
  },
  {
    Pincode: 124010,
    StateName: "HARYANA",
  },
  {
    Pincode: 124303,
    StateName: "HARYANA",
  },
  {
    Pincode: 562125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 801306,
    StateName: "BIHAR",
  },
  {
    Pincode: 124514,
    StateName: "HARYANA",
  },
  {
    Pincode: 124111,
    StateName: "HARYANA",
  },
  {
    Pincode: 364521,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365535,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365635,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365730,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364515,
    StateName: "GUJARAT",
  },
  {
    Pincode: 494449,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 591118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 688526,
    StateName: "KERALA",
  },
  {
    Pincode: 688523,
    StateName: "KERALA",
  },
  {
    Pincode: 688527,
    StateName: "KERALA",
  },
  {
    Pincode: 688532,
    StateName: "KERALA",
  },
  {
    Pincode: 785007,
    StateName: "ASSAM",
  },
  {
    Pincode: 785006,
    StateName: "ASSAM",
  },
  {
    Pincode: 785107,
    StateName: "ASSAM",
  },
  {
    Pincode: 785108,
    StateName: "ASSAM",
  },
  {
    Pincode: 785633,
    StateName: "ASSAM",
  },
  {
    Pincode: 785700,
    StateName: "ASSAM",
  },
  {
    Pincode: 785634,
    StateName: "ASSAM",
  },
  {
    Pincode: 688009,
    StateName: "KERALA",
  },
  {
    Pincode: 362135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 182313,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182143,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182222,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182312,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182301,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182204,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182315,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 688537,
    StateName: "KERALA",
  },
  {
    Pincode: 688003,
    StateName: "KERALA",
  },
  {
    Pincode: 370001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370155,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370205,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370427,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 852121,
    StateName: "BIHAR",
  },
  {
    Pincode: 852218,
    StateName: "BIHAR",
  },
  {
    Pincode: 852125,
    StateName: "BIHAR",
  },
  {
    Pincode: 852116,
    StateName: "BIHAR",
  },
  {
    Pincode: 182205,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185203,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182141,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 125047,
    StateName: "HARYANA",
  },
  {
    Pincode: 125007,
    StateName: "HARYANA",
  },
  {
    Pincode: 125121,
    StateName: "HARYANA",
  },
  {
    Pincode: 125042,
    StateName: "HARYANA",
  },
  {
    Pincode: 362630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 182124,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182142,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 683580,
    StateName: "KERALA",
  },
  {
    Pincode: 686665,
    StateName: "KERALA",
  },
  {
    Pincode: 686668,
    StateName: "KERALA",
  },
  {
    Pincode: 682311,
    StateName: "KERALA",
  },
  {
    Pincode: 686662,
    StateName: "KERALA",
  },
  {
    Pincode: 686673,
    StateName: "KERALA",
  },
  {
    Pincode: 682301,
    StateName: "KERALA",
  },
  {
    Pincode: 682307,
    StateName: "KERALA",
  },
  {
    Pincode: 683519,
    StateName: "KERALA",
  },
  {
    Pincode: 683562,
    StateName: "KERALA",
  },
  {
    Pincode: 683518,
    StateName: "KERALA",
  },
  {
    Pincode: 532430,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781012,
    StateName: "ASSAM",
  },
  {
    Pincode: 683520,
    StateName: "KERALA",
  },
  {
    Pincode: 683577,
    StateName: "KERALA",
  },
  {
    Pincode: 683516,
    StateName: "KERALA",
  },
  {
    Pincode: 683110,
    StateName: "KERALA",
  },
  {
    Pincode: 683102,
    StateName: "KERALA",
  },
  {
    Pincode: 686672,
    StateName: "KERALA",
  },
  {
    Pincode: 682316,
    StateName: "KERALA",
  },
  {
    Pincode: 686669,
    StateName: "KERALA",
  },
  {
    Pincode: 123102,
    StateName: "HARYANA",
  },
  {
    Pincode: 123303,
    StateName: "HARYANA",
  },
  {
    Pincode: 123035,
    StateName: "HARYANA",
  },
  {
    Pincode: 123401,
    StateName: "HARYANA",
  },
  {
    Pincode: 682310,
    StateName: "KERALA",
  },
  {
    Pincode: 182127,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182128,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182125,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 131001,
    StateName: "HARYANA",
  },
  {
    Pincode: 182146,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182148,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 362230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 131021,
    StateName: "HARYANA",
  },
  {
    Pincode: 182147,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 852212,
    StateName: "BIHAR",
  },
  {
    Pincode: 813102,
    StateName: "BIHAR",
  },
  {
    Pincode: 683576,
    StateName: "KERALA",
  },
  {
    Pincode: 683565,
    StateName: "KERALA",
  },
  {
    Pincode: 683513,
    StateName: "KERALA",
  },
  {
    Pincode: 854337,
    StateName: "BIHAR",
  },
  {
    Pincode: 854306,
    StateName: "BIHAR",
  },
  {
    Pincode: 854203,
    StateName: "BIHAR",
  },
  {
    Pincode: 854204,
    StateName: "BIHAR",
  },
  {
    Pincode: 805132,
    StateName: "BIHAR",
  },
  {
    Pincode: 805102,
    StateName: "BIHAR",
  },
  {
    Pincode: 805101,
    StateName: "BIHAR",
  },
  {
    Pincode: 854339,
    StateName: "BIHAR",
  },
  {
    Pincode: 852101,
    StateName: "BIHAR",
  },
  {
    Pincode: 803303,
    StateName: "BIHAR",
  },
  {
    Pincode: 781316,
    StateName: "ASSAM",
  },
  {
    Pincode: 781321,
    StateName: "ASSAM",
  },
  {
    Pincode: 781311,
    StateName: "ASSAM",
  },
  {
    Pincode: 383205,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383270,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383276,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383246,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383215,
    StateName: "GUJARAT",
  },
  {
    Pincode: 803221,
    StateName: "BIHAR",
  },
  {
    Pincode: 803306,
    StateName: "BIHAR",
  },
  {
    Pincode: 801305,
    StateName: "BIHAR",
  },
  {
    Pincode: 803111,
    StateName: "BIHAR",
  },
  {
    Pincode: 533252,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 781365,
    StateName: "ASSAM",
  },
  {
    Pincode: 533240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 175132,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 829121,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835214,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834012,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835219,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835303,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834009,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835222,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 175140,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175139,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175142,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175106,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175123,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 781132,
    StateName: "ASSAM",
  },
  {
    Pincode: 781135,
    StateName: "ASSAM",
  },
  {
    Pincode: 532426,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532425,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532186,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532408,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532407,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532403,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532168,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 494122,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494441,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494556,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 494114,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 571603,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571189,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 532409,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532127,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532402,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532474,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 815317,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 815316,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825324,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 182144,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 532401,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532406,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532410,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532405,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825108,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 365480,
    StateName: "GUJARAT",
  },
  {
    Pincode: 365456,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364525,
    StateName: "GUJARAT",
  },
  {
    Pincode: 124406,
    StateName: "HARYANA",
  },
  {
    Pincode: 124102,
    StateName: "HARYANA",
  },
  {
    Pincode: 124103,
    StateName: "HARYANA",
  },
  {
    Pincode: 124106,
    StateName: "HARYANA",
  },
  {
    Pincode: 175101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 124104,
    StateName: "HARYANA",
  },
  {
    Pincode: 124146,
    StateName: "HARYANA",
  },
  {
    Pincode: 124108,
    StateName: "HARYANA",
  },
  {
    Pincode: 383230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 683105,
    StateName: "KERALA",
  },
  {
    Pincode: 683522,
    StateName: "KERALA",
  },
  {
    Pincode: 123021,
    StateName: "HARYANA",
  },
  {
    Pincode: 123029,
    StateName: "HARYANA",
  },
  {
    Pincode: 688529,
    StateName: "KERALA",
  },
  {
    Pincode: 688531,
    StateName: "KERALA",
  },
  {
    Pincode: 688540,
    StateName: "KERALA",
  },
  {
    Pincode: 785110,
    StateName: "ASSAM",
  },
  {
    Pincode: 123023,
    StateName: "HARYANA",
  },
  {
    Pincode: 123024,
    StateName: "HARYANA",
  },
  {
    Pincode: 811304,
    StateName: "BIHAR",
  },
  {
    Pincode: 854325,
    StateName: "BIHAR",
  },
  {
    Pincode: 854328,
    StateName: "BIHAR",
  },
  {
    Pincode: 854329,
    StateName: "BIHAR",
  },
  {
    Pincode: 854335,
    StateName: "BIHAR",
  },
  {
    Pincode: 854336,
    StateName: "BIHAR",
  },
  {
    Pincode: 781329,
    StateName: "ASSAM",
  },
  {
    Pincode: 781325,
    StateName: "ASSAM",
  },
  {
    Pincode: 781314,
    StateName: "ASSAM",
  },
  {
    Pincode: 781307,
    StateName: "ASSAM",
  },
  {
    Pincode: 781302,
    StateName: "ASSAM",
  },
  {
    Pincode: 177501,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176111,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177048,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177038,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176110,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 814155,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 781326,
    StateName: "ASSAM",
  },
  {
    Pincode: 847422,
    StateName: "BIHAR",
  },
  {
    Pincode: 383315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383307,
    StateName: "GUJARAT",
  },
  {
    Pincode: 854102,
    StateName: "BIHAR",
  },
  {
    Pincode: 854205,
    StateName: "BIHAR",
  },
  {
    Pincode: 683108,
    StateName: "KERALA",
  },
  {
    Pincode: 683517,
    StateName: "KERALA",
  },
  {
    Pincode: 174001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174024,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 847405,
    StateName: "BIHAR",
  },
  {
    Pincode: 847407,
    StateName: "BIHAR",
  },
  {
    Pincode: 847233,
    StateName: "BIHAR",
  },
  {
    Pincode: 847423,
    StateName: "BIHAR",
  },
  {
    Pincode: 846002,
    StateName: "BIHAR",
  },
  {
    Pincode: 845413,
    StateName: "BIHAR",
  },
  {
    Pincode: 845412,
    StateName: "BIHAR",
  },
  {
    Pincode: 845414,
    StateName: "BIHAR",
  },
  {
    Pincode: 845302,
    StateName: "BIHAR",
  },
  {
    Pincode: 845415,
    StateName: "BIHAR",
  },
  {
    Pincode: 845416,
    StateName: "BIHAR",
  },
  {
    Pincode: 365455,
    StateName: "GUJARAT",
  },
  {
    Pincode: 845417,
    StateName: "BIHAR",
  },
  {
    Pincode: 845418,
    StateName: "BIHAR",
  },
  {
    Pincode: 845303,
    StateName: "BIHAR",
  },
  {
    Pincode: 845419,
    StateName: "BIHAR",
  },
  {
    Pincode: 845420,
    StateName: "BIHAR",
  },
  {
    Pincode: 845422,
    StateName: "BIHAR",
  },
  {
    Pincode: 845423,
    StateName: "BIHAR",
  },
  {
    Pincode: 845440,
    StateName: "BIHAR",
  },
  {
    Pincode: 845424,
    StateName: "BIHAR",
  },
  {
    Pincode: 845304,
    StateName: "BIHAR",
  },
  {
    Pincode: 845425,
    StateName: "BIHAR",
  },
  {
    Pincode: 845458,
    StateName: "BIHAR",
  },
  {
    Pincode: 845426,
    StateName: "BIHAR",
  },
  {
    Pincode: 182161,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 385515,
    StateName: "GUJARAT",
  },
  {
    Pincode: 847230,
    StateName: "BIHAR",
  },
  {
    Pincode: 844112,
    StateName: "BIHAR",
  },
  {
    Pincode: 843101,
    StateName: "BIHAR",
  },
  {
    Pincode: 843102,
    StateName: "BIHAR",
  },
  {
    Pincode: 843127,
    StateName: "BIHAR",
  },
  {
    Pincode: 843103,
    StateName: "BIHAR",
  },
  {
    Pincode: 843104,
    StateName: "BIHAR",
  },
  {
    Pincode: 131304,
    StateName: "HARYANA",
  },
  {
    Pincode: 182320,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 803113,
    StateName: "BIHAR",
  },
  {
    Pincode: 803114,
    StateName: "BIHAR",
  },
  {
    Pincode: 803119,
    StateName: "BIHAR",
  },
  {
    Pincode: 803116,
    StateName: "BIHAR",
  },
  {
    Pincode: 803216,
    StateName: "BIHAR",
  },
  {
    Pincode: 803117,
    StateName: "BIHAR",
  },
  {
    Pincode: 843120,
    StateName: "BIHAR",
  },
  {
    Pincode: 688541,
    StateName: "KERALA",
  },
  {
    Pincode: 364210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364295,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364050,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364710,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 845301,
    StateName: "BIHAR",
  },
  {
    Pincode: 825412,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825320,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 388255,
    StateName: "GUJARAT",
  },
  {
    Pincode: 845411,
    StateName: "BIHAR",
  },
  {
    Pincode: 845315,
    StateName: "BIHAR",
  },
  {
    Pincode: 394651,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394317,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394635,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394670,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394633,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394710,
    StateName: "GUJARAT",
  },
  {
    Pincode: 829144,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835325,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835217,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 177006,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176049,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 365541,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176042,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177601,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177044,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176045,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177022,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396521,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396060,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396590,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396580,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396406,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 854326,
    StateName: "BIHAR",
  },
  {
    Pincode: 394310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394690,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370015,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370405,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370435,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362570,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 125011,
    StateName: "HARYANA",
  },
  {
    Pincode: 360570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 843322,
    StateName: "BIHAR",
  },
  {
    Pincode: 843332,
    StateName: "BIHAR",
  },
  {
    Pincode: 843323,
    StateName: "BIHAR",
  },
  {
    Pincode: 394305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 496223,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497449,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497339,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497778,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497559,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497224,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497442,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497223,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497118,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497220,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497333,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497225,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497447,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497117,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497226,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497331,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 388370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 123028,
    StateName: "HARYANA",
  },
  {
    Pincode: 396412,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396418,
    StateName: "GUJARAT",
  },
  {
    Pincode: 174311,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174034,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174003,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174028,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174033,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174035,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174030,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174036,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174013,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174002,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174015,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177301,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 785015,
    StateName: "ASSAM",
  },
  {
    Pincode: 785635,
    StateName: "ASSAM",
  },
  {
    Pincode: 396325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396570,
    StateName: "GUJARAT",
  },
  {
    Pincode: 177045,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 847429,
    StateName: "BIHAR",
  },
  {
    Pincode: 688535,
    StateName: "KERALA",
  },
  {
    Pincode: 688582,
    StateName: "KERALA",
  },
  {
    Pincode: 785106,
    StateName: "ASSAM",
  },
  {
    Pincode: 804454,
    StateName: "BIHAR",
  },
  {
    Pincode: 804452,
    StateName: "BIHAR",
  },
  {
    Pincode: 841407,
    StateName: "BIHAR",
  },
  {
    Pincode: 841427,
    StateName: "BIHAR",
  },
  {
    Pincode: 841428,
    StateName: "BIHAR",
  },
  {
    Pincode: 841436,
    StateName: "BIHAR",
  },
  {
    Pincode: 843121,
    StateName: "BIHAR",
  },
  {
    Pincode: 843105,
    StateName: "BIHAR",
  },
  {
    Pincode: 843107,
    StateName: "BIHAR",
  },
  {
    Pincode: 843123,
    StateName: "BIHAR",
  },
  {
    Pincode: 843360,
    StateName: "BIHAR",
  },
  {
    Pincode: 843109,
    StateName: "BIHAR",
  },
  {
    Pincode: 843124,
    StateName: "BIHAR",
  },
  {
    Pincode: 843321,
    StateName: "BIHAR",
  },
  {
    Pincode: 844120,
    StateName: "BIHAR",
  },
  {
    Pincode: 843125,
    StateName: "BIHAR",
  },
  {
    Pincode: 842003,
    StateName: "BIHAR",
  },
  {
    Pincode: 843128,
    StateName: "BIHAR",
  },
  {
    Pincode: 383320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383225,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841413,
    StateName: "BIHAR",
  },
  {
    Pincode: 841437,
    StateName: "BIHAR",
  },
  {
    Pincode: 841501,
    StateName: "BIHAR",
  },
  {
    Pincode: 841438,
    StateName: "BIHAR",
  },
  {
    Pincode: 843333,
    StateName: "BIHAR",
  },
  {
    Pincode: 843324,
    StateName: "BIHAR",
  },
  {
    Pincode: 843325,
    StateName: "BIHAR",
  },
  {
    Pincode: 492112,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 383255,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841412,
    StateName: "BIHAR",
  },
  {
    Pincode: 841212,
    StateName: "BIHAR",
  },
  {
    Pincode: 841213,
    StateName: "BIHAR",
  },
  {
    Pincode: 841215,
    StateName: "BIHAR",
  },
  {
    Pincode: 841313,
    StateName: "BIHAR",
  },
  {
    Pincode: 841316,
    StateName: "BIHAR",
  },
  {
    Pincode: 841216,
    StateName: "BIHAR",
  },
  {
    Pincode: 841224,
    StateName: "BIHAR",
  },
  {
    Pincode: 841219,
    StateName: "BIHAR",
  },
  {
    Pincode: 363660,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360022,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785704,
    StateName: "ASSAM",
  },
  {
    Pincode: 363670,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363641,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363642,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 821301,
    StateName: "BIHAR",
  },
  {
    Pincode: 802211,
    StateName: "BIHAR",
  },
  {
    Pincode: 821304,
    StateName: "BIHAR",
  },
  {
    Pincode: 496225,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496113,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496220,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497229,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496661,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496100,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497116,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 360550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389180,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 496118,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496440,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496115,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496445,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496554,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496107,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497114,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497111,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 175133,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 136034,
    StateName: "HARYANA",
  },
  {
    Pincode: 360003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360021,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360025,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360452,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383317,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 844122,
    StateName: "BIHAR",
  },
  {
    Pincode: 388306,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394125,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394112,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396409,
    StateName: "GUJARAT",
  },
  {
    Pincode: 136042,
    StateName: "HARYANA",
  },
  {
    Pincode: 136027,
    StateName: "HARYANA",
  },
  {
    Pincode: 126113,
    StateName: "HARYANA",
  },
  {
    Pincode: 126112,
    StateName: "HARYANA",
  },
  {
    Pincode: 136156,
    StateName: "HARYANA",
  },
  {
    Pincode: 136030,
    StateName: "HARYANA",
  },
  {
    Pincode: 136129,
    StateName: "HARYANA",
  },
  {
    Pincode: 136130,
    StateName: "HARYANA",
  },
  {
    Pincode: 493776,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 591110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591173,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 841503,
    StateName: "BIHAR",
  },
  {
    Pincode: 829123,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829127,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829209,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 396415,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 174017,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174004,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174310,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174012,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174011,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 391125,
    StateName: "GUJARAT",
  },
  {
    Pincode: 800012,
    StateName: "BIHAR",
  },
  {
    Pincode: 803202,
    StateName: "BIHAR",
  },
  {
    Pincode: 396380,
    StateName: "GUJARAT",
  },
  {
    Pincode: 801103,
    StateName: "BIHAR",
  },
  {
    Pincode: 803201,
    StateName: "BIHAR",
  },
  {
    Pincode: 804451,
    StateName: "BIHAR",
  },
  {
    Pincode: 801503,
    StateName: "BIHAR",
  },
  {
    Pincode: 800002,
    StateName: "BIHAR",
  },
  {
    Pincode: 801111,
    StateName: "BIHAR",
  },
  {
    Pincode: 801506,
    StateName: "BIHAR",
  },
  {
    Pincode: 800030,
    StateName: "BIHAR",
  },
  {
    Pincode: 801105,
    StateName: "BIHAR",
  },
  {
    Pincode: 383220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 493558,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493559,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493101,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492013,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 493229,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 360405,
    StateName: "GUJARAT",
  },
  {
    Pincode: 673593,
    StateName: "KERALA",
  },
  {
    Pincode: 673122,
    StateName: "KERALA",
  },
  {
    Pincode: 673596,
    StateName: "KERALA",
  },
  {
    Pincode: 673591,
    StateName: "KERALA",
  },
  {
    Pincode: 673579,
    StateName: "KERALA",
  },
  {
    Pincode: 673592,
    StateName: "KERALA",
  },
  {
    Pincode: 395004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 175138,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 394155,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394163,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394180,
    StateName: "GUJARAT",
  },
  {
    Pincode: 585322,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 110094,
    StateName: "DELHI",
  },
  {
    Pincode: 110090,
    StateName: "DELHI",
  },
  {
    Pincode: 496330,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496245,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496336,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496227,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 136020,
    StateName: "HARYANA",
  },
  {
    Pincode: 192233,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192302,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192231,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192301,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192122,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192124,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192211,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192126,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192221,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192129,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 136035,
    StateName: "HARYANA",
  },
  {
    Pincode: 126114,
    StateName: "HARYANA",
  },
  {
    Pincode: 126115,
    StateName: "HARYANA",
  },
  {
    Pincode: 126152,
    StateName: "HARYANA",
  },
  {
    Pincode: 670646,
    StateName: "KERALA",
  },
  {
    Pincode: 686667,
    StateName: "KERALA",
  },
  {
    Pincode: 686664,
    StateName: "KERALA",
  },
  {
    Pincode: 686671,
    StateName: "KERALA",
  },
  {
    Pincode: 686663,
    StateName: "KERALA",
  },
  {
    Pincode: 686670,
    StateName: "KERALA",
  },
  {
    Pincode: 686681,
    StateName: "KERALA",
  },
  {
    Pincode: 683544,
    StateName: "KERALA",
  },
  {
    Pincode: 683549,
    StateName: "KERALA",
  },
  {
    Pincode: 360465,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 583102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 125048,
    StateName: "HARYANA",
  },
  {
    Pincode: 192210,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192125,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192303,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 561201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562164,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 132039,
    StateName: "HARYANA",
  },
  {
    Pincode: 132054,
    StateName: "HARYANA",
  },
  {
    Pincode: 132114,
    StateName: "HARYANA",
  },
  {
    Pincode: 132036,
    StateName: "HARYANA",
  },
  {
    Pincode: 132037,
    StateName: "HARYANA",
  },
  {
    Pincode: 132157,
    StateName: "HARYANA",
  },
  {
    Pincode: 132024,
    StateName: "HARYANA",
  },
  {
    Pincode: 132023,
    StateName: "HARYANA",
  },
  {
    Pincode: 125051,
    StateName: "HARYANA",
  },
  {
    Pincode: 125120,
    StateName: "HARYANA",
  },
  {
    Pincode: 125044,
    StateName: "HARYANA",
  },
  {
    Pincode: 125113,
    StateName: "HARYANA",
  },
  {
    Pincode: 125055,
    StateName: "HARYANA",
  },
  {
    Pincode: 125078,
    StateName: "HARYANA",
  },
  {
    Pincode: 492099,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 360050,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785009,
    StateName: "ASSAM",
  },
  {
    Pincode: 670645,
    StateName: "KERALA",
  },
  {
    Pincode: 670721,
    StateName: "KERALA",
  },
  {
    Pincode: 673124,
    StateName: "KERALA",
  },
  {
    Pincode: 673577,
    StateName: "KERALA",
  },
  {
    Pincode: 843114,
    StateName: "BIHAR",
  },
  {
    Pincode: 394655,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394380,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394715,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394716,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394720,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394352,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394365,
    StateName: "GUJARAT",
  },
  {
    Pincode: 132046,
    StateName: "HARYANA",
  },
  {
    Pincode: 132116,
    StateName: "HARYANA",
  },
  {
    Pincode: 132122,
    StateName: "HARYANA",
  },
  {
    Pincode: 132107,
    StateName: "HARYANA",
  },
  {
    Pincode: 132113,
    StateName: "HARYANA",
  },
  {
    Pincode: 132104,
    StateName: "HARYANA",
  },
  {
    Pincode: 132140,
    StateName: "HARYANA",
  },
  {
    Pincode: 132105,
    StateName: "HARYANA",
  },
  {
    Pincode: 132102,
    StateName: "HARYANA",
  },
  {
    Pincode: 132101,
    StateName: "HARYANA",
  },
  {
    Pincode: 132040,
    StateName: "HARYANA",
  },
  {
    Pincode: 132041,
    StateName: "HARYANA",
  },
  {
    Pincode: 132001,
    StateName: "HARYANA",
  },
  {
    Pincode: 132022,
    StateName: "HARYANA",
  },
  {
    Pincode: 132117,
    StateName: "HARYANA",
  },
  {
    Pincode: 132108,
    StateName: "HARYANA",
  },
  {
    Pincode: 132106,
    StateName: "HARYANA",
  },
  {
    Pincode: 803115,
    StateName: "BIHAR",
  },
  {
    Pincode: 580011,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580007,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 801112,
    StateName: "BIHAR",
  },
  {
    Pincode: 803118,
    StateName: "BIHAR",
  },
  {
    Pincode: 496551,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496665,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496116,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496111,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496450,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 360020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 683541,
    StateName: "KERALA",
  },
  {
    Pincode: 673633,
    StateName: "KERALA",
  },
  {
    Pincode: 673661,
    StateName: "KERALA",
  },
  {
    Pincode: 673005,
    StateName: "KERALA",
  },
  {
    Pincode: 360023,
    StateName: "GUJARAT",
  },
  {
    Pincode: 785111,
    StateName: "ASSAM",
  },
  {
    Pincode: 192306,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 562163,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 132145,
    StateName: "HARYANA",
  },
  {
    Pincode: 394350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394375,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 492008,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 387370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360485,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 174023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174032,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 391130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 442905,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 394445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 580112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 394315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387375,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388580,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389232,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389190,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389341,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 585326,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585328,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585411,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585402,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585403,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585412,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585413,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 822126,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 580023,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581195,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582208,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580024,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 382445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 193101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 673575,
    StateName: "KERALA",
  },
  {
    Pincode: 394430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395013,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394405,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391105,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395012,
    StateName: "GUJARAT",
  },
  {
    Pincode: 171204,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171202,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 193123,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 122102,
    StateName: "HARYANA",
  },
  {
    Pincode: 122506,
    StateName: "HARYANA",
  },
  {
    Pincode: 122505,
    StateName: "HARYANA",
  },
  {
    Pincode: 583137,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 821302,
    StateName: "BIHAR",
  },
  {
    Pincode: 587115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585329,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585416,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 496224,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496331,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382422,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382725,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382810,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382845,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382735,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382740,
    StateName: "GUJARAT",
  },
  {
    Pincode: 585214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585317,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585318,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585309,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585327,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585290,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585319,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585418,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585414,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585415,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 494221,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 171217,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171216,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176043,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176044,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176108,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171007,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171213,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171210,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 844508,
    StateName: "BIHAR",
  },
  {
    Pincode: 844123,
    StateName: "BIHAR",
  },
  {
    Pincode: 801113,
    StateName: "BIHAR",
  },
  {
    Pincode: 844124,
    StateName: "BIHAR",
  },
  {
    Pincode: 587203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 833102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 591129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 844509,
    StateName: "BIHAR",
  },
  {
    Pincode: 844125,
    StateName: "BIHAR",
  },
  {
    Pincode: 844126,
    StateName: "BIHAR",
  },
  {
    Pincode: 843106,
    StateName: "BIHAR",
  },
  {
    Pincode: 843122,
    StateName: "BIHAR",
  },
  {
    Pincode: 573217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573133,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 833106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829128,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 394185,
    StateName: "GUJARAT",
  },
  {
    Pincode: 585210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581206,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581209,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580028,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 841214,
    StateName: "BIHAR",
  },
  {
    Pincode: 384001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110093,
    StateName: "DELHI",
  },
  {
    Pincode: 585320,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 490042,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 490036,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491111,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491107,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 756060,
    StateName: "ODISHA",
  },
  {
    Pincode: 756045,
    StateName: "ODISHA",
  },
  {
    Pincode: 573134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 384455,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841223,
    StateName: "BIHAR",
  },
  {
    Pincode: 841220,
    StateName: "BIHAR",
  },
  {
    Pincode: 581201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 413603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415014,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 581207,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581196,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 796261,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796181,
    StateName: "MIZORAM",
  },
  {
    Pincode: 389365,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389175,
    StateName: "GUJARAT",
  },
  {
    Pincode: 573226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573137,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 192304,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 562132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 389154,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125101,
    StateName: "HARYANA",
  },
  {
    Pincode: 125110,
    StateName: "HARYANA",
  },
  {
    Pincode: 125049,
    StateName: "HARYANA",
  },
  {
    Pincode: 394246,
    StateName: "GUJARAT",
  },
  {
    Pincode: 454010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 181133,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181132,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181143,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181145,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181102,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180011,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184120,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 110053,
    StateName: "DELHI",
  },
  {
    Pincode: 110049,
    StateName: "DELHI",
  },
  {
    Pincode: 110014,
    StateName: "DELHI",
  },
  {
    Pincode: 841305,
    StateName: "BIHAR",
  },
  {
    Pincode: 394105,
    StateName: "GUJARAT",
  },
  {
    Pincode: 171207,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171224,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 752038,
    StateName: "ODISHA",
  },
  {
    Pincode: 752021,
    StateName: "ODISHA",
  },
  {
    Pincode: 752023,
    StateName: "ODISHA",
  },
  {
    Pincode: 670644,
    StateName: "KERALA",
  },
  {
    Pincode: 670731,
    StateName: "KERALA",
  },
  {
    Pincode: 364265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364070,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364290,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364280,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364760,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364270,
    StateName: "GUJARAT",
  },
  {
    Pincode: 673019,
    StateName: "KERALA",
  },
  {
    Pincode: 673004,
    StateName: "KERALA",
  },
  {
    Pincode: 193504,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193223,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193501,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193225,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193224,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 673581,
    StateName: "KERALA",
  },
  {
    Pincode: 364275,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364313,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 122107,
    StateName: "HARYANA",
  },
  {
    Pincode: 122508,
    StateName: "HARYANA",
  },
  {
    Pincode: 122105,
    StateName: "HARYANA",
  },
  {
    Pincode: 305005,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 370615,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370655,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370425,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370165,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370625,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370601,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370660,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370665,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370675,
    StateName: "GUJARAT",
  },
  {
    Pincode: 583103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583152,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 370040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384415,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 144702,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144044,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144036,
    StateName: "PUNJAB",
  },
  {
    Pincode: 802164,
    StateName: "BIHAR",
  },
  {
    Pincode: 802165,
    StateName: "BIHAR",
  },
  {
    Pincode: 583115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583155,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 110047,
    StateName: "DELHI",
  },
  {
    Pincode: 110080,
    StateName: "DELHI",
  },
  {
    Pincode: 110076,
    StateName: "DELHI",
  },
  {
    Pincode: 185154,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 670612,
    StateName: "KERALA",
  },
  {
    Pincode: 181131,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180010,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 364720,
    StateName: "GUJARAT",
  },
  {
    Pincode: 122502,
    StateName: "HARYANA",
  },
  {
    Pincode: 110036,
    StateName: "DELHI",
  },
  {
    Pincode: 110039,
    StateName: "DELHI",
  },
  {
    Pincode: 110081,
    StateName: "DELHI",
  },
  {
    Pincode: 110040,
    StateName: "DELHI",
  },
  {
    Pincode: 110035,
    StateName: "DELHI",
  },
  {
    Pincode: 110085,
    StateName: "DELHI",
  },
  {
    Pincode: 571201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585304,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585315,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571247,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571248,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 122504,
    StateName: "HARYANA",
  },
  {
    Pincode: 144632,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144037,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144043,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144626,
    StateName: "PUNJAB",
  },
  {
    Pincode: 181111,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180003,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 587114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 756047,
    StateName: "ODISHA",
  },
  {
    Pincode: 756030,
    StateName: "ODISHA",
  },
  {
    Pincode: 756026,
    StateName: "ODISHA",
  },
  {
    Pincode: 756038,
    StateName: "ODISHA",
  },
  {
    Pincode: 756079,
    StateName: "ODISHA",
  },
  {
    Pincode: 756036,
    StateName: "ODISHA",
  },
  {
    Pincode: 144603,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144622,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144407,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144405,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144411,
    StateName: "PUNJAB",
  },
  {
    Pincode: 171212,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 496005,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 380059,
    StateName: "GUJARAT",
  },
  {
    Pincode: 571249,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571234,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571250,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571251,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571252,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 171006,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 441225,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 171221,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171219,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 442906,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441222,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441221,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 122004,
    StateName: "HARYANA",
  },
  {
    Pincode: 122017,
    StateName: "HARYANA",
  },
  {
    Pincode: 587102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752063,
    StateName: "ODISHA",
  },
  {
    Pincode: 415109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415124,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415020,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413524,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413521,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413527,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415021,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415514,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 110030,
    StateName: "DELHI",
  },
  {
    Pincode: 110068,
    StateName: "DELHI",
  },
  {
    Pincode: 125054,
    StateName: "HARYANA",
  },
  {
    Pincode: 766111,
    StateName: "ODISHA",
  },
  {
    Pincode: 415537,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415518,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 181141,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181123,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181122,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 766002,
    StateName: "ODISHA",
  },
  {
    Pincode: 766020,
    StateName: "ODISHA",
  },
  {
    Pincode: 761016,
    StateName: "ODISHA",
  },
  {
    Pincode: 761213,
    StateName: "ODISHA",
  },
  {
    Pincode: 761201,
    StateName: "ODISHA",
  },
  {
    Pincode: 360590,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360579,
    StateName: "GUJARAT",
  },
  {
    Pincode: 122503,
    StateName: "HARYANA",
  },
  {
    Pincode: 122103,
    StateName: "HARYANA",
  },
  {
    Pincode: 123001,
    StateName: "HARYANA",
  },
  {
    Pincode: 362650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362015,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362610,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125004,
    StateName: "HARYANA",
  },
  {
    Pincode: 125033,
    StateName: "HARYANA",
  },
  {
    Pincode: 125052,
    StateName: "HARYANA",
  },
  {
    Pincode: 125005,
    StateName: "HARYANA",
  },
  {
    Pincode: 181224,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 363655,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 844128,
    StateName: "BIHAR",
  },
  {
    Pincode: 383410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 383325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 144042,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144625,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144311,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144033,
    StateName: "PUNJAB",
  },
  {
    Pincode: 573121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573144,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 361012,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361306,
    StateName: "GUJARAT",
  },
  {
    Pincode: 415505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415530,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 392110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 413544,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413531,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 125037,
    StateName: "HARYANA",
  },
  {
    Pincode: 125039,
    StateName: "HARYANA",
  },
  {
    Pincode: 110061,
    StateName: "DELHI",
  },
  {
    Pincode: 110037,
    StateName: "DELHI",
  },
  {
    Pincode: 110070,
    StateName: "DELHI",
  },
  {
    Pincode: 110072,
    StateName: "DELHI",
  },
  {
    Pincode: 110043,
    StateName: "DELHI",
  },
  {
    Pincode: 125111,
    StateName: "HARYANA",
  },
  {
    Pincode: 370115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 123501,
    StateName: "HARYANA",
  },
  {
    Pincode: 305205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305206,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 122104,
    StateName: "HARYANA",
  },
  {
    Pincode: 122108,
    StateName: "HARYANA",
  },
  {
    Pincode: 305023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 454335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 391761,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391770,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391101,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391760,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 766032,
    StateName: "ODISHA",
  },
  {
    Pincode: 766028,
    StateName: "ODISHA",
  },
  {
    Pincode: 793108,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793109,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793110,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 305002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 441217,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442916,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442907,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442918,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413517,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413530,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 393041,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393105,
    StateName: "GUJARAT",
  },
  {
    Pincode: 431522,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413514,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 393002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 573135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 392130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391810,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388307,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 393040,
    StateName: "GUJARAT",
  },
  {
    Pincode: 413582,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 793150,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793200,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793210,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793021,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793114,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793121,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793120,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 125106,
    StateName: "HARYANA",
  },
  {
    Pincode: 362560,
    StateName: "GUJARAT",
  },
  {
    Pincode: 573123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 491340,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491337,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 491993,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 175040,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175006,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175036,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175049,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175015,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175003,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 394325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394248,
    StateName: "GUJARAT",
  },
  {
    Pincode: 305024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 360480,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360070,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360375,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360490,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360380,
    StateName: "GUJARAT",
  },
  {
    Pincode: 193301,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 571231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342312,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342314,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342308,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 573165,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 573141,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 394620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 444101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 396230,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 175032,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175005,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 573124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573164,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 573142,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 175014,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175007,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396035,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394730,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396125,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396126,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396067,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396065,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396145,
    StateName: "GUJARAT",
  },
  {
    Pincode: 175016,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 121106,
    StateName: "HARYANA",
  },
  {
    Pincode: 121105,
    StateName: "HARYANA",
  },
  {
    Pincode: 144035,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144032,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144633,
    StateName: "PUNJAB",
  },
  {
    Pincode: 333704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 362265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125050,
    StateName: "HARYANA",
  },
  {
    Pincode: 110010,
    StateName: "DELHI",
  },
  {
    Pincode: 110045,
    StateName: "DELHI",
  },
  {
    Pincode: 110077,
    StateName: "DELHI",
  },
  {
    Pincode: 110071,
    StateName: "DELHI",
  },
  {
    Pincode: 110075,
    StateName: "DELHI",
  },
  {
    Pincode: 415527,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413518,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413529,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 360510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391745,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391775,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 761211,
    StateName: "ODISHA",
  },
  {
    Pincode: 394680,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396055,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396155,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396165,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396185,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110091,
    StateName: "DELHI",
  },
  {
    Pincode: 361110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110044,
    StateName: "DELHI",
  },
  {
    Pincode: 333053,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333307,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 121101,
    StateName: "HARYANA",
  },
  {
    Pincode: 122101,
    StateName: "HARYANA",
  },
  {
    Pincode: 122413,
    StateName: "HARYANA",
  },
  {
    Pincode: 122003,
    StateName: "HARYANA",
  },
  {
    Pincode: 122414,
    StateName: "HARYANA",
  },
  {
    Pincode: 583117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583154,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 305012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 673595,
    StateName: "KERALA",
  },
  {
    Pincode: 673123,
    StateName: "KERALA",
  },
  {
    Pincode: 383240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 752030,
    StateName: "ODISHA",
  },
  {
    Pincode: 144620,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144606,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144628,
    StateName: "PUNJAB",
  },
  {
    Pincode: 583116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 125053,
    StateName: "HARYANA",
  },
  {
    Pincode: 182122,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 362310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 583124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802316,
    StateName: "BIHAR",
  },
  {
    Pincode: 574323,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574253,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 125006,
    StateName: "HARYANA",
  },
  {
    Pincode: 125038,
    StateName: "HARYANA",
  },
  {
    Pincode: 125133,
    StateName: "HARYANA",
  },
  {
    Pincode: 144403,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144034,
    StateName: "PUNJAB",
  },
  {
    Pincode: 384135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 333042,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333308,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 580009,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580025,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 442919,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 451001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 171003,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171225,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171226,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171018,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171220,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171208,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171211,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171012,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171209,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171206,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171019,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171214,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171205,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 415019,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 793004,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793160,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793113,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 393125,
    StateName: "GUJARAT",
  },
  {
    Pincode: 834010,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 171222,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171005,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 673576,
    StateName: "KERALA",
  },
  {
    Pincode: 673029,
    StateName: "KERALA",
  },
  {
    Pincode: 389380,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125112,
    StateName: "HARYANA",
  },
  {
    Pincode: 110038,
    StateName: "DELHI",
  },
  {
    Pincode: 110078,
    StateName: "DELHI",
  },
  {
    Pincode: 388345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388360,
    StateName: "GUJARAT",
  },
  {
    Pincode: 425416,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425419,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425444,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425432,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425452,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425412,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 171014,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 131409,
    StateName: "HARYANA",
  },
  {
    Pincode: 131302,
    StateName: "HARYANA",
  },
  {
    Pincode: 131039,
    StateName: "HARYANA",
  },
  {
    Pincode: 131408,
    StateName: "HARYANA",
  },
  {
    Pincode: 131101,
    StateName: "HARYANA",
  },
  {
    Pincode: 131301,
    StateName: "HARYANA",
  },
  {
    Pincode: 131028,
    StateName: "HARYANA",
  },
  {
    Pincode: 131024,
    StateName: "HARYANA",
  },
  {
    Pincode: 362268,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360545,
    StateName: "GUJARAT",
  },
  {
    Pincode: 122006,
    StateName: "HARYANA",
  },
  {
    Pincode: 123034,
    StateName: "HARYANA",
  },
  {
    Pincode: 360035,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360045,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360055,
    StateName: "GUJARAT",
  },
  {
    Pincode: 131022,
    StateName: "HARYANA",
  },
  {
    Pincode: 131023,
    StateName: "HARYANA",
  },
  {
    Pincode: 110020,
    StateName: "DELHI",
  },
  {
    Pincode: 361170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361162,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360531,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360515,
    StateName: "GUJARAT",
  },
  {
    Pincode: 184144,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184141,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184142,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184102,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184152,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184104,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184151,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184145,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184148,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180012,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182311,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182203,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182221,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 125102,
    StateName: "HARYANA",
  },
  {
    Pincode: 125103,
    StateName: "HARYANA",
  },
  {
    Pincode: 125201,
    StateName: "HARYANA",
  },
  {
    Pincode: 573201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 496334,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496242,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 496338,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 382650,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110088,
    StateName: "DELHI",
  },
  {
    Pincode: 110086,
    StateName: "DELHI",
  },
  {
    Pincode: 110082,
    StateName: "DELHI",
  },
  {
    Pincode: 110042,
    StateName: "DELHI",
  },
  {
    Pincode: 110084,
    StateName: "DELHI",
  },
  {
    Pincode: 134008,
    StateName: "HARYANA",
  },
  {
    Pincode: 134007,
    StateName: "HARYANA",
  },
  {
    Pincode: 382835,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382855,
    StateName: "GUJARAT",
  },
  {
    Pincode: 345001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 756002,
    StateName: "ODISHA",
  },
  {
    Pincode: 756085,
    StateName: "ODISHA",
  },
  {
    Pincode: 756022,
    StateName: "ODISHA",
  },
  {
    Pincode: 396191,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394107,
    StateName: "GUJARAT",
  },
  {
    Pincode: 173101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396220,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 454774,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 394245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394652,
    StateName: "GUJARAT",
  },
  {
    Pincode: 175102,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175136,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175143,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175105,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175129,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 345027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 827010,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828134,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827013,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828306,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828201,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 136117,
    StateName: "HARYANA",
  },
  {
    Pincode: 134118,
    StateName: "HARYANA",
  },
  {
    Pincode: 110033,
    StateName: "DELHI",
  },
  {
    Pincode: 752082,
    StateName: "ODISHA",
  },
  {
    Pincode: 752089,
    StateName: "ODISHA",
  },
  {
    Pincode: 752024,
    StateName: "ODISHA",
  },
  {
    Pincode: 752084,
    StateName: "ODISHA",
  },
  {
    Pincode: 126102,
    StateName: "HARYANA",
  },
  {
    Pincode: 577301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577245,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577243,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 173024,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 756028,
    StateName: "ODISHA",
  },
  {
    Pincode: 828301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828303,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828202,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 175141,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125104,
    StateName: "HARYANA",
  },
  {
    Pincode: 125077,
    StateName: "HARYANA",
  },
  {
    Pincode: 125076,
    StateName: "HARYANA",
  },
  {
    Pincode: 125060,
    StateName: "HARYANA",
  },
  {
    Pincode: 125075,
    StateName: "HARYANA",
  },
  {
    Pincode: 125058,
    StateName: "HARYANA",
  },
  {
    Pincode: 574203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574325,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574230,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 389382,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389146,
    StateName: "GUJARAT",
  },
  {
    Pincode: 425418,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 388225,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388335,
    StateName: "GUJARAT",
  },
  {
    Pincode: 424302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424318,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 388265,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396235,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396140,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388235,
    StateName: "GUJARAT",
  },
  {
    Pincode: 184205,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 361008,
    StateName: "GUJARAT",
  },
  {
    Pincode: 110095,
    StateName: "DELHI",
  },
  {
    Pincode: 110096,
    StateName: "DELHI",
  },
  {
    Pincode: 384515,
    StateName: "GUJARAT",
  },
  {
    Pincode: 143102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 582202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 151103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151401,
    StateName: "PUNJAB",
  },
  {
    Pincode: 194101,
    StateName: "LADAKH",
  },
  {
    Pincode: 194104,
    StateName: "LADAKH",
  },
  {
    Pincode: 194107,
    StateName: "LADAKH",
  },
  {
    Pincode: 194401,
    StateName: "LADAKH",
  },
  {
    Pincode: 194103,
    StateName: "LADAKH",
  },
  {
    Pincode: 194102,
    StateName: "LADAKH",
  },
  {
    Pincode: 586215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 795138,
    StateName: "MANIPUR",
  },
  {
    Pincode: 173029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173032,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173212,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173208,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173205,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173217,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171009,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 491665,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 795115,
    StateName: "MANIPUR",
  },
  {
    Pincode: 396430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396436,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396466,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396463,
    StateName: "GUJARAT",
  },
  {
    Pincode: 800020,
    StateName: "BIHAR",
  },
  {
    Pincode: 382640,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382721,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 424119,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 389172,
    StateName: "GUJARAT",
  },
  {
    Pincode: 793119,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793126,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794115,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794102,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793012,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793022,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 841217,
    StateName: "BIHAR",
  },
  {
    Pincode: 854327,
    StateName: "BIHAR",
  },
  {
    Pincode: 574234,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574235,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591147,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 793009,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793151,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793161,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 425104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 345034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 345031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 345033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 756044,
    StateName: "ODISHA",
  },
  {
    Pincode: 756027,
    StateName: "ODISHA",
  },
  {
    Pincode: 756048,
    StateName: "ODISHA",
  },
  {
    Pincode: 756043,
    StateName: "ODISHA",
  },
  {
    Pincode: 756059,
    StateName: "ODISHA",
  },
  {
    Pincode: 181206,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 388230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 413523,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756056,
    StateName: "ODISHA",
  },
  {
    Pincode: 756020,
    StateName: "ODISHA",
  },
  {
    Pincode: 756003,
    StateName: "ODISHA",
  },
  {
    Pincode: 756049,
    StateName: "ODISHA",
  },
  {
    Pincode: 756058,
    StateName: "ODISHA",
  },
  {
    Pincode: 756040,
    StateName: "ODISHA",
  },
  {
    Pincode: 756021,
    StateName: "ODISHA",
  },
  {
    Pincode: 756019,
    StateName: "ODISHA",
  },
  {
    Pincode: 756041,
    StateName: "ODISHA",
  },
  {
    Pincode: 756025,
    StateName: "ODISHA",
  },
  {
    Pincode: 345024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 482001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 482004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 794106,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794107,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 591109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 345028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 490024,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 591112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 148025,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148028,
    StateName: "PUNJAB",
  },
  {
    Pincode: 574238,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574239,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574241,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574324,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574243,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574265,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574274,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802210,
    StateName: "BIHAR",
  },
  {
    Pincode: 802166,
    StateName: "BIHAR",
  },
  {
    Pincode: 445206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 466651,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 177114,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177108,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176033,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396385,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176030,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 424310,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 172104,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 793102,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793103,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793116,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794101,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794103,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794005,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794104,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794105,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794002,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 802112,
    StateName: "BIHAR",
  },
  {
    Pincode: 591106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 148109,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148100,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 802111,
    StateName: "BIHAR",
  },
  {
    Pincode: 802102,
    StateName: "BIHAR",
  },
  {
    Pincode: 793104,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793105,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 193502,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193505,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 841225,
    StateName: "BIHAR",
  },
  {
    Pincode: 841301,
    StateName: "BIHAR",
  },
  {
    Pincode: 423502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 182126,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 182145,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 394520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 171004,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396235,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 754018,
    StateName: "ODISHA",
  },
  {
    Pincode: 754001,
    StateName: "ODISHA",
  },
  {
    Pincode: 754003,
    StateName: "ODISHA",
  },
  {
    Pincode: 754105,
    StateName: "ODISHA",
  },
  {
    Pincode: 754002,
    StateName: "ODISHA",
  },
  {
    Pincode: 754206,
    StateName: "ODISHA",
  },
  {
    Pincode: 754209,
    StateName: "ODISHA",
  },
  {
    Pincode: 796184,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796161,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796091,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796017,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796186,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796009,
    StateName: "MIZORAM",
  },
  {
    Pincode: 193303,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193503,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193221,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193222,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193302,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 796101,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796691,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796470,
    StateName: "MIZORAM",
  },
  {
    Pincode: 841409,
    StateName: "BIHAR",
  },
  {
    Pincode: 574259,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574240,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 394641,
    StateName: "GUJARAT",
  },
  {
    Pincode: 802115,
    StateName: "BIHAR",
  },
  {
    Pincode: 802114,
    StateName: "BIHAR",
  },
  {
    Pincode: 802116,
    StateName: "BIHAR",
  },
  {
    Pincode: 802117,
    StateName: "BIHAR",
  },
  {
    Pincode: 802118,
    StateName: "BIHAR",
  },
  {
    Pincode: 802122,
    StateName: "BIHAR",
  },
  {
    Pincode: 802123,
    StateName: "BIHAR",
  },
  {
    Pincode: 802125,
    StateName: "BIHAR",
  },
  {
    Pincode: 802126,
    StateName: "BIHAR",
  },
  {
    Pincode: 802128,
    StateName: "BIHAR",
  },
  {
    Pincode: 802129,
    StateName: "BIHAR",
  },
  {
    Pincode: 802131,
    StateName: "BIHAR",
  },
  {
    Pincode: 802131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 802130,
    StateName: "BIHAR",
  },
  {
    Pincode: 444511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 465001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 802133,
    StateName: "BIHAR",
  },
  {
    Pincode: 754007,
    StateName: "ODISHA",
  },
  {
    Pincode: 754009,
    StateName: "ODISHA",
  },
  {
    Pincode: 574233,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 465335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 444302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577527,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802119,
    StateName: "BIHAR",
  },
  {
    Pincode: 802136,
    StateName: "BIHAR",
  },
  {
    Pincode: 444106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 754203,
    StateName: "ODISHA",
  },
  {
    Pincode: 754006,
    StateName: "ODISHA",
  },
  {
    Pincode: 754004,
    StateName: "ODISHA",
  },
  {
    Pincode: 444403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 797115,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798618,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797105,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798620,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798603,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797108,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797104,
    StateName: "NAGALAND",
  },
  {
    Pincode: 491338,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 444404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444117,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 465441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465230,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 797107,
    StateName: "NAGALAND",
  },
  {
    Pincode: 852106,
    StateName: "BIHAR",
  },
  {
    Pincode: 852107,
    StateName: "BIHAR",
  },
  {
    Pincode: 852105,
    StateName: "BIHAR",
  },
  {
    Pincode: 852114,
    StateName: "BIHAR",
  },
  {
    Pincode: 854340,
    StateName: "BIHAR",
  },
  {
    Pincode: 852213,
    StateName: "BIHAR",
  },
  {
    Pincode: 465445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465550,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 122051,
    StateName: "HARYANA",
  },
  {
    Pincode: 802127,
    StateName: "BIHAR",
  },
  {
    Pincode: 577529,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577531,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577554,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577532,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577533,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577535,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577536,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 802113,
    StateName: "BIHAR",
  },
  {
    Pincode: 802103,
    StateName: "BIHAR",
  },
  {
    Pincode: 445304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 754204,
    StateName: "ODISHA",
  },
  {
    Pincode: 754293,
    StateName: "ODISHA",
  },
  {
    Pincode: 754207,
    StateName: "ODISHA",
  },
  {
    Pincode: 754202,
    StateName: "ODISHA",
  },
  {
    Pincode: 754221,
    StateName: "ODISHA",
  },
  {
    Pincode: 586111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 415122,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415515,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 835302,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835232,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822119,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 415536,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 193109,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193103,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193108,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 364135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 174315,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177210,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177207,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177209,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174301,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 465110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 176036,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177034,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177111,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177043,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176501,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177105,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177112,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176054,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176038,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176025,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176209,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 802120,
    StateName: "BIHAR",
  },
  {
    Pincode: 465106,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465227,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465333,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 176314,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176312,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 793018,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794108,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794110,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 171102,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177213,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177220,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174308,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174319,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177208,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174503,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177039,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174321,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177211,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173209,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173221,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173225,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173229,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173207,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 394326,
    StateName: "GUJARAT",
  },
  {
    Pincode: 441104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 396135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396195,
    StateName: "GUJARAT",
  },
  {
    Pincode: 465339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 174101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173223,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177206,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174507,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 577213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 384130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 844506,
    StateName: "BIHAR",
  },
  {
    Pincode: 843110,
    StateName: "BIHAR",
  },
  {
    Pincode: 844121,
    StateName: "BIHAR",
  },
  {
    Pincode: 844507,
    StateName: "BIHAR",
  },
  {
    Pincode: 176047,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176208,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 466120,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 177106,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176502,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176028,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177117,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177104,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 835230,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835220,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835223,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835226,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 193122,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 793115,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 835202,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835205,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834006,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 181202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 577537,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577538,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577539,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571313,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571439,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571443,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571442,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571444,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571342,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571441,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 181221,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181203,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181207,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180002,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184206,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184143,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193402,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184204,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 143108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143202,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143203,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 802209,
    StateName: "BIHAR",
  },
  {
    Pincode: 571110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583285,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583238,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585419,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 126111,
    StateName: "HARYANA",
  },
  {
    Pincode: 756134,
    StateName: "ODISHA",
  },
  {
    Pincode: 132115,
    StateName: "HARYANA",
  },
  {
    Pincode: 585445,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585437,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585443,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585444,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585331,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585421,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585436,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 829208,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 177107,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176032,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176053,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 586209,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586206,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752017,
    StateName: "ODISHA",
  },
  {
    Pincode: 180017,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181204,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 361310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 686102,
    StateName: "KERALA",
  },
  {
    Pincode: 686103,
    StateName: "KERALA",
  },
  {
    Pincode: 176026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 833214,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 752002,
    StateName: "ODISHA",
  },
  {
    Pincode: 752014,
    StateName: "ODISHA",
  },
  {
    Pincode: 752046,
    StateName: "ODISHA",
  },
  {
    Pincode: 583278,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583281,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583280,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 767019,
    StateName: "ODISHA",
  },
  {
    Pincode: 822122,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822110,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835224,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835233,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 756115,
    StateName: "ODISHA",
  },
  {
    Pincode: 148023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148019,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148106,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148020,
    StateName: "PUNJAB",
  },
  {
    Pincode: 571122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577526,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 829204,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834002,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 793005,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 389160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 445002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445324,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 389155,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389152,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389170,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 799143,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799115,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799145,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799144,
    StateName: "TRIPURA",
  },
  {
    Pincode: 362222,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396439,
    StateName: "GUJARAT",
  },
  {
    Pincode: 414005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 333012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144526,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144525,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144417,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144505,
    StateName: "PUNJAB",
  },
  {
    Pincode: 413702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 173235,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 414002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577425,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 828307,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829205,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 413601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413525,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 767018,
    StateName: "ODISHA",
  },
  {
    Pincode: 767023,
    StateName: "ODISHA",
  },
  {
    Pincode: 587204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587312,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587313,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587330,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587315,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 829207,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 388260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388520,
    StateName: "GUJARAT",
  },
  {
    Pincode: 767022,
    StateName: "ODISHA",
  },
  {
    Pincode: 767040,
    StateName: "ODISHA",
  },
  {
    Pincode: 767026,
    StateName: "ODISHA",
  },
  {
    Pincode: 767048,
    StateName: "ODISHA",
  },
  {
    Pincode: 767065,
    StateName: "ODISHA",
  },
  {
    Pincode: 767024,
    StateName: "ODISHA",
  },
  {
    Pincode: 767029,
    StateName: "ODISHA",
  },
  {
    Pincode: 767061,
    StateName: "ODISHA",
  },
  {
    Pincode: 767046,
    StateName: "ODISHA",
  },
  {
    Pincode: 767038,
    StateName: "ODISHA",
  },
  {
    Pincode: 767067,
    StateName: "ODISHA",
  },
  {
    Pincode: 767027,
    StateName: "ODISHA",
  },
  {
    Pincode: 388530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388205,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388130,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388540,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 752045,
    StateName: "ODISHA",
  },
  {
    Pincode: 175001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175121,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175033,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175004,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 583283,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583230,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583277,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587155,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587207,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 828114,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 414606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 814146,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 396433,
    StateName: "GUJARAT",
  },
  {
    Pincode: 587116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 799131,
    StateName: "TRIPURA",
  },
  {
    Pincode: 793112,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793010,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 587121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 756122,
    StateName: "ODISHA",
  },
  {
    Pincode: 756116,
    StateName: "ODISHA",
  },
  {
    Pincode: 686541,
    StateName: "KERALA",
  },
  {
    Pincode: 686544,
    StateName: "KERALA",
  },
  {
    Pincode: 686533,
    StateName: "KERALA",
  },
  {
    Pincode: 686543,
    StateName: "KERALA",
  },
  {
    Pincode: 686542,
    StateName: "KERALA",
  },
  {
    Pincode: 686536,
    StateName: "KERALA",
  },
  {
    Pincode: 686532,
    StateName: "KERALA",
  },
  {
    Pincode: 686539,
    StateName: "KERALA",
  },
  {
    Pincode: 686535,
    StateName: "KERALA",
  },
  {
    Pincode: 686538,
    StateName: "KERALA",
  },
  {
    Pincode: 686503,
    StateName: "KERALA",
  },
  {
    Pincode: 686540,
    StateName: "KERALA",
  },
  {
    Pincode: 686105,
    StateName: "KERALA",
  },
  {
    Pincode: 396469,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396475,
    StateName: "GUJARAT",
  },
  {
    Pincode: 814148,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816118,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 173025,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 414504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 754107,
    StateName: "ODISHA",
  },
  {
    Pincode: 754102,
    StateName: "ODISHA",
  },
  {
    Pincode: 754108,
    StateName: "ODISHA",
  },
  {
    Pincode: 148035,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148034,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148031,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148033,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148027,
    StateName: "PUNJAB",
  },
  {
    Pincode: 184203,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 814110,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 396472,
    StateName: "GUJARAT",
  },
  {
    Pincode: 816108,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816109,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816129,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816116,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 173218,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 828125,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 431701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 454441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 173204,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 144504,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144502,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144518,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144515,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144512,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144501,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144510,
    StateName: "PUNJAB",
  },
  {
    Pincode: 431507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 822113,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828112,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827009,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828104,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 756023,
    StateName: "ODISHA",
  },
  {
    Pincode: 756087,
    StateName: "ODISHA",
  },
  {
    Pincode: 144516,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144416,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144418,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144409,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 442605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 395008,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363427,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363755,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363351,
    StateName: "GUJARAT",
  },
  {
    Pincode: 685501,
    StateName: "KERALA",
  },
  {
    Pincode: 685514,
    StateName: "KERALA",
  },
  {
    Pincode: 791001,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791120,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 175128,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175134,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 144524,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144509,
    StateName: "PUNJAB",
  },
  {
    Pincode: 686691,
    StateName: "KERALA",
  },
  {
    Pincode: 683545,
    StateName: "KERALA",
  },
  {
    Pincode: 683556,
    StateName: "KERALA",
  },
  {
    Pincode: 683546,
    StateName: "KERALA",
  },
  {
    Pincode: 173213,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 194105,
    StateName: "LADAKH",
  },
  {
    Pincode: 305801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 177212,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177219,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 816117,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822115,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822131,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822116,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822118,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829206,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 363765,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 148107,
    StateName: "PUNJAB",
  },
  {
    Pincode: 415727,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415640,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 822128,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 444704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444717,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444719,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 388305,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 833101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 415702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415641,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444809,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444808,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444813,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444723,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444706,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 177113,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176055,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 799105,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799013,
    StateName: "TRIPURA",
  },
  {
    Pincode: 444005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 175013,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175050,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175008,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 791113,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 175023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175052,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175034,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176090,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175024,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175002,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175126,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 560077,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686534,
    StateName: "KERALA",
  },
  {
    Pincode: 799153,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799132,
    StateName: "TRIPURA",
  },
  {
    Pincode: 756166,
    StateName: "ODISHA",
  },
  {
    Pincode: 756138,
    StateName: "ODISHA",
  },
  {
    Pincode: 756137,
    StateName: "ODISHA",
  },
  {
    Pincode: 362020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362250,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362037,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560056,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560076,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560083,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 799150,
    StateName: "TRIPURA",
  },
  {
    Pincode: 752032,
    StateName: "ODISHA",
  },
  {
    Pincode: 587316,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 144511,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144506,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144531,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146107,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146111,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146112,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144528,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144529,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144532,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146109,
    StateName: "PUNJAB",
  },
  {
    Pincode: 396240,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 175021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 454660,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454552,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 682007,
    StateName: "KERALA",
  },
  {
    Pincode: 682509,
    StateName: "KERALA",
  },
  {
    Pincode: 683547,
    StateName: "KERALA",
  },
  {
    Pincode: 686693,
    StateName: "KERALA",
  },
  {
    Pincode: 686692,
    StateName: "KERALA",
  },
  {
    Pincode: 303006,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 454446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 175104,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175131,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 685512,
    StateName: "KERALA",
  },
  {
    Pincode: 685602,
    StateName: "KERALA",
  },
  {
    Pincode: 685606,
    StateName: "KERALA",
  },
  {
    Pincode: 686545,
    StateName: "KERALA",
  },
  {
    Pincode: 192401,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192212,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192123,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 833221,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 685515,
    StateName: "KERALA",
  },
  {
    Pincode: 685601,
    StateName: "KERALA",
  },
  {
    Pincode: 685509,
    StateName: "KERALA",
  },
  {
    Pincode: 685604,
    StateName: "KERALA",
  },
  {
    Pincode: 685535,
    StateName: "KERALA",
  },
  {
    Pincode: 685553,
    StateName: "KERALA",
  },
  {
    Pincode: 685531,
    StateName: "KERALA",
  },
  {
    Pincode: 685532,
    StateName: "KERALA",
  },
  {
    Pincode: 685554,
    StateName: "KERALA",
  },
  {
    Pincode: 685505,
    StateName: "KERALA",
  },
  {
    Pincode: 685503,
    StateName: "KERALA",
  },
  {
    Pincode: 685551,
    StateName: "KERALA",
  },
  {
    Pincode: 685533,
    StateName: "KERALA",
  },
  {
    Pincode: 685561,
    StateName: "KERALA",
  },
  {
    Pincode: 685565,
    StateName: "KERALA",
  },
  {
    Pincode: 685613,
    StateName: "KERALA",
  },
  {
    Pincode: 685588,
    StateName: "KERALA",
  },
  {
    Pincode: 685562,
    StateName: "KERALA",
  },
  {
    Pincode: 685581,
    StateName: "KERALA",
  },
  {
    Pincode: 136119,
    StateName: "HARYANA",
  },
  {
    Pincode: 303009,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303348,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303005,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 173215,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 443002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577540,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571320,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571457,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 756139,
    StateName: "ODISHA",
  },
  {
    Pincode: 576117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574197,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 754109,
    StateName: "ODISHA",
  },
  {
    Pincode: 442507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144020,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146106,
    StateName: "PUNJAB",
  },
  {
    Pincode: 388110,
    StateName: "GUJARAT",
  },
  {
    Pincode: 444807,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 136132,
    StateName: "HARYANA",
  },
  {
    Pincode: 136131,
    StateName: "HARYANA",
  },
  {
    Pincode: 136135,
    StateName: "HARYANA",
  },
  {
    Pincode: 136136,
    StateName: "HARYANA",
  },
  {
    Pincode: 797003,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798625,
    StateName: "NAGALAND",
  },
  {
    Pincode: 583284,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583287,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587311,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574236,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574244,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574242,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591304,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591248,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591303,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 443102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 799113,
    StateName: "TRIPURA",
  },
  {
    Pincode: 587206,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 192232,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193411,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191111,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 124508,
    StateName: "HARYANA",
  },
  {
    Pincode: 124505,
    StateName: "HARYANA",
  },
  {
    Pincode: 124105,
    StateName: "HARYANA",
  },
  {
    Pincode: 124504,
    StateName: "HARYANA",
  },
  {
    Pincode: 124404,
    StateName: "HARYANA",
  },
  {
    Pincode: 124501,
    StateName: "HARYANA",
  },
  {
    Pincode: 124142,
    StateName: "HARYANA",
  },
  {
    Pincode: 191113,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191112,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 193401,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 795113,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795001,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795103,
    StateName: "MANIPUR",
  },
  {
    Pincode: 833203,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833215,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833104,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833218,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833105,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 146108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 390019,
    StateName: "GUJARAT",
  },
  {
    Pincode: 816110,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 390022,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390014,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391780,
    StateName: "GUJARAT",
  },
  {
    Pincode: 415715,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415708,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415722,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 796581,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796190,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796007,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796111,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796070,
    StateName: "MIZORAM",
  },
  {
    Pincode: 415202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415716,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415628,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 560099,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 362002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362227,
    StateName: "GUJARAT",
  },
  {
    Pincode: 190015,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 192121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 124411,
    StateName: "HARYANA",
  },
  {
    Pincode: 124021,
    StateName: "HARYANA",
  },
  {
    Pincode: 191201,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191131,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 560064,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 362215,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362245,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362260,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362565,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362710,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362530,
    StateName: "GUJARAT",
  },
  {
    Pincode: 443206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 391740,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391244,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391774,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391510,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362720,
    StateName: "GUJARAT",
  },
  {
    Pincode: 362550,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560060,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 362030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 798612,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798616,
    StateName: "NAGALAND",
  },
  {
    Pincode: 136043,
    StateName: "HARYANA",
  },
  {
    Pincode: 136026,
    StateName: "HARYANA",
  },
  {
    Pincode: 136033,
    StateName: "HARYANA",
  },
  {
    Pincode: 136021,
    StateName: "HARYANA",
  },
  {
    Pincode: 685507,
    StateName: "KERALA",
  },
  {
    Pincode: 685552,
    StateName: "KERALA",
  },
  {
    Pincode: 685609,
    StateName: "KERALA",
  },
  {
    Pincode: 396215,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 396210,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 792122,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 175130,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396105,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396193,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396050,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396375,
    StateName: "GUJARAT",
  },
  {
    Pincode: 303338,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303124,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 175012,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175124,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175042,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175025,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 396120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 797006,
    StateName: "NAGALAND",
  },
  {
    Pincode: 481778,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 194106,
    StateName: "LADAKH",
  },
  {
    Pincode: 362011,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391450,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391220,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391430,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391440,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391243,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 793008,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793006,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793015,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 799142,
    StateName: "TRIPURA",
  },
  {
    Pincode: 415724,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 390009,
    StateName: "GUJARAT",
  },
  {
    Pincode: 144419,
    StateName: "PUNJAB",
  },
  {
    Pincode: 390010,
    StateName: "GUJARAT",
  },
  {
    Pincode: 854338,
    StateName: "BIHAR",
  },
  {
    Pincode: 798614,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798627,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798613,
    StateName: "NAGALAND",
  },
  {
    Pincode: 791123,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 686106,
    StateName: "KERALA",
  },
  {
    Pincode: 686104,
    StateName: "KERALA",
  },
  {
    Pincode: 686546,
    StateName: "KERALA",
  },
  {
    Pincode: 829210,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 483001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 454773,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 577215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577302,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 331803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 585302,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 277205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 797106,
    StateName: "NAGALAND",
  },
  {
    Pincode: 481996,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481768,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481664,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481776,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481885,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481662,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481663,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481668,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 281204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 691536,
    StateName: "KERALA",
  },
  {
    Pincode: 691509,
    StateName: "KERALA",
  },
  {
    Pincode: 691541,
    StateName: "KERALA",
  },
  {
    Pincode: 691531,
    StateName: "KERALA",
  },
  {
    Pincode: 691507,
    StateName: "KERALA",
  },
  {
    Pincode: 691532,
    StateName: "KERALA",
  },
  {
    Pincode: 691560,
    StateName: "KERALA",
  },
  {
    Pincode: 691521,
    StateName: "KERALA",
  },
  {
    Pincode: 571490,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591305,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591254,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591309,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591310,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591233,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591312,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591340,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591236,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591313,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591306,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 796015,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796014,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796081,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796571,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796321,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796710,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796770,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796751,
    StateName: "MIZORAM",
  },
  {
    Pincode: 685511,
    StateName: "KERALA",
  },
  {
    Pincode: 685615,
    StateName: "KERALA",
  },
  {
    Pincode: 136044,
    StateName: "HARYANA",
  },
  {
    Pincode: 560034,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 133205,
    StateName: "HARYANA",
  },
  {
    Pincode: 133201,
    StateName: "HARYANA",
  },
  {
    Pincode: 133102,
    StateName: "HARYANA",
  },
  {
    Pincode: 133004,
    StateName: "HARYANA",
  },
  {
    Pincode: 133203,
    StateName: "HARYANA",
  },
  {
    Pincode: 133104,
    StateName: "HARYANA",
  },
  {
    Pincode: 133206,
    StateName: "HARYANA",
  },
  {
    Pincode: 175019,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175035,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175125,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175045,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175028,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175030,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175047,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175011,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 135106,
    StateName: "HARYANA",
  },
  {
    Pincode: 135102,
    StateName: "HARYANA",
  },
  {
    Pincode: 136128,
    StateName: "HARYANA",
  },
  {
    Pincode: 135101,
    StateName: "HARYANA",
  },
  {
    Pincode: 135103,
    StateName: "HARYANA",
  },
  {
    Pincode: 135003,
    StateName: "HARYANA",
  },
  {
    Pincode: 135002,
    StateName: "HARYANA",
  },
  {
    Pincode: 133103,
    StateName: "HARYANA",
  },
  {
    Pincode: 135133,
    StateName: "HARYANA",
  },
  {
    Pincode: 133204,
    StateName: "HARYANA",
  },
  {
    Pincode: 135004,
    StateName: "HARYANA",
  },
  {
    Pincode: 185131,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185155,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185233,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 695501,
    StateName: "KERALA",
  },
  {
    Pincode: 695123,
    StateName: "KERALA",
  },
  {
    Pincode: 695132,
    StateName: "KERALA",
  },
  {
    Pincode: 695524,
    StateName: "KERALA",
  },
  {
    Pincode: 689126,
    StateName: "KERALA",
  },
  {
    Pincode: 689511,
    StateName: "KERALA",
  },
  {
    Pincode: 689124,
    StateName: "KERALA",
  },
  {
    Pincode: 689533,
    StateName: "KERALA",
  },
  {
    Pincode: 689612,
    StateName: "KERALA",
  },
  {
    Pincode: 689611,
    StateName: "KERALA",
  },
  {
    Pincode: 689531,
    StateName: "KERALA",
  },
  {
    Pincode: 689614,
    StateName: "KERALA",
  },
  {
    Pincode: 185211,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 396215,
    StateName: "GUJARAT",
  },
  {
    Pincode: 682508,
    StateName: "KERALA",
  },
  {
    Pincode: 682006,
    StateName: "KERALA",
  },
  {
    Pincode: 242221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 194109,
    StateName: "LADAKH",
  },
  {
    Pincode: 194302,
    StateName: "LADAKH",
  },
  {
    Pincode: 194301,
    StateName: "LADAKH",
  },
  {
    Pincode: 194303,
    StateName: "LADAKH",
  },
  {
    Pincode: 676508,
    StateName: "KERALA",
  },
  {
    Pincode: 676552,
    StateName: "KERALA",
  },
  {
    Pincode: 242223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 390012,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 591221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583268,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583279,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583235,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583236,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582207,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582209,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 582211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 795122,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795149,
    StateName: "MANIPUR",
  },
  {
    Pincode: 212201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221112,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 185132,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 232105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232106,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 362315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 185202,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185156,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185152,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185102,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185151,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 135021,
    StateName: "HARYANA",
  },
  {
    Pincode: 185135,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 627152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627356,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 574285,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 388315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 835218,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 124109,
    StateName: "HARYANA",
  },
  {
    Pincode: 124107,
    StateName: "HARYANA",
  },
  {
    Pincode: 191121,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190006,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 127307,
    StateName: "HARYANA",
  },
  {
    Pincode: 127308,
    StateName: "HARYANA",
  },
  {
    Pincode: 127312,
    StateName: "HARYANA",
  },
  {
    Pincode: 127025,
    StateName: "HARYANA",
  },
  {
    Pincode: 190008,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 185234,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 691512,
    StateName: "KERALA",
  },
  {
    Pincode: 691566,
    StateName: "KERALA",
  },
  {
    Pincode: 691537,
    StateName: "KERALA",
  },
  {
    Pincode: 691538,
    StateName: "KERALA",
  },
  {
    Pincode: 281205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 481880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481882,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481883,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481884,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 175037,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175010,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 242226,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 673315,
    StateName: "KERALA",
  },
  {
    Pincode: 673612,
    StateName: "KERALA",
  },
  {
    Pincode: 673306,
    StateName: "KERALA",
  },
  {
    Pincode: 673524,
    StateName: "KERALA",
  },
  {
    Pincode: 695503,
    StateName: "KERALA",
  },
  {
    Pincode: 689542,
    StateName: "KERALA",
  },
  {
    Pincode: 689547,
    StateName: "KERALA",
  },
  {
    Pincode: 689546,
    StateName: "KERALA",
  },
  {
    Pincode: 591227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591344,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591243,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 190011,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190017,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190003,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 124401,
    StateName: "HARYANA",
  },
  {
    Pincode: 124202,
    StateName: "HARYANA",
  },
  {
    Pincode: 281404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 638311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 691543,
    StateName: "KERALA",
  },
  {
    Pincode: 691535,
    StateName: "KERALA",
  },
  {
    Pincode: 691540,
    StateName: "KERALA",
  },
  {
    Pincode: 127111,
    StateName: "HARYANA",
  },
  {
    Pincode: 833216,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833219,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833220,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 636903,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636812,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 124506,
    StateName: "HARYANA",
  },
  {
    Pincode: 682554,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 193404,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 583234,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 636905,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636810,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636811,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636906,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635651,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635655,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635653,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 585303,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585305,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585306,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585307,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585308,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 390003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 583134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 627133,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627127,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627354,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627355,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 574202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574248,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577520,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 630411,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 577521,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577518,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577522,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577524,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577557,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577546,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 628201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 334202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 689545,
    StateName: "KERALA",
  },
  {
    Pincode: 689544,
    StateName: "KERALA",
  },
  {
    Pincode: 689583,
    StateName: "KERALA",
  },
  {
    Pincode: 689582,
    StateName: "KERALA",
  },
  {
    Pincode: 480661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689122,
    StateName: "KERALA",
  },
  {
    Pincode: 689532,
    StateName: "KERALA",
  },
  {
    Pincode: 689548,
    StateName: "KERALA",
  },
  {
    Pincode: 689543,
    StateName: "KERALA",
  },
  {
    Pincode: 638656,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638657,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638661,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638673,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 190019,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190018,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 191103,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 124201,
    StateName: "HARYANA",
  },
  {
    Pincode: 583282,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577599,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577598,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586207,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571404,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581356,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581358,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586208,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 415634,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577547,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 244221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 134202,
    StateName: "HARYANA",
  },
  {
    Pincode: 581351,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581361,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581333,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581320,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581323,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581384,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585287,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 628622,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143416,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143414,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143302,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143118,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758029,
    StateName: "ODISHA",
  },
  {
    Pincode: 758027,
    StateName: "ODISHA",
  },
  {
    Pincode: 143410,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758025,
    StateName: "ODISHA",
  },
  {
    Pincode: 563138,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 758023,
    StateName: "ODISHA",
  },
  {
    Pincode: 758028,
    StateName: "ODISHA",
  },
  {
    Pincode: 758083,
    StateName: "ODISHA",
  },
  {
    Pincode: 691516,
    StateName: "KERALA",
  },
  {
    Pincode: 691510,
    StateName: "KERALA",
  },
  {
    Pincode: 481879,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 586210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 682507,
    StateName: "KERALA",
  },
  {
    Pincode: 676304,
    StateName: "KERALA",
  },
  {
    Pincode: 676317,
    StateName: "KERALA",
  },
  {
    Pincode: 673304,
    StateName: "KERALA",
  },
  {
    Pincode: 673526,
    StateName: "KERALA",
  },
  {
    Pincode: 194202,
    StateName: "LADAKH",
  },
  {
    Pincode: 194201,
    StateName: "LADAKH",
  },
  {
    Pincode: 758084,
    StateName: "ODISHA",
  },
  {
    Pincode: 758034,
    StateName: "ODISHA",
  },
  {
    Pincode: 758044,
    StateName: "ODISHA",
  },
  {
    Pincode: 758040,
    StateName: "ODISHA",
  },
  {
    Pincode: 758014,
    StateName: "ODISHA",
  },
  {
    Pincode: 144039,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758022,
    StateName: "ODISHA",
  },
  {
    Pincode: 758015,
    StateName: "ODISHA",
  },
  {
    Pincode: 560091,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562157,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 416519,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 586125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587154,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581359,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 673616,
    StateName: "KERALA",
  },
  {
    Pincode: 673010,
    StateName: "KERALA",
  },
  {
    Pincode: 673572,
    StateName: "KERALA",
  },
  {
    Pincode: 673323,
    StateName: "KERALA",
  },
  {
    Pincode: 673307,
    StateName: "KERALA",
  },
  {
    Pincode: 673527,
    StateName: "KERALA",
  },
  {
    Pincode: 673310,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 673571,
    StateName: "KERALA",
  },
  {
    Pincode: 673604,
    StateName: "KERALA",
  },
  {
    Pincode: 673602,
    StateName: "KERALA",
  },
  {
    Pincode: 673601,
    StateName: "KERALA",
  },
  {
    Pincode: 673582,
    StateName: "KERALA",
  },
  {
    Pincode: 673586,
    StateName: "KERALA",
  },
  {
    Pincode: 673573,
    StateName: "KERALA",
  },
  {
    Pincode: 673603,
    StateName: "KERALA",
  },
  {
    Pincode: 673015,
    StateName: "KERALA",
  },
  {
    Pincode: 673008,
    StateName: "KERALA",
  },
  {
    Pincode: 673614,
    StateName: "KERALA",
  },
  {
    Pincode: 577116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577180,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577548,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574260,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 272124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 172001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172027,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 673528,
    StateName: "KERALA",
  },
  {
    Pincode: 673620,
    StateName: "KERALA",
  },
  {
    Pincode: 673317,
    StateName: "KERALA",
  },
  {
    Pincode: 673106,
    StateName: "KERALA",
  },
  {
    Pincode: 673507,
    StateName: "KERALA",
  },
  {
    Pincode: 673506,
    StateName: "KERALA",
  },
  {
    Pincode: 242127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 585228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585324,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342310,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 345025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 345021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 571314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 272175,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272173,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272172,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272271,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 571315,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591156,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 673513,
    StateName: "KERALA",
  },
  {
    Pincode: 673508,
    StateName: "KERALA",
  },
  {
    Pincode: 638702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689506,
    StateName: "KERALA",
  },
  {
    Pincode: 689573,
    StateName: "KERALA",
  },
  {
    Pincode: 689123,
    StateName: "KERALA",
  },
  {
    Pincode: 695542,
    StateName: "KERALA",
  },
  {
    Pincode: 695572,
    StateName: "KERALA",
  },
  {
    Pincode: 695574,
    StateName: "KERALA",
  },
  {
    Pincode: 695541,
    StateName: "KERALA",
  },
  {
    Pincode: 695568,
    StateName: "KERALA",
  },
  {
    Pincode: 695573,
    StateName: "KERALA",
  },
  {
    Pincode: 695012,
    StateName: "KERALA",
  },
  {
    Pincode: 171201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171203,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171011,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 816101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 758079,
    StateName: "ODISHA",
  },
  {
    Pincode: 758031,
    StateName: "ODISHA",
  },
  {
    Pincode: 561204,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686514,
    StateName: "KERALA",
  },
  {
    Pincode: 581411,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571811,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571423,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571807,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571426,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571427,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571431,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571432,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571434,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 144819,
    StateName: "PUNJAB",
  },
  {
    Pincode: 563147,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 758002,
    StateName: "ODISHA",
  },
  {
    Pincode: 673104,
    StateName: "KERALA",
  },
  {
    Pincode: 673501,
    StateName: "KERALA",
  },
  {
    Pincode: 673509,
    StateName: "KERALA",
  },
  {
    Pincode: 673522,
    StateName: "KERALA",
  },
  {
    Pincode: 673105,
    StateName: "KERALA",
  },
  {
    Pincode: 673611,
    StateName: "KERALA",
  },
  {
    Pincode: 673580,
    StateName: "KERALA",
  },
  {
    Pincode: 345023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 133005,
    StateName: "HARYANA",
  },
  {
    Pincode: 133202,
    StateName: "HARYANA",
  },
  {
    Pincode: 134109,
    StateName: "HARYANA",
  },
  {
    Pincode: 134204,
    StateName: "HARYANA",
  },
  {
    Pincode: 762029,
    StateName: "ODISHA",
  },
  {
    Pincode: 762022,
    StateName: "ODISHA",
  },
  {
    Pincode: 762012,
    StateName: "ODISHA",
  },
  {
    Pincode: 762105,
    StateName: "ODISHA",
  },
  {
    Pincode: 673585,
    StateName: "KERALA",
  },
  {
    Pincode: 695026,
    StateName: "KERALA",
  },
  {
    Pincode: 695027,
    StateName: "KERALA",
  },
  {
    Pincode: 695006,
    StateName: "KERALA",
  },
  {
    Pincode: 695013,
    StateName: "KERALA",
  },
  {
    Pincode: 695551,
    StateName: "KERALA",
  },
  {
    Pincode: 638660,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689510,
    StateName: "KERALA",
  },
  {
    Pincode: 689594,
    StateName: "KERALA",
  },
  {
    Pincode: 689102,
    StateName: "KERALA",
  },
  {
    Pincode: 689581,
    StateName: "KERALA",
  },
  {
    Pincode: 641653,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641665,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 560074,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 679308,
    StateName: "KERALA",
  },
  {
    Pincode: 232101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212111,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212106,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212107,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 127311,
    StateName: "HARYANA",
  },
  {
    Pincode: 562160,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562138,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581334,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581335,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 232104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221011,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221116,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232111,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 591345,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591143,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591302,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590014,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590020,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590018,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571312,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 635654,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 571129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571302,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590015,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591346,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 141412,
    StateName: "PUNJAB",
  },
  {
    Pincode: 591124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 635652,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 762109,
    StateName: "ODISHA",
  },
  {
    Pincode: 762027,
    StateName: "ODISHA",
  },
  {
    Pincode: 762102,
    StateName: "ODISHA",
  },
  {
    Pincode: 762112,
    StateName: "ODISHA",
  },
  {
    Pincode: 762011,
    StateName: "ODISHA",
  },
  {
    Pincode: 762101,
    StateName: "ODISHA",
  },
  {
    Pincode: 762106,
    StateName: "ODISHA",
  },
  {
    Pincode: 832404,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 209743,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209652,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 695527,
    StateName: "KERALA",
  },
  {
    Pincode: 695043,
    StateName: "KERALA",
  },
  {
    Pincode: 695571,
    StateName: "KERALA",
  },
  {
    Pincode: 695020,
    StateName: "KERALA",
  },
  {
    Pincode: 695562,
    StateName: "KERALA",
  },
  {
    Pincode: 695561,
    StateName: "KERALA",
  },
  {
    Pincode: 695563,
    StateName: "KERALA",
  },
  {
    Pincode: 695575,
    StateName: "KERALA",
  },
  {
    Pincode: 758081,
    StateName: "ODISHA",
  },
  {
    Pincode: 758036,
    StateName: "ODISHA",
  },
  {
    Pincode: 144703,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144806,
    StateName: "PUNJAB",
  },
  {
    Pincode: 571316,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591307,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 127032,
    StateName: "HARYANA",
  },
  {
    Pincode: 127028,
    StateName: "HARYANA",
  },
  {
    Pincode: 127027,
    StateName: "HARYANA",
  },
  {
    Pincode: 127035,
    StateName: "HARYANA",
  },
  {
    Pincode: 127030,
    StateName: "HARYANA",
  },
  {
    Pincode: 127029,
    StateName: "HARYANA",
  },
  {
    Pincode: 127114,
    StateName: "HARYANA",
  },
  {
    Pincode: 127201,
    StateName: "HARYANA",
  },
  {
    Pincode: 127309,
    StateName: "HARYANA",
  },
  {
    Pincode: 127043,
    StateName: "HARYANA",
  },
  {
    Pincode: 127031,
    StateName: "HARYANA",
  },
  {
    Pincode: 833217,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833202,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832303,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832102,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835227,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835221,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 211008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636902,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636904,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 591232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591235,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591240,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591265,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591316,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 140506,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140507,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140501,
    StateName: "PUNJAB",
  },
  {
    Pincode: 754112,
    StateName: "ODISHA",
  },
  {
    Pincode: 127026,
    StateName: "HARYANA",
  },
  {
    Pincode: 127306,
    StateName: "HARYANA",
  },
  {
    Pincode: 127022,
    StateName: "HARYANA",
  },
  {
    Pincode: 127310,
    StateName: "HARYANA",
  },
  {
    Pincode: 127042,
    StateName: "HARYANA",
  },
  {
    Pincode: 560090,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560089,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563157,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563163,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563139,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 816120,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816105,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 813208,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832304,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831012,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832104,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832105,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831002,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 173022,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173030,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173104,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173233,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173214,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173206,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173234,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177110,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 814118,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814158,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816104,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814141,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 762019,
    StateName: "ODISHA",
  },
  {
    Pincode: 762010,
    StateName: "ODISHA",
  },
  {
    Pincode: 762107,
    StateName: "ODISHA",
  },
  {
    Pincode: 209501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 762020,
    StateName: "ODISHA",
  },
  {
    Pincode: 762023,
    StateName: "ODISHA",
  },
  {
    Pincode: 762017,
    StateName: "ODISHA",
  },
  {
    Pincode: 762016,
    StateName: "ODISHA",
  },
  {
    Pincode: 754100,
    StateName: "ODISHA",
  },
  {
    Pincode: 753011,
    StateName: "ODISHA",
  },
  {
    Pincode: 581363,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581315,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581322,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581329,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 627104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627353,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 577523,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 172021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172022,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 574122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581331,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581336,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571415,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571445,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571477,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571435,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 762013,
    StateName: "ODISHA",
  },
  {
    Pincode: 832401,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 342001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 175038,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175046,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175017,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175018,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175009,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 175048,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 828401,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 173021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173220,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174102,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176311,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176321,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176319,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176320,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176207,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 814166,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 176301,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176308,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176316,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176315,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176302,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176326,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176309,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176318,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 342014,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 581327,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581330,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581332,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 345026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628623,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 832108,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832107,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832403,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 562149,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686508,
    StateName: "KERALA",
  },
  {
    Pincode: 686511,
    StateName: "KERALA",
  },
  {
    Pincode: 686513,
    StateName: "KERALA",
  },
  {
    Pincode: 686512,
    StateName: "KERALA",
  },
  {
    Pincode: 686506,
    StateName: "KERALA",
  },
  {
    Pincode: 628621,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 396240,
    StateName: "GUJARAT",
  },
  {
    Pincode: 272153,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 176323,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176325,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 814119,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814144,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 174303,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177203,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174307,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177109,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174316,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177204,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174306,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 176601,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 673525,
    StateName: "KERALA",
  },
  {
    Pincode: 175039,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 134102,
    StateName: "HARYANA",
  },
  {
    Pincode: 172026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172032,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172023,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172107,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172105,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172111,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172116,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 577555,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 673574,
    StateName: "KERALA",
  },
  {
    Pincode: 673308,
    StateName: "KERALA",
  },
  {
    Pincode: 673502,
    StateName: "KERALA",
  },
  {
    Pincode: 682303,
    StateName: "KERALA",
  },
  {
    Pincode: 682312,
    StateName: "KERALA",
  },
  {
    Pincode: 695010,
    StateName: "KERALA",
  },
  {
    Pincode: 590010,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686520,
    StateName: "KERALA",
  },
  {
    Pincode: 686509,
    StateName: "KERALA",
  },
  {
    Pincode: 686522,
    StateName: "KERALA",
  },
  {
    Pincode: 695543,
    StateName: "KERALA",
  },
  {
    Pincode: 689112,
    StateName: "KERALA",
  },
  {
    Pincode: 686547,
    StateName: "KERALA",
  },
  {
    Pincode: 689586,
    StateName: "KERALA",
  },
  {
    Pincode: 689587,
    StateName: "KERALA",
  },
  {
    Pincode: 689615,
    StateName: "KERALA",
  },
  {
    Pincode: 689549,
    StateName: "KERALA",
  },
  {
    Pincode: 689613,
    StateName: "KERALA",
  },
  {
    Pincode: 689113,
    StateName: "KERALA",
  },
  {
    Pincode: 695512,
    StateName: "KERALA",
  },
  {
    Pincode: 695526,
    StateName: "KERALA",
  },
  {
    Pincode: 695525,
    StateName: "KERALA",
  },
  {
    Pincode: 686518,
    StateName: "KERALA",
  },
  {
    Pincode: 560087,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 121004,
    StateName: "HARYANA",
  },
  {
    Pincode: 121107,
    StateName: "HARYANA",
  },
  {
    Pincode: 121103,
    StateName: "HARYANA",
  },
  {
    Pincode: 160101,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160003,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 586122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 586127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 673102,
    StateName: "KERALA",
  },
  {
    Pincode: 673504,
    StateName: "KERALA",
  },
  {
    Pincode: 673503,
    StateName: "KERALA",
  },
  {
    Pincode: 673541,
    StateName: "KERALA",
  },
  {
    Pincode: 673613,
    StateName: "KERALA",
  },
  {
    Pincode: 171301,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 590008,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 232118,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 831004,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832112,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833204,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 345022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 584123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 628204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 585330,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585417,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686510,
    StateName: "KERALA",
  },
  {
    Pincode: 686502,
    StateName: "KERALA",
  },
  {
    Pincode: 127041,
    StateName: "HARYANA",
  },
  {
    Pincode: 585353,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591230,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591234,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 481998,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 581111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572133,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563162,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563161,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 636352,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 581116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581148,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 481995,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481672,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 281121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 172106,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 209651,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 835201,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835228,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835235,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835211,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 591263,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591311,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591238,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 835212,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835213,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 211011,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 762026,
    StateName: "ODISHA",
  },
  {
    Pincode: 835229,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835203,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835231,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835206,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 835208,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 127046,
    StateName: "HARYANA",
  },
  {
    Pincode: 127040,
    StateName: "HARYANA",
  },
  {
    Pincode: 487330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 560073,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 431718,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431808,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 172024,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172029,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172102,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172030,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172114,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172117,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172113,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172108,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172118,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172112,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172109,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 182101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 131305,
    StateName: "HARYANA",
  },
  {
    Pincode: 131402,
    StateName: "HARYANA",
  },
  {
    Pincode: 431742,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431708,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431721,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 686585,
    StateName: "KERALA",
  },
  {
    Pincode: 686555,
    StateName: "KERALA",
  },
  {
    Pincode: 686521,
    StateName: "KERALA",
  },
  {
    Pincode: 686515,
    StateName: "KERALA",
  },
  {
    Pincode: 686519,
    StateName: "KERALA",
  },
  {
    Pincode: 686501,
    StateName: "KERALA",
  },
  {
    Pincode: 562117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 326036,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326035,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 131027,
    StateName: "HARYANA",
  },
  {
    Pincode: 131029,
    StateName: "HARYANA",
  },
  {
    Pincode: 131102,
    StateName: "HARYANA",
  },
  {
    Pincode: 131403,
    StateName: "HARYANA",
  },
  {
    Pincode: 560068,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 679501,
    StateName: "KERALA",
  },
  {
    Pincode: 679336,
    StateName: "KERALA",
  },
  {
    Pincode: 560082,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 131306,
    StateName: "HARYANA",
  },
  {
    Pincode: 131103,
    StateName: "HARYANA",
  },
  {
    Pincode: 173211,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 281006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 581119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581198,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342309,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142037,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142038,
    StateName: "PUNJAB",
  },
  {
    Pincode: 232107,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 142039,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142011,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142053,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142048,
    StateName: "PUNJAB",
  },
  {
    Pincode: 581338,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581314,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581316,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581306,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581328,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581339,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581302,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581308,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581345,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581352,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581353,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581357,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581319,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585291,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581326,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584170,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581402,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 160014,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 326501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 627109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627652,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630713,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 574213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577145,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577146,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 272171,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 574104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 174302,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 816103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 577130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577133,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577182,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577138,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577137,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577550,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 172110,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172002,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172025,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172033,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 799101,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799114,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799125,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799104,
    StateName: "TRIPURA",
  },
  {
    Pincode: 176303,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 174314,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 814145,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816107,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 673523,
    StateName: "KERALA",
  },
  {
    Pincode: 673529,
    StateName: "KERALA",
  },
  {
    Pincode: 673521,
    StateName: "KERALA",
  },
  {
    Pincode: 695505,
    StateName: "KERALA",
  },
  {
    Pincode: 638672,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638451,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 682313,
    StateName: "KERALA",
  },
  {
    Pincode: 682027,
    StateName: "KERALA",
  },
  {
    Pincode: 563131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 326513,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325223,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325218,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 487551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 563135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563137,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 176324,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 487221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 325222,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325220,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 560049,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 325207,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326520,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325214,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 828106,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 584103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 142056,
    StateName: "PUNJAB",
  },
  {
    Pincode: 326518,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 444906,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 172031,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172115,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 799157,
    StateName: "TRIPURA",
  },
  {
    Pincode: 444908,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 641401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 695564,
    StateName: "KERALA",
  },
  {
    Pincode: 695002,
    StateName: "KERALA",
  },
  {
    Pincode: 142040,
    StateName: "PUNJAB",
  },
  {
    Pincode: 673009,
    StateName: "KERALA",
  },
  {
    Pincode: 673012,
    StateName: "KERALA",
  },
  {
    Pincode: 673309,
    StateName: "KERALA",
  },
  {
    Pincode: 631301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 799155,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799103,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799156,
    StateName: "TRIPURA",
  },
  {
    Pincode: 577179,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577139,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 133101,
    StateName: "HARYANA",
  },
  {
    Pincode: 584129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577168,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577140,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 631302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602023,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 695521,
    StateName: "KERALA",
  },
  {
    Pincode: 695504,
    StateName: "KERALA",
  },
  {
    Pincode: 431806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431719,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431715,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 581318,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581325,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 587205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 140201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 695125,
    StateName: "KERALA",
  },
  {
    Pincode: 171013,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 695124,
    StateName: "KERALA",
  },
  {
    Pincode: 695506,
    StateName: "KERALA",
  },
  {
    Pincode: 695523,
    StateName: "KERALA",
  },
  {
    Pincode: 563128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563146,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 144631,
    StateName: "PUNJAB",
  },
  {
    Pincode: 121003,
    StateName: "HARYANA",
  },
  {
    Pincode: 602024,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602025,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602026,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 561202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577175,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768029,
    StateName: "ODISHA",
  },
  {
    Pincode: 768030,
    StateName: "ODISHA",
  },
  {
    Pincode: 768104,
    StateName: "ODISHA",
  },
  {
    Pincode: 768045,
    StateName: "ODISHA",
  },
  {
    Pincode: 768032,
    StateName: "ODISHA",
  },
  {
    Pincode: 768052,
    StateName: "ODISHA",
  },
  {
    Pincode: 768037,
    StateName: "ODISHA",
  },
  {
    Pincode: 768034,
    StateName: "ODISHA",
  },
  {
    Pincode: 768042,
    StateName: "ODISHA",
  },
  {
    Pincode: 768048,
    StateName: "ODISHA",
  },
  {
    Pincode: 584135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584133,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 481771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 581202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581193,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581208,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 585222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768050,
    StateName: "ODISHA",
  },
  {
    Pincode: 768035,
    StateName: "ODISHA",
  },
  {
    Pincode: 768039,
    StateName: "ODISHA",
  },
  {
    Pincode: 768036,
    StateName: "ODISHA",
  },
  {
    Pincode: 577160,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577144,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563133,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752061,
    StateName: "ODISHA",
  },
  {
    Pincode: 572175,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 695009,
    StateName: "KERALA",
  },
  {
    Pincode: 585225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581317,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581396,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581400,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585321,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585323,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581337,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 757038,
    StateName: "ODISHA",
  },
  {
    Pincode: 757053,
    StateName: "ODISHA",
  },
  {
    Pincode: 591287,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591317,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591241,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 284403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 480555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480108,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 160002,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160036,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 571455,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591244,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 695502,
    StateName: "KERALA",
  },
  {
    Pincode: 695133,
    StateName: "KERALA",
  },
  {
    Pincode: 121002,
    StateName: "HARYANA",
  },
  {
    Pincode: 757036,
    StateName: "ODISHA",
  },
  {
    Pincode: 591216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591239,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 431709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431743,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431723,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431736,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431711,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 313704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 581365,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752120,
    StateName: "ODISHA",
  },
  {
    Pincode: 313903,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 577129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577549,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 313601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 686504,
    StateName: "KERALA",
  },
  {
    Pincode: 313206,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313902,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313904,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431731,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 682024,
    StateName: "KERALA",
  },
  {
    Pincode: 673638,
    StateName: "KERALA",
  },
  {
    Pincode: 673641,
    StateName: "KERALA",
  },
  {
    Pincode: 676542,
    StateName: "KERALA",
  },
  {
    Pincode: 679333,
    StateName: "KERALA",
  },
  {
    Pincode: 676122,
    StateName: "KERALA",
  },
  {
    Pincode: 676525,
    StateName: "KERALA",
  },
  {
    Pincode: 676123,
    StateName: "KERALA",
  },
  {
    Pincode: 676523,
    StateName: "KERALA",
  },
  {
    Pincode: 577117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 799141,
    StateName: "TRIPURA",
  },
  {
    Pincode: 250341,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 577135,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 480557,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 301001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301414,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301413,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321605,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321606,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301405,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 585355,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577426,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 680721,
    StateName: "KERALA",
  },
  {
    Pincode: 680722,
    StateName: "KERALA",
  },
  {
    Pincode: 680733,
    StateName: "KERALA",
  },
  {
    Pincode: 680303,
    StateName: "KERALA",
  },
  {
    Pincode: 680688,
    StateName: "KERALA",
  },
  {
    Pincode: 679303,
    StateName: "KERALA",
  },
  {
    Pincode: 680687,
    StateName: "KERALA",
  },
  {
    Pincode: 250004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250342,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250344,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250626,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 245206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250222,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250106,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 176313,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 160102,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 591315,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591242,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 313901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 832402,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 793101,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 680724,
    StateName: "KERALA",
  },
  {
    Pincode: 680734,
    StateName: "KERALA",
  },
  {
    Pincode: 680311,
    StateName: "KERALA",
  },
  {
    Pincode: 680305,
    StateName: "KERALA",
  },
  {
    Pincode: 680310,
    StateName: "KERALA",
  },
  {
    Pincode: 680301,
    StateName: "KERALA",
  },
  {
    Pincode: 680561,
    StateName: "KERALA",
  },
  {
    Pincode: 680568,
    StateName: "KERALA",
  },
  {
    Pincode: 680703,
    StateName: "KERALA",
  },
  {
    Pincode: 680666,
    StateName: "KERALA",
  },
  {
    Pincode: 680125,
    StateName: "KERALA",
  },
  {
    Pincode: 680683,
    StateName: "KERALA",
  },
  {
    Pincode: 680308,
    StateName: "KERALA",
  },
  {
    Pincode: 480441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480109,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480106,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 680732,
    StateName: "KERALA",
  },
  {
    Pincode: 680699,
    StateName: "KERALA",
  },
  {
    Pincode: 680304,
    StateName: "KERALA",
  },
  {
    Pincode: 768103,
    StateName: "ODISHA",
  },
  {
    Pincode: 273016,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273213,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273157,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273158,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 695126,
    StateName: "KERALA",
  },
  {
    Pincode: 631212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 480223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480559,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480338,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480107,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 799251,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799207,
    StateName: "TRIPURA",
  },
  {
    Pincode: 487661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 325221,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 176306,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 783133,
    StateName: "ASSAM",
  },
  {
    Pincode: 799290,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799282,
    StateName: "TRIPURA",
  },
  {
    Pincode: 627862,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627851,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627852,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 571253,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571236,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768049,
    StateName: "ODISHA",
  },
  {
    Pincode: 273209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 487337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 487881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 326517,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 571217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571237,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571254,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581360,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 680518,
    StateName: "KERALA",
  },
  {
    Pincode: 470661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 691306,
    StateName: "KERALA",
  },
  {
    Pincode: 691533,
    StateName: "KERALA",
  },
  {
    Pincode: 691307,
    StateName: "KERALA",
  },
  {
    Pincode: 691322,
    StateName: "KERALA",
  },
  {
    Pincode: 691312,
    StateName: "KERALA",
  },
  {
    Pincode: 691309,
    StateName: "KERALA",
  },
  {
    Pincode: 691310,
    StateName: "KERALA",
  },
  {
    Pincode: 250402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 464001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 793106,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 627758,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689695,
    StateName: "KERALA",
  },
  {
    Pincode: 689696,
    StateName: "KERALA",
  },
  {
    Pincode: 486441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486669,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689694,
    StateName: "KERALA",
  },
  {
    Pincode: 481449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 572137,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572139,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572138,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572140,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561207,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 794111,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 209402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 680102,
    StateName: "KERALA",
  },
  {
    Pincode: 680506,
    StateName: "KERALA",
  },
  {
    Pincode: 680101,
    StateName: "KERALA",
  },
  {
    Pincode: 680517,
    StateName: "KERALA",
  },
  {
    Pincode: 680519,
    StateName: "KERALA",
  },
  {
    Pincode: 680501,
    StateName: "KERALA",
  },
  {
    Pincode: 680523,
    StateName: "KERALA",
  },
  {
    Pincode: 245101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 680505,
    StateName: "KERALA",
  },
  {
    Pincode: 680512,
    StateName: "KERALA",
  },
  {
    Pincode: 680542,
    StateName: "KERALA",
  },
  {
    Pincode: 680104,
    StateName: "KERALA",
  },
  {
    Pincode: 831001,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 481331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 444720,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 325216,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325215,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325219,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325217,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325224,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 560048,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560092,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 680563,
    StateName: "KERALA",
  },
  {
    Pincode: 680641,
    StateName: "KERALA",
  },
  {
    Pincode: 680651,
    StateName: "KERALA",
  },
  {
    Pincode: 680541,
    StateName: "KERALA",
  },
  {
    Pincode: 680751,
    StateName: "KERALA",
  },
  {
    Pincode: 680306,
    StateName: "KERALA",
  },
  {
    Pincode: 680652,
    StateName: "KERALA",
  },
  {
    Pincode: 680014,
    StateName: "KERALA",
  },
  {
    Pincode: 680005,
    StateName: "KERALA",
  },
  {
    Pincode: 680614,
    StateName: "KERALA",
  },
  {
    Pincode: 680586,
    StateName: "KERALA",
  },
  {
    Pincode: 679531,
    StateName: "KERALA",
  },
  {
    Pincode: 679532,
    StateName: "KERALA",
  },
  {
    Pincode: 680584,
    StateName: "KERALA",
  },
  {
    Pincode: 680604,
    StateName: "KERALA",
  },
  {
    Pincode: 680581,
    StateName: "KERALA",
  },
  {
    Pincode: 680601,
    StateName: "KERALA",
  },
  {
    Pincode: 680623,
    StateName: "KERALA",
  },
  {
    Pincode: 326519,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 324008,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326530,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 670571,
    StateName: "KERALA",
  },
  {
    Pincode: 670632,
    StateName: "KERALA",
  },
  {
    Pincode: 670511,
    StateName: "KERALA",
  },
  {
    Pincode: 670334,
    StateName: "KERALA",
  },
  {
    Pincode: 670142,
    StateName: "KERALA",
  },
  {
    Pincode: 670521,
    StateName: "KERALA",
  },
  {
    Pincode: 670309,
    StateName: "KERALA",
  },
  {
    Pincode: 325206,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 273203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 583127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 324010,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 680511,
    StateName: "KERALA",
  },
  {
    Pincode: 680602,
    StateName: "KERALA",
  },
  {
    Pincode: 680522,
    StateName: "KERALA",
  },
  {
    Pincode: 680508,
    StateName: "KERALA",
  },
  {
    Pincode: 679562,
    StateName: "KERALA",
  },
  {
    Pincode: 680027,
    StateName: "KERALA",
  },
  {
    Pincode: 680006,
    StateName: "KERALA",
  },
  {
    Pincode: 680007,
    StateName: "KERALA",
  },
  {
    Pincode: 680028,
    StateName: "KERALA",
  },
  {
    Pincode: 680617,
    StateName: "KERALA",
  },
  {
    Pincode: 680552,
    StateName: "KERALA",
  },
  {
    Pincode: 691508,
    StateName: "KERALA",
  },
  {
    Pincode: 691522,
    StateName: "KERALA",
  },
  {
    Pincode: 691332,
    StateName: "KERALA",
  },
  {
    Pincode: 691308,
    StateName: "KERALA",
  },
  {
    Pincode: 678682,
    StateName: "KERALA",
  },
  {
    Pincode: 678510,
    StateName: "KERALA",
  },
  {
    Pincode: 678702,
    StateName: "KERALA",
  },
  {
    Pincode: 678543,
    StateName: "KERALA",
  },
  {
    Pincode: 680653,
    StateName: "KERALA",
  },
  {
    Pincode: 680012,
    StateName: "KERALA",
  },
  {
    Pincode: 680010,
    StateName: "KERALA",
  },
  {
    Pincode: 680589,
    StateName: "KERALA",
  },
  {
    Pincode: 670306,
    StateName: "KERALA",
  },
  {
    Pincode: 670563,
    StateName: "KERALA",
  },
  {
    Pincode: 670502,
    StateName: "KERALA",
  },
  {
    Pincode: 670307,
    StateName: "KERALA",
  },
  {
    Pincode: 670633,
    StateName: "KERALA",
  },
  {
    Pincode: 670631,
    StateName: "KERALA",
  },
  {
    Pincode: 670694,
    StateName: "KERALA",
  },
  {
    Pincode: 678686,
    StateName: "KERALA",
  },
  {
    Pincode: 678506,
    StateName: "KERALA",
  },
  {
    Pincode: 678684,
    StateName: "KERALA",
  },
  {
    Pincode: 678571,
    StateName: "KERALA",
  },
  {
    Pincode: 678703,
    StateName: "KERALA",
  },
  {
    Pincode: 678508,
    StateName: "KERALA",
  },
  {
    Pincode: 670672,
    StateName: "KERALA",
  },
  {
    Pincode: 670663,
    StateName: "KERALA",
  },
  {
    Pincode: 670703,
    StateName: "KERALA",
  },
  {
    Pincode: 670642,
    StateName: "KERALA",
  },
  {
    Pincode: 670674,
    StateName: "KERALA",
  },
  {
    Pincode: 670706,
    StateName: "KERALA",
  },
  {
    Pincode: 670643,
    StateName: "KERALA",
  },
  {
    Pincode: 670702,
    StateName: "KERALA",
  },
  {
    Pincode: 670692,
    StateName: "KERALA",
  },
  {
    Pincode: 670691,
    StateName: "KERALA",
  },
  {
    Pincode: 670704,
    StateName: "KERALA",
  },
  {
    Pincode: 670673,
    StateName: "KERALA",
  },
  {
    Pincode: 464573,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464258,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 209863,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 471301,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471315,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471510,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471311,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 581101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577428,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577429,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577433,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577434,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577411,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 250103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 679576,
    StateName: "KERALA",
  },
  {
    Pincode: 679582,
    StateName: "KERALA",
  },
  {
    Pincode: 679591,
    StateName: "KERALA",
  },
  {
    Pincode: 679571,
    StateName: "KERALA",
  },
  {
    Pincode: 679573,
    StateName: "KERALA",
  },
  {
    Pincode: 679579,
    StateName: "KERALA",
  },
  {
    Pincode: 676102,
    StateName: "KERALA",
  },
  {
    Pincode: 673314,
    StateName: "KERALA",
  },
  {
    Pincode: 670353,
    StateName: "KERALA",
  },
  {
    Pincode: 671531,
    StateName: "KERALA",
  },
  {
    Pincode: 671316,
    StateName: "KERALA",
  },
  {
    Pincode: 671318,
    StateName: "KERALA",
  },
  {
    Pincode: 671326,
    StateName: "KERALA",
  },
  {
    Pincode: 671314,
    StateName: "KERALA",
  },
  {
    Pincode: 301604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 464337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462101,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 671312,
    StateName: "KERALA",
  },
  {
    Pincode: 671533,
    StateName: "KERALA",
  },
  {
    Pincode: 671534,
    StateName: "KERALA",
  },
  {
    Pincode: 671532,
    StateName: "KERALA",
  },
  {
    Pincode: 671310,
    StateName: "KERALA",
  },
  {
    Pincode: 671541,
    StateName: "KERALA",
  },
  {
    Pincode: 577225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577432,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581199,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577202,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577414,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577415,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577436,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 471105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 463111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462120,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464993,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462044,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679584,
    StateName: "KERALA",
  },
  {
    Pincode: 679580,
    StateName: "KERALA",
  },
  {
    Pincode: 676305,
    StateName: "KERALA",
  },
  {
    Pincode: 676319,
    StateName: "KERALA",
  },
  {
    Pincode: 691333,
    StateName: "KERALA",
  },
  {
    Pincode: 604304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604153,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689692,
    StateName: "KERALA",
  },
  {
    Pincode: 689711,
    StateName: "KERALA",
  },
  {
    Pincode: 689673,
    StateName: "KERALA",
  },
  {
    Pincode: 689667,
    StateName: "KERALA",
  },
  {
    Pincode: 691552,
    StateName: "KERALA",
  },
  {
    Pincode: 691555,
    StateName: "KERALA",
  },
  {
    Pincode: 689503,
    StateName: "KERALA",
  },
  {
    Pincode: 604203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689653,
    StateName: "KERALA",
  },
  {
    Pincode: 689676,
    StateName: "KERALA",
  },
  {
    Pincode: 689678,
    StateName: "KERALA",
  },
  {
    Pincode: 689647,
    StateName: "KERALA",
  },
  {
    Pincode: 689672,
    StateName: "KERALA",
  },
  {
    Pincode: 689699,
    StateName: "KERALA",
  },
  {
    Pincode: 689662,
    StateName: "KERALA",
  },
  {
    Pincode: 689656,
    StateName: "KERALA",
  },
  {
    Pincode: 691524,
    StateName: "KERALA",
  },
  {
    Pincode: 689625,
    StateName: "KERALA",
  },
  {
    Pincode: 691526,
    StateName: "KERALA",
  },
  {
    Pincode: 689693,
    StateName: "KERALA",
  },
  {
    Pincode: 676501,
    StateName: "KERALA",
  },
  {
    Pincode: 323001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 413515,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413522,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413520,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413513,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413519,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457887,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431713,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 583128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583239,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583131,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 464661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 678706,
    StateName: "KERALA",
  },
  {
    Pincode: 678005,
    StateName: "KERALA",
  },
  {
    Pincode: 678003,
    StateName: "KERALA",
  },
  {
    Pincode: 678631,
    StateName: "KERALA",
  },
  {
    Pincode: 678651,
    StateName: "KERALA",
  },
  {
    Pincode: 678642,
    StateName: "KERALA",
  },
  {
    Pincode: 678006,
    StateName: "KERALA",
  },
  {
    Pincode: 678101,
    StateName: "KERALA",
  },
  {
    Pincode: 678502,
    StateName: "KERALA",
  },
  {
    Pincode: 678501,
    StateName: "KERALA",
  },
  {
    Pincode: 678551,
    StateName: "KERALA",
  },
  {
    Pincode: 678555,
    StateName: "KERALA",
  },
  {
    Pincode: 678557,
    StateName: "KERALA",
  },
  {
    Pincode: 678102,
    StateName: "KERALA",
  },
  {
    Pincode: 678534,
    StateName: "KERALA",
  },
  {
    Pincode: 572212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561206,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561208,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561209,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 562105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 561228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 422502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 451228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451556,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413532,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413581,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 678572,
    StateName: "KERALA",
  },
  {
    Pincode: 678721,
    StateName: "KERALA",
  },
  {
    Pincode: 678544,
    StateName: "KERALA",
  },
  {
    Pincode: 678688,
    StateName: "KERALA",
  },
  {
    Pincode: 678573,
    StateName: "KERALA",
  },
  {
    Pincode: 678574,
    StateName: "KERALA",
  },
  {
    Pincode: 678545,
    StateName: "KERALA",
  },
  {
    Pincode: 678687,
    StateName: "KERALA",
  },
  {
    Pincode: 678722,
    StateName: "KERALA",
  },
  {
    Pincode: 678683,
    StateName: "KERALA",
  },
  {
    Pincode: 678641,
    StateName: "KERALA",
  },
  {
    Pincode: 678613,
    StateName: "KERALA",
  },
  {
    Pincode: 678004,
    StateName: "KERALA",
  },
  {
    Pincode: 678612,
    StateName: "KERALA",
  },
  {
    Pincode: 678007,
    StateName: "KERALA",
  },
  {
    Pincode: 678622,
    StateName: "KERALA",
  },
  {
    Pincode: 678621,
    StateName: "KERALA",
  },
  {
    Pincode: 678701,
    StateName: "KERALA",
  },
  {
    Pincode: 678556,
    StateName: "KERALA",
  },
  {
    Pincode: 678553,
    StateName: "KERALA",
  },
  {
    Pincode: 678552,
    StateName: "KERALA",
  },
  {
    Pincode: 678103,
    StateName: "KERALA",
  },
  {
    Pincode: 471405,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471318,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690538,
    StateName: "KERALA",
  },
  {
    Pincode: 691583,
    StateName: "KERALA",
  },
  {
    Pincode: 690519,
    StateName: "KERALA",
  },
  {
    Pincode: 690523,
    StateName: "KERALA",
  },
  {
    Pincode: 284404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 560067,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560037,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 691502,
    StateName: "KERALA",
  },
  {
    Pincode: 691602,
    StateName: "KERALA",
  },
  {
    Pincode: 691579,
    StateName: "KERALA",
  },
  {
    Pincode: 691574,
    StateName: "KERALA",
  },
  {
    Pincode: 691601,
    StateName: "KERALA",
  },
  {
    Pincode: 691005,
    StateName: "KERALA",
  },
  {
    Pincode: 691020,
    StateName: "KERALA",
  },
  {
    Pincode: 691511,
    StateName: "KERALA",
  },
  {
    Pincode: 691520,
    StateName: "KERALA",
  },
  {
    Pincode: 691559,
    StateName: "KERALA",
  },
  {
    Pincode: 691505,
    StateName: "KERALA",
  },
  {
    Pincode: 460001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 301019,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301018,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 799271,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799256,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799262,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799286,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799266,
    StateName: "TRIPURA",
  },
  {
    Pincode: 460220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460553,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471525,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471101,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 281504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 471313,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471515,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471606,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471516,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471501,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471201,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471625,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471408,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 577223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577551,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577230,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581321,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581324,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572141,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 422403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689664,
    StateName: "KERALA",
  },
  {
    Pincode: 689646,
    StateName: "KERALA",
  },
  {
    Pincode: 689671,
    StateName: "KERALA",
  },
  {
    Pincode: 689642,
    StateName: "KERALA",
  },
  {
    Pincode: 486666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486892,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488333,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488050,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488448,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689698,
    StateName: "KERALA",
  },
  {
    Pincode: 691331,
    StateName: "KERALA",
  },
  {
    Pincode: 486333,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477227,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477566,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474006,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690547,
    StateName: "KERALA",
  },
  {
    Pincode: 403802,
    StateName: "GOA",
  },
  {
    Pincode: 403714,
    StateName: "GOA",
  },
  {
    Pincode: 403710,
    StateName: "GOA",
  },
  {
    Pincode: 403509,
    StateName: "GOA",
  },
  {
    Pincode: 403503,
    StateName: "GOA",
  },
  {
    Pincode: 460554,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460663,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 577231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577422,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577424,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 600047,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600045,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600044,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600073,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600059,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600063,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 484001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 606902,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606908,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606904,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606752,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 761055,
    StateName: "ODISHA",
  },
  {
    Pincode: 761042,
    StateName: "ODISHA",
  },
  {
    Pincode: 761012,
    StateName: "ODISHA",
  },
  {
    Pincode: 760010,
    StateName: "ODISHA",
  },
  {
    Pincode: 761009,
    StateName: "ODISHA",
  },
  {
    Pincode: 761008,
    StateName: "ODISHA",
  },
  {
    Pincode: 761002,
    StateName: "ODISHA",
  },
  {
    Pincode: 760003,
    StateName: "ODISHA",
  },
  {
    Pincode: 761005,
    StateName: "ODISHA",
  },
  {
    Pincode: 761209,
    StateName: "ODISHA",
  },
  {
    Pincode: 761054,
    StateName: "ODISHA",
  },
  {
    Pincode: 760002,
    StateName: "ODISHA",
  },
  {
    Pincode: 760007,
    StateName: "ODISHA",
  },
  {
    Pincode: 450445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670701,
    StateName: "KERALA",
  },
  {
    Pincode: 670661,
    StateName: "KERALA",
  },
  {
    Pincode: 670107,
    StateName: "KERALA",
  },
  {
    Pincode: 670651,
    StateName: "KERALA",
  },
  {
    Pincode: 670105,
    StateName: "KERALA",
  },
  {
    Pincode: 670675,
    StateName: "KERALA",
  },
  {
    Pincode: 670741,
    StateName: "KERALA",
  },
  {
    Pincode: 761010,
    StateName: "ODISHA",
  },
  {
    Pincode: 761006,
    StateName: "ODISHA",
  },
  {
    Pincode: 761100,
    StateName: "ODISHA",
  },
  {
    Pincode: 761001,
    StateName: "ODISHA",
  },
  {
    Pincode: 761011,
    StateName: "ODISHA",
  },
  {
    Pincode: 761007,
    StateName: "ODISHA",
  },
  {
    Pincode: 761004,
    StateName: "ODISHA",
  },
  {
    Pincode: 761014,
    StateName: "ODISHA",
  },
  {
    Pincode: 761037,
    StateName: "ODISHA",
  },
  {
    Pincode: 761026,
    StateName: "ODISHA",
  },
  {
    Pincode: 761041,
    StateName: "ODISHA",
  },
  {
    Pincode: 670705,
    StateName: "KERALA",
  },
  {
    Pincode: 670649,
    StateName: "KERALA",
  },
  {
    Pincode: 670102,
    StateName: "KERALA",
  },
  {
    Pincode: 670693,
    StateName: "KERALA",
  },
  {
    Pincode: 670650,
    StateName: "KERALA",
  },
  {
    Pincode: 464240,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 422104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 461221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670581,
    StateName: "KERALA",
  },
  {
    Pincode: 670562,
    StateName: "KERALA",
  },
  {
    Pincode: 670582,
    StateName: "KERALA",
  },
  {
    Pincode: 670308,
    StateName: "KERALA",
  },
  {
    Pincode: 671313,
    StateName: "KERALA",
  },
  {
    Pincode: 671311,
    StateName: "KERALA",
  },
  {
    Pincode: 671351,
    StateName: "KERALA",
  },
  {
    Pincode: 671319,
    StateName: "KERALA",
  },
  {
    Pincode: 470441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470119,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470227,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470120,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 691311,
    StateName: "KERALA",
  },
  {
    Pincode: 680587,
    StateName: "KERALA",
  },
  {
    Pincode: 488442,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488443,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 488220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486341,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431807,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 486448,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 691553,
    StateName: "KERALA",
  },
  {
    Pincode: 481001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457882,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457772,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457779,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457773,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465693,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465667,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477660,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460225,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460668,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460557,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690573,
    StateName: "KERALA",
  },
  {
    Pincode: 480001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 283104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 475682,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 606803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606611,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606709,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606753,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606754,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 680585,
    StateName: "KERALA",
  },
  {
    Pincode: 679563,
    StateName: "KERALA",
  },
  {
    Pincode: 680507,
    StateName: "KERALA",
  },
  {
    Pincode: 680103,
    StateName: "KERALA",
  },
  {
    Pincode: 680510,
    StateName: "KERALA",
  },
  {
    Pincode: 680620,
    StateName: "KERALA",
  },
  {
    Pincode: 680003,
    StateName: "KERALA",
  },
  {
    Pincode: 680004,
    StateName: "KERALA",
  },
  {
    Pincode: 680551,
    StateName: "KERALA",
  },
  {
    Pincode: 680569,
    StateName: "KERALA",
  },
  {
    Pincode: 680619,
    StateName: "KERALA",
  },
  {
    Pincode: 604601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632055,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 475671,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476134,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476355,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476554,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690536,
    StateName: "KERALA",
  },
  {
    Pincode: 691573,
    StateName: "KERALA",
  },
  {
    Pincode: 691302,
    StateName: "KERALA",
  },
  {
    Pincode: 691578,
    StateName: "KERALA",
  },
  {
    Pincode: 691003,
    StateName: "KERALA",
  },
  {
    Pincode: 691501,
    StateName: "KERALA",
  },
  {
    Pincode: 691577,
    StateName: "KERALA",
  },
  {
    Pincode: 691503,
    StateName: "KERALA",
  },
  {
    Pincode: 691021,
    StateName: "KERALA",
  },
  {
    Pincode: 691534,
    StateName: "KERALA",
  },
  {
    Pincode: 691557,
    StateName: "KERALA",
  },
  {
    Pincode: 690542,
    StateName: "KERALA",
  },
  {
    Pincode: 691584,
    StateName: "KERALA",
  },
  {
    Pincode: 690544,
    StateName: "KERALA",
  },
  {
    Pincode: 691576,
    StateName: "KERALA",
  },
  {
    Pincode: 691571,
    StateName: "KERALA",
  },
  {
    Pincode: 691334,
    StateName: "KERALA",
  },
  {
    Pincode: 691010,
    StateName: "KERALA",
  },
  {
    Pincode: 691500,
    StateName: "KERALA",
  },
  {
    Pincode: 577232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 679106,
    StateName: "KERALA",
  },
  {
    Pincode: 680590,
    StateName: "KERALA",
  },
  {
    Pincode: 680583,
    StateName: "KERALA",
  },
  {
    Pincode: 680588,
    StateName: "KERALA",
  },
  {
    Pincode: 680596,
    StateName: "KERALA",
  },
  {
    Pincode: 680543,
    StateName: "KERALA",
  },
  {
    Pincode: 680544,
    StateName: "KERALA",
  },
  {
    Pincode: 679561,
    StateName: "KERALA",
  },
  {
    Pincode: 680008,
    StateName: "KERALA",
  },
  {
    Pincode: 670143,
    StateName: "KERALA",
  },
  {
    Pincode: 321206,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 678593,
    StateName: "KERALA",
  },
  {
    Pincode: 679514,
    StateName: "KERALA",
  },
  {
    Pincode: 679522,
    StateName: "KERALA",
  },
  {
    Pincode: 679309,
    StateName: "KERALA",
  },
  {
    Pincode: 679533,
    StateName: "KERALA",
  },
  {
    Pincode: 678598,
    StateName: "KERALA",
  },
  {
    Pincode: 679307,
    StateName: "KERALA",
  },
  {
    Pincode: 679122,
    StateName: "KERALA",
  },
  {
    Pincode: 679121,
    StateName: "KERALA",
  },
  {
    Pincode: 679513,
    StateName: "KERALA",
  },
  {
    Pincode: 679102,
    StateName: "KERALA",
  },
  {
    Pincode: 679502,
    StateName: "KERALA",
  },
  {
    Pincode: 679534,
    StateName: "KERALA",
  },
  {
    Pincode: 678632,
    StateName: "KERALA",
  },
  {
    Pincode: 321204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321642,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 761132,
    StateName: "ODISHA",
  },
  {
    Pincode: 761122,
    StateName: "ODISHA",
  },
  {
    Pincode: 761140,
    StateName: "ODISHA",
  },
  {
    Pincode: 761131,
    StateName: "ODISHA",
  },
  {
    Pincode: 793111,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 457888,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457777,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 686651,
    StateName: "KERALA",
  },
  {
    Pincode: 686574,
    StateName: "KERALA",
  },
  {
    Pincode: 686578,
    StateName: "KERALA",
  },
  {
    Pincode: 686121,
    StateName: "KERALA",
  },
  {
    Pincode: 686571,
    StateName: "KERALA",
  },
  {
    Pincode: 686572,
    StateName: "KERALA",
  },
  {
    Pincode: 686582,
    StateName: "KERALA",
  },
  {
    Pincode: 686577,
    StateName: "KERALA",
  },
  {
    Pincode: 686604,
    StateName: "KERALA",
  },
  {
    Pincode: 686611,
    StateName: "KERALA",
  },
  {
    Pincode: 686144,
    StateName: "KERALA",
  },
  {
    Pincode: 686608,
    StateName: "KERALA",
  },
  {
    Pincode: 686633,
    StateName: "KERALA",
  },
  {
    Pincode: 457993,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465685,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465689,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 471411,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476229,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 314001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 475336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475685,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475673,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 314021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 474020,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 328031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 283201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283113,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 282005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283111,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 480887,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480882,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480667,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480888,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480999,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480994,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480991,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480886,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473995,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462022,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462030,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 403703,
    StateName: "GOA",
  },
  {
    Pincode: 403706,
    StateName: "GOA",
  },
  {
    Pincode: 403705,
    StateName: "GOA",
  },
  {
    Pincode: 403704,
    StateName: "GOA",
  },
  {
    Pincode: 403723,
    StateName: "GOA",
  },
  {
    Pincode: 403702,
    StateName: "GOA",
  },
  {
    Pincode: 403410,
    StateName: "GOA",
  },
  {
    Pincode: 403707,
    StateName: "GOA",
  },
  {
    Pincode: 465679,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465687,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 762028,
    StateName: "ODISHA",
  },
  {
    Pincode: 762002,
    StateName: "ODISHA",
  },
  {
    Pincode: 762104,
    StateName: "ODISHA",
  },
  {
    Pincode: 762100,
    StateName: "ODISHA",
  },
  {
    Pincode: 425428,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 686636,
    StateName: "KERALA",
  },
  {
    Pincode: 686613,
    StateName: "KERALA",
  },
  {
    Pincode: 462036,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462038,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462042,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462047,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462026,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679575,
    StateName: "KERALA",
  },
  {
    Pincode: 679578,
    StateName: "KERALA",
  },
  {
    Pincode: 321405,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 678681,
    StateName: "KERALA",
  },
  {
    Pincode: 678507,
    StateName: "KERALA",
  },
  {
    Pincode: 678542,
    StateName: "KERALA",
  },
  {
    Pincode: 678611,
    StateName: "KERALA",
  },
  {
    Pincode: 678009,
    StateName: "KERALA",
  },
  {
    Pincode: 678592,
    StateName: "KERALA",
  },
  {
    Pincode: 678594,
    StateName: "KERALA",
  },
  {
    Pincode: 678731,
    StateName: "KERALA",
  },
  {
    Pincode: 480996,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470669,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 686146,
    StateName: "KERALA",
  },
  {
    Pincode: 686610,
    StateName: "KERALA",
  },
  {
    Pincode: 686606,
    StateName: "KERALA",
  },
  {
    Pincode: 686143,
    StateName: "KERALA",
  },
  {
    Pincode: 686587,
    StateName: "KERALA",
  },
  {
    Pincode: 686562,
    StateName: "KERALA",
  },
  {
    Pincode: 686015,
    StateName: "KERALA",
  },
  {
    Pincode: 686631,
    StateName: "KERALA",
  },
  {
    Pincode: 686008,
    StateName: "KERALA",
  },
  {
    Pincode: 686632,
    StateName: "KERALA",
  },
  {
    Pincode: 686020,
    StateName: "KERALA",
  },
  {
    Pincode: 686017,
    StateName: "KERALA",
  },
  {
    Pincode: 686563,
    StateName: "KERALA",
  },
  {
    Pincode: 686019,
    StateName: "KERALA",
  },
  {
    Pincode: 686013,
    StateName: "KERALA",
  },
  {
    Pincode: 686014,
    StateName: "KERALA",
  },
  {
    Pincode: 686011,
    StateName: "KERALA",
  },
  {
    Pincode: 455001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689513,
    StateName: "KERALA",
  },
  {
    Pincode: 673631,
    StateName: "KERALA",
  },
  {
    Pincode: 673016,
    StateName: "KERALA",
  },
  {
    Pincode: 673007,
    StateName: "KERALA",
  },
  {
    Pincode: 676553,
    StateName: "KERALA",
  },
  {
    Pincode: 462046,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 463106,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 673632,
    StateName: "KERALA",
  },
  {
    Pincode: 679574,
    StateName: "KERALA",
  },
  {
    Pincode: 679586,
    StateName: "KERALA",
  },
  {
    Pincode: 563114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 671317,
    StateName: "KERALA",
  },
  {
    Pincode: 671124,
    StateName: "KERALA",
  },
  {
    Pincode: 671321,
    StateName: "KERALA",
  },
  {
    Pincode: 671323,
    StateName: "KERALA",
  },
  {
    Pincode: 690505,
    StateName: "KERALA",
  },
  {
    Pincode: 690507,
    StateName: "KERALA",
  },
  {
    Pincode: 690534,
    StateName: "KERALA",
  },
  {
    Pincode: 690514,
    StateName: "KERALA",
  },
  {
    Pincode: 690535,
    StateName: "KERALA",
  },
  {
    Pincode: 690516,
    StateName: "KERALA",
  },
  {
    Pincode: 690517,
    StateName: "KERALA",
  },
  {
    Pincode: 690533,
    StateName: "KERALA",
  },
  {
    Pincode: 690506,
    StateName: "KERALA",
  },
  {
    Pincode: 690503,
    StateName: "KERALA",
  },
  {
    Pincode: 690527,
    StateName: "KERALA",
  },
  {
    Pincode: 690105,
    StateName: "KERALA",
  },
  {
    Pincode: 690106,
    StateName: "KERALA",
  },
  {
    Pincode: 563113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 759026,
    StateName: "ODISHA",
  },
  {
    Pincode: 624101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 679357,
    StateName: "KERALA",
  },
  {
    Pincode: 679340,
    StateName: "KERALA",
  },
  {
    Pincode: 676519,
    StateName: "KERALA",
  },
  {
    Pincode: 670641,
    StateName: "KERALA",
  },
  {
    Pincode: 581145,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 689624,
    StateName: "KERALA",
  },
  {
    Pincode: 690110,
    StateName: "KERALA",
  },
  {
    Pincode: 690101,
    StateName: "KERALA",
  },
  {
    Pincode: 689505,
    StateName: "KERALA",
  },
  {
    Pincode: 689520,
    StateName: "KERALA",
  },
  {
    Pincode: 671542,
    StateName: "KERALA",
  },
  {
    Pincode: 671543,
    StateName: "KERALA",
  },
  {
    Pincode: 671348,
    StateName: "KERALA",
  },
  {
    Pincode: 671552,
    StateName: "KERALA",
  },
  {
    Pincode: 671322,
    StateName: "KERALA",
  },
  {
    Pincode: 671123,
    StateName: "KERALA",
  },
  {
    Pincode: 591247,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 610102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 576211,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576214,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576283,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576257,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 425407,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425415,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425442,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425417,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425414,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 466001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466448,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458669,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458389,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458558,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 678554,
    StateName: "KERALA",
  },
  {
    Pincode: 678532,
    StateName: "KERALA",
  },
  {
    Pincode: 303604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303904,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303008,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303328,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303007,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 422214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 450116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480884,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 480881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 344701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 284136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 460443,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 460665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690574,
    StateName: "KERALA",
  },
  {
    Pincode: 691585,
    StateName: "KERALA",
  },
  {
    Pincode: 691582,
    StateName: "KERALA",
  },
  {
    Pincode: 690526,
    StateName: "KERALA",
  },
  {
    Pincode: 690524,
    StateName: "KERALA",
  },
  {
    Pincode: 690528,
    StateName: "KERALA",
  },
  {
    Pincode: 691011,
    StateName: "KERALA",
  },
  {
    Pincode: 691004,
    StateName: "KERALA",
  },
  {
    Pincode: 691015,
    StateName: "KERALA",
  },
  {
    Pincode: 691303,
    StateName: "KERALA",
  },
  {
    Pincode: 762014,
    StateName: "ODISHA",
  },
  {
    Pincode: 762015,
    StateName: "ODISHA",
  },
  {
    Pincode: 431606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413516,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 583219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583132,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 756111,
    StateName: "ODISHA",
  },
  {
    Pincode: 576230,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 403524,
    StateName: "GOA",
  },
  {
    Pincode: 756126,
    StateName: "ODISHA",
  },
  {
    Pincode: 770002,
    StateName: "ODISHA",
  },
  {
    Pincode: 770020,
    StateName: "ODISHA",
  },
  {
    Pincode: 770014,
    StateName: "ODISHA",
  },
  {
    Pincode: 443104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 576235,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576233,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752109,
    StateName: "ODISHA",
  },
  {
    Pincode: 752105,
    StateName: "ODISHA",
  },
  {
    Pincode: 752118,
    StateName: "ODISHA",
  },
  {
    Pincode: 752110,
    StateName: "ODISHA",
  },
  {
    Pincode: 770033,
    StateName: "ODISHA",
  },
  {
    Pincode: 770038,
    StateName: "ODISHA",
  },
  {
    Pincode: 770051,
    StateName: "ODISHA",
  },
  {
    Pincode: 770044,
    StateName: "ODISHA",
  },
  {
    Pincode: 770039,
    StateName: "ODISHA",
  },
  {
    Pincode: 416511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416518,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 344033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 424207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770074,
    StateName: "ODISHA",
  },
  {
    Pincode: 770011,
    StateName: "ODISHA",
  },
  {
    Pincode: 770072,
    StateName: "ODISHA",
  },
  {
    Pincode: 756121,
    StateName: "ODISHA",
  },
  {
    Pincode: 768105,
    StateName: "ODISHA",
  },
  {
    Pincode: 768119,
    StateName: "ODISHA",
  },
  {
    Pincode: 752100,
    StateName: "ODISHA",
  },
  {
    Pincode: 752101,
    StateName: "ODISHA",
  },
  {
    Pincode: 752102,
    StateName: "ODISHA",
  },
  {
    Pincode: 752103,
    StateName: "ODISHA",
  },
  {
    Pincode: 751003,
    StateName: "ODISHA",
  },
  {
    Pincode: 751019,
    StateName: "ODISHA",
  },
  {
    Pincode: 422112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 466115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466554,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 424002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768106,
    StateName: "ODISHA",
  },
  {
    Pincode: 768109,
    StateName: "ODISHA",
  },
  {
    Pincode: 679504,
    StateName: "KERALA",
  },
  {
    Pincode: 680671,
    StateName: "KERALA",
  },
  {
    Pincode: 680667,
    StateName: "KERALA",
  },
  {
    Pincode: 679304,
    StateName: "KERALA",
  },
  {
    Pincode: 480447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 577205,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 476228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 303908,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 581341,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 424005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425408,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680594,
    StateName: "KERALA",
  },
  {
    Pincode: 680689,
    StateName: "KERALA",
  },
  {
    Pincode: 680121,
    StateName: "KERALA",
  },
  {
    Pincode: 680681,
    StateName: "KERALA",
  },
  {
    Pincode: 680664,
    StateName: "KERALA",
  },
  {
    Pincode: 756181,
    StateName: "ODISHA",
  },
  {
    Pincode: 756132,
    StateName: "ODISHA",
  },
  {
    Pincode: 756164,
    StateName: "ODISHA",
  },
  {
    Pincode: 756167,
    StateName: "ODISHA",
  },
  {
    Pincode: 756131,
    StateName: "ODISHA",
  },
  {
    Pincode: 756135,
    StateName: "ODISHA",
  },
  {
    Pincode: 424301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 465677,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465691,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 425403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756123,
    StateName: "ODISHA",
  },
  {
    Pincode: 756130,
    StateName: "ODISHA",
  },
  {
    Pincode: 768102,
    StateName: "ODISHA",
  },
  {
    Pincode: 458226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416525,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416512,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 752011,
    StateName: "ODISHA",
  },
  {
    Pincode: 752010,
    StateName: "ODISHA",
  },
  {
    Pincode: 752012,
    StateName: "ODISHA",
  },
  {
    Pincode: 752015,
    StateName: "ODISHA",
  },
  {
    Pincode: 576210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576213,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576234,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 424205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 678008,
    StateName: "KERALA",
  },
  {
    Pincode: 678661,
    StateName: "KERALA",
  },
  {
    Pincode: 679338,
    StateName: "KERALA",
  },
  {
    Pincode: 323026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323616,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 624211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624215,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624216,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624219,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624220,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624612,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624613,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624614,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 422004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 464334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 303509,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303505,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303506,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303510,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321612,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321613,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303326,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303508,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 678012,
    StateName: "KERALA",
  },
  {
    Pincode: 678623,
    StateName: "KERALA",
  },
  {
    Pincode: 678503,
    StateName: "KERALA",
  },
  {
    Pincode: 678671,
    StateName: "KERALA",
  },
  {
    Pincode: 678624,
    StateName: "KERALA",
  },
  {
    Pincode: 670671,
    StateName: "KERALA",
  },
  {
    Pincode: 486771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486675,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689514,
    StateName: "KERALA",
  },
  {
    Pincode: 572119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581362,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581344,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581348,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581354,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584140,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584143,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584138,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 422013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 670103,
    StateName: "KERALA",
  },
  {
    Pincode: 584167,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584139,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577430,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577431,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584136,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768006,
    StateName: "ODISHA",
  },
  {
    Pincode: 768200,
    StateName: "ODISHA",
  },
  {
    Pincode: 754110,
    StateName: "ODISHA",
  },
  {
    Pincode: 754139,
    StateName: "ODISHA",
  },
  {
    Pincode: 754106,
    StateName: "ODISHA",
  },
  {
    Pincode: 754136,
    StateName: "ODISHA",
  },
  {
    Pincode: 303507,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 584120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581421,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581342,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581343,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 770040,
    StateName: "ODISHA",
  },
  {
    Pincode: 770036,
    StateName: "ODISHA",
  },
  {
    Pincode: 323023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323613,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 457885,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413250,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 581423,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581440,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581350,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572126,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572130,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572222,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686005,
    StateName: "KERALA",
  },
  {
    Pincode: 686584,
    StateName: "KERALA",
  },
  {
    Pincode: 686583,
    StateName: "KERALA",
  },
  {
    Pincode: 686635,
    StateName: "KERALA",
  },
  {
    Pincode: 686652,
    StateName: "KERALA",
  },
  {
    Pincode: 572134,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563159,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563123,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 624704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 759128,
    StateName: "ODISHA",
  },
  {
    Pincode: 759146,
    StateName: "ODISHA",
  },
  {
    Pincode: 759125,
    StateName: "ODISHA",
  },
  {
    Pincode: 759129,
    StateName: "ODISHA",
  },
  {
    Pincode: 759124,
    StateName: "ODISHA",
  },
  {
    Pincode: 679321,
    StateName: "KERALA",
  },
  {
    Pincode: 673505,
    StateName: "KERALA",
  },
  {
    Pincode: 673517,
    StateName: "KERALA",
  },
  {
    Pincode: 673542,
    StateName: "KERALA",
  },
  {
    Pincode: 473775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473781,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450112,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 686586,
    StateName: "KERALA",
  },
  {
    Pincode: 686579,
    StateName: "KERALA",
  },
  {
    Pincode: 686581,
    StateName: "KERALA",
  },
  {
    Pincode: 686573,
    StateName: "KERALA",
  },
  {
    Pincode: 686576,
    StateName: "KERALA",
  },
  {
    Pincode: 686580,
    StateName: "KERALA",
  },
  {
    Pincode: 686612,
    StateName: "KERALA",
  },
  {
    Pincode: 686634,
    StateName: "KERALA",
  },
  {
    Pincode: 686605,
    StateName: "KERALA",
  },
  {
    Pincode: 754162,
    StateName: "ODISHA",
  },
  {
    Pincode: 754141,
    StateName: "ODISHA",
  },
  {
    Pincode: 754119,
    StateName: "ODISHA",
  },
  {
    Pincode: 754104,
    StateName: "ODISHA",
  },
  {
    Pincode: 403713,
    StateName: "GOA",
  },
  {
    Pincode: 403708,
    StateName: "GOA",
  },
  {
    Pincode: 403718,
    StateName: "GOA",
  },
  {
    Pincode: 754111,
    StateName: "ODISHA",
  },
  {
    Pincode: 754135,
    StateName: "ODISHA",
  },
  {
    Pincode: 403508,
    StateName: "GOA",
  },
  {
    Pincode: 770021,
    StateName: "ODISHA",
  },
  {
    Pincode: 415803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415616,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 475686,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441903,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441122,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440023,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441914,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441906,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441905,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441908,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 686603,
    StateName: "KERALA",
  },
  {
    Pincode: 686607,
    StateName: "KERALA",
  },
  {
    Pincode: 686564,
    StateName: "KERALA",
  },
  {
    Pincode: 686003,
    StateName: "KERALA",
  },
  {
    Pincode: 415517,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415528,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415526,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415524,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 769043,
    StateName: "ODISHA",
  },
  {
    Pincode: 752116,
    StateName: "ODISHA",
  },
  {
    Pincode: 752113,
    StateName: "ODISHA",
  },
  {
    Pincode: 416513,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441924,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 455116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416521,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416531,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416520,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 754153,
    StateName: "ODISHA",
  },
  {
    Pincode: 576212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 412803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 576282,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576232,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576226,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 416702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415613,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415611,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415629,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 606905,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 759103,
    StateName: "ODISHA",
  },
  {
    Pincode: 759143,
    StateName: "ODISHA",
  },
  {
    Pincode: 759132,
    StateName: "ODISHA",
  },
  {
    Pincode: 572217,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 759141,
    StateName: "ODISHA",
  },
  {
    Pincode: 483225,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 686516,
    StateName: "KERALA",
  },
  {
    Pincode: 686004,
    StateName: "KERALA",
  },
  {
    Pincode: 686012,
    StateName: "KERALA",
  },
  {
    Pincode: 690548,
    StateName: "KERALA",
  },
  {
    Pincode: 486226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486556,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690513,
    StateName: "KERALA",
  },
  {
    Pincode: 690504,
    StateName: "KERALA",
  },
  {
    Pincode: 690531,
    StateName: "KERALA",
  },
  {
    Pincode: 671121,
    StateName: "KERALA",
  },
  {
    Pincode: 671324,
    StateName: "KERALA",
  },
  {
    Pincode: 671551,
    StateName: "KERALA",
  },
  {
    Pincode: 769042,
    StateName: "ODISHA",
  },
  {
    Pincode: 769009,
    StateName: "ODISHA",
  },
  {
    Pincode: 770041,
    StateName: "ODISHA",
  },
  {
    Pincode: 473662,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 673303,
    StateName: "KERALA",
  },
  {
    Pincode: 770043,
    StateName: "ODISHA",
  },
  {
    Pincode: 770031,
    StateName: "ODISHA",
  },
  {
    Pincode: 416303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770034,
    StateName: "ODISHA",
  },
  {
    Pincode: 416514,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431512,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 673014,
    StateName: "KERALA",
  },
  {
    Pincode: 673003,
    StateName: "KERALA",
  },
  {
    Pincode: 473101,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 759117,
    StateName: "ODISHA",
  },
  {
    Pincode: 759118,
    StateName: "ODISHA",
  },
  {
    Pincode: 759127,
    StateName: "ODISHA",
  },
  {
    Pincode: 759130,
    StateName: "ODISHA",
  },
  {
    Pincode: 759123,
    StateName: "ODISHA",
  },
  {
    Pincode: 624615,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 472339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465697,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465680,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 759119,
    StateName: "ODISHA",
  },
  {
    Pincode: 759126,
    StateName: "ODISHA",
  },
  {
    Pincode: 759105,
    StateName: "ODISHA",
  },
  {
    Pincode: 759100,
    StateName: "ODISHA",
  },
  {
    Pincode: 472115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472101,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 606601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606907,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606906,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 423701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431133,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431154,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 690515,
    StateName: "KERALA",
  },
  {
    Pincode: 690501,
    StateName: "KERALA",
  },
  {
    Pincode: 689508,
    StateName: "KERALA",
  },
  {
    Pincode: 689622,
    StateName: "KERALA",
  },
  {
    Pincode: 690102,
    StateName: "KERALA",
  },
  {
    Pincode: 690107,
    StateName: "KERALA",
  },
  {
    Pincode: 487001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690508,
    StateName: "KERALA",
  },
  {
    Pincode: 690529,
    StateName: "KERALA",
  },
  {
    Pincode: 690104,
    StateName: "KERALA",
  },
  {
    Pincode: 689504,
    StateName: "KERALA",
  },
  {
    Pincode: 690509,
    StateName: "KERALA",
  },
  {
    Pincode: 466445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670305,
    StateName: "KERALA",
  },
  {
    Pincode: 312624,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 475110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 577218,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577221,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577552,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577227,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577233,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577401,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577412,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577413,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 327605,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 466125,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 450331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 422211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412216,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412219,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412220,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416516,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770048,
    StateName: "ODISHA",
  },
  {
    Pincode: 769003,
    StateName: "ODISHA",
  },
  {
    Pincode: 416411,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416418,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416412,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416419,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416312,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 327026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 577453,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577417,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 759145,
    StateName: "ODISHA",
  },
  {
    Pincode: 676521,
    StateName: "KERALA",
  },
  {
    Pincode: 679332,
    StateName: "KERALA",
  },
  {
    Pincode: 679339,
    StateName: "KERALA",
  },
  {
    Pincode: 691551,
    StateName: "KERALA",
  },
  {
    Pincode: 689501,
    StateName: "KERALA",
  },
  {
    Pincode: 690522,
    StateName: "KERALA",
  },
  {
    Pincode: 689674,
    StateName: "KERALA",
  },
  {
    Pincode: 689643,
    StateName: "KERALA",
  },
  {
    Pincode: 689675,
    StateName: "KERALA",
  },
  {
    Pincode: 413120,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412218,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680566,
    StateName: "KERALA",
  },
  {
    Pincode: 680682,
    StateName: "KERALA",
  },
  {
    Pincode: 680567,
    StateName: "KERALA",
  },
  {
    Pincode: 680662,
    StateName: "KERALA",
  },
  {
    Pincode: 680711,
    StateName: "KERALA",
  },
  {
    Pincode: 680702,
    StateName: "KERALA",
  },
  {
    Pincode: 680123,
    StateName: "KERALA",
  },
  {
    Pincode: 680668,
    StateName: "KERALA",
  },
  {
    Pincode: 680564,
    StateName: "KERALA",
  },
  {
    Pincode: 680697,
    StateName: "KERALA",
  },
  {
    Pincode: 680562,
    StateName: "KERALA",
  },
  {
    Pincode: 678581,
    StateName: "KERALA",
  },
  {
    Pincode: 678601,
    StateName: "KERALA",
  },
  {
    Pincode: 679536,
    StateName: "KERALA",
  },
  {
    Pincode: 679503,
    StateName: "KERALA",
  },
  {
    Pincode: 679511,
    StateName: "KERALA",
  },
  {
    Pincode: 678596,
    StateName: "KERALA",
  },
  {
    Pincode: 678595,
    StateName: "KERALA",
  },
  {
    Pincode: 402101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431117,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431150,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689649,
    StateName: "KERALA",
  },
  {
    Pincode: 689648,
    StateName: "KERALA",
  },
  {
    Pincode: 689691,
    StateName: "KERALA",
  },
  {
    Pincode: 486006,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 695103,
    StateName: "KERALA",
  },
  {
    Pincode: 695310,
    StateName: "KERALA",
  },
  {
    Pincode: 695311,
    StateName: "KERALA",
  },
  {
    Pincode: 695605,
    StateName: "KERALA",
  },
  {
    Pincode: 695601,
    StateName: "KERALA",
  },
  {
    Pincode: 695602,
    StateName: "KERALA",
  },
  {
    Pincode: 695610,
    StateName: "KERALA",
  },
  {
    Pincode: 695609,
    StateName: "KERALA",
  },
  {
    Pincode: 695584,
    StateName: "KERALA",
  },
  {
    Pincode: 695612,
    StateName: "KERALA",
  },
  {
    Pincode: 695303,
    StateName: "KERALA",
  },
  {
    Pincode: 461990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416549,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 574103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576247,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 679506,
    StateName: "KERALA",
  },
  {
    Pincode: 678633,
    StateName: "KERALA",
  },
  {
    Pincode: 679523,
    StateName: "KERALA",
  },
  {
    Pincode: 679337,
    StateName: "KERALA",
  },
  {
    Pincode: 754113,
    StateName: "ODISHA",
  },
  {
    Pincode: 403709,
    StateName: "GOA",
  },
  {
    Pincode: 403728,
    StateName: "GOA",
  },
  {
    Pincode: 770015,
    StateName: "ODISHA",
  },
  {
    Pincode: 410208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577418,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577419,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577435,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577421,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577452,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577427,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 400707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410220,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770016,
    StateName: "ODISHA",
  },
  {
    Pincode: 770075,
    StateName: "ODISHA",
  },
  {
    Pincode: 577224,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 457114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 695586,
    StateName: "KERALA",
  },
  {
    Pincode: 695614,
    StateName: "KERALA",
  },
  {
    Pincode: 695606,
    StateName: "KERALA",
  },
  {
    Pincode: 695141,
    StateName: "KERALA",
  },
  {
    Pincode: 695615,
    StateName: "KERALA",
  },
  {
    Pincode: 695607,
    StateName: "KERALA",
  },
  {
    Pincode: 695312,
    StateName: "KERALA",
  },
  {
    Pincode: 695021,
    StateName: "KERALA",
  },
  {
    Pincode: 695581,
    StateName: "KERALA",
  },
  {
    Pincode: 695011,
    StateName: "KERALA",
  },
  {
    Pincode: 483775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 422601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413714,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413738,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413737,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413739,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 487225,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 632010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 458883,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 752121,
    StateName: "ODISHA",
  },
  {
    Pincode: 576124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 321615,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 461111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 574119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577416,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577423,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581129,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581340,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581412,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581346,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581347,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581349,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581450,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581403,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 485775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485778,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 415643,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 461771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670595,
    StateName: "KERALA",
  },
  {
    Pincode: 670621,
    StateName: "KERALA",
  },
  {
    Pincode: 670592,
    StateName: "KERALA",
  },
  {
    Pincode: 670602,
    StateName: "KERALA",
  },
  {
    Pincode: 670331,
    StateName: "KERALA",
  },
  {
    Pincode: 670622,
    StateName: "KERALA",
  },
  {
    Pincode: 756129,
    StateName: "ODISHA",
  },
  {
    Pincode: 756163,
    StateName: "ODISHA",
  },
  {
    Pincode: 770037,
    StateName: "ODISHA",
  },
  {
    Pincode: 576225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 431703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 466113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 466118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431513,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441907,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441910,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441909,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422222,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 572116,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 624210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 691556,
    StateName: "KERALA",
  },
  {
    Pincode: 690520,
    StateName: "KERALA",
  },
  {
    Pincode: 690561,
    StateName: "KERALA",
  },
  {
    Pincode: 624610,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 412201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 572223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 695008,
    StateName: "KERALA",
  },
  {
    Pincode: 695122,
    StateName: "KERALA",
  },
  {
    Pincode: 695102,
    StateName: "KERALA",
  },
  {
    Pincode: 695304,
    StateName: "KERALA",
  },
  {
    Pincode: 695306,
    StateName: "KERALA",
  },
  {
    Pincode: 695608,
    StateName: "KERALA",
  },
  {
    Pincode: 695104,
    StateName: "KERALA",
  },
  {
    Pincode: 481111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481102,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481051,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 695144,
    StateName: "KERALA",
  },
  {
    Pincode: 695603,
    StateName: "KERALA",
  },
  {
    Pincode: 695307,
    StateName: "KERALA",
  },
  {
    Pincode: 695604,
    StateName: "KERALA",
  },
  {
    Pincode: 695305,
    StateName: "KERALA",
  },
  {
    Pincode: 695145,
    StateName: "KERALA",
  },
  {
    Pincode: 695583,
    StateName: "KERALA",
  },
  {
    Pincode: 695024,
    StateName: "KERALA",
  },
  {
    Pincode: 695028,
    StateName: "KERALA",
  },
  {
    Pincode: 689641,
    StateName: "KERALA",
  },
  {
    Pincode: 691554,
    StateName: "KERALA",
  },
  {
    Pincode: 689663,
    StateName: "KERALA",
  },
  {
    Pincode: 689621,
    StateName: "KERALA",
  },
  {
    Pincode: 689104,
    StateName: "KERALA",
  },
  {
    Pincode: 481222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 481556,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431509,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431521,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431514,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431720,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 461441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 572142,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572120,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 422006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 572122,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572124,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 689571,
    StateName: "KERALA",
  },
  {
    Pincode: 689572,
    StateName: "KERALA",
  },
  {
    Pincode: 431504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689590,
    StateName: "KERALA",
  },
  {
    Pincode: 482003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 482002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486550,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486553,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 581186,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581355,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581401,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 416402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 482021,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483119,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 482008,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483501,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483773,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486450,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461668,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 690572,
    StateName: "KERALA",
  },
  {
    Pincode: 690512,
    StateName: "KERALA",
  },
  {
    Pincode: 690108,
    StateName: "KERALA",
  },
  {
    Pincode: 690558,
    StateName: "KERALA",
  },
  {
    Pincode: 689509,
    StateName: "KERALA",
  },
  {
    Pincode: 670504,
    StateName: "KERALA",
  },
  {
    Pincode: 416407,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 485774,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 415719,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416523,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416522,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416529,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416550,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444902,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 670009,
    StateName: "KERALA",
  },
  {
    Pincode: 670006,
    StateName: "KERALA",
  },
  {
    Pincode: 670591,
    StateName: "KERALA",
  },
  {
    Pincode: 670593,
    StateName: "KERALA",
  },
  {
    Pincode: 686561,
    StateName: "KERALA",
  },
  {
    Pincode: 686601,
    StateName: "KERALA",
  },
  {
    Pincode: 313038,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 670613,
    StateName: "KERALA",
  },
  {
    Pincode: 670561,
    StateName: "KERALA",
  },
  {
    Pincode: 670303,
    StateName: "KERALA",
  },
  {
    Pincode: 571416,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571417,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571419,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571422,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571403,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571475,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571446,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571476,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571424,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571425,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571478,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571428,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571430,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571433,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571463,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571402,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571405,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571605,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571448,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571606,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571802,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 486117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486123,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486338,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 770070,
    StateName: "ODISHA",
  },
  {
    Pincode: 400708,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400706,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415711,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415612,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410221,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770076,
    StateName: "ODISHA",
  },
  {
    Pincode: 679552,
    StateName: "KERALA",
  },
  {
    Pincode: 679301,
    StateName: "KERALA",
  },
  {
    Pincode: 678583,
    StateName: "KERALA",
  },
  {
    Pincode: 678582,
    StateName: "KERALA",
  },
  {
    Pincode: 679335,
    StateName: "KERALA",
  },
  {
    Pincode: 461110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 721232,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 444802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444711,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444905,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 679123,
    StateName: "KERALA",
  },
  {
    Pincode: 679313,
    StateName: "KERALA",
  },
  {
    Pincode: 680571,
    StateName: "KERALA",
  },
  {
    Pincode: 680669,
    StateName: "KERALA",
  },
  {
    Pincode: 680663,
    StateName: "KERALA",
  },
  {
    Pincode: 680701,
    StateName: "KERALA",
  },
  {
    Pincode: 678591,
    StateName: "KERALA",
  },
  {
    Pincode: 679521,
    StateName: "KERALA",
  },
  {
    Pincode: 679103,
    StateName: "KERALA",
  },
  {
    Pincode: 770022,
    StateName: "ODISHA",
  },
  {
    Pincode: 770024,
    StateName: "ODISHA",
  },
  {
    Pincode: 770013,
    StateName: "ODISHA",
  },
  {
    Pincode: 472337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472442,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472246,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 472447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 637401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636451,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415022,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 571812,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571436,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 483770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 283114,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 473249,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473287,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 571421,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571450,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 636354,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 473113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679330,
    StateName: "KERALA",
  },
  {
    Pincode: 679329,
    StateName: "KERALA",
  },
  {
    Pincode: 673637,
    StateName: "KERALA",
  },
  {
    Pincode: 572125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572127,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 423303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 450991,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 450051,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413216,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770073,
    StateName: "ODISHA",
  },
  {
    Pincode: 768108,
    StateName: "ODISHA",
  },
  {
    Pincode: 770019,
    StateName: "ODISHA",
  },
  {
    Pincode: 770018,
    StateName: "ODISHA",
  },
  {
    Pincode: 770012,
    StateName: "ODISHA",
  },
  {
    Pincode: 770017,
    StateName: "ODISHA",
  },
  {
    Pincode: 415729,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415519,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415513,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415523,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415525,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444708,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 571429,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571418,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 752085,
    StateName: "ODISHA",
  },
  {
    Pincode: 721260,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 574143,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574144,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574151,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574153,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574199,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575007,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575029,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577544,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574141,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574142,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570010,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 283123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 282007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 282006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 282009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 415610,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415620,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415617,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415639,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444915,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444901,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689103,
    StateName: "KERALA",
  },
  {
    Pincode: 689108,
    StateName: "KERALA",
  },
  {
    Pincode: 473110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 688011,
    StateName: "KERALA",
  },
  {
    Pincode: 444707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444903,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 473885,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473585,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473793,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473774,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 283105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 445109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415522,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415520,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 224001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224225,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 575023,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575028,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 282002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283110,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 756128,
    StateName: "ODISHA",
  },
  {
    Pincode: 756118,
    StateName: "ODISHA",
  },
  {
    Pincode: 752092,
    StateName: "ODISHA",
  },
  {
    Pincode: 752025,
    StateName: "ODISHA",
  },
  {
    Pincode: 752068,
    StateName: "ODISHA",
  },
  {
    Pincode: 752077,
    StateName: "ODISHA",
  },
  {
    Pincode: 752090,
    StateName: "ODISHA",
  },
  {
    Pincode: 767039,
    StateName: "ODISHA",
  },
  {
    Pincode: 416626,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 752078,
    StateName: "ODISHA",
  },
  {
    Pincode: 423202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 752093,
    StateName: "ODISHA",
  },
  {
    Pincode: 415508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415540,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 465669,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 574146,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574154,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575030,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574150,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575014,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 224123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 412312,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 465683,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 402303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445230,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413725,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413720,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422611,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 762018,
    StateName: "ODISHA",
  },
  {
    Pincode: 224116,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224189,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 314801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314035,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314036,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 475661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 224141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224228,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 342311,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 636204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 331304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 412104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410516,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410512,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 636111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 752013,
    StateName: "ODISHA",
  },
  {
    Pincode: 752016,
    StateName: "ODISHA",
  },
  {
    Pincode: 752094,
    StateName: "ODISHA",
  },
  {
    Pincode: 752079,
    StateName: "ODISHA",
  },
  {
    Pincode: 752070,
    StateName: "ODISHA",
  },
  {
    Pincode: 752083,
    StateName: "ODISHA",
  },
  {
    Pincode: 679327,
    StateName: "KERALA",
  },
  {
    Pincode: 679328,
    StateName: "KERALA",
  },
  {
    Pincode: 464551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 676551,
    StateName: "KERALA",
  },
  {
    Pincode: 676504,
    StateName: "KERALA",
  },
  {
    Pincode: 636404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636351,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636309,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 412308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412412,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 676503,
    StateName: "KERALA",
  },
  {
    Pincode: 676562,
    StateName: "KERALA",
  },
  {
    Pincode: 636452,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636458,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 752081,
    StateName: "ODISHA",
  },
  {
    Pincode: 752026,
    StateName: "ODISHA",
  },
  {
    Pincode: 752065,
    StateName: "ODISHA",
  },
  {
    Pincode: 752080,
    StateName: "ODISHA",
  },
  {
    Pincode: 752091,
    StateName: "ODISHA",
  },
  {
    Pincode: 415510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 147004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 401405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 331501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 413401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764045,
    StateName: "ODISHA",
  },
  {
    Pincode: 764044,
    StateName: "ODISHA",
  },
  {
    Pincode: 445210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415312,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415539,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415538,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415509,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 686602,
    StateName: "KERALA",
  },
  {
    Pincode: 686016,
    StateName: "KERALA",
  },
  {
    Pincode: 721144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721242,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721253,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 321601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 670005,
    StateName: "KERALA",
  },
  {
    Pincode: 670604,
    StateName: "KERALA",
  },
  {
    Pincode: 415207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 486115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486340,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416216,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 147102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 333026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 442904,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413226,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413220,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 484224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484776,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 761017,
    StateName: "ODISHA",
  },
  {
    Pincode: 761215,
    StateName: "ODISHA",
  },
  {
    Pincode: 761207,
    StateName: "ODISHA",
  },
  {
    Pincode: 761210,
    StateName: "ODISHA",
  },
  {
    Pincode: 761206,
    StateName: "ODISHA",
  },
  {
    Pincode: 761208,
    StateName: "ODISHA",
  },
  {
    Pincode: 761015,
    StateName: "ODISHA",
  },
  {
    Pincode: 761212,
    StateName: "ODISHA",
  },
  {
    Pincode: 761214,
    StateName: "ODISHA",
  },
  {
    Pincode: 761217,
    StateName: "ODISHA",
  },
  {
    Pincode: 679535,
    StateName: "KERALA",
  },
  {
    Pincode: 680686,
    StateName: "KERALA",
  },
  {
    Pincode: 679305,
    StateName: "KERALA",
  },
  {
    Pincode: 678762,
    StateName: "KERALA",
  },
  {
    Pincode: 679302,
    StateName: "KERALA",
  },
  {
    Pincode: 415206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415512,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 342023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342307,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342605,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 413215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413253,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413219,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413217,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413221,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413410,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413227,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413222,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413248,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413324,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413224,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 486003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670012,
    StateName: "KERALA",
  },
  {
    Pincode: 670601,
    StateName: "KERALA",
  },
  {
    Pincode: 690103,
    StateName: "KERALA",
  },
  {
    Pincode: 342603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 151210,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152115,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152031,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 344035,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 147202,
    StateName: "PUNJAB",
  },
  {
    Pincode: 450554,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413255,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 143402,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758017,
    StateName: "ODISHA",
  },
  {
    Pincode: 758047,
    StateName: "ODISHA",
  },
  {
    Pincode: 758016,
    StateName: "ODISHA",
  },
  {
    Pincode: 758043,
    StateName: "ODISHA",
  },
  {
    Pincode: 758076,
    StateName: "ODISHA",
  },
  {
    Pincode: 758045,
    StateName: "ODISHA",
  },
  {
    Pincode: 416218,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689595,
    StateName: "KERALA",
  },
  {
    Pincode: 482056,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679554,
    StateName: "KERALA",
  },
  {
    Pincode: 403507,
    StateName: "GOA",
  },
  {
    Pincode: 403004,
    StateName: "GOA",
  },
  {
    Pincode: 403510,
    StateName: "GOA",
  },
  {
    Pincode: 403512,
    StateName: "GOA",
  },
  {
    Pincode: 690540,
    StateName: "KERALA",
  },
  {
    Pincode: 690521,
    StateName: "KERALA",
  },
  {
    Pincode: 403511,
    StateName: "GOA",
  },
  {
    Pincode: 403505,
    StateName: "GOA",
  },
  {
    Pincode: 403506,
    StateName: "GOA",
  },
  {
    Pincode: 141127,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416120,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416138,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770023,
    StateName: "ODISHA",
  },
  {
    Pincode: 403107,
    StateName: "GOA",
  },
  {
    Pincode: 403401,
    StateName: "GOA",
  },
  {
    Pincode: 403406,
    StateName: "GOA",
  },
  {
    Pincode: 416507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 331504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331518,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331505,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 482051,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483053,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 482005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 331506,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331517,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 495001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 688005,
    StateName: "KERALA",
  },
  {
    Pincode: 688007,
    StateName: "KERALA",
  },
  {
    Pincode: 689109,
    StateName: "KERALA",
  },
  {
    Pincode: 485112,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 581187,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 415805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415609,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 344801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 151205,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151213,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151202,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151204,
    StateName: "PUNJAB",
  },
  {
    Pincode: 344502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 695301,
    StateName: "KERALA",
  },
  {
    Pincode: 695004,
    StateName: "KERALA",
  },
  {
    Pincode: 695309,
    StateName: "KERALA",
  },
  {
    Pincode: 695313,
    StateName: "KERALA",
  },
  {
    Pincode: 695146,
    StateName: "KERALA",
  },
  {
    Pincode: 695589,
    StateName: "KERALA",
  },
  {
    Pincode: 695143,
    StateName: "KERALA",
  },
  {
    Pincode: 689502,
    StateName: "KERALA",
  },
  {
    Pincode: 689677,
    StateName: "KERALA",
  },
  {
    Pincode: 689585,
    StateName: "KERALA",
  },
  {
    Pincode: 689110,
    StateName: "KERALA",
  },
  {
    Pincode: 224183,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224186,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224227,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224190,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 402202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416524,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416420,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 670007,
    StateName: "KERALA",
  },
  {
    Pincode: 670594,
    StateName: "KERALA",
  },
  {
    Pincode: 670011,
    StateName: "KERALA",
  },
  {
    Pincode: 670003,
    StateName: "KERALA",
  },
  {
    Pincode: 670611,
    StateName: "KERALA",
  },
  {
    Pincode: 721136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721166,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 689541,
    StateName: "KERALA",
  },
  {
    Pincode: 691525,
    StateName: "KERALA",
  },
  {
    Pincode: 759104,
    StateName: "ODISHA",
  },
  {
    Pincode: 673017,
    StateName: "KERALA",
  },
  {
    Pincode: 676561,
    StateName: "KERALA",
  },
  {
    Pincode: 676528,
    StateName: "KERALA",
  },
  {
    Pincode: 676303,
    StateName: "KERALA",
  },
  {
    Pincode: 676106,
    StateName: "KERALA",
  },
  {
    Pincode: 676302,
    StateName: "KERALA",
  },
  {
    Pincode: 676320,
    StateName: "KERALA",
  },
  {
    Pincode: 676103,
    StateName: "KERALA",
  },
  {
    Pincode: 676105,
    StateName: "KERALA",
  },
  {
    Pincode: 676301,
    StateName: "KERALA",
  },
  {
    Pincode: 676306,
    StateName: "KERALA",
  },
  {
    Pincode: 676311,
    StateName: "KERALA",
  },
  {
    Pincode: 676502,
    StateName: "KERALA",
  },
  {
    Pincode: 458110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 444804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415615,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413412,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441223,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764040,
    StateName: "ODISHA",
  },
  {
    Pincode: 444907,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 570028,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 495677,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 758020,
    StateName: "ODISHA",
  },
  {
    Pincode: 758018,
    StateName: "ODISHA",
  },
  {
    Pincode: 758030,
    StateName: "ODISHA",
  },
  {
    Pincode: 758026,
    StateName: "ODISHA",
  },
  {
    Pincode: 758019,
    StateName: "ODISHA",
  },
  {
    Pincode: 143111,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143601,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758021,
    StateName: "ODISHA",
  },
  {
    Pincode: 758038,
    StateName: "ODISHA",
  },
  {
    Pincode: 758035,
    StateName: "ODISHA",
  },
  {
    Pincode: 758080,
    StateName: "ODISHA",
  },
  {
    Pincode: 758041,
    StateName: "ODISHA",
  },
  {
    Pincode: 673640,
    StateName: "KERALA",
  },
  {
    Pincode: 673636,
    StateName: "KERALA",
  },
  {
    Pincode: 473111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689650,
    StateName: "KERALA",
  },
  {
    Pincode: 689668,
    StateName: "KERALA",
  },
  {
    Pincode: 689106,
    StateName: "KERALA",
  },
  {
    Pincode: 689105,
    StateName: "KERALA",
  },
  {
    Pincode: 412110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410509,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412408,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411025,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 576101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581453,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 445323,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413715,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410515,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 590006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 591113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 758078,
    StateName: "ODISHA",
  },
  {
    Pincode: 624801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144801,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144629,
    StateName: "PUNJAB",
  },
  {
    Pincode: 314402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 152025,
    StateName: "PUNJAB",
  },
  {
    Pincode: 576111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 757026,
    StateName: "ODISHA",
  },
  {
    Pincode: 757074,
    StateName: "ODISHA",
  },
  {
    Pincode: 757025,
    StateName: "ODISHA",
  },
  {
    Pincode: 757087,
    StateName: "ODISHA",
  },
  {
    Pincode: 410511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 575015,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575013,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576121,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575010,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574509,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 344044,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 590016,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590019,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768027,
    StateName: "ODISHA",
  },
  {
    Pincode: 768038,
    StateName: "ODISHA",
  },
  {
    Pincode: 583101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 344501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 500045,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500004,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500033,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500082,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500028,
    StateName: "TELANGANA",
  },
  {
    Pincode: 147005,
    StateName: "PUNJAB",
  },
  {
    Pincode: 331507,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 147203,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416143,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416114,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458553,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 148022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148017,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148026,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148029,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148030,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 403110,
    StateName: "GOA",
  },
  {
    Pincode: 403104,
    StateName: "GOA",
  },
  {
    Pincode: 403105,
    StateName: "GOA",
  },
  {
    Pincode: 623513,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623514,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623515,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623516,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623517,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623519,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 334001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 575018,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574145,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575022,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575017,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 314027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 136118,
    StateName: "HARYANA",
  },
  {
    Pincode: 136134,
    StateName: "HARYANA",
  },
  {
    Pincode: 757014,
    StateName: "ODISHA",
  },
  {
    Pincode: 757018,
    StateName: "ODISHA",
  },
  {
    Pincode: 587331,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 151507,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151302,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151501,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151005,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151206,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151508,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151504,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 757021,
    StateName: "ODISHA",
  },
  {
    Pincode: 757075,
    StateName: "ODISHA",
  },
  {
    Pincode: 757082,
    StateName: "ODISHA",
  },
  {
    Pincode: 757023,
    StateName: "ODISHA",
  },
  {
    Pincode: 757086,
    StateName: "ODISHA",
  },
  {
    Pincode: 757093,
    StateName: "ODISHA",
  },
  {
    Pincode: 623521,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623523,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 484774,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484110,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484660,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 637201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637214,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623537,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623525,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623538,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623528,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623532,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623531,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623407,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623530,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623534,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623533,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 403403,
    StateName: "GOA",
  },
  {
    Pincode: 403513,
    StateName: "GOA",
  },
  {
    Pincode: 403108,
    StateName: "GOA",
  },
  {
    Pincode: 403530,
    StateName: "GOA",
  },
  {
    Pincode: 314401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314406,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314038,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 575008,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 416111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416230,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 575019,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 490001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 144701,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144041,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144040,
    StateName: "PUNJAB",
  },
  {
    Pincode: 343030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343049,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343042,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 689107,
    StateName: "KERALA",
  },
  {
    Pincode: 689589,
    StateName: "KERALA",
  },
  {
    Pincode: 314022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 689588,
    StateName: "KERALA",
  },
  {
    Pincode: 601103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 758032,
    StateName: "ODISHA",
  },
  {
    Pincode: 758085,
    StateName: "ODISHA",
  },
  {
    Pincode: 758086,
    StateName: "ODISHA",
  },
  {
    Pincode: 758087,
    StateName: "ODISHA",
  },
  {
    Pincode: 147104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140406,
    StateName: "PUNJAB",
  },
  {
    Pincode: 403114,
    StateName: "GOA",
  },
  {
    Pincode: 403404,
    StateName: "GOA",
  },
  {
    Pincode: 590011,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590017,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 141126,
    StateName: "PUNJAB",
  },
  {
    Pincode: 403115,
    StateName: "GOA",
  },
  {
    Pincode: 403517,
    StateName: "GOA",
  },
  {
    Pincode: 314024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 140408,
    StateName: "PUNJAB",
  },
  {
    Pincode: 307501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307511,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307512,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307019,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307513,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 495452,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 495444,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 224133,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 422308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 331503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 757052,
    StateName: "ODISHA",
  },
  {
    Pincode: 757040,
    StateName: "ODISHA",
  },
  {
    Pincode: 757019,
    StateName: "ODISHA",
  },
  {
    Pincode: 151502,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151506,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151510,
    StateName: "PUNJAB",
  },
  {
    Pincode: 124001,
    StateName: "HARYANA",
  },
  {
    Pincode: 321023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 416203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 623522,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795128,
    StateName: "MANIPUR",
  },
  {
    Pincode: 231305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 490026,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 151208,
    StateName: "PUNJAB",
  },
  {
    Pincode: 344043,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 341516,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341318,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341508,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341519,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 458468,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 209745,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209747,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209735,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 413304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413322,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 209727,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209728,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 457555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457340,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457119,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457550,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 224235,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 490011,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 410513,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 575011,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 637503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 484116,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 522101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 484440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457333,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 457118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 333001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 757083,
    StateName: "ODISHA",
  },
  {
    Pincode: 416528,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 331025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333707,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 422305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416146,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416121,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416231,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144624,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 605007,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 607402,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605004,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605106,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605010,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605009,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 641663,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641664,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641607,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641606,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143119,
    StateName: "PUNJAB",
  },
  {
    Pincode: 758082,
    StateName: "ODISHA",
  },
  {
    Pincode: 413223,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 451447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 344703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 458664,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 124507,
    StateName: "HARYANA",
  },
  {
    Pincode: 124141,
    StateName: "HARYANA",
  },
  {
    Pincode: 484444,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 637101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623536,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623524,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623526,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623409,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623315,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 583153,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 148102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 403103,
    StateName: "GOA",
  },
  {
    Pincode: 637102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638183,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 484330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484886,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484887,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484669,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 124513,
    StateName: "HARYANA",
  },
  {
    Pincode: 484665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679512,
    StateName: "KERALA",
  },
  {
    Pincode: 679505,
    StateName: "KERALA",
  },
  {
    Pincode: 458775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458778,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458667,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 414609,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413716,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422620,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413718,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413717,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422610,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 678597,
    StateName: "KERALA",
  },
  {
    Pincode: 679306,
    StateName: "KERALA",
  },
  {
    Pincode: 262001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 400021,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 484117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413721,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413706,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 231211,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231215,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231213,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 795102,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795127,
    StateName: "MANIPUR",
  },
  {
    Pincode: 457339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 148105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 630551,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 321407,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313322,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313327,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313323,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313328,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 758046,
    StateName: "ODISHA",
  },
  {
    Pincode: 799269,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799261,
    StateName: "TRIPURA",
  },
  {
    Pincode: 465449,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 341515,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341517,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341306,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341507,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341506,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341533,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 637304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641669,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 414505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 451225,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451442,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 451440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 307803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343040,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343048,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 410406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 314026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301411,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341509,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341316,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341319,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 484446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 141117,
    StateName: "PUNJAB",
  },
  {
    Pincode: 756114,
    StateName: "ODISHA",
  },
  {
    Pincode: 144404,
    StateName: "PUNJAB",
  },
  {
    Pincode: 641667,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 500018,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500081,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500096,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500041,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500034,
    StateName: "TELANGANA",
  },
  {
    Pincode: 151503,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151111,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 756113,
    StateName: "ODISHA",
  },
  {
    Pincode: 756112,
    StateName: "ODISHA",
  },
  {
    Pincode: 756125,
    StateName: "ODISHA",
  },
  {
    Pincode: 756168,
    StateName: "ODISHA",
  },
  {
    Pincode: 756120,
    StateName: "ODISHA",
  },
  {
    Pincode: 756165,
    StateName: "ODISHA",
  },
  {
    Pincode: 756133,
    StateName: "ODISHA",
  },
  {
    Pincode: 756101,
    StateName: "ODISHA",
  },
  {
    Pincode: 756171,
    StateName: "ODISHA",
  },
  {
    Pincode: 756117,
    StateName: "ODISHA",
  },
  {
    Pincode: 756119,
    StateName: "ODISHA",
  },
  {
    Pincode: 756127,
    StateName: "ODISHA",
  },
  {
    Pincode: 756162,
    StateName: "ODISHA",
  },
  {
    Pincode: 756124,
    StateName: "ODISHA",
  },
  {
    Pincode: 148018,
    StateName: "PUNJAB",
  },
  {
    Pincode: 641605,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641662,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641666,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 506013,
    StateName: "TELANGANA",
  },
  {
    Pincode: 344705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332709,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332711,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332713,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332718,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332719,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 465674,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413719,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413708,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422622,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 341001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 623404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623529,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 590009,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 590003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 402404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 341317,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 422602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413723,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413712,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413722,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 333302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 636104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636141,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636121,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 313603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 441105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 799277,
    StateName: "TRIPURA",
  },
  {
    Pincode: 441303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 456665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452020,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 451660,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 148021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 148101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 630602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 756144,
    StateName: "ODISHA",
  },
  {
    Pincode: 583105,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 313804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313330,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313329,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313342,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 422608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 676307,
    StateName: "KERALA",
  },
  {
    Pincode: 676510,
    StateName: "KERALA",
  },
  {
    Pincode: 676108,
    StateName: "KERALA",
  },
  {
    Pincode: 679324,
    StateName: "KERALA",
  },
  {
    Pincode: 679326,
    StateName: "KERALA",
  },
  {
    Pincode: 676506,
    StateName: "KERALA",
  },
  {
    Pincode: 676507,
    StateName: "KERALA",
  },
  {
    Pincode: 673642,
    StateName: "KERALA",
  },
  {
    Pincode: 332742,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332712,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332714,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 490009,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 453112,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 465118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458895,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413317,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413315,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 483504,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486776,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486670,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 403006,
    StateName: "GOA",
  },
  {
    Pincode: 484661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 484664,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 676509,
    StateName: "KERALA",
  },
  {
    Pincode: 679325,
    StateName: "KERALA",
  },
  {
    Pincode: 673639,
    StateName: "KERALA",
  },
  {
    Pincode: 673634,
    StateName: "KERALA",
  },
  {
    Pincode: 679334,
    StateName: "KERALA",
  },
  {
    Pincode: 679322,
    StateName: "KERALA",
  },
  {
    Pincode: 679323,
    StateName: "KERALA",
  },
  {
    Pincode: 673645,
    StateName: "KERALA",
  },
  {
    Pincode: 679331,
    StateName: "KERALA",
  },
  {
    Pincode: 676541,
    StateName: "KERALA",
  },
  {
    Pincode: 307043,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 637215,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638181,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 495455,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 343039,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 442201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 464665,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464672,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464668,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 679341,
    StateName: "KERALA",
  },
  {
    Pincode: 413736,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 637501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636138,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636139,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636142,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 441107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 301402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 476001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 343001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 476111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476219,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 500038,
    StateName: "TELANGANA",
  },
  {
    Pincode: 797112,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797111,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797103,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797118,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797117,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797116,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798611,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797002,
    StateName: "NAGALAND",
  },
  {
    Pincode: 403502,
    StateName: "GOA",
  },
  {
    Pincode: 583118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583275,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 423605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 209503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209729,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209731,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209734,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209732,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209738,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 402209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402120,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 151106,
    StateName: "PUNJAB",
  },
  {
    Pincode: 230401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 477117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477557,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477105,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 756182,
    StateName: "ODISHA",
  },
  {
    Pincode: 452016,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477333,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 456222,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456313,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456550,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 424102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425420,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 464570,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464774,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464884,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464671,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464776,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464651,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464886,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 464986,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 490006,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 685619,
    StateName: "KERALA",
  },
  {
    Pincode: 312022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323307,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 230403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230138,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230139,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 486882,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486889,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486886,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 605014,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 486887,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470021,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470442,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 442301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 212601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212631,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 343032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 421402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 333304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312614,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312619,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312616,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 453771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 453551,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 275306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 402117,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402122,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 476444,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 476335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 230306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 421301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 230404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230133,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230134,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221603,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221602,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221706,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 442304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 758037,
    StateName: "ODISHA",
  },
  {
    Pincode: 416526,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416223,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 455227,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416122,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416219,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416551,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416118,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416235,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416232,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416220,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400612,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 470051,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470226,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470228,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470232,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470125,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 421312,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 146110,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144519,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416221,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 470235,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 606204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271825,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 415605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415726,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 484113,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 604505,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604408,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604410,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606755,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 230136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284419,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 284204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 151505,
    StateName: "PUNJAB",
  },
  {
    Pincode: 275307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 402115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458888,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470117,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470880,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470664,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470673,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470675,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 475675,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 636140,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 485115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485111,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470663,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470666,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470672,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 412409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 458556,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416413,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416414,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416415,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 485772,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 231219,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 642132,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 321001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 610205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 402126,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416314,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415412,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415315,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 757055,
    StateName: "ODISHA",
  },
  {
    Pincode: 757100,
    StateName: "ODISHA",
  },
  {
    Pincode: 685618,
    StateName: "KERALA",
  },
  {
    Pincode: 685595,
    StateName: "KERALA",
  },
  {
    Pincode: 455223,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 232102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 415603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 455332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 343023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 621701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 402204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 609704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 455339,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455459,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 455118,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 424203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756046,
    StateName: "ODISHA",
  },
  {
    Pincode: 685583,
    StateName: "KERALA",
  },
  {
    Pincode: 685563,
    StateName: "KERALA",
  },
  {
    Pincode: 685608,
    StateName: "KERALA",
  },
  {
    Pincode: 685585,
    StateName: "KERALA",
  },
  {
    Pincode: 685614,
    StateName: "KERALA",
  },
  {
    Pincode: 685607,
    StateName: "KERALA",
  },
  {
    Pincode: 209749,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 458330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 458336,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 485114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 307030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 343027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307515,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 764052,
    StateName: "ODISHA",
  },
  {
    Pincode: 764072,
    StateName: "ODISHA",
  },
  {
    Pincode: 764002,
    StateName: "ODISHA",
  },
  {
    Pincode: 441207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 623518,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 431102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 475115,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 327604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 613602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 412102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413132,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425310,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425327,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425524,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 613102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 768228,
    StateName: "ODISHA",
  },
  {
    Pincode: 768227,
    StateName: "ODISHA",
  },
  {
    Pincode: 768214,
    StateName: "ODISHA",
  },
  {
    Pincode: 768212,
    StateName: "ODISHA",
  },
  {
    Pincode: 431101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431118,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431135,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144623,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143109,
    StateName: "PUNJAB",
  },
  {
    Pincode: 768110,
    StateName: "ODISHA",
  },
  {
    Pincode: 425302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 794109,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 761151,
    StateName: "ODISHA",
  },
  {
    Pincode: 766019,
    StateName: "ODISHA",
  },
  {
    Pincode: 766102,
    StateName: "ODISHA",
  },
  {
    Pincode: 766023,
    StateName: "ODISHA",
  },
  {
    Pincode: 766026,
    StateName: "ODISHA",
  },
  {
    Pincode: 766110,
    StateName: "ODISHA",
  },
  {
    Pincode: 413314,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 766100,
    StateName: "ODISHA",
  },
  {
    Pincode: 766103,
    StateName: "ODISHA",
  },
  {
    Pincode: 766029,
    StateName: "ODISHA",
  },
  {
    Pincode: 425203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 470771,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470772,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431131,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431125,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431515,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413229,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413249,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431129,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 327034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431130,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431530,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431144,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764005,
    StateName: "ODISHA",
  },
  {
    Pincode: 764041,
    StateName: "ODISHA",
  },
  {
    Pincode: 764028,
    StateName: "ODISHA",
  },
  {
    Pincode: 764058,
    StateName: "ODISHA",
  },
  {
    Pincode: 680741,
    StateName: "KERALA",
  },
  {
    Pincode: 680731,
    StateName: "KERALA",
  },
  {
    Pincode: 680312,
    StateName: "KERALA",
  },
  {
    Pincode: 680309,
    StateName: "KERALA",
  },
  {
    Pincode: 680302,
    StateName: "KERALA",
  },
  {
    Pincode: 680684,
    StateName: "KERALA",
  },
  {
    Pincode: 322001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 224181,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 321611,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 768222,
    StateName: "ODISHA",
  },
  {
    Pincode: 768107,
    StateName: "ODISHA",
  },
  {
    Pincode: 768234,
    StateName: "ODISHA",
  },
  {
    Pincode: 768202,
    StateName: "ODISHA",
  },
  {
    Pincode: 431120,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756033,
    StateName: "ODISHA",
  },
  {
    Pincode: 756086,
    StateName: "ODISHA",
  },
  {
    Pincode: 756081,
    StateName: "ODISHA",
  },
  {
    Pincode: 756035,
    StateName: "ODISHA",
  },
  {
    Pincode: 756034,
    StateName: "ODISHA",
  },
  {
    Pincode: 756024,
    StateName: "ODISHA",
  },
  {
    Pincode: 431124,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756083,
    StateName: "ODISHA",
  },
  {
    Pincode: 756051,
    StateName: "ODISHA",
  },
  {
    Pincode: 756042,
    StateName: "ODISHA",
  },
  {
    Pincode: 756029,
    StateName: "ODISHA",
  },
  {
    Pincode: 756055,
    StateName: "ODISHA",
  },
  {
    Pincode: 756080,
    StateName: "ODISHA",
  },
  {
    Pincode: 312602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301714,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301406,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 797120,
    StateName: "NAGALAND",
  },
  {
    Pincode: 207002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 798615,
    StateName: "NAGALAND",
  },
  {
    Pincode: 277001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 327601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312620,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327606,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 768213,
    StateName: "ODISHA",
  },
  {
    Pincode: 768005,
    StateName: "ODISHA",
  },
  {
    Pincode: 768112,
    StateName: "ODISHA",
  },
  {
    Pincode: 442705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442914,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768113,
    StateName: "ODISHA",
  },
  {
    Pincode: 768121,
    StateName: "ODISHA",
  },
  {
    Pincode: 442701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442917,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441226,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442902,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764076,
    StateName: "ODISHA",
  },
  {
    Pincode: 764047,
    StateName: "ODISHA",
  },
  {
    Pincode: 764075,
    StateName: "ODISHA",
  },
  {
    Pincode: 794003,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 766037,
    StateName: "ODISHA",
  },
  {
    Pincode: 766011,
    StateName: "ODISHA",
  },
  {
    Pincode: 766031,
    StateName: "ODISHA",
  },
  {
    Pincode: 766101,
    StateName: "ODISHA",
  },
  {
    Pincode: 794114,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 444203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761109,
    StateName: "ODISHA",
  },
  {
    Pincode: 766106,
    StateName: "ODISHA",
  },
  {
    Pincode: 766105,
    StateName: "ODISHA",
  },
  {
    Pincode: 761116,
    StateName: "ODISHA",
  },
  {
    Pincode: 761107,
    StateName: "ODISHA",
  },
  {
    Pincode: 341021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 621702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 770035,
    StateName: "ODISHA",
  },
  {
    Pincode: 621703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621719,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 757104,
    StateName: "ODISHA",
  },
  {
    Pincode: 757028,
    StateName: "ODISHA",
  },
  {
    Pincode: 757032,
    StateName: "ODISHA",
  },
  {
    Pincode: 610001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 423109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431132,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431214,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 635802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795140,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795148,
    StateName: "MANIPUR",
  },
  {
    Pincode: 632601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764061,
    StateName: "ODISHA",
  },
  {
    Pincode: 764077,
    StateName: "ODISHA",
  },
  {
    Pincode: 764078,
    StateName: "ODISHA",
  },
  {
    Pincode: 233304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232332,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232333,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 143107,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143009,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143113,
    StateName: "PUNJAB",
  },
  {
    Pincode: 230137,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 637505,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 343028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 766016,
    StateName: "ODISHA",
  },
  {
    Pincode: 766027,
    StateName: "ODISHA",
  },
  {
    Pincode: 444204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 603303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 799254,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799203,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799270,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799201,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799260,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799253,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799205,
    StateName: "TRIPURA",
  },
  {
    Pincode: 795009,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795142,
    StateName: "MANIPUR",
  },
  {
    Pincode: 609504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416436,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 766108,
    StateName: "ODISHA",
  },
  {
    Pincode: 425505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 790106,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791103,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791119,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790104,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792001,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 603302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 791104,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791118,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791102,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790102,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 799285,
    StateName: "TRIPURA",
  },
  {
    Pincode: 614903,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143301,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143408,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143411,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143407,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143412,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143304,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143149,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143502,
    StateName: "PUNJAB",
  },
  {
    Pincode: 614701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614902,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 799288,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799264,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799275,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799279,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799263,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799278,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799284,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799280,
    StateName: "TRIPURA",
  },
  {
    Pincode: 792105,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792103,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792110,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 614802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614615,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143303,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143406,
    StateName: "PUNJAB",
  },
  {
    Pincode: 756039,
    StateName: "ODISHA",
  },
  {
    Pincode: 301027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301408,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301415,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321633,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 442403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764085,
    StateName: "ODISHA",
  },
  {
    Pincode: 768215,
    StateName: "ODISHA",
  },
  {
    Pincode: 768226,
    StateName: "ODISHA",
  },
  {
    Pincode: 676517,
    StateName: "KERALA",
  },
  {
    Pincode: 143305,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143419,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143409,
    StateName: "PUNJAB",
  },
  {
    Pincode: 614623,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143204,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143422,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143114,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143603,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143112,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143116,
    StateName: "PUNJAB",
  },
  {
    Pincode: 483990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483442,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 483334,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 143501,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143115,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143415,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143117,
    StateName: "PUNJAB",
  },
  {
    Pincode: 624710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143205,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143008,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416234,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 143413,
    StateName: "PUNJAB",
  },
  {
    Pincode: 415302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415413,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416315,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 610109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415310,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416408,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416310,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415407,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416614,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416628,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416632,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416612,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415414,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415408,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416313,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415415,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768221,
    StateName: "ODISHA",
  },
  {
    Pincode: 415637,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416712,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768220,
    StateName: "ODISHA",
  },
  {
    Pincode: 416608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416804,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768224,
    StateName: "ODISHA",
  },
  {
    Pincode: 768211,
    StateName: "ODISHA",
  },
  {
    Pincode: 416707,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416713,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415619,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768217,
    StateName: "ODISHA",
  },
  {
    Pincode: 624802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 422205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415807,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415626,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768216,
    StateName: "ODISHA",
  },
  {
    Pincode: 423208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768025,
    StateName: "ODISHA",
  },
  {
    Pincode: 761111,
    StateName: "ODISHA",
  },
  {
    Pincode: 761013,
    StateName: "ODISHA",
  },
  {
    Pincode: 761103,
    StateName: "ODISHA",
  },
  {
    Pincode: 761106,
    StateName: "ODISHA",
  },
  {
    Pincode: 415406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415410,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415411,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423117,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761108,
    StateName: "ODISHA",
  },
  {
    Pincode: 761125,
    StateName: "ODISHA",
  },
  {
    Pincode: 761117,
    StateName: "ODISHA",
  },
  {
    Pincode: 799009,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799004,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799003,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799045,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799102,
    StateName: "TRIPURA",
  },
  {
    Pincode: 322234,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322249,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322251,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431152,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 791105,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791002,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 301026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301409,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301035,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301410,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 416613,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416620,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416630,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416811,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416616,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416609,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416610,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416812,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416611,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416813,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416810,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 603105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 798604,
    StateName: "NAGALAND",
  },
  {
    Pincode: 473660,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 798601,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797113,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797109,
    StateName: "NAGALAND",
  },
  {
    Pincode: 636457,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 798621,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798622,
    StateName: "NAGALAND",
  },
  {
    Pincode: 798602,
    StateName: "NAGALAND",
  },
  {
    Pincode: 301707,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 797004,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797101,
    StateName: "NAGALAND",
  },
  {
    Pincode: 797110,
    StateName: "NAGALAND",
  },
  {
    Pincode: 641654,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764043,
    StateName: "ODISHA",
  },
  {
    Pincode: 323022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323614,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303806,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303807,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303102,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303903,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303103,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303109,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 473990,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473670,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473865,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 332031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332311,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332312,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332307,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332715,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322220,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322252,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322213,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322216,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322255,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322215,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322218,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322219,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322230,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322238,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322236,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321610,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322254,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322243,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322242,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 680317,
    StateName: "KERALA",
  },
  {
    Pincode: 276125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 416534,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 322703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322211,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322214,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 614723,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614904,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 322029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 613205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 768111,
    StateName: "ODISHA",
  },
  {
    Pincode: 768115,
    StateName: "ODISHA",
  },
  {
    Pincode: 768040,
    StateName: "ODISHA",
  },
  {
    Pincode: 322026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 761120,
    StateName: "ODISHA",
  },
  {
    Pincode: 761119,
    StateName: "ODISHA",
  },
  {
    Pincode: 761118,
    StateName: "ODISHA",
  },
  {
    Pincode: 614602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614906,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614625,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764046,
    StateName: "ODISHA",
  },
  {
    Pincode: 764088,
    StateName: "ODISHA",
  },
  {
    Pincode: 764049,
    StateName: "ODISHA",
  },
  {
    Pincode: 764051,
    StateName: "ODISHA",
  },
  {
    Pincode: 414113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413728,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 485773,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 485001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 623706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623608,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623712,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 623711,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 799015,
    StateName: "TRIPURA",
  },
  {
    Pincode: 761141,
    StateName: "ODISHA",
  },
  {
    Pincode: 761123,
    StateName: "ODISHA",
  },
  {
    Pincode: 636003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761124,
    StateName: "ODISHA",
  },
  {
    Pincode: 761121,
    StateName: "ODISHA",
  },
  {
    Pincode: 761133,
    StateName: "ODISHA",
  },
  {
    Pincode: 761143,
    StateName: "ODISHA",
  },
  {
    Pincode: 761146,
    StateName: "ODISHA",
  },
  {
    Pincode: 322212,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 790001,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 799204,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799287,
    StateName: "TRIPURA",
  },
  {
    Pincode: 635810,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635813,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795132,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795133,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795011,
    StateName: "MANIPUR",
  },
  {
    Pincode: 303801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303108,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303905,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303119,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303120,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 414302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413726,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 322704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 768219,
    StateName: "ODISHA",
  },
  {
    Pincode: 768225,
    StateName: "ODISHA",
  },
  {
    Pincode: 627110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627654,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 766104,
    StateName: "ODISHA",
  },
  {
    Pincode: 766107,
    StateName: "ODISHA",
  },
  {
    Pincode: 635811,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635812,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635754,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635851,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635852,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635752,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635853,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795010,
    StateName: "MANIPUR",
  },
  {
    Pincode: 413107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413319,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 799289,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799281,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799273,
    StateName: "TRIPURA",
  },
  {
    Pincode: 442603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 614626,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 423401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 614804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795145,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795131,
    StateName: "MANIPUR",
  },
  {
    Pincode: 635814,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 233228,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641659,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 792111,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 341520,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341505,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 151509,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151301,
    StateName: "PUNJAB",
  },
  {
    Pincode: 641670,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638459,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641652,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641697,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641668,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 442402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442908,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442903,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 332702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 413711,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431121,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431148,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413218,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431750,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431745,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431746,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431810,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431717,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431809,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431722,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431811,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431712,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 762103,
    StateName: "ODISHA",
  },
  {
    Pincode: 762021,
    StateName: "ODISHA",
  },
  {
    Pincode: 762110,
    StateName: "ODISHA",
  },
  {
    Pincode: 313201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332317,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 765025,
    StateName: "ODISHA",
  },
  {
    Pincode: 765029,
    StateName: "ODISHA",
  },
  {
    Pincode: 765002,
    StateName: "ODISHA",
  },
  {
    Pincode: 765018,
    StateName: "ODISHA",
  },
  {
    Pincode: 313702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 769004,
    StateName: "ODISHA",
  },
  {
    Pincode: 639005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 151105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 638501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635815,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 795007,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795004,
    StateName: "MANIPUR",
  },
  {
    Pincode: 301403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321607,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301020,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301709,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301412,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301427,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 791110,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 795129,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795146,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795002,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795107,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795130,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795003,
    StateName: "MANIPUR",
  },
  {
    Pincode: 796891,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796701,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796441,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796431,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796501,
    StateName: "MIZORAM",
  },
  {
    Pincode: 643005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643239,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 301713,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332405,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332042,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 795134,
    StateName: "MANIPUR",
  },
  {
    Pincode: 635854,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 621801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 792101,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792131,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 643220,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643226,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643237,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643241,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 796005,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796370,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796290,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796012,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796421,
    StateName: "MIZORAM",
  },
  {
    Pincode: 764081,
    StateName: "ODISHA",
  },
  {
    Pincode: 414602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 638116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638052,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638315,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638057,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638056,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638752,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638051,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638154,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332411,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 630410,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628902,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628712,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628714,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628952,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629851,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629852,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629251,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 767028,
    StateName: "ODISHA",
  },
  {
    Pincode: 767066,
    StateName: "ODISHA",
  },
  {
    Pincode: 767020,
    StateName: "ODISHA",
  },
  {
    Pincode: 767037,
    StateName: "ODISHA",
  },
  {
    Pincode: 767041,
    StateName: "ODISHA",
  },
  {
    Pincode: 767025,
    StateName: "ODISHA",
  },
  {
    Pincode: 767002,
    StateName: "ODISHA",
  },
  {
    Pincode: 767032,
    StateName: "ODISHA",
  },
  {
    Pincode: 757084,
    StateName: "ODISHA",
  },
  {
    Pincode: 757029,
    StateName: "ODISHA",
  },
  {
    Pincode: 757105,
    StateName: "ODISHA",
  },
  {
    Pincode: 757030,
    StateName: "ODISHA",
  },
  {
    Pincode: 757081,
    StateName: "ODISHA",
  },
  {
    Pincode: 757085,
    StateName: "ODISHA",
  },
  {
    Pincode: 757092,
    StateName: "ODISHA",
  },
  {
    Pincode: 757107,
    StateName: "ODISHA",
  },
  {
    Pincode: 757017,
    StateName: "ODISHA",
  },
  {
    Pincode: 757020,
    StateName: "ODISHA",
  },
  {
    Pincode: 757073,
    StateName: "ODISHA",
  },
  {
    Pincode: 757077,
    StateName: "ODISHA",
  },
  {
    Pincode: 757103,
    StateName: "ODISHA",
  },
  {
    Pincode: 757102,
    StateName: "ODISHA",
  },
  {
    Pincode: 757027,
    StateName: "ODISHA",
  },
  {
    Pincode: 757101,
    StateName: "ODISHA",
  },
  {
    Pincode: 757016,
    StateName: "ODISHA",
  },
  {
    Pincode: 757106,
    StateName: "ODISHA",
  },
  {
    Pincode: 757024,
    StateName: "ODISHA",
  },
  {
    Pincode: 757051,
    StateName: "ODISHA",
  },
  {
    Pincode: 757079,
    StateName: "ODISHA",
  },
  {
    Pincode: 764087,
    StateName: "ODISHA",
  },
  {
    Pincode: 764056,
    StateName: "ODISHA",
  },
  {
    Pincode: 763008,
    StateName: "ODISHA",
  },
  {
    Pincode: 765013,
    StateName: "ODISHA",
  },
  {
    Pincode: 758013,
    StateName: "ODISHA",
  },
  {
    Pincode: 152023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152028,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152033,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152132,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142060,
    StateName: "PUNJAB",
  },
  {
    Pincode: 628908,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628904,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 301407,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 301705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 152122,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152005,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142050,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142047,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152118,
    StateName: "PUNJAB",
  },
  {
    Pincode: 331701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 765024,
    StateName: "ODISHA",
  },
  {
    Pincode: 799035,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799130,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799212,
    StateName: "TRIPURA",
  },
  {
    Pincode: 766036,
    StateName: "ODISHA",
  },
  {
    Pincode: 766017,
    StateName: "ODISHA",
  },
  {
    Pincode: 766015,
    StateName: "ODISHA",
  },
  {
    Pincode: 757031,
    StateName: "ODISHA",
  },
  {
    Pincode: 757049,
    StateName: "ODISHA",
  },
  {
    Pincode: 757022,
    StateName: "ODISHA",
  },
  {
    Pincode: 757003,
    StateName: "ODISHA",
  },
  {
    Pincode: 143507,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143511,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143604,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143605,
    StateName: "PUNJAB",
  },
  {
    Pincode: 757041,
    StateName: "ODISHA",
  },
  {
    Pincode: 757047,
    StateName: "ODISHA",
  },
  {
    Pincode: 757054,
    StateName: "ODISHA",
  },
  {
    Pincode: 757046,
    StateName: "ODISHA",
  },
  {
    Pincode: 757048,
    StateName: "ODISHA",
  },
  {
    Pincode: 757033,
    StateName: "ODISHA",
  },
  {
    Pincode: 757042,
    StateName: "ODISHA",
  },
  {
    Pincode: 757050,
    StateName: "ODISHA",
  },
  {
    Pincode: 757045,
    StateName: "ODISHA",
  },
  {
    Pincode: 757034,
    StateName: "ODISHA",
  },
  {
    Pincode: 757037,
    StateName: "ODISHA",
  },
  {
    Pincode: 757091,
    StateName: "ODISHA",
  },
  {
    Pincode: 795150,
    StateName: "MANIPUR",
  },
  {
    Pincode: 795124,
    StateName: "MANIPUR",
  },
  {
    Pincode: 624202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624619,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624621,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144410,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 635809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 431741,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431716,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431714,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 762030,
    StateName: "ODISHA",
  },
  {
    Pincode: 762024,
    StateName: "ODISHA",
  },
  {
    Pincode: 431215,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 313906,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321614,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321409,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321406,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 757035,
    StateName: "ODISHA",
  },
  {
    Pincode: 144301,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144025,
    StateName: "PUNJAB",
  },
  {
    Pincode: 757039,
    StateName: "ODISHA",
  },
  {
    Pincode: 627719,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627760,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628552,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627753,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627754,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 792055,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790116,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 431151,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431134,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431136,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431147,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413228,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 766018,
    StateName: "ODISHA",
  },
  {
    Pincode: 766014,
    StateName: "ODISHA",
  },
  {
    Pincode: 766013,
    StateName: "ODISHA",
  },
  {
    Pincode: 766012,
    StateName: "ODISHA",
  },
  {
    Pincode: 799011,
    StateName: "TRIPURA",
  },
  {
    Pincode: 766118,
    StateName: "ODISHA",
  },
  {
    Pincode: 765020,
    StateName: "ODISHA",
  },
  {
    Pincode: 206253,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206242,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 796901,
    StateName: "MIZORAM",
  },
  {
    Pincode: 638112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638476,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638505,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 431002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 642154,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 413704,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 642120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642123,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642125,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642127,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144804,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144026,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144630,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144805,
    StateName: "PUNJAB",
  },
  {
    Pincode: 761031,
    StateName: "ODISHA",
  },
  {
    Pincode: 624307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 414105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 796320,
    StateName: "MIZORAM",
  },
  {
    Pincode: 642005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638453,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638454,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638455,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638456,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 797114,
    StateName: "NAGALAND",
  },
  {
    Pincode: 638102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636455,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638316,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638461,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627953,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627755,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627855,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627857,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627757,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627860,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 767068,
    StateName: "ODISHA",
  },
  {
    Pincode: 629701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144106,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144302,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144513,
    StateName: "PUNJAB",
  },
  {
    Pincode: 628905,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628720,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 792056,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792120,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790105,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 144514,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144503,
    StateName: "PUNJAB",
  },
  {
    Pincode: 799007,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799210,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799008,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799006,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799211,
    StateName: "TRIPURA",
  },
  {
    Pincode: 144517,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144421,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144621,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144802,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 799012,
    StateName: "TRIPURA",
  },
  {
    Pincode: 629201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 792130,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790003,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790114,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791122,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 416615,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431137,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 642130,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642129,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 313905,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415313,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 313324,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313341,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305921,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313332,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326529,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326038,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144303,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144508,
    StateName: "PUNJAB",
  },
  {
    Pincode: 312401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 638002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638153,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628721,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627951,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638460,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 232331,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232329,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 331001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331411,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628907,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 334302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331811,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334604,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 643003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643223,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643225,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 796310,
    StateName: "MIZORAM",
  },
  {
    Pincode: 222127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222162,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 792129,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790101,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791101,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790002,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 606104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 796410,
    StateName: "MIZORAM",
  },
  {
    Pincode: 326037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326512,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313333,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313211,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 222126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222144,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222133,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 331301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303712,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 250619,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 331021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 140412,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141411,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141801,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147301,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140405,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140802,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144212,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144207,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144221,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144208,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144209,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144223,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146116,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144305,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144202,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144211,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144214,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144204,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 627761,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 140602,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146113,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144222,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144206,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 222129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 606304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144523,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144520,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144530,
    StateName: "PUNJAB",
  },
  {
    Pincode: 307001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144422,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147006,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140701,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140417,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144521,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144522,
    StateName: "PUNJAB",
  },
  {
    Pincode: 606106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623605,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 331302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 331403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 422304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 423111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 201009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 332001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332318,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 623527,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623135,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332406,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 627764,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332316,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415730,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415621,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415720,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 311407,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 314032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 146114,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146115,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 792102,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792121,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791112,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 792104,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791121,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 456770,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456224,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456668,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 414306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 642201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 761003,
    StateName: "ODISHA",
  },
  {
    Pincode: 761027,
    StateName: "ODISHA",
  },
  {
    Pincode: 761029,
    StateName: "ODISHA",
  },
  {
    Pincode: 441224,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441205,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 473331,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 761030,
    StateName: "ODISHA",
  },
  {
    Pincode: 761032,
    StateName: "ODISHA",
  },
  {
    Pincode: 761045,
    StateName: "ODISHA",
  },
  {
    Pincode: 761105,
    StateName: "ODISHA",
  },
  {
    Pincode: 761019,
    StateName: "ODISHA",
  },
  {
    Pincode: 761018,
    StateName: "ODISHA",
  },
  {
    Pincode: 761028,
    StateName: "ODISHA",
  },
  {
    Pincode: 621313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 306119,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 441614,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441801,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441807,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441911,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411033,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411048,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431517,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 473446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473443,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473332,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473444,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473330,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 431523,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 456443,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456664,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 144533,
    StateName: "PUNJAB",
  },
  {
    Pincode: 334402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 456337,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456661,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456006,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 441201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 799002,
    StateName: "TRIPURA",
  },
  {
    Pincode: 413308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413252,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 306302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 635116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 441302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444407,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761043,
    StateName: "ODISHA",
  },
  {
    Pincode: 761035,
    StateName: "ODISHA",
  },
  {
    Pincode: 326034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 631304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 202123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202134,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204212,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605102,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 306303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306307,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306421,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306102,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306104,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 767017,
    StateName: "ODISHA",
  },
  {
    Pincode: 767016,
    StateName: "ODISHA",
  },
  {
    Pincode: 767062,
    StateName: "ODISHA",
  },
  {
    Pincode: 143525,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143520,
    StateName: "PUNJAB",
  },
  {
    Pincode: 304021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304505,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303313,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 642102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 312601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312606,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 444510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 635207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635121,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 431518,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431153,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431126,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431128,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431127,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411062,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 325009,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 325208,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 143530,
    StateName: "PUNJAB",
  },
  {
    Pincode: 306902,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 441916,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431123,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 638003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144527,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140601,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140702,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144224,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144213,
    StateName: "PUNJAB",
  },
  {
    Pincode: 642133,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642134,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 201015,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643215,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643224,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144028,
    StateName: "PUNJAB",
  },
  {
    Pincode: 221505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 604207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 444701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444904,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 605102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144030,
    StateName: "PUNJAB",
  },
  {
    Pincode: 207003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 431142,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 222146,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222148,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222143,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 767021,
    StateName: "ODISHA",
  },
  {
    Pincode: 767035,
    StateName: "ODISHA",
  },
  {
    Pincode: 767033,
    StateName: "ODISHA",
  },
  {
    Pincode: 767030,
    StateName: "ODISHA",
  },
  {
    Pincode: 767071,
    StateName: "ODISHA",
  },
  {
    Pincode: 302013,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431519,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431143,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 312206,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312605,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415015,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415521,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412806,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413133,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412411,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 313331,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 635102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635126,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635130,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415706,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415712,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415714,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 312623,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312613,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312612,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311806,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628903,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 331031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628722,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628718,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627713,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332315,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142054,
    StateName: "PUNJAB",
  },
  {
    Pincode: 413108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 456441,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 413310,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413251,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 142046,
    StateName: "PUNJAB",
  },
  {
    Pincode: 756037,
    StateName: "ODISHA",
  },
  {
    Pincode: 456440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456776,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 456221,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 311204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 638812,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 202125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 604201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 311403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 207401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207247,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207248,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207241,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207242,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 415004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424309,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425427,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 473445,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 473440,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 304801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 304024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 415718,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412405,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 412410,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 628906,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 444502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411015,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411057,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411047,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411036,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411017,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 207125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 638462,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 207249,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 327031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 327032,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 424105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425113,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 140413,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140301,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140109,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140307,
    StateName: "PUNJAB",
  },
  {
    Pincode: 413130,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756084,
    StateName: "ODISHA",
  },
  {
    Pincode: 224153,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224158,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224117,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224161,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224118,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224164,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 425301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761115,
    StateName: "ODISHA",
  },
  {
    Pincode: 415710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415728,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 473001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 322241,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312207,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312625,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 411014,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 140118,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140111,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140133,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140112,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140126,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140123,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140113,
    StateName: "PUNJAB",
  },
  {
    Pincode: 224201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224119,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224171,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 140115,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140116,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140124,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140128,
    StateName: "PUNJAB",
  },
  {
    Pincode: 313325,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 431537,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144406,
    StateName: "PUNJAB",
  },
  {
    Pincode: 146103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 424308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 635122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 224188,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224195,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 334808,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323306,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 147103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144803,
    StateName: "PUNJAB",
  },
  {
    Pincode: 305901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305631,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305621,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305926,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305624,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305630,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305207,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305923,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305203,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311606,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142032,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142036,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141107,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142031,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142029,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142030,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142027,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141422,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141102,
    StateName: "PUNJAB",
  },
  {
    Pincode: 222139,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 752031,
    StateName: "ODISHA",
  },
  {
    Pincode: 752062,
    StateName: "ODISHA",
  },
  {
    Pincode: 752066,
    StateName: "ODISHA",
  },
  {
    Pincode: 752034,
    StateName: "ODISHA",
  },
  {
    Pincode: 752020,
    StateName: "ODISHA",
  },
  {
    Pincode: 752054,
    StateName: "ODISHA",
  },
  {
    Pincode: 344034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 344702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 224172,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 752050,
    StateName: "ODISHA",
  },
  {
    Pincode: 411021,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411023,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411046,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 635108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 140603,
    StateName: "PUNJAB",
  },
  {
    Pincode: 306912,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 425112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424108,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 140125,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145025,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143534,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140110,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140308,
    StateName: "PUNJAB",
  },
  {
    Pincode: 635101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635123,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 767045,
    StateName: "ODISHA",
  },
  {
    Pincode: 141015,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141007,
    StateName: "PUNJAB",
  },
  {
    Pincode: 441809,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441802,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441902,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441901,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 142035,
    StateName: "PUNJAB",
  },
  {
    Pincode: 626119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626124,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626135,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626125,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626126,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626131,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764057,
    StateName: "ODISHA",
  },
  {
    Pincode: 752035,
    StateName: "ODISHA",
  },
  {
    Pincode: 752027,
    StateName: "ODISHA",
  },
  {
    Pincode: 752018,
    StateName: "ODISHA",
  },
  {
    Pincode: 441915,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441912,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 791125,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 752019,
    StateName: "ODISHA",
  },
  {
    Pincode: 752057,
    StateName: "ODISHA",
  },
  {
    Pincode: 752056,
    StateName: "ODISHA",
  },
  {
    Pincode: 752064,
    StateName: "ODISHA",
  },
  {
    Pincode: 245301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 441404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 624004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144216,
    StateName: "PUNJAB",
  },
  {
    Pincode: 604301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 232330,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 140127,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140117,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140119,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145026,
    StateName: "PUNJAB",
  },
  {
    Pincode: 604154,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 212502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 425409,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425413,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425423,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425426,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425421,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431541,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431516,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431511,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431536,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431542,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 145029,
    StateName: "PUNJAB",
  },
  {
    Pincode: 767060,
    StateName: "ODISHA",
  },
  {
    Pincode: 306001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 761104,
    StateName: "ODISHA",
  },
  {
    Pincode: 305623,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305204,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305925,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305622,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305625,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305927,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305202,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 637406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637407,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637408,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637412,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 207123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207243,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207244,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 416211,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764011,
    StateName: "ODISHA",
  },
  {
    Pincode: 142045,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142055,
    StateName: "PUNJAB",
  },
  {
    Pincode: 224229,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224234,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 441102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 761101,
    StateName: "ODISHA",
  },
  {
    Pincode: 761102,
    StateName: "ODISHA",
  },
  {
    Pincode: 761144,
    StateName: "ODISHA",
  },
  {
    Pincode: 761114,
    StateName: "ODISHA",
  },
  {
    Pincode: 444303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 603106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 207245,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636453,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641658,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641671,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416236,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144306,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144210,
    StateName: "PUNJAB",
  },
  {
    Pincode: 224182,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224284,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 610105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629158,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629160,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628716,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332722,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332707,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333514,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 202121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 233221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 333503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333035,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333515,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 416505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416229,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416509,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 629161,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629163,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629164,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629165,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629166,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629168,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629169,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629174,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629252,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629159,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629180,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629153,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 431510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431540,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425422,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425424,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425411,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425410,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 632511,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632512,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632516,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632506,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629157,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629193,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629167,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629170,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305415,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305406,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305405,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305627,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305924,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305412,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305408,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 143529,
    StateName: "PUNJAB",
  },
  {
    Pincode: 341510,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 632507,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632508,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632518,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632517,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231312,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 631151,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 413507,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413623,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415023,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 624003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624620,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624622,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624709,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 201010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 152116,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152124,
    StateName: "PUNJAB",
  },
  {
    Pincode: 401301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 233229,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 630303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630309,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 140306,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140901,
    StateName: "PUNJAB",
  },
  {
    Pincode: 233231,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233300,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232336,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232326,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 631551,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641655,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641687,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605111,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605110,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 609603,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 609607,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 611104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622507,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341512,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 140114,
    StateName: "PUNJAB",
  },
  {
    Pincode: 632509,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 142057,
    StateName: "PUNJAB",
  },
  {
    Pincode: 332706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332721,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 608002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305922,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 403101,
    StateName: "GOA",
  },
  {
    Pincode: 403504,
    StateName: "GOA",
  },
  {
    Pincode: 403516,
    StateName: "GOA",
  },
  {
    Pincode: 415713,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 403523,
    StateName: "GOA",
  },
  {
    Pincode: 623502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 623402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632515,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632318,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632521,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632513,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 413606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413528,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413505,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 608301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 413509,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413534,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 140101,
    StateName: "PUNJAB",
  },
  {
    Pincode: 312205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 312025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332746,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333516,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142042,
    StateName: "PUNJAB",
  },
  {
    Pincode: 401602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 603301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603309,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603310,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 403515,
    StateName: "GOA",
  },
  {
    Pincode: 623512,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271831,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 606701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 401102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 152022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152121,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152128,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142044,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152021,
    StateName: "PUNJAB",
  },
  {
    Pincode: 335024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335707,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335711,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305814,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305816,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305815,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 624002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624711,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 285001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202280,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233222,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 232340,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 624403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 400607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401503,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444126,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 311408,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311605,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 643004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626149,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 441101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 142058,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142043,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441913,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 141108,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141109,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142033,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142025,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141121,
    StateName: "PUNJAB",
  },
  {
    Pincode: 313207,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313321,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313334,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341513,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 332716,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341514,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 421311,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416552,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416119,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 274405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274509,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 335801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335513,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335523,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 333029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 638103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636030,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636454,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141421,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141206,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141116,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141414,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141112,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141119,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141416,
    StateName: "PUNJAB",
  },
  {
    Pincode: 626189,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626128,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626130,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626140,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 626005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637410,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638182,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 285205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 312626,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 413624,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 643217,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643214,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643219,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637409,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 425501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 333036,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335524,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335511,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 231313,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231225,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 335503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 401401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401504,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 152117,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152123,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152020,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 142052,
    StateName: "PUNJAB",
  },
  {
    Pincode: 401208,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 604206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 444301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 443403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 765019,
    StateName: "ODISHA",
  },
  {
    Pincode: 605501,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 641603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 232327,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 631553,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631561,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 441103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 285126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 603319,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 152024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 413526,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 341030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 765033,
    StateName: "ODISHA",
  },
  {
    Pincode: 626204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 142023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 609608,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629173,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629177,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609609,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 609604,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 143602,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143514,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143506,
    StateName: "PUNJAB",
  },
  {
    Pincode: 752060,
    StateName: "ODISHA",
  },
  {
    Pincode: 638457,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638458,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638506,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764036,
    StateName: "ODISHA",
  },
  {
    Pincode: 764014,
    StateName: "ODISHA",
  },
  {
    Pincode: 764021,
    StateName: "ODISHA",
  },
  {
    Pincode: 764038,
    StateName: "ODISHA",
  },
  {
    Pincode: 764039,
    StateName: "ODISHA",
  },
  {
    Pincode: 442305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 323602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323803,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 323801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 752022,
    StateName: "ODISHA",
  },
  {
    Pincode: 231314,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 608302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143513,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143518,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143512,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143516,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143515,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143517,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143526,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143527,
    StateName: "PUNJAB",
  },
  {
    Pincode: 313205,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 261501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262901,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243631,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243632,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243633,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 335065,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 411045,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764063,
    StateName: "ODISHA",
  },
  {
    Pincode: 764059,
    StateName: "ODISHA",
  },
  {
    Pincode: 764071,
    StateName: "ODISHA",
  },
  {
    Pincode: 764074,
    StateName: "ODISHA",
  },
  {
    Pincode: 764070,
    StateName: "ODISHA",
  },
  {
    Pincode: 764073,
    StateName: "ODISHA",
  },
  {
    Pincode: 143528,
    StateName: "PUNJAB",
  },
  {
    Pincode: 622301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614617,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754134,
    StateName: "ODISHA",
  },
  {
    Pincode: 613701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 145022,
    StateName: "PUNJAB",
  },
  {
    Pincode: 342902,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341518,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 142028,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141118,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141419,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141204,
    StateName: "PUNJAB",
  },
  {
    Pincode: 303315,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 752108,
    StateName: "ODISHA",
  },
  {
    Pincode: 611110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 304507,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 141417,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141123,
    StateName: "PUNJAB",
  },
  {
    Pincode: 606209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141202,
    StateName: "PUNJAB",
  },
  {
    Pincode: 627412,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 765026,
    StateName: "ODISHA",
  },
  {
    Pincode: 765017,
    StateName: "ODISHA",
  },
  {
    Pincode: 443303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444312,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 335037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335038,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335039,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335040,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 401107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401106,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 624616,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624712,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624618,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 614703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614711,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614715,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 142041,
    StateName: "PUNJAB",
  },
  {
    Pincode: 614613,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614628,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614614,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614905,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614716,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 444201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444306,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 765021,
    StateName: "ODISHA",
  },
  {
    Pincode: 764062,
    StateName: "ODISHA",
  },
  {
    Pincode: 765022,
    StateName: "ODISHA",
  },
  {
    Pincode: 765016,
    StateName: "ODISHA",
  },
  {
    Pincode: 765015,
    StateName: "ODISHA",
  },
  {
    Pincode: 630306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 142002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 274808,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274807,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641032,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243720,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243635,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614714,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614712,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 759020,
    StateName: "ODISHA",
  },
  {
    Pincode: 759015,
    StateName: "ODISHA",
  },
  {
    Pincode: 759017,
    StateName: "ODISHA",
  },
  {
    Pincode: 759013,
    StateName: "ODISHA",
  },
  {
    Pincode: 759025,
    StateName: "ODISHA",
  },
  {
    Pincode: 759016,
    StateName: "ODISHA",
  },
  {
    Pincode: 759022,
    StateName: "ODISHA",
  },
  {
    Pincode: 759018,
    StateName: "ODISHA",
  },
  {
    Pincode: 632006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632059,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643216,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 444002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 313701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313802,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 313801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 643236,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141415,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141125,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141113,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141205,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141115,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141203,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141418,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141120,
    StateName: "PUNJAB",
  },
  {
    Pincode: 612301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754142,
    StateName: "ODISHA",
  },
  {
    Pincode: 605751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605766,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605759,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754140,
    StateName: "ODISHA",
  },
  {
    Pincode: 754138,
    StateName: "ODISHA",
  },
  {
    Pincode: 754160,
    StateName: "ODISHA",
  },
  {
    Pincode: 759028,
    StateName: "ODISHA",
  },
  {
    Pincode: 759014,
    StateName: "ODISHA",
  },
  {
    Pincode: 759023,
    StateName: "ODISHA",
  },
  {
    Pincode: 759024,
    StateName: "ODISHA",
  },
  {
    Pincode: 759039,
    StateName: "ODISHA",
  },
  {
    Pincode: 607107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627413,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627415,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209214,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 603109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 285201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 414303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764037,
    StateName: "ODISHA",
  },
  {
    Pincode: 759027,
    StateName: "ODISHA",
  },
  {
    Pincode: 759019,
    StateName: "ODISHA",
  },
  {
    Pincode: 759121,
    StateName: "ODISHA",
  },
  {
    Pincode: 759029,
    StateName: "ODISHA",
  },
  {
    Pincode: 759120,
    StateName: "ODISHA",
  },
  {
    Pincode: 759021,
    StateName: "ODISHA",
  },
  {
    Pincode: 754132,
    StateName: "ODISHA",
  },
  {
    Pincode: 627602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627425,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627416,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627421,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627427,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625531,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625534,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631605,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631552,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631606,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764003,
    StateName: "ODISHA",
  },
  {
    Pincode: 764027,
    StateName: "ODISHA",
  },
  {
    Pincode: 414701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764086,
    StateName: "ODISHA",
  },
  {
    Pincode: 243634,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 160047,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 243723,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243636,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243724,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243638,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 326001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326515,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326514,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326516,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 326039,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 243751,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 314404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627453,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627423,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627424,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605754,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754137,
    StateName: "ODISHA",
  },
  {
    Pincode: 605752,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605755,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627417,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627426,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627351,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627657,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627352,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627359,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627357,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627358,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627651,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335051,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625529,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625532,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625537,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143606,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 614612,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609605,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 609606,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 610003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614616,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614630,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305629,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 614707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 144507,
    StateName: "PUNJAB",
  },
  {
    Pincode: 335703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332708,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335061,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335062,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625536,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625605,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625562,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 143504,
    StateName: "PUNJAB",
  },
  {
    Pincode: 609115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609601,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 609602,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 614624,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614619,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622515,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614621,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628653,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628616,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628619,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 152113,
    StateName: "PUNJAB",
  },
  {
    Pincode: 625535,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625527,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 152107,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152026,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151207,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152032,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152114,
    StateName: "PUNJAB",
  },
  {
    Pincode: 628703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 332710,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 285204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335073,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335804,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 401304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 210341,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 629703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629162,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305201,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 243123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 622505,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243722,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243727,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643221,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632058,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 210423,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210424,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210425,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210427,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 608502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 151212,
    StateName: "PUNJAB",
  },
  {
    Pincode: 630107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 307022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307514,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307510,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335805,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 630312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 285128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 629809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629154,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629175,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641035,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641028,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641048,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641023,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641062,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641031,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 764055,
    StateName: "ODISHA",
  },
  {
    Pincode: 641007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231212,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 414601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 307024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 765023,
    StateName: "ODISHA",
  },
  {
    Pincode: 222135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 632114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 303003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303805,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303105,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303106,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303107,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303110,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 632502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632505,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629171,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629172,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 611102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 306306,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 622101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609309,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632520,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632510,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631051,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631052,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632514,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203398,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203397,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203392,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203393,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262903,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262725,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 609201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305628,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 305407,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 311602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 630101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630554,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630709,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630609,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 307023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 307027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 609205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203396,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203399,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203390,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203408,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203394,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614810,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335512,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144029,
    StateName: "PUNJAB",
  },
  {
    Pincode: 222002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222138,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 637019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637411,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605651,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 151209,
    StateName: "PUNJAB",
  },
  {
    Pincode: 630001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628612,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628613,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 303104,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 250611,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250606,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250620,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250345,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250617,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 638054,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638055,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203391,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641029,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641047,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641046,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641030,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203411,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 631003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632531,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605756,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271821,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630559,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 250615,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250622,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250625,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 303511,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303304,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303323,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 322240,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303325,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321609,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321608,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 603003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 303504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 764042,
    StateName: "ODISHA",
  },
  {
    Pincode: 636813,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 228171,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203389,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 629176,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 629178,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621220,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 151211,
    StateName: "PUNJAB",
  },
  {
    Pincode: 152112,
    StateName: "PUNJAB",
  },
  {
    Pincode: 608304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600053,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600120,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600052,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600067,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 222161,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222165,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222170,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222109,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212213,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 609503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614629,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614622,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614618,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 210428,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210506,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210429,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210422,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 622303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622411,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621316,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 306105,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306709,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306422,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 639136,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621307,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 222141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 627414,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627418,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754133,
    StateName: "ODISHA",
  },
  {
    Pincode: 621308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243637,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262902,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262904,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262907,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262805,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262906,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242021,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212651,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212654,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212655,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212657,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212656,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212658,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250609,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250621,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 250623,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 607112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 311801,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 621310,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621653,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621651,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621706,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608901,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621707,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612902,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 203409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203150,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 203395,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210426,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210507,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621709,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612903,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621711,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621712,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621718,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621714,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612904,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621851,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621715,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621218,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621722,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 311021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 851101,
    StateName: "BIHAR",
  },
  {
    Pincode: 781037,
    StateName: "ASSAM",
  },
  {
    Pincode: 781008,
    StateName: "ASSAM",
  },
  {
    Pincode: 781007,
    StateName: "ASSAM",
  },
  {
    Pincode: 208021,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 607106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 212659,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212665,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 784010,
    StateName: "ASSAM",
  },
  {
    Pincode: 784001,
    StateName: "ASSAM",
  },
  {
    Pincode: 222131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222175,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222149,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605758,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 142034,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141105,
    StateName: "PUNJAB",
  },
  {
    Pincode: 639107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632519,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 784028,
    StateName: "ASSAM",
  },
  {
    Pincode: 751024,
    StateName: "ODISHA",
  },
  {
    Pincode: 624617,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141413,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141122,
    StateName: "PUNJAB",
  },
  {
    Pincode: 305403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 243407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 628702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628617,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628620,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 608801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246726,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246728,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244715,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244713,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263148,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263153,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262405,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263145,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 212207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212214,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212216,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212217,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212108,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 607102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 311805,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 641026,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641022,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641025,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621730,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 262726,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262905,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 622403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341542,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 143532,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143519,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143531,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143533,
    StateName: "PUNJAB",
  },
  {
    Pincode: 621713,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622407,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622409,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622422,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622412,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246149,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244414,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 328022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 244402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244901,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244921,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244923,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244701,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244927,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244922,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 603004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 284126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 622204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229411,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211019,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229413,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212507,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211013,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221507,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 202143,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202165,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 605105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 602106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 305812,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 741122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 605502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605652,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 233232,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232339,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233224,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233225,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233233,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233226,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233227,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232328,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 233230,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246731,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246729,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222180,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222181,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742163,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742235,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742213,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 781301,
    StateName: "ASSAM",
  },
  {
    Pincode: 244924,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244415,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244602,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222137,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600055,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600057,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 209101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209112,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212218,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231226,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 601202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 601204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 263126,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244716,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 209305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208017,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209217,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 781133,
    StateName: "ASSAM",
  },
  {
    Pincode: 781039,
    StateName: "ASSAM",
  },
  {
    Pincode: 263001,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263136,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263137,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263132,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263159,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 614402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 781131,
    StateName: "ASSAM",
  },
  {
    Pincode: 628614,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628618,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 742184,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 628656,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341511,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 145027,
    StateName: "PUNJAB",
  },
  {
    Pincode: 145024,
    StateName: "PUNJAB",
  },
  {
    Pincode: 742224,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742226,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 209209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212661,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212664,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 321401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321410,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321411,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 627006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 229001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 846008,
    StateName: "BIHAR",
  },
  {
    Pincode: 609310,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 765034,
    StateName: "ODISHA",
  },
  {
    Pincode: 742404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742212,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 612802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 244303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 851114,
    StateName: "BIHAR",
  },
  {
    Pincode: 851135,
    StateName: "BIHAR",
  },
  {
    Pincode: 207250,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 207246,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246121,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249306,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246127,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246128,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246144,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 622001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614620,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 622506,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 275301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221705,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 639101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621306,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246173,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246275,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246276,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246277,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 607006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206241,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206252,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600099,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246279,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 328041,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328029,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 328027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 600049,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600060,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 263134,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244924,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 321404,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321402,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 321408,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 742227,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 243122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 141103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 621652,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 275105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 306602,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306501,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306502,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306706,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306705,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306603,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306504,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306103,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306503,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306101,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306308,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306901,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625515,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625516,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625519,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625524,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625525,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625526,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625582,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 788803,
    StateName: "ASSAM",
  },
  {
    Pincode: 788727,
    StateName: "ASSAM",
  },
  {
    Pincode: 742237,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 305819,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 741161,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 643218,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 788001,
    StateName: "ASSAM",
  },
  {
    Pincode: 788118,
    StateName: "ASSAM",
  },
  {
    Pincode: 742113,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 612101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 212404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221508,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212109,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621315,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 620021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 205001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742166,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742187,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742308,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742175,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742306,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 607403,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605107,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605502,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 204215,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742174,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 605105,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 283112,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283115,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206251,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206243,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206244,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206246,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206249,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206248,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600058,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206255,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206247,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621217,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621214,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 742303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 241001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643238,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643231,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643240,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632316,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632311,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632314,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243725,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 639205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 637017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600048,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604405,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632315,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632317,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604407,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604409,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206250,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263135,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263138,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263157,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 281301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 342606,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 224125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230143,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230144,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 249304,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246155,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 342022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 212620,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212622,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212663,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212650,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212641,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212653,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212621,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246725,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614404,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 342024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342028,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342306,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 612605,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 781171,
    StateName: "ASSAM",
  },
  {
    Pincode: 781034,
    StateName: "ASSAM",
  },
  {
    Pincode: 781040,
    StateName: "ASSAM",
  },
  {
    Pincode: 781003,
    StateName: "ASSAM",
  },
  {
    Pincode: 781001,
    StateName: "ASSAM",
  },
  {
    Pincode: 781002,
    StateName: "ASSAM",
  },
  {
    Pincode: 781013,
    StateName: "ASSAM",
  },
  {
    Pincode: 209304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243506,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262310,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 284121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 788103,
    StateName: "ASSAM",
  },
  {
    Pincode: 788014,
    StateName: "ASSAM",
  },
  {
    Pincode: 246129,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246131,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 209302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209111,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209115,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 639111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 210125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 612501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612502,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 209125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 784166,
    StateName: "ASSAM",
  },
  {
    Pincode: 303327,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 625530,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625533,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625512,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625517,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625579,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 335063,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335526,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 335064,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 713152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 612610,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 328026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 284135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 851115,
    StateName: "BIHAR",
  },
  {
    Pincode: 600054,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600095,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600123,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600124,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600077,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600130,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600132,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606903,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 262308,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 722152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 243402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 272163,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 341303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341551,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 341302,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 609301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 272165,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272164,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271840,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614717,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614710,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614705,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 604403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 232110,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232109,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232108,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221115,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 632312,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 341301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 713520,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713406,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 621114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 284401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 284203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 609801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 610107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 224159,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243630,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243639,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243726,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243641,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 342015,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 262908,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225416,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 335023,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 621118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 225124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 639004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632326,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 612803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 281308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244923,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 243203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229212,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229211,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 272150,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 342013,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 225405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225119,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 306114,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306701,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306126,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 784026,
    StateName: "ASSAM",
  },
  {
    Pincode: 306115,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306707,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306301,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 612703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 306703,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 306116,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 212635,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 613702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 224129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224137,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224139,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 281004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227816,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 229215,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 781035,
    StateName: "ASSAM",
  },
  {
    Pincode: 781006,
    StateName: "ASSAM",
  },
  {
    Pincode: 781036,
    StateName: "ASSAM",
  },
  {
    Pincode: 781032,
    StateName: "ASSAM",
  },
  {
    Pincode: 241404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 302027,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302031,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 263642,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263641,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 620012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246124,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246193,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246162,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246179,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246172,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209870,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209871,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 713128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713407,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713408,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713141,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713428,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 600125,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632319,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600128,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 731124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731214,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 224143,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224146,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224147,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224149,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224230,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224157,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 625518,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625520,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625521,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625540,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625528,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620025,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 639112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 209860,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 788109,
    StateName: "ASSAM",
  },
  {
    Pincode: 731126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110029,
    StateName: "DELHI",
  },
  {
    Pincode: 713124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713403,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263601,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262722,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210431,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 612106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 224155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110022,
    StateName: "DELHI",
  },
  {
    Pincode: 722153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263127,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263158,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 229010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224231,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224152,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224168,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224176,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 620007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 306704,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 604504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 713131,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 625203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625604,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 742168,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 244104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 736146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736169,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246278,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246001,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 625523,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 242306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262546,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 735214,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 609811,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 731303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 614102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 736201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735217,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246161,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 271208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271604,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743318,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743503,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272176,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229216,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110066,
    StateName: "DELHI",
  },
  {
    Pincode: 246701,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 609303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 609402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 276128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223222,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223224,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227411,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227811,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227413,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228161,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209621,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209622,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209720,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209602,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209721,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209625,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263624,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263622,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743329,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743330,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743372,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743399,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263128,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 273212,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271865,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271871,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271875,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271881,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271830,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263679,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263635,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263634,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246169,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246177,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244231,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244235,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244241,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244222,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244251,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244236,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244255,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743504,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743513,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743336,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 244242,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246123,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249301,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246146,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246147,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246159,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246163,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246167,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246174,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246285,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 700137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 227812,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228172,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227815,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 311022,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 743378,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 242001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 242042,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227808,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 632504,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 283203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283152,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 285129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742159,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742161,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262522,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 742103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262311,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 251305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 243301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246736,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246764,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246721,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246732,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246763,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246749,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246761,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227809,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227813,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227807,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228159,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263655,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721501,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721503,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721504,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721505,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721506,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 210123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204211,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202150,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204214,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204216,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 390006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390017,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390025,
    StateName: "GUJARAT",
  },
  {
    Pincode: 209203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 395003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 736182,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246722,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246727,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246762,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246733,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271870,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271806,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271902,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 396171,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 274306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274802,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274806,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274602,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274701,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274702,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274603,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643242,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600056,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600127,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246734,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246745,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246747,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271872,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271904,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271903,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274506,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274703,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244225,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 133301,
    StateName: "HARYANA",
  },
  {
    Pincode: 134108,
    StateName: "HARYANA",
  },
  {
    Pincode: 134101,
    StateName: "HARYANA",
  },
  {
    Pincode: 274508,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271802,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271851,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271824,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271882,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271855,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272161,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 222303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228142,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209722,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209723,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209739,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209733,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209724,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209736,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210432,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210433,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263629,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262728,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600069,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600071,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600072,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600062,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600126,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246735,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246746,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721157,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721131,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721155,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721150,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 225204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 228119,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274704,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274182,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274705,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 285127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271803,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271835,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271845,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271804,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271841,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 394111,
    StateName: "GUJARAT",
  },
  {
    Pincode: 621202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621216,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271603,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271607,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 134116,
    StateName: "HARYANA",
  },
  {
    Pincode: 134107,
    StateName: "HARYANA",
  },
  {
    Pincode: 134103,
    StateName: "HARYANA",
  },
  {
    Pincode: 134113,
    StateName: "HARYANA",
  },
  {
    Pincode: 621209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621215,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621206,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271609,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202133,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271901,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 242220,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202281,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202282,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202137,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202138,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202140,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271602,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262542,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 202139,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 204213,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202155,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283119,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262545,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262551,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262532,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244410,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 134104,
    StateName: "HARYANA",
  },
  {
    Pincode: 134112,
    StateName: "HARYANA",
  },
  {
    Pincode: 244413,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271861,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271215,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 205268,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205247,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205261,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205262,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205263,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205264,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274604,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231218,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231216,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 275101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262523,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 276127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 391345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390020,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391776,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390024,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 244105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244411,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 134003,
    StateName: "HARYANA",
  },
  {
    Pincode: 133001,
    StateName: "HARYANA",
  },
  {
    Pincode: 743370,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 786125,
    StateName: "ASSAM",
  },
  {
    Pincode: 786155,
    StateName: "ASSAM",
  },
  {
    Pincode: 209505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223227,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276139,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263630,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 390016,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390023,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390021,
    StateName: "GUJARAT",
  },
  {
    Pincode: 722122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722157,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262580,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 202122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 202170,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 390018,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390011,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394101,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395011,
    StateName: "GUJARAT",
  },
  {
    Pincode: 133010,
    StateName: "HARYANA",
  },
  {
    Pincode: 133207,
    StateName: "HARYANA",
  },
  {
    Pincode: 244504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 394541,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 277208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277211,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 335525,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 722132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722162,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 247771,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277213,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221712,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221715,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221701,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221709,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221718,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221716,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221711,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262502,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 722154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 277502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277506,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 781021,
    StateName: "ASSAM",
  },
  {
    Pincode: 781016,
    StateName: "ASSAM",
  },
  {
    Pincode: 781004,
    StateName: "ASSAM",
  },
  {
    Pincode: 781010,
    StateName: "ASSAM",
  },
  {
    Pincode: 781022,
    StateName: "ASSAM",
  },
  {
    Pincode: 781020,
    StateName: "ASSAM",
  },
  {
    Pincode: 396001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396180,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396115,
    StateName: "GUJARAT",
  },
  {
    Pincode: 221713,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743337,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 283125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743349,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743610,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 785001,
    StateName: "ASSAM",
  },
  {
    Pincode: 700141,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743354,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743338,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743611,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743387,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743383,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700150,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743395,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743376,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 786623,
    StateName: "ASSAM",
  },
  {
    Pincode: 786153,
    StateName: "ASSAM",
  },
  {
    Pincode: 743502,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 244925,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700110,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700083,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700059,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731243,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721516,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262701,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 134005,
    StateName: "HARYANA",
  },
  {
    Pincode: 133006,
    StateName: "HARYANA",
  },
  {
    Pincode: 246130,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 621112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 846001,
    StateName: "BIHAR",
  },
  {
    Pincode: 225409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110064,
    StateName: "DELHI",
  },
  {
    Pincode: 700103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 614211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 743165,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731240,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 225206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225412,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225415,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246194,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246113,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246148,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246175,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246164,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246165,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 735215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736172,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736179,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 276137,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 431601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 682011,
    StateName: "KERALA",
  },
  {
    Pincode: 742121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742189,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742165,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742406,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262501,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 670101,
    StateName: "KERALA",
  },
  {
    Pincode: 621107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621716,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621717,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 261145,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621109,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 261204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 743377,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743363,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743312,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673001,
    StateName: "KERALA",
  },
  {
    Pincode: 673305,
    StateName: "KERALA",
  },
  {
    Pincode: 676505,
    StateName: "KERALA",
  },
  {
    Pincode: 679577,
    StateName: "KERALA",
  },
  {
    Pincode: 700151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 231306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 713148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 271805,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721632,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721631,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721633,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721629,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 685584,
    StateName: "KERALA",
  },
  {
    Pincode: 246166,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 225120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225413,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228118,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 691523,
    StateName: "KERALA",
  },
  {
    Pincode: 689101,
    StateName: "KERALA",
  },
  {
    Pincode: 691305,
    StateName: "KERALA",
  },
  {
    Pincode: 689645,
    StateName: "KERALA",
  },
  {
    Pincode: 621708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 731301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 205265,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 690502,
    StateName: "KERALA",
  },
  {
    Pincode: 682555,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 721159,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 273007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273162,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273164,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273165,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273161,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 621219,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 261135,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 742134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713166,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 737128,
    StateName: "SIKKIM",
  },
  {
    Pincode: 700104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743609,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743332,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743347,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743348,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743351,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743355,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743356,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743357,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743374,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 243403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 679101,
    StateName: "KERALA",
  },
  {
    Pincode: 700157,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272178,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731218,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731234,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731236,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 711322,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711314,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711226,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711316,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711315,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711227,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263139,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 756001,
    StateName: "ODISHA",
  },
  {
    Pincode: 756032,
    StateName: "ODISHA",
  },
  {
    Pincode: 263140,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 743221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743232,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743235,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743233,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743234,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743245,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 680307,
    StateName: "KERALA",
  },
  {
    Pincode: 731304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 627853,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627854,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627809,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627859,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 134114,
    StateName: "HARYANA",
  },
  {
    Pincode: 134117,
    StateName: "HARYANA",
  },
  {
    Pincode: 134105,
    StateName: "HARYANA",
  },
  {
    Pincode: 400014,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 249130,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249125,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249181,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249185,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249199,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249192,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249186,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262401,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263152,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 244712,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 743247,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 630610,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630611,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630566,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630552,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630553,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630557,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630313,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630562,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 273152,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273163,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 756100,
    StateName: "ODISHA",
  },
  {
    Pincode: 273301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 737120,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737111,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737116,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737107,
    StateName: "SIKKIM",
  },
  {
    Pincode: 713142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713426,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743371,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743373,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743368,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272181,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272148,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272151,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272190,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 770001,
    StateName: "ODISHA",
  },
  {
    Pincode: 229202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 713324,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713325,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713334,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 261405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261141,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 416003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 721133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 206125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272270,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743424,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743423,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743249,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743251,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 231217,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231221,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 711401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246723,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246724,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 458001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 424001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 261403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 225414,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 402201,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 690518,
    StateName: "KERALA",
  },
  {
    Pincode: 691506,
    StateName: "KERALA",
  },
  {
    Pincode: 691001,
    StateName: "KERALA",
  },
  {
    Pincode: 277503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500073,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500109,
    StateName: "TELANGANA",
  },
  {
    Pincode: 142001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 249180,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249193,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 754211,
    StateName: "ODISHA",
  },
  {
    Pincode: 276122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 390013,
    StateName: "GUJARAT",
  },
  {
    Pincode: 249121,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249126,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249135,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247670,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249404,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247668,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247663,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 440002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 414001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 713335,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713336,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713371,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713359,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713373,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713212,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713211,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400071,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680503,
    StateName: "KERALA",
  },
  {
    Pincode: 680582,
    StateName: "KERALA",
  },
  {
    Pincode: 721125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713321,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713420,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713421,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 752055,
    StateName: "ODISHA",
  },
  {
    Pincode: 395002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 442401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 212645,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 212652,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 411002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 731132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272182,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272189,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272191,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272192,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500012,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500066,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500058,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500059,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500044,
    StateName: "TELANGANA",
  },
  {
    Pincode: 695101,
    StateName: "KERALA",
  },
  {
    Pincode: 695001,
    StateName: "KERALA",
  },
  {
    Pincode: 713422,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713423,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 142026,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141401,
    StateName: "PUNJAB",
  },
  {
    Pincode: 247664,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247656,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 732205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732209,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 249131,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249127,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249141,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 627861,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 768028,
    StateName: "ODISHA",
  },
  {
    Pincode: 625501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625514,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625205,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625221,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625234,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625703,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 782001,
    StateName: "ASSAM",
  },
  {
    Pincode: 737134,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737102,
    StateName: "SIKKIM",
  },
  {
    Pincode: 500086,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500077,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500060,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500013,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500076,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500102,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500074,
    StateName: "TELANGANA",
  },
  {
    Pincode: 713427,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721452,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 271306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 712410,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 560001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 205304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205267,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 205119,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 431203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 152001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 271312,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271503,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 271122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 846006,
    StateName: "BIHAR",
  },
  {
    Pincode: 759001,
    StateName: "ODISHA",
  },
  {
    Pincode: 210207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 444805,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 392001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 210301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231222,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110016,
    StateName: "DELHI",
  },
  {
    Pincode: 560004,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 263160,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 500029,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500063,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500095,
    StateName: "TELANGANA",
  },
  {
    Pincode: 281201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500114,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500111,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500091,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500104,
    StateName: "TELANGANA",
  },
  {
    Pincode: 797001,
    StateName: "NAGALAND",
  },
  {
    Pincode: 273304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273014,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209312,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 395001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 227805,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227814,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227806,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228133,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 753002,
    StateName: "ODISHA",
  },
  {
    Pincode: 757043,
    StateName: "ODISHA",
  },
  {
    Pincode: 757001,
    StateName: "ODISHA",
  },
  {
    Pincode: 582101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 141001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 627804,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627805,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627751,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627814,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627812,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625022,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625702,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 712122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712616,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712615,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712611,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712602,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712614,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712613,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712412,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712612,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712406,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712413,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712416,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711114,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 225123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210421,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 210430,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 712417,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712501,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 403001,
    StateName: "GOA",
  },
  {
    Pincode: 401404,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 625016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 732206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 247665,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249408,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249405,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 625018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625218,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625704,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 132103,
    StateName: "HARYANA",
  },
  {
    Pincode: 249132,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249171,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 754103,
    StateName: "ODISHA",
  },
  {
    Pincode: 272203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 678541,
    StateName: "KERALA",
  },
  {
    Pincode: 733127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110052,
    StateName: "DELHI",
  },
  {
    Pincode: 247666,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247671,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 122001,
    StateName: "HARYANA",
  },
  {
    Pincode: 712414,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262529,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 673121,
    StateName: "KERALA",
  },
  {
    Pincode: 743613,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721438,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721447,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721448,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721439,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712415,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713333,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 520008,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520013,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521137,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 713339,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713341,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713315,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 682001,
    StateName: "KERALA",
  },
  {
    Pincode: 676121,
    StateName: "KERALA",
  },
  {
    Pincode: 676101,
    StateName: "KERALA",
  },
  {
    Pincode: 712617,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 210501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 415001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 210126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 560013,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 712409,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400053,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 735135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735224,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 151001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 753001,
    StateName: "ODISHA",
  },
  {
    Pincode: 143505,
    StateName: "PUNJAB",
  },
  {
    Pincode: 576104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 576201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 732216,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721641,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721654,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721628,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721649,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262540,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721440,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721441,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721446,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262525,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262527,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 786173,
    StateName: "ASSAM",
  },
  {
    Pincode: 400091,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 110021,
    StateName: "DELHI",
  },
  {
    Pincode: 110057,
    StateName: "DELHI",
  },
  {
    Pincode: 110028,
    StateName: "DELHI",
  },
  {
    Pincode: 400002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400016,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 362001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 125001,
    StateName: "HARYANA",
  },
  {
    Pincode: 743222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 363001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 135001,
    StateName: "HARYANA",
  },
  {
    Pincode: 732207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 625019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 575005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 627802,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 743702,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743294,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700113,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700119,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700105,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 627803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627813,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627856,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 743135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700112,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700159,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110046,
    StateName: "DELHI",
  },
  {
    Pincode: 711404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711411,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711412,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711309,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711310,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711413,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711408,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711312,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711410,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 572101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 261208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743345,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673020,
    StateName: "KERALA",
  },
  {
    Pincode: 673101,
    StateName: "KERALA",
  },
  {
    Pincode: 733124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262534,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262530,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262543,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262576,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262555,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262531,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721453,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721427,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721428,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721429,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721430,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 462001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 712123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 462003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 700149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735216,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 686001,
    StateName: "KERALA",
  },
  {
    Pincode: 686575,
    StateName: "KERALA",
  },
  {
    Pincode: 686141,
    StateName: "KERALA",
  },
  {
    Pincode: 721426,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721467,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721457,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721443,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 261207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 800004,
    StateName: "BIHAR",
  },
  {
    Pincode: 416115,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 751001,
    StateName: "ODISHA",
  },
  {
    Pincode: 416012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 143401,
    StateName: "PUNJAB",
  },
  {
    Pincode: 520001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 520011,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 500048,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500069,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500097,
    StateName: "TELANGANA",
  },
  {
    Pincode: 695121,
    StateName: "KERALA",
  },
  {
    Pincode: 500108,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500068,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500051,
    StateName: "TELANGANA",
  },
  {
    Pincode: 785013,
    StateName: "ASSAM",
  },
  {
    Pincode: 713330,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713360,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 678002,
    StateName: "KERALA",
  },
  {
    Pincode: 678001,
    StateName: "KERALA",
  },
  {
    Pincode: 800001,
    StateName: "BIHAR",
  },
  {
    Pincode: 577001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768001,
    StateName: "ODISHA",
  },
  {
    Pincode: 492001,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 711301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 764001,
    StateName: "ODISHA",
  },
  {
    Pincode: 764020,
    StateName: "ODISHA",
  },
  {
    Pincode: 209801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209831,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209868,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209864,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209865,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209866,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209827,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209869,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209862,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209825,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209821,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209881,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 711403,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711307,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711414,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711409,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 209859,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110001,
    StateName: "DELHI",
  },
  {
    Pincode: 361001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 127021,
    StateName: "HARYANA",
  },
  {
    Pincode: 263653,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 110051,
    StateName: "DELHI",
  },
  {
    Pincode: 571401,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 160017,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 711313,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 206126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206245,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 206127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 522201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 140001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 586101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 767001,
    StateName: "ODISHA",
  },
  {
    Pincode: 752069,
    StateName: "ODISHA",
  },
  {
    Pincode: 143521,
    StateName: "PUNJAB",
  },
  {
    Pincode: 227304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228132,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227408,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 228131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 625708,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 732103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732141,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110007,
    StateName: "DELHI",
  },
  {
    Pincode: 110083,
    StateName: "DELHI",
  },
  {
    Pincode: 110034,
    StateName: "DELHI",
  },
  {
    Pincode: 229209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 571301,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 754029,
    StateName: "ODISHA",
  },
  {
    Pincode: 360575,
    StateName: "GUJARAT",
  },
  {
    Pincode: 228125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 227407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 759122,
    StateName: "ODISHA",
  },
  {
    Pincode: 758001,
    StateName: "ODISHA",
  },
  {
    Pincode: 380002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 580001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580020,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 246125,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 110054,
    StateName: "DELHI",
  },
  {
    Pincode: 396193,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 781029,
    StateName: "ASSAM",
  },
  {
    Pincode: 781028,
    StateName: "ASSAM",
  },
  {
    Pincode: 781009,
    StateName: "ASSAM",
  },
  {
    Pincode: 781018,
    StateName: "ASSAM",
  },
  {
    Pincode: 781038,
    StateName: "ASSAM",
  },
  {
    Pincode: 781019,
    StateName: "ASSAM",
  },
  {
    Pincode: 411001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441904,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 424101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 136038,
    StateName: "HARYANA",
  },
  {
    Pincode: 685508,
    StateName: "KERALA",
  },
  {
    Pincode: 227406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 671315,
    StateName: "KERALA",
  },
  {
    Pincode: 670001,
    StateName: "KERALA",
  },
  {
    Pincode: 500019,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500046,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500032,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500022,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500031,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500006,
    StateName: "TELANGANA",
  },
  {
    Pincode: 230405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500057,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500110,
    StateName: "TELANGANA",
  },
  {
    Pincode: 670141,
    StateName: "KERALA",
  },
  {
    Pincode: 180001,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 184101,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 560010,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 735304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 686101,
    StateName: "KERALA",
  },
  {
    Pincode: 110032,
    StateName: "DELHI",
  },
  {
    Pincode: 110031,
    StateName: "DELHI",
  },
  {
    Pincode: 110092,
    StateName: "DELHI",
  },
  {
    Pincode: 360001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 686661,
    StateName: "KERALA",
  },
  {
    Pincode: 711204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262528,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262524,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 743248,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731235,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 147001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 140401,
    StateName: "PUNJAB",
  },
  {
    Pincode: 844101,
    StateName: "BIHAR",
  },
  {
    Pincode: 390007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 391750,
    StateName: "GUJARAT",
  },
  {
    Pincode: 452007,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 794001,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 411005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 799001,
    StateName: "TRIPURA",
  },
  {
    Pincode: 793001,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 443001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 765001,
    StateName: "ODISHA",
  },
  {
    Pincode: 799120,
    StateName: "TRIPURA",
  },
  {
    Pincode: 761200,
    StateName: "ODISHA",
  },
  {
    Pincode: 761110,
    StateName: "ODISHA",
  },
  {
    Pincode: 761126,
    StateName: "ODISHA",
  },
  {
    Pincode: 766001,
    StateName: "ODISHA",
  },
  {
    Pincode: 452001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 271126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721642,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721655,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721658,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721652,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721659,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262541,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262554,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262547,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262553,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262552,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 271313,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271601,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262561,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262544,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262550,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262533,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 262521,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721458,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 533103,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533104,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 387411,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 760001,
    StateName: "ODISHA",
  },
  {
    Pincode: 761020,
    StateName: "ODISHA",
  },
  {
    Pincode: 177101,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 815301,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833201,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 444601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431401,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 762001,
    StateName: "ODISHA",
  },
  {
    Pincode: 415110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 533003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 560008,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560032,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 247662,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 768201,
    StateName: "ODISHA",
  },
  {
    Pincode: 272194,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 821115,
    StateName: "BIHAR",
  },
  {
    Pincode: 577501,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 272199,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272162,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110003,
    StateName: "DELHI",
  },
  {
    Pincode: 190001,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 735102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735233,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 413709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413512,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 835207,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 416410,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416416,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 262520,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 733142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 755001,
    StateName: "ODISHA",
  },
  {
    Pincode: 769012,
    StateName: "ODISHA",
  },
  {
    Pincode: 583231,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 505001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 769001,
    StateName: "ODISHA",
  },
  {
    Pincode: 587101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 226201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226028,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 571438,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 735220,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735228,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735218,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735219,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735225,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 686507,
    StateName: "KERALA",
  },
  {
    Pincode: 735213,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 228120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272128,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262572,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 121007,
    StateName: "HARYANA",
  },
  {
    Pincode: 121005,
    StateName: "HARYANA",
  },
  {
    Pincode: 121006,
    StateName: "HARYANA",
  },
  {
    Pincode: 121001,
    StateName: "HARYANA",
  },
  {
    Pincode: 396445,
    StateName: "GUJARAT",
  },
  {
    Pincode: 413001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 396321,
    StateName: "GUJARAT",
  },
  {
    Pincode: 176310,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 517325,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 814101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 814112,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 209205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 209210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 509001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 201314,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201312,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 121012,
    StateName: "HARYANA",
  },
  {
    Pincode: 229208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 560041,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 743286,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500092,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500035,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500053,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500065,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500023,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500020,
    StateName: "TELANGANA",
  },
  {
    Pincode: 486001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 500024,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500027,
    StateName: "TELANGANA",
  },
  {
    Pincode: 520010,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 721137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 431122,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 517506,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 394601,
    StateName: "GUJARAT",
  },
  {
    Pincode: 533215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 425001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 791111,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 456001,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 444001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441001,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 781014,
    StateName: "ASSAM",
  },
  {
    Pincode: 683542,
    StateName: "KERALA",
  },
  {
    Pincode: 121010,
    StateName: "HARYANA",
  },
  {
    Pincode: 121015,
    StateName: "HARYANA",
  },
  {
    Pincode: 723121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 380001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 577201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585401,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585201,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 584101,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 506167,
    StateName: "TELANGANA",
  },
  {
    Pincode: 274208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 823001,
    StateName: "BIHAR",
  },
  {
    Pincode: 274408,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 834001,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 735230,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 180004,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 110019,
    StateName: "DELHI",
  },
  {
    Pincode: 247340,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247231,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247662,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247554,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247669,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247341,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247551,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247342,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247452,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247451,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247232,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 723104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713332,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713343,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 143001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 380009,
    StateName: "GUJARAT",
  },
  {
    Pincode: 506101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 201206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 245304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 518501,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 822101,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 531001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 245201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 737121,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737113,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737103,
    StateName: "SIKKIM",
  },
  {
    Pincode: 122002,
    StateName: "HARYANA",
  },
  {
    Pincode: 122018,
    StateName: "HARYANA",
  },
  {
    Pincode: 122015,
    StateName: "HARYANA",
  },
  {
    Pincode: 123031,
    StateName: "HARYANA",
  },
  {
    Pincode: 274149,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731241,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700051,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110015,
    StateName: "DELHI",
  },
  {
    Pincode: 247776,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247777,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731233,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731238,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731245,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731242,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731237,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731216,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731219,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 516360,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 247453,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 732210,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110009,
    StateName: "DELHI",
  },
  {
    Pincode: 732121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 732208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711109,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 829122,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 530001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 389001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 390002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 389151,
    StateName: "GUJARAT",
  },
  {
    Pincode: 515801,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 723215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 171001,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 209867,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110002,
    StateName: "DELHI",
  },
  {
    Pincode: 735231,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734503,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 516115,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 209841,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 530016,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 110023,
    StateName: "DELHI",
  },
  {
    Pincode: 700135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700163,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722150,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743252,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743262,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743263,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743270,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743273,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 403601,
    StateName: "GOA",
  },
  {
    Pincode: 148001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 505172,
    StateName: "TELANGANA",
  },
  {
    Pincode: 272177,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743438,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743289,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743290,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700131,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743701,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743711,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743287,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743425,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 241405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721431,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110006,
    StateName: "DELHI",
  },
  {
    Pincode: 364001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 688001,
    StateName: "KERALA",
  },
  {
    Pincode: 737126,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737132,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737139,
    StateName: "SIKKIM",
  },
  {
    Pincode: 723145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713378,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713385,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713381,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713346,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713362,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713363,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721445,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721436,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721437,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 245205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700035,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700108,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700028,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700052,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700036,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263636,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263626,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 245207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 509125,
    StateName: "TELANGANA",
  },
  {
    Pincode: 271303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271504,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721451,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713323,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713358,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 241302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241123,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 241403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 737106,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737131,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737135,
    StateName: "SIKKIM",
  },
  {
    Pincode: 737133,
    StateName: "SIKKIM",
  },
  {
    Pincode: 805110,
    StateName: "BIHAR",
  },
  {
    Pincode: 521153,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 246176,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 721148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743710,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700091,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700048,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700161,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500015,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500003,
    StateName: "TELANGANA",
  },
  {
    Pincode: 209861,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500084,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500107,
    StateName: "TELANGANA",
  },
  {
    Pincode: 230127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 535001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530009,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530014,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530015,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530032,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 229408,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 532001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 827001,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826001,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 208020,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 799250,
    StateName: "TRIPURA",
  },
  {
    Pincode: 515671,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 176215,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 274801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 151203,
    StateName: "PUNJAB",
  },
  {
    Pincode: 506164,
    StateName: "TELANGANA",
  },
  {
    Pincode: 570001,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570009,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 504208,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 804408,
    StateName: "BIHAR",
  },
  {
    Pincode: 363002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 249001,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 133008,
    StateName: "HARYANA",
  },
  {
    Pincode: 721130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721636,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263625,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263643,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263623,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 700073,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700001,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700050,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263619,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263640,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263631,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263632,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263639,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263633,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 731220,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 247778,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700054,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700015,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700014,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700039,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110058,
    StateName: "DELHI",
  },
  {
    Pincode: 110026,
    StateName: "DELHI",
  },
  {
    Pincode: 110027,
    StateName: "DELHI",
  },
  {
    Pincode: 263628,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 700007,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700062,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700002,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700077,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700065,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700080,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700090,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743297,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743456,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743291,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743437,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722164,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721601,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721648,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721645,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721653,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721657,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721603,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721625,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721626,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 786005,
    StateName: "ASSAM",
  },
  {
    Pincode: 786692,
    StateName: "ASSAM",
  },
  {
    Pincode: 786006,
    StateName: "ASSAM",
  },
  {
    Pincode: 721602,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721627,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721432,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721434,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721449,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721444,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723212,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 121008,
    StateName: "HARYANA",
  },
  {
    Pincode: 721643,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110089,
    StateName: "DELHI",
  },
  {
    Pincode: 230301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 534260,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 277216,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221717,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 521121,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 277219,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 534447,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 263678,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263637,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263645,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263646,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263667,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263680,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263660,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263659,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263664,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 533002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 743412,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743422,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743445,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743426,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743439,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 121013,
    StateName: "HARYANA",
  },
  {
    Pincode: 121014,
    StateName: "HARYANA",
  },
  {
    Pincode: 533004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 517101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 226002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226021,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 723153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 271319,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 230131,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 271124,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 384012,
    StateName: "GUJARAT",
  },
  {
    Pincode: 743435,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743292,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743427,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743442,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743446,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743704,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743272,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 384320,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384160,
    StateName: "GUJARAT",
  },
  {
    Pincode: 700126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743411,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743293,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743428,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 416606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416510,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 505327,
    StateName: "TELANGANA",
  },
  {
    Pincode: 226013,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226026,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 508213,
    StateName: "TELANGANA",
  },
  {
    Pincode: 203207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 843301,
    StateName: "BIHAR",
  },
  {
    Pincode: 802101,
    StateName: "BIHAR",
  },
  {
    Pincode: 273413,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273013,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273411,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 854105,
    StateName: "BIHAR",
  },
  {
    Pincode: 812001,
    StateName: "BIHAR",
  },
  {
    Pincode: 712706,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743271,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 507001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 721656,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 273408,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273211,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 521231,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 735101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734501,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500098,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500070,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500115,
    StateName: "TELANGANA",
  },
  {
    Pincode: 201310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 847211,
    StateName: "BIHAR",
  },
  {
    Pincode: 842001,
    StateName: "BIHAR",
  },
  {
    Pincode: 508207,
    StateName: "TELANGANA",
  },
  {
    Pincode: 735222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735229,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 505468,
    StateName: "TELANGANA",
  },
  {
    Pincode: 824101,
    StateName: "BIHAR",
  },
  {
    Pincode: 229316,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743429,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 229311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229126,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229802,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 734007,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734015,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 251319,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 731244,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736171,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736170,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 229206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229129,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 533242,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532185,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 532201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 208004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 122008,
    StateName: "HARYANA",
  },
  {
    Pincode: 122010,
    StateName: "HARYANA",
  },
  {
    Pincode: 122009,
    StateName: "HARYANA",
  },
  {
    Pincode: 122011,
    StateName: "HARYANA",
  },
  {
    Pincode: 122016,
    StateName: "HARYANA",
  },
  {
    Pincode: 122007,
    StateName: "HARYANA",
  },
  {
    Pincode: 122052,
    StateName: "HARYANA",
  },
  {
    Pincode: 122012,
    StateName: "HARYANA",
  },
  {
    Pincode: 121009,
    StateName: "HARYANA",
  },
  {
    Pincode: 122005,
    StateName: "HARYANA",
  },
  {
    Pincode: 388001,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388543,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388545,
    StateName: "GUJARAT",
  },
  {
    Pincode: 721607,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 852201,
    StateName: "BIHAR",
  },
  {
    Pincode: 811307,
    StateName: "BIHAR",
  },
  {
    Pincode: 723103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 388121,
    StateName: "GUJARAT",
  },
  {
    Pincode: 516390,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 531116,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 263638,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 741223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741248,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741249,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741255,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741501,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741502,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741503,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 271307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 741509,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741402,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741238,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741505,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741506,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741507,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 364081,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 364006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 384345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 841418,
    StateName: "BIHAR",
  },
  {
    Pincode: 380015,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380054,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380051,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380013,
    StateName: "GUJARAT",
  },
  {
    Pincode: 713322,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713337,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713338,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 786102,
    StateName: "ASSAM",
  },
  {
    Pincode: 787001,
    StateName: "ASSAM",
  },
  {
    Pincode: 785621,
    StateName: "ASSAM",
  },
  {
    Pincode: 273015,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229121,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 535558,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 533255,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530026,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 700136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734105,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734217,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734216,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734226,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734209,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743166,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734312,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734224,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734014,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734423,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734429,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734427,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734214,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712408,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 127045,
    StateName: "HARYANA",
  },
  {
    Pincode: 700037,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721513,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262723,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262802,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 363643,
    StateName: "GUJARAT",
  },
  {
    Pincode: 742407,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 782460,
    StateName: "ASSAM",
  },
  {
    Pincode: 741251,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741253,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741256,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 248199,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248198,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248143,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248001,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248002,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248140,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248005,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248014,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248008,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249205,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248145,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248013,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247667,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 247661,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248121,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249204,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 261502,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262724,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262702,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226017,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 509103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 734313,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734013,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734434,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734218,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734003,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700017,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700010,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700085,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700011,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721450,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 509301,
    StateName: "TELANGANA",
  },
  {
    Pincode: 733210,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 523155,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523105,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 501101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 524101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523240,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 854301,
    StateName: "BIHAR",
  },
  {
    Pincode: 524001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 523001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 700100,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 249202,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248016,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 744201,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744202,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744302,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744303,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 736122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 507111,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503224,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503111,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 503001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507125,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504303,
    StateName: "TELANGANA",
  },
  {
    Pincode: 733202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721433,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741508,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733141,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 522503,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 262721,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 261506,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721514,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721515,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 630408,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630612,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630204,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630555,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630556,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 742402,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742409,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742408,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742410,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742225,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741163,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 201103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 802302,
    StateName: "BIHAR",
  },
  {
    Pincode: 700127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734315,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734316,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 125056,
    StateName: "HARYANA",
  },
  {
    Pincode: 721517,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 261505,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 506001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 508116,
    StateName: "TELANGANA",
  },
  {
    Pincode: 504107,
    StateName: "TELANGANA",
  },
  {
    Pincode: 570005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 506004,
    StateName: "TELANGANA",
  },
  {
    Pincode: 263621,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 530008,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530011,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530018,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530046,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 245208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 229410,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 534201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 380019,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380027,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 201005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 743375,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 786622,
    StateName: "ASSAM",
  },
  {
    Pincode: 700055,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734311,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721145,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110004,
    StateName: "DELHI",
  },
  {
    Pincode: 110069,
    StateName: "DELHI",
  },
  {
    Pincode: 110005,
    StateName: "DELHI",
  },
  {
    Pincode: 505326,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502001,
    StateName: "TELANGANA",
  },
  {
    Pincode: 570023,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570033,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 506011,
    StateName: "TELANGANA",
  },
  {
    Pincode: 734213,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734223,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700097,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700162,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731224,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 731101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 570034,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 507101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 736207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 744105,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744102,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744104,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744107,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744211,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744207,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744103,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744206,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 394190,
    StateName: "GUJARAT",
  },
  {
    Pincode: 515201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 744301,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744304,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 736131,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736168,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 507154,
    StateName: "TELANGANA",
  },
  {
    Pincode: 506350,
    StateName: "TELANGANA",
  },
  {
    Pincode: 490023,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 490020,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 736135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 123110,
    StateName: "HARYANA",
  },
  {
    Pincode: 721435,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741167,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741164,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741317,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713424,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246421,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246419,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 734008,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 637211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 796001,
    StateName: "MIZORAM",
  },
  {
    Pincode: 221301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246425,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 363035,
    StateName: "GUJARAT",
  },
  {
    Pincode: 744204,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744210,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744205,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 497555,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497446,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 226101,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226102,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226104,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 735211,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 262803,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262727,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262804,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263656,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263658,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263665,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263676,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263661,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 700030,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110012,
    StateName: "DELHI",
  },
  {
    Pincode: 110008,
    StateName: "DELHI",
  },
  {
    Pincode: 735305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 388120,
    StateName: "GUJARAT",
  },
  {
    Pincode: 522002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522403,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522601,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 221314,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221106,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 722161,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 534101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522238,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522510,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522004,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 522007,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521301,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 641001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 226301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 735121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735210,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110011,
    StateName: "DELHI",
  },
  {
    Pincode: 110055,
    StateName: "DELHI",
  },
  {
    Pincode: 735302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735212,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722173,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734006,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700009,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734004,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722141,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272154,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 630561,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 492012,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 504218,
    StateName: "TELANGANA",
  },
  {
    Pincode: 628601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 274001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 274304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 502220,
    StateName: "TELANGANA",
  },
  {
    Pincode: 534211,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 521001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 277302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 603001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 281501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246440,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246475,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248009,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249402,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246486,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246424,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 110110,
    StateName: "DELHI",
  },
  {
    Pincode: 721152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246429,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246439,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 110062,
    StateName: "DELHI",
  },
  {
    Pincode: 110025,
    StateName: "DELHI",
  },
  {
    Pincode: 110024,
    StateName: "DELHI",
  },
  {
    Pincode: 110013,
    StateName: "DELHI",
  },
  {
    Pincode: 110017,
    StateName: "DELHI",
  },
  {
    Pincode: 493881,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492010,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 272201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272193,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 272152,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 722142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 360026,
    StateName: "GUJARAT",
  },
  {
    Pincode: 360028,
    StateName: "GUJARAT",
  },
  {
    Pincode: 742236,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742238,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 122098,
    StateName: "HARYANA",
  },
  {
    Pincode: 713502,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500062,
    StateName: "TELANGANA",
  },
  {
    Pincode: 635001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 522006,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 110067,
    StateName: "DELHI",
  },
  {
    Pincode: 734426,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734010,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734009,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 251309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247772,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251314,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247774,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247773,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247775,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 721507,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712403,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500083,
    StateName: "TELANGANA",
  },
  {
    Pincode: 741165,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741162,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741181,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741155,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741316,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741315,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741245,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741257,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 251203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251315,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251316,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251320,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 251318,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 711206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734220,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721644,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721634,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721647,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721650,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721651,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721635,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721646,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246426,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246427,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246428,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246435,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 803101,
    StateName: "BIHAR",
  },
  {
    Pincode: 713123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 323615,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 723202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723213,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723131,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723151,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712512,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712515,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500037,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500103,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500042,
    StateName: "TELANGANA",
  },
  {
    Pincode: 689121,
    StateName: "KERALA",
  },
  {
    Pincode: 500080,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500007,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500016,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500025,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500010,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500014,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500017,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500050,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500047,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500049,
    StateName: "TELANGANA",
  },
  {
    Pincode: 201306,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201309,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 625513,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 384470,
    StateName: "GUJARAT",
  },
  {
    Pincode: 723149,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723155,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 502110,
    StateName: "TELANGANA",
  },
  {
    Pincode: 277201,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 277202,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246401,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246422,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 110056,
    StateName: "DELHI",
  },
  {
    Pincode: 110099,
    StateName: "DELHI",
  },
  {
    Pincode: 248003,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246495,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 712304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712701,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712250,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712407,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 324007,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 324006,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 721127,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721306,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 609110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246441,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246443,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246444,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246446,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246431,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246488,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246453,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246449,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246455,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 623501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606202,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 493887,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 626134,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 713405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733209,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 272195,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 630606,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 492009,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 700074,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 636001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 723133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 631152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276136,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223225,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276203,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110025,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263652,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263663,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 383006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 621212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621133,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 733130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712402,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 500085,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500072,
    StateName: "TELANGANA",
  },
  {
    Pincode: 492018,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 722102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722155,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700004,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 276143,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 627811,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 497450,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 607001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 612002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 496108,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 628501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628215,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626118,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626607,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626112,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626129,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626111,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626127,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 722160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 722156,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 604001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605602,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 233001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 500026,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500056,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500061,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500011,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500094,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500040,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500009,
    StateName: "TELANGANA",
  },
  {
    Pincode: 734005,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734012,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 221307,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221208,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600070,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600064,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600043,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600131,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 276288,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 223226,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276207,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 276138,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 620001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221404,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 530020,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530017,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 515872,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 246487,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246472,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246473,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246442,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246141,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 626138,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626141,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626137,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 271801,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700012,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711106,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 251327,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 110097,
    StateName: "DELHI",
  },
  {
    Pincode: 626001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 496109,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 628001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628218,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628152,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 492004,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492014,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492005,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 628851,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628303,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 638452,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600026,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600033,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 277402,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 735301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 630321,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630558,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605757,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 606001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 206001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 643001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221204,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221108,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 741138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741150,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741157,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741313,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713409,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 277301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277401,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277210,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 277214,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600082,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 712308,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712152,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 632401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 712310,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712311,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712138,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712249,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 110065,
    StateName: "DELHI",
  },
  {
    Pincode: 741166,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741504,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 380006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380052,
    StateName: "GUJARAT",
  },
  {
    Pincode: 625601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 380007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380061,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380055,
    StateName: "GUJARAT",
  },
  {
    Pincode: 576220,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 276140,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 380014,
    StateName: "GUJARAT",
  },
  {
    Pincode: 624208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 500054,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500067,
    StateName: "TELANGANA",
  },
  {
    Pincode: 500093,
    StateName: "TELANGANA",
  },
  {
    Pincode: 602001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 282003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 788151,
    StateName: "ASSAM",
  },
  {
    Pincode: 517001,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 516005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 763002,
    StateName: "ODISHA",
  },
  {
    Pincode: 144023,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144010,
    StateName: "PUNJAB",
  },
  {
    Pincode: 271001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221405,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221313,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221107,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221206,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201301,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 626104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 642006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 635601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 522239,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 642126,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 110048,
    StateName: "DELHI",
  },
  {
    Pincode: 517646,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 431005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 533464,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 523316,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 388239,
    StateName: "GUJARAT",
  },
  {
    Pincode: 626004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 272001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 632001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221310,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221406,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221409,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 632301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 515413,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530022,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530013,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 825421,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 530041,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 530024,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 700111,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743268,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 600032,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600091,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600088,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600097,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600041,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600096,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600022,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600042,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628103,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 506146,
    StateName: "TELANGANA",
  },
  {
    Pincode: 823005,
    StateName: "BIHAR",
  },
  {
    Pincode: 628208,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 825326,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 825415,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829130,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 302002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302006,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302039,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302016,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302036,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302019,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302020,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628213,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 570027,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570014,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570022,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 497773,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 628101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628304,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 413004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 413005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 396421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396370,
    StateName: "GUJARAT",
  },
  {
    Pincode: 324004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 110060,
    StateName: "DELHI",
  },
  {
    Pincode: 393017,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388310,
    StateName: "GUJARAT",
  },
  {
    Pincode: 387315,
    StateName: "GUJARAT",
  },
  {
    Pincode: 626612,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626139,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626136,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626121,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626142,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626132,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628252,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628251,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 342003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 600013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600081,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600021,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 207001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600093,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 741254,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 303329,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 600102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600101,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600023,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600031,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600106,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600030,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600039,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600083,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600036,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600078,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600061,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 360007,
    StateName: "GUJARAT",
  },
  {
    Pincode: 363623,
    StateName: "GUJARAT",
  },
  {
    Pincode: 627401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 732101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 506009,
    StateName: "TELANGANA",
  },
  {
    Pincode: 342007,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 784125,
    StateName: "ASSAM",
  },
  {
    Pincode: 626116,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626123,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 387003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 144415,
    StateName: "PUNJAB",
  },
  {
    Pincode: 842006,
    StateName: "BIHAR",
  },
  {
    Pincode: 843130,
    StateName: "BIHAR",
  },
  {
    Pincode: 700089,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700064,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 605001,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 641601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631501,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 700033,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 504220,
    StateName: "TELANGANA",
  },
  {
    Pincode: 243501,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700046,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 764006,
    StateName: "ODISHA",
  },
  {
    Pincode: 763004,
    StateName: "ODISHA",
  },
  {
    Pincode: 763001,
    StateName: "ODISHA",
  },
  {
    Pincode: 413411,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 505211,
    StateName: "TELANGANA",
  },
  {
    Pincode: 396403,
    StateName: "GUJARAT",
  },
  {
    Pincode: 396424,
    StateName: "GUJARAT",
  },
  {
    Pincode: 814167,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 394516,
    StateName: "GUJARAT",
  },
  {
    Pincode: 395017,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394270,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394515,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394518,
    StateName: "GUJARAT",
  },
  {
    Pincode: 392135,
    StateName: "GUJARAT",
  },
  {
    Pincode: 621601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 160059,
    StateName: "PUNJAB",
  },
  {
    Pincode: 160062,
    StateName: "PUNJAB",
  },
  {
    Pincode: 611001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 576114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 700016,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700069,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700071,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700021,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700072,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 560093,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560016,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560017,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560300,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560005,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 492002,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492003,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492016,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 492017,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 900056,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 394327,
    StateName: "GUJARAT",
  },
  {
    Pincode: 394660,
    StateName: "GUJARAT",
  },
  {
    Pincode: 686022,
    StateName: "KERALA",
  },
  {
    Pincode: 686002,
    StateName: "KERALA",
  },
  {
    Pincode: 712306,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 302005,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302018,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302015,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302017,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302033,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 721172,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246474,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246481,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246171,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 627756,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 497553,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 625002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 491996,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497451,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 497557,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 712702,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712245,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712246,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 302042,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 388355,
    StateName: "GUJARAT",
  },
  {
    Pincode: 825329,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833212,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831005,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 680691,
    StateName: "KERALA",
  },
  {
    Pincode: 311001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 721637,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 248171,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248012,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 700086,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700025,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700042,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700107,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700019,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700082,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700020,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700031,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700026,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700047,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700040,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700094,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700078,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700022,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700032,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700045,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700092,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700061,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 388470,
    StateName: "GUJARAT",
  },
  {
    Pincode: 766003,
    StateName: "ODISHA",
  },
  {
    Pincode: 388465,
    StateName: "GUJARAT",
  },
  {
    Pincode: 741250,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 678013,
    StateName: "KERALA",
  },
  {
    Pincode: 678533,
    StateName: "KERALA",
  },
  {
    Pincode: 678014,
    StateName: "KERALA",
  },
  {
    Pincode: 690532,
    StateName: "KERALA",
  },
  {
    Pincode: 690511,
    StateName: "KERALA",
  },
  {
    Pincode: 690530,
    StateName: "KERALA",
  },
  {
    Pincode: 689623,
    StateName: "KERALA",
  },
  {
    Pincode: 690510,
    StateName: "KERALA",
  },
  {
    Pincode: 721122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721211,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721126,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721212,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721161,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 744209,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 742101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 742160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 384003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 624001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 624601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614601,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246469,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 621102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 712301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 683573,
    StateName: "KERALA",
  },
  {
    Pincode: 683502,
    StateName: "KERALA",
  },
  {
    Pincode: 683512,
    StateName: "KERALA",
  },
  {
    Pincode: 683571,
    StateName: "KERALA",
  },
  {
    Pincode: 497448,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 587156,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 639001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 276001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 160004,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 713130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 683111,
    StateName: "KERALA",
  },
  {
    Pincode: 712513,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 688012,
    StateName: "KERALA",
  },
  {
    Pincode: 221003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 637001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 831021,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832113,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 822117,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831014,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 628219,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 613010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 505215,
    StateName: "TELANGANA",
  },
  {
    Pincode: 614201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 688014,
    StateName: "KERALA",
  },
  {
    Pincode: 688004,
    StateName: "KERALA",
  },
  {
    Pincode: 688534,
    StateName: "KERALA",
  },
  {
    Pincode: 688533,
    StateName: "KERALA",
  },
  {
    Pincode: 688528,
    StateName: "KERALA",
  },
  {
    Pincode: 688536,
    StateName: "KERALA",
  },
  {
    Pincode: 323601,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 814115,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 342005,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342006,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 342011,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 628212,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 490022,
    StateName: "CHHATTISGARH",
  },
  {
    Pincode: 763003,
    StateName: "ODISHA",
  },
  {
    Pincode: 144001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144007,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144005,
    StateName: "PUNJAB",
  },
  {
    Pincode: 621010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 614713,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 741246,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 723101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 144601,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144401,
    StateName: "PUNJAB",
  },
  {
    Pincode: 814116,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 816115,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 413007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 515003,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 829129,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829131,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 711201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400701,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400710,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 505214,
    StateName: "TELANGANA",
  },
  {
    Pincode: 505210,
    StateName: "TELANGANA",
  },
  {
    Pincode: 834013,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 431006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 829149,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 723142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 602117,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 642128,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 631001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 713101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 222001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 600113,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600104,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600089,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 603211,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 246471,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246483,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 421501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421203,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144205,
    StateName: "PUNJAB",
  },
  {
    Pincode: 281001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 638001,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 230001,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700106,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734314,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 342026,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 246482,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248124,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248123,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 502325,
    StateName: "TELANGANA",
  },
  {
    Pincode: 502205,
    StateName: "TELANGANA",
  },
  {
    Pincode: 603127,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600087,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600092,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600040,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600084,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600038,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600107,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600025,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 248125,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248142,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248159,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248158,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248165,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248179,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248007,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248197,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248196,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249145,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249196,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249151,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249146,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249152,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249155,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249122,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249123,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249161,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249165,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249194,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249128,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249175,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 342012,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 630005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 829108,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829135,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 680122,
    StateName: "KERALA",
  },
  {
    Pincode: 680670,
    StateName: "KERALA",
  },
  {
    Pincode: 680712,
    StateName: "KERALA",
  },
  {
    Pincode: 680661,
    StateName: "KERALA",
  },
  {
    Pincode: 680665,
    StateName: "KERALA",
  },
  {
    Pincode: 680565,
    StateName: "KERALA",
  },
  {
    Pincode: 400011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400020,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 324001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 679564,
    StateName: "KERALA",
  },
  {
    Pincode: 630003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 581303,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 581304,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 628005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628302,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 342008,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 736157,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736165,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736167,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 686653,
    StateName: "KERALA",
  },
  {
    Pincode: 690559,
    StateName: "KERALA",
  },
  {
    Pincode: 834008,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 736160,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736159,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 614207,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 140604,
    StateName: "PUNJAB",
  },
  {
    Pincode: 712502,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 764048,
    StateName: "ODISHA",
  },
  {
    Pincode: 413003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440024,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 626105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 400007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400034,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 574112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 834007,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 842005,
    StateName: "BIHAR",
  },
  {
    Pincode: 131030,
    StateName: "HARYANA",
  },
  {
    Pincode: 440017,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440037,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440036,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440022,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440015,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440020,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 629810,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 440003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 146001,
    StateName: "PUNJAB",
  },
  {
    Pincode: 629156,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689512,
    StateName: "KERALA",
  },
  {
    Pincode: 689627,
    StateName: "KERALA",
  },
  {
    Pincode: 721129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 680521,
    StateName: "KERALA",
  },
  {
    Pincode: 700013,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 181152,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 560007,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560036,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560038,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 302037,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302034,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302038,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302045,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302046,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 721128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 683587,
    StateName: "KERALA",
  },
  {
    Pincode: 683589,
    StateName: "KERALA",
  },
  {
    Pincode: 688008,
    StateName: "KERALA",
  },
  {
    Pincode: 688555,
    StateName: "KERALA",
  },
  {
    Pincode: 700095,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700099,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 581212,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 600015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600085,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 502324,
    StateName: "TELANGANA",
  },
  {
    Pincode: 700075,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 602003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 854305,
    StateName: "BIHAR",
  },
  {
    Pincode: 854302,
    StateName: "BIHAR",
  },
  {
    Pincode: 721132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 302043,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 302040,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 534350,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 812003,
    StateName: "BIHAR",
  },
  {
    Pincode: 631006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 834014,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834005,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 440018,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440032,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440025,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440030,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 173230,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 395023,
    StateName: "GUJARAT",
  },
  {
    Pincode: 312001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 736101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 835215,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 834011,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 392215,
    StateName: "GUJARAT",
  },
  {
    Pincode: 689521,
    StateName: "KERALA",
  },
  {
    Pincode: 600129,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600075,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600074,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626108,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626133,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 410204,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422221,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421308,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400602,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400615,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 585229,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570002,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570004,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585106,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585367,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570025,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570031,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575016,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 401302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 305001,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 688013,
    StateName: "KERALA",
  },
  {
    Pincode: 303122,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 303123,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144006,
    StateName: "PUNJAB",
  },
  {
    Pincode: 575006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 440021,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440016,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411037,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411028,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 176218,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 560047,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560066,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560024,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560046,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560063,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560065,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560043,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560025,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 461122,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 461881,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 415213,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400709,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 613503,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 711108,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711115,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734001,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 744101,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 833213,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833223,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831009,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831007,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832111,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 421005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 303121,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 678010,
    StateName: "KERALA",
  },
  {
    Pincode: 741235,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743331,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735227,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 736206,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 678512,
    StateName: "KERALA",
  },
  {
    Pincode: 713347,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 563115,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563117,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 421305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400610,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 764004,
    StateName: "ODISHA",
  },
  {
    Pincode: 362540,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 829125,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 829126,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 388410,
    StateName: "GUJARAT",
  },
  {
    Pincode: 388421,
    StateName: "GUJARAT",
  },
  {
    Pincode: 518102,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 518101,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 712135,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 412112,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410508,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410403,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 712105,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713301,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 452006,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452011,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 387325,
    StateName: "GUJARAT",
  },
  {
    Pincode: 563160,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 462066,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 302044,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 686609,
    StateName: "KERALA",
  },
  {
    Pincode: 686616,
    StateName: "KERALA",
  },
  {
    Pincode: 689515,
    StateName: "KERALA",
  },
  {
    Pincode: 686122,
    StateName: "KERALA",
  },
  {
    Pincode: 575002,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575025,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560071,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 629155,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 682302,
    StateName: "KERALA",
  },
  {
    Pincode: 683514,
    StateName: "KERALA",
  },
  {
    Pincode: 683112,
    StateName: "KERALA",
  },
  {
    Pincode: 683563,
    StateName: "KERALA",
  },
  {
    Pincode: 700027,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713154,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 442003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442111,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 394221,
    StateName: "GUJARAT",
  },
  {
    Pincode: 173222,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 560075,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560094,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560045,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560084,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560033,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 413118,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 174317,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177202,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 440014,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440035,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440034,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440027,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440026,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440019,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 600007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 413303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440033,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 600090,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 524005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 441109,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 441123,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442107,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 173202,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173236,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 411043,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411016,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411024,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411030,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410210,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410222,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 827006,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826005,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 411039,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411019,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 574267,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575020,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 176304,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 414110,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 176305,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 585219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 453552,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 190014,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 313004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 686630,
    StateName: "KERALA",
  },
  {
    Pincode: 686010,
    StateName: "KERALA",
  },
  {
    Pincode: 629179,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 576228,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 632403,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 176317,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 737101,
    StateName: "SIKKIM",
  },
  {
    Pincode: 854109,
    StateName: "BIHAR",
  },
  {
    Pincode: 831019,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832109,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831013,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 370201,
    StateName: "GUJARAT",
  },
  {
    Pincode: 370230,
    StateName: "GUJARAT",
  },
  {
    Pincode: 700068,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700041,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 733101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712601,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713140,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 431003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 445216,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 686123,
    StateName: "KERALA",
  },
  {
    Pincode: 712201,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 180015,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180009,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 461005,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 713132,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713512,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713513,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713519,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713514,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713150,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721454,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 305007,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 194402,
    StateName: "LADAKH",
  },
  {
    Pincode: 688521,
    StateName: "KERALA",
  },
  {
    Pincode: 572103,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 827004,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828128,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827015,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828124,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828108,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826003,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 144031,
    StateName: "PUNJAB",
  },
  {
    Pincode: 688525,
    StateName: "KERALA",
  },
  {
    Pincode: 688570,
    StateName: "KERALA",
  },
  {
    Pincode: 462008,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 683543,
    StateName: "KERALA",
  },
  {
    Pincode: 828122,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828126,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826008,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826007,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827014,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 683106,
    StateName: "KERALA",
  },
  {
    Pincode: 721456,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 414003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 440008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 177205,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 411069,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 182207,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 700056,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400035,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 827012,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828135,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 411032,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411026,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 826009,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826011,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 411060,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411018,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 583203,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574327,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 360577,
    StateName: "GUJARAT",
  },
  {
    Pincode: 570007,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570020,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 585292,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 577451,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 722101,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700120,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400028,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400017,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400025,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400018,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 560080,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560051,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 461335,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 180020,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 400026,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680685,
    StateName: "KERALA",
  },
  {
    Pincode: 680570,
    StateName: "KERALA",
  },
  {
    Pincode: 362226,
    StateName: "GUJARAT",
  },
  {
    Pincode: 182104,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 400032,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 452015,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452009,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 174320,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 370210,
    StateName: "GUJARAT",
  },
  {
    Pincode: 678531,
    StateName: "KERALA",
  },
  {
    Pincode: 682309,
    StateName: "KERALA",
  },
  {
    Pincode: 682020,
    StateName: "KERALA",
  },
  {
    Pincode: 682304,
    StateName: "KERALA",
  },
  {
    Pincode: 682040,
    StateName: "KERALA",
  },
  {
    Pincode: 682036,
    StateName: "KERALA",
  },
  {
    Pincode: 682015,
    StateName: "KERALA",
  },
  {
    Pincode: 682031,
    StateName: "KERALA",
  },
  {
    Pincode: 682013,
    StateName: "KERALA",
  },
  {
    Pincode: 682028,
    StateName: "KERALA",
  },
  {
    Pincode: 682042,
    StateName: "KERALA",
  },
  {
    Pincode: 682501,
    StateName: "KERALA",
  },
  {
    Pincode: 682010,
    StateName: "KERALA",
  },
  {
    Pincode: 682505,
    StateName: "KERALA",
  },
  {
    Pincode: 682009,
    StateName: "KERALA",
  },
  {
    Pincode: 828119,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828123,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 826010,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 411035,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411027,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411031,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 736176,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 831006,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831011,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831020,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 833222,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831017,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 180018,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180006,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180005,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181124,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180007,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 181205,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180013,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 700087,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 686021,
    StateName: "KERALA",
  },
  {
    Pincode: 686637,
    StateName: "KERALA",
  },
  {
    Pincode: 686560,
    StateName: "KERALA",
  },
  {
    Pincode: 180016,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 180019,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190010,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190002,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 686548,
    StateName: "KERALA",
  },
  {
    Pincode: 686537,
    StateName: "KERALA",
  },
  {
    Pincode: 686517,
    StateName: "KERALA",
  },
  {
    Pincode: 190009,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190005,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 831015,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831003,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 832103,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 831016,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 690571,
    StateName: "KERALA",
  },
  {
    Pincode: 690537,
    StateName: "KERALA",
  },
  {
    Pincode: 190021,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 576216,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 682553,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 682557,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 193403,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 686041,
    StateName: "KERALA",
  },
  {
    Pincode: 686018,
    StateName: "KERALA",
  },
  {
    Pincode: 686006,
    StateName: "KERALA",
  },
  {
    Pincode: 686007,
    StateName: "KERALA",
  },
  {
    Pincode: 683521,
    StateName: "KERALA",
  },
  {
    Pincode: 742164,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 683515,
    StateName: "KERALA",
  },
  {
    Pincode: 452003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 400614,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410216,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 410218,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 583276,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 629102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 686009,
    StateName: "KERALA",
  },
  {
    Pincode: 560079,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560023,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560086,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 402125,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 402207,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 586109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 341020,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 191132,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190007,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190004,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190023,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 577181,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 821307,
    StateName: "BIHAR",
  },
  {
    Pincode: 190012,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 176066,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 829116,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 415212,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 642114,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 630102,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 194404,
    StateName: "LADAKH",
  },
  {
    Pincode: 682016,
    StateName: "KERALA",
  },
  {
    Pincode: 682012,
    StateName: "KERALA",
  },
  {
    Pincode: 682038,
    StateName: "KERALA",
  },
  {
    Pincode: 682032,
    StateName: "KERALA",
  },
  {
    Pincode: 410301,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 581307,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 410302,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 828309,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 827003,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828130,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828133,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828107,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 812002,
    StateName: "BIHAR",
  },
  {
    Pincode: 176002,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 518186,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 182206,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 360578,
    StateName: "GUJARAT",
  },
  {
    Pincode: 516439,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 828404,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 144602,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 470340,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 421506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 421202,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144408,
    StateName: "PUNJAB",
  },
  {
    Pincode: 800016,
    StateName: "BIHAR",
  },
  {
    Pincode: 800025,
    StateName: "BIHAR",
  },
  {
    Pincode: 800026,
    StateName: "BIHAR",
  },
  {
    Pincode: 800022,
    StateName: "BIHAR",
  },
  {
    Pincode: 800018,
    StateName: "BIHAR",
  },
  {
    Pincode: 486004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 518005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 678104,
    StateName: "KERALA",
  },
  {
    Pincode: 721424,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 683550,
    StateName: "KERALA",
  },
  {
    Pincode: 683548,
    StateName: "KERALA",
  },
  {
    Pincode: 686666,
    StateName: "KERALA",
  },
  {
    Pincode: 682041,
    StateName: "KERALA",
  },
  {
    Pincode: 682033,
    StateName: "KERALA",
  },
  {
    Pincode: 800027,
    StateName: "BIHAR",
  },
  {
    Pincode: 800013,
    StateName: "BIHAR",
  },
  {
    Pincode: 800014,
    StateName: "BIHAR",
  },
  {
    Pincode: 563118,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 563119,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 144008,
    StateName: "PUNJAB",
  },
  {
    Pincode: 523002,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 682037,
    StateName: "KERALA",
  },
  {
    Pincode: 682026,
    StateName: "KERALA",
  },
  {
    Pincode: 682035,
    StateName: "KERALA",
  },
  {
    Pincode: 682018,
    StateName: "KERALA",
  },
  {
    Pincode: 360460,
    StateName: "GUJARAT",
  },
  {
    Pincode: 670676,
    StateName: "KERALA",
  },
  {
    Pincode: 673311,
    StateName: "KERALA",
  },
  {
    Pincode: 682017,
    StateName: "KERALA",
  },
  {
    Pincode: 682022,
    StateName: "KERALA",
  },
  {
    Pincode: 453555,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 562161,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 700003,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 361004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 191102,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 686531,
    StateName: "KERALA",
  },
  {
    Pincode: 176062,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 570015,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570012,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570030,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570032,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 686505,
    StateName: "KERALA",
  },
  {
    Pincode: 560072,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 342030,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 682023,
    StateName: "KERALA",
  },
  {
    Pincode: 682019,
    StateName: "KERALA",
  },
  {
    Pincode: 682502,
    StateName: "KERALA",
  },
  {
    Pincode: 682511,
    StateName: "KERALA",
  },
  {
    Pincode: 682029,
    StateName: "KERALA",
  },
  {
    Pincode: 682003,
    StateName: "KERALA",
  },
  {
    Pincode: 682002,
    StateName: "KERALA",
  },
  {
    Pincode: 682051,
    StateName: "KERALA",
  },
  {
    Pincode: 682034,
    StateName: "KERALA",
  },
  {
    Pincode: 683503,
    StateName: "KERALA",
  },
  {
    Pincode: 676312,
    StateName: "KERALA",
  },
  {
    Pincode: 676309,
    StateName: "KERALA",
  },
  {
    Pincode: 370203,
    StateName: "GUJARAT",
  },
  {
    Pincode: 482011,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486885,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 800006,
    StateName: "BIHAR",
  },
  {
    Pincode: 416008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 800028,
    StateName: "BIHAR",
  },
  {
    Pincode: 800017,
    StateName: "BIHAR",
  },
  {
    Pincode: 800024,
    StateName: "BIHAR",
  },
  {
    Pincode: 202146,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 486884,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 689574,
    StateName: "KERALA",
  },
  {
    Pincode: 171015,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 173210,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 177021,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 600046,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 484220,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 799046,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799005,
    StateName: "TRIPURA",
  },
  {
    Pincode: 177005,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 679583,
    StateName: "KERALA",
  },
  {
    Pincode: 676109,
    StateName: "KERALA",
  },
  {
    Pincode: 676107,
    StateName: "KERALA",
  },
  {
    Pincode: 670104,
    StateName: "KERALA",
  },
  {
    Pincode: 673316,
    StateName: "KERALA",
  },
  {
    Pincode: 670662,
    StateName: "KERALA",
  },
  {
    Pincode: 673312,
    StateName: "KERALA",
  },
  {
    Pincode: 713515,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 799022,
    StateName: "TRIPURA",
  },
  {
    Pincode: 793107,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 583215,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 583225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560026,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560102,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560070,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560085,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 453446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 828132,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828105,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 560050,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560114,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560081,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560500,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 362520,
    StateName: "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
  },
  {
    Pincode: 574225,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 572168,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 721422,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721423,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721442,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 591246,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 571607,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 813214,
    StateName: "BIHAR",
  },
  {
    Pincode: 415516,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 721425,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721455,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700044,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700024,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700063,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700053,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700018,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700008,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700034,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700066,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700029,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 173201,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 442303,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 560042,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560107,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560054,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560058,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560097,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 682552,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 682556,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 682558,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 682559,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 700005,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700006,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700067,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 680613,
    StateName: "KERALA",
  },
  {
    Pincode: 190020,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 560021,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 753010,
    StateName: "ODISHA",
  },
  {
    Pincode: 755020,
    StateName: "ODISHA",
  },
  {
    Pincode: 562165,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 190025,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 190024,
    StateName: "JAMMU AND KASHMIR",
  },
  {
    Pincode: 680546,
    StateName: "KERALA",
  },
  {
    Pincode: 209725,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 413713,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 174005,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 411038,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411052,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411009,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411042,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411068,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 700060,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700038,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 670708,
    StateName: "KERALA",
  },
  {
    Pincode: 678546,
    StateName: "KERALA",
  },
  {
    Pincode: 209726,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 678051,
    StateName: "KERALA",
  },
  {
    Pincode: 791114,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 790103,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 431011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680612,
    StateName: "KERALA",
  },
  {
    Pincode: 680655,
    StateName: "KERALA",
  },
  {
    Pincode: 534006,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 534005,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 685603,
    StateName: "KERALA",
  },
  {
    Pincode: 249136,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 685591,
    StateName: "KERALA",
  },
  {
    Pincode: 534215,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 798623,
    StateName: "NAGALAND",
  },
  {
    Pincode: 454775,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 828207,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828120,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828206,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 828127,
    StateName: "JHARKHAND",
  },
  {
    Pincode: 208027,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208013,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 411034,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 700147,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 305009,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 400030,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 362715,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560030,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560018,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560095,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560098,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560027,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560100,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 361347,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560015,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560022,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560088,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 247002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 247343,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 560002,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560009,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560040,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560055,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 575004,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 574148,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 841447,
    StateName: "BIHAR",
  },
  {
    Pincode: 577125,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 821303,
    StateName: "BIHAR",
  },
  {
    Pincode: 560111,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 800003,
    StateName: "BIHAR",
  },
  {
    Pincode: 800021,
    StateName: "BIHAR",
  },
  {
    Pincode: 798607,
    StateName: "NAGALAND",
  },
  {
    Pincode: 691013,
    StateName: "KERALA",
  },
  {
    Pincode: 691515,
    StateName: "KERALA",
  },
  {
    Pincode: 691504,
    StateName: "KERALA",
  },
  {
    Pincode: 411044,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 700148,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700070,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700084,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700146,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 305817,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 700023,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700043,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700088,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 689111,
    StateName: "KERALA",
  },
  {
    Pincode: 805114,
    StateName: "BIHAR",
  },
  {
    Pincode: 470124,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 470229,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 523156,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 695302,
    StateName: "KERALA",
  },
  {
    Pincode: 695585,
    StateName: "KERALA",
  },
  {
    Pincode: 695318,
    StateName: "KERALA",
  },
  {
    Pincode: 695025,
    StateName: "KERALA",
  },
  {
    Pincode: 695033,
    StateName: "KERALA",
  },
  {
    Pincode: 695036,
    StateName: "KERALA",
  },
  {
    Pincode: 695016,
    StateName: "KERALA",
  },
  {
    Pincode: 695023,
    StateName: "KERALA",
  },
  {
    Pincode: 695022,
    StateName: "KERALA",
  },
  {
    Pincode: 695035,
    StateName: "KERALA",
  },
  {
    Pincode: 695317,
    StateName: "KERALA",
  },
  {
    Pincode: 695029,
    StateName: "KERALA",
  },
  {
    Pincode: 695582,
    StateName: "KERALA",
  },
  {
    Pincode: 695015,
    StateName: "KERALA",
  },
  {
    Pincode: 695007,
    StateName: "KERALA",
  },
  {
    Pincode: 695588,
    StateName: "KERALA",
  },
  {
    Pincode: 695051,
    StateName: "KERALA",
  },
  {
    Pincode: 695142,
    StateName: "KERALA",
  },
  {
    Pincode: 695611,
    StateName: "KERALA",
  },
  {
    Pincode: 695587,
    StateName: "KERALA",
  },
  {
    Pincode: 695316,
    StateName: "KERALA",
  },
  {
    Pincode: 689626,
    StateName: "KERALA",
  },
  {
    Pincode: 689115,
    StateName: "KERALA",
  },
  {
    Pincode: 689597,
    StateName: "KERALA",
  },
  {
    Pincode: 560035,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560053,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560059,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560110,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560108,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560029,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560062,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560061,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 484114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 411006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 208007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 691301,
    StateName: "KERALA",
  },
  {
    Pincode: 691581,
    StateName: "KERALA",
  },
  {
    Pincode: 691007,
    StateName: "KERALA",
  },
  {
    Pincode: 691319,
    StateName: "KERALA",
  },
  {
    Pincode: 691019,
    StateName: "KERALA",
  },
  {
    Pincode: 691527,
    StateName: "KERALA",
  },
  {
    Pincode: 691304,
    StateName: "KERALA",
  },
  {
    Pincode: 691012,
    StateName: "KERALA",
  },
  {
    Pincode: 796004,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796036,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796230,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796075,
    StateName: "MIZORAM",
  },
  {
    Pincode: 796471,
    StateName: "MIZORAM",
  },
  {
    Pincode: 689551,
    StateName: "KERALA",
  },
  {
    Pincode: 799014,
    StateName: "TRIPURA",
  },
  {
    Pincode: 799010,
    StateName: "TRIPURA",
  },
  {
    Pincode: 208005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208025,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208023,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208024,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 560096,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 361350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 680656,
    StateName: "KERALA",
  },
  {
    Pincode: 812007,
    StateName: "BIHAR",
  },
  {
    Pincode: 516311,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 630002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 503002,
    StateName: "TELANGANA",
  },
  {
    Pincode: 570016,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570006,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570029,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570011,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 570017,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 274403,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 744203,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 414605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 797099,
    StateName: "NAGALAND",
  },
  {
    Pincode: 691006,
    StateName: "KERALA",
  },
  {
    Pincode: 691016,
    StateName: "KERALA",
  },
  {
    Pincode: 691009,
    StateName: "KERALA",
  },
  {
    Pincode: 691589,
    StateName: "KERALA",
  },
  {
    Pincode: 208026,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 791003,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 176075,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 792123,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 791109,
    StateName: "ARUNACHAL PRADESH",
  },
  {
    Pincode: 208011,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 855106,
    StateName: "BIHAR",
  },
  {
    Pincode: 636002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636016,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 208015,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 799202,
    StateName: "TRIPURA",
  },
  {
    Pincode: 208010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208014,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 208019,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 636007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 682551,
    StateName: "LAKSHADWEEP",
  },
  {
    Pincode: 680013,
    StateName: "KERALA",
  },
  {
    Pincode: 680502,
    StateName: "KERALA",
  },
  {
    Pincode: 680520,
    StateName: "KERALA",
  },
  {
    Pincode: 680618,
    StateName: "KERALA",
  },
  {
    Pincode: 560104,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560020,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 768031,
    StateName: "ODISHA",
  },
  {
    Pincode: 623806,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 560112,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 361009,
    StateName: "GUJARAT",
  },
  {
    Pincode: 173026,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 796810,
    StateName: "MIZORAM",
  },
  {
    Pincode: 484120,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 796772,
    StateName: "MIZORAM",
  },
  {
    Pincode: 221009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 689592,
    StateName: "KERALA",
  },
  {
    Pincode: 470003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486888,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 486890,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 482020,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 695017,
    StateName: "KERALA",
  },
  {
    Pincode: 221407,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 682025,
    StateName: "KERALA",
  },
  {
    Pincode: 682039,
    StateName: "KERALA",
  },
  {
    Pincode: 682050,
    StateName: "KERALA",
  },
  {
    Pincode: 682317,
    StateName: "KERALA",
  },
  {
    Pincode: 682503,
    StateName: "KERALA",
  },
  {
    Pincode: 682004,
    StateName: "KERALA",
  },
  {
    Pincode: 682005,
    StateName: "KERALA",
  },
  {
    Pincode: 679585,
    StateName: "KERALA",
  },
  {
    Pincode: 679587,
    StateName: "KERALA",
  },
  {
    Pincode: 679581,
    StateName: "KERALA",
  },
  {
    Pincode: 679572,
    StateName: "KERALA",
  },
  {
    Pincode: 721463,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721406,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 670106,
    StateName: "KERALA",
  },
  {
    Pincode: 673313,
    StateName: "KERALA",
  },
  {
    Pincode: 678011,
    StateName: "KERALA",
  },
  {
    Pincode: 678732,
    StateName: "KERALA",
  },
  {
    Pincode: 721420,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721405,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 800005,
    StateName: "BIHAR",
  },
  {
    Pincode: 800019,
    StateName: "BIHAR",
  },
  {
    Pincode: 800011,
    StateName: "BIHAR",
  },
  {
    Pincode: 210122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 753003,
    StateName: "ODISHA",
  },
  {
    Pincode: 753016,
    StateName: "ODISHA",
  },
  {
    Pincode: 767042,
    StateName: "ODISHA",
  },
  {
    Pincode: 768218,
    StateName: "ODISHA",
  },
  {
    Pincode: 797121,
    StateName: "NAGALAND",
  },
  {
    Pincode: 678705,
    StateName: "KERALA",
  },
  {
    Pincode: 678505,
    StateName: "KERALA",
  },
  {
    Pincode: 673103,
    StateName: "KERALA",
  },
  {
    Pincode: 700142,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 473112,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 676104,
    StateName: "KERALA",
  },
  {
    Pincode: 462031,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 800023,
    StateName: "BIHAR",
  },
  {
    Pincode: 800015,
    StateName: "BIHAR",
  },
  {
    Pincode: 636401,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636456,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600028,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636402,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 312622,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 503322,
    StateName: "TELANGANA",
  },
  {
    Pincode: 603210,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 680515,
    StateName: "KERALA",
  },
  {
    Pincode: 680504,
    StateName: "KERALA",
  },
  {
    Pincode: 680509,
    StateName: "KERALA",
  },
  {
    Pincode: 755050,
    StateName: "ODISHA",
  },
  {
    Pincode: 754011,
    StateName: "ODISHA",
  },
  {
    Pincode: 753015,
    StateName: "ODISHA",
  },
  {
    Pincode: 754013,
    StateName: "ODISHA",
  },
  {
    Pincode: 591308,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 744106,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 744112,
    StateName: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    Pincode: 208016,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 768203,
    StateName: "ODISHA",
  },
  {
    Pincode: 627451,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 361330,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361002,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361005,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560113,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 627452,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627422,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627420,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415705,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 800010,
    StateName: "BIHAR",
  },
  {
    Pincode: 801106,
    StateName: "BIHAR",
  },
  {
    Pincode: 144011,
    StateName: "PUNJAB",
  },
  {
    Pincode: 456010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 411022,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411040,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444603,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 689550,
    StateName: "KERALA",
  },
  {
    Pincode: 521201,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 689584,
    StateName: "KERALA",
  },
  {
    Pincode: 689591,
    StateName: "KERALA",
  },
  {
    Pincode: 689602,
    StateName: "KERALA",
  },
  {
    Pincode: 600005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600034,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 796008,
    StateName: "MIZORAM",
  },
  {
    Pincode: 700155,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 480003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 680011,
    StateName: "KERALA",
  },
  {
    Pincode: 680616,
    StateName: "KERALA",
  },
  {
    Pincode: 680545,
    StateName: "KERALA",
  },
  {
    Pincode: 680002,
    StateName: "KERALA",
  },
  {
    Pincode: 680642,
    StateName: "KERALA",
  },
  {
    Pincode: 680611,
    StateName: "KERALA",
  },
  {
    Pincode: 361142,
    StateName: "GUJARAT",
  },
  {
    Pincode: 361150,
    StateName: "GUJARAT",
  },
  {
    Pincode: 560057,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560012,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560078,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 560011,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 679515,
    StateName: "KERALA",
  },
  {
    Pincode: 793011,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 600035,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 312627,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 144009,
    StateName: "PUNJAB",
  },
  {
    Pincode: 144027,
    StateName: "PUNJAB",
  },
  {
    Pincode: 759102,
    StateName: "ODISHA",
  },
  {
    Pincode: 759111,
    StateName: "ODISHA",
  },
  {
    Pincode: 759037,
    StateName: "ODISHA",
  },
  {
    Pincode: 759149,
    StateName: "ODISHA",
  },
  {
    Pincode: 759116,
    StateName: "ODISHA",
  },
  {
    Pincode: 416006,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401702,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 700153,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700093,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700143,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 323305,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 246142,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 474002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474009,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 411051,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411020,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411058,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411067,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442502,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 695308,
    StateName: "KERALA",
  },
  {
    Pincode: 695034,
    StateName: "KERALA",
  },
  {
    Pincode: 282010,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 627818,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 636406,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 803121,
    StateName: "BIHAR",
  },
  {
    Pincode: 284125,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 678704,
    StateName: "KERALA",
  },
  {
    Pincode: 678685,
    StateName: "KERALA",
  },
  {
    Pincode: 678504,
    StateName: "KERALA",
  },
  {
    Pincode: 444304,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 144012,
    StateName: "PUNJAB",
  },
  {
    Pincode: 625556,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 711103,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711110,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711308,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711102,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 231209,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 767070,
    StateName: "ODISHA",
  },
  {
    Pincode: 711112,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711225,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 560109,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 244717,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 400013,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 577128,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 753013,
    StateName: "ODISHA",
  },
  {
    Pincode: 444810,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431009,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 624701,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600094,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 160012,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160019,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 225002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 160104,
    StateName: "PUNJAB",
  },
  {
    Pincode: 160103,
    StateName: "PUNJAB",
  },
  {
    Pincode: 416316,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416307,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 768053,
    StateName: "ODISHA",
  },
  {
    Pincode: 613008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627428,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 415717,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415614,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431520,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 208022,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 411061,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 641407,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 721403,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721402,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721404,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 627759,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627858,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 462024,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 624301,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 803215,
    StateName: "BIHAR",
  },
  {
    Pincode: 284305,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 343002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 401206,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 625522,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416527,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 751006,
    StateName: "ODISHA",
  },
  {
    Pincode: 751008,
    StateName: "ODISHA",
  },
  {
    Pincode: 751010,
    StateName: "ODISHA",
  },
  {
    Pincode: 751013,
    StateName: "ODISHA",
  },
  {
    Pincode: 751026,
    StateName: "ODISHA",
  },
  {
    Pincode: 751033,
    StateName: "ODISHA",
  },
  {
    Pincode: 801507,
    StateName: "BIHAR",
  },
  {
    Pincode: 759107,
    StateName: "ODISHA",
  },
  {
    Pincode: 759106,
    StateName: "ODISHA",
  },
  {
    Pincode: 759040,
    StateName: "ODISHA",
  },
  {
    Pincode: 160001,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160011,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160015,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160022,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160030,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 516330,
    StateName: "ANDHRA PRADESH",
  },
  {
    Pincode: 625214,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 141003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141013,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141014,
    StateName: "PUNJAB",
  },
  {
    Pincode: 249137,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 474012,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 793019,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 711203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711105,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711107,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711306,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711331,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 244720,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 263142,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 444606,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444605,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 444607,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 415601,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401506,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401305,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 759148,
    StateName: "ODISHA",
  },
  {
    Pincode: 462002,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462016,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462039,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462020,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670358,
    StateName: "KERALA",
  },
  {
    Pincode: 461114,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 670004,
    StateName: "KERALA",
  },
  {
    Pincode: 670327,
    StateName: "KERALA",
  },
  {
    Pincode: 670567,
    StateName: "KERALA",
  },
  {
    Pincode: 211003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 793014,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793122,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 794112,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 452012,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452014,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 793002,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 793016,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 160005,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160006,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 793003,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 754034,
    StateName: "ODISHA",
  },
  {
    Pincode: 753009,
    StateName: "ODISHA",
  },
  {
    Pincode: 751012,
    StateName: "ODISHA",
  },
  {
    Pincode: 751034,
    StateName: "ODISHA",
  },
  {
    Pincode: 751032,
    StateName: "ODISHA",
  },
  {
    Pincode: 401703,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411008,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 753012,
    StateName: "ODISHA",
  },
  {
    Pincode: 753007,
    StateName: "ODISHA",
  },
  {
    Pincode: 416803,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 670002,
    StateName: "KERALA",
  },
  {
    Pincode: 670310,
    StateName: "KERALA",
  },
  {
    Pincode: 712234,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712235,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 680524,
    StateName: "KERALA",
  },
  {
    Pincode: 680516,
    StateName: "KERALA",
  },
  {
    Pincode: 680026,
    StateName: "KERALA",
  },
  {
    Pincode: 400070,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400024,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400022,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400058,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400050,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400051,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400093,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400099,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400049,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400055,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400084,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400074,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400088,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400080,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400076,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400079,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400075,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400078,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 671122,
    StateName: "KERALA",
  },
  {
    Pincode: 671320,
    StateName: "KERALA",
  },
  {
    Pincode: 670008,
    StateName: "KERALA",
  },
  {
    Pincode: 670014,
    StateName: "KERALA",
  },
  {
    Pincode: 670017,
    StateName: "KERALA",
  },
  {
    Pincode: 416517,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 411041,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 453556,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 312617,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 400012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 627603,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 769011,
    StateName: "ODISHA",
  },
  {
    Pincode: 769006,
    StateName: "ODISHA",
  },
  {
    Pincode: 612905,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621731,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 769013,
    StateName: "ODISHA",
  },
  {
    Pincode: 769008,
    StateName: "ODISHA",
  },
  {
    Pincode: 400033,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400003,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400027,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400037,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400031,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400009,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 249124,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 757002,
    StateName: "ODISHA",
  },
  {
    Pincode: 768019,
    StateName: "ODISHA",
  },
  {
    Pincode: 768003,
    StateName: "ODISHA",
  },
  {
    Pincode: 768002,
    StateName: "ODISHA",
  },
  {
    Pincode: 462100,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462041,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462023,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 416437,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416417,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 769007,
    StateName: "ODISHA",
  },
  {
    Pincode: 507138,
    StateName: "TELANGANA",
  },
  {
    Pincode: 400010,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 754297,
    StateName: "ODISHA",
  },
  {
    Pincode: 221012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 751022,
    StateName: "ODISHA",
  },
  {
    Pincode: 751025,
    StateName: "ODISHA",
  },
  {
    Pincode: 751015,
    StateName: "ODISHA",
  },
  {
    Pincode: 751030,
    StateName: "ODISHA",
  },
  {
    Pincode: 751020,
    StateName: "ODISHA",
  },
  {
    Pincode: 751023,
    StateName: "ODISHA",
  },
  {
    Pincode: 401209,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 756089,
    StateName: "ODISHA",
  },
  {
    Pincode: 770032,
    StateName: "ODISHA",
  },
  {
    Pincode: 769010,
    StateName: "ODISHA",
  },
  {
    Pincode: 769014,
    StateName: "ODISHA",
  },
  {
    Pincode: 769002,
    StateName: "ODISHA",
  },
  {
    Pincode: 769016,
    StateName: "ODISHA",
  },
  {
    Pincode: 620022,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 751007,
    StateName: "ODISHA",
  },
  {
    Pincode: 751005,
    StateName: "ODISHA",
  },
  {
    Pincode: 473638,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462040,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 211014,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 503174,
    StateName: "TELANGANA",
  },
  {
    Pincode: 507119,
    StateName: "TELANGANA",
  },
  {
    Pincode: 344024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334004,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334403,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334401,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 141006,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141017,
    StateName: "PUNJAB",
  },
  {
    Pincode: 609808,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 480112,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 600024,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 221110,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 221109,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 679104,
    StateName: "KERALA",
  },
  {
    Pincode: 262309,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 620027,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 416406,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 751004,
    StateName: "ODISHA",
  },
  {
    Pincode: 751031,
    StateName: "ODISHA",
  },
  {
    Pincode: 754012,
    StateName: "ODISHA",
  },
  {
    Pincode: 751009,
    StateName: "ODISHA",
  },
  {
    Pincode: 752115,
    StateName: "ODISHA",
  },
  {
    Pincode: 751014,
    StateName: "ODISHA",
  },
  {
    Pincode: 751011,
    StateName: "ODISHA",
  },
  {
    Pincode: 400067,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400068,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400102,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400064,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 263151,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 400104,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400065,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 770025,
    StateName: "ODISHA",
  },
  {
    Pincode: 751018,
    StateName: "ODISHA",
  },
  {
    Pincode: 600014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 605008,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605003,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 281303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 460447,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 638011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 621729,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 670304,
    StateName: "KERALA",
  },
  {
    Pincode: 670501,
    StateName: "KERALA",
  },
  {
    Pincode: 400072,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 680514,
    StateName: "KERALA",
  },
  {
    Pincode: 670010,
    StateName: "KERALA",
  },
  {
    Pincode: 712136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712248,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 605005,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605011,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 600086,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 282008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 400019,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400057,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400052,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400059,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400069,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400098,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400094,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400082,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400042,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400089,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400043,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400086,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400081,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400077,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 147003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 680513,
    StateName: "KERALA",
  },
  {
    Pincode: 680555,
    StateName: "KERALA",
  },
  {
    Pincode: 680615,
    StateName: "KERALA",
  },
  {
    Pincode: 141106,
    StateName: "PUNJAB",
  },
  {
    Pincode: 400066,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400063,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400060,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 679551,
    StateName: "KERALA",
  },
  {
    Pincode: 400101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400095,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400097,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 482010,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 605006,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 502101,
    StateName: "TELANGANA",
  },
  {
    Pincode: 141002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 344025,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 462037,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462033,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462011,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 741139,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713331,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 444007,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 425101,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 327024,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 246178,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 679516,
    StateName: "KERALA",
  },
  {
    Pincode: 711113,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711317,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 679553,
    StateName: "KERALA",
  },
  {
    Pincode: 768016,
    StateName: "ODISHA",
  },
  {
    Pincode: 323303,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 249302,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 246150,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 843335,
    StateName: "BIHAR",
  },
  {
    Pincode: 160025,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160018,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160020,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160009,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160043,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 224232,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 224238,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 141016,
    StateName: "PUNJAB",
  },
  {
    Pincode: 643201,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643270,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 754145,
    StateName: "ODISHA",
  },
  {
    Pincode: 713326,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713302,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 676522,
    StateName: "KERALA",
  },
  {
    Pincode: 753006,
    StateName: "ODISHA",
  },
  {
    Pincode: 735234,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400054,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400029,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400061,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400056,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 324009,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 231224,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 283205,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 768017,
    StateName: "ODISHA",
  },
  {
    Pincode: 768118,
    StateName: "ODISHA",
  },
  {
    Pincode: 673635,
    StateName: "KERALA",
  },
  {
    Pincode: 263156,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 734002,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734425,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 620020,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620019,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620017,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 334003,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 334006,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 713304,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 752003,
    StateName: "ODISHA",
  },
  {
    Pincode: 141010,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141012,
    StateName: "PUNJAB",
  },
  {
    Pincode: 141011,
    StateName: "PUNJAB",
  },
  {
    Pincode: 793123,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 172028,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 713365,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713340,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713344,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713386,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713303,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673011,
    StateName: "KERALA",
  },
  {
    Pincode: 760004,
    StateName: "ODISHA",
  },
  {
    Pincode: 400092,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400103,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 711305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 711111,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 638006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 249195,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 171008,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 401105,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401608,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 401402,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 461446,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 400087,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400083,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 735203,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 760005,
    StateName: "ODISHA",
  },
  {
    Pincode: 175051,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 614738,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 262526,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 741319,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 737136,
    StateName: "SIKKIM",
  },
  {
    Pincode: 673647,
    StateName: "KERALA",
  },
  {
    Pincode: 760006,
    StateName: "ODISHA",
  },
  {
    Pincode: 760011,
    StateName: "ODISHA",
  },
  {
    Pincode: 175075,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 250003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 741221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713369,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713372,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713357,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713361,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713213,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713305,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713216,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673028,
    StateName: "KERALA",
  },
  {
    Pincode: 673328,
    StateName: "KERALA",
  },
  {
    Pincode: 171215,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171002,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 271308,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 751021,
    StateName: "ODISHA",
  },
  {
    Pincode: 751027,
    StateName: "ODISHA",
  },
  {
    Pincode: 751029,
    StateName: "ODISHA",
  },
  {
    Pincode: 751028,
    StateName: "ODISHA",
  },
  {
    Pincode: 380016,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380004,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380024,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382415,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382345,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380018,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380026,
    StateName: "GUJARAT",
  },
  {
    Pincode: 282004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 673002,
    StateName: "KERALA",
  },
  {
    Pincode: 462027,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462043,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 462045,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 769015,
    StateName: "ODISHA",
  },
  {
    Pincode: 770042,
    StateName: "ODISHA",
  },
  {
    Pincode: 416807,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416515,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 609803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 382024,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382030,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382620,
    StateName: "GUJARAT",
  },
  {
    Pincode: 607807,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 273005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 626161,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626122,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 626188,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 591223,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 160071,
    StateName: "PUNJAB",
  },
  {
    Pincode: 583233,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 607002,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 382021,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382045,
    StateName: "GUJARAT",
  },
  {
    Pincode: 638053,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 230130,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 732144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 637415,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643233,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643243,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 452013,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 452018,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 322021,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 263149,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 732215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 249403,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249401,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249407,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249410,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249411,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248006,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248122,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248195,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248146,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248011,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 382041,
    StateName: "GUJARAT",
  },
  {
    Pincode: 249203,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 249201,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248018,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 670013,
    StateName: "KERALA",
  },
  {
    Pincode: 670325,
    StateName: "KERALA",
  },
  {
    Pincode: 670302,
    StateName: "KERALA",
  },
  {
    Pincode: 670018,
    StateName: "KERALA",
  },
  {
    Pincode: 670051,
    StateName: "KERALA",
  },
  {
    Pincode: 670503,
    StateName: "KERALA",
  },
  {
    Pincode: 734204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 273003,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 273017,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 380008,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382340,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380022,
    StateName: "GUJARAT",
  },
  {
    Pincode: 263651,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 712258,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 628007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 382428,
    StateName: "GUJARAT",
  },
  {
    Pincode: 607803,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607308,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 751016,
    StateName: "ODISHA",
  },
  {
    Pincode: 201011,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201018,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201016,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 580008,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580021,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 380063,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380021,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380023,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382350,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380003,
    StateName: "GUJARAT",
  },
  {
    Pincode: 628753,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628229,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 160023,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 160016,
    StateName: "CHANDIGARH",
  },
  {
    Pincode: 770052,
    StateName: "ODISHA",
  },
  {
    Pincode: 736205,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 380028,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382443,
    StateName: "GUJARAT",
  },
  {
    Pincode: 450119,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 227817,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 627003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 403102,
    StateName: "GOA",
  },
  {
    Pincode: 403206,
    StateName: "GOA",
  },
  {
    Pincode: 403109,
    StateName: "GOA",
  },
  {
    Pincode: 403106,
    StateName: "GOA",
  },
  {
    Pincode: 403202,
    StateName: "GOA",
  },
  {
    Pincode: 769005,
    StateName: "ODISHA",
  },
  {
    Pincode: 403526,
    StateName: "GOA",
  },
  {
    Pincode: 623520,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 711202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 400015,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 246448,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 735208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735226,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 301002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 271304,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 244223,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201303,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201302,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201013,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 754298,
    StateName: "ODISHA",
  },
  {
    Pincode: 638012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628217,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628209,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628216,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 628615,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 761052,
    StateName: "ODISHA",
  },
  {
    Pincode: 458470,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 201017,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201014,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201020,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 400096,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 400085,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 735232,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 735209,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 591219,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 482009,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 477450,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 141201,
    StateName: "PUNJAB",
  },
  {
    Pincode: 600051,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643105,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600037,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 251002,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 673051,
    StateName: "KERALA",
  },
  {
    Pincode: 422012,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416623,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 431604,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 333702,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 580002,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 382480,
    StateName: "GUJARAT",
  },
  {
    Pincode: 313805,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 382475,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380049,
    StateName: "GUJARAT",
  },
  {
    Pincode: 380050,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382418,
    StateName: "GUJARAT",
  },
  {
    Pincode: 201007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201019,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201021,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 262402,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 793007,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 620026,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211017,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 580003,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580030,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580031,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580032,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 713370,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713214,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713215,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673032,
    StateName: "KERALA",
  },
  {
    Pincode: 673301,
    StateName: "KERALA",
  },
  {
    Pincode: 673655,
    StateName: "KERALA",
  },
  {
    Pincode: 673021,
    StateName: "KERALA",
  },
  {
    Pincode: 628752,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 756088,
    StateName: "ODISHA",
  },
  {
    Pincode: 474004,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 244245,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 635124,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 680553,
    StateName: "KERALA",
  },
  {
    Pincode: 680591,
    StateName: "KERALA",
  },
  {
    Pincode: 313002,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 768233,
    StateName: "ODISHA",
  },
  {
    Pincode: 754120,
    StateName: "ODISHA",
  },
  {
    Pincode: 754143,
    StateName: "ODISHA",
  },
  {
    Pincode: 768004,
    StateName: "ODISHA",
  },
  {
    Pincode: 403712,
    StateName: "GOA",
  },
  {
    Pincode: 403602,
    StateName: "GOA",
  },
  {
    Pincode: 605013,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 605002,
    StateName: "PUDUCHERRY",
  },
  {
    Pincode: 632008,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643203,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 643006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 734421,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734424,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 734011,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 143006,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143005,
    StateName: "PUNJAB",
  },
  {
    Pincode: 201316,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 422002,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 201315,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 422011,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416144,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 416116,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 403806,
    StateName: "GOA",
  },
  {
    Pincode: 403725,
    StateName: "GOA",
  },
  {
    Pincode: 403729,
    StateName: "GOA",
  },
  {
    Pincode: 403724,
    StateName: "GOA",
  },
  {
    Pincode: 403720,
    StateName: "GOA",
  },
  {
    Pincode: 403521,
    StateName: "GOA",
  },
  {
    Pincode: 403201,
    StateName: "GOA",
  },
  {
    Pincode: 403529,
    StateName: "GOA",
  },
  {
    Pincode: 627004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 580026,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580029,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 580027,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 143002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 147002,
    StateName: "PUNJAB",
  },
  {
    Pincode: 741159,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741252,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 403527,
    StateName: "GOA",
  },
  {
    Pincode: 403203,
    StateName: "GOA",
  },
  {
    Pincode: 403501,
    StateName: "GOA",
  },
  {
    Pincode: 273008,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201313,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 201318,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 147008,
    StateName: "PUNJAB",
  },
  {
    Pincode: 752037,
    StateName: "ODISHA",
  },
  {
    Pincode: 768018,
    StateName: "ODISHA",
  },
  {
    Pincode: 768020,
    StateName: "ODISHA",
  },
  {
    Pincode: 582210,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 609305,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 689507,
    StateName: "KERALA",
  },
  {
    Pincode: 403701,
    StateName: "GOA",
  },
  {
    Pincode: 403716,
    StateName: "GOA",
  },
  {
    Pincode: 403717,
    StateName: "GOA",
  },
  {
    Pincode: 403711,
    StateName: "GOA",
  },
  {
    Pincode: 403803,
    StateName: "GOA",
  },
  {
    Pincode: 403804,
    StateName: "GOA",
  },
  {
    Pincode: 403721,
    StateName: "GOA",
  },
  {
    Pincode: 403722,
    StateName: "GOA",
  },
  {
    Pincode: 643253,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600110,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632115,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 244103,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 474011,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474007,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474015,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474008,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 474003,
    StateName: "MADHYA PRADESH",
  },
  {
    Pincode: 609810,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625007,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 607801,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625023,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 690546,
    StateName: "KERALA",
  },
  {
    Pincode: 638010,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 690525,
    StateName: "KERALA",
  },
  {
    Pincode: 690539,
    StateName: "KERALA",
  },
  {
    Pincode: 691590,
    StateName: "KERALA",
  },
  {
    Pincode: 691002,
    StateName: "KERALA",
  },
  {
    Pincode: 691014,
    StateName: "KERALA",
  },
  {
    Pincode: 721606,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721604,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 721171,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 770046,
    StateName: "ODISHA",
  },
  {
    Pincode: 680022,
    StateName: "KERALA",
  },
  {
    Pincode: 680009,
    StateName: "KERALA",
  },
  {
    Pincode: 679105,
    StateName: "KERALA",
  },
  {
    Pincode: 680020,
    StateName: "KERALA",
  },
  {
    Pincode: 680021,
    StateName: "KERALA",
  },
  {
    Pincode: 680654,
    StateName: "KERALA",
  },
  {
    Pincode: 211018,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211016,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641014,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641042,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 243005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 689644,
    StateName: "KERALA",
  },
  {
    Pincode: 741232,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741401,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 250005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641045,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 151003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 722183,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 620024,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 226016,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226014,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226004,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226005,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226018,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 580004,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 700057,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 226023,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 620023,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 620018,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 761025,
    StateName: "ODISHA",
  },
  {
    Pincode: 760008,
    StateName: "ODISHA",
  },
  {
    Pincode: 641050,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641036,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641033,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641049,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641004,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641037,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641009,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641044,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641005,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 700114,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700081,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700115,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700117,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743144,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743128,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743133,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743125,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700123,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 243006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641013,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641003,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641041,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641034,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211015,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211023,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 211021,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 231311,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 246737,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 700076,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700079,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700058,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700049,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700118,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743136,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 632057,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 632012,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600066,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211022,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641024,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 712222,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712221,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712233,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712232,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712137,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 641040,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 212105,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 691530,
    StateName: "KERALA",
  },
  {
    Pincode: 689652,
    StateName: "KERALA",
  },
  {
    Pincode: 695508,
    StateName: "KERALA",
  },
  {
    Pincode: 695005,
    StateName: "KERALA",
  },
  {
    Pincode: 673018,
    StateName: "KERALA",
  },
  {
    Pincode: 673302,
    StateName: "KERALA",
  },
  {
    Pincode: 673027,
    StateName: "KERALA",
  },
  {
    Pincode: 712104,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712503,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 241127,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 695032,
    StateName: "KERALA",
  },
  {
    Pincode: 695522,
    StateName: "KERALA",
  },
  {
    Pincode: 695019,
    StateName: "KERALA",
  },
  {
    Pincode: 695014,
    StateName: "KERALA",
  },
  {
    Pincode: 689654,
    StateName: "KERALA",
  },
  {
    Pincode: 689661,
    StateName: "KERALA",
  },
  {
    Pincode: 689713,
    StateName: "KERALA",
  },
  {
    Pincode: 600068,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 600050,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 211020,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 263620,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 226022,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226020,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226027,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226024,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226006,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226007,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226025,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226019,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226009,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226012,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226011,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 425004,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 175020,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 721624,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 263150,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 743122,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743124,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 246445,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 248015,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 229120,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 754071,
    StateName: "ODISHA",
  },
  {
    Pincode: 403002,
    StateName: "GOA",
  },
  {
    Pincode: 641027,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641039,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 212212,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 382006,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382630,
    StateName: "GUJARAT",
  },
  {
    Pincode: 382729,
    StateName: "GUJARAT",
  },
  {
    Pincode: 712121,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712514,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 226030,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 582206,
    StateName: "KARNATAKA",
  },
  {
    Pincode: 151004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 151006,
    StateName: "PUNJAB",
  },
  {
    Pincode: 627119,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 403801,
    StateName: "GOA",
  },
  {
    Pincode: 403731,
    StateName: "GOA",
  },
  {
    Pincode: 403715,
    StateName: "GOA",
  },
  {
    Pincode: 403409,
    StateName: "GOA",
  },
  {
    Pincode: 628006,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 625015,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 700096,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713342,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713210,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713209,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 712504,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 226029,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226015,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 226031,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 754026,
    StateName: "ODISHA",
  },
  {
    Pincode: 403005,
    StateName: "GOA",
  },
  {
    Pincode: 403204,
    StateName: "GOA",
  },
  {
    Pincode: 403402,
    StateName: "GOA",
  },
  {
    Pincode: 623566,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 385506,
    StateName: "GUJARAT",
  },
  {
    Pincode: 600076,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 231220,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641038,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 201317,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 422009,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 422005,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 248019,
    StateName: "UTTARAKHAND",
  },
  {
    Pincode: 768210,
    StateName: "ODISHA",
  },
  {
    Pincode: 403719,
    StateName: "GOA",
  },
  {
    Pincode: 403726,
    StateName: "GOA",
  },
  {
    Pincode: 691572,
    StateName: "KERALA",
  },
  {
    Pincode: 638060,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 140407,
    StateName: "PUNJAB",
  },
  {
    Pincode: 233234,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 232325,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 695040,
    StateName: "KERALA",
  },
  {
    Pincode: 695030,
    StateName: "KERALA",
  },
  {
    Pincode: 695099,
    StateName: "KERALA",
  },
  {
    Pincode: 680631,
    StateName: "KERALA",
  },
  {
    Pincode: 247122,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 641043,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 641011,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 695507,
    StateName: "KERALA",
  },
  {
    Pincode: 695042,
    StateName: "KERALA",
  },
  {
    Pincode: 695003,
    StateName: "KERALA",
  },
  {
    Pincode: 695018,
    StateName: "KERALA",
  },
  {
    Pincode: 695038,
    StateName: "KERALA",
  },
  {
    Pincode: 695513,
    StateName: "KERALA",
  },
  {
    Pincode: 695134,
    StateName: "KERALA",
  },
  {
    Pincode: 695528,
    StateName: "KERALA",
  },
  {
    Pincode: 713208,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 606811,
    StateName: "TAMIL NADU",
  },
  {
    Pincode: 760009,
    StateName: "ODISHA",
  },
  {
    Pincode: 793017,
    StateName: "MEGHALAYA",
  },
  {
    Pincode: 172034,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 172103,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 713376,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 673570,
    StateName: "KERALA",
  },
  {
    Pincode: 442901,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 442501,
    StateName: "MAHARASHTRA",
  },
  {
    Pincode: 691008,
    StateName: "KERALA",
  },
  {
    Pincode: 900099,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 301416,
    StateName: "RAJASTHAN",
  },
  {
    Pincode: 244926,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 722204,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 171223,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 143003,
    StateName: "PUNJAB",
  },
  {
    Pincode: 143004,
    StateName: "PUNJAB",
  },
  {
    Pincode: 743194,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 244928,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 695547,
    StateName: "KERALA",
  },
  {
    Pincode: 695570,
    StateName: "KERALA",
  },
  {
    Pincode: 689666,
    StateName: "KERALA",
  },
  {
    Pincode: 232341,
    StateName: "UTTAR PRADESH",
  },
  {
    Pincode: 741234,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 741247,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713202,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713207,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700109,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700116,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700158,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 700134,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743130,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743129,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 743193,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713217,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 713384,
    StateName: "WEST BENGAL",
  },
  {
    Pincode: 171218,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 171010,
    StateName: "HIMACHAL PRADESH",
  },
  {
    Pincode: 673006,
    StateName: "KERALA",
  },
];
