export const quesLangList = [
  "ENGLISH",
  "HINDI",
  "MARATHI",
  "BENGALI",
  "TELUGU",
  "TAMIL",
  "GUJARATI",
  "URDU",
  "KANNADA",
  "ASSAMESE",
  // "BODO",
  // "DOGRI",
  // "KASHMIRI",
  // "KONKANI",
  // "MAITHILI",
  "MALAYALAM",
  // "MANIPURI",
  // "NEPALI",
  "ODIA",
  "PUNJABI",
  // "SANTALI",
  // "SINDHI",
]; //IF ANY CHANGES HERE THEN MAKE IN BE ALSO//ALWAYS MAINTAIN THIS ORDER
