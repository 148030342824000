import React, { useState } from "react";
import { MathJax } from "better-react-mathjax";
import { Link } from "react-router-dom";

import { quesLangList } from "../../../constants/quesLangList";
import {
  CardDiv,
  CardParentDiv,
  StyledButton,
  StyledCard,
  StyledCardActions,
  StyledCardButton,
  StyledCardContent,
} from "../../../css/StyledComponents";

const QuestionListCourseCard = (props) => {
  const getLangIndex = (arr, lang) => {
    return arr.findIndex((element) => {
      return element.language === lang;
    });
  };
  return (
    // <CardParentDiv>
    <StyledCard>
      <StyledCardContent>
        <MathJax dynamic={true}>
          {props.question[getLangIndex(props.question, props.quesLangs[0])] &&
          props.question[getLangIndex(props.question, props.quesLangs[0])]?.is
            ? props.question[getLangIndex(props.question, props.quesLangs[0])]
                .is
            : `Not Available in ${props.quesLangs[0]} language`}
        </MathJax>
        {props.quesType === "ObjSingleSol" ||
        props.quesType === "ObjMultiSol" ||
        props.quesType === "TNF"
          ? props.options.map((item, index) => (
              <div style={{ display: "flex", flexDirection: "row" }}>
                ({index + 1})
                <MathJax dynamic={true}>
                  {item.option[getLangIndex(item.option, props.quesLangs[0])] &&
                  item.option[getLangIndex(item.option, props.quesLangs[0])]?.is
                    ? item.option[getLangIndex(item.option, props.quesLangs[0])]
                        .is
                    : `Not Available in ${props.quesLangs[0]} language`}
                </MathJax>
              </div>
            ))
          : null}
      </StyledCardContent>

      <StyledCardContent>
        <CardDiv>
          <Link
            exact
            to={"/newQuestion"}
            state={{
              id: props.id,
            }}
            style={{ textDecoration: "none" }}
          >
            <StyledCardActions>
              <StyledCardButton variant="contained" size="small">
                View
              </StyledCardButton>
            </StyledCardActions>
          </Link>
        </CardDiv>
      </StyledCardContent>
    </StyledCard>
    // {/* </CardParentDiv> */}
  );
};

export default QuestionListCourseCard;
