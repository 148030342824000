import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { RootDiv } from "../../css/StyledComponents";
import HomeMyCourse from "./homeMyCourse";
import Events_Updates from "./events&updates";

const Home = (props) => {
  return (
    <RootDiv>
      <Grid container direction="column">
        <Grid item container spacing={6} marginTop={0}>
          <Grid item />

          <Grid item xs={4} sm={3} style={{ paddingTop: 0 }}>
            <Events_Updates fUser={props.fUser} />
          </Grid>

          <Grid item xs={12} sm={8} style={{ paddingTop: 0 }}>
            <HomeMyCourse fUser={props.fUser} />
          </Grid>
        </Grid>
      </Grid>
    </RootDiv>
  );
};

export default Home;
