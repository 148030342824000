/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MathJaxContext } from "better-react-mathjax";

import {
  SignInParentDiv,
  SignInDiv,
  CarouselDiv,
} from "./css/StyledAuthComponents";

import MainFlow from "./flows/mainFlow";
import SignUp from "./components/signUp/SignUp";
import SignIn from "./components/signIn/SignIn";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import GSHomeImage from "../src/images/GS.png";
import Footer from "./components/Footer";
import Header from "./components/FrontHeader";

import "./App.css";

const MainApp = () => {
  const [hostname, setHostname] = React.useState(window.location.hostname);
  const [authPage, setAuthPage] = useState("SIGN_IN");
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  // const mathjaxConfig = {
  //   loader: {
  //     load: [
  //       "input/tex-full",
  //       "[tex]/newcommand",
  //       "[tex]/textmacros",
  //       "output/chtml",
  //     ],
  //     tex: {
  //       packages: { "[+]": ["newcommand", "textmacros"] },
  //       processEnvironments: true,
  //       // displaystyle: false,
  //       // processEscapes: false,
  //       inlineMath: [
  //         ["$", "$"],
  //         ["\\(", "\\)"],
  //       ],
  //       displayMath: [
  //         ["$$", "$$"],
  //         ["\\[", "\\]"],
  //       ],
  //       formatError: (jax, err) => jax.formatError(err),
  //     },
  //     // svg: {
  //     //   mathmlSpacing: true, // true for MathML spacing rules, false for TeX rules
  //     // },
  //     // load: ["input/asciimath", "output/svg"],
  //     // asciimath: {
  //     //   displaystyle: true,
  //     //   delimiters: [
  //     //     ["$", "$"],
  //     //     ["`", "`"],
  //     //   ],
  //     //   formatError: (jax, err) => jax.formatError(err),
  //     // },
  //     // load: ["input/mml", "output/svg"],
  //     // mml: { forceReparse: true },
  //     // mml: {
  //     //   displaystyle: true,
  //     //   delimiters: [
  //     //     ["$", "$"],
  //     //     ["`", "`"],
  //     //   ],
  //     //   formatError: (jax, err) => jax.formatError(err),
  //     // },
  //   },
  // };

  const mathjaxConfig = {
    tex2jax: {
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
      processEscapes: true,
      processEnvironments: true,
    },
  };
  const uploadLink = createUploadLink({
    uri: "https://h4yhqzubjc.execute-api.ap-south-1.amazonaws.com/dev/graphql",
    // uri: "http://localhost:3000/graphql",
    // headers: {
    //"Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": 'https://bprim.gyansopan.com',
    //"Content-Type": undefined,
    //"X-Content-Type-Options": "nosniff",
    // authorization: CognitoUser, // on production you need to store token
    // in storage or in redux persist, for demonstration purposes we do this like that
    // },
    // fetchOptions: {
    //    mode: "no-cors",
    // }
    // fetch: this._customFetch,
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) =>
    //     console.log(
    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    //     )
    //   );
    // if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const _createApolloClient = () => {
    const client = new ApolloClient({
      link: from([errorLink, uploadLink]),

      // uri: "http://localhost:3000/dev/graphql",
      cache: new InMemoryCache({
        addTypename: false,
      }),
      // connectToDevTools: true,
    });
    // console.log(client);
    return client;
  };
  return (
    <>
      {authStatus === "configuring" && "Loading..."}
      {authStatus !== "authenticated" ? (
        <SignInParentDiv>
          <Header />
          {/* <Marquee
            style={{  color: "#1F5156",
            fontSize: "18px",
            position: "fixed",
            top: 70,
            [theme.breakpoints.down("xs")]: {
              height: 0,
            },}}
            pauseOnHover
            speed={40}
            gradient={false}
          >
            {"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"}
            BMTSC 2023-2024 Round 2 will be conducted on Sunday, 4th
            February 2024, 12pm - 2:30pm
          </Marquee> */}

          <CarouselDiv>
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs={false}
              interval={13000}
              showStatus={false}
              showIndicators={false}
            >
              <div>
                {hostname === "bprim.gyansopan.com" ? (
                  <img
                    src="https://lh5.googleusercontent.com/p/AF1QipNKRfcuKv7ahnet8l3bN898qIbIeeprqXa6Wtxn=w1080-k-no"
                    alt="Bhaskaracharya Pratishthana"
                  />
                ) : (
                  <img
                    src={GSHomeImage}
                    alt="App"
                    width={"55vw"}
                    height={"100%"}
                  />
                )}
              </div>
            </Carousel>
          </CarouselDiv>
          <SignInDiv>
            {authPage === "SIGN_IN" ? (
              <SignIn setAuthPage={setAuthPage} />
            ) : authPage === "SIGN_UP" ? (
              <ApolloProvider client={_createApolloClient()}>
                <SignUp setAuthPage={setAuthPage} />
              </ApolloProvider>
            ) : authPage === "FORGOT_PASSWORD" ? (
              <ForgotPassword setAuthPage={setAuthPage} />
            ) : (
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", margin: 20 }}
              >
                Please Refresh Page, For any Technical assistance please call :
                +91 9967976846 || +91 9762938277
              </Typography>
            )}
          </SignInDiv>
          <Footer />
        </SignInParentDiv>
      ) : (
        <ApolloProvider client={_createApolloClient()}>
          <MathJaxContext
            config={mathjaxConfig}
            // src={"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"}
            // src="https://cdn.jsdelivr.net/npm/mathjax@3.2.2/es5/core.js"
            // src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.2.2/es5/tex-mml-svg.min.js"
            // src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS-MML_CHTML"
            version={2}
          >
            <MainFlow />
          </MathJaxContext>
        </ApolloProvider>
      )}
    </>
  );
};

export default MainApp;
