import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  InputAdornment,
  IconButton,
  FormControl,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  RootDiv,
  InnerDiv,
  StyledButton,
  StyledTextField,
  StyledPara,
  StyledSpan,
  StyledFormHelperText,
  ColumnFlexDiv,
  InstructionsDiv,
} from "../../css/StyledAuthComponents";

import { LoadingSpinner } from "../../constants/loadingSpinner";

import SignInValidationForm from "../validations/SignInValidation";

export default function SignIn(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [nameReq, setNameReq] = useState(false);
  const [newName, setNewName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [modeNewPassword, setModeNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { toSignUp, toForgotPassword } = useAuthenticator();
  const navigate = useNavigate();

  const validationSchema = SignInValidationForm;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    context: { modeNewPassword: modeNewPassword, nameReq: nameReq },
  });

  function callSignIn() {
    setLoading(true);
    signIn({ username, password })
      .then(({ nextStep }) => {
        if (
          nextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED" // ISSUE: WRITE CASE FOR RESET_PASSWORD
        ) {
          // ISSUE: WHAT IF ITS SOME OTHER CHALLENGE, WE JUST GONNA LET THE USER LOGIN?
        } else {
          setLoading(false);
          setModeNewPassword(true);
          const { missingAttributes } = nextStep ? nextStep : [];
          setNameReq(missingAttributes.includes("name"));
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        // console.log(JSON.stringify(error, null, 2));
        switch (error.code) {
          case "UserNotFoundException":
            alert("User Not Found");
            break;
          case "PasswordResetRequiredException":
            alert("Please reset your password by clicking on reset password");
            break;
          case "NotAuthorizedException":
            alert(`${error.message}`);
            break;
          default:
            alert("Something went wrong. Please contact admin.");
        }
      });
  }
  function submitNewPassword() {
    setLoading(true);
    confirmSignIn({
      challengeResponse: newPassword,
      options: {
        userAttributes: nameReq
          ? {
              name: newName,
            }
          : {},
      },
    })
      .then(({ nextStep }) => {})
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        // console.log(JSON.stringify(error, null, 2));
        switch (error.code) {
          case "UserNotFoundException":
            alert("User Not Found");
            break;
          case "PasswordResetRequiredException":
            alert("Please reset your password by clicking on reset password");
            setNameReq(false);
            setModeNewPassword(false);
            break;
          case "AliasExistsException":
            alert("User Already Exists");
            break;
          case "InvalidPasswordException":
            alert("Invalid Password Format");
            break;
          case "NotAuthorizedException":
            alert("Please Sign In Again");
            setNameReq(false);
            setModeNewPassword(false);
            break;
          default:
            alert("Something went wrong. Please contact admin.");
        }
      });
  }
  return (
    <RootDiv>
      <InnerDiv>
        <form>
          <Typography
            style={{
              color: "#1f5156",
              fontSize: "1.15rem",
              fontWeight: 700,
              marginBottom: "0.75rem",
              display: "inline-block",
            }}
          >
            Welcome to GyanSopan
          </Typography>
          <InstructionsDiv>
            <Link
              exact
              to={{
                pathname: "/instructions",
              }}
              style={{
                textDecoration: "none",
                // width: "100%",
                margin: "0px",
                textAlign: "center",
              }}
            >
              <StyledButton
                style={{
                  color: "white",
                  width: "fit-content",
                  marginBottom: 13,
                }}
              >
                Instructions
              </StyledButton>
            </Link>
          </InstructionsDiv>
          {modeNewPassword ? (
            <ColumnFlexDiv>
              <StyledFormHelperText style={{ color: "red" }}>
                Type a new password of your choice
              </StyledFormHelperText>
              <FormControl
                {...register("newPassword")}
                error={errors.newPassword ? true : false}
                style={{ alignItems: "center" }}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      type="password"
                      name="newPassword"
                      variant="outlined"
                      label="New Password"
                      value={newPassword}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => setNewPassword(event.target.value)}
                      required
                    />
                  )}
                  control={control}
                  name="newPassword"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.newPassword?.message}
                </StyledFormHelperText>
              </FormControl>
              <FormControl
                {...register("confirmNewPassword")}
                error={errors.confirmNewPassword ? true : false}
                style={{ alignItems: "center" }}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      type="password"
                      name="confirmNewPassword"
                      variant="outlined"
                      label="Confirm New Password"
                      value={confirmNewPassword}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) =>
                        setConfirmNewPassword(event.target.value)
                      }
                      required
                    />
                  )}
                  control={control}
                  name="confirmNewPassword"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.confirmNewPassword?.message}
                </StyledFormHelperText>
              </FormControl>
              {nameReq ? (
                <FormControl
                  {...register("newName")}
                  error={errors.newName ? true : false}
                  style={{ alignItems: "center" }}
                >
                  <Controller
                    render={({
                      field: { onChange, value, name, ...field },
                    }) => (
                      <StyledTextField
                        {...field}
                        name="newName"
                        variant="outlined"
                        label="Name"
                        value={newName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => setNewName(event.target.value)}
                        required
                      />
                    )}
                    control={control}
                    name="newName"
                  />
                  <StyledFormHelperText style={{ color: "red" }}>
                    {errors.newName?.message}
                  </StyledFormHelperText>
                </FormControl>
              ) : null}
              <ColumnFlexDiv>
                <StyledPara>
                  <StyledSpan
                    // variant="contained"
                    // color="primary"
                    onClick={() => {
                      setNameReq(false);
                      setModeNewPassword(false);
                    }}
                  >
                    Back to Sign In
                  </StyledSpan>
                </StyledPara>
                {loading ? (
                  LoadingSpinner(loading)
                ) : (
                  <StyledButton
                    variant="contained"
                    // color="primary"
                    size="large"
                    // onClick={() => {
                    //   handleSubmit();
                    // }}
                    onClick={handleSubmit(submitNewPassword)}
                    // onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </StyledButton>
                )}
              </ColumnFlexDiv>
            </ColumnFlexDiv>
          ) : (
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  {...register("username")}
                  error={errors.username ? true : false}
                >
                  <Controller
                    render={({
                      field: { onChange, value, name, ...field },
                    }) => (
                      <StyledTextField
                        {...field}
                        name="username"
                        variant="outlined"
                        label="User Name(Email)"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                    )}
                    control={control}
                    name="username"
                  />
                  <StyledFormHelperText style={{ color: "red" }}>
                    {errors.username?.message}
                  </StyledFormHelperText>
                </FormControl>
                <FormControl
                  {...register("password")}
                  error={errors.password ? true : false}
                >
                  <Controller
                    render={({
                      field: { onChange, value, name, ...field },
                    }) => (
                      <StyledTextField
                        {...field}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        variant="outlined"
                        label="Password"
                        value={password}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                style={{ padding: 0 }}
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!showPassword)
                                }
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    )}
                    control={control}
                    name="password"
                  />
                  <StyledFormHelperText style={{ color: "red" }}>
                    {errors.password?.message}
                  </StyledFormHelperText>
                </FormControl>
              </div>
              <StyledPara>
                Forgot your password?
                <StyledSpan
                  // variant="contained"
                  // color="primary"
                  // style={{ position: "absolute", right: "200px", top: "5px" }}
                  onClick={() => {
                    // toForgotPassword();
                    props.setAuthPage("FORGOT_PASSWORD");
                  }}
                >
                  Reset Password
                </StyledSpan>
              </StyledPara>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  flexDirection: "column",
                }}
              >
                <StyledPara>
                  Don't have an account?
                  <StyledSpan
                    // variant="contained"
                    // color="primary"
                    // style={{ position: "absolute", right: "200px", top: "5px" }}
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      // toSignUp();
                      props.setAuthPage("SIGN_UP");
                    }}
                  >
                    Sign Up
                  </StyledSpan>
                </StyledPara>
                {loading ? (
                  LoadingSpinner(loading)
                ) : (
                  <StyledButton
                    variant="contained"
                    // color="primary"
                    size="large"
                    // onClick={() => {
                    //   handleSubmit();
                    // }}
                    onClick={handleSubmit(callSignIn)}
                    // onClick={handleSubmit(onSubmit)}
                  >
                    Submit
                  </StyledButton>
                )}
              </div>
            </div>
          )}
        </form>
        <br />
        <br />
      </InnerDiv>
    </RootDiv>
  );
}
