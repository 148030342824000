import React from "react";
import InstructionDetails from "../../constants/instructions/instructionDetails";
import Footer from "../Footer";
import Header from "../FrontHeader";

const Faqs = () => {
  return (
    <div style={{ margin: "50px 0px 30px 0px" }}>
      <Header />
      <br />
      <InstructionDetails />
      <Footer />
    </div>
  );
};

export default Faqs;
