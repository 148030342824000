import React from "react";
import { styled } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import awsconfig from "./aws-exports";

import MainApp from "./MainApp";
import AboutUs from "./components/aboutUs/AboutUs";
import Faqs from "./components/faqs/Faqs";
import Instructions from "./components/instructions/Instructions";
import ContactUs from "./components/contactUs/ContactUs";
import PrivacyPolicy from "./components/policy/PrivacyPolicy";
import TermsAndConditions from "./components/policy/TermsAndConditions";
import DataPolicy from "./components/policy/DataPolicy";

import "./App.css";

Amplify.configure(awsconfig);

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "flex-end",
  flexGrow: 1,
  overflowX: "hidden",
  boxSizing: "border-box",
  // marginBottom: "20px",
  [theme.breakpoints.down("md")]: {
    minHeight: window.innerHeight,
    "align-items": "center",
  },
}));
const App = () => {
  return (
    <Authenticator.Provider>
      <RootDiv>
        <Router>
          <Routes>
            <Route exact="true" path="/aboutUs" element={<AboutUs />} />
            <Route exact="true" path="/contactUs" element={<ContactUs />} />
            <Route exact="true" path="/faqs" element={<Faqs />} />
            <Route
              exact="true"
              path="/instructions"
              element={<Instructions />}
            />
            <Route
              exact="true"
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
            />
            <Route exact="true" path="/dataPolicy" element={<DataPolicy />} />
            <Route
              exact="true"
              path="/termsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route exact path="/*" element={<MainApp />} />
          </Routes>
        </Router>
      </RootDiv>
    </Authenticator.Provider>
  );
};

export default App;
