import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  ButtonDiv,
  CardDiv,
  StyledCardButton,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardSpan,
  StyledCardTypography,
  StyledTypography,
} from "../../../css/StyledComponents";

const TestCourseCard = (props) => {
  const fUser = props.fUser;
  const [currentTime, setCurrentTime] = useState(new Date()); //NEEDS TO BE IN timeZone: "Asia/Kolkata", PLEASE SEE HOW TO DO,OTHERWISE USERS WITH DIFF SYSTEM TIMEZONE WILL HAVE ISSUES

  const testStartTimeParsed = new Date(props.testStartTime);
  const testStartTimeLocale = testStartTimeParsed.toLocaleTimeString("en-US", {
    timeZone: "Asia/Kolkata",
  });

  const testEndTimeParsed = new Date(props.testEndTime);
  const testEndTimeLocale = testEndTimeParsed.toLocaleTimeString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const testEndTimeDisplay = testEndTimeParsed.toLocaleTimeString("en-US", {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
  });

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h >= 0 ? h + (h == 1 ? " Hour " : " Hours ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Minute " : " Minutes ") : "";
    var sDisplay = s >= 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    return month + "/" + day + "/" + year;
  }
  const testEndTime = props.testEndTime;

  let formattedDate = props.ofDate ? formatDate(`${props.ofDate}`) : "";
  let formattedStartTime = new Date(`${formattedDate} ${testStartTimeLocale}`);
  // console.log(`${formattedDate} ${testStartTimeLocale}`, formattedStartTime);

  let formattedEndTime = new Date(`${formattedDate} ${testEndTimeLocale}`);
  // console.log(`${formattedDate} ${testEndTimeLocale}`, formattedEndTime);
  let fstSecsonds = formattedStartTime.getTime();
  let fetSecsonds = formattedEndTime.getTime();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date()); //NEEDS TO BE IN timeZone: "Asia/Kolkata",
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);
  let ctSeconds = currentTime.getTime();
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledCardTypography>
          Date(yyyy-mm-dd): <StyledCardSpan> {props.ofDate}</StyledCardSpan>
        </StyledCardTypography>
        <StyledCardTypography>
          Test Name: <StyledCardSpan> {props.name}</StyledCardSpan>
        </StyledCardTypography>
        <StyledCardTypography>
          Test Code: <StyledCardSpan> {props.code}</StyledCardSpan>
        </StyledCardTypography>
        <StyledCardTypography>
          Test Type: <StyledCardSpan> {props.testType}</StyledCardSpan>
        </StyledCardTypography>
        {props.isOfExam ? (
          <StyledCardTypography>
            Exam: <StyledCardSpan> {props.examName}</StyledCardSpan>
          </StyledCardTypography>
        ) : null}
        {/* <StyledCardTypography>
          Subject: <StyledCardSpan> {props.subject}</StyledCardSpan>
        </StyledCardTypography> */}
        <StyledCardTypography>
          Start Time: <StyledCardSpan> {testStartTimeLocale}</StyledCardSpan>
        </StyledCardTypography>
        <StyledCardTypography>
          Duration:{" "}
          <StyledCardSpan> {secondsToHms(props.duration)}</StyledCardSpan>
        </StyledCardTypography>
      </StyledCardContent>

      {!props.hideCardActions ? (
        <StyledCardActions>
          {fUser.role === "Teacher" || fUser.role === "Institute" ? (
            <ButtonDiv>
              <Link
                exact
                to={"/test"}
                state={{
                  id: props.id,
                  subId: props.subId,
                  isOfExam: props.isOfExam,
                  // primaryTopic: props.primaryTopic,
                  // secondaryTopic: props.secondaryTopic,
                  // gradeLevel: props.gradeLevel,
                }}
                style={{ textDecoration: "none" }}
              >
                <StyledCardButton variant="outlined" size="medium">
                  Open
                </StyledCardButton>
              </Link>
              <Link
                exact
                to={"/studentPerformanceList"}
                state={{
                  testId: props.id,
                }}
                style={{ textDecoration: "none" }}
              >
                <StyledCardButton variant="outlined" size="medium">
                  Performance
                </StyledCardButton>
              </Link>
              <Link
                exact
                to={"/examinerScreen"}
                state={{
                  testId: props.id,
                }}
                style={{ textDecoration: "none" }}
              >
                <StyledCardButton variant="outlined" size="medium">
                  Examine
                </StyledCardButton>
              </Link>
            </ButtonDiv>
          ) : fUser.role === "Student" ? (
            props.dq ? (
              <ButtonDiv>
                <StyledTypography>
                  You have been{" "}
                  <span style={{ color: "red" }}>DISQUALIFIED</span>
                </StyledTypography>
              </ButtonDiv>
            ) : (
              <ButtonDiv>
                {props.dirty ? (
                  props.restartable &&
                  fstSecsonds <= ctSeconds &&
                  ctSeconds < fetSecsonds ? (
                    <Link
                      exact
                      to={"/takeTest"}
                      state={{
                        id: props.id,
                        subId: props.subId,
                        duration: props.duration,
                        startTime: fstSecsonds,
                        // enterTime: ctSeconds,
                        restarted: true,
                        endTime: testEndTimeDisplay,
                        // primaryTopic: props.primaryTopic,
                        // secondaryTopic: props.secondaryTopic,
                        // gradeLevel: props.gradeLevel,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <StyledCardButton variant="outlined" size="medium">
                        Restart Test
                      </StyledCardButton>
                    </Link>
                  ) : // : !props.restartable ? (
                  //   <Link
                  //     exact
                  //     to={ "/performanceScreen"
                  //     }
                  // state={{
                  //         studId: fUser._id,
                  //         subId: props.subId,
                  //         testId: props.id,
                  //       }}
                  //     style={{ textDecoration: "none" }}
                  //   >
                  //       <StyledCardButton
                  //         variant="outlined"
                  //         size="medium"
                  //       >
                  //         Performance
                  //       </StyledCardButton>
                  //   </Link>
                  // )
                  null
                ) : fstSecsonds <= ctSeconds && ctSeconds < fetSecsonds ? (
                  <Link
                    exact
                    to={"/takeTest"}
                    state={{
                      id: props.id,
                      subId: props.subId,
                      duration: props.duration,
                      startTime: fstSecsonds,
                      endTime: testEndTimeDisplay,
                      // enterTime: ctSeconds,
                      // primaryTopic: props.primaryTopic,
                      // secondaryTopic: props.secondaryTopic,
                      // gradeLevel: props.gradeLevel,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <StyledCardButton variant="outlined" size="medium">
                      Start Test
                    </StyledCardButton>
                  </Link>
                ) : null}
                {props.dirty && ctSeconds > fetSecsonds ? (
                  <Link
                    exact
                    to={"/test"}
                    state={{
                      id: props.id,
                      subId: props.subId,
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <StyledCardButton variant="outlined" size="medium">
                      Submissions
                    </StyledCardButton>
                  </Link>
                ) : null}
              </ButtonDiv>
            )
          ) : null}
        </StyledCardActions>
      ) : null}
    </StyledCard>
  );
};

export default TestCourseCard;
