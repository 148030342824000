import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PublishTestContent from "./PublishTestContent";

import { RootDiv, StyledGrid } from "../../../css/StyledComponents";

const Test = (props) => {
  const fUser = props.fUser;
  const { state } = useLocation();
  const testId = state?.id;
  const submissionId = state?.subId;
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) navigate("/tests", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }

  return (
    <RootDiv>
      <StyledGrid container direction="column">
        <StyledGrid item container>
          <StyledGrid item xs={false} sm={1} />
          <StyledGrid item xs={12} sm={10}>
            <PublishTestContent
              fUser={fUser}
              testId={testId}
              submissionId={submissionId}
            />
          </StyledGrid>
          <StyledGrid item xs={false} sm={1} />
        </StyledGrid>
      </StyledGrid>
    </RootDiv>
  );
};

export default Test;
