import React, { useState } from "react";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";

import Header from "../header/Header";
import TeacherList from "./teacherList/TeacherList";

const Teachers = ({ fUser }) => {
  const headerFlag = 9;

  return (
    <RootDiv>
      <StyledGrid container direction="column" spacing={1}>
        <StyledGrid item>
          <Header flag={headerFlag} />
        </StyledGrid>

        {/* <StyledGrid item container>
          <StyledGrid item xs={false} sm={1} />
          <StyledGrid item xs={12} sm={10}>
            <Search flag={flag} />
          </StyledGrid>
          <StyledGrid item xs={false} sm={1} />
        </StyledGrid> */}

        <StyledGrid item container>
          <StyledGrid item xs={12} sm={10}>
            <TeacherList fUser={fUser} />
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </RootDiv>
  );
};

export default Teachers;
