import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import Header from "../header/Header";
import Content from "../course/Content";

const HomeMyCourse = (props) => {
  const fUser = props.fUser;
  const flag = 2;

  return (
    <Grid container direction="column" spacing={1}>
      {fUser.role === "Teacher" || fUser.role === "Institute" ? (
        <Grid item container>
          <Grid item xs={12} sm={12} style={{ display: "flex" }}>
            <Header flag={flag} />
          </Grid>
        </Grid>
      ) : null}

      <Grid item container>
        <Grid item xs={12} sm={12}>
          <Content flag={flag} fUser={props.fUser} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeMyCourse;
