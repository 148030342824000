import React, { useState, useRef, useEffect } from "react";
import { Paper, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ReactExports from "react-export-excel";
import ReactPaginate from "react-paginate";
import { Visibility } from "@mui/icons-material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "../../../ckEditor/build/ckeditor";

import {
  StyledButton,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTypography,
  StyledGrid,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  ButtonDiv,
  TableParentDiv,
  ListDiv,
  StyledCard,
  StyledAutocomplete,
  StyledAutocompleteTextField,
  RowToColumnFlexDiv,
  StyledInputLabel,
  ColumnFlexDiv,
  FiftyDiv,
} from "../../css/StyledComponents";

import { LoadingSpinner } from "../../constants/loadingSpinner";
import TestCourseCard from "../test/testList/TestCourseCard";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";

const studentGetExamQuery = loader(
  "../../graphqlCalls/exam/studentGetAExam.gql"
);
const userGetExamQuery = loader("../../graphqlCalls/exam/userGetAExam.gql");
const userAssignExamTestsMutation = loader(
  "../../graphqlCalls/exam/UserAssignExamTests.gql"
);

const ExcelFile = ReactExports.ExcelFile;
const ExcelSheet = ReactExports.ExcelSheet;
const ExcelColumn = ReactExports.ExcelColumn;
const dataSet = [];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const editorConfiguration = {
  toolbar: [],
};

const ExamInfo = (props) => {
  const fUser = props.fUser;

  const { state } = useLocation();

  // ISSUE: fetch exam again from backend and set it again
  const exam = state?.exam;
  const paid = state?.paid;
  const isCreator = state?.isCreator;

  const navigate = useNavigate();

  const [assignedTests, setAssignedTests] = useState([]);
  const [otherTests, setOtherTests] = useState([]);
  const [students, setStudents] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [selModel, setSelModel] = useState([]);
  const [unselectedTests, setUnselectedTests] = useState([]);
  const [roundsObject, setRoundsObject] = useState({});

  const editor = useRef(null);

  let testsPerPage = 3;
  const pagesVisited = pageNumber * testsPerPage;
  const pageCount = assignedTests
    ? Math.ceil(assignedTests.length / testsPerPage)
    : 0;

  const changepage = ({ selected }) => {
    setPageNumber(selected);
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "subject", headerName: "Subject", width: 150 },
    {
      field: "testType",
      headerName: "Type",
      width: 150,
    },
    {
      field: "testCode",
      headerName: "Code",
      width: 150,
    },
    {
      field: "ofDate",
      headerName: "Date(yyyy-mm-dd)",
      width: 150,
    },
  ];
  const rows = otherTests.map((test) => {
    return {
      id: test._id,
      ofDate: test.ofDate,
      name: test.name,
      testCode: test.testCode,
      subject: test.subject,
      testType: test.testType,
    };
  });

  const displayTests = assignedTests
    ? assignedTests
        .slice(pagesVisited, pagesVisited + testsPerPage)
        .map((test, index) => {
          if (fUser.role === "Student")
            return test.test != null ? (
              <StyledGrid key={index} item xs={12} sm={4}>
                <TestCourseCard
                  fUser={fUser}
                  id={test.test._id}
                  name={test.test.name}
                  ofDate={test.test.ofDate}
                  code={test.test.testCode}
                  subId={test.submissionId}
                  subject={test.test.subject}
                  duration={test.test.duration}
                  testStartTime={test.test.testStartTime}
                  testEndTime={test.test.testEndTime}
                  dirty={test.dirty}
                  restartable={test.restartable}
                  dq={test.dq}
                  testType={test.test.testType}
                  isOfExam={test.test.isOfExam}
                  examName={test.test.examName}
                  // topics={test.topics}
                  // grade={test.grade}
                />
              </StyledGrid>
            ) : null;
          else if (fUser.role === "Teacher" || fUser.role === "Institute")
            return (
              <StyledGrid key={index} item xs={12} sm={4}>
                <TestCourseCard
                  fUser={fUser}
                  id={test._id}
                  name={test.name}
                  ofDate={test.ofDate}
                  code={test.testCode}
                  subject={test.subject}
                  duration={test.duration}
                  testStartTime={test.testStartTime}
                  testEndTime={test.testEndTime}
                  testType={test.testType}
                  isOfExam={test.isOfExam}
                  examName={test.examName}
                  // subId={test.submissionId}
                  // topics={test.topics}
                  // grade={test.grade}
                />
              </StyledGrid>
            );
        })
    : [];

  const currentTime = new Date();
  const ctSeconds = currentTime.getTime();

  const { loading } = useQuery(studentGetExamQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      eId: exam ? exam._id : null,
    },
    skip: !fUser || fUser.role != "Student" || !paid || !state,
    onCompleted: ({ studentGetAExam }) => {
      setAssignedTests(studentGetAExam.tests);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });
  const { loading: userTestsLoading, refetch } = useQuery(userGetExamQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      userT: fUser ? fUser.role : null,
      eId: exam ? exam._id : null,
    },
    skip: !fUser || fUser.role == "Student" || !isCreator || !state,
    onCompleted: ({ userGetAExam }) => {
      setAssignedTests(userGetAExam.assignedTests);
      setOtherTests(userGetAExam.otherTests);
      setStudents(userGetAExam.regStudents);
      setUnselectedTests(userGetAExam.otherTests);
      let ro = {};
      for (let i = 0; i < userGetAExam.exam?.rounds; i++) {
        ro[i + 1] = { tests: [], passingCriterion: "" };
      }
      for (let i = 0; i < userGetAExam.assignedTests.length; i++) {
        if (userGetAExam.assignedTests[i].round) {
          ro[userGetAExam.assignedTests[i].round]["tests"].push(
            userGetAExam.assignedTests[i]
          );
          if (
            userGetAExam.assignedTests[i].passingCriterion &&
            !ro[userGetAExam.assignedTests[i].round]["passingCriterion"]
          ) {
            let ft = { ...userGetAExam.assignedTests[i] };
            ft = userGetAExam.assignedTests.find(
              (at) =>
                at._id.toString() ==
                userGetAExam.assignedTests[i].passingCriterion.toString()
            );
            ro[userGetAExam.assignedTests[i].round]["passingCriterion"] = ft;
          }
        }
      }
      setRoundsObject(ro);
    },
    onError: (error) => {
      alert("Something went wrong");
    },
  });
  const [
    UserAssignExamTestsMutation,
    { loading: assigning, error: errorAssigning, data: dataAssigning },
  ] = useMutation(userAssignExamTestsMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Assigned!");
      setSelModel([]);
      refetch();
    },
  });

  const assignTests = async () => {
    if (assigning || userTestsLoading) return;
    let testsToBackend = [];
    Object.keys(roundsObject).map((roundNo) => {
      for (let i = 0; i < roundsObject[roundNo]["tests"].length; i++) {
        testsToBackend.push({
          testId: roundsObject[roundNo]["tests"][i]["_id"],
          roundNo: parseInt(roundNo),
          passingCriterion: roundsObject[roundNo]["passingCriterion"]["_id"]
            ? roundsObject[roundNo]["passingCriterion"]["_id"]
            : "",
        });
      }
    });
    try {
      const callInfo = await UserAssignExamTestsMutation({
        variables: {
          input: {
            userType: fUser ? fUser.role : null,
            userId: fUser ? fUser._id : null,
            examId: exam ? exam._id : null,
            tests: testsToBackend,
          },
        },
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };
  function formatDate(input, time) {
    var datePart = input.match(/\d+/g),
      year = datePart[0].substring(2), // get only two digits
      month = datePart[1],
      day = datePart[2];

    const formattedDate = month + "/" + day + "/" + year;
    const formattedTime = new Date(`${formattedDate} ${time}`);
    const formattedSeconds = formattedTime.getTime();
    return formattedSeconds;
  }
  const formattedRegStudents = students?.map((studData) => {
    return {
      name: `${studData.student.portrait.alias.first} ${studData.student.portrait.alias.last}`,
      email: `${studData.student.portrait.pingAt.virtualAdd}`,
      phone: `${studData.student.portrait.pingAt.cell}`,
      grade: `${studData.student.portrait.grade}`,
      school: `${studData.student.portrait.academic?.schoolName}`,
      rType: `${
        studData.receipt?.paymentFor === "BULK" ? "BULK" : "INDIVIDUAL"
      }`,
      rName: `${studData.payer?.portrait.alias?.first} ${studData.payer?.portrait.alias?.last}`,
      rMobile: `${studData.payer?.portrait.pingAt?.cell}`,
      rEmail: `${studData.receipt?.from?.mail}`,
    };
  });

  const GetPossibleTests = (roundNo) => {
    let possibleTests = [];
    let j = 1;
    while (j < roundNo) {
      possibleTests = possibleTests.concat(roundsObject[j]["tests"]);
      j = j + 1;
    }
    return possibleTests;
  };
  const RegistrationButtons = () => {
    return (
      <ButtonDiv>
        <Link
          exact
          to={"/individualReg"}
          state={{
            exam: exam,
          }}
          style={{ textDecoration: "none" }}
        >
          <StyledButton variant="contained" size="medium">
            {fUser.role === "Student" ? "Register" : "Individual Registration"}
          </StyledButton>
        </Link>
        {fUser.role === "Teacher" || fUser.role === "Institute" ? (
          <ButtonDiv>
            <Link
              exact
              to={"/bulkReg"}
              state={{
                exam: exam,
              }}
              style={{ textDecoration: "none" }}
            >
              <StyledButton variant="contained" size="medium">
                Bulk Registration
              </StyledButton>
            </Link>
            <Link
              exact
              to={"/alreadyReg"}
              state={{
                exam: exam,
              }}
              style={{ textDecoration: "none" }}
            >
              <StyledButton variant="contained" size="medium">
                Already signed up Registration
              </StyledButton>
            </Link>
          </ButtonDiv>
        ) : null}
      </ButtonDiv>
    );
  };

  useEffect(() => {
    if (!state) navigate("/examList", { replace: true });
  }, [navigate, state]);

  if (!state) {
    return null;
  }
  return (
    <div>
      {NotificationsContainer()}
      {/* <StyledDialog
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
      >
        <StyledDialogContent>
          <br />
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              checkboxSelection
              loading={userTestsLoading}
              disableSelectionOnClick={assigning}
              onRowSelectionModelChange={(selectedRows) => {
                setSelModel(selectedRows);
              }}
              selectionModel={selModel ? Object.values(selModel) : []}
            />
          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
          >
            Cancel
          </StyledButton>

          <StyledButton
            variant="contained"
            disabled={selModel.length < 1 || userTestsLoading || assigning}
            onClick={async () => {
              await assignTests();
              setOpen(false);
            }}
          >
            Assign
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog> */}
      <StyledDialog
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
      >
        <StyledDialogContent>
          <br />
          {Object.keys(roundsObject).map((roundNo, index) => {
            return (
              <StyledCard key={roundNo}>
                <StyledTypography>ROUND {roundNo}</StyledTypography>
                <ColumnFlexDiv>
                  <RowToColumnFlexDiv style={{ width: "100%" }}>
                    <FiftyDiv style={{ textAlign: "end" }}>
                      <StyledInputLabel>Tests:</StyledInputLabel>
                    </FiftyDiv>
                    <FiftyDiv>
                      <StyledAutocomplete
                        multiple
                        options={unselectedTests}
                        disableCloseOnSelect
                        disabled={assigning}
                        value={roundsObject[roundNo]["tests"]}
                        onChange={(event, value) => {
                          let ro = { ...roundsObject };
                          if (ro[roundNo]["tests"].length > value.length) {
                            let removedTest = ro[roundNo]["tests"].filter(
                              (a) => !value.map((b) => b._id).includes(a._id)
                            )[0];
                            let sl = [...unselectedTests];
                            sl.push(removedTest);
                            setUnselectedTests(sl);
                          } else {
                            let addedTest = value.filter(
                              (a) =>
                                !ro[roundNo]["tests"]
                                  .map((b) => b._id)
                                  .includes(a._id)
                            )[0];
                            let sl = [...unselectedTests];
                            let newSl = sl.filter(
                              (a) =>
                                ![addedTest].map((b) => b._id).includes(a._id)
                            );
                            setUnselectedTests(newSl);
                          }
                          ro[roundNo]["tests"] = value;
                          setRoundsObject(ro);
                        }}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        renderInput={(params) => (
                          <StyledAutocompleteTextField
                            {...params}
                            disabled={assigning}
                          />
                        )}
                      />
                    </FiftyDiv>
                  </RowToColumnFlexDiv>

                  {roundNo != 1 ? (
                    <RowToColumnFlexDiv style={{ width: "100%" }}>
                      <FiftyDiv style={{ textAlign: "end" }}>
                        <StyledInputLabel>Passing Criterion:</StyledInputLabel>
                      </FiftyDiv>
                      <FiftyDiv>
                        <StyledAutocomplete
                          options={GetPossibleTests(roundNo)}
                          disabled={assigning}
                          value={roundsObject[roundNo]["passingCriterion"]}
                          onChange={(event, value) => {
                            let ro = { ...roundsObject };
                            ro[roundNo]["passingCriterion"] = value;
                            setRoundsObject(ro);
                          }}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          renderInput={(params) => (
                            <StyledAutocompleteTextField
                              {...params}
                              disabled={assigning}
                            />
                          )}
                        />
                      </FiftyDiv>
                    </RowToColumnFlexDiv>
                  ) : null}
                </ColumnFlexDiv>
              </StyledCard>
            );
          })}
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={() => {
              setOpen(false);
            }}
            variant="contained"
          >
            Cancel
          </StyledButton>

          <StyledButton
            variant="contained"
            disabled={userTestsLoading || assigning}
            onClick={async () => {
              await assignTests();
              setOpen(false);
            }}
          >
            Assign
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <br />
      {isCreator && (fUser.role === "Teacher" || fUser.role === "Institute") ? (
        <div>
          <StyledButton
            variant="contained"
            disabled={userTestsLoading || assigning}
            style={{ marginBottom: "10px" }}
            size="medium"
            onClick={() => {
              setOpen(true);
            }}
          >
            Assign Tests
          </StyledButton>
        </div>
      ) : null}
      {fUser.role === "Student" && paid ? (
        <StyledTypography>
          You have Successfully Registered for this Exam.
        </StyledTypography>
      ) : null}
      {!paid
        ? formatDate(exam.regStart, "00:00:00") <= ctSeconds &&
          ctSeconds < formatDate(exam.regEnd, "23:59:59")
          ? RegistrationButtons()
          : null
        : null}
      <div style={{ margin: "13px 7px 0 7px" }}>
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          disabled={true}
          data={exam.examInfo}
          // onReady={(editor) => {
          //   // You can store the "editor" and use when it is needed.
          //   console.log("Editor is ready to use!", editor);
          // }}
          // onChange={(event, editor) => {
          //   const data = editor.getData();
          //   // console.log({ event, editor, data });
          //   setValues({
          //     ...formValues,
          //     examInfo: data,
          //   });
          //   console.log("ei", data);
          // }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />
      </div>
      <div>
        <StyledTypography>Registration Open For {exam.name}</StyledTypography>
        <StyledTypography>
          Registration Fees: Rs.{exam.fees?.individual}/- Individual
          {fUser.role === "Teacher" || fUser.role === "Institute"
            ? ` , Rs.${exam.fees?.bulk}/- Bulk Per Student`
            : ``}
        </StyledTypography>
        <StyledTypography>
          Registrations open on {getFormattedDate(Date.parse(exam.regStart))}{" "}
          and will close on {getFormattedDate(Date.parse(exam.regEnd))}
        </StyledTypography>
        <StyledTypography>
          Grades :
          {exam.grade.map((item, index) => {
            return (
              <span key={index}>
                {item}
                {index != exam.grade.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </StyledTypography>
        <StyledTypography>
          Payment Mode: {exam.paymentMode?.join(" , ")}
        </StyledTypography>
        <StyledTypography>Payment Type: {exam.paymentType}</StyledTypography>
        {exam.paymentType === "PARTIAL / FULL" ? (
          <StyledTypography>
            Minimum Registration Fee: Rs.{exam.minimumFee}/- Individual
          </StyledTypography>
        ) : null}
        {exam.concession ? (
          <StyledTypography>
            Concession Type:{" "}
            {exam.concessionType?.map((item, index) => {
              return (
                <span key={index}>
                  {item}
                  {index != exam.concessionType.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </StyledTypography>
        ) : null}
        <StyledTypography>Rounds: {exam.rounds}</StyledTypography>
      </div>
      {!paid
        ? formatDate(exam.regStart, "00:00:00") <= ctSeconds &&
          ctSeconds < formatDate(exam.regEnd, "23:59:59")
          ? RegistrationButtons()
          : null
        : null}
      {paid || isCreator ? (
        <ListDiv>
          {fUser.role === "Student" ? (
            <StyledTypography>
              Please <span style={{ color: "red" }}>Refresh</span> the page if
              the Start Test StyledButton has not appeared at the Test Start
              Time
            </StyledTypography>
          ) : null}
          {assignedTests ? (
            <StyledGrid container spacing={2}>
              {displayTests}
            </StyledGrid>
          ) : null}
          {assignedTests.length != 0 ? (
            <ReactPaginate
              previousLabel={"←"}
              nextLabel={"→"}
              pageCount={pageCount}
              onPageChange={changepage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"paginationLink"}
              nextLinkClassName={"paginationLink"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          ) : null}
          <br />
        </ListDiv>
      ) : null}
      {isCreator ? (
        <div>
          <StyledTypography>Registered Students</StyledTypography>
          <TableParentDiv>
            <StyledTableContainer component={Paper}>
              <StyledTable aria-label="simple table">
                <StyledTableHead>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Name
                    </StyledTableCell>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Grade
                    </StyledTableCell>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      School/Institute
                    </StyledTableCell>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Registration Type
                    </StyledTableCell>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Mobile
                    </StyledTableCell>
                    <StyledTableCell>
                      <ExcelFile
                        element={
                          <StyledButton variant="contained" size="medium">
                            Download Data
                          </StyledButton>
                        }
                      >
                        <ExcelSheet
                          data={formattedRegStudents}
                          name="Registered Students"
                        >
                          <ExcelColumn label="Name" value="name" />
                          <ExcelColumn label="Email Id" value="email" />
                          <ExcelColumn label="Phone" value="phone" />
                          <ExcelColumn label="Grade" value="grade" />
                          <ExcelColumn
                            label="School/Institute Name"
                            value="school"
                          />
                          <ExcelColumn
                            label="Registration Type"
                            value="rType"
                          />
                          <ExcelColumn
                            label="Registration By - Name"
                            value="rName"
                          />
                          <ExcelColumn
                            label="Registration By - Mobile No."
                            value="rMobile"
                          />
                          <ExcelColumn
                            label="Registration By - Email Id"
                            value="rEmail"
                          />
                        </ExcelSheet>
                      </ExcelFile>
                    </StyledTableCell>
                  </StyledTableRow>
                </StyledTableHead>
                <StyledTableBody>
                  {/* {students.map((stud, index) => ( */}
                  {students.map((stud, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {stud?.student.portrait.alias.first}{" "}
                        {stud?.student.portrait.alias.last}
                      </StyledTableCell>
                      <StyledTableCell>
                        {stud?.student.portrait.grade}
                      </StyledTableCell>
                      <StyledTableCell>
                        {stud.student.portrait.academic?.schoolName
                          ? stud.student.portrait.academic?.schoolName
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {stud.receipt?.paymentFor === "BULK"
                          ? "BULK"
                          : "INDIVIDUAL"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {stud.student.portrait.pingAt.cell
                          ? stud.student.portrait.pingAt.cell
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell>
                        {
                          <Link
                            exact
                            to={"/studentProfile"}
                            state={{
                              id: stud?.student._id,
                              email: stud?.student.portrait.pingAt.virtualAdd,
                              // primaryTopic: props.primaryTopic,
                              // secondaryTopic: props.secondaryTopic,
                              // gradeLevel: props.gradeLevel,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Tooltip title="View">
                              <StyledButton
                                // variant="contained"
                                size="large"
                                startIcon={<Visibility />}
                              ></StyledButton>
                            </Tooltip>
                          </Link>
                        }
                        {
                          <Link
                            exact
                            to={"/manageRegistration"}
                            state={{
                              id: stud?.student._id,
                              exam: exam,
                              isCreator: isCreator,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <Tooltip title="View">
                              <StyledButton
                                // variant="contained"
                                size="large"
                              >
                                Manage
                              </StyledButton>
                            </Tooltip>
                          </Link>
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </StyledTableBody>
              </StyledTable>
            </StyledTableContainer>
          </TableParentDiv>
        </div>
      ) : null}
    </div>
  );
};

export default ExamInfo;
