import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  StyledTypography,
  StyledTextField,
  StyledButton,
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
  StyledBox,
  StyledChip,
  StyledCheckbox,
  StyledListItemText,
  TextAreaDiv,
  SelectDiv,
  RootDiv,
  TextAreaHeader,
  ButtonDiv,
} from "../../../css/StyledComponents";
import { loader } from "graphql.macro";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Attachment, Edit, Label } from "@mui/icons-material";

import { examsList } from "../../../constants/examsList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";

const teacherGetProfileQuery = loader(
  "../../../graphqlCalls/teacher/teacherGetProfile.gql"
);
const teacherEditProfileMutation = loader(
  "../../../graphqlCalls/teacher/TeacherEditProfile.gql"
);
const userGetTeacherQuery = loader(
  "../../../graphqlCalls/teacher/userGetATeacher.gql"
);
const teacherExistsQuery = loader(
  "../../../graphqlCalls/teacher/userTeachExists.gql"
);
const userEditTeacherMutation = loader(
  "../../../graphqlCalls/teacher/UserEditTeacher.gql"
);
const createTeacherMutation = loader(
  "../../../graphqlCalls/teacher/UserAddTeacher.gql"
);
const getTeachersQuery = loader(
  "../../../graphqlCalls/teacher/userGetAllTeachers.gql"
);

const initialFormValues = {
  teachId: "",
  category: "",
  firstName: "",
  middleName: "",
  lastName: "",
  tMail: "",
  email: "",
  mobileNo: "",
  address1: "",
  address2: "",
  pinCode: 0,
  instituteName: "",
  qualification: "",
  compiExams: [],
  bankName: "",
  branchName: "",
  accountNo: "",
  IFSCcode: "",
  docType: "",
  docNo: "",
  docFile64: "",
};

const TeacherProfile = (props) => {
  const fUser = props.fUser;
  const { state } = useLocation();

  const documentList = [
    "PAN Card",
    "AADHAAR Card",
    "Driving License",
    "Passport",
  ];
  const categoryList = [
    "Mathematics",
    "Physics",
    "Chemistry",
    "Biology",
    "English",
    "History",
    "Geography",
  ];

  const [newMode, setNewMode] = useState(true);
  const [teacherExists, setTeacherExists] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [formValues, setValues] = useState(initialFormValues);
  const [fileName, setFileName] = useState("");
  const navigate = useNavigate();

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    let fileSize = Math.round(target.files[0].size / 1024);
    if (fileSize > 2048) {
      alert("file size is more than " + 2 + " mb");
      return;
    }

    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setValues({
          ...formValues,
          docFile64: result,
        });
        setFileName(target.files[0]);
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };

  const [
    TeacherEditProfileMutation,
    {
      loading: editingProfile,
      error: errorEditingProfile,
      data: dataEditingProfile,
    },
  ] = useMutation(teacherEditProfileMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Edited!");
    },
  });
  const [
    CreateTeacherMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createTeacherMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert(
        err.message == "Error: Duplicate Email,User Already Exists!"
          ? err.message
          : "Something went wrong"
      );
    },
    onCompleted: () => {
      NotificationsSuccess("Submitted!");
    },
  });
  const [
    UserEditTeacherMutation,
    { loading: userEditing, error: errorEditing, data: dataEditing },
  ] = useMutation(userEditTeacherMutation, {
    fetchPolicy: "no-cache",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Edited!");
    },
  });

  const { data, error, loading, refetch, networkStatus } = useQuery(
    userGetTeacherQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        tId: state ? state.id : null,
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !state || !fUser || fUser.role != "Institute",
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userGetATeacher }) => {
        setNewMode(false);
        setValues({
          ...formValues,
          teachId: userGetATeacher.teacher._id,
          firstName: userGetATeacher.teacher.portrait.alias.first,
          middleName: userGetATeacher.teacher.portrait.alias.middle,
          lastName: userGetATeacher.teacher.portrait.alias.last,
          category: userGetATeacher.category,
          email: userGetATeacher.teacher.portrait.pingAt.virtualAdd,
          mobileNo: userGetATeacher.teacher.portrait.pingAt.cell,
          address1: userGetATeacher.teacher.portrait.address.line1,
          address2: userGetATeacher.teacher.portrait.address.line2,
          pinCode: userGetATeacher.teacher.portrait.address.pinCode,
          instituteName: userGetATeacher.teacher.portrait.institute.name,
          qualification:
            userGetATeacher.teacher.portrait.institute.qualification,
          bankName: userGetATeacher.teacher.portrait.bankDets.bankName,
          branchName: userGetATeacher.teacher.portrait.bankDets.branchName,
          accountNo: userGetATeacher.teacher.portrait.bankDets.accountNo,
          IFSCcode: userGetATeacher.teacher.portrait.bankDets.IFSCcode,
          docType: userGetATeacher.teacher.portrait.docType,
          docNo: userGetATeacher.teacher.portrait.docNo,
          docFile64: userGetATeacher.teacher.portrait.docFile64,
          // pastPerformance: [],
          compiExams: userGetATeacher.teacher.preferredCompiExam,
        });
      },
    }
  );

  const { teacherData } = useQuery(teacherGetProfileQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      tId: fUser ? fUser._id : null,
    },
    skip: !state || !fUser || fUser.role != "Teacher",
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: ({ teacherGetProfile }) => {
      setNewMode(false);
      setValues({
        ...formValues,
        teachId: teacherGetProfile._id,
        firstName: teacherGetProfile.portrait.alias.first,
        middleName: teacherGetProfile.portrait.alias.middle,
        lastName: teacherGetProfile.portrait.alias.last,
        email: teacherGetProfile.portrait.pingAt.virtualAdd,
        mobileNo: teacherGetProfile.portrait.pingAt.cell,
        address1: teacherGetProfile.portrait.address.line1,
        address2: teacherGetProfile.portrait.address.line2,
        pinCode: teacherGetProfile.portrait.address.pinCode,
        instituteName: teacherGetProfile.portrait.institute.name,
        qualification: teacherGetProfile.portrait.institute.qualification,
        docType: teacherGetProfile.portrait.docType,
        docNo: teacherGetProfile.portrait.docNo,
        docFile64: teacherGetProfile.portrait.docFile64,
        bankName: teacherGetProfile.portrait.bankDets.bankName,
        branchName: teacherGetProfile.portrait.bankDets.branchName,
        accountNo: teacherGetProfile.portrait.bankDets.accountNo,
        IFSCcode: teacherGetProfile.portrait.bankDets.IFSCcode,
        compiExams: teacherGetProfile.preferredCompiExam,
      });
    },
  });

  const [teacherExistsCheck, { loading: searching, data: teacherExistsData }] =
    useLazyQuery(teacherExistsQuery, {
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
        tMail: formValues.tMail,
      },
      onError: (err) => {
        alert("Something went wrong");
      },
      onCompleted: ({ userTeachExists }) => {
        if (userTeachExists) {
          setNewMode(false);
          setTeacherExists(true);
          userTeachExists.teachRegUnderInsti
            ? setEditMode(true)
            : setEditMode(false);
          setValues({
            ...formValues,
            teachId: userTeachExists.teachCat.teacher._id,
            firstName: userTeachExists.teachCat.teacher.portrait.alias.first,
            middleName: userTeachExists.teachCat.teacher.portrait.alias.middle,
            lastName: userTeachExists.teachCat.teacher.portrait.alias.last,
            category: userTeachExists.teachCat.category,
            email: userTeachExists.teachCat.teacher.portrait.pingAt.virtualAdd,
            mobileNo: userTeachExists.teachCat.teacher.portrait.pingAt.cell,
            address1: userTeachExists.teachCat.teacher.portrait.address.line1,
            address2: userTeachExists.teachCat.teacher.portrait.address.line2,
            pinCode: userTeachExists.teachCat.teacher.portrait.address.pinCode,
            instituteName:
              userTeachExists.teachCat.teacher.portrait.institute.name,
            qualification:
              userTeachExists.teachCat.teacher.portrait.institute.qualification,
            docType: userTeachExists.teachCat.teacher.portrait.docType,
            docNo: userTeachExists.teachCat.teacher.portrait.docNo,
            docFile64: userTeachExists.teachCat.teacher.portrait.docFile64,
            bankName:
              userTeachExists.teachCat.teacher.portrait.bankDets.bankName,
            branchName:
              userTeachExists.teachCat.teacher.portrait.bankDets.branchName,
            accountNo:
              userTeachExists.teachCat.teacher.portrait.bankDets.accountNo,
            IFSCcode:
              userTeachExists.teachCat.teacher.portrait.bankDets.IFSCcode,
            compiExams: userTeachExists.teachCat.teacher.preferredCompiExam,
          });
        } else {
          setTeacherExists(false);
        }
      },
    });
  const updateCacheCreating = (client, { data: { UserAddTeacher } }) => {
    const data = client.readQuery({
      query: getTeachersQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
      },
    });
    const newTeacher = UserAddTeacher;
    if (data) {
      const newTeacherList = {
        userGetAllTeachers: [newTeacher, ...data.userGetAllTeachers],
      };
      client.writeQuery({
        query: getTeachersQuery,
        variables: {
          userId: fUser._id,
          userT: fUser.role,
        },
        data: newTeacherList,
      });
    }
    setValues({
      ...formValues,
      teachId: UserAddTeacher.teacher._id,
    });
    setNewMode(false);
  };
  const submitContent = async () => {
    if (creating) return;
    try {
      const callInfo = await CreateTeacherMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            category: formValues.category,
            email: formValues.email,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            mobileNo: formValues.mobileNo,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            instituteName: formValues.instituteName,
            qualification: formValues.qualification,
            bankName: formValues.bankName,
            branchName: formValues.branchName,
            accountNo: formValues.accountNo,
            IFSCcode: formValues.IFSCcode,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
          },
        },

        update: updateCacheCreating,
      });
    } catch (error) {}
  };
  const updateCacheEditing = (client, { data: UserEditTeacher }) => {
    const wt = client.writeQuery({
      query: userGetTeacherQuery,
      variables: {
        userId: fUser._id,
        userT: fUser.role,
        tId: formValues.teachId,
      },
      data: { userGetATeacher: UserEditTeacher },
    });
    setEditMode(false);
  };
  const userEditContent = async () => {
    try {
      const callInfo = await UserEditTeacherMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            teacherId: formValues.teachId,
            category: formValues.category,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            mobileNo: formValues.mobileNo,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            instituteName: formValues.instituteName,
            qualification: formValues.qualification,
            bankName: formValues.bankName,
            branchName: formValues.branchName,
            accountNo: formValues.accountNo,
            IFSCcode: formValues.IFSCcode,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
          },
        },

        update: updateCacheEditing,
      });
    } catch (error) {}
  };

  const updateCacheTeacherEditing = (client, { data: TeacherEditProfile }) => {
    const wt = client.writeQuery({
      query: teacherGetProfileQuery,
      variables: {
        tId: fUser._id,
      },
      data: { teacherGetProfile: TeacherEditProfile },
    });
    setEditMode(false);
  };

  const teacherEditContent = async () => {
    try {
      const callInfo = await TeacherEditProfileMutation({
        variables: {
          input: {
            teacherId: fUser._id,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            mobileNo: formValues.mobileNo,
            address1: formValues.address1,
            address2: formValues.address2,
            pinCode: parseInt(formValues.pinCode),
            instituteName: formValues.instituteName,
            qualification: formValues.qualification,
            bankName: formValues.bankName,
            branchName: formValues.branchName,
            accountNo: formValues.accountNo,
            IFSCcode: formValues.IFSCcode,
            docType: formValues.docType,
            docNo: formValues.docNo,
            docFile64: formValues.docFile64,
            // pastPerformance: [],
            compiExams: formValues.compiExams,
            // topicData: [topicDataTypeIp],
          },
        },

        update: updateCacheTeacherEditing,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!state && fUser.role !== "Institute") navigate("/", { replace: true });
  }, [navigate, state]);

  if (!state) {
    if (fUser.role !== "Institute") {
      return null;
    }
  }
  return (
    <RootDiv>
      {NotificationsContainer()}
      <TextAreaHeader>
        <StyledTypography variant="h5" style={{ fontWeight: "bold" }}>
          Personal Details
        </StyledTypography>
        {!newMode ? (
          <StyledButton
            variant="contained"
            size="large"
            startIcon={<Edit />}
            onClick={() => {
              setEditMode(!editMode);
            }}
            disabled={loading || editingProfile || userEditing || creating}
          >
            Edit
          </StyledButton>
        ) : null}
      </TextAreaHeader>
      <TextAreaDiv>
        <StyledTextField
          name="email"
          label="Email ID:"
          value={formValues.email}
          onChange={handleInputChange}
          disabled={
            !newMode ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
      </TextAreaDiv>
      <TextAreaDiv>
        <StyledTextField
          name="mobileNo"
          value={formValues.mobileNo}
          onChange={handleInputChange}
          variant="filled"
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          label="Mobile No:"
        />
        {fUser.role === "Institute" ? (
          <SelectDiv>
            <StyledInputLabel shrink htmlFor="grade">
              Category:
            </StyledInputLabel>
            <StyledSelect
              name="category"
              value={formValues.category}
              disabled={
                (!editMode && !newMode) ||
                loading ||
                searching ||
                editingProfile ||
                userEditing ||
                creating
              }
              onChange={handleInputChange}
              style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
            >
              {categoryList.map((level, index) => {
                return (
                  <StyledMenuItem key={index} value={level}>
                    {level}
                  </StyledMenuItem>
                );
              })}
            </StyledSelect>
          </SelectDiv>
        ) : null}
      </TextAreaDiv>
      <TextAreaDiv>
        <StyledTextField
          name="firstName"
          label="First Name:"
          value={formValues.firstName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="middleName"
          label="Middle Name"
          value={formValues.middleName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="lastName"
          label="Last Name"
          value={formValues.lastName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
      </TextAreaDiv>
      <TextAreaDiv>
        <StyledTextField
          name="address1"
          label="Address Line 1:"
          value={formValues.address1}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="address2"
          label="Address Line 2:"
          value={formValues.address2}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="pinCode"
          label="Pin code:"
          type="number"
          value={formValues.pinCode}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
      </TextAreaDiv>
      <br />
      <br />
      <TextAreaHeader>
        <StyledTypography
          variant="h5"
          style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
        >
          Academic Details
        </StyledTypography>
      </TextAreaHeader>
      <TextAreaDiv>
        <StyledTextField
          name="instituteName"
          label="Institute Name:"
          value={formValues.instituteName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="qualification"
          label="Qualification:"
          value={formValues.qualification}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <SelectDiv>
          <StyledInputLabel shrink>Preferred Exams:</StyledInputLabel>
          <StyledSelect
            name="compiExams"
            multiple
            value={formValues.compiExams}
            onChange={handleInputChange}
            disabled={
              (!editMode && !newMode) ||
              loading ||
              searching ||
              editingProfile ||
              userEditing ||
              creating
            }
            style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
            renderValue={(selected) => (
              <StyledBox
                style={{ display: "flex", flexWrap: "wrap", padding: 0.7 }}
              >
                {selected.map((value) => (
                  <StyledChip key={value} label={value} style={{ margin: 1 }} />
                ))}
              </StyledBox>
            )}
          >
            {examsList.map((exam, index) => {
              return (
                <StyledMenuItem key={index} value={exam}>
                  <StyledCheckbox
                    checked={formValues.compiExams.indexOf(exam) > -1}
                  />
                  <StyledListItemText primary={exam} />
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </SelectDiv>
      </TextAreaDiv>
      <br />
      <br />
      <TextAreaHeader>
        <StyledTypography
          variant="h5"
          style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
        >
          Bank Details
        </StyledTypography>
      </TextAreaHeader>
      <TextAreaDiv>
        <StyledTextField
          name="bankName"
          label="Bank Name:"
          value={formValues.bankName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="branchName"
          label="Branch Name:"
          value={formValues.branchName}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <StyledTextField
          name="accountNo"
          label="Account Number:"
          value={formValues.accountNo}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
      </TextAreaDiv>
      <TextAreaDiv>
        <StyledTextField
          name="IFSCcode"
          label="IFSC Code:"
          value={formValues.IFSCcode}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
      </TextAreaDiv>
      <br />
      <br />
      <TextAreaHeader>
        <StyledTypography
          variant="h5"
          style={{ fontWeight: "bold", marginLeft: "1.2vw" }}
        >
          KYC Details
        </StyledTypography>
      </TextAreaHeader>
      <TextAreaDiv>
        <SelectDiv>
          <StyledInputLabel shrink>Document Type:</StyledInputLabel>
          <StyledSelect
            name="docType"
            value={formValues.docType}
            onChange={handleInputChange}
            disabled={
              (!editMode && !newMode) ||
              loading ||
              searching ||
              editingProfile ||
              userEditing ||
              creating
            }
            style={{ width: "100%", marginTop: "1vh", marginLeft: "1vw" }}
          >
            {documentList.map((document, index) => {
              return (
                <StyledMenuItem key={index} value={document}>
                  {document}
                </StyledMenuItem>
              );
            })}
          </StyledSelect>
        </SelectDiv>
        <StyledTextField
          name="docNo"
          label="Document Number:"
          value={formValues.docNo}
          onChange={handleInputChange}
          disabled={
            (!editMode && !newMode) ||
            loading ||
            searching ||
            editingProfile ||
            userEditing ||
            creating
          }
          variant="filled"
        />
        <div>
          <label
            for="file-upload"
            style={{
              margin: "0 0 2.5vh 0",
            }}
          >
            Upload PDF File or Image (size limit: 2mb):
          </label>
          <input
            type="file"
            id="file-upload"
            disabled={
              (!editMode && !newMode) ||
              loading ||
              searching ||
              editingProfile ||
              userEditing ||
              creating
            }
            accept="application/pdf,image/*"
            // hidden
            name="filetobase64"
            onChange={onUploadFileChange}
          />
        </div>
        {formValues.docFile64 && formValues.docFile64 != "" ? (
          <a href={formValues.docFile64} download="file">
            Download KYC Document
          </a>
        ) : null}
      </TextAreaDiv>
      <br />
      <br />
      <ButtonDiv>
        {newMode ? (
          <StyledButton
            variant="contained"
            color="primary"
            disabled={
              loading || searching || editingProfile || userEditing || creating
            }
            // startIcon={<SaveIcon />}
            // style={{ top: "200px", left: "615px" }}
            size="medium"
            onClick={submitContent}
          >
            Submit
          </StyledButton>
        ) : null}
        {editMode ? (
          <StyledButton
            variant="contained"
            disabled={loading || editingProfile || userEditing || creating}
            onClick={
              fUser.role === "Institute" ? userEditContent : teacherEditContent
            }
          >
            Submit
          </StyledButton>
        ) : null}
      </ButtonDiv>
    </RootDiv>
  );
};

export default TeacherProfile;
