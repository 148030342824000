import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default (
  fUser,
  amount,
  description,
  to,
  from,
  state,
  pincode,
  GSTNo,
  paymentId,
  startDate,
  endDate
) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;
  // console.log(
  //   fUser,
  //   amount,
  //   description,
  //   to,
  //   from,
  //   state,
  //   pincode,
  //   GSTNo,
  //   paymentId,
  //   startDate,
  //   endDate
  // );

  const getCgst = () => {
    if (state == "MAHARASHTRA") return (100 * 9) / amount;
    else return 0.0;
  };
  const getSgst = () => {
    if (state == "MAHARASHTRA") return (100 * 9) / amount;
    else return 0.0;
  };
  const getIgst = () => {
    if (state != "MAHARASHTRA") return (100 * 18) / amount;
    else return 0.0;
  };
  const getTotal = () => {
    return getCgst() + getSgst() + getIgst();
  };

  //   let startDate = new Date();
  //   let eDate = new Date();
  //   let subscriptionDays = 365;
  //   let result = eDate.setDate(eDate.getDate() + subscriptionDays);
  //   let endDate = new Date(result);

  const dd = {
    content: [
      { text: "Billing Invoice", style: "header" },
      " ",
      {
        alignment: "justify",
        columns: [
          {
            text: [
              { text: "To,\n", style: "subheader" },
              // {
              //   text: "Fenlei Technology Private Limited\n",
              //   style: "textContent",
              // },
              // "Pune, Maharashtra 411041\n",
              // "accounts@fenleitech.io\n",
              // " \n",
              {
                text: `${to.name}\n`,
                style: "textContent",
              },
              to.address?.line1 ? `${to.address?.line1}\n` : "",
              to.address?.line2 ? `${to.address?.line2}\n` : "",
              to.address?.pinCode ? `${to.address?.pinCode}\n` : "",
            ], //text content wrapper
          }, //text wrapper
          {
            text: [
              { text: "From,\n", style: "subheader" },
              { text: `${from.name}\n`, style: "textContent" },
              state && state != "" ? `${state}\n` : "",
              pincode && pincode != "" ? `${pincode}\n` : "",
              `${from.mail}\n`,
              GSTNo && GSTNo != "" ? `GSTIN:${GSTNo}\n` : "",
            ],
          },
        ], //coloumn
      }, //column wrapper
      " \n",
      {
        text: [
          { text: "Date: ", style: "subheader" },
          {
            text: `${startDate}`,
            style: "textContent",
          },
        ],
      },
      " \n",
      {
        text: [
          { text: "Reference No: ", style: "subheader" },
          { text: `${paymentId} \n`, style: "textContent" },
        ],
      },
      " \n",
      {
        style: "tableContent",
        table: {
          widths: [50, 300, 150],
          headerRows: 1,
          body: [
            [
              { text: "Sr No.", style: "tableHeader", alignment: "center" },
              {
                text: "Description",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Amount(INR)",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            [
              { text: "1", alignment: "center" },
              {
                text: `${description}`,
                alignment: "center",
              },
              { text: `${amount}`, alignment: "center" },
            ],
            // {to.GSTNo?
            // {[
            //   " ",
            //   { text: "C.G.S.T.:", alignment: "right" },
            //   { text: `${getCgst()}`, alignment: "right" },
            // ],
            // [
            //   " ",
            //   { text: "S.G.S.T.:", alignment: "right" },
            //   { text: `${getSgst()}`, alignment: "right" },
            // ],
            // [
            //   " ",
            //   { text: "I.G.S.T.:", alignment: "right" },
            //   { text: `${getIgst()}`, alignment: "right" },
            // ],
            // [
            //   " ",
            //   { text: "Total:", alignment: "right", style: "tableSubheader" },
            //   {
            //     text: `${getTotal()}`,
            //     alignment: "right",
            //     style: "tableSubheader",
            //   },
            // ],}:null}
          ],
        },
      },
      {
        text: "*This is an electronically generated invoice, no signature needed.",
        style: ["quote", "small"],
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      textContent: {
        fontSize: 15,
      },
      subheader: {
        fontSize: 15,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableContent: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      tableSubheader: {
        bold: true,
        // fontSize: 12,
        color: "black",
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 10,
      },
    },
    defaultStyle: {
      columnGap: 60,
    },
  };

  pdfMake.createPdf(dd).open();
};
