import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl } from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "../../../ckEditor/build/ckeditor";

import "./ckeditorStyles.css";

import {
  StyledTypography,
  StyledTextField,
  StyledButton,
  StyledSelect,
  StyledInputLabel,
  StyledMenuItem,
  StyledBox,
  StyledChip,
  StyledCheckbox,
  StyledFormHelperText,
  StyledListItemText,
  RootDiv,
  TextAreaDiv,
  SelectDiv,
  ButtonDiv,
} from "../../css/StyledComponents";

import { gradesList } from "../../constants/gradesList";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";
// import { convertToRaw, EditorState } from "draft-js";
// import "draft-js/dist/Draft.css";
// import { Editor } from "react-draft-wysiwyg";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CreateExamValidationForm from "../validations/CreateExamValidation";

const userSaveNewExamMutation = loader(
  "../../graphqlCalls/exam/UserSaveNewExam.gql"
);

const initialFormValues = {
  examName: "",
  examInfo: "",
  regStart: "",
  regEnd: "",
  grades: [],
  paymentType: "FULL",
  paymentMode: ["ONLINE"],
  concession: false,
  concessionType: [],
  minimumFee: 0,
  individualFee: 0,
  bulkFee: 0,
  rounds: 1,
};
const editorConfiguration = {
  toolbar: [
    "insertTable",
    "bulletedList",
    "numberedList",
    "Alignment",
    "Autoformat",
    "AutoImage",
    "AutoLink",
    "Autosave",
    "Base64UploadAdapter",
    "BlockQuote",
    "Bold",
    "Italic",
    "Essentials",
    "FindAndReplace",
    "FontBackgroundColor",
    "FontColor",
    "FontFamily",
    "FontSize",
    "Heading",
    "Highlight",
    "HorizontalLine",
    "Image",
    "ImageCaption",
    "ImageInsert",
    "ImageResize",
    "ImageStyle",
    "ImageToolbar",
    "ImageUpload",
    "Indent",
    "Link",
    "LinkImage",
    "List",
    "ListProperties",
    "MediaEmbed",
    "PageBreak",
    "Paragraph",
    "PasteFromOffice",
    "SpecialCharacters",
    "SpecialCharactersArrows",
    "SpecialCharactersCurrency",
    "Strikethrough",
    // "Style",
    "Subscript",
    "Superscript",
    "Table",
    "TableCaption",
    // "TableCellProperties",
    "TableColumnResize",
    // "TableProperties",
    "TableToolbar",
    "TextTransformation",
    "Title",
    "Underline",
  ],
  placeholder: "Write registration information...",
};
const CreateExam = (props) => {
  const fUser = props.fUser;

  const [formValues, setValues] = useState(initialFormValues);
  // const [editorState, setEditorState] = useState(() =>
  //   EditorState.createEmpty()
  // );
  const navigate = useNavigate();

  const editor = useRef(null);
  const focusEditor = () => {
    editor.current.focus();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
    // console.log(value);
  };

  const validationSchema = CreateExamValidationForm;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // const onEditorChange = (editorStateIp) => {
  //   const contentState = editorStateIp.getCurrentContent();
  //   // console.log("content state", convertToRaw(contentState));
  //   setValues({
  //     ...formValues,
  //     examInfo: JSON.stringify(convertToRaw(contentState)),
  //   });
  //   // setEditorState(editorState);
  // };

  const UploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onload = (e) => resolve({ data: { link: e.target.result } });
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  const [UserSaveNewExamMutation, { loading: savingExam, error, data }] =
    useMutation(userSaveNewExamMutation, {
      fetchPolicy: "no-cache",
      onError: (err) => {
        console.log(JSON.stringify(err, null, 2));
      },
      onCompleted: ({ UserSaveNewExam }) => {
        NotificationsSuccess("Exam Created!");
        navigate("/examInfo", {
          state: {
            exam: UserSaveNewExam,
            paid: false,
            isCreator: true,
          },
        });
      },
    });

  const userCreateExam = async () => {
    if (savingExam) return;

    try {
      const callInfo = await UserSaveNewExamMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            exam: {
              name: formValues.examName,
              examInfo: formValues.examInfo,
              regStart: formValues.regStart,
              regEnd: formValues.regEnd,
              grade: formValues.grades,
              paymentType: formValues.paymentType,
              paymentMode: formValues.paymentMode,
              concession: formValues.concession,
              concessionType: formValues.concessionType,
              minimumFee: parseInt(formValues.minimumFee),
              rounds: parseInt(formValues.rounds),
              fees: {
                individual: parseInt(formValues.individualFee),
                bulk: parseInt(formValues.bulkFee),
              },
            },
          },
        },
      });
    } catch (error) {
      alert("Something went wrong");
    }
  };
  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  return (
    <RootDiv>
      {NotificationsContainer()}
      <StyledTypography
        variant="h5"
        style={{
          fontWeight: "bold",
          marginLeft: 7,
          justifyContent: "center",
          display: "flex",
        }}
      >
        Registration Details
      </StyledTypography>
      <TextAreaDiv>
        <FormControl
          {...register("examName")}
          error={errors.examName ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                name="examName"
                label="Registration Name:"
                value={formValues.examName}
                onChange={handleInputChange}
                disabled={savingExam}
                variant="filled"
              />
            )}
            control={control}
            name="examName"
          />
          <StyledFormHelperText>
            {errors.examName?.message}
          </StyledFormHelperText>
        </FormControl>
        <FormControl
          {...register("regStart")}
          error={errors.regStart ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                name="regStart"
                label="Registration Start Date:"
                value={formValues.regStart}
                onChange={handleInputChange}
                disabled={savingExam}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            )}
            control={control}
            name="regStart"
          />
          <StyledFormHelperText>
            {errors.regStart?.message}
          </StyledFormHelperText>
        </FormControl>
        <FormControl
          {...register("regEnd")}
          error={errors.regEnd ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                name="regEnd"
                label="Registration End Date:"
                value={formValues.regEnd}
                onChange={handleInputChange}
                disabled={savingExam}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            )}
            control={control}
            name="regEnd"
          />
          <StyledFormHelperText>{errors.regEnd?.message}</StyledFormHelperText>
        </FormControl>
      </TextAreaDiv>
      <TextAreaDiv>
        <FormControl
          {...register("individualFee")}
          error={errors.individualFee ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                type="number"
                name="individualFee"
                label="Individual Fee:"
                value={formValues.individualFee}
                onChange={handleInputChange}
                disabled={savingExam}
                variant="filled"
              />
            )}
            control={control}
            name="individualFee"
          />
          <StyledFormHelperText>
            {errors.individualFee?.message}
          </StyledFormHelperText>
        </FormControl>
        <FormControl
          {...register("bulkFee")}
          error={errors.bulkFee ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                type="number"
                name="bulkFee"
                label="Bulk Fee(Per Individual):"
                value={formValues.bulkFee}
                onChange={handleInputChange}
                disabled={savingExam}
                variant="filled"
              />
            )}
            control={control}
            name="bulkFee"
          />
          <StyledFormHelperText>{errors.bulkFee?.message}</StyledFormHelperText>
        </FormControl>
        <SelectDiv>
          <FormControl
            {...register("grades")}
            error={errors.grades ? true : false}
          >
            <StyledInputLabel style={{ margin: 0 }}>Grade:</StyledInputLabel>
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledSelect
                  {...field}
                  name="grades"
                  id="grade"
                  multiple
                  value={formValues.grades}
                  onChange={handleInputChange}
                  renderValue={(selected) => (
                    <StyledBox
                    // style={{
                    //   display: "flex",
                    //   flexWrap: "wrap",
                    //   padding: 0.7,
                    // }}
                    >
                      {selected.map((value) => (
                        <StyledChip key={value} label={value} />
                      ))}
                    </StyledBox>
                  )}
                  MenuProps={MenuProps}
                >
                  {gradesList.map((grade, index) => {
                    return (
                      <StyledMenuItem key={index} value={grade}>
                        <StyledCheckbox
                          checked={formValues.grades.indexOf(grade) > -1}
                        />
                        <StyledListItemText primary={grade} />
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              control={control}
              name="grades"
            />
            <StyledFormHelperText>
              {errors.grades?.message}
            </StyledFormHelperText>
          </FormControl>
        </SelectDiv>
      </TextAreaDiv>
      <TextAreaDiv>
        <SelectDiv style={{ marginRight: 14 }}>
          <FormControl
            {...register("paymentMode")}
            error={errors.paymentMode ? true : false}
          >
            <StyledInputLabel style={{ margin: 0 }}>
              Payment Mode:
            </StyledInputLabel>
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledSelect
                  {...field}
                  name="paymentMode"
                  id="paymentMode"
                  multiple
                  value={formValues.paymentMode}
                  onChange={handleInputChange}
                  renderValue={(selected) => (
                    <StyledBox
                    // style={{
                    //   display: "flex",
                    //   flexWrap: "wrap",
                    //   padding: 0.7,
                    // }}
                    >
                      {selected.map((value) => (
                        <StyledChip key={value} label={value} />
                      ))}
                    </StyledBox>
                  )}
                  MenuProps={MenuProps}
                >
                  {["ONLINE", "OFFLINE"].map((pMode, index) => {
                    return (
                      <StyledMenuItem key={index} value={pMode}>
                        <StyledCheckbox
                          checked={formValues.paymentMode.indexOf(pMode) > -1}
                        />
                        <StyledListItemText primary={pMode} />
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              control={control}
              name="paymentMode"
            />
            <StyledFormHelperText>
              {errors.paymentMode?.message}
            </StyledFormHelperText>
          </FormControl>
        </SelectDiv>
        <SelectDiv style={{ marginRight: 14 }}>
          <FormControl
            {...register("paymentType")}
            error={errors.paymentType ? true : false}
          >
            <StyledInputLabel style={{ margin: 0 }}>
              Payment Type:
            </StyledInputLabel>
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledSelect
                  {...field}
                  name="paymentType"
                  id="paymentType"
                  value={formValues.paymentType}
                  onChange={handleInputChange}
                >
                  {["FULL", "PARTIAL / FULL"].map((pType, index) => {
                    return (
                      <StyledMenuItem key={index} value={pType}>
                        {pType}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              control={control}
              name="paymentType"
            />
            <StyledFormHelperText>
              {errors.paymentType?.message}
            </StyledFormHelperText>
          </FormControl>
        </SelectDiv>
        {formValues.paymentType === "PARTIAL / FULL" ? (
          <FormControl
            {...register("minimumFee")}
            error={errors.minimumFee ? true : false}
          >
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledTextField
                  {...field}
                  type="number"
                  name="minimumFee"
                  label="Minimum Fee for partial payment:"
                  value={formValues.minimumFee}
                  onChange={handleInputChange}
                  disabled={savingExam}
                  variant="filled"
                />
              )}
              control={control}
              name="minimumFee"
            />
            <StyledFormHelperText>
              {errors.minimumFee?.message}
            </StyledFormHelperText>
          </FormControl>
        ) : null}
      </TextAreaDiv>
      <TextAreaDiv>
        <SelectDiv style={{ marginRight: 14 }}>
          <FormControl
            {...register("concession")}
            error={errors.concession ? true : false}
          >
            <StyledInputLabel style={{ margin: 0 }}>
              Concession Allowed:
            </StyledInputLabel>
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledSelect
                  {...field}
                  name="concession"
                  id="concession"
                  value={formValues.concession}
                  onChange={handleInputChange}
                >
                  {[
                    { value: true, label: "YES" },
                    { value: false, label: "NO" },
                  ].map((con, index) => {
                    return (
                      <StyledMenuItem key={index} value={con.value}>
                        {con.label}
                      </StyledMenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              control={control}
              name="concession"
            />
            <StyledFormHelperText>
              {errors.concession?.message}
            </StyledFormHelperText>
          </FormControl>
        </SelectDiv>
        {formValues.concession ? (
          <SelectDiv style={{ marginRight: 14 }}>
            <FormControl
              {...register("concessionType")}
              error={errors.concessionType ? true : false}
            >
              <StyledInputLabel style={{ margin: 0 }}>
                Concession Type:
              </StyledInputLabel>
              <Controller
                render={({ field: { onChange, value, name, ...field } }) => (
                  <StyledSelect
                    {...field}
                    name="concessionType"
                    id="concessionType"
                    multiple
                    value={formValues.concessionType}
                    onChange={handleInputChange}
                    renderValue={(selected) => (
                      <StyledBox
                      // style={{
                      //   display: "flex",
                      //   flexWrap: "wrap",
                      //   padding: 0.7,
                      // }}
                      >
                        {selected.map((value) => (
                          <StyledChip key={value} label={value} />
                        ))}
                      </StyledBox>
                    )}
                    MenuProps={MenuProps}
                  >
                    {[
                      "Merit Based Concession",
                      "Need Based Concession",
                      "Cultural and Sports Based Concession",
                      "Caste/Community Based Concession",
                      "Special Category Concession",
                      "Other Concession",
                    ].map((conType, index) => {
                      return (
                        <StyledMenuItem key={index} value={conType}>
                          <StyledCheckbox
                            checked={
                              formValues.concessionType.indexOf(conType) > -1
                            }
                          />
                          <StyledListItemText primary={conType} />
                        </StyledMenuItem>
                      );
                    })}
                  </StyledSelect>
                )}
                control={control}
                name="concessionType"
              />
              <StyledFormHelperText>
                {errors.concessionType?.message}
              </StyledFormHelperText>
            </FormControl>
          </SelectDiv>
        ) : null}
      </TextAreaDiv>
      <TextAreaDiv>
        <FormControl
          {...register("rounds")}
          error={errors.rounds ? true : false}
        >
          <Controller
            render={({ field: { onChange, value, name, ...field } }) => (
              <StyledTextField
                {...field}
                type="number"
                name="rounds"
                label="Number of Rounds:"
                value={formValues.rounds}
                onChange={handleInputChange}
                disabled={savingExam}
                variant="filled"
              />
            )}
            control={control}
            name="rounds"
          />
          <StyledFormHelperText>{errors.rounds?.message}</StyledFormHelperText>
        </FormControl>
      </TextAreaDiv>
      <br />
      <br />
      <div
        style={{
          // border: "1px solid black",
          minHeight: "50vh",
          cursor: "text",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          // margin: "13px 13px 0 13px",
        }}
        // onClick={focusEditor}
      >
        {/* <Editor
          ref={editor}
          // wrapperClassName="wrapper-class"
          // editorClassName="editor-class"
          // toolbarClassName="toolbar-class"
          editorState={editorState}
          onEditorStateChange={(editorState) => {
            setEditorState(editorState);
            onEditorChange(editorState);
          }}
          // toolbar={{
          //   image: { urlEnabled: false, uploadEnabled: true },
          // }}
          toolbar={{
            // inline: { inDropdown: true },
            // list: { inDropdown: true },
            // textAlign: { inDropdown: true },
            // link: { inDropdown: true },
            // history: { inDropdown: true },
            image: { urlEnabled: true, uploadEnabled: true },
          }}
          // onChange={onEditorChange}
          placeholder="Write exam info..."
        /> */}
        <CKEditor
          editor={Editor}
          config={editorConfiguration}
          data={formValues.examInfo}
          // onReady={(editor) => {
          //   // You can store the "editor" and use when it is needed.
          //   console.log("Editor is ready to use!", editor);
          // }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            setValues({
              ...formValues,
              examInfo: data,
            });
            // console.log("ei", data);
          }}
          // onBlur={(event, editor) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event, editor) => {
          //   console.log("Focus.", editor);
          // }}
        />
      </div>
      <br />
      <br />
      <ButtonDiv>
        <StyledButton
          variant="contained"
          disabled={savingExam}
          onClick={
            // handleSubmit(onSubmit)
            handleSubmit(userCreateExam)
          }
        >
          Submit
        </StyledButton>
      </ButtonDiv>
    </RootDiv>
  );
};

export default CreateExam;
