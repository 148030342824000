import React, { useState } from "react";
import { Button } from "@mui/material";

import { RootDiv, StyledGrid } from "../../css/StyledComponents";
import Header from "../header/Header";
import QuestionList from "./questionList/QuestionList";

const QuestionBank = ({ fUser }) => {
  const flag = 5;
  const [filterDialog, setFilterDialog] = useState(false);

  return (
    <RootDiv>
      <Header
        flag={flag}
        filterDialog={filterDialog}
        setFilterDialog={setFilterDialog}
      />

      <StyledGrid item xs={12} sm={12}>
        <QuestionList
          fUser={fUser}
          filterDialog={filterDialog}
          setFilterDialog={setFilterDialog}
        />
      </StyledGrid>
    </RootDiv>
  );
};

export default QuestionBank;
