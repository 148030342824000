import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthenticator } from "@aws-amplify/ui-react";

import {
  RootDiv,
  InnerDiv,
  StyledButton,
  StyledTextField,
  StyledPara,
  StyledSpan,
  StyledFormHelperText,
  StyledTypography,
} from "../../css/StyledAuthComponents";

import ForgotPasswordValidationForm from "../validations/ForgotPasswordValidation";

export default function ForgotPassword(props) {
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");

  const { toSignIn } = useAuthenticator();

  const validationSchema = ForgotPasswordValidationForm;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    context: { codeSent: codeSent },
  });

  function getCode() {
    resetPassword(username)
      .then(() => {
        // console.log("awsUser", awsUser);
        // awsUser.name = awsUser?.challengeParam?.userAttributes?.name;
        // console.log(awsUser);
        alert("The verification code has been mailed to you");
        setCodeSent(true);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(JSON.stringify(error, null, 2));
        // Alert.alert(`${error}`);
        switch (error.code) {
          case "UserNotFoundException":
            alert("User Not Found");
            break;
          case "NotAuthorizedException":
            alert("Please Restart Process");
            // toSignIn();
            props.setAuthPage("SIGN_IN");
            break;
          default:
            alert("Something went wrong. Please contact admin.");
        }
      });
  }
  function submitNewPassword() {
    //   console.log(user);
    confirmResetPassword(username, code, newPassword)
      .then((awsUser) => {
        // console.log("✅ Successfully set new password");
        // console.log("tempAwsUser", tempAwsUser);
        // console.log("awsUser", awsUser);
        alert("Please Sign In with your new password");
        // toSignIn();
        props.setAuthPage("SIGN_IN");
      })
      .catch((error) => {
        // console.log(error);
        // console.log(JSON.stringify(error, null, 2));
        // Alert.alert(`${error}`);
        switch (error.code) {
          case "UserNotFoundException":
            alert("User Not Found");
            // toSignIn();
            props.setAuthPage("SIGN_IN");
            break;
          case "CodeMismatchException":
            alert("Wrong Verification Code");
            break;
          case "ExpiredCodeException":
            alert("Your code has expired. Please Restart Process");
            // toSignIn();
            props.setAuthPage("SIGN_IN");
            break;
          case "InvalidPasswordException":
            alert("Invalid Password Format");
            break;
          case "NotAuthorizedException":
            alert("Please Restart Process");
            // toSignIn();
            props.setAuthPage("SIGN_IN");
            break;
          default:
            alert("Something went wrong. Please contact admin.");
        }
      });
  }
  return (
    <RootDiv>
      <InnerDiv>
        <form>
          <StyledTypography
            style={{
              color: "#1f5156",
              fontSize: "1.15rem",
              fontWeight: 700,
              marginBottom: "0.75rem",
              display: "inline-block",
            }}
          >
            Welcome to GyanSopan
          </StyledTypography>
          {codeSent === true ? (
            <div>
              <FormControl
                {...register("code")}
                error={errors.code ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="code"
                      variant="outlined"
                      label="Verification Code"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      required
                    />
                  )}
                  control={control}
                  name="code"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.code?.message}
                </StyledFormHelperText>
              </FormControl>
              <StyledFormHelperText style={{ color: "red", marginTop: "10px" }}>
                Type a new password of your choice
              </StyledFormHelperText>
              <FormControl
                {...register("newPassword")}
                error={errors.newPassword ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      type="password"
                      name="newPassword"
                      variant="outlined"
                      label="New Password"
                      value={newPassword}
                      onChange={(event) => setNewPassword(event.target.value)}
                      required
                    />
                  )}
                  control={control}
                  name="newPassword"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.newPassword?.message}
                </StyledFormHelperText>
              </FormControl>
              <FormControl
                {...register("confirmNewPassword")}
                error={errors.confirmNewPassword ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      type="password"
                      name="confirmNewPassword"
                      variant="outlined"
                      label="Confirm New Password"
                      value={confirmNewPassword}
                      onChange={(event) =>
                        setConfirmNewPassword(event.target.value)
                      }
                      required
                    />
                  )}
                  control={control}
                  name="confirmNewPassword"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.confirmNewPassword?.message}
                </StyledFormHelperText>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  flexDirection: "column",
                }}
              >
                <StyledPara>
                  <StyledSpan
                    onClick={() => {
                      // toSignIn();
                      props.setAuthPage("SIGN_IN");
                    }}
                  >
                    Back to Sign In
                  </StyledSpan>
                </StyledPara>
                <StyledButton
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(submitNewPassword)}
                >
                  Submit
                </StyledButton>
              </div>
            </div>
          ) : (
            <div>
              <FormControl
                {...register("username")}
                error={errors.username ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="username"
                      variant="outlined"
                      label="User Name(Email)"
                      value={username}
                      onChange={(event) => setUsername(event.target.value)}
                      required
                    />
                  )}
                  control={control}
                  name="username"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.username?.message}
                </StyledFormHelperText>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px",
                  flexDirection: "column",
                }}
              >
                <StyledPara>
                  <StyledSpan
                    onClick={() => {
                      // toSignIn();
                      props.setAuthPage("SIGN_IN");
                    }}
                  >
                    Back to Sign In
                  </StyledSpan>
                </StyledPara>
                <StyledButton
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(getCode)}
                >
                  Submit
                </StyledButton>
              </div>
            </div>
          )}
        </form>
        <br />
        <br />
      </InnerDiv>
    </RootDiv>
  );
}
