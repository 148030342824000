import React from "react";
import { Typography } from "@mui/material";

const bPrim = () => {
  return (
    <div style={{ textAlign: "justify", margin: 20 }}>
      <Typography
        variant="h5"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        Frequently Asked Questions
      </Typography>{" "}
      <br />
      <br />
      <Typography variant="h6">
        <b>1. What is BMTSC?</b>
      </Typography>
      <br />
      <Typography style={{ fontSize: 18 }}>
        BMTSC stands for Bhaskaracharya Mathematics Talent Search Competition.
        This is a national level Mathematics competition for 5th and 6th
        standard students. This competition has been held since 2014.
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>2. What are the benefits of participating in BMTSC?</b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        BMTSC aims to enhance and nurture the problem solving ability and
        logical thinking of the students from an early age. The challenging and
        interesting problems in the competition encourage students to get
        introduced and to prepare for Mathematical Olympiad Competitions such as
        Pre-RMO, RMO, etc..
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>
          3. What are the accomplishments of students who completed the BMTSC
          successfully?
        </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        Students who were selected through BMTSC in previous years have done
        exceedingly well. With the help of Bhaskaracharya Pratishthana Training
        programme, Mahaveer Gandhi (BMTSC winner 2014) and Shaunak Ranade (BMTSC
        winner 2015) both have cracked the INMO (Indian National Mathematical
        Olympiad). This has enabled the Bhaskaracharya Pratishthana to train
        lots of students from std. V to XII for this prestigious examination.
        More than 50 medals have been received by high school students who
        received training at BP in the International Mathematical Olympiad.
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>4. How can I enroll for BMTSC? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        Please follow these steps for BMTSC enrollment:
        <ul typeof="1">
          <li>Sign up yourself using your authorized Email id.</li>
          <li>
            During sign up you may requested to provide your First name, Last
            name, etc..
          </li>
          <li>
            On successful submission of sign up form, you will receive an email
            from <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>{" "}
            for further verification.
          </li>
          <li>
            On successful email verification you will get the access to the
            Bhaskaracharya Pratishthana GyanSopan application.
          </li>
          <li>Sign in to your GyanSopan account.</li>
          <li>Click on Registration tab.</li>
          <li>
            You will find BMTSC 2023-2024 under examination list, mentioning the
            Institute name as Bhaskaracharya Pratishthana.
          </li>
          <li>Click on view button to proceed further.</li>
          <li>
            You will find detailed information of BMTSC 2023-2024, including the
            syllabus and registration fees.
          </li>
          <li>
            Click on REGISTER button which will require your ID proof such as
            School ID card, AADHAAR card, Recent school fee receipt.
          </li>
          <li>Make the payment to confirm your registration.</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>5. How can I do Bulk(Group) Registration for BMTSC through teacher or institution? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        Please follow these steps for BMTSC Bulk Registration:
        <ul typeof="1">
          <li>
            Sign up yourself using your authorized Email id as a Teacher or an
            Institute.
          </li>
          <li>
            During sign up you may requested to provide your First name, Last
            name, etc..
          </li>
          <li>
            On successful submission of sign up form, you will receive an email
            from <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>{" "}
            for further verification.
          </li>
          <li>
            On successful email verification you will get the access to the
            Bhaskaracharya Pratishthana GyanSopan application.
          </li>
          <li>Sign in to your GyanSopan account.</li>
          <li>Click on Registration tab.</li>
          <li>
            You will find BMTSC 2023-2024 under examination list, mentioning the
            Institute name as Bhaskaracharya Pratishthana.
          </li>
          <li>Click on view button to proceed further.</li>
          <li>
            You will find detailed information of BMTSC 2023-2024, including the
            syllabus and registration fees.
          </li>
          <li>
            Click on BULK REGISTRATION button which will require you to download
            an empty excel template.
          </li>
          <li>
            You are then requested to fill the excel template with the student's
            details such as First Name, Middle Name, Last Name, EmailId, Grade
            and Mobile No.
          </li>
          <li>
            On completing the excel template, please upload it on the GyanSopan
            account
          </li>
          <li>Make the payment to confirm your registration.</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>6. When can I enroll for BMTSC 2023-2024? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        The Registration starts from: <b>1st September, 2023 </b> and ends on:{" "}
        <b>30th November, 2023.</b>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>7. What is the syllabus for BMTSC? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        The syllabus for BMTSC is:
        <ul typeof="1">
          <li>Integers </li>
          <li>Operations on numbers</li>
          <li>Vulgar and Decimal</li>
          <li>LCM and GCD/HCF</li>
          <li>Squares and Square roots</li>
          <li>Percentage</li>
          <li>
            Average, Ratio and Proportion, Profit and Loss, Unitary Method
          </li>
          <li>Number Patterns</li>
          <li>Time - Speed - Work</li>
          <li>Units of measurement</li>
          <li>
            Geometry properties of Angles – Lines – Triangles – Quadrilaterals
            (Square, Rectangle, Trapezium, Rhombus, Parallelogram) – Circle –
            Chord, Radius, Diameter{" "}
          </li>
          <li>Perimeter and Area</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>8. What is the fee for BMTSC 2023-2024? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        The Individual Registration Fee is: <b>INR 200 </b>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>9. How many rounds are there in BMTSC? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        <b>Two(2)</b> BMTSC round tests will be conducted for BMTSC 2023 - 2024. Students
        shortlisted in BMTSC Round 1 will be eligible for BMTSC Round 2. The top performer
        students from BMTSC Round 1 will be shortlisted for BMTSC Round 2.
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>
          10. What are the Rules and Requirements for BMTSC Round 1 Online
          Examination?{" "}
        </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        The rules and requirements are:
        <ul typeof="1">
          <li>Students should login 15 minutes before the examination.</li>
          <li>
            Students must use a device with camera (Laptop, tab, mobile etc..)
            to appear for the examination.
          </li>
          <li>
            We recommend using <b>Laptop</b> for simplicity.
          </li>
          <li>
            Student must confirm that there is a good quality high speed
            internet connection available at the place from where he/she is
            appearing for examination.
          </li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>11. Is there any Mock Test available for BMTSC Examination? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        There will be at most 3 Mock Tests will be ogranized before BMTSC Round 1 Examination. 
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>12. What kinds of questions and corresponding answers are included in the BMTSC Examination? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      BMTSC Round 1 will comprise multiple-choice questions (MCQs) and numerical answer type questions. In contrast, the BMTSC Round 2 examination consists of subjective questions that require written answers with complete explanations.      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>13. What criteria are considered for the BMTSC Round 2 Examination? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      The top performer students from BMTSC Round 1 will be considered for the BMTSC Round 2 examination.     
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>14. Am I eligible to receive a certificate or prize for participating in BMTSC 2023-2024? </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
      The awards will be determined exclusively based on the scores achieved in the BTMSC Round 2 assessment. The Bhaskaracharya Pratishthana is presenting the following prizes for the BMTSC 2023-2024:
        <ul typeof="1">
          <li>The First Prize is INR 5000 and a book.</li>
          <li>The Second Prize is INR 4000 and a book.</li>
          <li>The Third Prize is INR 3000 and a book.</li>
          <li>Cheer Prizes in the form of books will be given to some percentage of top students from BMTSC Round 2.</li>
          <li>Nurture Training Camp will be arranged for all such students who are awarded a prize.</li>
        </ul>
      </Typography>{" "}
      <br /> <br />
      <Typography variant="h6">
        <b>
          15. Who should I contact for more clarification on the BMTSC 2023-2024 Registration Process?{" "}
        </b>
      </Typography>{" "}
      <br />
      <Typography style={{ fontSize: 18 }}>
        You can contact the following people:
        <ul typeof="1">
          <li>Dr. V V Acharya - Chief Coordinator BMTSC 2023-2024 </li>
          <li>Mr. Kiran Barve - +91 7040205185</li>
          <li>Ms. Medha Ambika - +91 9822211840</li>
          <li>Ms. Tejashree Gaidhani - +91 9762938277 (Only for Technical Issues)</li>
          <li>Mr. Jitendra Sadangi - +91 9967976846 (Only for Technical Issues)</li>
          <li> GyanSopan Admin : 
            <a href="mailto: admin@gyansopan.com">admin@gyansopan.com</a>
          </li>
        </ul>
      </Typography>{" "}
      <br /> <br />
    </div>
  );
};

export default bPrim;
