//export awsconfig = {region: 'ap-south-1'};
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
  //  "aws_cognito_identity_pool_id": "ap-south-1:0039298a-e94a-45e0-87d3-f6af80b754ed",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_7cNHAVzqs",
    "aws_user_pools_web_client_id": "2v7rl8m1cpn9ii1e167vv1gu37",
    "oauth": {},
    "aws_user_files_s3_bucket": "gyansopan",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
