import React, { useState } from "react";
import { createTheme, ThemeProvider, Grid } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";

import {
  BasicDiv,
  StyledButton,
  HideInMobileDiv,
} from "../../css/StyledComponents";
import EventCourseCard from "./EventCourseCard";

const userGetEventsQuery = loader(
  "../../graphqlCalls/user/userGetAllEvents.gql"
);

const Events_Updates = (props) => {
  const fUser = props.fUser;

  const theme = createTheme({
    overrides: {
      MuiButton: {
        label: {
          // don't change
          justifyContent: "center",
        },
      },
    },
  });

  const [events, setEvents] = useState([]);
  const [newEvents, setNewEvents] = useState(0);

  const { loading: loadingEvents, refetch } = useQuery(userGetEventsQuery, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      userId: fUser ? fUser._id : null,
      userT: fUser ? fUser.role : null,
    },
    skip: !fUser,
    onCompleted: ({ userGetAllEvents }) => {
      setNewEvents(
        userGetAllEvents.newNotifs >= 0 ? userGetAllEvents.newNotifs : 0
      );
      setEvents(userGetAllEvents.notifs);
    },
    onError: (error) => {},
  });
  return (
    <HideInMobileDiv>
      <ThemeProvider theme={theme}>
        <div style={{ display: "flex" }}>
          <StyledButton
            variant="contained"
            style={{ width: "100%" }}
            // sx={{
            //   // width: 300,
            //   // color: "success.main",
            //   "& .MuiButton-label": {
            //     "justify-content": "right",
            //   },
            // }}
            onClick={() => refetch()}
            disabled={loadingEvents}
            startIcon={<Refresh />}
          >
            <p style={{ "margin-top": "0em", "margin-bottom": "0em" }}>
              Events and Updates
              {newEvents != 0 ? (
                <sup
                  style={{
                    color: "#ffdd40",
                    // "vertical-align": "super",
                    "font-size": "medium",
                  }}
                >
                  {newEvents}
                </sup>
              ) : null}
            </p>
          </StyledButton>
        </div>

        <BasicDiv style={{ height: "70vh" }}>
          {events ? (
            <Grid
              container
              // spacing={10}
              style={{
                flexDirection: "row",
                display: "flex",
                overflowY: "scroll",
                display: "inline-block",
              }}
            >
              {events.map((event, index) => {
                // don't remove index its used for checking isNew
                return event.msg ? (
                  <EventCourseCard
                    key={index}
                    msg={event.msg}
                    path={event.path}
                    state={event.state}
                    isNew={index < newEvents ? true : false}
                  />
                ) : null;
              })}
            </Grid>
          ) : null}
        </BasicDiv>
      </ThemeProvider>
    </HideInMobileDiv>
  );
};

export default Events_Updates;
