import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import { FormControl } from "@mui/material";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import ReactExports from "react-export-excel";
import { Publish } from "@mui/icons-material";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  StyledDialog,
  StyledDialogContent,
  StyledTypography,
  StyledTextField,
  StyledGrid,
  StyledButton,
  StyledFormHelperText,
} from "../../css/StyledComponents";

import { pincodes } from "../../constants/pincodes";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";
import { LoadingSpinner } from "../../constants/loadingSpinner";

const ExcelFile = ReactExports.ExcelFile;
const ExcelSheet = ReactExports.ExcelSheet;
const ExcelColumn = ReactExports.ExcelColumn;
const dataSet = [];

const userDoBulkExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserDoBulkExamPayment.gql"
);
const userUpdatePostBulkExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserUpdatePostBulkExamPayment.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const mobileNoFormat = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const emailIdFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const BulkRegistration = (props) => {
  const fUser = props.fUser;
  const { state: locationState } = useLocation();
  const exam = locationState?.exam;
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [fileRows, setFileRows] = useState([]);
  const [GSTNo, setGSTNo] = useState("");
  const [pincode, setPincode] = useState(0);
  const [state, setState] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [billDate, setBillDate] = useState(0);

  const getRandomString = () => {
    return Math.random().toString(36);
  };
  const [fileIpKey, setFileIpKey] = useState(getRandomString());

  const [
    UserDoBulkExamPaymentMutation,
    {
      loading: fetchingUserPayment,
      error: errorUserPaying,
      data: dataUserPaying,
    },
  ] = useMutation(userDoBulkExamPaymentMutation, {
    onError: (err) => {
      setFileIpKey(getRandomString());
      setFileRows([]);
      alert("Something went wrong");
    },
  });
  const [
    UserUpdatePostBulkExamPaymentMutation,
    {
      loading: updatingUserPostPayment,
      error: errorUserPostUpdate,
      data: dataUserPostUpdate,
    },
  ] = useMutation(userUpdatePostBulkExamPaymentMutation, {
    onError: (err) => {
      setFileIpKey(getRandomString());
      setFileRows([]);
      alert("Something went wrong");
    },
    onCompleted: () => {
      NotificationsSuccess("Registered!");
      setFileIpKey(getRandomString());
      setFileRows([]);
    },
  });

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };

  const getState = (pincodeIp) => {
    const found = pincodes.find((pc) => pc.Pincode == pincodeIp);
    if (found) {
      return found.StateName;
    }
    return "";
  };
  async function DisplayRazorpay() {
    let callInfo;
    if (fetchingUserPayment) return;

    if (state == "") {
      alert("Enter valid pincode");
      return;
    }
    if (fileRows.length < 3) {
      setFileIpKey(getRandomString());
      setFileRows([]);
      alert("Add at least 2 students");
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }

    try {
      callInfo = await UserDoBulkExamPaymentMutation({
        variables: {
          input: {
            examId: exam._id,
            fileRows: fileRows,
          },
        },
      });
    } catch (error) {
      setFileIpKey(getRandomString());
      setFileRows([]);
      return;
    }
    let dp;
    dp = callInfo.data.UserDoBulkExamPayment;
    if (dp.userExists) {
      setFileIpKey(getRandomString());
      setFileRows([]);
      alert(`${dp.duplicateMail} Already Exists!`);
      return;
    }
    if (dp.wrongGrade?.is) {
      setFileIpKey(getRandomString());
      setFileRows([]);
      alert(
        `${dp.wrongGrade?.mail} has invalid grade ${dp.wrongGrade?.grade}!`
      );
      return;
    }

    const options = {
      key: "rzp_test_UL2w8U1WKAUgx8",
      currency: dp.currency,
      amount: dp.amount?.toString(),
      order_id: dp.id,
      name: "Registration Fees",
      description: `${exam.name} Fee (email id cannot be changed)`,
      handler: async function (response) {
        try {
          let callInfo;
          callInfo = await UserUpdatePostBulkExamPaymentMutation({
            variables: {
              input: {
                userId: fUser._id,
                userType: fUser.role,
                examId: exam?._id,
                fileRows: fileRows,
                state: state,
                pincode: parseInt(pincode),
                GSTNo: GSTNo,
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
              },
            },
          });
        } catch (error) {
          alert("Something went wrong");
        }
      },
      prefill: {
        email: fUser.mail,
      },
      readonly: { email: true },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const readFile = async () => {
    readXlsxFile(file)
      .then(async (rows) => {
        if (rows.length < 3) {
          alert("Add at least 2 Students in the File");
          setFileIpKey(getRandomString());
          setFileRows([]);
          return;
        } else {
          let emailRows = [];
          for (let i = 1; i < rows.length; i++) {
            if (!rows[i][3] || !emailIdFormat.test(rows[i][3])) {
              alert(`Invalid Email in row ${i + 1}`);
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            } else {
              emailRows.push(rows[i][3]);
            }
          }
          let duplicateIndex;
          let isDuplicate = emailRows.some(function (item, idx) {
            if (emailRows.indexOf(item) != idx) {
              duplicateIndex = idx + 2; //+2 is correct
              return true;
            }
          });
          if (isDuplicate) {
            alert(`Duplicate Email in row ${duplicateIndex}`);
            setFileIpKey(getRandomString());
            setFileRows([]);
            return;
          }
          for (let i = 1; i < rows.length; i++) {
            if (!rows[i][0]) {
              alert(`First Name cannot be empty in row ${i + 1}`);
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            }
            if (!rows[i][2]) {
              alert(`Last Name cannot be empty in row ${i + 1}`);
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            }
            if (!rows[i][3] || !emailIdFormat.test(rows[i][3])) {
              alert(`Invalid Email in row ${i + 1}`);
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            }
            if (!rows[i][4] || !exam.grade.includes(rows[i][4])) {
              alert(`Invalid Grade in row ${i + 1}`);
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            }
            if (!rows[i][5] || !mobileNoFormat.test(rows[i][5])) {
              alert(
                `Invalid Mobile Number in row ${i + 1}, Don't add country code.`
              );
              setFileIpKey(getRandomString());
              setFileRows([]);
              return;
            }
            for (let j = 0; j < rows[i].length; j++) {
              rows[i][j] = rows[i][j] ? `${rows[i][j]}` : null;
            }
          }

          setFileRows(rows);
          NotificationsSuccess("File Uploaded!");
        }
      })
      .catch((error) => {
        setFileIpKey(getRandomString());
        setFileRows([]);
        alert("something went wrong,only upload excel file.");
      });
  };
  const validationSchema = Yup.object().shape({
    pincode: Yup.number()
      .typeError("Pin code should contain numbers only")
      .integer("Pin code must be a 6 digit number")
      .max(999999, "Pin code must be a 6 digit number")
      .min(100000, "Pin code must be a 6 digit number")
      .required("Please enter the Pin code"),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  if (!locationState) {
    navigate("/examList");
    return null;
  }
  return (
    <div>
      {NotificationsContainer()}
      <StyledDialog
        maxWidth="lg"
        open={updatingUserPostPayment}
        onClose={() => {
          return;
        }}
      >
        <StyledDialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          {LoadingSpinner(updatingUserPostPayment)}
          <StyledTypography
            variant="h5"
            style={{ fontWeight: "bold", margin: 20 }}
          >
            Please wait while we process your Payment.
            <br />
            <StyledTypography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
              }}
            >
              Do not refresh the page or go back.
            </StyledTypography>
          </StyledTypography>
          <br />
        </StyledDialogContent>
      </StyledDialog>
      <div>
        <StyledTypography variant="h5">
          Registration Open For {exam.name}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registration Fees: Rs.{exam.fees?.individual}/- Individual
          {fUser.role === "Teacher" || fUser.role === "Institute"
            ? ` , Rs.${exam.fees?.bulk}/- Bulk Per Student`
            : ``}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registrations open on {getFormattedDate(Date.parse(exam.regStart))}{" "}
          and will close on {getFormattedDate(Date.parse(exam.regEnd))}
        </StyledTypography>
        <StyledTypography>
          *Please enter a valid Indian Pincode to enable Exam Registration
        </StyledTypography>

        <StyledTypography variant="h6">
          Please enter
          <span style={{ fontWeight: "bold", marginLeft: 10 }}>
            {" "}
            PINCODE* :
          </span>
          <FormControl
            {...register("pincode")}
            error={errors.pincode ? true : false}
          >
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledTextField
                  name="pincode"
                  value={pincode}
                  type="number"
                  onChange={(e) => {
                    setPincode(e.target.value);
                    let stateIs = getState(e.target.value);
                    setState(stateIs);
                  }}
                />
              )}
              control={control}
              name="pincode"
            />
            <StyledFormHelperText>
              {errors.pincode?.message}
            </StyledFormHelperText>
          </FormControl>
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> STATE:</span>
          <StyledTextField name="state" value={state} disabled />
        </StyledTypography>
        <br />
        <StyledTypography variant="h6">
          For value added tax invoice, please provide
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> GSTIN:</span>
          <StyledTextField
            name="GSTNo"
            value={GSTNo}
            label="(optional)"
            onChange={(e) => {
              setGSTNo(e.target.value);
            }}
          />
        </StyledTypography>
      </div>
      <br />
      <StyledGrid
        container
        direction="row"
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <StyledGrid item>
          <StyledTypography style={{ fontSize: "", color: "#1f5156" }}>
            Click here to download template file:
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <ExcelFile element={<button>Download Template</button>}>
            <ExcelSheet data={dataSet} name="Template file">
              <ExcelColumn label="First Name" />
              <ExcelColumn label="Middle Name" />
              <ExcelColumn label="Last Name" />
              <ExcelColumn label="Email" />
              <ExcelColumn label="Grade" />
              <ExcelColumn label="Mobile No" />
            </ExcelSheet>
          </ExcelFile>
        </StyledGrid>
        <StyledGrid item> </StyledGrid>
      </StyledGrid>
      <StyledGrid
        container
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <StyledGrid item>
          <StyledTypography variant="h6" style={{ fontWeight: "bold" }}>
            Register Bulk Students:
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <input
            key={fileIpKey}
            type="file"
            id="input"
            disabled={fetchingUserPayment || updatingUserPostPayment}
            onChange={(event) => setFile(event.target.files[0])}
          />
        </StyledGrid>
        <StyledGrid item>
          <StyledButton
            variant="contained"
            disabled={fetchingUserPayment || updatingUserPostPayment}
            onClick={readFile}
            startIcon={<Publish />}
          >
            Upload!
          </StyledButton>
        </StyledGrid>
      </StyledGrid>
      <br />
      <div style={{ textAlign: "center" }}>
        <StyledTypography
          variant="h7"
          style={{ fontWeight: "bold", margin: "auto" }}
        >
          Please upload student's details, every student should have an unique
          email-id.
        </StyledTypography>
      </div>

      <div>
        <StyledButton
          variant="contained"
          disabled={
            // state == "" ||
            // fileRows.length < 3 ||
            fetchingUserPayment || updatingUserPostPayment
          }
          size="medium"
          onClick={handleSubmit(DisplayRazorpay)}
        >
          Register
        </StyledButton>
        <StyledTypography>*Payment is non-refundable</StyledTypography>
      </div>
    </div>
  );
};

export default BulkRegistration;
