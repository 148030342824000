import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import ReactPaginate from "react-paginate";

import TestCourseCard from "./TestCourseCard";

import "../../../css/content.css";
import { StyledGrid, ListHeaderDiv, ListDiv } from "../../../css/StyledComponents";
import { LoadingSpinner } from "../../../constants/loadingSpinner";

const userGetTestsQuery = loader(
  "../../../graphqlCalls/test/userGetAllTests.gql"
);
const studentGetTestsQuery = loader(
  "../../../graphqlCalls/test/studentGetAllTests.gql"
);

const TestContent = (props) => {
  const [tests, setTests] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const fUser = props.fUser;
  const [criteria, setCriteria] = useState("");

  const returnQueryName = () => {
    if (fUser.role === "Teacher" || fUser.role === "Institute")
      return userGetTestsQuery;
    else if (fUser.role === "Student") return studentGetTestsQuery;
    else {
      console.log("HANDLE THIS CASE!");
    }
  };

  const returnQueryFields = () => {
    if (fUser.role === "Teacher" || fUser.role === "Institute") {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
          userId: fUser ? fUser._id : null,
          userT: fUser ? fUser.role : null,
        },
        skip: !fUser,
        onCompleted: ({ userGetAllTests }) => {
          setTests(userGetAllTests);
        },
        onError: (err) => {
          alert("Something went wrong");
        },
      };
    } else if (fUser.role === "Student") {
      return {
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        variables: {
          userId: fUser ? fUser._id : null,
        },
        skip: !fUser,
        onCompleted: ({ studentGetAllTests }) => {
          setTests(studentGetAllTests);
          // checkIfPassed(studentGetAllTests);
        },
        onError: (err) => {
          // console.log(JSON.stringify(err, null, 2));
          // if (err.message != "Failed to fetch") {
          // alert("Something went wrong");
          // }
          // alert("Something went wrong");
        },
      };
    } else {
      console.log("HANDLE THIS CASE!");
    }
  };
  const { loading, error, data, networkStatus, refetch } = useQuery(
    returnQueryName(),
    returnQueryFields()
  );
  let testsPerPage = 9;

  const pagesVisited = pageNumber * testsPerPage;

  const displayTests = tests
    ? tests
        .slice(pagesVisited, pagesVisited + testsPerPage)
        .map((test, index) => {
          if (fUser.role === "Student")
            return test.test != null ? (
              <StyledGrid key={index} item xs={12} sm={4}>
                <TestCourseCard
                  fUser={fUser}
                  id={test.test._id}
                  name={test.test.name}
                  ofDate={test.test.ofDate}
                  code={test.test.testCode}
                  subId={test.submissionId}
                  subject={test.test.subject}
                  duration={test.test.duration}
                  testStartTime={test.test.testStartTime}
                  testEndTime={test.test.testEndTime}
                  dirty={test.dirty}
                  restartable={test.restartable}
                  dq={test.dq}
                  testType={test.test.testType}
                  isOfExam={test.test.isOfExam}
                  examName={test.test.examName}
                  // topics={test.topics}
                  // grade={test.grade}
                />
              </StyledGrid>
            ) : null;
          else if (fUser.role === "Teacher" || fUser.role === "Institute")
            return (
              <StyledGrid key={index} item xs={12} sm={4}>
                <TestCourseCard
                  fUser={fUser}
                  id={test._id}
                  name={test.name}
                  ofDate={test.ofDate}
                  code={test.testCode}
                  subject={test.subject}
                  duration={test.duration}
                  testStartTime={test.testStartTime}
                  testEndTime={test.testEndTime}
                  testType={test.testType}
                  isOfExam={test.isOfExam}
                  examName={test.examName}
                  // subId={test.submissionId}
                  // topics={test.topics}
                  // grade={test.grade}
                />
              </StyledGrid>
            );
        })
    : [];
  const pageCount = tests ? Math.ceil(tests.length / testsPerPage) : 0;

  const changepage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <ListDiv /*className="content"*/>
      <ListHeaderDiv>
        <p>
          Please <span style={{ color: "red" }}>Refresh</span> the page if the
          Start Test Button has not{" "}
          <span style={{ color: "red" }}>Automatically appeared</span> at the
          Test Start Time
        </p>
      </ListHeaderDiv>
      {tests ? (
        <StyledGrid container spacing={2}>
          {displayTests}
        </StyledGrid>
      ) : null}
      {tests.length != 0 ? (
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={changepage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"paginationLink"}
          nextLinkClassName={"paginationLink"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      ) : null}
      <br />
      <br />
      {LoadingSpinner(loading || !fUser)}
    </ListDiv>
  );
};

export default TestContent;
