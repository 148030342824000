import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { pincodes } from "../../constants/pincodes";
import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../constants/notifications";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  StyledTypography,
  StyledTextField,
  StyledFormHelperText,
  StyledButton,
} from "../../css/StyledComponents";

const userGetUnRegStudentsQuery = loader(
  "../../graphqlCalls/exam/userGetAllUnRegStudents.gql"
);
const userDoAlreadyRegExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserDoAlreadyRegExamPayment.gql"
);
const userUpdatePostAlreadyRegExamPaymentMutation = loader(
  "../../graphqlCalls/payment/UserUpdatePostAlreadyRegExamPayment.gql"
);

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AlreadyRegRegistration = (props) => {
  const fUser = props.fUser;
  const {
    state: { exam },
  } = useLocation();

  const navigate = useNavigate();

  const [GSTNo, setGSTNo] = useState("");
  const [pincode, setPincode] = useState(0);
  const [state, setState] = useState("");
  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [billDate, setBillDate] = useState(0);
  const [selModel, setSelModel] = useState([]);
  const [students, setStudents] = useState([]);

  const columns = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    {
      field: "grade",
      headerName: "Grade",
      width: 150,
    },
    // {
    //   field: "category",
    //   headerName: "Category",
    //   width: 150,
    // },
  ];
  const rows = students.map((studCat, index) => {
    return {
      id: studCat.student._id,
      firstName: studCat.student.portrait.alias.first,
      lastName: studCat.student.portrait.alias.last,
      grade: studCat.student.portrait.grade,
      category: studCat.category,
    };
  });

  const {
    data: getStudentsData,
    loading: fetchingStudents,
    refetch,
  } = useQuery(userGetUnRegStudentsQuery, {
    fetchPolicy: "network-only",
    skip: !exam?._id,
    notifyOnNetworkStatusChange: true, //has to be added for refetch() to work
    variables: {
      userId: props.fUser ? props.fUser._id : null,
      userT: props.fUser ? props.fUser.role : null,
      eId: exam?._id,
    },
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: ({ userGetAllUnRegStudents }) => {
      setStudents(userGetAllUnRegStudents);
    },
  });

  const [
    UserDoAlreadyRegExamPaymentMutation,
    {
      loading: fetchingUserPayment,
      error: errorUserPaying,
      data: dataUserPaying,
    },
  ] = useMutation(userDoAlreadyRegExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
  });
  const [
    UserUpdatePostAlreadyRegExamPaymentMutation,
    {
      loading: updatingUserPostPayment,
      error: errorUserPostUpdate,
      data: dataUserPostUpdate,
    },
  ] = useMutation(userUpdatePostAlreadyRegExamPaymentMutation, {
    onError: (err) => {
      alert("Something went wrong");
    },
    onCompleted: () => {
      refetch();
      setSelModel([]);
      NotificationsSuccess("Registered!");
    },
  });

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const getFormattedDate = (dateIs) => {
    const formattedDate = new Date(dateIs);
    return `${formattedDate.getDate()} ${
      monthNames[formattedDate.getMonth()]
    } ${formattedDate.getFullYear()}`;
  };

  const getState = (pincodeIp) => {
    const found = pincodes.find((pc) => pc.Pincode == pincodeIp);
    if (found) {
      return found.StateName;
    }
    return "";
  };
  const getStudIds = () => {
    let sids = [];
    for (let i = 0; i < selModel.length; i++) {
      sids.push(selModel[i]);
    }
    return sids;
  };
  async function DisplayRazorpay() {
    let callInfo;
    if (fetchingUserPayment) return;

    if (state == "") {
      alert("Enter valid pincode");
      return;
    }
    if (selModel.length < 1) {
      alert("Select atleast one student");
      return;
    }

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }

    try {
      callInfo = await UserDoAlreadyRegExamPaymentMutation({
        variables: {
          input: {
            examId: exam._id,
            nos: selModel.length,
          },
        },
      });
    } catch (error) {}
    let dp;
    dp = callInfo.data.UserDoAlreadyRegExamPayment;

    const options = {
      key: "rzp_test_UL2w8U1WKAUgx8",
      currency: dp.currency,
      amount: dp.amount?.toString(),
      order_id: dp.id,
      name: "Registration Fees",
      description: `${exam.name} Fee (email id cannot be changed)`,
      handler: async function (response) {
        try {
          let callInfo;
          callInfo = await UserUpdatePostAlreadyRegExamPaymentMutation({
            variables: {
              input: {
                userId: fUser._id,
                userType: fUser.role,
                examId: exam?._id,
                students: getStudIds(),
                state: state,
                pincode: parseInt(pincode),
                GSTNo: GSTNo,
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
              },
            },
          });
        } catch (error) {}
      },
      prefill: {
        email: fUser.mail,
      },
      readonly: { email: true },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const validationSchema = Yup.object().shape({
    pincode: Yup.number()
      .typeError("Pin code should contain numbers only")
      .integer("Pin code must be a 6 digit number")
      .max(999999, "Pin code must be a 6 digit number")
      .min(100000, "Pin code must be a 6 digit number")
      .required("Please enter the Pin code"),
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  if (!exam) {
    navigate("/examList");
    return null;
  }

  return (
    <div>
      {NotificationsContainer()}
      <div>
        <StyledTypography variant="h5">
          Registration Open For {exam.name}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registration Fees: Rs.{exam.fees?.individual}/- Individual
          {fUser.role === "Teacher" || fUser.role === "Institute"
            ? ` , Rs.${exam.fees?.bulk}/- Bulk Per Student`
            : ``}
        </StyledTypography>
        <StyledTypography variant="h6">
          Registrations open on {getFormattedDate(Date.parse(exam.regStart))}{" "}
          and will close on {getFormattedDate(Date.parse(exam.regEnd))}
        </StyledTypography>

        <StyledTypography>
          *Please enter a valid Indian Pincode to enable Exam Registration
        </StyledTypography>
        <StyledTypography variant="h6">
          Please enter
          <span style={{ fontWeight: "bold", marginLeft: 10 }}>
            {" "}
            PINCODE* :
          </span>
          <FormControl
            {...register("pincode")}
            error={errors.pincode ? true : false}
          >
            <Controller
              render={({ field: { onChange, value, name, ...field } }) => (
                <StyledTextField
                  name="pincode"
                  value={pincode}
                  type="number"
                  onChange={(e) => {
                    setPincode(e.target.value);
                    let stateIs = getState(e.target.value);
                    setState(stateIs);
                  }}
                />
              )}
              control={control}
              name="pincode"
            />
            <StyledFormHelperText>
              {errors.pincode?.message}
            </StyledFormHelperText>
          </FormControl>
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> STATE:</span>
          <StyledTextField name="state" value={state} disabled />
        </StyledTypography>
        <br />
        <StyledTypography variant="h6">
          For value added tax invoice, please provide
          <span style={{ fontWeight: "bold", marginLeft: 10 }}> GSTIN:</span>
          <StyledTextField
            name="GSTNo"
            value={GSTNo}
            label="(optional)"
            onChange={(e) => {
              setGSTNo(e.target.value);
            }}
          />
        </StyledTypography>
      </div>
      <br />
      <StyledButton
        variant="contained"
        size="medium"
        onClick={() => {
          setSelModel([]);
        }}
      >
        Reset
      </StyledButton>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          loading={fetchingStudents}
          disableSelectionOnClick={
            fetchingUserPayment || updatingUserPostPayment
          }
          onRowSelectionModelChange={(selectedRows) => {
            setSelModel(selectedRows);
          }}
          selectionModel={selModel ? Object.values(selModel) : []}
        />
      </div>
      <StyledButton
        variant="contained"
        disabled={
          // state == "" ||
          // fileRows.length < 3 ||
          fetchingUserPayment || updatingUserPostPayment
        }
        size="medium"
        onClick={handleSubmit(DisplayRazorpay)}
      >
        Register
      </StyledButton>
      <StyledTypography>*Payment is non-refundable</StyledTypography>
    </div>
  );
};

export default AlreadyRegRegistration;
