import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { loader } from "graphql.macro";
import { useQuery, useMutation, NetworkStatus } from "@apollo/client";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  RootDiv,
  InnerDiv,
  StyledButton,
  StyledTextField,
  StyledPara,
  StyledSpan,
  StyledInputLabel,
  StyledFormHelperText,
  StyledTypography,
  StyledMenuItem,
  StyledSelect,
} from "../../css/StyledAuthComponents";

import { gradesList } from "../../constants/gradesList";
import SignUpValidationForm from "../validations/SignUpValidation";

const createUserMutation = loader("../../graphqlCalls/user/SignUpUser.gql");

const initialFormValues = {
  username: "",
  firstName: "",
  middleName: "",
  lastName: "",
  grade: "",
  userRole: "",
  instituteName: "",
  institutePincode: 0,
  mobileNo: "",
};

export default function SignUp(props) {
  const [hostname, setHostname] = useState(window.location.hostname);
  const [formValues, setValues] = useState(initialFormValues);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...formValues,
      [name]: value,
    });
  };
  const { toSignIn } = useAuthenticator();

  const validationSchema = SignUpValidationForm;
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    // setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [
    CreateUserMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createUserMutation, {
    onError: (err) => {
      // console.log(JSON.stringify(err, null, 2));
      alert(
        err.message == "Error: Duplicate Email,User Already Exists!"
          ? err.message
          : "Something went wrong"
      );
    },
    onCompleted: () => {
      alert("Temporary Password has been Mailed to you");
      // toSignIn();
      props.setAuthPage("SIGN_IN");
    },
  });

  const handleOnSubmit = async (e) => {
    if (creating) return;
    try {
      const callInfo = await CreateUserMutation({
        variables: {
          input: {
            email: formValues.username,
            mobileNo: formValues.mobileNo,
            firstName: formValues.firstName,
            middleName: formValues.middleName,
            lastName: formValues.lastName,
            grade: formValues.grade,
            userRole: formValues.userRole,
            instituteName: formValues.instituteName,
            institutePincode: parseInt(formValues.institutePincode),
          },
        },
        // onError: (err) => {
        //   console.log("err", err);
        // },
        // update: updateCacheCreating,
      });
      // console.log("callInfo", callInfo);
      // history.push({
      //   pathname: "/",
      // });
    } catch (error) {
      // console.log("error", error);
      // alert("Something went wrong");
    }

    // console.log(formValues);
  };

  const userTypes = [
    { value: "Student", label: "Student" },
    { value: "Teacher", label: "Teacher / Tutor" },
    { value: "Institute", label: "Institute /  School / Coaching Center" },
  ];
  return (
    <RootDiv>
      <InnerDiv>
        <br />
        <br />
        <form>
          <StyledTypography
            style={{
              color: "#1f5156",
              fontSize: "1.15rem",
              fontWeight: 700,
              marginBottom: "0.75rem",
              display: "inline-block",
            }}
          >
            Welcome to GyanSopan
          </StyledTypography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FormControl
              {...register("username")}
              error={errors.username ? true : false}
            >
              <Controller
                render={({ field: { onChange, value, name, ...field } }) => (
                  <StyledTextField
                    {...field}
                    name="username"
                    variant="outlined"
                    label="User Name(Email)"
                    value={formValues.username}
                    onChange={handleInputChange}
                    required
                  />
                )}
                control={control}
                name="username"
              />
              <StyledFormHelperText style={{ color: "red" }}>
                {errors.username?.message}
              </StyledFormHelperText>
            </FormControl>
            <FormControl
              {...register("mobileNo")}
              error={errors.mobileNo ? true : false}
            >
              <Controller
                render={({ field: { onChange, value, name, ...field } }) => (
                  <StyledTextField
                    {...field}
                    name="mobileNo"
                    value={formValues.mobileNo}
                    onChange={handleInputChange}
                    variant="outlined"
                    label="Mobile No"
                    required
                  />
                )}
                control={control}
                name="mobileNo"
              />
              <StyledFormHelperText style={{ color: "red" }}>
                {errors.mobileNo?.message}
              </StyledFormHelperText>
            </FormControl>
            <FormControl
              required
              {...register("userRole")}
              error={errors.userRole ? true : false}
            >
              <StyledInputLabel>User Type</StyledInputLabel>
              <Controller
                render={({ field: { onChange, value, name, ...field } }) => (
                  <StyledSelect
                    {...field}
                    name="userRole"
                    label="User Type"
                    value={formValues.userRole}
                    onChange={handleInputChange}
                  >
                    {userTypes.map((role, index) => {
                      return (
                        <StyledMenuItem key={index} value={role.value}>
                          {role.label}
                        </StyledMenuItem>
                      );
                    })}
                  </StyledSelect>
                )}
                control={control}
                name="userRole"
              />
              <StyledFormHelperText style={{ color: "red" }}>
                {errors.userRole?.message}
              </StyledFormHelperText>
            </FormControl>
            {formValues.userRole === "Student" ||
            formValues.userRole === "Teacher" ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl
                  {...register("firstName")}
                  error={errors.firstName ? true : false}
                >
                  <Controller
                    render={({
                      field: { onChange, name, value, ...field },
                    }) => (
                      <StyledTextField
                        {...field}
                        name="firstName"
                        variant="outlined"
                        label="First Name"
                        value={formValues.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    )}
                    control={control}
                    name="firstName"
                  />
                  <StyledFormHelperText style={{ color: "red" }}>
                    {errors.firstName?.message}
                  </StyledFormHelperText>
                </FormControl>
                <StyledTextField
                  name="middleName"
                  variant="outlined"
                  label="Middle Name"
                  value={formValues.middleName}
                  onChange={handleInputChange}
                />
                <FormControl
                  {...register("lastName")}
                  error={errors.lastName ? true : false}
                >
                  <Controller
                    render={({
                      field: { onChange, value, name, ...field },
                    }) => (
                      <StyledTextField
                        {...field}
                        name="lastName"
                        variant="outlined"
                        label="Last Name"
                        value={formValues.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    )}
                    control={control}
                    name="lastName"
                  />
                  <StyledFormHelperText style={{ color: "red" }}>
                    {errors.lastName?.message}
                  </StyledFormHelperText>
                </FormControl>
              </div>
            ) : null}
            {formValues.userRole === "Student" ? (
              <FormControl
                required
                {...register("grade")}
                error={errors.grade ? true : false}
              >
                <StyledInputLabel>Grade</StyledInputLabel>
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledSelect
                      {...field}
                      label="Grade"
                      name="grade"
                      value={formValues.grade}
                      onChange={handleInputChange}
                    >
                      {gradesList.map((grade, index) => {
                        return (
                          <StyledMenuItem key={index} value={grade}>
                            {grade}
                          </StyledMenuItem>
                        );
                      })}
                    </StyledSelect>
                  )}
                  control={control}
                  name="grade"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.grade?.message}
                </StyledFormHelperText>
              </FormControl>
            ) : null}
            {/* {formValues.userRole === "Institute" ? ( */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControl
                // required
                {...register("instituteName")}
                error={errors.instituteName ? true : false}
              >
                {/* <StyledInputLabel >
              Institute Name
            </StyledInputLabel> */}
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="instituteName"
                      variant="outlined"
                      label="Institute / School Name"
                      value={formValues.instituteName}
                      onChange={handleInputChange}
                      required
                    />
                  )}
                  control={control}
                  name="instituteName"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.instituteName?.message}
                </StyledFormHelperText>
                {/* <StyledInputLabel >
                Institute Pincode
                </StyledInputLabel> */}
              </FormControl>
              <FormControl
                {...register("institutePincode")}
                error={errors.institutePincode ? true : false}
              >
                <Controller
                  render={({ field: { onChange, value, name, ...field } }) => (
                    <StyledTextField
                      {...field}
                      name="institutePincode"
                      variant="outlined"
                      type="number"
                      label="Institute / School Pincode"
                      value={formValues.institutePincode}
                      onChange={handleInputChange}
                      required
                    />
                  )}
                  control={control}
                  name="institutePincode"
                />
                <StyledFormHelperText style={{ color: "red" }}>
                  {errors.institutePincode?.message}
                </StyledFormHelperText>
              </FormControl>
            </div>
            {/* ) : null} */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
              flexDirection: "column",
            }}
          >
            <StyledPara>
              Already have an account?{" "}
              <StyledSpan
                onClick={() => {
                  // toSignIn();
                  props.setAuthPage("SIGN_IN");
                }}
              >
                Sign In
              </StyledSpan>
            </StyledPara>
            <StyledButton
              disabled={creating}
              variant="contained"
              size="large"
              onClick={handleSubmit(handleOnSubmit)}
            >
              Submit
            </StyledButton>
          </div>
        </form>
        <br />
        <br />
      </InnerDiv>
    </RootDiv>
  );
}
