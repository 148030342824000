import React from "react";
import { Link } from "react-router-dom";

import {
  StyledCard,
  StyledCardContent,
  StyledCardActions,
  StyledCardButton,
  StyledTypography,
  ButtonDiv,
} from "../../css/StyledComponents";

const EventCourseCard = (props) => {
  return (
    <StyledCard
      style={{
        borderStyle: props.isNew ? "solid" : "",
        borderColor: props.isNew ? "#ffdd40" : "",
        borderWidth: props.isNew ? "medium" : "",
      }}
    >
      <StyledCardContent>
        <StyledTypography>{props.msg}</StyledTypography>
      </StyledCardContent>
      <StyledCardActions>
        <ButtonDiv>
          <Link
            exact
            to={props.path}
            state={{ ...props.state }}
            style={{ textDecoration: "none" }}
          >
            <StyledCardButton variant="outlined" size="medium">
              Open
            </StyledCardButton>
          </Link>
        </ButtonDiv>
      </StyledCardActions>
    </StyledCard>
  );
};

export default EventCourseCard;
