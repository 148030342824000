import React from "react";
import { Typography } from "@mui/material";

const bPrim = () => {
  return (
    <div style={{ textAlign: "justify", margin: 20 }}>
      <Typography
        variant="h5"
        style={{ textAlign: "center", fontWeight: "bold" }}
      >
        About us - Bhaskaracharya Pratishthana
      </Typography>
      <br />
      <br />
      <Typography>
        <b> Bhaskaracharya Pratishthana</b> is a research and education
        institute for mathematics in Pune, India, founded by noted
        Indian-American mathematician <b>Professor Shreeram Abhyankar</b>. The
        institute is named after the great ancient Indian Mathematician{" "}
        <b>Bhaskaracharya</b> (Born in 1114 A.D.). Bhaskaracharya Pratishthana
        is a Pune, India, based institute founded in <b>1976</b>. It has
        researchers working in many areas of mathematics, particularly in
        algebra and number theory.
      </Typography>
      <br />
      <br />
      <Typography>
        Since 1992, the Bhaskaracharya Pratishthana has also been a recognized
        center for conducting <b>Regional Mathematics Olympiad (RMO)</b> under
        the <b>National Board for Higher Mathematics (NBHM)</b> for Maharashtra
        and Goa Region. This has enabled the Pratishthana to train lots of
        students from std. V to XII for this examination. Many students who
        received training at BP have won medals in the International
        Mathematical Olympiad.
      </Typography>
      <br />
      <br />
      <Typography>
        Bhaskaracharya Pratishthana publishes the mathematics periodical Bona
        Mathematica and has published texts in higher and olympiad mathematics.
        Besides this the Pratishthana holds annual / biennial
        conferences/Workshops in some research areas in higher mathematics
        attended by Indian/Foreign scholars and Professors. The Pratishthana has
        organized a number of workshops for research students and college
        teachers under the aegis of NBHM/NCM. The National Board for Higher
        Mathematics has greatly helped Pratishthana to enrich its library and
        the Department of Atomic Energy and the Mathematics Department of S. P.
        Pune University have rendered active co-operation in holding
        conferences/workshops.
      </Typography>
      <br />
      <br />
      <Typography>
        It also conducts the BMTSC exam which is a school-level mathematics
        competition for students studying in the 5th and the 6th grade. The
        objectives of the competition are listed below: 1. Identify good
        students of mathematics at an early age. 2. A pre Olympiad type
        competition. 3. To enhance Mathematical ability and logical thinking. 4.
        Nurture programs for successful students to improve their ability.
      </Typography>
      <br />
      <br />
      <Typography>
        For more information please visit{" "}
        <a href="https://www.bprim.org/" target="_blank">
          https://www.bprim.org/
        </a>
      </Typography>
      <br />
      <br />
    </div>
  );
};

export default bPrim;
