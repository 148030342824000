import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { Link } from "react-router-dom";
import { Visibility, Delete } from "@mui/icons-material";
import { Tooltip, Paper } from "@mui/material";

import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledButton,
} from "../../../css/StyledComponents";

import {
  NotificationsSuccess,
  NotificationsContainer,
} from "../../../constants/notifications";
const getTeachersQuery = loader(
  "../../../graphqlCalls/teacher/userGetAllTeachers.gql"
);
const deleteTeacherMutation = loader(
  "../../../graphqlCalls/teacher/UserDeleteTeacher.gql"
);

export default function TeacherList(props) {
  const [teachers, setTeachers] = useState([]);
  const [teacherToDelete, setTeacherToDelete] = useState("");
  const [teacherToDeleteName, setTeacherToDeleteName] = useState("");

  const [open, setOpen] = useState(false);
  const fUser = props.fUser;

  const { data, error, loading, refetch, networkStatus } = useQuery(
    getTeachersQuery,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: fUser ? fUser._id : null,
        userT: fUser ? fUser.role : null,
      },
      skip: !fUser,
      onCompleted: ({ userGetAllTeachers }) => {
        setTeachers(userGetAllTeachers);
      },
      onError: (err) => {
        console.log(err);
        alert("something went wrong");
      },
    }
  );
  const [
    DeleteTeacherMutation,
    { loading: deleting, error: errorDeleting, data: dataDeleting },
  ] = useMutation(deleteTeacherMutation, {
    onCompleted(data) {
      NotificationsSuccess("Deleted!");
      refetch();
    },
  });
  const deleteTeacher = async () => {
    if (deleting) return;
    try {
      const callInfo = await DeleteTeacherMutation({
        variables: {
          input: {
            userId: fUser._id,
            userType: fUser.role,
            email: teacherToDelete,
          },
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      alert("Something went wrong");
    }
  };
  return (
    <div>
      {NotificationsContainer()}
      <StyledDialog maxWidth="md" open={open} disableEscapeKeyDown>
        <StyledDialogContent>
          <Paper>Are You Sure you want to Delete {teacherToDeleteName} ?</Paper>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            variant="contained"
            onClick={async () => {
              await deleteTeacher();
              setOpen(false);
            }}
          >
            Yes,Delete
          </StyledButton>
          <StyledButton
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
      <StyledTableContainer component={Paper}>
        <StyledTable aria-label="simple table">
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                Name
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                Department
              </StyledTableCell>
              <StyledTableCell> </StyledTableCell>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {/* let this error be lol 21/3/22 error:teacherCat.teacher==null */}
            {teachers.map((teacherCat, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>
                  {teacherCat.teacher.portrait.alias.first}{" "}
                  {teacherCat.teacher.portrait.alias.last}
                </StyledTableCell>
                <StyledTableCell>{teacherCat.category}</StyledTableCell>
                <StyledTableCell>
                  {
                    <Link
                      exact
                      to={"/teacherProfile"}
                      state={{
                        id: teacherCat.teacher._id,
                        email: teacherCat.teacher.portrait.pingAt.virtualAdd,
                        // primaryTopic: props.primaryTopic,
                        // secondaryTopic: props.secondaryTopic,
                        // gradeLevel: props.gradeLevel,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Tooltip title="View">
                        <StyledButton
                          // variant="contained"
                          size="large"
                          startIcon={<Visibility />}
                          //   className={classes.buttonStyles}
                        ></StyledButton>
                      </Tooltip>
                    </Link>
                  }
                  <Tooltip title="Remove">
                    <StyledButton
                      disabled={deleting}
                      onClick={() => {
                        setTeacherToDeleteName(`${teacherCat.teacher.portrait.alias.first} 
                        ${teacherCat.teacher.portrait.alias.last}`);
                        setTeacherToDelete(
                          teacherCat.teacher.portrait.pingAt.virtualAdd
                        );
                        setOpen(true);
                      }}
                      size="large"
                      startIcon={<Delete />}
                    ></StyledButton>
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
}
