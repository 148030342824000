import React, { useState } from "react";
import readXlsxFile from "read-excel-file";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/client";
import { useNavigation } from "react-router-dom";
import ReactExports from "react-export-excel";
import { Publish } from "@mui/icons-material";

import {
  RootDiv,
  StyledButton,
  StyledGrid,
  StyledTypography,
} from "../../css/StyledComponents";

const ExcelFile = ReactExports.ExcelFile;
const ExcelSheet = ReactExports.ExcelSheet;
const ExcelColumn = ReactExports.ExcelColumn;
const dataSet = [];

const createStudentMutation = loader(
  "../../graphqlCalls/student/UserAddStudent.gql"
);

const AddBulkStudents = ({ fUser }) => {
  const navigation = useNavigation();

  const [file, setFile] = useState(null);

  //   const headerFlag = 6;
  const [
    CreateStudentMutation,
    { loading: creating, error: errorCreating, data: dataCreating },
  ] = useMutation(createStudentMutation);

  const readFile = async () => {
    readXlsxFile(file)
      .then(async (rows) => {
        for (let i = 1; i <= rows.length - 1; i++) {
          try {
            const callInfo = await CreateStudentMutation({
              variables: {
                input: {
                  userId: fUser._id,
                  userType: fUser.role,
                  firstName: rows[i][0],
                  middleName: rows[i][1],
                  lastName: rows[i][2],
                  email: rows[i][3],
                  grade: rows[i][4],
                },
              },
            });
          } catch (error) {
            alert("Something went wrong");
          }
        }
        navigation("/students");
      })
      .catch((error) => {
        alert("something went wrong,only upload excel file");
      });
  };

  return (
    <RootDiv>
      <StyledGrid
        container
        direction="row"
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <StyledGrid item>
          <StyledTypography
            variant="h6"
            style={{ fontWeight: "", color: "#1f5156" }}
          >
            Click here to download template file:
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <ExcelFile element={<button>Download Template</button>}>
            <ExcelSheet data={dataSet} name="Template file">
              <ExcelColumn label="First Name" />
              <ExcelColumn label="Middle Name" />
              <ExcelColumn label="Last Name" />
              <ExcelColumn label="Email" />
              <ExcelColumn label="Grade" />
            </ExcelSheet>
          </ExcelFile>
        </StyledGrid>
        <StyledGrid item> </StyledGrid>
      </StyledGrid>
      <StyledGrid
        container
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <StyledGrid item>
          <StyledTypography variant="h6" style={{ fontWeight: "bold" }}>
            Add Bulk Students:
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <input
            type="file"
            id="input"
            onChange={(event) => setFile(event.target.files[0])}
          />
        </StyledGrid>
        <StyledGrid item>
          <StyledButton
            variant="contained"
            disabled={creating}
            onClick={readFile}
            startIcon={<Publish />}
          >
            Upload!
          </StyledButton>
        </StyledGrid>
      </StyledGrid>
    </RootDiv>
  );
};

export default AddBulkStudents;
