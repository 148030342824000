/* eslint-disable import/no-anonymous-default-export */
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";

import { R1Participation, R1GradeBC } from "./R1CertifcateImages";

// Round 2

const getContnent = (studPortrait, gradeChar) => {
  let content;
  const studentName = `${studPortrait?.alias?.first} ${
    studPortrait?.alias?.middle ? studPortrait?.alias?.middle + " " : ""
  }${studPortrait?.alias?.last ? studPortrait?.alias?.last + " " : ""}`;
  const schoolName = studPortrait?.academic?.schoolName;
  if (gradeChar === "B" || gradeChar === "C") {
    content = [
      {
        text: studentName,
        absolutePosition: { x: 323, y: 252 },
        style: "studentDets",
      },
      {
        text: schoolName,
        absolutePosition: { x: 273, y: 287 },
        style: "studentDets",
      },
      {
        text: `${gradeChar} - Round 1`,
        absolutePosition: { x: 277, y: 330 },
        style: "studentDets",
      },
    ];
  } else {
    content = [
      {
        text: studentName,
        absolutePosition: { x: 323, y: 252 },
        style: "studentDets",
      },
      {
        text: schoolName,
        absolutePosition: { x: 273, y: 287 },
        style: "studentDets",
      },
      {
        text: "Round 1",
        absolutePosition: { x: 288, y: 330 },
        style: "studentDets",
      },
    ];
  }
  return content;
};
export default (studPortrait, grade) => {
  const { vfs } = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;

  let gradeChar = null;
  if (grade === 2) {
    gradeChar = "B";
  } else if (grade === 1) {
    gradeChar = "C";
  }

  const dd = {
    pageMargins: [0, 0, 0, 0],
    pageOrientation: "landscape",
    background: [
      {
        image:
          gradeChar === "B" || gradeChar === "C" ? R1GradeBC : R1Participation,
        width: 842,
        height: 595,
      },
    ],
    content: getContnent(studPortrait, gradeChar),
    styles: {
      studentDets: {
        fontSize: 18,
        bold: true,
        italics: true,
        margin: [10, 3, 3, 10],
      },
    },
  };

  pdfMake.createPdf(dd).download("Round1Certifcate.pdf");
};
